import React from "react"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../../globals/constants/client/constants"
import MobileParentContainer from "../../shared/frames/MobileParentContainer"
import MobileHeaderIcon from "../../shared/frames/MobileHeaderIcon"
import MobileFooterLanguage from "../../shared/input/dropdown/MobileFooterLanguage"
import MobileZoomScrollIcons from "../../shared/input/scroll/MobileZoomScrollIcons"
/**
 * Displays new account and login options. Moves to next window upon selection.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} [props]
 * @param {Object} [props.origin] - last page user visited
 * @return {JSX.Element}
 * @constructor
 */
const MobileLoginStart = props => {
	let window = store.getState().window.current
	if (!window.levelOne) {window = props.origin}
	const items = [
		{
			id: "newUser",
			onClick: () => {
				store.dispatch({
					type: actions.SET_CURRENT_WINDOW,
					values: {window: PAGE_STATES[window.levelOne].LOGIN.NEW_USER.INVITATION_Q.X}})},
			icon: ICONS.PLUS,
			text: DICTIONARY.NEW_ACCOUNT.X,
			initialConditions: {
				iconFontSize: "16.3682vh",
				opacity: 0.832917,
				textFontSize: "4.29269vh",
				textWidth: "69.975%"
			},
		},
		{
			id: "login",
			onClick: () => {
				store.dispatch({
					type: actions.SET_CURRENT_WINDOW,
					values: {window: PAGE_STATES[window.levelOne].LOGIN.EXISTING_USER.USERNAME.X}})},
			icon: ICONS.KEY,
			text: DICTIONARY.LOGIN.X,
			initialConditions: {
				iconFontSize: "6.38211vh",
				opacity: 0.291698,
				textFontSize: "2.15314vh",
				textWidth: "37.5019%"
			}
		},
	]
	const content = <div className={"w3-animate-opacity"}>
		<MobileHeaderIcon/><MobileZoomScrollIcons items={items} scroll={14}/><MobileFooterLanguage/>
	</div>
	return <MobileParentContainer content={content}/>
}

export default MobileLoginStart