import React, {useState} from "react"
import {DICTIONARY, ICONS, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import doc from "../../../images/file-pdf-duotone.svg"
import MobileFontAwesome from "../MobileFontAwesome"
import Modal from "../../../globals/components/Modal"
import MobileHeaderAndContent from "../MobileHeaderAndContent"
import documentContainerToSubject from "./documentContainerToSubject"
import submitToServer from "../../../globals/functions/client/submitToServer"
import MobileDocumentSelectOrUpload from "./MobileDocumentSelectOrUpload"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputText from "../../shared/input/text/MobileInputText"
import {
	deleteDocumentRequirement, deleteDocumentRequirementDocument,
	renameDocumentRequirement, replaceDocumentRequirementDocument,
	uploadDocument
} from "../../../globals/functions/client/serverFunctions"
import appendFileUploadFormData from "../../shared/input/file/appendFileUploadFormData"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.requirement
 * @param {number} props.requirement.id
 * @param {string} props.requirement.container
 * @param {string} props.requirement.type
 * @param {string} props.requirement.specifications
 * @param {string} props.requirement.label
 * @param {number} props.requirement.organization
 * @param {IndexedArray[Document]} props.documents
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileRequiredDocumentRequirementCard = props => {
	const [edit, setEdit] = useState(false)
	const [showDocument, setShowDocument] = useState(false)
	const [upload, setUpload] = useState(false)
	const [rename, setRename] = useState(false)
	const requirementId = props.requirement.id
	const selectedDocument = props.documents.byIndex(props.requirement.specifications)
	const onSuccess = () => {
		props.refresh().then()
		setEdit(false)
		setUpload(false)
		setRename(false)
		fadingMessage("share-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X))
	}
	const onUpload = async event => {
		event.preventDefault()
		try {
			const documentId = document.getElementById(`${requirementId}-doc-select-upload-dropdown-input`).value
			if (documentId === "-2") {
				const params = {requirement: requirementId}
				await submitToServer(event, params, deleteDocumentRequirementDocument, onSuccess)
			} else if (documentId === "-1") {
				const target =  {organization: -1}
				const formData = new FormData()
				appendFileUploadFormData(`${requirementId}`, formData).then(updatedFormData => {
					updatedFormData.set("target", JSON.stringify(target))
					updatedFormData.set("requirement", `${requirementId}`)
					uploadDocument(updatedFormData, "requirements/replace").then(response => {
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							onSuccess()
						}
					})
				})
			} else {
				const params = {requirement: requirementId, document: documentId}
				await submitToServer(event, params, replaceDocumentRequirementDocument, onSuccess)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const onRename = async event => {
		event.preventDefault()
		try {
			const params = {
				requirement: requirementId,
				label: document.getElementById(`requirement-${requirementId}-rename-input`).value
			}
			await submitToServer(event, params, renameDocumentRequirement, onSuccess)
		} catch (e) {
			console.log(e)
		}
	}
	const upload_ = upload ? <MobileDocumentSelectOrUpload title={translate(DICTIONARY.REQUIREMENTS.X)}
		documents={props.documents} selected={props.requirement.specifications || "-1"}
		onClick={() => setUpload(false)} id={requirementId} onSubmit={onUpload}
		label={translate(DICTIONARY.REQUIREMENTS.X)} className={"paralian-theme-level-1"}
		info={"Please provide a PDF document which provides specifics of the document being requested. For example, " +
		"the amount and currency of the personal liability limit on an insurance contract."}/> : <></>

	const rename_ = rename ? <Modal icon={ICONS.I_CURSOR} title={translate(DICTIONARY.RENAME.X)}
		onClick={() => setRename(false)} id={`${requirementId}-rename-modal`} padding={"0vh"}
		body={<MobileInputForm id={`requirement-${requirementId}-rename`} onSubmit={onRename} style={{margin: "1vh 2vh"}}
			content={<MobileInputText id={`requirement-${requirementId}-rename`} showLabel={true} autoFocus={true}
			label={translate(DICTIONARY.LABEL.X)} className={"paralian-theme-level-1"} autoComplete={"off"}
			form={`requirement-${requirementId}-rename-form`} required={true} topRadius={"0vh"} placeholder={translate(DICTIONARY.LABEL.X)}
			defaultValue={props.requirement.label} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX} name={"label"}
			pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE}/>}/>}/> : <></>

	const pdf = showDocument ? <Modal icon={ICONS.FILE_PDF} onClick={() => setShowDocument(false)}
		id={`${props.id}-modal`} padding={"0vh"} body={
		<div className="w3-display-container" style={{minHeight: "10vh", padding: "2vh"}}>
			<picture style={{width: "22.5vh", paddingTop: "1.5vh"}}>
				{selectedDocument.path ? <a href={selectedDocument.path}
					download={`${selectedDocument.name}.pdf`} target={"_blank"} rel={"noreferrer"}>
					<MobileFontAwesome icon={ICONS.DOWNLOAD} className={"w3-display-middle"}
						style={{padding: "1.5vh",	zIndex: "3", fontSize: "10vh"}}/>
				</a> : <></>}
				<img id={`${props.id}-img`} className={"w3-display-container"} alt={selectedDocument.name}
					src={selectedDocument.image.path || doc} style={{height: "100%", width: "100%"}}
					onError={() => document.getElementById(`${props.id}-img`).src = doc}/>
			</picture>
		</div>
	}/> : <></>
	const onDelete = async () => {
		const confirmed = window.confirm(translate(DICTIONARY.DELETE_DOCUMENT_REQUIREMENT_CONFIRM_QUERY.X))
		if (confirmed) {
			const response = await deleteDocumentRequirement({requirement: requirementId})
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				onSuccess()
			}
		}
	}
	const content = <>
		{pdf}
		{upload_}
		{rename_}
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} onDelete={onDelete}
			labels={[translate(props.requirement.label)]} subtitle={
			edit ? <div><i style={{opacity: "0.5", cursor: "pointer"}} className={"w3-ripple"}
										 onClick={() => setRename(true)}>
				{translate(DICTIONARY.RENAME.X)}
				<MobileFontAwesome icon={ICONS.I_CURSOR} style={{paddingLeft: "1.5vh"}}/>
			</i></div> : undefined
		}/>
		<div style={{textAlign: "left"}}>
			<MobileHeaderAndContent name={"subject"} header={translate(DICTIONARY.SUBJECT.X)}
				content={translate(documentContainerToSubject(props.requirement.container))}/>
			<MobileHeaderAndContent name={"capacity"} header={translate(DICTIONARY.CAPACITY.X)}
				content={props.requirement.customers && props.requirement.providers ?
					translate(DICTIONARY.CUSTOMER.X) + " " + translate(DICTIONARY.OR.X)+ " " + translate(DICTIONARY.PROVIDER.X) :
					props.requirement.customers ? translate(DICTIONARY.CUSTOMER.X) : translate(DICTIONARY.PROVIDER.X)
				}/>
			<MobileHeaderAndContent name={"type"} header={translate(DICTIONARY.DOCUMENT_TYPE.X)}
				content={translate(props.requirement.type)}/>
			<MobileHeaderAndContent name={"document"} header={translate(DICTIONARY.REQUIREMENTS.X)}
				content={
					<div style={{display: "inline-flex"}}>
						<div>
							{selectedDocument ? selectedDocument.name : translate(DICTIONARY.NONE_PROVIDED.X)}
							{selectedDocument ? <MobileFontAwesome icon={ICONS.FILE_PDF} style={{paddingLeft: "1.5vh"}}
								onClick={() => setShowDocument(true)}/> : <></>}
						</div>
						{
							edit ? <div style={{whiteSpace: "nowrap"}}>
								<i style={{paddingLeft: "1.5vh", opacity: "0.5", fontWeight: "normal"}}
									 className={"w3-ripple"} onClick={() => setUpload(true)}>
									{selectedDocument ? translate(DICTIONARY.REPLACE.X) : translate(DICTIONARY.UPLOAD.X)}
									<MobileFontAwesome icon={selectedDocument ? ICONS.REPEAT : ICONS.UPLOAD}
										style={{paddingLeft: "1.5vh"}}/>
								</i>
							</div> : <></>}
					</div>
				}/>
		</div>
	</>
	return <div className={"paralian-mobile-info-card"} style={{padding: "2vh", margin: "2vh"}}>{content}</div>
}
export default MobileRequiredDocumentRequirementCard