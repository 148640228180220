import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, toggleOverflow, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
/**
 *
 * @param {Object} props
 * @param {ContactInfo} props.contactInfo
 * @param {Websites} props.websites
 * @param {boolean} props.isOrganization
 * @returns {JSX.Element}
 */
const MobileContactInfoFields = props => {
	const verificationMessage = verificationType => {
		let text = verificationType === "primary-email" ? translate(DICTIONARY.PRIMARY_EMAIL) :
			verificationType === "primary-phone" ? translate(DICTIONARY.PRIMARY_PHONE) : null
		if (verificationType === "secondary-email" || verificationType === "secondary-phone") {
			if (props.isOrganization) {
				text = verificationType === "secondary-email" ? translate(DICTIONARY.SUPPORT_EMAIL) :
					verificationType === "secondary-phone" ? translate(DICTIONARY.SUPPORT_PHONE) : null
			} else {
				text = verificationType === "secondary-email" ? translate(DICTIONARY.RECOVERY_EMAIL) :
					verificationType === "secondary-phone" ? translate(DICTIONARY.RECOVERY_PHONE) : null
			}
		}
		newMessage(`${verificationType}-alert`, translate(DICTIONARY.VERIFICATION.X),
			translate(DICTIONARY.YOUR.X) + text + translate(DICTIONARY.CONTACT_INFO_FIELDS_VERIFY_MESSAGE_PT2.X) +
			translate(DICTIONARY.CONTACT_INFO_FIELDS_VERIFY_MESSAGE_PT3), "w3-pale-blue", ICONS.INFO_CIRCLE)
	}
	const primaryEmailLogin = (props.contactInfo.emails.primary.login) ?
		<MobileFontAwesome icon={ICONS.SHIELD} label={translate(DICTIONARY.LOGIN_EMAIL.X)}
			style={{fontSize: "2vh", marginRight: "1vh"}}	onClick={() => newMessage("primary-email-login",
				translate(DICTIONARY.LOGIN.X), translate(DICTIONARY.CONTACT_INFO_FIELDS_LOGIN_EMAIL_MESSAGE.X),
			"w3-pale-blue", ICONS.INFO_CIRCLE)}/> : <></>
	const primaryPhoneLogin = (props.contactInfo.phones.primary.login) ?
		<MobileFontAwesome icon={ICONS.SHIELD} label={translate(DICTIONARY.LOGIN_PHONE.X)}
			style={{fontSize: "2vh", marginRight: "1vh"}} onClick={() => newMessage("primary-phone-login",
				translate(DICTIONARY.LOGIN.X), translate(DICTIONARY.CONTACT_INFO_FIELDS_LOGIN_PHONE_MESSAGE.X),
			"w3-pale-blue", ICONS.INFO_CIRCLE)}/> : <></>
	const primaryEmailAlert = (!props.contactInfo.emails.primary.verified && props.contactInfo.emails.primary.address) ?
		<MobileFontAwesome icon={ICONS.EXCLAMATION_TRIANGLE} label={translate(DICTIONARY.NOT_VERIFIED.X)}
			style={{fontSize: "2vh", color:"yellow"}} className={"w3-display-topright"}
			onClick={() => verificationMessage("primary-email")}/> : <></>
	const secondaryEmailAlert = (!props.contactInfo.emails.secondary.verified && props.contactInfo.emails.secondary.address) ?
		<MobileFontAwesome icon={ICONS.EXCLAMATION_TRIANGLE} label={translate(DICTIONARY.NOT_VERIFIED.X)}
			style={{fontSize: "1vh", color:"yellow", marginTop: "4.5vh"}} className={"w3-display-topright"}
			onClick={() => verificationMessage("secondary-email")}/> : <></>
	const primaryPhoneAlert = (!props.contactInfo.phones.primary.verified && props.contactInfo.phones.primary.nationalNumber) ?
		<MobileFontAwesome icon={ICONS.EXCLAMATION_TRIANGLE} label={translate(DICTIONARY.NOT_VERIFIED.X)}
			style={{fontSize: "2vh", color:"yellow"}} className={"w3-display-topright"}
			onClick={() => verificationMessage("primary-phone")}/> : <></>
	const secondaryPhoneAlert = (!props.contactInfo.phones.secondary.verified && props.contactInfo.phones.primary.nationalNumber) ?
		<MobileFontAwesome icon={ICONS.EXCLAMATION_TRIANGLE} label={translate(DICTIONARY.NOT_VERIFIED.X)}
			style={{fontSize: "1vh", color:"yellow", marginTop: "4.5vh"}} className={"w3-display-topright"}
			onClick={() => verificationMessage("secondary-phone")}/> : <></>
	const value = (data, alert, login, fontSize, id, isPhone) => {
		return <div>
			<div style={{display: "inline-flex", maxWidth: "100%"}}>
				<div style={{width: "2vh"}}>{login}</div>
				<div id={id} style={{fontSize: fontSize}} translate={"no"}
					className={"paralian-overflow-nowrap-ellipsis"} onClick={() => toggleOverflow(id)}>
					<a style={{textDecoration: "unset"}} href={`${isPhone ? "tel:" : "mailto:"}${data}`}>{data}</a>
				</div>
				<div style={{width: "2vh"}}>{alert}</div>
			</div>
		</div>
	}
	const primaryEmailContent = props.contactInfo.emails.primary.address ?
		value(props.contactInfo.emails.primary.address, primaryEmailAlert, primaryEmailLogin,"3vh",
			"primaryEmail_", false) :
		<div><i style={{fontSize: "1.5vh"}}>{`- ${translate(DICTIONARY.PRIMARY_EMAIL_FIELD_EMPTY.X)} -`}</i></div>
	const secondaryEmailContent = props.contactInfo.emails.secondary.address ?
		value(props.contactInfo.emails.secondary.address, secondaryEmailAlert, <></>, "2vh",
			"secondaryEmail_", false) :
		<div><i style={{fontSize: "1.5vh"}}>{`- ${props.isOrganization ?
			translate(DICTIONARY.SUPPORT_EMAIL_FIELD_EMPTY.X) : translate(DICTIONARY.RECOVERY_EMAIL_FIELD_EMPTY.X)} -`}</i></div>
	const primaryPhoneContent = props.contactInfo.phones.primary.nationalNumber ?
		value(props.contactInfo.phones.primary.formatted, primaryPhoneAlert, primaryPhoneLogin, "3vh",
			"primaryPhone_", true) :
		<div><i style={{fontSize: "1.5vh"}}>{`- ${translate(DICTIONARY.PRIMARY_PHONE_FIELD_EMPTY.X)} -`}</i></div>
	const secondaryPhoneContent = props.contactInfo.phones.secondary.nationalNumber ?
		value(props.contactInfo.phones.secondary.formatted, secondaryPhoneAlert, <></>, "2vh",
			"secondaryPhone_", true) :
		<div><i style={{fontSize: "1.5vh"}}>{`- ${props.isOrganization ?
			translate(DICTIONARY.SUPPORT_PHONE_FIELD_EMPTY.X) : translate(DICTIONARY.RECOVERY_PHONE_FIELD_EMPTY.X)} -`}</i></div>

	return <div className={"w3-animate-opacity"}>
		<div>
			<div>
				{primaryEmailContent}
				{props.isOrganization && !props.contactInfo.emails.secondary.address ? <></> : secondaryEmailContent}
			</div>
		</div>
		<div className={"w3-display-container"}>
			<div style={{paddingTop: "1vh"}}>
				{primaryPhoneContent}
				{props.isOrganization && !props.contactInfo.phones.secondary.nationalNumber ? <></> : secondaryPhoneContent}
			</div>
		</div>
		{props.websites ? <div className={"w3-display-container"}>
			<div style={{paddingTop: "1vh"}}>
				{props.websites.main || `- ${translate(DICTIONARY.WEBSITE_FIELD_EMPTY.X)} -`}
			</div>
		</div> : <></>}
		{props.contactInfo.vhf.primary ? <div className={"w3-display-container"}>
			<div style={{paddingTop: "1vh"}}>
				{`VHF ${props.contactInfo.vhf.primary}${props.contactInfo.vhf.secondary ? `& ${props.contactInfo.vhf.secondary}` : ``}` }
			</div>
		</div> : <></>}
	</div>
}

export default MobileContactInfoFields