import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {updateOrgSchedule} from "../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputWeek from "./MobileInputWeek"
import weekInputCapture from "./weekInputCapture"
import Schedule from "../../../globals/classes/shared/Schedule"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"

/**
 *
 * @param {Object} props
 * @param {Week} props.week
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {boolean} props.use24Hr
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputWeekEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		
		const week = weekInputCapture(props.id)
		// console.log(week)
		const keys = Object.keys(week)
		// console.log(keys)
		for (let i = 0; i < keys.length; i++) {
			if (week[keys[i]].isOpen && week[keys[i]].close.as24hrNumber === week[keys[i]].open.as24hrNumber) {
				newMessage(`${props.id}-error-message`, translate(DICTIONARY.INPUT_ERROR.X),
					translate(DICTIONARY.HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE.X)	+ translate(keys[i].toUpperCase()),
					"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)

				return
			}
		}
		
		const response = await updateOrgSchedule(new Schedule({week: week}))
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			if (props.onSubmit) {props.onSubmit(week)}
		}
	}
	const content = <MobileInputWeek week={props.week} id={props.id} form={props.form} className={props.className}
		use24Hr={props.use24Hr}/>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileInputWeekEdit