import React from "react"
import {ICONS} from "../../../globals/constants/client/constants"
import MobileParentContainer from "../../shared/frames/MobileParentContainer"
import MobileHeaderIcon from "../../shared/frames/MobileHeaderIcon"
import BottomQuote from "../../../globals/components/BottomQuote"
import MobileBackButton from "../MobileBackButton"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {JSX.Element} props.header
 * @param {JSX.Element|JSX.Element[]|JSX.Element[][]} props.content
 * @param {JSX.Element} props.aboveSubmit
 * @param {JSX.Element} props.back
 * @param {function} props.onSubmit
 * @param {string} props.encType
 * @param {boolean} props.disabled - true if submit button should be disabled
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginFormScaffolding = props => {
	// TODO: update the header to that in MobileLoginOrgAddress
	const encType = props.encType ? props.encType : "application/x-www-form-urlencoded"
	const onSubmit = props.onSubmit ? props.onSubmit : () => {}
	const aboveSubmit = props.aboveSubmit ? props.aboveSubmit : <></>
	const submit = props.onSubmit ?
		<div style={{width: "100%", textAlign: "center"}}>
			<MobileButtonGeneric left={{icon: ICONS.CHEVRON_CIRCLE_RIGHT}} type={"submit"} form={"form"}
				style={{marginTop: "1vh", fontSize: "6vh"}} disabled={props.disabled}/>
		</div> : <></>
	const container = props.onSubmit ?
		<form id={"form"} className={`w3-display-middle w3-animate-opacity ${props.className}`}
			style={{width: "100%", marginTop: props.header ? "6vh" : "0vh", ...props.style}} onSubmit={onSubmit} encType={encType}>
			<div className={"w3-center paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}
					 style={{margin: "0vh auto 0vh auto", maxHeight: props.header ? "46vh" : "52vh", padding: "0vh 4vh"}}>
				{props.content}
			</div>
			{aboveSubmit}
			{submit}
		</form> :
		<div id={"not-form"} className={`w3-display-middle w3-animate-opacity ${props.className}`}
			style={{width: "100%", marginTop: props.header ? "6vh" : "0vh", ...props.style}}>
			<div className={"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}
					 style={{margin: "0vh auto 0vh auto", maxHeight: props.header ? "46vh" : "52vh", padding: "0vh 4vh"}}>
				{props.content}
			</div>
			{aboveSubmit}
			{submit}
		</div>
	return <MobileParentContainer content={
		<>
			<MobileHeaderIcon/>
			{props.header}
			{container}
			{props.back ? <MobileBackButton window={props.back}/> : <></>}
			<BottomQuote/>
		</>
	}/>
}

export default MobileLoginFormScaffolding