import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {rateOrder} from "../../../../../globals/functions/client/serverFunctions"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileBusinessRater from "../../MobileBusinessRater"
/**
 *
 * @param {Object} props
 * @param {Organization} props.organization
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {boolean} props.order.isCustomer
 * @param {boolean} props.showRateModal
 * @param {function} props.setShowRateModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const RateOrderModal = props => {
	const order = props.order
	return props.showRateModal ? <Modal id={`order-${order.id}-rate-modal`} padding={"0vh"}
		title={translate(DICTIONARY.RATE_EXPERIENCE.X)} icon={ICONS.STAR} onClick={() => props.setShowRateModal(false)}
		body={<MobileBusinessRater onSubmit={async rating => {
		try {
			const response = await rateOrder(order.id, rating)
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.refreshOrders()
				fadingMessage("rate-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
				props.setShowRateModal(false)
			}
		} catch (e) {
			console.log(e)
		}
		// console.log("rate")
		}} isCustomer={order.isCustomer}/>}/> : <></>
}

export default RateOrderModal