import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputColor from "./MobileInputColor"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Color from "../../../globals/classes/shared/Color"
/**
 *
 * @param {Object} props
 * @param {Color} props.color
 * @param {string} props.subtitle
 * @param {function} props.onEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileColor = props => {
	const [edit, setEdit] = useState(false)
	const [color, setColor] = useState(new Color(props.color))
	useEffect(() => setColor(new Color(props.color)), [props.color])
	const onEdit = color_ => {
		if (props.refresh) {props.refresh().then()}
		setColor(color_)
		setEdit(false)
	}
	const label = translate(props.subtitle === "primary" ?
		DICTIONARY.PRIMARY_COLOR.X : DICTIONARY.SECONDARY_COLOR.X)
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} labels={[label]}/>
			{edit ? <MobileInputColor id={props.subtitle} color={color} style={{height: "6vh", borderRadius: "1vh"}}
				onSubmit={onEdit}/> :
				<div className={"w3-animate-opacity"} style={{backgroundColor: "white", padding: "1vh", borderRadius: "1vh"}}>
					<div style={{backgroundColor: color.asHex || "black", height: "6vh", borderRadius: "1vh"}}/></div>}
	</div>
}

export default MobileColor