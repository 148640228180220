import React, {useEffect, useState} from "react"
import ICONS from "../../../../globals/constants/client/icons"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {ADDRESS_TYPE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {addressAdd, getLatLon, updateOrgLocation} from "../../../../globals/functions/client/serverFunctions"
import {newMessage,	setToStorage,		translate} from "../../../../globals/functions/client/localFunctions"
import addressInputCapture from "../../../main/address/addressInputCapture"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
import MobileInputAddressFields from "../../../main/address/MobileInputAddressFields"
import Address from "../../../../globals/classes/shared/Address"
import Coordinates from "../../../../globals/classes/shared/Coordinates"
import State from "../../../../globals/classes/shared/State"
import Country from "../../../../globals/classes/shared/Country"

const MobileLoginOrgAddress = props => {
	const [search, setSearch] = useState({address: {city: "", state: "", country: ""}, attempt: 0})
	const type = props.type
	let title = translate(DICTIONARY.ADDRESS.X), icon = ICONS.MAP_MARKED_ALT
	switch (type) {
		case ADDRESS_TYPE.PHYSICAL:
			title = translate(DICTIONARY.PHYSICAL_ADDRESS.X)
			break
		case ADDRESS_TYPE.MAILING:
			title = translate(DICTIONARY.MAILING_ADDRESS.X)
			icon = ICONS.MAILBOX
			break
		case ADDRESS_TYPE.SUPPORT:
			title = translate(DICTIONARY.SUPPORT_ADDRESS.X)
			icon = ICONS.USER_HEADSET
			break
		default:
			break
	}
	useEffect(() => {
		if (type === ADDRESS_TYPE.PHYSICAL && search.attempt > 0 && search.attempt < 3) {
			getLatLon(search.address).then(response => {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					if (response.payload.length > 1) {setToStorage("addresses", response.payload, "session")}
					const location = response.payload[0].geometry.location
					const coordinates = new Coordinates({latitude: location.lat, longitude: location.lng})
					setToStorage("accurate", true, "session")
					setToStorage("coordinates_", coordinates, "session")
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
				} else {
					setSearch({address: search.address, attempt: search.attempt + 1})
				}
			})
		} else if (type === ADDRESS_TYPE.PHYSICAL && search.attempt === 3) {
			newMessage("address-issue", translate(DICTIONARY.MISMATCH.X), translate(DICTIONARY.ADDRESS_MISMATCH_MESSAGE.X),
				"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
			setSearch({address: search.address, attempt: 0})
		}
	}, [search, props.next, type])
	const onSubmit = async event => {
		event.preventDefault()
		const add = addressInputCapture(`${type}-address`)
		add.isPhysical = type === ADDRESS_TYPE.PHYSICAL
		add.isSupport = type === ADDRESS_TYPE.SUPPORT
		add.isMailing = type === ADDRESS_TYPE.MAILING
		const addressResponse = await addressAdd(add, true)
		// console.log(addressResponse)
		if (addressResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
			// confirm address and get coordinates
			if (type === ADDRESS_TYPE.PHYSICAL) {
				const location = {country: addressResponse.payload[0].country, state: addressResponse.payload[0].state}
				// console.log("location", location)
				setToStorage("location", location, "session")
				if (search.attempt === 0) {
					const searchAddress = {...add}
					if (searchAddress.state.name === "Puerto Rico") {
						searchAddress.state = new State()
						searchAddress.country = new Country({name: "Puerto Rico", code: "PRI", code2: "PR", parent: "USA"})
					}
					const latLonResponse = await getLatLon(searchAddress)
					if (latLonResponse) {
						if (latLonResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
							const location = latLonResponse.payload[0].geometry.location
							const coordinates = new Coordinates({latitude: location.lat, longitude: location.lng})
							setToStorage("accurate", true, "session")
							setToStorage("coordinates_", coordinates, "session")
							const coordinatesResponse = await updateOrgLocation(coordinates)
							if (coordinatesResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
								store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
							}
						} else {
							setToStorage("accurate", false, "session")
							setSearch({address: {city: add.city, state: add.state.name === "Puerto Rico" ? "" : add.state.name,
									country: add.state.name === "Puerto Rico" ? "Puerto Rico" : add.country.code},
								attempt: search.attempt + 1})
						}
					}
				} else {
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
				}
			} else {
				store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			}
		}
	}
	const content =
		<div className="w3-center" style={{fontSize: "3vh"}}>
			<MobileInputAddressFields id={`${type}-address`} form={"form"} showLabel={false} showLine3={true}
				address={new Address({country: {code: "USA"}, state: {code: "US-PR"}})}
				autoComplete={false} style={null} className={"paralian-theme-level-1-flipped"}/>
		</div>
	return <MobileLoginFormScaffolding header={<div className={"paralian-theme-level-1-flipped w3-display-topmiddle"}
		style={{fontSize: "2vh", marginTop: "17vh", width: "100%", textAlign: "center", padding: "2.5vh"}}>
			<MobileFontAwesome icon={icon} style={{paddingRight: "2vh", fontSize: "3vh"}}/>
			{title}
		</div>} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgAddress