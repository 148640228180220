import {PRICING_MODEL} from "../../../constants/shared/enumerators"
import {enumOrNull, typeOrNull} from "../../../functions/shared/local"
import Restriction from "./Restriction"
import Pricing from "./Pricing"
const TYPE = Object.freeze({GOODS: "GOODS", SERVICES: "SERVICES", FULL: "FULL"})
const RECORD_MODE = Object.freeze({increment: "increment", set: "set"})

class Product {
	/**
	 *
	 * @param {Product} [product = null]
	 * @param {boolean} [product.isMainProduct]
	 * @param {string} [product.name]
	 * @param {string} [product.type]
	 * @param {number} [product.levels]
	 * @param {string} [product.recordMode]
	 * @param {string} [product.stripeId]
	 * @param {Restriction} [product.restrictions]
	 * @param {Price[]} [product.pricing]
	 * @param {number} [product.free]
	 * @param {boolean} [product.isSubscription]
	 * @param {boolean} [product.isMetered]
	 * @param {number} [product.subscription]
	 * @param {string} [product.subscriptionItem]
	 * @param {string} [product.price]
	 * @param {number} [product.limit]
	 * @param {number} [product.quantity]
	 */
	constructor (product = null) {
		try {
			this.isMainProduct = !!product.isMainProduct
			this.name = typeOrNull(product.name, "string")
			this.type = enumOrNull(product.type, TYPE)
			this.levels = product.levels ? product.levels.map(v => typeOrNull(v, "string")) : []
			this.recordMode = enumOrNull(product.recordMode, RECORD_MODE)
			this.stripeId = typeOrNull(product.stripeId, "string")
			this.restrictions = new Restriction(product.restrictions)
			this.pricing = new Pricing(product.pricing)
			this.free = typeOrNull(product.free, "number")
			this.isSubscription = typeOrNull(product.isSubscription, "boolean")
			this.isMetered = typeOrNull(product.isMetered, "boolean")
			this.subscription = typeOrNull(product.subscription, "number")
			this.subscriptionItem = typeOrNull(product.subscriptionItem, "string")
			this.price = typeOrNull(product.price, "string")
			this.limit = typeOrNull(product.limit, "number")
			this.quantity = typeOrNull(product.quantity, "number")
		} catch (e) {
			this.isMainProduct = null
			this.name = null
			this.type = null
			this.levels = []
			this.recordMode = null
			this.stripeId = null
			this.restrictions = new Restriction()
			this.pricing = new Pricing()
			this.free = null
			this.isSubscription = null
			this.isMetered = null
			this.subscription = null
			this.subscriptionItem = null
			this.price = null
			this.limit = null
			this.quantity = null
		}
	}

	/**
	 *
	 * @param {Price} price
	 * @param {number} quantity
	 * @param {boolean} useBaseDenomination - true if requires results in base denomination (dollars for USD)
	 * @return {null|number}
	 */
	total(price, quantity, useBaseDenomination = true) {
		try {
			if (!price || !quantity) {return null}
			const model = price.model
			switch (model) {
				case PRICING_MODEL.STANDARD:
					return quantity * (useBaseDenomination ? price.unitPrice[0].currencyPerUnit : price.unitPrice[0].centsPerUnit)
				case PRICING_MODEL.PACKAGE:
					const packages = Math.ceil(quantity/price.unitPrice[0].packageSize)
					return packages * (useBaseDenomination ? price.unitPrice[0].currencyPerUnit : price.unitPrice[0].centsPerUnit)
				case PRICING_MODEL.GRADUATED:
					const layers = price.unitPrice.filter(unitPrice => unitPrice.firstUnit <= quantity)
					let total = 0
					for (let i = 0; i < layers.length; i++) {
						const layer = layers[i]
						const perUnit = useBaseDenomination ? layer.currencyPerUnit : layer.centsPerUnit
						const groupSize = layer.lastUnit === Infinity || i === layers.length - 1 ? quantity - layer.firstUnit : layer.lastUnit - layer.firstUnit
						total = total + perUnit * groupSize
					}
					return total
				case PRICING_MODEL.VOLUME:
					const unitPrice = price.unitPrice.find(unitPrice => unitPrice.firstUnit <= quantity && unitPrice.lastUnit >= quantity)
					return quantity * (useBaseDenomination ? unitPrice.currencyPerUnit : unitPrice.centsPerUnit)
				default:
					return null
			}
		} catch (e) {
			console.log(e)
			return null
		}
	}
}

export default Product