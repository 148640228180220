import { isObject, typeOrNull } from '../../functions/shared/local';

class Defaults {
	/**
	 *
	 * @param {Defaults} [defaults = null]
	 * @param {number} [defaults.vessel]
	 * @param {number} [defaults.marina]
	 */
	constructor(defaults = null) {
		if (isObject(defaults)) {
			this.vessel = typeOrNull(defaults.vessel, "number")
			this.marina = typeOrNull(defaults.marina, "number")
		} else {
			this.vessel = null
			this.marina = null
		}
	}
}

export default Defaults;