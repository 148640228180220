import React from "react"
import {addOrgEmployees} from "../../../globals/functions/client/serverFunctions"
import getEmployeeInput from "./getEmployeeInput"
import submitToServer from "../../../globals/functions/client/submitToServer"
import MobileInputNewEmployee from "./MobileInputNewEmployee"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {Servicio[]} props.services
 * @param {function} props.onSuccess
 * @param {function} props.onUndo
 * @returns {JSX.Element}
 * @constructor
 */
const MobileEmployeeAdd = props => {
	const id = props.id || "add"
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const input = getEmployeeInput({id: id, services: props.services})
			// console.log(input)
			await submitToServer(event, {employees: [input]}, addOrgEmployees, props.onSuccess)
		} catch (e) {
			console.log(e)
		}
	}
	const content = <MobileInputNewEmployee key={`${id}-new-employee`} id={id} services={props.services}
		className={"paralian-theme-level-1"}/>
	return <MobileInputForm id={id} content={content} className={"paralian-mobile-info-card"} onSubmit={onSubmit}/>
}

export default MobileEmployeeAdd