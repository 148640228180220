class TextInputFilter {
	minLength = 1
	maxLength = 128
	minVal = 0
	maxVal = 0
	regexp = /[^a-zA-ZÀ-ž0-9-!#%&'=_`}{~@$*+/?^)(|.]/gi
	/**
	 *
	 * @param {string|number|null} [input = null]
	 */
	constructor(input = null) {
		this.input_ = input !== null && input !== undefined ? `${input}` : null
	}

	/**
	 *
	 * @param {any} input
	 * @returns {boolean} - true if of correct type
	 */
	checkType(input) {
		return typeof input === "string" || typeof input === "number"
	}

	/**
	 *
	 * @param {string|number} input
	 * @returns {boolean} - true if within length range
	 */
	checkLength(input) {
		const stringInput = input ? `${input}` : null
		const length = stringInput ? stringInput.length : null
		return length !== null ? length >= this.minLength && length <= this.maxLength : false
	}

	/**
	 *
	 * @param {string|number} input
	 * @returns {boolean} - true if no unexpected characters are found
	 */
	checkCharacters(input) {
		return `${input}`.search(this.regexp) < 0
	}

	/**
	 *
	 * @param {any} input
	 * @returns {boolean} - true if of correct type, within length range, no unexpected characters are found
	 */
	checkInput(input) {
		return this.checkType(input) && this.checkLength(input) && this.checkCharacters(input)
	}

	get filtered() {
		const cleaned = this.input_ ? `${this.input_}`.replace(this.regexp, "") : null
		return this.checkInput(cleaned) ? cleaned : null
	}

	get conforms() {
		return this.checkInput(this.input_)
	}

	get text() {
		return this.input_
	}

	get conforming() {
		return this.conforms ? this.input_ : null
	}

	get isWithinRange() {
		const num = this.conforms ? Number(this.input_) : NaN
		return isNaN(num) ? false : num <= this.maxVal && num >= this.minVal
	}

	get number() {
		// return this.isWithinRange ? Number(this.text) : NaN
		return this.isWithinRange ? Number(this.input_) : null
	}
}

export default TextInputFilter;