import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputServiceAreaEdit from "./MobileInputServiceAreaEdit"
import MobileServiceAreaFields from "./MobileServiceAreaFields"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileInfoCardHeader from "../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {Area} props.area
 * @return {JSX.Element}
 * @constructor
 */
const MobileServiceArea = props => {
	const [area, setArea] = useState(props.area)
	const [edit, setEdit] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const label = translate(DICTIONARY.SERVICE_AREA.X)
	useEffect(() => {setArea(props.area)}, [props.area])
	/* TODO: work on service area edit */
	const content = edit && props.canEdit ? <MobileInputServiceAreaEdit area={area} setArea={setArea} onSubmit={() => {
		props.refresh().then()
		setEdit(false)
	}}/> :
		<MobileServiceAreaFields area={area}/>
	const card = expanded ? <div id={"service-area"} className={"w3-animate-opacity paralian-mobile-info-card"}
		style={{textAlign: "left", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}	labels={[label]}/>
		{content}
	</div> : <></>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top} text={label} onClick={() => setExpanded(!expanded)}/>
		{card}
	</>
}

export default MobileServiceArea