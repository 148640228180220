import Country from './Country'
import State from './State'
import {typeOrNull} from '../../functions/shared/local'

class Address {
	/**
	 * @param {Address} [address = null]
	 * @param {number} [address.id]
	 * @param {string} [address.label]
	 * @param {string} [address.line1]
	 * @param {string} [address.line2]
	 * @param {string} [address.line3]
	 * @param {string} [address.city]
	 * @param {string} [address.postCode]
	 * @param {Country} [address.country]
	 * @param {State} [address.state]
	 * @param {boolean} [address.isActive]
	 * @param {boolean} [address.isBilling]
	 * @param {boolean} [address.isMailing]
	 * @param {boolean} [address.isPhysical]
	 * @param {boolean} [address.isPrimary]
	 * @param {boolean} [address.isSupport]
	 * @param {boolean} [address.isVerified]
	 * @param {boolean} [address.display]
	 */
	constructor (address = null) {
		try {
			this.id = typeOrNull(address.id, "S/N")
			this.label = typeOrNull(address.label, "string")
			this.line1 = typeOrNull(address.line1, "string")
			this.line2 = typeOrNull(address.line2, "string")
			this.line3 = typeOrNull(address.line3, "string")
			this.city = typeOrNull(address.city, "string")
			this.postCode = typeOrNull(address.postCode, "S/N")
			this.country = new Country(address.country)
			this.state = new State(address.state)
			this.isActive = typeOrNull(address.isActive, "boolean")
			this.isBilling = typeOrNull(address.isBilling, "boolean")
			this.isMailing = typeOrNull(address.isMailing, "boolean")
			this.isPhysical = typeOrNull(address.isPhysical, "boolean")
			this.isPrimary = typeOrNull(address.isPrimary, "boolean")
			this.isSupport = typeOrNull(address.isSupport, "boolean")
			this.isVerified = typeOrNull(address.isVerified, "boolean")
			this.display = typeOrNull(address.display, "boolean")
		} catch (e) {
			this.id = null
			this.label = null
			this.line1 = null
			this.line2 = null
			this.line3 = null
			this.city = null
			this.postCode = null
			this.country = new Country()
			this.state = new State()
			this.isActive = null
			this.isBilling = null
			this.isMailing = null
			this.isPhysical = null
			this.isPrimary = null
			this.isSupport = null
			this.isVerified = null
			this.display = null
		}
	}
}

export default Address