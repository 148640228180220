import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileBrandModelYearHullEdit from "./MobileBrandModelYearHullEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.brand
 * @param {string} props.model
 * @param {string} props.year
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileBrandModelYearHull = props => {
	const [edit, setEdit] = useState(false) // use this to control toggles using edit button
	const [brand, setBrand] = useState(props.brand)
	const [model, setModel] = useState(props.model)
	const [year, setYear] = useState(props.year)
	useEffect(() => {
		setBrand(props.brand)
		setModel(props.model)
		setYear(props.year)
	}, [props.brand, props.model, props.year])
	const content = !edit ?
		<>
			<div style={{fontSize: "4vh"}}>{brand}</div>
			<div style={{fontSize: "3vh"}}>{model}</div>
			<div style={{fontSize: "2vh"}}>{year}</div>
		</> : <MobileBrandModelYearHullEdit id={props.id} brand={brand} model={model} year={year} onSuccess={bmy => {
			props.refresh().then()
			setBrand(bmy.brand)
			setModel(bmy.model)
			setYear(bmy.year)
			setEdit(false)
		}}/>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}	labels={[translate(DICTIONARY.HULL.X)]}/>
		{content}
	</div>
}

export default MobileBrandModelYearHull