import {typeOrNull} from '../../functions/shared/local'
import ItemId from "./textInputFilters/ItemId"
import FullName from "./textInputFilters/FullName"
import ISO3 from "./textInputFilters/ISO3"
class Tax {
	/**
	 *
	 * @param {Tax} [tax = null]
	 * @param {number} [tax.id]
	 * @param {string} [tax.country]
	 * @param {string} [tax.state]
	 * @param {string} [tax.city]
	 * @param {boolean} [tax.origin]
	 * @param {boolean} [tax.destination]
	 * @param {boolean} [tax.goods]
	 * @param {boolean} [tax.services]
	 * @param {boolean} [tax.b2c]
	 * @param {boolean} [tax.b2b]
	 * @param {string} [tax.display]
	 */
	constructor(tax = null) {
		try {
			this.id = new ItemId(tax.id).number
			this.country = new ISO3(tax.country).text
			this.state = new ISO3(tax.state).text
			this.city = new FullName(tax.city).text
			this.origin = typeOrNull(tax.origin, "boolean")
			this.destination = typeOrNull(tax.destination, "boolean")
			this.goods = typeOrNull(tax.goods, "boolean")
			this.services = typeOrNull(tax.services, "boolean")
			this.b2c = typeOrNull(tax.b2c, "boolean")
			this.b2b = typeOrNull(tax.b2b, "boolean")
			this.display = new FullName(tax.display).text
		} catch (e) {
			this.id = null
			this.country = null
			this.state = null
			this.city = null
			this.origin = null
			this.destination = null
			this.goods = null
			this.services = null
			this.b2c = null
			this.b2b = null
			this.display = null
		}
	}
}

export default Tax