import React from "react"
import {store} from "../../index"
import {actions} from "../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../main/MobileFontAwesome"

const MobileBackButton = props => {
	const onClick = props.onClick ? props.onClick : () => {
		store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.window}})
	}
	return <div className={`backButton w3-display-middle w3-center`}
		 style={{fontSize: "2vh", marginTop: "40vh", opacity: "0.5", height: "5vh",
			 padding: "1.5vh", zIndex: "2"}} onClick={onClick}>
		<MobileFontAwesome id={"back"} style={{paddingRight: "1vh"}} icon={ICONS.CHEVRON_CIRCLE_LEFT}/>
		<i>{props.text || translate(DICTIONARY.BACK.X)}</i>
	</div>
}

export default MobileBackButton