import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import Address from "../../../globals/classes/shared/Address"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Address[]} props.addresses
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Icon} props.left
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @returns {JSX.Element}
 * @constructor
 */
const MobileBillingAddressDropdown = props => {
	// console.log("form", props.form)
	const onChange = props.onChange ? props.onChange : () => {}
	const label = props.showLabel ? "" : `${translate(DICTIONARY.BILLING_ADDRESS.X)}: `
	const billing = []
	let list = []
	let other = {label: `${label}${translate(DICTIONARY.OTHER.X)}`, value: -1, dataId: -1}
	try {
		for (let i = 0; i < props.addresses.length; i++) {
			list[i] = {label: `${label}${props.addresses[i].label}`, value: i, dataId: props.addresses[i].id}
			if (props.addresses[i].isBilling)
			{
				billing.push({label: `${label}${props.addresses[i].label}`, value: i, dataId: props.addresses[i].id})
			}
		}
		if (billing.length > 0) {
			list = billing
		}
		list.push(other)
	} catch (e) {
		console.log(e)
	}
	const defaultValue = props.defaultValue ? list.find(item => item.dataId === props.defaultValue).value : list[0].value
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}`} className={props.className || "paralian-theme-level-1-flipped"}
		size={"1"} showLabel={props.showLabel} multiple={false} topRadius={props.topRadius} dataId={list[0].dataId}
		label={translate(DICTIONARY.BILLING_ADDRESS.X)} name={"billing-address"} form={props.form} autoFocus={false}
	 	required={true} list={list} left={props.left}	defaultValue={defaultValue} bottomRadius={props.bottomRadius}
		dataIndex={list[0].value} onChange={() => {
			const element = document.getElementById(`${props.id}-input`)
			if (`${element.value}` === "-1") {element.setAttribute("data-id", "-1")}
			else {element.setAttribute("data-id", list[element.value].dataId)}
			element.setAttribute("data-index", element.value)
			onChange(`${element.value}` === "-1" ? new Address({id: -1}) : new Address(props.addresses[element.value]))
	}}/>
}

export default MobileBillingAddressDropdown