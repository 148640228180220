import {typeOrNull } from '../../functions/shared/local'

class Services {
	/**
	 *
	 * @param {Services} [services = null]
	 * @param {Servicio[]} [services.parents]
	 * @param {Servicio[]} [services.singles]
	 */
	constructor(services = null) {
		try {
			this.level = typeOrNull(services.level, "number")
			this.parents = Array.isArray(services.parents) ? services.parents.map(parent => {
				// const parent_ = new Service(parent)
				// parent_.level = this.level + 1
				// return parent_
				parent.level = this.level + 1
				return parent
			}) : []
			this.singles = Array.isArray(services.singles) ? services.singles.map(single => {
				// const single_ = new Service(single)
				// single_.level = this.level + 1
				// return single_
				single.level = this.level + 1
				return single
			}) : []
		} catch (e) {
			this.level = null
			this.parents = []
			this.singles = []
		}
	}
}

export default Services