import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {getFromStorage,	replaceInStorage,	translate} from "../../../../globals/functions/client/localFunctions"
import Modal from "../../../../globals/components/Modal"
import RadioButton from "../cleaning/RadioButton"
import MobileGenericDropdown from "../../../shared/input/dropdown/MobileGenericDropdown"
import MobileInputContactInfo from "../../contact/MobileInputContactInfo"

const ConciergeContact = props => {
	/* TODO: translate */
	/* TODO: if none selected, disable */
	const session = getFromStorage("order", "session")
	const primaryEmailVerified = props.account.contact.contactInfo.emails.primary.verified
	const secondaryEmailVerified = props.account.contact.contactInfo.emails.secondary.verified
	const primaryPhoneVerified = props.account.contact.contactInfo.phones.primary.verified
	const secondaryPhoneVerified = props.account.contact.contactInfo.phones.secondary.verified
	const primaryEmail = props.account.contact.contactInfo.emails.primary.address
	const secondaryEmail = props.account.contact.contactInfo.emails.secondary.address
	const primaryPhone = props.account.contact.contactInfo.phones.primary.formatted
	const secondaryPhone = props.account.contact.contactInfo.phones.secondary.formatted
	const [selected, setSelected] = useState({
		method: primaryEmailVerified ? "email" : primaryPhoneVerified ? "message" : null,
		address: primaryEmailVerified ? "primaryEmail" : primaryPhoneVerified ? "primaryPhone" : null})
	const [showAddNew, setShowAddNew] = useState(false)
	// console.log(selected)
	useEffect(() => {
		if (session.contact && session.contact.length > 0 && session.contact.method && session.contact.address) {
			setSelected({method: session.contact.method, address: session.contact.address})
		} else {
			let address_, method_
			if (primaryEmailVerified) {
				address_ = "primaryEmail"
				method_ = "email"
			} else if (primaryPhoneVerified) {
				address_ = "primaryPhone"
				method_ = "message"
			} else if (secondaryEmailVerified) {
				address_ = "secondaryEmail"
				method_ = "email"
			} else if (secondaryPhoneVerified) {
				address_ = "secondaryPhone"
				method_ = "message"
			}
			if (!address_) {
				props.setDisabled(true)
			} else {
				props.setDisabled(false)
			}
			replaceInStorage("order", "contact", {address: address_, method: method_}, "session")
			setSelected({method: method_, address: address_})
		}
	}, [props.account])
	const onClick = v => {
		const selected_ = {...selected}
		selected_.address = v
		if (v === "primaryEmail" || v === "secondaryEmail") {selected_.method = "email"}
		else if (selected_.method === "email") {selected_.method = "message"}
		replaceInStorage("order", "contact", selected_, "session")
		setSelected(selected_)
	}
	const verify = showAddNew ? <Modal id={`${props.id}-addNew`} title={<>
		<div>{translate(DICTIONARY.CONTACT_INFO.X)}</div>
		<div>{translate(DICTIONARY.EDIT_VERIFY.X)}</div>
	</>}
		icon={ICONS.USER_HEADSET} padding={"0vh"} body={<div style={{margin: "2vh", padding: "2vh"}}
			className={"paralian-theme-level-2"}>
			<MobileInputContactInfo contactInfo={props.account.contact.contactInfo}
				refresh={props.refreshAccount} type={props.type} isOrganization={props.isOrganization}/>
		</div>}
		onClick={() => setShowAddNew(false)}/> : <></>
	const onClickDisabled = () => {setShowAddNew(true)}
	return <>
		{verify}
		<fieldset style={{margin: "1vh 0vh"}}>
			<legend style={{padding: "0vh 1vh 1vh", fontSize: "3vh"}}>
				{translate(DICTIONARY.PREFERRED_CONTACT_METHOD.X)}
			</legend>
			<RadioButton id={"primaryEmail"} title={translate(DICTIONARY.PRIMARY_EMAIL.X)} onClick={onClick}
				selected={selected.address === "primaryEmail"} subtitle={primaryEmail} onClickDisabled={onClickDisabled}
				defaultChecked={selected.address === "primaryEmail"} name={"engineFlush"} disabled={!primaryEmailVerified}/>
			<RadioButton id={"secondaryEmail"} title={translate(DICTIONARY.ALTERNATE_EMAIL.X)} onClick={onClick}
				selected={selected.address === "secondaryEmail"} subtitle={secondaryEmail} onClickDisabled={onClickDisabled}
				name={"engineFlush"} defaultChecked={selected.address === "secondaryEmail"} disabled={!secondaryEmailVerified}/>
			<RadioButton id={"primaryPhone"} title={translate(DICTIONARY.PRIMARY_PHONE.X)} onClick={onClick}
				selected={selected.address === "primaryPhone"} subtitle={primaryPhone} onClickDisabled={onClickDisabled}
				defaultChecked={selected.address === "primaryPhone"} name={"engineFlush"} disabled={!primaryPhoneVerified}/>
			<RadioButton id={"secondaryPhone"} title={translate(DICTIONARY.ALTERNATE_PHONE_NUMBER.X)} onClick={onClick}
				selected={selected.address === "secondaryPhone"} subtitle={secondaryPhone} onClickDisabled={onClickDisabled}
				name={"engineFlush"} defaultChecked={selected.address === "secondaryPhone"} disabled={!secondaryPhoneVerified}/>
			{selected.address === "primaryPhone" || selected.address === "secondaryPhone" ?
				<div style={{padding: "4vh 4vh 2vh"}}>
					<MobileGenericDropdown id={"contact-method"} showLabel={true} options={[{id: "message",
						label: translate(DICTIONARY.MESSAGE_VERB.X)},	{id: "call", label: translate(DICTIONARY.CALL_VERB.X)}]}
						className={"paralian-theme-level-2"} defaultValue={selected.method === "call" ? 1 : 0}
						label={translate(DICTIONARY.METHOD.X)} onChange={id => {
							const selected_ = {...selected}
							selected_.method = id
							replaceInStorage("order", "contact", selected_, "session")
							setSelected(selected_)
					}}/>
				</div> : <></>}
	</fieldset>
	</>
}

export default ConciergeContact