import Statistics from "./Statistics"
import PositiveNumber from "../textInputFilters/PositiveNumber"
class RatingStatistics {
	/**
	 *
	 * @param {RatingStatistics} [rating = null]
	 * @param {number} [rating.overall]
	 * @param {number} [rating.service]
	 * @param {number} [rating.quality]
	 * @param {number} [rating.value]
	 * @param {number} [rating.reviews]
	 */
	constructor (rating = null) {
		try {
			this.overall = new Statistics(rating.overall)
			this.service = new Statistics(rating.service)
			this.quality = new Statistics(rating.quality)
			this.value = new Statistics(rating.value)
			this.reviews = new PositiveNumber(rating.reviews).number
		} catch (e) {
			this.overall = new Statistics()
			this.service = new Statistics()
			this.quality = new Statistics()
			this.value = new Statistics()
			this.reviews = null
		}
	}
}

export default RatingStatistics