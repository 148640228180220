import React from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {deleteVesselCallSign, deleteVesselDocumentationNumber, deleteVesselHIN, deleteVesselIMO, deleteVesselMMSI,
	deleteVesselRegistrationNumber, setVesselCallSign, setVesselDocumentationNumber, setVesselIMO, setVesselMMSI,
	setVesselRegistrationNumber} from "../../../../globals/functions/client/serverFunctions"
import MobileSingleFieldToggle from "./MobileSingleFieldToggle"
import MobileVesselHIN from "../hull/MobileVesselHIN"

/**
 *
 * @param {Object} props
 * @param {Vessel} props.vessel
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselIdentifiersFields = props => {
	let registrationFlag, documentationFlag
	if (props.vessel.documents.vesselRegistrations.length > 0) {
		if (props.vessel.registration && (props.vessel.registration !== props.vessel.documents.vesselRegistrations[0].registration)) {
			registrationFlag = {
				id: "registration-flag-message",
				title: translate(DICTIONARY.MISMATCH.X),
				text: translate(DICTIONARY.REGISTRATION_MISMATCH_MESSAGE.X),
				color: "w3-pale-yellow",
				icon: ICONS.EXCLAMATION_TRIANGLE
			}
		}
	} else if (props.canEdit) {
		registrationFlag = {
			id: "registration-flag-message",
			title: translate(DICTIONARY.REGISTRATION_DOCUMENT_NOT_PROVIDED.X),
			color: "w3-pale-yellow",
			icon: ICONS.MINUS_CIRCLE
		}
	}
	if (props.vessel.documents.vesselDocumentations.length > 0) {
		if (props.vessel.documentation && (props.vessel.documentation !== props.vessel.documents.vesselDocumentations[0].documentation)) {
			documentationFlag = {
				id: "documentation-flag-message",
				title: translate(DICTIONARY.MISMATCH.X),
				text: translate(DICTIONARY.OFFICIAL_NUMBER_MISMATCH_MESSAGE.X),
				color: "w3-pale-yellow",
				icon: ICONS.EXCLAMATION_TRIANGLE
			}
		}
	} else if (props.canEdit) {
		documentationFlag = {
			id: "documentation-flag-message",
			title: translate(DICTIONARY.DOCUMENTATION_DOCUMENT_NOT_PROVIDED.X),
			color: "w3-pale-yellow",
			icon: ICONS.MINUS_CIRCLE
		}
	}
	const onSuccess = () => {
		props.refresh().then()
	}
	/* TODO: check data in AIS matches other identifiers by calling marinetraffic.com or aishub.net */
	/* TODO: check data in documentation matches other identifiers by calling marinetitle.com */
	/* TODO: use identifier-specific input elements */
	const registration = <MobileSingleFieldToggle label={translate(DICTIONARY.REGISTRATION_NUMBER.X)}
		flag={registrationFlag} canEdit={props.canEdit} onSuccess={onSuccess}
		onSubmit={async () => {
			const number = document.getElementById(`${props.vessel.id}-registration-input`).value
			return await setVesselRegistrationNumber(props.vessel.id, number)
		}}
		onDelete={async () => {
			try {
				const response = await deleteVesselRegistrationNumber(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				}
			} catch (e) {
				console.log(e)
			}
		}}
		invalidMessage={translate(DICTIONARY.REGISTRATION_NUMBER_DESCRIPTION.X)}
		field={{
			id: `${props.vessel.id}-registration`,
			text: props.vessel.registration,
			maxLength: "8",
			pattern: "([A-Z0-9]){8,8}",
			placeholder: "AA1234BB"
		}}/>
	const documentation = <MobileSingleFieldToggle label={translate(DICTIONARY.DOCUMENTATION_NUMBER.X)}
		flag={documentationFlag} canEdit={props.canEdit} onSuccess={onSuccess}
		onSubmit={async () => {
			const number = document.getElementById(`${props.vessel.id}-documentation-input`).value
			return await setVesselDocumentationNumber(props.vessel.id, number)
		}}
		onDelete={async () => {
			try {
				const response = await deleteVesselDocumentationNumber(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				}
			} catch (e) {
				console.log(e)
			}
		}}
		invalidMessage={translate(DICTIONARY.OFFICIAL_NUMBER_DESCRIPTION.X)}
		field={{
			id: `${props.vessel.id}-documentation`,
			text: props.vessel.documentation,
			maxLength: "7",
			pattern: "([0-9]){6,7}",
			placeholder: "1234567"
		}}/>
	const mmsi = <MobileSingleFieldToggle label={translate(DICTIONARY.MMSI_NUMBER.X)} canEdit={props.canEdit}
		onSubmit={async () => {
			const number = document.getElementById(`${props.vessel.id}-mmsi-input`).value
			return await setVesselMMSI(props.vessel.id, number)
		}}
		onDelete={async () => {
			try {
				const response = await deleteVesselMMSI(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				}
			} catch (e) {
				console.log(e)
			}
		}}
		onSuccess={onSuccess}
		invalidMessage={translate(DICTIONARY.MMSI_NUMBER_DESCRIPTION.X)}
		field={{
			id: `${props.vessel.id}-mmsi`,
			text: props.vessel.ais.mmsi,
			maxLength: "9",
			pattern: "([0-9]){9,9}",
			placeholder: "123456789"
		}}/>
	const imo = <MobileSingleFieldToggle label={translate(DICTIONARY.IMO_NUMBER.X)} canEdit={props.canEdit}
		onSubmit={async () => {
			const number = document.getElementById(`${props.vessel.id}-imo-input`).value
			return await setVesselIMO(props.vessel.id, number)
		}}
		onDelete={async () => {
			try {
				const response = await deleteVesselIMO(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				}
			} catch (e) {
				console.log(e)
			}
		}}
		onSuccess={onSuccess}
		invalidMessage={translate(DICTIONARY.IMO_NUMBER_DESCRIPTION.X)}
		field={{
			id: `${props.vessel.id}-imo`,
			text: props.vessel.imo,
			maxLength: "7",
			pattern: "([0-9]){7,7}",
			placeholder: "1234567"
		}}/>
	const callSign = <MobileSingleFieldToggle label={translate(DICTIONARY.CALL_SIGN.X)} canEdit={props.canEdit}
		onSubmit={async () => {
			const number = document.getElementById(`${props.vessel.id}-callSign-input`).value
			return await setVesselCallSign(props.vessel.id, number)
		}}
		onDelete={async () => {
			try {
				const response = await deleteVesselCallSign(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				}
			} catch (e) {
				console.log(e)
			}
		}}
		onSuccess={onSuccess}
		invalidMessage={translate(DICTIONARY.CALL_SIGN_DESCRIPTION.X)}
		field={{
			id: `${props.vessel.id}-callSign`,
			text: props.vessel.callSign,
			maxLength: "7",
			pattern: "([A-Z0-9]){3,7}",
			placeholder: "KX0983"
		}}/>
	return <>
		{registration}
		{documentation}
		<MobileVesselHIN id={props.vessel.id} hin={props.vessel.hull.HIN} canEdit={props.canEdit} refresh={props.refresh}
			onDelete={async () => {
			try {
				const response = await deleteVesselHIN(props.vessel.id)
				// console.log(response)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				}
			} catch (e) {
				console.log(e)
			}
			}}/>
		{mmsi}
		{imo}
		{callSign}</>
}

export default MobileVesselIdentifiersFields