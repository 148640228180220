import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {Address} props.address
 * @param {boolean} props.isOrganization
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAddressType = props => {
	const primary = props.address.isPrimary || false
	const mailing = props.address.isMailing || false
	const physical = props.address.isPhysical || false
	const billing = props.address.isBilling || false
	const support = props.address.isSupport || false
	const display = props.address.display || false
	const divStyle = {height: "3vh", margin: "1vh 0vw"}
	const sliderPadding = "0.25vh 0vh 0.25vh 2vh"
	const sliderHeight = "3vh"
	const sliderWidth = "8vh"
	const sliderClassName = "w3-display-left"
	const physical_ = <div key={`${props.id}-isPhysical`} className={"w3-display-container"}
			style={{...divStyle, display: props.isOrganization && physical ? "none" : "block"}}>
		<MobileInputSlider id={`${props.id}-isPhysical`} label={translate(DICTIONARY.PHYSICAL_ADDRESS.X)} value={true}
			padding={sliderPadding}	height={sliderHeight} width={sliderWidth} className={sliderClassName}
			defaultChecked={physical} onChange={() => {}}/>
	</div>
	// TODO: prevent slider from moving and show message if address is physical and belongs to organization
	const content = [
		<div key={`${props.id}-isMailing`} className={"w3-display-container"} style={divStyle}>
			<MobileInputSlider id={`${props.id}-isMailing`} label={translate(DICTIONARY.MAILING_ADDRESS.X)} value={true}
				padding={sliderPadding}	height={sliderHeight} width={sliderWidth} className={sliderClassName}
				defaultChecked={mailing} onChange={() => {}}/>
		</div>, physical_,
		<div key={`${props.id}-isBilling`} className={"w3-display-container"} style={divStyle}>
			<MobileInputSlider id={`${props.id}-isBilling`} label={translate(DICTIONARY.BILLING_ADDRESS.X)} value={true}
				padding={sliderPadding}	height={sliderHeight} width={sliderWidth} className={sliderClassName}
				defaultChecked={billing} onChange={() => {}}/>
		</div>
	]
	if (props.isOrganization) {
		content.splice(0, 0, <div key={`${props.id}-show`} className={"w3-display-container"} style={divStyle}>
			<MobileInputSlider id={`${props.id}-show`} label={translate(DICTIONARY.SHARE_WITH_CUSTOMERS.X)} value={true}
				padding={sliderPadding}	height={sliderHeight} width={sliderWidth} className={sliderClassName}
				defaultChecked={display} onChange={() => {}}/></div>)
		content.push(<div key={`${props.id}-isSupport`} className={"w3-display-container"} style={divStyle}>
			<MobileInputSlider id={`${props.id}-isSupport`} label={translate(DICTIONARY.SUPPORT_ADDRESS.X)} value={true}
				padding={sliderPadding}	height={sliderHeight} width={sliderWidth} className={sliderClassName}
				defaultChecked={support} onChange={() => {}}/></div>)
	} else {
		content.splice(0, 0, <div key={`${props.id}-isPrimary`} className={"w3-display-container"} style={divStyle}>
			<MobileInputSlider id={`${props.id}-isPrimary`} label={translate(DICTIONARY.PRIMARY_ADDRESS.X)} value={true}
				padding={sliderPadding}	height={sliderHeight} width={sliderWidth} className={sliderClassName}
				defaultChecked={primary} onChange={() => {}}/></div>)
	}
	return <div style={{width: "100%", fontSize: "2vh", paddingLeft: "2vh"}}>{content}</div>
}

export default MobileInputAddressType