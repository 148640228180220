import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileTimezoneEdit from "./MobileTimezoneEdit"
import MobileInfoCardHeader from "../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {string} [props.country = "USA"] - ISO3166 3-letter code
 * @param {Timezone} props.timezone
 * @return {JSX.Element}
 * @constructor
 */
const MobileTimezone = props => {
	const [edit, setEdit] = useState(false)
	const [timezone, setTimezone] = useState(props.timezone)
	useEffect(() => {setTimezone(props.timezone)}, [props.timezone])
	const onSubmit = timezone => {
		props.refresh().then()
		setTimezone(timezone)
		setEdit(false)
	}
	const content = edit ? <MobileTimezoneEdit id={"tz"} timezone={timezone} onSubmit={onSubmit} country={props.country}/> :
		<div className={"w3-animate-opacity"}>{timezone.asString}</div>
	return <div className={"paralian-mobile-info-card"} style={{textAlign: "center", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.TIME_ZONE.X)]}/>
		{content}
	</div>
}

export default MobileTimezone