import React, {useEffect, useState} from "react"
import {ICONS} from "../../../globals/constants/client/constants"
import {getFromStorage} from "../../../globals/functions/client/localFunctions"
import selectLoginAccount from "./selectLoginAccount"
import MobileZoomScrollImages from "../../shared/input/scroll/MobileZoomScrollImages"
import MobileFontAwesome from "../../main/MobileFontAwesome"

const MobileLoginRoleSelection = () => {
	/* TODO: move to cache */
	const [showScroll, setShowScroll] = useState(false)
	const accounts = getFromStorage("accounts", "session")
	useEffect(() => {
		const accounts_ = getFromStorage("accounts", "session")
		if (accounts_.length === 1) {
			selectLoginAccount(accounts_[0].id).then()
		}
	}, [])
	let items = []
	for (let i = 0; i < accounts.length; i++) {
		items[i] = {
			id: accounts[i].username,
			onClick: (() => {
				selectLoginAccount(accounts[i].id).then()
			}),
			image: accounts[i].image || `${process.env.REACT_APP_IMAGES_PATH}/blank.png`,
			active: true,
			text: accounts[i].organization,
			initialConditions: {
				opacity: `${0.989989 / Math.pow(1.5, i)}vh`,
				imageSize: `${32.5 / Math.pow(1.5, i)}vh`,
				textFontSize: `${3.5 / Math.pow(1.5, i)}vh`,
				textWidth: `${20 / Math.pow(1.5, i)}vh`
			}
		}
	}

	return <>
		<div className={"w3-display-middle"} style={{height: "100vh", width: "100vw"}}>
			<MobileZoomScrollImages items={items} displayId={true} selection={"single"} scroll={5} setShowScroll={setShowScroll}/>
		</div>
		{showScroll ? <div className={"w3-display-bottommiddle"} style={{marginBottom: "22.5vh", fontSize: "5vh", opacity: "0.6"}}>
			<MobileFontAwesome icon={ICONS.SCROLL}/>
		</div> : <></>}
	</>
}

export default MobileLoginRoleSelection