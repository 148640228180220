import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileContactInfoFields from "../../../contact/MobileContactInfoFields"
import Modal from "../../../../../globals/components/Modal"
/**
 *
 * @param {Object} props
 * @param {Organization} props.organization
 * @param {Object} props.order
 * @param {Account} props.order.provider
 * @param {Name} props.order.provider.name
 * @param {ContactInfo} props.contactInfo
 * @param {Websites} props.websites
 * @param {boolean} props.showDisputeModal
 * @param {function} props.setShowDisputeModal
 * @return {JSX.Element}
 * @constructor
 */
const DisputeOrderModal = props => {
	return props.showDisputeModal ? <Modal id={"dispute-modal"} padding={"0vh"} icon={ICONS.EXCLAMATION_TRIANGLE}
		title={translate(DICTIONARY.DISPUTE.X)} onClick={() => props.setShowDisputeModal(false)}
		body={<>
			<div className={"paralian-theme-level-1"} style={{padding: "1vh"}}>
				<div>
					<div>
						{translate(DICTIONARY.DISPUTES_MESSAGE_A.X)}
					</div>
					<div className={"paralian-theme-level-2"} style={{padding: "1vh", margin: "2vh 1vh", borderRadius: "1vh"}}>
						<div>
							{props.order.provider.name.display}
						</div>
						<MobileContactInfoFields contactInfo={props.contactInfo} websites={props.websites} isOrganization={true}/>
					</div>
					<div style={{fontSize: "2vh", padding: "1vh"}}>
						{translate(DICTIONARY.DISPUTES_MESSAGE_B.X)}
					</div>
				</div>
			</div>
			</>}/> : <></>
}

export default DisputeOrderModal