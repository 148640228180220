import {store} from "../../../../index"
import {actions} from "../../../constants/client/reduxData"
import {setToStorage} from "../localFunctions"
import getMode from "./getMode"

/**
 *
 * @param {("PERSONAL"|"EMPLOYEE"|"ORGANIZATION")} type - account type
 * @param {boolean} [refresh = true] - set to false if only the localStorage is to be updated as to avoid a re-render
 */
const setAccountType = (type, refresh = true) => {
	try {
		const mode = getMode()
		if (mode.account !== type) {
			mode.account = type || mode.account
			setToStorage("mode", mode)
		}
	} catch (e) {
		console.log(e)
	} finally {
		if (refresh && (store.getState().account.type !== type)) {
			store.dispatch({type: actions.SET_ACCOUNT_TYPE, values: {type: type}})
		}
	}
}

export default setAccountType