import { isObject, typeOrNull } from '../../functions/shared/local';

class Email {
	/**
	 *
	 * @param {Email} [email = null]
	 * @param {string} [email.address]
	 * @param {boolean} [email.verified]
	 * @param {boolean} [email.login]
	 */
	constructor (email = null) {
		if (isObject(email)) {
			this.address_ = this.filterAddress(email.address || email.address_)
			this.verified = typeOrNull(email.verified, "boolean")
			this.login = typeOrNull(email.login, "boolean")
		} else {
			this.address_ = null
			this.verified = null
			this.login = null
		}
	}

	get address() {
		return this.address_
	}

	set address(address) {
		this.address_ = this.filterAddress(address)
	}

	filterAddress(address) {
		if (address && typeof address === "string") {
			const at = address.search("@")
			if (at <= 0) {this.address_ = null} // if @ character is missing or at start
			const lengthAndSymbols = address.match(/[a-zA-Z0-9-!#$%&'*+/=?^_`{|}~@.]{6,120}/) // permitted symbols and length
			return lengthAndSymbols[0] !== address ? null : address
		} else {
			return null
		}
	}
}

export default Email;