import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {vesselAccessGrant} from "../../../../globals/functions/client/serverFunctions"
import MobileVesselAccessInputFields from "./MobileVesselAccessInputFields"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputUsername from "../../../shared/input/text/MobileInputUsername"
import MobileInputID from "../../../shared/input/text/MobileInputID"
import DataAccess from "../../../../globals/classes/shared/DataAccess"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {function} props.onAdd
 * @constructor
 */
const MobileVesselAccessAdd = props => {
	/* TODO: find a way to provide access to accounts/organizations interchangeably */
	/* TODO: use QR code to fill the organization ID/username field */
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const input = document.getElementById(props.toOrganization ?
				"organization-id-whole-input" : "username-input").value /* TODO: email/phone/username/id */
			const owner = document.getElementById(`${props.id}-owner`).getAttribute("data-a") === "true"
			let ok = true, submitted = false, response
			if (owner) {
				response = await vesselAccessGrant(props.id, "ownership", null,
					props.toOrganization ? null : input, props.toOrganization ? input : null)
				submitted = true
				if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
			} else {
				const daViewDocuments = document.getElementById(`${props.id}-da-viewDocuments`).getAttribute("data-a")
				const daViewProfile = document.getElementById(`${props.id}-da-viewProfile`).getAttribute("data-a")
				const paMoveWithinPremises = document.getElementById(`${props.id}-pa-moveWithinPremises`).getAttribute("data-a")
				const paLeavePremises = document.getElementById(`${props.id}-pa-leavePremises`).getAttribute("data-a")
				const paBoard = document.getElementById(`${props.id}-pa-board`).getAttribute("data-a")
				const paService = document.getElementById(`${props.id}-pa-service`).getAttribute("data-a")
				const orders = document.getElementById(`${props.id}-oa-place`).getAttribute("data-a")
				const physical = []
				const order = orders === "true" ? ["PLACE"] : []
				const data = new DataAccess({
					fullProfile: daViewDocuments === "true" ? ["VIEW"] : [],
					limitedProfile: daViewProfile === "true" ? ["VIEW"] : []}
				)
				if (paMoveWithinPremises === "true") {physical.push("MOVE")}
				if (paLeavePremises === "true") {physical.push("LEAVE")}
				if (paBoard === "true") {physical.push("BOARD")}
				if (paService === "true") {physical.push("SERVICE")}
				if (order.length > 0) {
					response = await vesselAccessGrant(props.id, "orders", order, input)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (data.fullProfile.length > 0 || data.limitedProfile.length > 0) {
					response = await vesselAccessGrant(props.id, "data", data, input)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (physical.length > 0) {
					response = await vesselAccessGrant(props.id, "physical", physical, input)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
			}
			// users are not allowed to modify or delete access to organizations directly, they must cancel the order
			// this is why the account type is always "individuals"
			if (!ok) {
				try {
					newMessage("vessel-access-grant-fail", translate(DICTIONARY.ERROR_GRANTING_ACCESS.X),
						response.payload.message)
				} catch (e) {
					console.log(e)
				}
			}
			if (ok && submitted) {
				props.onAdd(response.payload)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <>
		{
			props.toOrganization ?
				<MobileInputID className={"paralian-theme-level-1"} placeholder={translate(DICTIONARY.ORGANIZATION_ID.X)}
					label={translate(DICTIONARY.ORGANIZATION_ID.X)}	defaultValue={null} required={true} showLabel={false}
					form={`${props.id}-access-add-form`} topRadius={"0vh"} name={"id"} id={"organization-id"}
					digits={11} decimals={0} autoFocus={true} bottomRadius={"0vh"} defaultLong={null}/> :
				<MobileInputUsername className={"paralian-theme-level-1"}	form={`${props.id}-access-add-form`} required={true}
					autoFocus={true} autoComplete={"off"}/>
		}
		<MobileVesselAccessInputFields id={props.id} toOrganization={props.toOrganization}/>
	</>
	return <MobileInputForm id={`${props.id}-access-add`} content={content} onSubmit={onSubmit}/>
}

export default MobileVesselAccessAdd