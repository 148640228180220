import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileTimeField from "./MobileTimeField"
import Time from "../../../globals/classes/shared/Time"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Time} props.open
 * @param {Time} props.close
 * @param {boolean} props.use24Hr
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputHours = props => {
	const [open, setOpen] = useState(props.open || new Time())
	const [close, setClose] = useState(props.close || new Time())
	const className = props.className || "paralian-theme-level-1-flipped"
	const warn = close.as24hrNumber <= open.as24hrNumber
	return <div style={{display: "inline-flex", width: "100%"}}>
		<div style={{marginRight: "0.5vh", width: "50%"}}>
			<div style={{padding: "1vh", fontSize: "2.5vh"}}>{translate(DICTIONARY.OPEN.X)}</div>
			<MobileTimeField id={`${props.id}-open`} form={props.form} time={open} setTime={setOpen}
				className={`${className} w3-animate-opacity`} style={{fontSize: "3vh", height: "12vh"}}
				use24Hr={props.use24Hr}/>
		</div>
		<div style={{marginLeft: "0.5vh", width: "50%"}}>
			<div style={{padding: "1vh", fontSize: "2.5vh"}}>
				{translate(DICTIONARY.CLOSE.X)}
				{warn ?
					<span style={{color: "red"}} onClick={() => newMessage("hour-overlap-warn",
						translate(DICTIONARY.OVERNIGHT_HOURS.X))}>
						{"*"}
					</span> : <></>}
			</div>
			<MobileTimeField id={`${props.id}-close`} form={props.form} time={close} setTime={setClose}
				className={`${className} w3-animate-opacity`} style={{fontSize: "3vh", height: "12vh"}}
				use24Hr={props.use24Hr}/>
		</div>
	</div>
}

export default MobileInputHours