import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.required {boolean}
 * @param props.topRadius {string}
 * @param props.bottomRadius {string}
 * @param props.showLabel {boolean}
 * @param props.defaultValue {number}
 * @param props.onChange {function} - function
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputPercent = props => {
	/* TODO: look into why label had "- Whole" added to it */
	return <div style={{display: "inline-flex", width: "100%"}}>
		<MobileInputGeneric type={"long"} id={`${props.id}-whole`} name={`${props.name}-whole`} form={props.form}
			autoFocus={props.autoFocus} maxLength={"2"} autoComplete={"off"} pattern={"([0-9]){1,2}"} width={"100%"}
			required={props.required} placeholder={"##"} min={"0"} max={"99"}	step={"1"} className={props.className}
			defaultValue={`${props.defaultValue}`} label={props.label} bottomRadius={props.bottomRadius} units={"% "}
			showLabel={props.showLabel}	style={{textAlign: "center", ...props.style}}	topRadius={props.topRadius}
			onChange={() => {
				try {
					const value = document.getElementById(`${props.id}-whole-input`).value
					props.onChange(value)
				} catch (e) {
					console.log(e)
				}
			}}/>
	</div>
}

export default MobileInputPercent