import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {DAY_DATE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getTimeFormat, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {updateOrgHolidays} from "../../../globals/functions/client/serverFunctions"
import MobileInputDay from "./MobileInputDay"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Time from "../../../globals/classes/shared/Time"
import Holiday from "../../../globals/classes/shared/Holiday"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.name
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Day} props.day
 * @param {Holiday} props.holiday
 * @param {boolean} props.use24Hr
 * @param {function} props.onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputHolidayEdit = props => {
	const onSubmit = async event => {
		try {
			event.preventDefault()
			const getState = id => {
				return document.getElementById(`${id}-state`).getAttribute("data-state")
			}
			const getHoursOfOperation = (id, action) => {
				const state = document.getElementById(`${id}-state`).getAttribute("data-state")
				if (state === "open") {
					// console.log(getTimeFormat())
					let use24Hr = getTimeFormat() !== "xm"
					// console.log(use24Hr)
					try {
						use24Hr = document.getElementById(`time-format`).getAttribute("data-is-checked")
					} catch (e) {
						console.log(e)
					}
					// console.log(use24Hr)
					const hour = document.getElementById(`${id}-${action}-hour-input`).value
					const minute = document.getElementById(`${id}-${action}-minute-input`).value
					const xm = use24Hr ? null : document.getElementById(`${id}-${action}-format-input`).value
					return new Time({hour: hour, minute: minute, xm: xm})
				} else if (state === "closed") {
					return new Time({hour: 0, minute: 0, xm: null})
				} else {
					return action === "open" ? new Time({hour: 0, minute: 0, xm: null}) : new Time({hour: 23, minute: 59, xm: null})
				}
			}
			const getRecurrence = id => {
				const id_ = props.holiday.id
				const isCustom = props.holiday.isCustom
				let recurrence = null, name = null, dateMonth = null, dateDate = null, dayOrdinal = null
				let dayDay = null, dayMonth = null
				try {
					recurrence = document.getElementById(`${id}-new-recurrence`).getAttribute("data-recurrence")
					name = document.getElementById(`${id}-new-name-input`).value
					dateMonth = document.getElementById(`${id}-new-date-month-input`).getAttribute("data-a")
					dateDate = document.getElementById(`${id}-new-date-date-input`).getAttribute("data-a")
					dayOrdinal = document.getElementById(`${id}-new-day-ordinal-input`).getAttribute("data-a")
					dayDay = document.getElementById(`${id}-new-day-day-input`).getAttribute("data-a")
					dayMonth = document.getElementById(`${id}-new-day-month-input`).getAttribute("data-a")
					// console.log("recurrence", recurrence, "dateDate", dateDate, "dayOrdinal", dayOrdinal)
				} catch (e) {
					console.log(e)
				}
				return new Holiday({id: id_,	recurrence: recurrence,	name: name, isCustom: isCustom,
					date: recurrence === DAY_DATE.DATE ? dateDate : dayOrdinal,
					month: recurrence === DAY_DATE.DATE ? dateMonth : dayMonth, weekday: dayDay, day: null})
				// {id: _holiday,	type: type,	name: name,	dateMonth: dateMonth,	dateDate: dateDate,	dayOrdinal: dayOrdinal,
				// 				dayDay: dayDay,	dayMonth: dayMonth}
			}
			const holiday_ = getRecurrence(props.id)
			// console.log(holiday_)
			holiday_.day = {
				isOpen: getState(props.id) !== "closed",
				open: getHoursOfOperation(props.id, "open"),
				close: getHoursOfOperation(props.id, "close")
			}
			if (holiday_.day.close.as24hrNumber < holiday_.day.open.as24hrNumber) {
				newMessage("hooError", translate(DICTIONARY.INPUT_ERROR.X),
					translate(DICTIONARY.HOLIDAY_HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE.X), "w3-pale-yellow",
					ICONS.EXCLAMATION_TRIANGLE)
			}
			const response = await updateOrgHolidays([holiday_])
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				if (props.onSubmit) {
					props.onSubmit(holiday_)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <MobileInputDay id={props.id} form={`holiday-${props.id}`} holiday={props.holiday}
		use24Hr={props.use24Hr} left={null} className={props.className} disableHolidayDropdown={true}/>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileInputHolidayEdit