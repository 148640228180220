import React, {useCallback, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {toggleInArray} from "../../../../globals/functions/shared/local"
import Modal from "../../../../globals/components/Modal"
import MobileButtonGeneric from "./MobileButtonGeneric"
import MobileSelectSingleOrMultiple from "./MobileSelectSingleOrMultiple"

/**
 *
 * @param {Object} props
 * @param {number[][][]} props.constraints
 * @param {IndexedArray} props.indexedArray - of {id: number, label: string, constraints: Constraints}
 * @param {number} props.version
 * @param {number[]} props.selected
 * @param {Object} props.action
 * @param {number} props.action.id
 * @param {boolean} props.action.wasSelected
 * @param {function} props.setSelected
 * @param {function} props.setShowModal
 * @param {function} props.setAction
 * @return {JSX.Element}
 * @constructor
 */
const MobileMultipleInputSelectModal = props => {
	const [added, setAdded] = useState([])
	const [removed, setRemoved] = useState([])
	const setAdded_ = useCallback((v, x) => {
		const newAdded = toggleInArray(added, v, {addOnly: true, removeOnly: false, exclude: x})
		// console.log("newAdded", newAdded)
		setAdded(newAdded)
		}, [added, setAdded]
	)
	const setRemoved_ = useCallback(v => setRemoved(toggleInArray(removed, v,
		{addOnly: true, removeOnly: false})), [removed, setRemoved])
	const toAdd = props.constraints[0]
	const toRemove = props.constraints[1]
	// console.log("toAdd", toAdd)
	// console.log("toRemove", toRemove)
	// console.log("added", added)
	// console.log("removed", removed)
	const id = `multiple-select-modal-${props.version}`
	const includes = toAdd.map((constraint, index) =>
		<MobileSelectSingleOrMultiple key={`add-${index}`} id={`add-${index}`} indexedArray={props.indexedArray}
			options={constraint} setSelected={setAdded_} title={translate(DICTIONARY.ADD.X)}/>)
	const excludes = toRemove.map((constraint, index) =>
		<MobileSelectSingleOrMultiple key={`remove-${index}`} id={`remove-${index}`} indexedArray={props.indexedArray}
			options={constraint} setSelected={setRemoved_} title={translate(DICTIONARY.DELETE.X)}/>)
	const body = <div>
		<div style={{padding: "1vh 0vh"}}>
			{translate(DICTIONARY.SELECTIONS_REQUIRE_CHANGES.X)}
		</div>
		{includes}
		{excludes}
	</div>
	const buttons = <>
		<MobileButtonGeneric type={"button"} text={{style: {paddingLeft: "0vh"},
			content: translate(DICTIONARY.APPLY_SELECTION.X)}} className={"paralian-theme-level-1-flipped"} padding={"1vh"}
			style={{width: "100%"}} disabled={toAdd.length + toRemove.length !== added.length + removed.length} onClick={() => {
			try {
				/*
				const toAdd = props.constraints[selected_][0]
				const toRemove = props.constraints[selected_][1]
				if (props.version === 2 && props.action.id !== null) {
					props.action.wasSelected ? toRemove.push(props.action.id) : toAdd.push(props.action.id)
				}
				*/
				props.setSelected(Array.from(new Set([...props.selected, ...added])).filter(value => !removed.includes(value)))
				props.setShowModal(false)
			} catch (e) {
				console.log(e)
			}
		}}/>
		{props.version === 1 ? <MobileButtonGeneric type={"button"} text={{
				style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.RESET_ALL_SELECTIONS.X)}} padding={"1vh"}
				className={"paralian-theme-level-1-flipped"} style={{width: "100%", marginTop: "1vh"}} onClick={() => {
					try {
						props.setSelected([])
						props.setShowModal(false)
					} catch (e) {
						console.log(e)
					}
				}
			}/> :
			<MobileButtonGeneric type={"button"} text={{style: {paddingLeft: "0vh"},
				content: translate(DICTIONARY.CANCEL_SELECTION.X)}} className={"paralian-theme-level-1-flipped"} padding={"1vh"}
				style={{width: "100%", marginTop: "1vh"}} onClick={() => {
				try {
					props.setShowModal(false)
				} catch (e) {
					console.log(e)
				}
			}}/>}
	</>
	return <Modal id={id} title={translate(DICTIONARY.INPUT_REQUIRED.X)} icon={ICONS.QUESTION_CIRCLE} body={body}
		onClick={() => props.setShowModal(false)} buttons={buttons}/>
}

export default MobileMultipleInputSelectModal