import TextInputFilter from './TextInputFilter';
/**
 *
 * @param {string} state
 * @returns {Promise<Object[]>}
 */
class ItemId extends TextInputFilter {
	minLength = 1
	maxLength = 11
	regexp = /[^0-9]/gi
	minVal = 1
	maxVal = 99999999999
}

export default ItemId;