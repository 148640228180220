import {DOCUMENT_CONTAINER} from "../../../globals/constants/shared/enumerators"

const documentContainerToSubject = container => {
	let subject
	switch (container) {
		case DOCUMENT_CONTAINER.ORGANIZATIONS:
			subject = "ORGANIZATION"
			break
		case DOCUMENT_CONTAINER.ACCOUNTS:
			subject = "INDIVIDUAL"
			break
		case DOCUMENT_CONTAINER.VESSELS:
			subject = "VESSEL"
			break
	}
	return subject
}

export default documentContainerToSubject