import PositiveNumber from "../textInputFilters/PositiveNumber"

class Rating {
	/**
	 *
	 * @param {Rating} [rating = null]
	 * @param {number} [rating.overall]
	 * @param {number} [rating.service]
	 * @param {number} [rating.quality]
	 * @param {number} [rating.value]
	 * @param {number} [rating.reviews]
	 * @param {number} [rating.responseTime]
	 * @param {number} [rating.acceptanceRate] - percentage of orders accepted
	 * @param {number} [rating.organizationCancelRate] - percentage of orders cancelled by organization
	 * @param {number} [rating.requesterCancelRate] - percentage of orders cancelled by requester
	 * @param {number} [rating.priceAdjustment] - usual price adjustment on order. ideal would be zero
	 */
	constructor (rating = null) {
		try {
			this.overall = new PositiveNumber(rating.overall).number
			this.service = new PositiveNumber(rating.service).number
			this.quality = new PositiveNumber(rating.quality).number
			this.value = new PositiveNumber(rating.value).number
			this.reviews = new PositiveNumber(rating.reviews).number
		} catch (e) {
			this.overall = null
			this.service = null
			this.quality = null
			this.value = null
			this.reviews = null
		}
	}
}

export default Rating