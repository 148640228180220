import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getFromStorage} from "../../../globals/functions/client/localFunctions"
import {updateOrgServiceArea, updateOrgWelcomesVisitors} from "../../../globals/functions/client/serverFunctions"
import MobileInputServiceArea from "./MobileInputServiceArea"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Area from "../../../globals/classes/shared/Area"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {Area} props.area
 * @param {function} props.setArea
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputServiceAreaEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		
		let _country = null, _countryName = null, _state = null, _stateName = null
		try {
			_country = document.getElementById(`${props.id}-country-input`).getAttribute("data-id")
		} catch (e) {
			console.log(e)
		}
		try {
			_state = document.getElementById(`${props.id}-state-input`).getAttribute("data-id")
		} catch (e) {
			console.log(e)
		}
		const units = document.getElementById(`${props.id}-units-input`).value
		const radius = document.getElementById(`${props.id}-distance-input`).value.replace(",","")
		const response = await updateOrgServiceArea(units === "miles" ? Number(radius)*1.60934 :
			Number(radius), _country, _state)
		
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			const wv = await updateOrgWelcomesVisitors(getFromStorage("canVisit"))
			if (wv.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.setArea(new Area({radius: radius, country: {name: _countryName}, state: {name: _stateName}}))
				props.onSubmit()
			}
		}
	}
	const content = <MobileInputServiceArea id={props.id} form={`${props.id}-form`} defaultLimits={true}
		setArea={props.setArea} className={"paralian-theme-level-1"} area={props.area}/>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileInputServiceAreaEdit