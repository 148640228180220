import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
// TODO: expand to include top and bottom icons

/**
 *
 * @param {Object} props
 * @param {string} props.id - used only when button is of type "button"
 * @param {string} props.type - submit, button, reset
 * @param {React.HTMLAttributes.className} props.className - applies to the contents of the button container
 * @param {React.HTMLAttributes.style} props.style - applies to the contents of the button container
 * @param {string} props.padding
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string[]} props.left.icon
 * @param {string} props.left.label
 * @param {Object} props.left.style
 * @param {string} props.left.className
 * @param {Object} props.right
 * @param {string[]} props.right.icon
 * @param {string} props.right.label
 * @param {Object} props.right.style
 * @param {string} props.right.className
 * @param {Object} props.text
 * @param {Object} props.text.style
 * @param {string} props.text.content
 * @param {boolean} props.disabled
 * @param {function} props.onClick
 * @param {function} props.onClickDisabled
 * @returns {JSX.Element}
 * @constructor
 */
const MobileButtonGeneric = props => {
	const width = props.left && props.right ? "80%" : props.left || props.right ? "90%" : "100%"
	const text = props.text ? <span style={{...props.text.style, width: width, marginTop: "auto", marginBottom: "auto",
		overflowWrap: "break-word"}}>
		{props.text.content}
	</span> : ""
	const label = props.text ? props.text.content : translate(DICTIONARY.SUBMIT.X)
	const left = props.left ? <MobileFontAwesome id={"submit-left"} icon={props.left.icon} label={props.left.label}
		style={{...props.left.style, width: "10%", margin: "auto"}} className={props.left.className}/> : <></>
	const right = props.right ?
		(props.right.icon === null ? <div style={{minWidth: "10%"}}>{""}</div> :
			<MobileFontAwesome id={"submit-right"} icon={props.right.icon} label={props.right.label}
		style={{...props.right.style, width: "10%", margin: "auto"}} className={props.right.className}/>) : <></>
	let type, id
	if (props.type) {
		switch (props.type) {
			case "submit":
				type = "submit"
				id = "submit"
				break
			case "reset":
				type = "reset"
				id = "reset"
				break
			default:
				type = "button"
				id = props.id
		}
	}
	const cursor = !props.disabled ? "pointer" : null
	const opacity = !props.disabled ? null : "0.5"
	const ripple = !props.disabled ? " w3-ripple" : ""
	return <button id={id} form={props.form} type={type} style={{border: "unset", background: "inherit", color: "inherit",
		padding: props.padding || "unset", cursor: cursor, opacity: opacity,
		borderTopLeftRadius: props.borderRadius || props.borderTopRadius || "1vh",
		borderTopRightRadius: props.borderRadius  || props.borderTopRadius || "1vh",
		borderBottomLeftRadius: props.borderRadius  || props.borderBottomRadius || "1vh",
		borderBottomRightRadius: props.borderRadius  || props.borderBottomRadius || "1vh",
		display: props.text && (props.left || props.right) ? "inline-flex" : null, ...props.style}}
		disabled={props.disabled === true && !props.onClickDisabled}
		className={`${props.className}${ripple}`} onClick={props.disabled ? props.onClickDisabled : props.onClick}>
		<label htmlFor={id} style={{display: "none"}}>{label}</label>
		{left}{text}{right}
	</button>
}

export default MobileButtonGeneric