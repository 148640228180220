import React from "react"
import {DICTIONARY, IMAGES} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {setHullDimensions} from "../../../../globals/functions/client/serverFunctions"
import {parsedLengthToMeters, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputDistance from "../MobileInputDistance"
import MobileHorizontalPictureAndText from "../../MobileHorizontalPictureAndText"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {React.HTMLProps.className} props.className
 * @param {HullDimensions} props.dimensions
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullDimensionsEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		let lengthFraction = 0, beamFraction = 0, draughtFraction = 0, heightFraction = 0
		const lengthWhole = Number(document.getElementById("hull-loa-whole-input").value)
		try {
			lengthFraction = Number(document.getElementById("hull-loa-fraction-input").value)
		} catch (e) {}
		const beamWhole = Number(document.getElementById("hull-beam-whole-input").value)
		try {
			beamFraction = Number(document.getElementById("hull-beam-fraction-input").value)
		} catch (e) {}
		const draughtWhole = Number(document.getElementById("hull-draught-whole-input").value)
		try {
			draughtFraction = Number(document.getElementById("hull-draught-fraction-input").value)
		} catch (e) {}
		const heightWhole = Number(document.getElementById("hull-height-whole-input").value)
		try {
			heightFraction = Number(document.getElementById("hull-height-fraction-input").value)
		} catch (e) {}
		// console.log(parsedLengthToMeters(lengthWhole, lengthFraction, props.units))
		const length = props.units === "SI" ? lengthWhole : parsedLengthToMeters(lengthWhole, lengthFraction, props.units)
		const beam = props.units === "SI" ? beamWhole : parsedLengthToMeters(beamWhole, beamFraction, props.units)
		const draught = props.units === "SI" ? draughtWhole : parsedLengthToMeters(draughtWhole, draughtFraction, props.units)
		const clearance = props.units === "SI" ? heightWhole : parsedLengthToMeters(heightWhole, heightFraction, props.units)
		// console.log(lengthWhole, lengthFraction, beamWhole, beamFraction, draughtWhole, draughtFraction, heightWhole, heightFraction, props.units)
		// console.log(props.id, length, beam, draught, clearance)
		const response = await setHullDimensions(props.id, length, beam, draught, clearance)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess({length: length, beam: beam, draught: draught, clearance: clearance})
		}
	}
	const form = "hull-dimensions-form"
	const length = <MobileInputDistance id={"hull-loa"} label={translate(DICTIONARY.HULL_LENGTH_OVER_ALL.X)}
		value={props.dimensions.length} units={props.units} form={form}/>
	const beam = <MobileInputDistance id={"hull-beam"} label={translate(DICTIONARY.BEAM.X)} value={props.dimensions.beam}
		units={props.units} form={form}/>
	const draught = <MobileInputDistance id={"hull-draught"} label={translate(DICTIONARY.DRAUGHT.X)}
		value={props.dimensions.draught} units={props.units} form={form}/>
	const clearance = <MobileInputDistance id={"hull-height"} label={translate(DICTIONARY.CLEARANCE.X)}
		value={props.dimensions.clearance} units={props.units} form={form}/>
	const content = <>
		<MobileHorizontalPictureAndText imagePath={IMAGES.HULL_LOA} label={translate(DICTIONARY.HULL_LENGTH_OVER_ALL.X)}
			content={length} className={props.className}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.BEAM} label={translate(DICTIONARY.BEAM.X)} content={beam}
			className={props.className}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.DRAUGHT} label={translate(DICTIONARY.DRAUGHT.X)} content={draught}
			className={props.className}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.HULL_CLEARANCE} label={translate(DICTIONARY.CLEARANCE.X)} content={clearance}
			className={props.className}/>
	</>
	return <MobileInputForm id={"hull-dimensions"} content={content} className={" "} disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileVesselHullDimensionsEdit