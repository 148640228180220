class IndexedArray {
	/**
	 * @param {IndexedArray} array
	 * @param {Object[]|number[]} [array.array] - array set (no duplicate values for key - or number)
	 * @param {string|null} [array.key]
	 * @param {Object} [array.obj]
	 */
	constructor(array = null) {
		try {
			this.obj = array.obj ? array.obj : {}
			if (!array.obj) {
				for (let i = 0; i < array.array.length; i++) {
					const currentValue = array.key ? array.array[i][array.key] : array.array[i]
					if (!this.obj[`${currentValue}`]) {
						this.obj[`${currentValue}`] = array.array[i]
					}
				}
			}
		} catch (e) {
			this.obj = {}
		}
	}

	get values() {
		try {
			return Object.values(this.obj)
		} catch (e) {
			return []
		}
	}

	get keys() {
		try {
			return Object.keys(this.obj)
		} catch (e) {
			return []
		}
	}

	get keysAsNumber() {
		try {
			return this.keys.map(key => Number(key))
		} catch (e) {
			return []
		}
	}

	get isEmpty() {
		return this.keys.length === 0
	}

	byIndex(index) {
		try {
			return this.obj[`${index}`]
		} catch (e) {
			return undefined
		}
	}
}

export default IndexedArray