import React from "react"
import {store} from "../../index"
import {actions} from "../../globals/constants/client/reduxData"
import {ICONS, DICTIONARY} from "../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {getFromStorage, translate} from "../../globals/functions/client/localFunctions"
import {updateOrgServiceArea, updateOrgWelcomesVisitors} from "../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "./frames/MobileLoginFormScaffolding"
import MobileFontAwesome from "../main/MobileFontAwesome"
import MobileInputServiceArea from "../main/service/MobileInputServiceArea"
import Area from "../../globals/classes/shared/Area"

const MobileLoginServiceArea = props => {
	const location = getFromStorage("location", "session")
	// console.log("location", location)
	// console.log({country: {code: location.country}, state: {code: location.state}})
	// const area = new Area({country: {code: location.country}, state: {code: location.state}})
	const area = new Area({country: location.country, state: location.state})
	// console.log("area", area)
	const id = "login-service-area"
	const onSubmit = async event => {
		event.preventDefault()
		let _country = null, _state = null
		try {
			_country = document.getElementById(`${id}-country-input`).getAttribute("data-id")
		} catch (e) {
			console.log(e)
		}
		try {
			_state = document.getElementById(`${id}-state-input`).getAttribute("data-id")
		} catch (e) {
			console.log(e)
		}
		const units = document.getElementById(`${id}-units-input`).value
		const radius = document.getElementById(`${id}-distance-input`).value.replace(",","")
		const response = await updateOrgServiceArea(units === "miles" ? Number(radius)*1.60934 :
			Number(radius), _country, _state)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			// console.log("canVisit", getFromStorage("canVisit", "session"), typeof getFromStorage("canVisit", "session"))
			const wv = await updateOrgWelcomesVisitors(getFromStorage("canVisit", "session") === "true")
			if (wv.status === SERVER_RESPONSE_STATE.SUCCESS) {
				sessionStorage.removeItem("canVisit")
				store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			}
		}
	}
	const content = <div className="w3-center" style={{fontSize: "3vh"}}>
		<MobileInputServiceArea id={id} form={"form"} area={area}	className={"paralian-theme-level-1-flipped"}
			defaultLimits={false}/>
	</div>
	return <MobileLoginFormScaffolding header={<div className={"paralian-theme-level-1-flipped w3-display-topmiddle"}
		style={{fontSize: "2vh", marginTop: "17vh", width: "100%", textAlign: "center", padding: "2.5vh"}}>
		<MobileFontAwesome icon={ICONS.GLOBE_AMERICAS} style={{paddingRight: "2vh", fontSize: "3vh"}}/>
		{translate(DICTIONARY.SERVICE_AREA.X)}</div>} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginServiceArea