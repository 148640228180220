import DICTIONARY from '../../constants/shared/dictionary';
import { DAY_DATE, MONTH, WEEKDAY, ORDINAL } from '../../constants/shared/enumerators';
import { numberToOrdinal, translate } from '../../functions/shared/local';
import { isObject, typeOrNull, enumOrNull } from '../../functions/shared/local';
import Day from './Day';
/* TODO: privatize with properties_ with  when supported */

class Holiday {
	/**
	 *
	 * @param {Holiday} [holiday = null]
	 * @param {Day} [holiday.day]
	 * @param {number} [holiday.id]
	 * @param {string} [holiday.name]
	 * @param {string} [holiday.recurrence] - RECURRENCE
	 * @param {number} [holiday.date]
	 * @param {string} [holiday.month] - MONTH
	 * @param {string} [holiday.weekday] - WEEKDAY
	 * @param {boolean} [holiday.isCustom]
	 */
	constructor (holiday = null) {
		if (isObject(holiday)) {
			this.id = typeOrNull(holiday.id, "number")
			this.name = typeOrNull(holiday.name, "string")
			this.recurrence_ = enumOrNull(holiday.recurrence || holiday.recurrence_, DAY_DATE)
			this.date = typeOrNull(holiday.date, "number")
			this.month_ = enumOrNull(holiday.month || holiday.month_, MONTH)
			this.weekday_ = enumOrNull(holiday.weekday || holiday.weekday_, WEEKDAY)
			this.day_ = new Day(holiday.day || holiday.day_)
			this.isCustom = typeOrNull(holiday.isCustom, "boolean")
		} else {
			this.id = null
			this.name = null
			this.recurrence_ = null
			this.date = null
			this.month_ = null
			this.weekday_ = null
			this.day_ = new Day()
			this.isCustom = null
		}
	}

	get day() {
		return this.day_
	}

	set day(day) {
		this.day_ = new Day(day)
	}

	get recurrence(){
		return this.recurrence_
	}

	set recurrence(recurrence) {
		this.recurrence_ = enumOrNull(recurrence, DAY_DATE)
	}

	get month(){
		return this.month_
	}

	set month(month) {
		this.month_ = enumOrNull(month, MONTH)
	}

	get weekday(){
		return this.weekday_
	}

	set weekday(weekday) {
		this.weekday_ = enumOrNull(weekday, WEEKDAY)
	}

	/**
	 *
	 * @return {string}
	 */
	get asRecurrenceString() {
		if (this.recurrence === DAY_DATE.DATE && this.month !== null && this.date !== null) {
			return `${translate(this.month)} ${this.date}`
		}
		if (this.recurrence === DAY_DATE.DAY && this.weekday !== null && this.month !== null && this.date > -2 && this.date < 4) {
			return `${numberToOrdinal(this.date)} ${translate(this.weekday)} ${translate(DICTIONARY.OF.X)} ${translate(this.month)}`
		}
		return ""
	}

	/**
	 * @return {string}
	 */
	get ordinalAsString() {
		if (this.date > -1 && this.date < 4) {
			return ORDINAL[this.date]
		}
		return ""
	}
}

export default Holiday;