import React, {useEffect, useRef, useState} from "react"
import {DOCUMENT_CONTAINER,	DOCUMENT_TYPE, IMAGE_CATEGORY} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {getUnits, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import ImageUpload from "../../../tools/ImageUpload"
import MobileProfileImage from "../MobileProfileImage"
import MobileBrandModelYearHull from "./hull/MobileBrandModelYearHull"
import MobileVesselIdentifiers from "./identifiers/MobileVesselIdentifiers"
import MobileHullSpecifications from "./hull/MobileHullSpecifications"
import MobileVesselStorageSpecs from "./storage/MobileVesselStorageSpecs"
import MobileEngines from "./engines/MobileEngines"
import MobileRig from "./rig/MobileRig"
import MobileDocuments from "../documents/MobileDocuments"
import MobileVesselAccess from "./access/MobileVesselAccess"
import Modal from "../../../globals/components/Modal"
import MobileCustomerVesselState from "./customer/MobileCustomerVesselState"
import Vessel from "../../../globals/classes/shared/Vessel"
import imagePlaceholder from "../../../images/ship-duotone.svg"
import Account from "../../../globals/classes/shared/Account"
import Organization from "../../../globals/classes/shared/Organization"
/**
 *
 * @param {Object} props
 * @param {Vessel} props.vessel
 * @param {Account} props.account
 * @param {Organization} props.organization
 * @param {Organization[]} props.marinas
 * @param {function} props.refreshVessels
 * @param {function} props.refreshAccount
 * @return {JSX.Element}
 * @constructor
 */
const MobileVessel = props => {
	/* TODO: profile X% complete */
	/* TODO: add tanks with levels */
	/* TODO: flash sections which are incomplete to prompt user to provide the data */
	/* TODO: add isVerified functions */
	/* TODO: add list of facilities, fuel, bath, hotel, pump-out facilities icons */
	/* TODO: unlink vessel */
	/* TODO: add LOCATION banner with pin on map with last known location, location source and timestamp along with MMSI */
	/* TODO: vessel control section where vessel may be deleted */
	/* TODO: vessel ownership verification requests - title, registration, documentation, MMSI */
	/* TODO: vessel QR code */
	/* TODO: Vessel Access - ORGANIZATIONS */
	const [vessel, setVessel] = useState(props.vessel || new Vessel())
	const [uploadImage, setUploadImage] = useState(false)
	const [showCustomerStatusModal, setShowCustomerStatusModal] = useState(false)
	useEffect(() => setVessel(props.vessel || new Vessel()), [props.vessel])
	const account = props.account || new Account()
	const organization = props.organization || new Organization()
	const marinas = props.marinas || []
	const refreshVessels = props.refreshVessels
	const storageRef = useRef(null)
	const titleRef = useRef(null)
	const units = getUnits(account)
	const top = "4.4vh"
	const confirmedAccountOwners = vessel.access.individuals.filter(v => v.ownership.response === "CONFIRM").map(v => v.id)
	const confirmedOrganizationOwners = vessel.access.organizations.filter(v => v.ownership.response === "CONFIRM").map(v => v.id)
	const hasConfirmedOwners = [...confirmedAccountOwners, ...confirmedOrganizationOwners].length > 0
	const accountIsOwner = confirmedAccountOwners.includes(account.id)
	const organizationIsOwner = confirmedOrganizationOwners.includes(organization.id)
	const isAdmin = account.isAdmin
	/* TODO: use DATA_ACCESS to delve deeper into EDIT access */
	const canEdit = !hasConfirmedOwners || accountIsOwner || (organizationIsOwner && isAdmin)
	const upload = uploadImage ? <ImageUpload id={vessel.id} show={setUploadImage} type={IMAGE_CATEGORY.VESSEL_PROFILE}
		refresh={refreshVessels}/> : <></>
	const registration = vessel.registration || vessel.documentation || vessel.imo || vessel.hull.HIN || ""
	const customerStatusModal = !showCustomerStatusModal ? <></> :
		<Modal id={"customer-status"} title={translate(DICTIONARY.SET_CUSTOMER_VESSEL_STATE.X)} padding={"0vh"}
			onClick={() => setShowCustomerStatusModal(false)} body={<MobileCustomerVesselState state={vessel.customer}
			vessel={vessel.id} refresh={refreshVessels} onSuccess={() => {
			refreshVessels()
			setShowCustomerStatusModal(false)
		}}/>}/>
	// console.log(vessel)
	const profile = <>
		{/* Profile Image */}
		<MobileProfileImage image={vessel.image.path ? vessel.image : null} id={vessel.id} placeholder={imagePlaceholder}
			upload={{include: canEdit, hide: true, onClick: () => setUploadImage(!uploadImage)}}/>
		{/* Name */}
		<div style={{fontSize: "4vh"}} translate={"no"}>
			{vessel.name.display}
		</div>
		{/* Registration/Documentation */}
		<div style={{zIndex: "20", padding: "1vh 0vw"}} className={"sticky paralian-theme-level-1"}>
			<i translate={"no"}>{registration ||  "..."}</i>
		</div>
		{/* Icons */}
		<div style={{fontSize: "3vh", display: "inline-flex"}}>
			{
				vessel.customer && organization.id && !organizationIsOwner ?
					<MobileFontAwesome icon={vessel.customer === "TEMPORARY" ? ICONS.SHIELD_SLASH : ICONS.SHIELD}
						label={translate(DICTIONARY.TEMPORARY.X)}
						style={{padding: "0vh 1vh"}} onClick={() => setShowCustomerStatusModal(true)}/> : <></>
			}
			<MobileFontAwesome icon={ICONS.ANCHOR} style={{padding: "0vh 1vh"}} onClick={() => {
				document.getElementById("vessels-container").scrollTo(0, storageRef.current.offsetTop)
			}}/>
			<MobileFontAwesome icon={ICONS.ID_CARD} style={{padding: "0vh 1vh"}} onClick={() => {
				document.getElementById("vessels-container").scrollTo(0, titleRef.current.offsetTop)
			}}/>
			<MobileFontAwesome icon={["fas", ICONS.SHIELD_CHECK[1]]} style={{fontSize: "2vh", marginLeft: "-2vh"}}/>
			<MobileFontAwesome icon={ICONS.SYNC} style={{padding: "0vh 1vh"}}
				onClick={() => refreshVessels().then()}/>
			{/*<MobileFontAwesome icon={ICONS.COMPASS} style={{padding: "0vh 1vh"}}/>*/}
		</div>
		{/* Hull - Brand, Model Year */}
		<MobileBrandModelYearHull id={vessel.id} brand={vessel.hull.brand} model={vessel.hull.model}
			year={vessel.hull.year} refresh={refreshVessels} canEdit={canEdit}/>
		{/* Identifiers */}
		<MobileVesselIdentifiers vessel={vessel} zIndex={1} top={top} refresh={refreshVessels} canEdit={canEdit}/>
		{/* Hull - Specifications */}
		<MobileHullSpecifications id={vessel.id} hull={vessel.hull} zIndex={2} top={top} units={units} refresh={refreshVessels}
			canEdit={canEdit}/>
		{/* Storage */}
		<div ref={storageRef}>
			<MobileVesselStorageSpecs id={vessel.id} storage={vessel.storage} zIndex={3} units={units} marinas={marinas}
				canEdit={canEdit} top={top} homes={vessel.homes} refresh={refreshVessels}/>
		</div>
		{/* Engine */}
		<MobileEngines id={vessel.id} engines={vessel.engines} zIndex={4} top={top} units={units} refresh={refreshVessels}
			canEdit={canEdit}/>
		{/* Rig */}
		<MobileRig id={vessel.id} rig={vessel.rig} zIndex={5} top={top} units={units} refresh={refreshVessels} canEdit={canEdit}/>
		{/* Registration */}
		<MobileDocuments vesselId={vessel.id} add={canEdit} documents={vessel.documents.vesselRegistrations} top={top}
			canEdit={canEdit}	container={DOCUMENT_CONTAINER.VESSELS} docType={DOCUMENT_TYPE.VESSEL_REGISTRATIONS} zIndex={6}
			refresh={refreshVessels}/>
		{/* Documentation */}
		<MobileDocuments vesselId={vessel.id} add={canEdit} documents={vessel.documents.vesselDocumentations} top={top}
			canEdit={canEdit}	container={DOCUMENT_CONTAINER.VESSELS} docType={DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS} zIndex={7}
			refresh={refreshVessels}/>
		{/* Title */}
		<div ref={titleRef}>
			<MobileDocuments vesselId={vessel.id} add={canEdit} documents={vessel.documents.vesselTitles} top={top}
				canEdit={canEdit} container={DOCUMENT_CONTAINER.VESSELS} docType={DOCUMENT_TYPE.VESSEL_TITLES} zIndex={8}
				refresh={refreshVessels}/>
		</div>
		{/* Insurance Policies */}
		<MobileDocuments vesselId={vessel.id} add={canEdit} documents={vessel.documents.insurancePolicies} top={top}
			canEdit={canEdit} container={DOCUMENT_CONTAINER.VESSELS} docType={DOCUMENT_TYPE.INSURANCE} zIndex={9}
			refresh={refreshVessels}/>
		{/* Certificates */}
		<MobileDocuments vesselId={vessel.id} add={canEdit} documents={vessel.documents.certificates} top={top}
			canEdit={canEdit} container={DOCUMENT_CONTAINER.VESSELS} docType={DOCUMENT_TYPE.CERTIFICATES} zIndex={10}
			refresh={refreshVessels}/>
		{/* Maintenance & Upkeep Records */}
		<MobileDocuments vesselId={vessel.id} add={account.membership === "PREMIUM" && canEdit} top={top}
			canEdit={canEdit} documents={vessel.documents.upkeep} premium={true} container={DOCUMENT_CONTAINER.VESSELS}
			refreshAccount={props.refreshAccount} docType={DOCUMENT_TYPE.UPKEEP} zIndex={11} account={account}
			subscribe={account.membership !== "PREMIUM" && !account.isAdminOrEmployee} refresh={refreshVessels}/>
		{/* Access */}
		<MobileVesselAccess id={vessel.id} vesselAccess={vessel.access} account={account} zIndex={12} top={top}
			canEdit={canEdit}	refresh={refreshVessels}/>
	</>
	return <>{upload}{customerStatusModal}{profile}</>
}

export default MobileVessel