import React from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {deleteVesselStorageUnit, setAsCurrentVesselStorageUnit, setAsPrimaryVesselStorageUnit} from "../../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileImage from "../../account/MobileImage"
import Image from "../../../../globals/classes/shared/Image"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {number} props.index
 * @param {VesselHome} props.home
 * @param {boolean} props.canEdit
 * @param {boolean} props.edit
 * @param {function} props.onDelete
 * @param {function} props.onPrimary
 * @param {function} props.onCurrent
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageUnitCard = props => {
	return <div className={"paralian-mobile-info-card"}>
		{props.home.priority === 1 || props.home.isCurrent ?
			<div style={{display: "inline-flex", width: "100%"}}>
			{
				props.home.priority === 1 ?
					<div className={"paralian-theme-level-1"}
						style={{padding: "1vh", borderRadius: "1vh", marginBottom: "1vh", minWidth: "45%", width: "100%",
							marginRight: props.home.isCurrent ? "1vh" : "0vh"}}>
						<MobileFontAwesome icon={ICONS.ANCHOR} label={translate(DICTIONARY.DEFAULT.X)}
							style={{fontSize: "2vh", marginRight: "1vh"}}/>
						<span>{translate(DICTIONARY.PRIMARY.X)}</span>
					</div> : <></>
			}
			{
				props.home.isCurrent ?
					<div className={"paralian-theme-level-1"}
						 style={{padding: "1vh", borderRadius: "1vh", marginBottom: "1vh", minWidth: "45%", width: "100%"}}>
						<MobileFontAwesome icon={ICONS.MAP_MARKER_ALT} label={translate(DICTIONARY.CURRENT.X)}
							style={{fontSize: "2vh", marginRight: "1vh"}}/>
						<span>{translate(DICTIONARY.CURRENT.X)}</span>
					</div> : <></>
			}
		</div> : <></>}
		{(props.edit && (props.home.priority !== 1 || !props.home.isCurrent)) ?
			<div style={{display: "inline-flex", width: "100%"}}>
				{props.home.priority !== 1 ?
					<div className={"paralian-theme-level-4 w3-animate-opacity w3-ripple"}
						style={{padding: "1vh", borderRadius: "1vh", marginBottom: "1vh", minWidth: "45%", width: "100%", cursor: "pointer",
							fontSize: "1.5vh", textAlign: "center", marginRight: !props.home.isCurrent ? "1vh" : "0vh"}} onClick={async () => {
							const response = await setAsPrimaryVesselStorageUnit(props.id, props.home.id, props.home.priority)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								props.onPrimary(props.index)
							}
						}}>
						<i>{translate(DICTIONARY.SET_AS_PRIMARY.X)}</i>
						<MobileFontAwesome icon={ICONS.ANCHOR} style={{marginLeft: "1vh"}}/>
					</div> : <></>}
				{!props.home.isCurrent ?
					<div className={"paralian-theme-level-4 w3-animate-opacity w3-ripple"}
						style={{padding: "1vh", borderRadius: "1vh", marginBottom: "1vh", minWidth: "45%", width: "100%", cursor: "pointer",
							fontSize: "1.5vh", textAlign: "center"}} onClick={async () => {
							const response = await setAsCurrentVesselStorageUnit(props.id, props.home.id, props.home.priority)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								props.onCurrent(props.index)
							}
						}}>
						<i>{translate(DICTIONARY.SET_AS_CURRENT.X)}</i>
						<MobileFontAwesome icon={ICONS.MAP_MARKER_ALT} style={{marginLeft: "1vh"}}/>
					</div> : <></>}
			</div> : <></>
		}
		{
			props.edit && ((props.home.isVerified && props.home.expired) || (!props.home.isVerified)) ?
			<div className={"paralian-theme-level-4 w3-animate-opacity w3-ripple"}
					 style={{padding: "1vh", borderRadius: "1vh", marginBottom: "1vh", minWidth: "45%", width: "100%", cursor: "pointer",
						 fontSize: "1.5vh", textAlign: "center"}} onClick={async () => {
				const confirmed = window.confirm(translate(DICTIONARY.REMOVE_STORAGE_UNIT_QUERY.X))
				if (confirmed) {
					const response = await deleteVesselStorageUnit(props.id, props.home.id, props.home.priority)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.onDelete(props.index)
					}
				}}}>
				<i>{translate(DICTIONARY.DELETE.X)}</i>
				<MobileFontAwesome icon={ICONS.TRASH} style={{marginLeft: "1vh"}}/>
			</div> : <></>
		}
		<div style={{display: "inline-flex", width: "100%", maxWidth: "36vh"}}>
			<MobileImage image={new Image({path: props.home.logo.path})} size={"10vh"} alt={"logo"}
				style={{margin: "auto 1vh"}}/>
			<div style={{padding: "2vh", textAlign: "right", width: "100%"}}>
				<div style={{fontSize: "2.5vh"}}>
					{props.home.name.display}
				</div>
				<div style={{fontSize: "2vh", opacity: "0.5"}}>
					{props.home.facility ? <div style={{fontSize: "2.5vh"}}>{props.home.facility}</div> : <></>}
					{props.home.unit ? <div style={{fontSize: "2vh"}}>{props.home.unit}</div> : <></>}
				</div>
			</div>
		</div>
	</div>
}

export default MobileVesselStorageUnitCard