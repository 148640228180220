import {DOCUMENT_CONTAINER, DOCUMENT_TYPE, DOCUMENT_TYPE_MINIMAL} from "../../../globals/constants/shared/enumerators"

const containerAndMinimalTypeToType = (container, type) => {
	switch (container) {
		case DOCUMENT_CONTAINER.VESSELS:
			switch (type) {
				case DOCUMENT_TYPE.VESSEL_TITLES:
					return "vesselTitles"
				case DOCUMENT_TYPE.VESSEL_REGISTRATIONS:
					return "vesselRegistrations"
				case DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS:
					return "vesselDocumentations"
				case DOCUMENT_TYPE_MINIMAL.TITLES:
					return "vesselTitles"
				case DOCUMENT_TYPE_MINIMAL.REGISTRATIONS:
					return "vesselRegistrations"
				case DOCUMENT_TYPE_MINIMAL.DOCUMENTATIONS:
					return "vesselDocumentations"
				case DOCUMENT_TYPE_MINIMAL.PUBLIC:
					return "publicDocuments"
				case DOCUMENT_TYPE_MINIMAL.INSURANCE:
					return "insurancePolicies"
				case DOCUMENT_TYPE_MINIMAL.CERTIFICATES:
					return "certificates"
				case DOCUMENT_TYPE_MINIMAL.UPKEEP:
					return "upkeep"
				default:
					console.log("document type error")
					return
			}
		case DOCUMENT_CONTAINER.ORGANIZATIONS:
			switch (type) {
				case DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS:
					return "organizationRegistrations"
				case DOCUMENT_TYPE_MINIMAL.REGISTRATIONS:
					return "organizationRegistrations"
				case DOCUMENT_TYPE_MINIMAL.CONTRACTS:
					return "contracts"
				case DOCUMENT_TYPE_MINIMAL.CERTIFICATES:
					return "certificates"
				case DOCUMENT_TYPE_MINIMAL.BONDS:
					return "bonds"
				case DOCUMENT_TYPE_MINIMAL.INSURANCE:
					return "insurancePolicies"
				case DOCUMENT_TYPE_MINIMAL.PUBLIC:
					return "publicDocuments"
				default:
					console.log("document type error")
					return
			}
		default:
			switch (type) {
				case DOCUMENT_TYPE_MINIMAL.CERTIFICATES:
					return "certificates"
				case DOCUMENT_TYPE_MINIMAL.BONDS:
					return "bonds"
				case DOCUMENT_TYPE_MINIMAL.CONTRACTS:
					return "contracts"
				case DOCUMENT_TYPE_MINIMAL.PUBLIC:
					return "publicDocuments"
				case DOCUMENT_TYPE_MINIMAL.INSURANCE:
					return "insurancePolicies"
				default:
					console.log("document type error")
					return
			}
	}
}

export default containerAndMinimalTypeToType