import { SERVER_RESPONSE_STATE } from '../../constants/shared/enumerators';
import { isObject, enumOrNull } from '../../functions/shared/local';

class ServerResponse {
	/**
	 *
	 * @param {ServerResponse} [response = null]
	 * @param {string} [response.status]
	 * @param {Object} [response.payload]
	 * @param {Object} [response.data]
	 */
	constructor (response = null) {
		try {
			this.status_ = enumOrNull(response.status || response.status_, SERVER_RESPONSE_STATE)
			this.payload = isObject(response.payload) ? response.payload : null
			this.data = isObject(response.data) ? response.data : null
		} catch (e) {
			this.status_ = null
			this.payload = null
			this.data = null
		}
	}

	get status(){
		return this.status_
	}

	set status(status) {
		this.status_ = enumOrNull(status, SERVER_RESPONSE_STATE)
	}
}

export default ServerResponse;