import React ,{useState} from "react"
import {TEXT_FIELD_PROPERTIES, LOGIN_TYPES, ICONS, DICTIONARY} from "../../../../globals/constants/client/constants"
import {newMessage, toggleByElementId, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.label
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @return {JSX.Element}
 * @constructor
 */
const MobileUsernameField = props => {
	const [loginIndices, setLoginIndices] = useState([0,1,2])
	const loginType = LOGIN_TYPES[loginIndices[0]][4]
	const currentFieldProperties = TEXT_FIELD_PROPERTIES[loginType]
	const label = translate(DICTIONARY.INFORMATION.X)
	const className = props.className || "paralian-theme-level-1-flipped"
	return <>
		<div className={"paralian-label-field"} style={{display: props.showLabel ? "block" : "none"}}>
			{translate(DICTIONARY.USERNAME.X)}
		</div>
		<div className={`w3-center ${className}`} style={{...props.style, display: "inline-flex", height: "6vh",
			width: "100%", borderTopLeftRadius: props.topRadius, borderBottomLeftRadius: props.bottomRadius,
			borderTopRightRadius: props.topRadius, borderBottomRightRadius: props.bottomRadius}}>
			<MobileFontAwesome icon={LOGIN_TYPES[loginIndices[0]][3]} className={``}
				onClick={() => toggleByElementId("loginInputTypes")}
				style={{padding: "1vh 0vw", fontSize: "3vh", height: "6vh", minWidth: "6vh"}}/>
			<div id={"loginInputTypes"} className={"w3-dropdown-content w3-bar-block w3-animate-opacity"}
				style={{marginTop: "6.5vh", width: "6vh", minWidth: "unset", animationDuration: "1.2s",
					backgroundColor: "unset"}}>
				<div style={{minHeight: "6vh", minWidth: "6vh", padding: "1vh", margin: "0.5vh 0vh 1vh 0vh"}}
				 	className={className}>
					<MobileFontAwesome label={label} icon={LOGIN_TYPES[loginIndices[1]][3]}	style={{fontSize: "3vh"}}
						onClick={() => {
							setLoginIndices([loginIndices[1],loginIndices[2],loginIndices[0]])
							toggleByElementId("loginInputTypes")
						}}/>
				</div>
				<div style={{minHeight: "6vh", minWidth: "6vh", padding: "1vh"}} className={className}>
					<MobileFontAwesome label={label} icon={LOGIN_TYPES[loginIndices[2]][3]}
					style={{fontSize: "3vh"}}
					onClick={() => {
						setLoginIndices([loginIndices[2],loginIndices[0],loginIndices[1]])
						toggleByElementId("loginInputTypes")
					}}/>
				</div>
			</div>
			<div style = {{height: "6vh", width: "100%"}}>
				<label htmlFor={`${props.id}-input`} style={{display: "none"}}>
					{translate(currentFieldProperties.placeHolder)}
				</label>
				<input id={`${props.id}-input`} className={"w3-input w3-border-0"} name={"user"}
					type={currentFieldProperties.inputType} autoFocus={"autofocus"} form={props.form}
					maxLength={currentFieldProperties.maxLength} pattern={currentFieldProperties.pattern}
					autoComplete={LOGIN_TYPES[loginIndices[0]][2]} placeholder={translate(currentFieldProperties.placeHolder)}
					required={"required"} size={currentFieldProperties.size} style={{backgroundColor: "unset", color: "unset",
						height: "100%", fontSize: "2.5vh", outline: "transparent", width: "100%"}}/>
			</div>
			<MobileFontAwesome label={label} icon={ICONS.INFO_CIRCLE} className={``}
				style={{padding: "1vh 0vw", fontSize: "3vh", height: "6vh", minWidth: "6vh"}}
				onClick={() => newMessage("login", translate(currentFieldProperties.hoverTitle),
					translate(currentFieldProperties.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
		</div>
	</>
}

export default MobileUsernameField