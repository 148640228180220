import React from "react"
import IndexedArray from "../../../globals/classes/shared/IndexedArray"
import MobileRequiredDocumentRequirementCard from "./MobileRequiredDocumentRequirementCard"

/**
 *
 * @param {Object} props
 * @param {DocumentRequirements} props.documentRequirements
 * @param {function} props.refresh
 * @param {boolean} props.canEdit
 * @param {Documents} props.documents
 * @param {boolean} props.isMarina
 * @return {JSX.Element}
 * @constructor
 */
const MobileRequiredDocuments = props => {
	const documents = new IndexedArray({array: props.documentRequirements.documents, key: "id"})
	return props.documentRequirements.requirements.length > 0 ? <>
		{
			props.documentRequirements.requirements.map(v => <MobileRequiredDocumentRequirementCard
				key={`${v.id}-${v.organization}`} requirement={v} documents={documents}
				canEdit={props.canEdit} refresh={props.refresh}/>)
		}
	</> : <></>
}

export default MobileRequiredDocuments