import { typeOrNull, enumOrNull } from '../../functions/shared/local'
const RESPONSE = Object.freeze({CONFIRM: "CONFIRM", DENY: "DENY"})
const PARALIAN_VERIFICATION = Object.freeze({VERIFIED_FALSE: "VERIFIED_FALSE", VERIFIED_TRUE: "VERIFIED_TRUE", ONGOING: "ONGOING", NONE: "NONE"})
class OwnershipAccess {
	/**
	 *
	 * @param {OwnershipAccess|Object|null} [access = null]
	 * @param {number} [access.id]
	 * @param {Date|number|string} [access.dateGranted]
	 * @param {Date|number|string} [access.startDate]
	 * @param {Date|number|string} [access.endDate]
	 * @param {string} [access.response] - RESPONSE
	 * @param {boolean} [access.isVerified]
	 * @param {string} [access.paralianVerification] - PARALIAN_VERIFICATION
	 */
	constructor(access = null) {
		try {
			this.id = typeOrNull(access.id, "number")
			this.dateGranted = typeOrNull(access.dateGranted, "date")
			this.startDate = typeOrNull(access.startDate, "date")
			this.endDate = typeOrNull(access.endDate, "date")
			this.response = enumOrNull(access.response, RESPONSE)
			this.isVerified = typeOrNull(access.isVerified, "boolean")
			this.paralianVerification = enumOrNull(access.paralianVerification, PARALIAN_VERIFICATION)
		} catch (e) {
			this.id = null
			this.dateGranted = null
			this.startDate = null
			this.endDate = null
			this.response = null
			this.isVerified = null
			this.paralianVerification = null
		}
	}
	get isEmpty() {
		return !(this.id || this.dateGranted || this.startDate || this.endDate || this.response || this.isVerified || this.paralianVerification)
	}
}

export default OwnershipAccess