import React from "react"
import {convertToPreferredUnits} from "../../../globals/functions/client/localFunctions"
import MobileInputDecimal from "../../shared/input/text/MobileInputDecimal"

/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {string} props.units - SI or US
 * @param {number} props.value
 * @param {string} props.label
 * @param {React.HTMLProps.className} props.className
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputVolume = props => {
	const units = (props.units === "SI") ? "l" : "gal"
	const value = Number(convertToPreferredUnits(props.value, "l", props.units).replace(/[a-zA-Z,. ]/g,''))
	const value_ = value > 0 ? Number(`${value}`.replace(/[a-zA-Z ]/g,'')).toLocaleString() : null
	return <div style={{margin: "2vh"}}>
		<div style={{textAlign: "left"}}><i>{props.label}</i></div>
		<div className={`${props.className} w3-animate-opacity`} style={{padding: "0vh 2vh", fontSize: "2.5vh", textAlign: "right"}}>
			<MobileInputDecimal label={props.label} autoFocus={false} style={{}}
				defaultValue={null} required={false} units={units} form={"capacity-form"} topRadius={"0vh"}
				bottomRadius={"0vh"} name={props.id} id={props.id} digits={6} decimals={0}
				defaultLong={value_}/>
		</div>
	</div>
}

export default MobileInputVolume