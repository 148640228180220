import React from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {setToStorage} from "../../../../globals/functions/client/localFunctions"
import {updateOrgCurrency, updateOrgTimezone} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputTimezoneDropdown from "../../../main/currency/MobileInputTimezoneDropdown"
import MobileInputCurrencyDropdown from "../../../main/currency/MobileInputCurrencyDropdown"
import Country from "../../../../globals/classes/shared/Country"
import Currency from "../../../../globals/classes/shared/Currency"

/**
 *
 * @param {Object} props
 * @param {Object} props.defaultTimezone
 * @param {string} props.defaultTimezone.name
 * @param {string} props.defaultTimezone.zone
 * @param {Object} props.defaultCurrency
 * @param {number} props.defaultCurrency.symbol
 * @param {string} props.defaultCurrency.iso
 * @param {string} props.country
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginCurrencyAndTimeZone = props => {
	const country = props.country || new Country({name: "United States of America", code: "USA", code2: "US",
		currency: {code: "USD", symbol: 36, name: "US Dollar"}})
	const currency = props.defaultCurrency || country.currency || new Currency({code: "USD", symbol: 36, name: "US Dollar"})
	const onSubmit = async event => {
		event.preventDefault()
		const currency_ = {
			code: document.getElementById("currency-input").getAttribute("data-id"),
			symbol: document.getElementById("currency-input").getAttribute("data-a")
		}
		const timezone = document.getElementById("timezone-input").getAttribute("data-id")
		setToStorage("currency", currency_, "session")
		const responseCurrency = await updateOrgCurrency(currency_.code)
		const responseTimezone = await updateOrgTimezone(timezone)
		if (responseCurrency.status === SERVER_RESPONSE_STATE.SUCCESS && responseTimezone.status === SERVER_RESPONSE_STATE.SUCCESS) {
			sessionStorage.removeItem("timezone")
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
		}
	}
	const content = <>
		<MobileInputCurrencyDropdown id={"currency"} form={"form"} showLabel={true} country={country} showSymbol={true}
			currency={currency}/>
		<div style={{marginTop: "1vh"}}>
			<MobileInputTimezoneDropdown id={"timezone"} form={"form"} country={country} showLabel={true} showCountry={true}
				style={{fontWeight: "bold"}} defaultValue={props.defaultTimezone} topRadius={"1vh"} bottomRadius={"1vh"}/>
		</div>
	</>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginCurrencyAndTimeZone