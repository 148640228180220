import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselHINEdit from "./MobileVesselHINEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.hin
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHIN = props => {
	/* TODO: check HIN in documentation matches HIN by calling marinetitle.com */
	const [hin, setHin] = useState(props.hin)
	const [edit, setEdit] = useState(false)
	const onDelete = props.onDelete ? () => {
		props.onDelete()
		setEdit(false)
	} : undefined
	const content = edit ? <MobileVesselHINEdit id={props.id} hin={hin} onSuccess={hin => {
		props.refresh().then()
		setHin(hin)
		setEdit(false)
	}}/> :
		<div style={{marginTop: "1vh"}} className={"w3-animate-opacity"}><b>{hin}</b></div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} onDelete={onDelete}
			labels={[translate(DICTIONARY.HULL_IDENTIFICATION_NUMBER.X)]}/>
		{content}
	</div>
}

export default MobileVesselHIN