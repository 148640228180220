import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class IMO extends TextInputFilter {
	minLength = 7
	maxLength = 7
	regexp = /[^0-9]/gi
	minVal = 0
	maxVal = 9999999
}

export default IMO;