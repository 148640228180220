import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getEngineModels} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDatalist from "../../../shared/input/datalist/MobileInputDatalist"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLProps.className} props.className
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {string} props.defaultValue
 * @param {string} props.name
 * @param {string} props.form
 * @param {boolean} props.showLabel
 * @param {boolean} props.required
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputEngineModelDatalist = props => {
	const onChange = props.onChange ? props.onChange : () => {}
	const [models, setModels] = useState([])
	useEffect(() => {
		getEngineModels(props.brand).then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setModels((response.payload.map(model => {return {label: model.model, value: model.model}})))
			}
		}).catch(reason => console.log(reason))
	},[props.brand])
	return <MobileInputDatalist id={props.id} className={props.className} style={props.style} list={models}
		label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} frozenValue={null} name={props.name} form={props.form} translate={"no"}
		autoFocus={props.autoFocus} maxLength={"45"} autoComplete={props.autoComplete} pattern={null}
		required={props.required} placeholder={props.placeholder} defaultValue={props.defaultValue}
		onChange={() => {
			const element = document.getElementById(`${props.id}-input`)
			element.setAttribute("data-id", element.value)
			onChange()
		}}
	/>
}

export default MobileInputEngineModelDatalist