import Timezone_ from './textInputFilters/Timezone';
class Timezone {
	/**
	 *
	 * @param {Timezone} [timezone = null] - IANA timezone
	 * @param {string} timezone.zone
	 */
	constructor (timezone = null) {
		try {
			this.zone_ = new Timezone_(timezone.zone || timezone.zone_).filtered
		} catch (e) {
			this.zone_ = null
		}
	}

	/**
	 *
	 * @returns {string}
	 */
	get asString() {
		return this.zone !== null ? this.zone.replace("/", " - ").replace("_", " ") : ""
	}

	get zone() {
		return this.zone_
	}

	set zone(zone) {
		this.zone_ = new Timezone_(zone).filtered
	}

}

export default Timezone;