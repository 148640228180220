import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {Object} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {boolean} props.showLabel
 * @param {boolean} [props.autoComplete = "off"]
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputPostCode = props => {
	const style = props.style || {margin: "0.5vh 0vh"}
	const autoComplete = props.autoComplete === true ? "postal-code" : "off"
	const label = translate(DICTIONARY.POSTAL_CODE.X)
	return <MobileInputText id={`${props.id}-postCode`} form={props.form} name={"postCode"} placeholder={label}
		label={label} showLabel={props.showLabel} autoComplete={autoComplete} autoFocus={false}	required={true}
		pattern={"([a-zA-Z0-9 -]){4,11}"} maxLength={"11"} defaultValue={props.defaultValue} className={props.className} style={style}/>
}

export default MobileInputPostCode