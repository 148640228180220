import { isObject, typeOrNull } from '../../../functions/shared/local';

class Methods {
	/**
	 *
	 * @param {Methods} [methods = null]
	 * @param {Card[]} [methods.cards]
	 */
	constructor (methods = null) {
		if (isObject(methods)) {
			this.cards = typeOrNull(methods.cards, "array")
		} else {
			this.cards = []
		}
	}
}

export default Methods;