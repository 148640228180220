import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {Object} [props.style = {margin: "0.5vh 0vh"}]
 * @param {Object} props.left
 * @param {Object} props.right
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {boolean} props.showLabel
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAddressLabel = props => {
	const style = props.style || {margin: "0.5vh 0vh"}
	const label = `${translate(DICTIONARY.ADDRESS.X)} - ${translate(DICTIONARY.LABEL.X)}`
	return <MobileInputText id={`${props.id}-label`} form={props.form} name={"label"} placeholder={label}
		label={label} showLabel={props.showLabel} autoComplete={"off"} autoFocus={false} required={true}
		pattern={"([a-zA-ZÀ-ž0-9\. -']){2,64}"} maxLength={"64"} defaultValue={props.defaultValue}
		left={props.left} right={props.right} style={style} className={props.className}/>
}

export default MobileInputAddressLabel