import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {ACCOUNT_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {boolean} props.state - true for active, false for deactivated
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputActiveStateButton = props => {
	const [status, setStatus] = useState(props.state)
	return <div id={`active-button-${props.id}`} style={{padding: "1vh", textAlign: "center", fontSize: "3vh", ...props.style}}
		className={`w3-animate-opacity ${props.className} paralian-theme-level-1 w3-ripple`} data-active={props.state}
		onClick={() => {
			let newState = props.state
			if (![ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(status)) {
				newState = ACCOUNT_STATE.DISABLED
			}
			document.getElementById(`active-button-${props.id}`).setAttribute("data-active", newState)
			setStatus(newState)
		}}>
		<span>
			<MobileFontAwesome icon={status === ACCOUNT_STATE.ACTIVE ? ICONS.CHECK :
				[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(status) ? ICONS.BAN :
					ICONS.EXCLAMATION_TRIANGLE} style={{paddingRight: "1.5vh"}}/>
			{status === ACCOUNT_STATE.ACTIVE ? translate(DICTIONARY.ACTIVE.X) :
				[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(status) ?
					translate(DICTIONARY.DEACTIVATED.X) : translate(DICTIONARY.PENDING.X)}
		</span>
	</div>
}

export default MobileInputActiveStateButton