import React, {useEffect, useMemo, useState} from "react"
import {subtractMultipleFromArray} from "../../../globals/functions/shared/local"
import submitOrgServicePricesUpdate from "../pricing/submitOrgServicePricesUpdate"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputPricing from "../pricing/MobileInputPricing"
import MobileServicesTreeSimple from "./simple/MobileServicesTreeSimple"
import Country from "../../../globals/classes/shared/Country"
import Currency from "../../../globals/classes/shared/Currency"
import Servicio from "../../../globals/classes/shared/Servicio"
/**
 *
 * @param {Object} props
 * @param {Servicio[]} props.parents - all available services
 * @param {number[]} props.preSelected - array of ids of already selected services
 * @param {Country} props.country
 * @param {Currency} props.currency
 * @param {IndexedArray} props.defaultPricingStructures
 * @param {function} props.onUndo
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputServiceAdd = props => {
	const country = useMemo(() => props.country ? new Country(props.country) :
		new Country({code: "USA", code2: "US"}), [props.country])
	const currency = useMemo(() => props.currency ? new Currency(props.currency) : country.currency.code ?
		country.currency : new Currency({iso: "USD", symbol: 36, name: "US Dollar"}),
		[props.currency, country.currency])
	const [selected, setSelected] = useState(props.preSelected)
	useEffect(() => {setSelected(props.preSelected)}, [props.preSelected])
	const added = subtractMultipleFromArray(props.preSelected, selected)
	let selection = new Servicio()
	if (added.length === 1) {
		selection = new Servicio(props.allServices.asIndexedArray.byIndex(added[0]))
	}
	// console.log(selection)
	const content = <>
		{/* props.preSelected */}
		<MobileServicesTreeSimple allServices={props.allServices} preSelected={props.preSelected} singleSelect={true}
			selected={selected} setSelected={setSelected}/>
		{added.length === 1 ? <><div style={{minHeight: "1vh", marginBottom: "2.5vh"}} className={"paralian-theme-level-1"}/>
		<MobileInputPricing service={selection} showLabel={true} currency={currency} form={"service-add-form"}
			defaultPricingStructures={props.defaultPricingStructures} country={country}
			className={"paralian-theme-level-1"}/></> : <></>}
	</>
	return <MobileInputForm id={"service-add"} content={content} className={null}
		onSubmit={async event =>
			await submitOrgServicePricesUpdate(event, selection, props.defaultPricingStructures, currency, props.onSubmit)}/>
}

export default MobileInputServiceAdd