import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../../globals/constants/client/constants"
import {getVesselModels} from "../../../../globals/functions/client/serverFunctions"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../../shared/input/MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.showLabel {boolean}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.autoComplete {string}
 * @param props.placeholder {string}
 * @param props.defaultValue {string}
 * @param props.required {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.onChange {Object} - function - datalist type only
 * @param props.left {Object}
 * @param props.left.icon {string} - FontAwesome icon classname
 * @param props.left.onClick {Object} - function
 * @param props.right {Object}
 * @param props.right.icon {string} - FontAwesome icon classname
 * @param props.right.onClick {Object} - function
 * @param props.setBrand {Object} - function
 * @param {string} props.frozenValue
 * @returns {JSX.Element}
 * @constructor
 */
const MobileVesselModelDropdown = props => {
	const onChange = props.onChange ? props.onChange : () => {}
	const [showText, setShowText] = useState(false)
	const [models, setModels] = useState([])
	const [model, setModel] = useState(props.defaultValue)
	useEffect(() => {
		console.log("useEffect props.brand", props.brand)
		if (props.brand) {
			getVesselModels(props.brand).then(response => {
				// console.log("response", response)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					const modelsList = response.payload.map(model_ => {
						return {label: model_.model, value: model_.model, code: model_.model}
					})
					const filteredModelsList = modelsList.filter(item => item.value !== "na")
					filteredModelsList.push({label: "*" + translate(DICTIONARY.OTHER.X) + "*", value: "na", code: "na"})
					setModels(filteredModelsList)
				}
			})
		}
	},[props.brand])
// console.log("props.models", models)
	return <>
		<MobileInputGeneric type={props.brand !== "na" ? "dropdown" : "text"} style={props.style} list={models}
			id={props.brand !== "na" ? props.id : props.id + "-text" } className={props.className}
			label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
			left={props.left} right={props.right} frozenValue={props.frozenValue} name={props.name} form={props.form} translate={"no"}
			autoFocus={props.autoFocus} maxLength={"45"} autoComplete={props.autoComplete}
			required={true} placeholder={props.placeholder} defaultValue={model}
				onChange={() => {
					const element = document.getElementById(props.brand !== "na" ? `${props.id}-input` : `${props.id}-text-input`)
					if (props.brand === "na") {element.setAttribute("data-a", element.value)}
					setShowText(props.brand !== "na" && element.value === "na")
					setModel(element.value)
					onChange(element.value)
				}}/>
		{
			showText && props.brand !== "na" ?
				<MobileInputGeneric type={"text"} id={props.id + "-text"} className={props.className} style={props.style}
					label={props.label} showLabel={false} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
					left={props.left} right={props.right} frozenValue={props.frozenValue} name={props.name} form={props.form}
					autoFocus={props.autoFocus} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX}
					autoComplete={props.autoComplete} pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} translate={"no"}
					title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE} required={true} placeholder={translate(DICTIONARY.MODEL.X)}
					onChange={() => {
						const element = document.getElementById(`${props.id}-text-input`)
						element.setAttribute("data-a", element.value)
						onChange(element.value)
					}}/>
				: <></>
		}
	</>
}

export default MobileVesselModelDropdown