import React from "react"
import {store} from "../../index"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../globals/constants/client/constants"
import {translate} from "../../globals/functions/client/localFunctions"
import MobileQuestionTwoOptions from "../shared/input/combination/MobileQuestionTwoOptions"

/**
 * Displays question. Has user received invitation code? Proceeds based on answer.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} props
 * @param {Object} props.back - page visited before current
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginInvitationQ = props => {
  const levelOne = store.getState().window.current.levelOne
  return <MobileQuestionTwoOptions icon={ICONS.ENVELOPE_OPEN} keyname={"invited"}
    question={translate(DICTIONARY.RECEIVED_INVITATION_Q.X)} back={props.back}
    left={{next: PAGE_STATES[levelOne].LOGIN.NEW_USER.ACCOUNT_TYPE.X, text: translate(DICTIONARY.NO.X), value: false}}
    right={{next: PAGE_STATES[levelOne].LOGIN.NEW_USER.INVITATION_CODE.X, text: translate(DICTIONARY.YES.X), value: true}}/>
}

export default MobileLoginInvitationQ