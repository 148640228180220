import {constructArray, typeOrNull} from "../../../functions/shared/local"
import Price from "./Price"
import IndexedArray from "../IndexedArray"

class Pricing {
	/**
	 *
	 * @param {Pricing} [pricing = null]
	 * @param {string[]} [pricing.default] - array of names of default prices
	 * @param {Price[]} [pricing.prices]
	 */
	constructor (pricing = null) {
		try {
			this.default = constructArray(pricing.default, item => typeOrNull(item, "string"))
			this.prices = constructArray(pricing.prices, item => new Price(item))
		} catch (e) {
			this.default = []
			this.prices = []
		}
	}

	get pricesByName() {
		try {
			return new IndexedArray({array: this.prices, key: "name"})
		} catch (e) {
			console.log(e)
			return new IndexedArray()
		}
	}

	get pricesById() {
		try {
			return new IndexedArray({array: this.prices, key: "stripeId"})
		} catch (e) {
			console.log(e)
			return new IndexedArray()
		}
	}

	get defaultPriceIds() {
		try {
			const indexed = this.pricesByName
			return this.default.map(name => indexed.byIndex(name).stripeId)
		} catch (e) {
			console.log(e)
			return new IndexedArray()
		}
	}
}

export default Pricing