import { isObject, typeOrNull } from '../../functions/shared/local';

class Websites {
	/**
	 *
	 * @param {Websites} [websites = null]
	 * @param {string} [websites.main]
	 * @param {string} [websites.support]
	 */
	constructor(websites = null) {
		if (isObject(websites)) {
			this.main = typeOrNull(websites.main, "string")
			this.support = typeOrNull(websites.support, "string")
		} else {
			this.main = null
			this.support = null
		}
	}
}

export default Websites;