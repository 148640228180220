import React, {useRef} from "react"
import {Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js"
import {manageCrash} from "../../../globals/functions/client/localFunctions"

/**
 *
 * @param {Object} props
 * @param {JSX.Element} props.element
 * @param {string} props.connectId
 * @return {JSX.Element}
 * @constructor
 */
const StripeElementWrapper = props => {
	try {
		const stripePromise = useRef(props.connectId ?
			loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {stripeAccount: props.connectId}) :
			loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
		console.log("props.connectId", props.connectId)
		console.log(stripePromise, "stripePromise")
		return <Elements stripe={stripePromise.current}>
			{props.element}
		</Elements>
	} catch (e) {
		manageCrash(e, "StripeElementWrapper")
	}
}

export default StripeElementWrapper