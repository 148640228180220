import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {
	editPauseListings, editSalesTaxExemptionsGoods,
	editSalesTaxExemptionsServices
} from "../../../globals/functions/client/serverFunctions"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"

const MobileOrganizationControl = props => {
	const [expanded, setExpanded] = useState(false)
	const [isReversion, setIsReversion] = useState(false)
	const id = "account-control"
	/* TODO: offer dual-factor authentication */
	/* TODO: lock account */
	const pauseListings = async () => {
		try {
			const element = document.getElementById(`${id}-pause`)
			const isPaused = element.getAttribute("data-is-checked") === "true"
			if (isReversion) {setIsReversion(false); return}
			if (!isPaused) {
				const response = await editPauseListings(isPaused)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				} else {
					setIsReversion(true)
					setTimeout(() => element.click(), 10)
				}
			} else {
				const confirmed = window.confirm(translate(DICTIONARY.IS_PAUSED_PROMPT.X))
				if (confirmed) {
					const response = await editPauseListings(isPaused)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.refresh().then()
					} else {
						setIsReversion(true)
						setTimeout(() => element.click(), 10)
					}
				} else {
					setIsReversion(true)
					setTimeout(() => element.click(), 10)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	const chargeTax = async type => {
		try {
			const element = document.getElementById(`${id}-charge-${type}-tax`)
			const isExempt = element.getAttribute("data-is-checked") === "false"
			if (isReversion) {setIsReversion(false); return}
			if (!isExempt) {
				const response = type === "services" ? await editSalesTaxExemptionsServices(isExempt) :
					await editSalesTaxExemptionsGoods(isExempt)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
				} else {
					setIsReversion(true)
					setTimeout(() => element.click(), 10)
				}
			} else {
				const confirmed = window.confirm(translate(type === "services" ?
					DICTIONARY.IS_EXEMPT_SERVICES_PROMPT.X : DICTIONARY.IS_EXEMPT_GOODS_PROMPT.X))
				if (confirmed) {
					const response = type === "services" ? await editSalesTaxExemptionsServices(isExempt) :
						await editSalesTaxExemptionsGoods(isExempt)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.refresh().then()
					} else {
						setIsReversion(true)
						setTimeout(() => element.click(), 10)
					}
				} else {
					setIsReversion(true)
					setTimeout(() => element.click(), 10)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = !expanded ? <></> :
		<div className={"w3-animate-top"} style={{fontSize: "2vh", textAlign: "left"}}>
			{/* Change of Legal/Display Name or Company Type/Structure */}
			<div className={"changeName paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{width: "100%"}}>
					<div className={"paralian-mobile-info-card-header"}>
						{translate(DICTIONARY.CHANGE_OFFICIAL_COMPANY_INFORMATION.X)}
					</div>
				</div>
				<p>{translate(DICTIONARY.CHANGE_OFFICIAL_COMPANY_INFORMATION_MESSAGE.X)}</p>
				<div><a href={"mailto:support@paralian.io"}>
					{translate(DICTIONARY.SEND_US_AN_EMAIL.X)}
				</a>
				</div>
			</div>
			{/* TODO: delete organization */}
			{/* Pause Listings */}
			<div className={"paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{display: "flex", width: "100%", height: "3vh"}}>
					<MobileInputSlider id={`${id}-pause`} label={translate(DICTIONARY.PAUSE_SERVICE_LISTINGS.X)} height={"3vh"}
						width={"8vh"} className={"paralian-mobile-info-card-header w3-display-left"} form={`${id}-delete-form`}
						defaultChecked={props.organization.isPaused} padding={"0.25vh 0vh 0.25vh 2vh"} onChange={pauseListings}/>
				</div>
				<p>{translate(DICTIONARY.PAUSE_SERVICE_LISTINGS_MESSAGE.X)}</p>
			</div>
			{/* Tax Collection */}
			{/* TODO: list all taxes - specify exemption by tax */}
			{/* Charge Sales Tax on Goods */}
			<div className={"paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{display: "flex", width: "100%", height: "3vh"}}>
					<MobileInputSlider id={`${id}-charge-services-tax`} label={translate(DICTIONARY.COLLECT_SALES_TAX_ON_SERVICES.X)}
						width={"8vh"} height={"3vh"} className={"paralian-mobile-info-card-header w3-display-left"}
						defaultChecked={props.organization.taxExemptions.filter(v => v.services).length === 0}
						padding={"0.25vh 0vh 0.25vh 2vh"} form={`${id}-delete-form`} onChange={async () => await chargeTax("services")}/>
				</div>
				<p>{translate(DICTIONARY.COLLECT_SALES_TAX_ON_SERVICES_MESSAGE.X)}</p>
			</div>
			{/* Charge Sales Tax on Services */}
			<div className={"paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{display: "flex", width: "100%", height: "3vh"}}>
					<MobileInputSlider id={`${id}-charge-goods-tax`} label={translate(DICTIONARY.COLLECT_SALES_TAX_ON_GOODS.X)}
						width={"8vh"} height={"3vh"} className={"paralian-mobile-info-card-header w3-display-left"}
						defaultChecked={props.organization.taxExemptions.filter(v => v.goods).length === 0}
						padding={"0.25vh 0vh 0.25vh 2vh"} form={`${id}-delete-form`} onChange={async () => await chargeTax("goods")}/>
				</div>
				<p>{translate(DICTIONARY.COLLECT_SALES_TAX_ON_GOODS_MESSAGE.X)}</p>
			</div>
			{/* Retain 10% of Paralian Fees */}
			{/*
			<div className={"paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{display: "flex", width: "100%", height: "3vh"}}>
					<MobileInputSlider id={`${id}-charge-tax`} label={translate(DICTIONARY.RETAIN_TEN_PERCENT_ON_PARALIAN_FEES.X)}
						width={"8vh"} height={"3vh"} className={"paralian-mobile-info-card-header w3-display-left"}
						defaultChecked={props.organization.taxExemptions.length === 0}
						padding={"0.25vh 0vh 0.25vh 2vh"} form={`${id}-delete-form`} onChange={chargeTax}/>
				</div>
				<p>{translate(DICTIONARY.RETAIN_TEN_PERCENT_ON_PARALIAN_FEES_MESSAGE.X)}</p>
			</div>
			*/}
		</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} top={props.top} zIndex={props.zIndex} text={translate(DICTIONARY.ACCOUNT_CONTROL.X)}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileOrganizationControl