import {store} from "../../../../index"
import {actions} from "../../../constants/client/reduxData"
import {setToStorage} from "../localFunctions"
import getMode from "./getMode"
import Mode from "../../../classes/client/Mode"

/**
 * Sets device value of Mode on both Redux and localStorage
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {string} type - device type: one of "DESKTOP", "MOBILE" (case-insensitive)
 */
const setDeviceType = type => {
	const _mode = new Mode({device: type})
	if (_mode.device !== null) {
		try {
			const mode = getMode()
			if (mode.device !== type) {
				mode.device = _mode.device
				setToStorage("mode", mode)
			}
		} catch (e) {
			console.log(e)
		} finally {
			if (store.getState().device.type !== type) {
				store.dispatch({type: actions.SET_DEVICE_TYPE, values: {type: _mode.device}})
			}
		}
	}
}

export default setDeviceType