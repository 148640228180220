import TextInputFilter from './TextInputFilter';
/**
 *
 * @param {string} state
 * @returns {Promise<Object[]>}
 */
class Timezone extends TextInputFilter {
	minLength = 2
	maxLength = 35
	regexp = /[^a-zA-Z/_-]/gi
}

export default Timezone;