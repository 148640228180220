import React from "react"

/**
 *
 * @param {Object} props
 * @param {JSX.Element} props.content
 * @param {string} props.label
 * @param {React.HTMLProps.className} props.className
 * @param {string} props.imagePath
 * @return {JSX.Element}
 * @constructor
 */
const MobileHorizontalPictureAndText = props => {
	return <div style={{display: "flex"}}>
		<div className={"accountProfileImage w3-display-container"} style={{height: "14vh", width: "100%"}}>
			<picture className={"w3-display-middle"} style={{width: "14vh"}}>
				<img src={props.imagePath} alt={"profileImage"}
					style={{height: "100%", width: "100%"}}/>
			</picture>
		</div>
		<div className={"w3-display-container w3-animate-opacity"} style={{height: "14vh", width: "100%"}}>
			<div className={"w3-display-middle"} style={{width: "100%", textAlign: "right", paddingRight: "2vh"}}>
				<div style={{fontSize: "3vh"}} className={props.className}>
					{props.content === "0' 0\"" ? "#' #\"" : props.content}
				</div>
				<div><i>{props.label}</i></div>
			</div>
		</div>
	</div>
}

export default MobileHorizontalPictureAndText