import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileFontAwesome from "../../MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {Object} props.field
 * @param {string} props.field.id
 * @param {string} props.field.text
 * @param {string} props.field.pattern
 * @param {string} props.field.maxLength
 * @param {string} props.label
 * @param {string} [props.className]
 * @param {string} [props.invalidMessage]
 * @param {boolean} props.canEdit
 * @param {function} props.onSubmit
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileSingleFieldToggle = props => {
	const [edit, setEdit] = useState(false)
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const response = await props.onSubmit()
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess(response)
				setEdit(false)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const onDelete = props.onDelete ? () => {
		props.onDelete()
		setEdit(false)
	} : undefined
	const content = edit ? <MobileInputForm id={props.field.id} onSubmit={onSubmit} className={" "} content={
		<MobileInputText id={props.field.id} title={props.label} label={props.label} defaultValue={props.field.text}
			form={`${props.field.id}-form`} showLabel={false} placeholder={props.field.placeholder || props.label}
			required={true} autoFocus={true} name={props.field.id} pattern={props.field.pattern}
			maxLength={props.field.maxLength} invalidMessage={props.invalidMessage}
 			style={{marginTop: "1vh"}} className={props.className || "paralian-theme-level-1"}/>
	}/>  :
	<div style={{marginTop: "1vh"}}>
		{props.field.text}
		{props.flag ? <MobileFontAwesome icon={props.flag.icon} label={translate(DICTIONARY.INFORMATION.X)}
			onClick={() => {
			newMessage(props.flag.id, props.flag.title, props.flag.text, props.flag.color, props.flag.icon)
		}} style={{paddingLeft: "1.5vh"}}/> : <></>}
	</div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} onDelete={onDelete}
			labels={[props.label]}/>
		{content}
	</div>
}

export default MobileSingleFieldToggle