import Longitude from './textInputFilters/Longitude';
import Latitude from './textInputFilters/Latitude';

class Coordinates {
	/**
	 *
	 * @param {Coordinates} [coordinates = null]
	 * @param {number} [coordinates.latitude]
	 * @param {number} [coordinates.longitude]
	 */
	constructor (coordinates = null) {
		try {
			this.latitude_ = new Latitude(coordinates.latitude || coordinates.latitude_).number
			this.longitude_ = new Longitude(coordinates.longitude || coordinates.longitude_).number
		} catch (e) {
			this.latitude_ = null
			this.longitude_ = null
		}
	}

	get latitude() {
		return this.latitude_
	}

	set latitude(latitude) {
		this.latitude_ = new Latitude(latitude).number
	}

	get longitude() {
		return this.longitude_
	}

	set longitude(longitude) {
		this.longitude_ = new Longitude(longitude).number
	}
}

export default Coordinates;