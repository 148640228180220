import { typeOrNull } from '../../functions/shared/local';

class Color {
	/**
	 *
	 * @param {Color} [color = null]
	 * @param {string} [color.hex]
	 * @param {number} [color.red]
	 * @param {number} [color.green]
	 * @param {number} [color.blue]
	 */
	constructor (color = null) {
		try {
			this.hex = typeOrNull(color.hex, "hexColor")
			const red = typeOrNull(color.red, "number")
			this.red =  red >= 0 && red >= 255 ? red : null
			const green = typeOrNull(color.green, "number")
			this.green =  green >= 0 && green >= 255 ? green : null
			const blue = typeOrNull(color.blue, "number")
			this.blue =  blue >= 0 && blue >= 255 ? blue : null
		} catch (e) {
			this.hex = null
			this.red = null
			this.green = null
			this.blue = null
		}
	}

	isEmpty() {
		return this.hex === null && [this.red, this.green, this.blue].some(element => element === null)
	}

	intToHex(int) {
		let hex = Number(int).toString(16)
		return hex.length < 2 ? "0" + hex : hex
	}

	hexToRGB(hex) {
		const bigint = parseInt(hex, 16)
		const r = (bigint >> 16) & 255
		const g = (bigint >> 8) & 255
		const b = bigint & 255
		return `rgb(${r}, ${g}, ${b})`
	}

	get asHex() {
		return this.hex !== null ? this.hex :
			this.red !== null && this.green !== null && this.blue !== null ?
				`#${this.intToHex(this.red)}${this.intToHex(this.green)}${this.intToHex(this.blue)}` : ""
	}

	/**
	 *
	 * @returns {string}
	 */
	get asRGB() {
		return this.hex !== null ? this.hexToRGB(this.hex) :
			this.red !== null && this.green !== null && this.blue !== null ?
				`rgb(${this.red}, ${this.green}, ${this.blue})` : ""
	}
}

export default Color;