import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselEngineSNEdit from "./MobileVesselEngineSNEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileVesselEngineSN = props => {
	const [edit, setEdit] = useState(false)
	const [serialNumber, setSerialNumber] = useState(props.engine.serialNumber)
	const content = edit ?
		<MobileVesselEngineSNEdit id={props.id} engine={props.engine} onSuccess={serialNumber => {
			props.refresh().then()
			setSerialNumber(serialNumber)
			setEdit(false)
		}}/> :
		<div className={"w3-animate-opacity"}><b>{serialNumber}</b></div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.SERIAL_NUMBER.X)]}/>
		{content}
	</div>
}

export default MobileVesselEngineSN