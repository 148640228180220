import {typeOrNull} from '../../../functions/shared/local'
import Capacity from '../Capacity'

class VesselStorageFacility {
	/**
	 *
	 * @param {VesselStorageFacility} [facility = null]
	 * @param {number} [facility.quantity]
	 * @param {Capacity} [facility.capacity]
	 */
	constructor(facility = null) {
		try {
			this.quantity = typeOrNull(facility.quantity, "number")
			this.capacity = new Capacity(facility.capacity)
		} catch (e) {
			this.quantity = null
			this.capacity = new Capacity()
		}
	}
}

export default VesselStorageFacility