import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileVesselIdentifiersFields from "./MobileVesselIdentifiersFields"

const MobileVesselIdentifiers = props => {
	const [expanded, setExpanded] = useState(false)
	const content = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"} style={{fontSize: "2vh"}}>
		<MobileVesselIdentifiersFields vessel={props.vessel} canEdit={props.canEdit} refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.IDENTIFIERS.X)}
			top={props.top} onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileVesselIdentifiers