import React from "react"
import {DOCUMENT_CONTAINER, DOCUMENT_TYPE} from "../../globals/constants/shared/enumerators"
import MobileDocuments from "./documents/MobileDocuments"

/**
 *
 * @param {Object} props
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @param {boolean} props.canEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileOrganizationDocuments = props => {
	return <>
		<MobileDocuments container={DOCUMENT_CONTAINER.ORGANIZATIONS} docType={DOCUMENT_TYPE.BONDS}
			zIndex={props.zIndex} top={props.top} add={props.canEdit} canEdit={props.canEdit}
			documents={props.documents.bonds} refresh={props.refresh}/>
		<MobileDocuments container={DOCUMENT_CONTAINER.ORGANIZATIONS} docType={DOCUMENT_TYPE.CERTIFICATES}
			zIndex={props.zIndex + 1} top={props.top} add={props.canEdit} canEdit={props.canEdit}
			documents={props.documents.certificates} refresh={props.refresh}/>
		<MobileDocuments container={DOCUMENT_CONTAINER.ORGANIZATIONS} docType={DOCUMENT_TYPE.INSURANCE}
			zIndex={props.zIndex + 2} top={props.top} add={props.canEdit} canEdit={props.canEdit}
			documents={props.documents.insurancePolicies} refresh={props.refresh}/>
		<MobileDocuments container={DOCUMENT_CONTAINER.ORGANIZATIONS} docType={DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS}
			zIndex={props.zIndex + 3} top={props.top} add={props.canEdit} canEdit={props.canEdit}
			documents={props.documents.organizationRegistrations} refresh={props.refresh}/>
		<MobileDocuments container={DOCUMENT_CONTAINER.ORGANIZATIONS} docType={DOCUMENT_TYPE.PUBLIC}
			zIndex={props.zIndex + 4} top={props.top} add={props.canEdit} canEdit={props.canEdit}
			documents={props.documents.publicDocuments} refresh={props.refresh}/>
	</>
}

export default MobileOrganizationDocuments