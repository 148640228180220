import React from "react"
import {DOCUMENT_CONTAINER} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

const MobileDocumentContainerDropdown = props => {
	const list = [
		{label: translate(DICTIONARY.ORGANIZATION.X), value: DOCUMENT_CONTAINER.ORGANIZATIONS},
		{label: translate(DICTIONARY.VESSEL.X), value: DOCUMENT_CONTAINER.VESSELS},
		{label: translate(DICTIONARY.INDIVIDUAL.X), value: DOCUMENT_CONTAINER.ACCOUNTS},
	]
	const label = props.label || translate(DICTIONARY.SUBJECT.X)
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-container`} className={className} size={"1"}
		left={left}	label={label} multiple={false} topRadius={props.topRadius} showLabel={props.showLabel}
		right={props.right}	style={style} list={list} placeholder={label}	name={"document-container"} form={props.form}
		autoFocus={false} required={true}	bottomRadius={props.bottomRadius} dataId={list[0].value} onChange={() => {
			try {
				const element = document.getElementById(`${props.id}-container-input`)
				element.setAttribute("data-id", element.value)
				onChange(element.value)
			} catch (e) {
				console.log(e)
			}
		}}/>
}

export default MobileDocumentContainerDropdown