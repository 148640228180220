import {SERVER_RESPONSE_STATE} from "../../constants/shared/enumerators"
import {DICTIONARY} from "../../constants/client/constants"
import {newMessage} from "./localFunctions"
import Coordinates from "../../classes/shared/Coordinates"

const getPosition = async () => {
	const options = {enableHighAccuracy: true, timeout: 5000, maximumAge: 0}
	return new Promise(resolve => {
		const success = position => {
			console.log("position", position)
			resolve({status: SERVER_RESPONSE_STATE.SUCCESS,
				payload: new Coordinates({latitude: position.coords.latitude, longitude: position.coords.longitude})})
		}
		const error = e => {
			console.log("error", e)
			resolve({status: SERVER_RESPONSE_STATE.FAIL, payload: new Coordinates()})
		}
		if(!navigator.geolocation) {
			newMessage("geolocation-message", DICTIONARY.GEOLOCATION.X, DICTIONARY.GEOLOCATION_PROVIDE_ACCESS.X)
		} else {
			navigator.geolocation.getCurrentPosition(success, error, options)
		}
	})
}

export default getPosition