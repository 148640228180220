import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {setEngineBmy} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputEngineBrandDatalist from "./MobileInputEngineBrandDatalist"
import MobileInputYear from "../../../shared/input/text/MobileInputYear"
import MobileInputEngineModelDatalist from "./MobileInputEngineModelDatalist"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.engine
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselEngineBMYEdit = props => {
	const [year, setYear] = useState(props.engine.year)
	const [brand, setBrand] = useState(props.engine.brand)
	const onSubmit = async event => {
		try {
			event.preventDefault()
			const brand = document.getElementById(`${props.engine.id}-engine-brand-input`).value
			const model = document.getElementById(`${props.engine.id}-engine-model-input`).value
			const year = document.getElementById(`${props.engine.id}-engine-year-input`).value
			const response = await setEngineBmy(props.id, props.engine.id, brand, model, year)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess({brand: brand, model: model, year: year})
			}
		} catch (e) {
			console.log(e)
		}
	}
	/* TODO: use year to filter brands and models - post v1.0 */
	const content = <>
		<MobileInputEngineBrandDatalist required={true} label={translate(DICTIONARY.BRAND.X)} year={year}
			placeholder={translate(DICTIONARY.BRAND.X)} name={"brand"} className={"paralian-theme-level-1"} defaultValue={brand}
			id={`${props.engine.id}-engine-brand`} showLabel={true} autoFocus={false} setBrand={setBrand}
			form={`${props.engine.id}-engine-bmy-form`} autoComplete={"off"} style={{margin: "1vh 0vh"}}/>
		<MobileInputEngineModelDatalist required={true} label={translate(DICTIONARY.MODEL.X)} year={year} brand={brand}
			placeholder={translate(DICTIONARY.MODEL.X)} name={"model"} className={"paralian-theme-level-1"}
			id={`${props.engine.id}-engine-model`} showLabel={true} autoFocus={false} defaultValue={props.engine.model}
			form={`${props.engine.id}-engine-bmy-form`} autoComplete={"off"} style={{margin: "1vh 0vh"}}/>
		<MobileInputYear id={`${props.engine.id}-engine-year`} name={"year"} showLabel={true} style={{margin: "1vh 0vh"}}
			form={`${props.engine.id}-engine-bmy-form`} autoComplete={"off"} required={false} defaultValue={year}
			placeholder={translate(DICTIONARY.MODEL_YEAR.X)} autoFocus={false} label={translate(DICTIONARY.MODEL_YEAR.X)}
			className={"paralian-theme-level-1"} onChange={year => {if (year.length === 4) {setYear(year)}}}/>
	</>
	return <MobileInputForm id={`${props.engine.id}-engine-bmy`} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselEngineBMYEdit