import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.showLabel {boolean}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.maxLength {string}
 * @param props.autoComplete {string}
 * @param props.placeholder {string}
 * @param props.defaultValue {string}
 * @param props.required {boolean}
 * @param props.pattern {string} - regex string
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.translate {string}
 * @param props.onChange {Object} - function - datalist type only
 * @param props.list {Object[]} - list items for datalist with 'value' and 'label' string text - datalist type only
 * @param props.frozenValue {string} - value to be shown on read only version of datalist - datalist type only
 * @param props.left {Icon}
 * @param props.right {Icon}
 * @param {string|number} props.dataId - Default content of data-id attribute of element
 * @param {string|number} props.dataIndex - Default content of data-index attribute of element
 * @param {string|number} props.dataA - Default content of data-a attribute of element
 * @param {string|number} props.dataB - Default content of data-b attribute of element
 * @param {string|number} props.dataC - Default content of data-c attribute of element
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputDatalist = props => {
	return <MobileInputGeneric type="datalist" id={props.id} className={props.className} style={props.style} list={props.list}
		label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} frozenValue={props.frozenValue} name={props.name} form={props.form} translate={props.translate}
		autoFocus={props.autoFocus} maxLength={props.maxLength} autoComplete={props.autoComplete} onChange={props.onChange}
		required={props.required} placeholder={props.placeholder} defaultValue={props.defaultValue} pattern={props.pattern}
		dataIndex={props.dataIndex} dataId={props.dataId} dataA={props.dataA} dataB={props.dataB} dataC={props.dataC}/>
}

export default MobileInputDatalist