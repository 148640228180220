import React from "react"
import {DICTIONARY, IMAGES} from "../../../../globals/constants/client/constants"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import MobileHorizontalPictureAndText from "../../MobileHorizontalPictureAndText"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"

/**
 *
 * @param {Object} props
 * @param {string} props.units - SI or US
 * @param {Object} props.storage
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageSpaceAndCapacityFields = props => {
	const length = convertToPreferredUnits(props.storage.length, "m", props.units, 2)
	const width = convertToPreferredUnits(props.storage.width, "m", props.units, 2)
	const draught = convertToPreferredUnits(props.storage.draught, "m", props.units, 2)
	const clearance = convertToPreferredUnits(props.storage.clearance, "m", props.units, 2)
	return <>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.WEIGHT.X)} units={props.units}
			value={{number: props.storage.weight, units: "kg"}}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.LOA} label={translate(DICTIONARY.LENGTH.X)} content={length}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.BEAM} label={translate(DICTIONARY.WIDTH.X)} content={width}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.DRAUGHT} label={translate(DICTIONARY.DRAUGHT.X)} content={draught}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.CLEARANCE} label={translate(DICTIONARY.CLEARANCE.X)} content={clearance}/>
	</>
}

export default MobileVesselStorageSpaceAndCapacityFields