import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {checkUsername, linkAccount} from "../../../globals/functions/client/serverFunctions"
import respondToUsernameCheck from "../../login/shared/respondToUsernameCheck"
import respondToPasswordCheck from "../../login/current/respondToPasswordCheck"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileUsernameField from "../../shared/input/text/MobileUsernameField"
import MobileInputPassword from "../../shared/input/text/MobileInputPassword"

/**
 *
 * @param {Object} props
 * @param {function} props.onSuccess
 * @param {function} props.onUndo
 * @return {JSX.Element}
 * @constructor
 */
const MobileNewLinkedAccount = props => {
	const [loginStage, setLoginStage] = useState("username")
	const [username, setUsername] = useState(null)
	const onSubmit = async event => {
		event.preventDefault()
		let element, type, response
		try {
			switch (loginStage) {
				case "username":
					element = document.getElementById("username-input")
					type = "username"
					if (element.type === "email") {type = "loginEmail"}
					else if (element.type === "tel") {type = "loginPhone"}
					response = await checkUsername(element.value, type)
					// console.log(response)
					if (response.status !== "bad") {
						respondToUsernameCheck(response.payload.status, true,
							() => {
								setUsername(element.value)
								setLoginStage("password")
							})}
					break
				case "password":
					element = document.getElementById("password-input")
					response = await linkAccount(element.value)
					// console.log(response)
					if (response.status === SERVER_RESPONSE_STATE.FAIL) {
						respondToPasswordCheck(response.payload.status)
					} else if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.onSuccess(response.payload)
					}
					break
				default:
					break
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = loginStage === "username" ?
		<MobileUsernameField id={"username"} form={"linked-account-form"}
			className={"paralian-theme-level-1 w3-animate-opacity"} topRadius={"1vh"} bottomRadius={"1vh"}/> :
		<MobileInputPassword id={"password"} form={"linked-account-form"} autoComplete={false} username={username}
			className={"paralian-theme-level-1 w3-animate-opacity"}	autoFocus={true} showLabel={false}
			topRadius={"1vh"} bottomRadius={"1vh"}/>
	return <MobileInputForm id={"linked-account"} content={content} onSubmit={onSubmit}/>
}

export default MobileNewLinkedAccount