import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {ORDER_CHARGE_STATE, ORDER_STATE} from "../../../globals/constants/shared/enumerators"
import {ccBrandToLabelAndIcon, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import Modal from "../../../globals/components/Modal"
import AcceptOrderButton from "./orderCard/buttons/AcceptOrderButton"
import ConfirmOrderButton from "./orderCard/buttons/ConfirmOrderButton"
import DeclineOrderButton from "./orderCard/buttons/DeclineOrderButton"
import OrderDetailsButton from "./orderCard/buttons/OrderDetailsButton"
import ProposeAdjustOrderPriceButton from "./orderCard/buttons/ProposeAdjustOrderPriceButton"
import ProposeAdjustOrderDueDateButton from "./orderCard/buttons/ProposeAdjustOrderDueDateButton"
import RespondToOrderAdjustButton from "./orderCard/buttons/RespondToOrderAdjustButton"
import RejectOrderButton from "./orderCard/buttons/RejectOrderButton"
import AssignOrderButton from "./orderCard/buttons/AssignOrderButton"
import StartOrderButton from "./orderCard/buttons/StartOrderButton"
import CompleteOrderButton from "./orderCard/buttons/CompleteOrderButton"
import IncompleteOrderButton from "./orderCard/buttons/IncompleteOrderButton"
import RefundOrderButton from "./orderCard/buttons/RefundOrderButton"
import DisputeOrderButton from "./orderCard/buttons/DisputeOrderButton"
import CancelOrderButton from "./orderCard/buttons/CancelOrderButton"
import CancelRecurrenceButton from "./orderCard/buttons/CancelRecurrenceButton"
import SetAsFavoriteButton from "./orderCard/buttons/SetAsFavoriteButton"
import RateOrderButton from "./orderCard/buttons/RateOrderButton"
import OrderReceiptButtons from "./orderCard/buttons/OrderReceiptButtons"
import EditOrderRecurrenceButton from "./orderCard/buttons/EditOrderRecurrenceButton"
import MakeOrderRecurringButton from "./orderCard/buttons/MakeOrderRecurringButton"
import ChangePaymentMethodButton from "./orderCard/buttons/ChangePaymentMethodButton"
import OrderDetailsModal from "./orderCard/modals/OrderDetailsModal"
import ProposeAdjustOrderPriceModal from "./orderCard/modals/ProposeAdjustOrderPriceModal"
import MobileAdjustRespondModal from "./orderCard/modals/MobileAdjustRespondModal"
import ProposeAdjustOrderDueDateModal from "./orderCard/modals/ProposeAdjustOrderDueDateModal"
import AssignOrderModal from "./orderCard/modals/AssignOrderModal"
import RefundOrderModal from "./orderCard/modals/RefundOrderModal"
import DisputeOrderModal from "./orderCard/modals/DisputeOrderModal"
import RateOrderModal from "./orderCard/modals/RateOrderModal"
import ConfirmOrderModal from "./orderCard/modals/ConfirmOrderModal"
import RecurrenceModal from "./orderCard/modals/RecurrenceModal"
import ChangePaymentMethodModal from "./orderCard/modals/ChangePaymentMethodModal"
import IncompleteOrderModal from "./orderCard/modals/IncompleteOrderModal"
import MobileOrganizationPublicProfile from "../organization/MobileOrganizationPublicProfile"
import PayButton from "./orderCard/buttons/PayButton"
import MarkPaidButton from "./orderCard/buttons/MarkPaidButton"
import MarkUnpaidButton from "./orderCard/buttons/MarkUnpaidButton"
import PayModal from "./orderCard/modals/PayModal"
import Currency from "../../../globals/classes/shared/Currency"
import ContactInfo from "../../../globals/classes/shared/ContactInfo"
import Websites from "../../../globals/classes/shared/Websites"

const MobileOrderCard = props => {
	/* TODO: show rating for order */
	/* TODO: include customer rating when viewed as provider */
	/* TODO: include special requests message section within order details */
	/* TODO: include upload document button when viewed as provider */
	/* TODO: if there's a problem with the payment, provide a link to where it can be completed - order adjustments */
	const [dropdown, setDropdown] = useState(false)
	const [showOrderDetails, setShowOrderDetails] = useState(false)
	const [showRateModal, setShowRateModal] = useState(false)
	const [showProposeAdjustPriceModal, setShowProposeAdjustPriceModal] = useState(false)
	const [showProposeAdjustDueDateModal, setShowProposeAdjustDueDateModal] = useState(false)
	const [showAssignModal, setShowAssignModal] = useState(false)
	const [showRefundModal, setShowRefundModal] = useState(false)
	const [showDisputeModal, setShowDisputeModal] = useState(false)
	const [showAdjustRespondModal, setShowAdjustRespondModal] = useState(false)
	const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false)
	const [showRecurrenceModal, setShowRecurrenceModal] = useState(false)
	const [showProviderModal, setShowProviderModal] = useState(false)
	const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] = useState(false)
	const [showIncompleteOrderModal, setShowIncompleteOrderModal] = useState(false)
	const [showPayModal, setShowPayModal] = useState(false)
	const [contactInfo, setContactInfo] = useState(new ContactInfo())
	const [websites, setWebsites] = useState(new Websites())
	const order = props.order
	// console.log(order)
	const currency = new Currency(order.currency)
	// console.log("order.state", order.state, "order.isCustomer", order.isCustomer)
	let status = window.textToIcon(order.state.toLowerCase())
	if (props.order.buttons.changePaymentMethod && order.isCustomer &&
		order.state !== ORDER_STATE.INCOMPLETE && order.state !== ORDER_STATE.INCOMPLETE_CUSTOMER) {
		status = window.textToIcon("funds_unavailable-customer")
	}
	if (order.state !== ORDER_STATE.ABORTED && order.charge === ORDER_CHARGE_STATE.HOLD_FAILED && !order.isCustomer) {
		status = window.textToIcon("funds_unavailable")
	}
	if ((props.order.adjustments.items.length > 0 || props.order.adjustments.dueDate ||
		order.state === ORDER_STATE.CONFIRM) && !order.isCustomer) {
		status = window.textToIcon("adjust-provider")
	}
	if ((props.order.adjustments.items.length > 0 || props.order.adjustments.dueDate ||
		order.state === ORDER_STATE.CONFIRM) && order.isCustomer) {
		status = window.textToIcon("adjust")
	}
	if (order.state === ORDER_STATE.INCOMPLETE_CUSTOMER) {
		status = window.textToIcon("incomplete")
	}
	if ([ORDER_STATE.INCOMPLETE, ORDER_STATE.INCOMPLETE_CUSTOMER].includes(order.state) &&
		[ORDER_CHARGE_STATE.METER_UNUSED, ORDER_CHARGE_STATE.CANCELLED_PAID,
		ORDER_CHARGE_STATE.CANCELLED_UNPAID].includes(order.charge)) {
		status = window.textToIcon("aborted")
	}
	if (order.state === ORDER_STATE.COMPLETED && [ORDER_CHARGE_STATE.CANCELLED_PAID,
		ORDER_CHARGE_STATE.CANCELLED_UNPAID].includes(order.charge)) {
		status = window.textToIcon("completed")
	}
	if (![ORDER_CHARGE_STATE.PAID, ORDER_CHARGE_STATE.UNPAID, ORDER_CHARGE_STATE.CAPTURED,
			ORDER_CHARGE_STATE.METER_UNUSED, ORDER_CHARGE_STATE.CANCELLED_PAID, ORDER_CHARGE_STATE.CANCELLED,
			ORDER_CHARGE_STATE.CANCELLED_UNPAID].includes(order.charge) &&
		order.isCustomer && (props.order.buttons.changePaymentMethod || props.order.buttons.pay) &&
		(order.charge === ORDER_CHARGE_STATE.CAPTURE_FAILED ||
			(order.charges.total.requested !== null &&
				(order.state === ORDER_STATE.INCOMPLETE || order.state === ORDER_STATE.INCOMPLETE_CUSTOMER)))) {
		status = window.textToIcon("payment-requested-customer")
	}
	if (![ORDER_CHARGE_STATE.PAID, ORDER_CHARGE_STATE.UNPAID, ORDER_CHARGE_STATE.CAPTURED,
			ORDER_CHARGE_STATE.METER_UNUSED, ORDER_CHARGE_STATE.CANCELLED_PAID, ORDER_CHARGE_STATE.CANCELLED,
			ORDER_CHARGE_STATE.CANCELLED_UNPAID].includes(order.charge) &&
		!order.isCustomer && (order.charge === ORDER_CHARGE_STATE.CAPTURE_FAILED ||
			(order.charges.total.requested !== null &&
				(order.state === ORDER_STATE.INCOMPLETE || order.state === ORDER_STATE.INCOMPLETE_CUSTOMER)))) {
		status = window.textToIcon("payment-requested-provider")
	}
	const type = window.textToIcon(order.category.name.replace(/_/g, " "))
	const date = new Date(order.opened).toLocaleString(undefined,
		{day: "numeric", month: "short", year: "numeric"})
	const dueDate = {
		date: new Date(order.dueDate).toLocaleString(undefined,
			{day: "numeric", month: "short", year: "numeric"}),
		time: new Date(order.dueDate).toLocaleString(undefined,
			{hour: "numeric", minute: "numeric", timeZoneName: "short"})
	}
	const accessStart = {
		date: new Date(order.accessStart).toLocaleString(undefined,
			{day: "numeric", month: "short", year: "numeric"}),
		time: new Date(order.accessStart).toLocaleString(undefined,
			{hour: "numeric", minute: "numeric", timeZoneName: "short"})
	}
	const update = {
		date: new Date(order.updated).toLocaleString(undefined,
			{day: "numeric", month: "short", year: "numeric"}),
		time: new Date(order.updated).toLocaleString(undefined,
			{hour: "numeric", minute: "numeric", timeZoneName: "short"})
	}
	const dataFilterArray = props.dataFilterArray
	/* TODO: include name of provider with onClick to launch modal with profile */
	/* TODO: include name of vessel with onClick to launch modal with profile. gather data from customer vessels or own vessels */
	/* TODO: include name of marina with onClick to launch modal with profile */
	// buttons
	const accept = <AcceptOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const orderDetailsButton = <OrderDetailsButton order={order} setShowOrderDetails={setShowOrderDetails}/>
	const cancelRecurrenceButton = <CancelRecurrenceButton order={order} refreshOrders={props.refreshOrders}/>
	const changePaymentMethodButton = <ChangePaymentMethodButton order={order}
		setShowChangePaymentMethodModal={setShowChangePaymentMethodModal}/>
	const confirm = <ConfirmOrderButton order={order} setShowConfirmOrderModal={setShowConfirmOrderModal}/>
	const decline = <DeclineOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const proposeAdjustPrice = <ProposeAdjustOrderPriceButton order={order}
		setShowProposeAdjustPriceModal={setShowProposeAdjustPriceModal}/>
	const proposeAdjustDueDate = <ProposeAdjustOrderDueDateButton order={order}
		setShowProposeAdjustDueDateModal={setShowProposeAdjustDueDateModal}/>
	const adjustRespond = <RespondToOrderAdjustButton order={order}
		setShowAdjustRespondModal={setShowAdjustRespondModal}/>
	const reject = <RejectOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const assign = <AssignOrderButton order={order} setShowAssignModal={setShowAssignModal}/>
	const start = <StartOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const makeRecurring = <MakeOrderRecurringButton order={order} setShowRecurrenceModal={setShowRecurrenceModal}/>
	const editRecurrence = <EditOrderRecurrenceButton order={order} setShowRecurrenceModal={setShowRecurrenceModal}/>
	/*
	const contact = order.buttons.contact ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"block"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: order.isCustomer ? translate(DICTIONARY.CONTACT_PROVIDER.X) :
				translate(DICTIONARY.CONTACT_CUSTOMER.X)}} left={{icon: ICONS.PEOPLE_ARROWS}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%"}} onClick={async () => {
					/!* TODO: contact customer/provider *!/
				try {
					const response = await assignOrder(order.id)
					// console.log(response)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.refreshOrders()
						fadingMessage("contact-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
					}
				} catch (e) {
					console.log(e)
				}
				// console.log("contact")
			}}/>
		</div> : <></>
	 */
	const complete = <CompleteOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const incomplete = <IncompleteOrderButton order={order} setShowIncompleteOrderModal={setShowIncompleteOrderModal}/>
	const refund = <RefundOrderButton order={order} setShowRefundModal={setShowRefundModal}/>
	const favorite = <SetAsFavoriteButton order={order} refreshOrders={props.refreshOrders}/>
	const cancel = <CancelOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const dispute = <DisputeOrderButton order={order} setShowDisputeModal={setShowDisputeModal}
		setContactInfo={setContactInfo} setWebsites={setWebsites}/>
	const rate = <RateOrderButton order={order} setShowRateModal={setShowRateModal}/>
	const receipt = <OrderReceiptButtons order={order}/>
	const pay = <PayButton order={order} setShowPayModal={setShowChangePaymentMethodModal}/>
	const paid = <MarkPaidButton order={order} refreshOrders={props.refreshOrders}/>
	const unpaid = <MarkUnpaidButton order={order} refreshOrders={props.refreshOrders}/>
	// modals
	const orderDetailsModal = <OrderDetailsModal account={props.account} order={order}
		setShowOrderDetails={setShowOrderDetails}	showOrderDetails={showOrderDetails} services={props.services}
		currency={currency}/>
	const payModal = <PayModal account={props.account} order={order}
		setShowPayModal={setShowPayModal}	showPayModal={showPayModal} services={props.services}
		currency={currency}/>
	const confirmOrderModal = <ConfirmOrderModal order={order} showConfirmOrderModal={showConfirmOrderModal}
		setShowConfirmOrderModal={setShowConfirmOrderModal}	organization={props.organization} account={props.account}
		refreshOrganization={props.refreshOrganization}	refreshAccount={props.refreshAccount}
		refreshOrders={props.refreshOrders} services={props.services}/>
	const proposeAdjustPriceModal = <ProposeAdjustOrderPriceModal order={order} currency={currency}
		setShowProposeAdjustPriceModal={setShowProposeAdjustPriceModal}	services={props.services}
		showProposeAdjustPriceModal={showProposeAdjustPriceModal}	refreshOrders={props.refreshOrders}/>
	const proposeAdjustDueDateModal = <ProposeAdjustOrderDueDateModal order={order}
		showProposeAdjustDueDateModal={showProposeAdjustDueDateModal}	dueDate={dueDate} account={props.account}
		setShowProposeAdjustDueDateModal={setShowProposeAdjustDueDateModal}	refreshOrders={props.refreshOrders}/>
	const incompleteOrderModal = <IncompleteOrderModal order={order} currency={currency}
		setShowIncompleteOrderModal={setShowIncompleteOrderModal}	services={props.services}
		showIncompleteOrderModal={showIncompleteOrderModal}	refreshOrders={props.refreshOrders}/>
	// console.log("indexedServices", indexedServices)
	const adjustRespondModal = <MobileAdjustRespondModal showAdjustRespondModal={showAdjustRespondModal}
		setShowAdjustRespondModal={setShowAdjustRespondModal}	order={order}	refreshOrders={props.refreshOrders}
		services={props.services} organization={props.organization} account={props.account} currency={currency}
		refreshOrganization={props.refreshOrganization}	refreshAccount={props.refreshAccount}/>
	const changePaymentMethodModal = <ChangePaymentMethodModal account={props.account} currency={currency}
		refreshAccount={props.refreshAccount} order={order} showChangePaymentMethodModal={showChangePaymentMethodModal}
		setShowChangePaymentMethodModal={setShowChangePaymentMethodModal} refreshOrders={props.refreshOrders}/>
	/* TODO: GET RECURRENCE DATA FROM ORDER OBJECT */
	const recurrenceModal = <RecurrenceModal showRecurrenceModal={showRecurrenceModal}
		setShowRecurrenceModal={setShowRecurrenceModal} order={order}	form={"recurrence"}
		use24Hr={props.account.preferences.use24Hr}	refreshOrders={props.refreshOrders}/>
	const assignModal = <AssignOrderModal order={order} setShowAssignModal={setShowAssignModal}
		showAssignModal={showAssignModal} refreshOrders={props.refreshOrders}	organization={props.organization}/>
	const refundModal = <RefundOrderModal order={order} showRefundModal={showRefundModal} currency={currency}
		setShowRefundModal={setShowRefundModal} organization={props.organization}	refreshOrders={props.refreshOrders}/>
	const disputeModal = <DisputeOrderModal order={order} setShowDisputeModal={setShowDisputeModal}
		websites={websites}	showDisputeModal={showDisputeModal} organization={props.organization}
		contactInfo={contactInfo}/>
	/* TODO: show rating & maybe hide rating button of those already rated */
	const rateModal = <RateOrderModal order={order} setShowRateModal={setShowRateModal}
		showRateModal={showRateModal} organization={props.organization} refreshOrders={props.refreshOrders}/>
	const orderPaymentMethod = props.account.payment.methods.cards.find(i => i.id === order.paymentMethod)
	const paymentMethodDate = new Date()
	if (orderPaymentMethod !== undefined) {
		paymentMethodDate.setUTCMonth(orderPaymentMethod.exp_month - 1)
		paymentMethodDate.setUTCFullYear(orderPaymentMethod.exp_year)
	}
	const paymentMethod = orderPaymentMethod !== undefined ?
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
				{translate(DICTIONARY.ASSIGNED_PAYMENT_METHOD.X)}
			</div>
			<div style={{display: "flex", width: "100%"}}>
				<MobileFontAwesome icon={ccBrandToLabelAndIcon(orderPaymentMethod.brand).icon}
			 		label={ccBrandToLabelAndIcon(orderPaymentMethod.brand).label}
					style={{marginRight: "2vh", fontSize: "6vh"}}/>
				<div style={{display: "block", textAlign: "left", margin: "auto 0vw", width: "100%", fontSize: "2vh"}}>
					<div><b>{ccBrandToLabelAndIcon(orderPaymentMethod.brand).label} </b><b>*{orderPaymentMethod.last4}</b></div>
					<div>
						{`${translate(DICTIONARY.EXPIRES.X)} ${date.toLocaleString(undefined,
							{timeZone: "UTC", month: "short", year: "numeric"})}`}
					</div>
				</div>
			</div>
		</div> : <></>
	const providerModal = showProviderModal ?
		<Modal padding={"0vh"} title={order.provider.name.display} onClick={() => setShowProviderModal(false)}
			id={"provider-public-profile"} body={<MobileOrganizationPublicProfile id={order.provider.id}
			setAccount={() => {}} color={"paralian-theme-level-1"}/>}/> : <></>
	const assignedEmployee = order.assignedEmployee.name ?
		<div style={{display: "flex", padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
				{translate(DICTIONARY.ASSIGNED_EMPLOYEE.X)}
			</div>
			<div style={{textAlign: "right", width: "100%"}}>
				{order.assignedEmployee.name.given}
			</div>
		</div> : <></>
	const assignedGroup = order.assignedGroup.name && !order.isCustomer ?
		<div style={{display: "flex", padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
				{translate(DICTIONARY.ASSIGNED_GROUP.X)}
			</div>
			<div style={{textAlign: "right", width: "100%"}}>
				{order.assignedGroup.name}
			</div>
		</div> : <></>
	const orderBasics = <div style={{display: "flex", padding: "1vh"}}>
		<div style={{minWidth: "40%"}}>
			<div style={{fontSize: "6.5vh"}}>
				<MobileFontAwesome icon={status.icon} style={{color: status.color}} label={status.text}/>
			</div>
			<div>
				{status.text}
			</div>
		</div>
		<div style={{fontSize: "2.5vh", width: "100%"}}>
			<div style={{margin: "1vh 2vw", textAlign: "right"}}>
				<div>
					{type.text}
				</div>
				<div style={{fontSize: "1.5vh", opacity: "0.75"}}>
					{translate(DICTIONARY.ORDER_TYPE.X)}
				</div>
			</div>
			<div style={{margin: "1vh 2vw", textAlign: "right"}}>
				<div>
					{date}
				</div>
				<div style={{fontSize: "1.5vh", opacity: "0.75"}}>
					{translate(DICTIONARY.ORDER_DATE.X)}
				</div>
			</div>
		</div>
	</div>

	const dropdown_ = !dropdown ? <></> :
		<Modal id={`order-${order.id}-modal`} icon={ICONS.CALENDAR_ALT} onClick={() => setDropdown(false)} padding={"0vh"}
			title={translate(DICTIONARY.ORDER.X) + " " + order.id}
			body={<div className={"paralian-theme-level-2"} style={{margin: "2vh", padding: "2vh 0vh", borderRadius: "1vh"}}>
			{orderBasics}
			{changePaymentMethodModal}
			{orderDetailsModal}
			{proposeAdjustPriceModal}
			{proposeAdjustDueDateModal}
			{assignModal}
			{refundModal}
			{rateModal}
			{disputeModal}
			{adjustRespondModal}
			{confirmOrderModal}
			{recurrenceModal}
			{providerModal}
			{incompleteOrderModal}
			{payModal}
			<div style={{padding: "2vh"}}>
				<div style={{display: "flex", padding: "1vh 0vh"}}>
					<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
						{translate(DICTIONARY.PROVIDER.X)}
					</div>
					<div style={{textAlign: "right", width: "100%", fontSize: "3vh"}} className={"w3-ripple"}
							 onClick={() => setShowProviderModal(true)}>
						{order.provider.name.display}
					</div>
				</div>
				<div style={{display: "flex", padding: "1vh 0vh"}}>
					<div style={{
						textAlign: "left",
						fontSize: "1.5vh",
						opacity: "0.75",
						minWidth: "10%"
					}}>
						{translate(DICTIONARY.VESSEL.X)}
					</div>
					<div style={{textAlign: "right", width: "90%"}}>
						{order.vessel.name.display ?
							<div style={{textAlign: "right", width: "100%"}}>
								{order.vessel.name.display}
							</div> : <></>}
						{order.vessel.hull.brand ?
							<div style={{textAlign: "right", width: "100%"}}>
								{order.vessel.hull.brand + (order.vessel.hull.model ? ` | ${order.vessel.hull.model}` : "") +
									(order.vessel.hull.year ? ` | ${order.vessel.hull.year}` : "")}
							</div> : <></>}
					</div>
				</div>
				<div style={{display: "flex", padding: "1vh 0vh"}}>
					<div style={{
						textAlign: "left",
						fontSize: "1.5vh",
						opacity: "0.75",
						minWidth: "10%"
					}}>
						{translate(DICTIONARY.MARINA.X)}
					</div>
					<div style={{textAlign: "right", width: "90%"}}>
						{order.marina.name.display}
					</div>
				</div>
				{order.vessel.home.unit ?
					<div style={{display: "flex", padding: "1vh 0vh"}}>
						<div style={{
							textAlign: "left",
							fontSize: "1.5vh",
							opacity: "0.75",
							minWidth: "10%"
						}}>
							{translate(DICTIONARY.LOCATION.X)}
						</div>
						<div style={{textAlign: "right", width: "90%"}}>
							<div style={{textAlign: "right", width: "100%"}}>
								{order.vessel.home.unit}
							</div>
							{order.vessel.home.facility ?
								<div style={{textAlign: "right", width: "100%"}}>
									{order.vessel.home.facility}
								</div> : <></>}
						</div>
					</div> : <></>}
				<div style={{display: "flex", padding: "1vh 0vh"}}>
					<div style={{
						textAlign: "left",
						fontSize: "1.5vh",
						opacity: "0.75",
						minWidth: "10%"
					}}>
						{translate(DICTIONARY.ACCESS_START_DATE.X)}
					</div>
					<div style={{textAlign: "right", width: "90%"}}>
						<div style={{textAlign: "right", width: "100%"}}>
							{accessStart.date}
						</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{accessStart.time}
						</div>
					</div>
				</div>
				<div style={{display: "flex", padding: "1vh 0vh"}}>
					<div style={{
						textAlign: "left",
						fontSize: "1.5vh",
						opacity: "0.75",
						minWidth: "10%"
					}}>
						{translate(DICTIONARY.DUE_DATE.X)}
					</div>
					<div style={{textAlign: "right", width: "90%"}}>
						<div style={{textAlign: "right", width: "100%"}}>
							{dueDate.date}
						</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{dueDate.time}
						</div>
					</div>
				</div>
				<div style={{display: "flex", padding: "1vh 0vh"}}>
					<div style={{
						textAlign: "left",
						fontSize: "1.5vh",
						opacity: "0.75",
						minWidth: "10%"
					}}>
						{translate(DICTIONARY.STATUS_UPDATED.X)}
					</div>
					<div style={{textAlign: "right", width: "90%"}}>
						<div style={{textAlign: "right", width: "100%"}}>
							{update.date}
						</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{update.time}
						</div>
					</div>
				</div>
				{order.specialRequests ?
					<div style={{padding: "1vh 0vh"}}>
						<div style={{
							textAlign: "left",
							fontSize: "1.5vh",
							opacity: "0.75"
						}}>
							{translate(DICTIONARY.CUSTOMER_NOTES.X)}
						</div>
						<div style={{textAlign: "justify", paddingTop: "1vh"}}>
							{order.specialRequests}
						</div>
					</div> : <></>
				}
				{assignedEmployee}
				{assignedGroup}
				{paymentMethod}
			</div>
			{orderDetailsButton}
			{confirm}
			{decline}
			{accept}
			{reject}
			{assign}
			{changePaymentMethodButton}
			{refund}
			{adjustRespond}
			{proposeAdjustPrice}
			{proposeAdjustDueDate}
			{cancel}
			{dispute}
			{rate}
			{/*blockAccount*/}
			{/*blockVessel*/}
			{/*blockOrganization*/}
			{favorite}
			{start}
			{/*contact*/}
			{makeRecurring}
			{editRecurrence}
			{cancelRecurrenceButton}
			{complete}
			{incomplete}
			{receipt}
			{pay}
			{paid}
			{unpaid}
		</div>}/>
			return <div id={`${order.id}-orderCard`} style={{width: "100%"}} data-filter-array={dataFilterArray}>
			{dropdown_}
			<div className={"paralian-theme-level-1-flipped"} style={{
				display: "flex", margin: "2vh 0vh 0.5vh 0vh",
				padding: "1vh 2vw", width: "100%", borderRadius: "1vh 1vh 0vh 0vh"
			}}>
				<div style={{textAlign: "left", opacity: "0.75", width: "50%"}}>
					<MobileFontAwesome icon={type.icon} style={{fontWeight: "bold", paddingRight: "2vh"}} label={type.text}/>
					{translate(DICTIONARY.ORDER_NUMBER.X)}
				</div>
				<div style={{textAlign: "right", width: "50%"}}>
					<b>{order.id}</b>
				</div>
			</div>
			<div className={"paralian-theme-level-2 w3-ripple"} style={{cursor: "pointer", borderRadius: "0vh 0vh 1vh 1vh"}}
					 onClick={() => setDropdown(!dropdown)}>
				{props.order.recurrence.id !== null && props.order.recurrence.next !== null &&
					props.order.recurrence.state !== "CANCELLED" && props.order.recurrence.state !== "SUPERSEDED"?
					<div style={{padding: "1vh 1vh 0vh 1vh"}}>
						<div className={"paralian-theme-level-1"} style={{padding: "1vh", borderRadius: "1vh"}}>
							<MobileFontAwesome icon={ICONS.REPEAT} label={translate(DICTIONARY.RECURRING_ORDER.X)}
								style={{paddingRight: "1vh"}}/>
								{translate(DICTIONARY.RECURRING_ORDER.X)}
						</div>
					</div> : <></>
				}
				{orderBasics}
			</div>
		</div>
			}

export default MobileOrderCard