import React from "react"
import {DICTIONARY, TIME_NAMES} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileDayFields from "./MobileDayFields"

/**
 *
 * @param {Object} props
 * @param {Week} props.week
 * @param {boolean} props.use24Hr
 * @returns {JSX.Element}
 * @constructor
 */
const MobileWeekFields = props => {
	return TIME_NAMES.day.map(day => <div key={day} style={{display: "inline-flex", width: "100%"}}
		className={"w3-animate-opacity"}>
		<div style={{textAlign: "left", width: "100%"}}>
			<div style={{padding: "0.5vh 2vh 0.5vh 0vh"}}>
				{`${translate(DICTIONARY[day.toUpperCase()].X)}: `}
			</div>
		</div>
		<div style={{textAlign: "right", width: "100%"}}>
			<MobileDayFields day={props.week[day]} name={day} use24Hr={props.use24Hr} style={null}/>
		</div>
	</div>)
}

export default MobileWeekFields