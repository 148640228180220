import React, {useEffect} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {dateToYYYYMMDD,	getFromStorage, newMessage,	replaceInStorage,	translate} from "../../../../globals/functions/client/localFunctions"
import DateAndTime from "./DateAndTime"
import MobileFontAwesome from "../../MobileFontAwesome"
import Time from "../../../../globals/classes/shared/Time"

const DueDate = props => {
	const session = getFromStorage("order", "session")
	const defaultTime = session.dueTime ? new Time(session.dueTime) : {hour: 8, minute: 0, xm: null}
	useEffect(() => {
		if (!session.dueTime) {replaceInStorage("order", "dueTime", defaultTime, "session")}
	}, [])
	return <DateAndTime id={"due"} minDate={dateToYYYYMMDD(new Date())} defaultDate={session.dueDate}
		use24Hr={props.use24Hr} legend={<>
		{translate(DICTIONARY.DUE_DATE.X)}
		<MobileFontAwesome id={"due-date"} icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.DUE_DATE.X)}
			style={{paddingLeft: "1vh"}} onClick={() => newMessage("dueDate", translate(DICTIONARY.DUE_DATE.X),
			translate(DICTIONARY.DUE_DATE_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
	</>} defaultTime={defaultTime}
		setTime={v => replaceInStorage("order", "dueTime", v, "session")}
		setDate={v => replaceInStorage("order", "dueDate", v, "session")}/>
}

export default DueDate