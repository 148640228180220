import React, {useState, useEffect} from "react"
import ICONS from "../globals/constants/client/icons"
import {connectionCheck, moveFrame} from "../globals/functions/client/localFunctions"
import MobileFontAwesome from "../mobile/main/MobileFontAwesome"

const ImageFramePlayer = props => {
	const [windMapObject, setFrame] = useState({
		frame: props.frames.min, path: props.path.prefix + props.frames.min + props.path.suffix})
	const [intervalId, setIntervalId] = useState(null)
	// clear interval when unmounting component
	useEffect(() => {
		connectionCheck()
		// console.log("useEffect called")
		return () => window.clearInterval(intervalId)
	},[intervalId])
	const imageFramePlayerStyle = {height:"60vh", margin: "2vh 0vh", borderRadius: "1vh"}
	// const imageFrameImageStyle = {maxWidth: "92vw", maxHeight: "65vh"}
	return <div id={"imageFramePlayer"} style={imageFramePlayerStyle}>
		<div className={"w3-display-middle"} style={{width: "100%", padding: "2vh"}}>
			<picture>
				<img id={"imageFrameImage"} src={windMapObject.path} alt={"wind and waves"} style={{// ...imageFrameImageStyle,
					minWidth: "100%", width: "100%", objectFit: "contain"}}/>
			</picture>
			<div style={{paddingTop: "2vh"}}>
				<MobileFontAwesome icon={ICONS.STEP_BACKWARD} style={{padding: "1.5vh"}}
					onClick={() => {
						setFrame(moveFrame(
							windMapObject.frame, -1, props.path.prefix, props.path.suffix,
							props.frames.min, props.frames.max))}}/>
				<MobileFontAwesome icon={ICONS.PLAY} style={{padding: "1.5vh"}}
					onClick={() => {
						// console.log(intervalId)
						if (intervalId) {window.clearInterval(intervalId)}
						setIntervalId(window.setInterval(() => {
						setFrame(windMapObject => moveFrame(
							windMapObject.frame, 1, props.path.prefix, props.path.suffix,	props.frames.min, props.frames.max))
					},1000))}}/>
				<MobileFontAwesome icon={ICONS.PAUSE} style={{padding: "1.5vh"}}
					 onClick={() => window.clearInterval(intervalId)}/>
				<MobileFontAwesome icon={ICONS.STOP} style={{padding: "1.5vh"}}
					 onClick={() => {
						 window.clearInterval(intervalId)
						 setFrame({frame: props.frames.min, path: props.path.prefix + props.frames.min + props.path.suffix})}}/>
				<MobileFontAwesome icon={ICONS.STEP_FORWARD} style={{padding: "1.5vh"}}
					onClick={() => {
						setFrame(moveFrame(
							windMapObject.frame, 1, props.path.prefix, props.path.suffix,
							props.frames.min, props.frames.max))}}/>
			</div>
		</div>
	</div>
}

export default ImageFramePlayer