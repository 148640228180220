import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {togglePasswordVisibility, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"
import Icon from "../../../../globals/classes/shared/Icon"

/**
 *
 * @param {Object} props
 * @param {Object} props.style - style object of container's top level element
 * @param {string} props.className - className(s) of container's top level element
 * @param {string} props.id
 * @param {string} props.form
 * @param {boolean} props.showLabel
 * @param {string} props.name
 * @param {string} props.username
 * @param {boolean} props.autoFocus
 * @param {boolean} props.topRadius
 * @param {boolean} props.bottomRadius
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCurrentPassword = props => {
	const [icon, setIcon] = useState(ICONS.EYE_SLASH)
	const left = new Icon(props.left)
	if (props.left) {if (props.left.icon === " ") {left.icon = " "}}
	const right = new Icon(props.right)
	return <MobileInputGeneric type={"password"} id={props.id} label={translate(DICTIONARY.CURRENT_PASSWORD.X)}
		showLabel={props.showLabel}	className={props.className} style={props.style} bottomRadius={props.bottomRadius}
		topRadius={props.topRadius} username={props.username} form={props.form} autoComplete={"current-password"}
		placeholder={translate(DICTIONARY.CURRENT_PASSWORD.X)} autoFocus={props.autoFocus} name={props.name} left={left}
	 	right={{icon: icon, onClick: () => {
			togglePasswordVisibility(`${props.id}-input`,`${props.id}-right`, setIcon)
				right.onClick()
			}}}/>
}

export default MobileInputCurrentPassword