import React, {useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {ORGANIZATION_TYPE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getFromStorage,		translate} from "../../../../globals/functions/client/localFunctions"
import {registerOrganization} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import MobileBusinessTypeDropdown from "../../../shared/input/dropdown/MobileBusinessTypeDropdown"
import MobileBusinessStructureDropdown from "../../../shared/input/dropdown/MobileBusinessStructureDropdown"

const MobileLoginOrgType = props => {
	const [type, setType] = useState(ORGANIZATION_TYPE.INDIVIDUAL)
	const structure = type === ORGANIZATION_TYPE.INDIVIDUAL ? <></> :
		<MobileBusinessStructureDropdown id={"structure"} form={"form"} showLabel={true}/>
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const name = {
				legal: document.getElementById("legal-name-input").value,
				display: document.getElementById("display-name-input").value
			}
			const type = document.getElementById("type-input").value
			const structure = type === ORGANIZATION_TYPE.INDIVIDUAL ? null :
				document.getElementById("structure-input").value
			const website = document.getElementById("website-input").value
			const services = getFromStorage("services", "session")
			
			const response = await registerOrganization(name.legal, name.display,	type.toString().toUpperCase(), services,
				structure ? structure.toString().toUpperCase() :
					null, getFromStorage("title", "session"), website)
			
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				sessionStorage.removeItem("title")
				sessionStorage.removeItem("services")
				store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			}
		} catch (e) {
			console.log(e)
			
		}
	}
	{/* TODO: use standard patterns */}
	const content =
		<div className="w3-center"
				 style={{fontSize: "3vh"}}>
			<MobileInputText id={"legal-name"} form={"form"} className={"paralian-theme-level-1-flipped"}
				label={translate(DICTIONARY.LEGAL_NAME.X)} showLabel={true} placeholder={translate(DICTIONARY.LEGAL_NAME.X)}
				required={true} name={"legal-name"} pattern={"([a-zA-ZÀ-ž0-9-_\.' ]){2,128}"} maxLength={"128"} autoFocus={true}/>
			{/* TODO: mark as visible to users (customers/public) */}
			<MobileInputText id={"display-name"} form={"form"} className={"paralian-theme-level-1-flipped"}
				label={translate(DICTIONARY.DISPLAY_NAME.X)} showLabel={true} placeholder={translate(DICTIONARY.DISPLAY_NAME.X)}
				required={true} name={"legal-name"} pattern={"([a-zA-Z0-9-_\.' ]){2,128}"} maxLength={"128"} autoFocus={true}/>
			<MobileBusinessTypeDropdown id={"type"} form={"form"} showLabel={true} onChange={type => setType(type)} defaultValue={type}/>
			{structure}
			<MobileInputText id={"website"} form={"form"} className={"paralian-theme-level-1-flipped"}
				label={translate(DICTIONARY.WEBSITE.X)} showLabel={true} placeholder={translate(DICTIONARY.WEBSITE.X)}
				required={false} name={"legal-name"} pattern={"([a-zA-Z0-9-_\.:/ ]){2,128}"} maxLength={"128"} autoFocus={true}/>
		</div>
	return <MobileLoginFormScaffolding header={null} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgType
