import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {PRICING_STRUCTURE_UNITS, UNIT_SYSTEM} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MooringIcon from "../icons/MooringIcon"
import SlipIcon from "../icons/SlipIcon"
import TrailerIcon from "../icons/TrailerIcon"
import BoatyardIcon from "../icons/BoatyardIcon"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileInputDecimal from "../../../shared/input/text/MobileInputDecimal"
import Units from "../../../../globals/classes/client/Units"

const MobileVesselStorageFacilitiesEditCard = props => {
	const [quantity, setQuantity] = useState(props.current.quantity || 0)
	const otherIcons = {
		MOORINGS: <MooringIcon/>,
		SLIPS: <SlipIcon/>,
		TRAILERS: <TrailerIcon/>,
		CRADLES: <BoatyardIcon/>
	}
	const lengthUnits = props.units === UNIT_SYSTEM.SI ? "m" : "ft"
	const weightUnits = props.units === UNIT_SYSTEM.SI ? "kg" : "lb"
	const UNITS = new Units()
	const defaults = props.current ? {
		weight: Math.round(UNITS.convert(props.current.capacity.weight.max, PRICING_STRUCTURE_UNITS.KILOGRAM, "kg",
			false, props.units)),
		length: Math.round(UNITS.convert(props.current.capacity.length.max, PRICING_STRUCTURE_UNITS.METER, "m",
			false, props.units)),
		width: Math.round(UNITS.convert(props.current.capacity.width.max, PRICING_STRUCTURE_UNITS.METER, "m",
			false, props.units)),
		depth: Math.round(UNITS.convert(props.current.capacity.depth.max, PRICING_STRUCTURE_UNITS.METER, "m",
			false, props.units)),
		height: Math.round(UNITS.convert(props.current.capacity.height.max, PRICING_STRUCTURE_UNITS.METER, "m",
			false, props.units)),
		} : {
		weight: null,
		length: null,
		width: null,
		depth: null,
		height: null
	}
	const inputData = quantity > 0 ? <div style={{padding: "2vh"}} className={"w3-animate-top w3-animate-opacity"}>
		<div>{translate(DICTIONARY.UPPER_LIMITS.X)}</div>
		<div>
			<MobileInputDecimal className={"paralian-theme-level-4-flipped"} label={translate(DICTIONARY.WEIGHT.X)}
				showLabel={true} digits={9} decimals={0} autoFocus={false} defaultLong={defaults.weight}
				defaultValue={defaults.weight} required={false} name={`${props.facility.type}-weight`}
				id={`${props.facility.type}-weight`} units={weightUnits} form={props.form}/>
		</div>
		<div>
			<MobileInputDecimal className={"paralian-theme-level-4-flipped"} label={translate(DICTIONARY.LENGTH.X)}
				defaultValue={defaults.length} required={false}	form={props.form} name={`${props.facility.type}-length`}
				id={`${props.facility.type}-length`} digits={4} decimals={0} autoFocus={false} defaultLong={defaults.length}
				showLabel={true} units={lengthUnits}/>
		</div>
		<div>
			<MobileInputDecimal className={"paralian-theme-level-4-flipped"} label={translate(DICTIONARY.WIDTH.X)}
				defaultValue={defaults.width} required={false} form={props.form} name={`${props.facility.type}-width`}
				id={`${props.facility.type}-width`} digits={3} decimals={0} autoFocus={false} defaultLong={defaults.width}
				showLabel={true} units={lengthUnits}/>
		</div>
		<div>
			<MobileInputDecimal className={"paralian-theme-level-4-flipped"} label={translate(DICTIONARY.DRAUGHT.X)}
				defaultValue={defaults.depth} required={false} form={props.form} name={`${props.facility.type}-draught`}
				id={`${props.facility.type}-draught`} digits={3} decimals={0} autoFocus={false} defaultLong={defaults.depth}
				showLabel={true} units={lengthUnits}/>
		</div>
		<div>
			<MobileInputDecimal className={"paralian-theme-level-4-flipped"} label={translate(DICTIONARY.BRIDGE_CLEARANCE.X)}
				showLabel={true} defaultValue={defaults.height} form={props.form} name={`${props.facility.type}-height`}
				id={`${props.facility.type}-height`} digits={4} decimals={0} autoFocus={false} defaultLong={defaults.height}
				required={false} units={lengthUnits}/>
		</div>
	</div> : <></>

	return <div className={"paralian-theme-level-1"} style={{margin: "2vh 0vh", padding: "2vh"}}>
		<div style={{fontSize: "3vh"}}>
		{props.facility.iconType === "OTHER" ? otherIcons[props.facility.type] :
			<MobileFontAwesome icon={ICONS[props.facility.icon]} label={translate(props.facility.type)}
				style={{paddingRight: "1vh"}}/>}
			{translate(props.facility.type)}
		</div>
		<div>
			<MobileInputDecimal className={"paralian-theme-level-4-flipped"} label={props.facility.type === "BOAT_YARD" ?
				translate(DICTIONARY.AREA.X) : translate(DICTIONARY.QUANTITY.X)} showLabel={true}
				defaultValue={props.current.quantity} required={false}	form={props.form} name={`${props.facility.type}-quantity`}
				id={`${props.facility.type}-quantity`} digits={9} decimals={0} autoFocus={false} defaultLong={props.current.quantity}
				onChange={(x, pass) => setQuantity(pass ? x : 0)} units={props.facility.type === "BOAT_YARD" ?
					<>{lengthUnits}<sup>{"2"}</sup></> : null}/>
		</div>
		{inputData}
	</div>
}

export default MobileVesselStorageFacilitiesEditCard