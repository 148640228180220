import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {Birthdate} props.birthdate
 * @return {JSX.Element}
 * @constructor
 */
const MobileDateOfBirthField = props => {
	const [show, setShow] = useState(false)
	const full = <div className={"w3-animate-opacity"}>{props.birthdate.asLocaleString}</div>
	const hidden = <div className={"w3-animate-opacity"}>{"**/**/****"}</div>
	return <div style={{fontSize: "3vh", padding: "1vh 2vw", display: "inline-flex"}} className={"w3-animate-opacity"}>
		<div onClick={() => setShow(!show)}>{show ? full : hidden}</div>
		<MobileFontAwesome icon={ICONS.EXCLAMATION_TRIANGLE} label={translate(DICTIONARY.NOT_VERIFIED.X)}
			style={{fontSize: "1.5vh", color:"yellow"}}
			onClick={() => newMessage("dob-verification-message", translate(DICTIONARY.DOB_VERIFICATION_MESSAGE.X),
				translate(DICTIONARY.DOB_VERIFICATION_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
	</div>
}

export default MobileDateOfBirthField