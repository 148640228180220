import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {ICONS, DICTIONARY, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {storePersonalSecurityPhrase} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import submitToServer from "../../../../globals/functions/client/submitToServer"

/**
 *
 * @param {Object} props
 * @param {Object} props.next
 * @return {JSX.Element}
 * @constructor
 */
const MobileLoginPersonalSecurityPhrase = props => {
	useEffect(() => {
		if (props.message) {
			newMessage("psp-message", translate(DICTIONARY.PERSONAL_SECURITY_PHRASE.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}, [props.message])
	const onSuccess = () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
	const onSubmit = async event => {
		const psp = document.getElementById("personal-security-phrase-input").value
		await submitToServer(event, psp, storePersonalSecurityPhrase, onSuccess)
	}

	const content =	<MobileInputText id={"personal-security-phrase"} className={"paralian-theme-level-1-flipped"}
		name={"personal-security-phrase"} pattern={TEXT_FIELD_PROPERTIES.personalSecurityPhrase.pattern} autoFocus={true}
		showLabel={false} left={{icon: ICONS.USER_SECRET}} right={{icon: ICONS.INFO_CIRCLE, onClick: () => {
		newMessage("psp-message", translate(DICTIONARY.PERSONAL_SECURITY_PHRASE.X),
		translate(DICTIONARY.NEW_LOGIN_PERSONAL_SECURITY_PHRASE_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}
		placeholder={translate(DICTIONARY.PERSONAL_SECURITY_PHRASE.X)}
		label={translate(DICTIONARY.PERSONAL_SECURITY_PHRASE.X)}/>

	return <MobileLoginFormScaffolding header={null} content={content} onSubmit={onSubmit}/>
}

export default MobileLoginPersonalSecurityPhrase