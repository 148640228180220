import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {updateOrgTimezone} from "../../../globals/functions/client/serverFunctions"
import MobileInputTimezoneDropdown from "./MobileInputTimezoneDropdown"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Timezone from "../../../globals/classes/shared/Timezone"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} [props.country = "USA"] - ISO3166 3-letter code
 * @param {Timezone} props.timezone
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileTimezoneEdit = props => {
	const onSubmit = props.onSubmit ? props.onSubmit : () => {}
	const content = <MobileInputTimezoneDropdown id={props.id} form={props.id} defaultValue={props.timezone}
		country={props.country} className={"paralian-theme-level-1"} bottomRadius={"1vh"} topRadius={"1vh"}/>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={async event => {
		try {
			event.preventDefault()
			const element = document.getElementById("tz-input")
			const zone = element.getAttribute("data-id")
			const response = await updateOrgTimezone(zone)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				onSubmit(new Timezone({zone: zone}))
			}
		} catch (e) {
			console.log(e)
		}
	}}/>
}

export default MobileTimezoneEdit