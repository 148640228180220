import React from "react"
import {store} from "../../index"
import {actions} from "../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES, PAGES} from "../../globals/constants/client/constants"
import {ACCOUNT_TYPE, ADDRESS_TYPE, IMAGE_CATEGORY, VERIFICATION_TYPE} from "../../globals/constants/shared/enumerators"
import {translate, handleNewUserSubmit, getFromStorage, setToStorage} from "../../globals/functions/client/localFunctions"
import BottomQuote from "../../globals/components/BottomQuote"
import MobileParentContainer from "../shared/frames/MobileParentContainer"
import MobileHeaderIcon from "../shared/frames/MobileHeaderIcon"
import MobileLoginStart from "./base/MobileLoginStart"
import MobileBackButton from "./MobileBackButton"
import MobileLoginInvitationQ from "./MobileLoginInvitationQ"
import MobileFontAwesome from "../main/MobileFontAwesome"
import MobileLoginAccountType from "./MobileLoginAccountType"
import MobileLoginOrgServices from "./new/organization/MobileLoginOrgServices"
import MobileLoginOrgType from "./new/organization/MobileLoginOrgType"
import MobileLoginOrgColor from "./new/organization/MobileLoginOrgColor"
import MobileLoginHoursOfOperation from "./new/organization/MobileLoginHoursOfOperation"
import MobileLoginOrgAddress from "./new/organization/MobileLoginOrgAddress"
import MobileLoginOrgContactInfo from "./new/organization/MobileLoginOrgContactInfo"
import MobileLoginNewUsername from "./new/account/MobileLoginNewUsername"
import MobileLoginNewPassword from "./new/account/MobileLoginNewPassword"
import MobileLoginNewTandC from "./new/account/MobileLoginNewTandC"
import MobileLoginHolidays from "./new/organization/MobileLoginHolidays"
import MobileLoginCurrencyAndTimeZone from "./new/organization/MobileLoginCurrencyAndTimeZone"
import MobileLoginOrgPricing from "./new/organization/MobileLoginOrgPricing"
import MobileLoginNewEmail from "./new/account/MobileLoginNewEmail"
import MobileLoginNewEmailVerification from "./new/account/MobileLoginNewEmailVerification"
import MobileLoginConfirmLocation from "./new/account/MobileLoginConfirmLocation"
import MobileMessagePage from "../main/MobileMessagePage"
import MobileLoginPhysicalAddressVisitQ from "./MobileLoginPhysicalAddressVisitQ"
import MobileLoginMailingAddressQ from "./MobileLoginMailingAddressQ"
import MobileLoginServiceArea from "./MobileLoginServiceArea"
import MobileLoginOrgContactInfoVerify from "./new/organization/MobileLoginOrgContactInfoVerify"
import MobileLoginSupportAddressQ from "./MobileLoginSupportAddressQ"
import MobileLoginOrgServiceMarinas from "./new/organization/MobileLoginOrgServiceMarinas"
import MobileLoginOrgImage from "./new/organization/MobileLoginOrgImage"
import MobileInputCode from "../shared/input/text/MobileInputCode"
import MobileLoginPersonalSecurityPhrase from "./new/shared/MobileLoginPersonalSecurityPhrase"
import MobileLoginNewName from "./new/account/MobileLoginNewName"
import MobileLoginOrgCreateStripeAccount from "./new/organization/MobileLoginOrgCreateStripeAccount"
import MobileLoginDateOfBirth from "./new/shared/MobileLoginDateOfBirth"
import MobileHeaderBanner from "../shared/frames/MobileHeaderBanner"
import MobileLoginOrgAdminTitle from "./new/organization/MobileLoginOrgAdminTitle"
import setAccountType from "../../globals/functions/client/mode/setAccountType"

/* TODO: translate messages */
/* TODO: clean up this mess */
/* TODO: disable button as soon as it is pressed */
/* TODO: go directly to TOS accept when account exists but TOS have not been accepted */

/**
 * Central station for all things related to creating a new account
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} [props]
 * @param {Object} [props.origin] - last page user visited
 * @return {JSX.Element}
 * @constructor
 */
const MobileNewUser = props => {
	let window = store.getState().window.current
	if (!window.levelOne) {window = props.origin}
	const levelOne = window.levelOne
	const levelFour = window.levelFour
	let formProps = {encType: "application/x-www-form-urlencoded", onSubmit: handleNewUserSubmit}
	let content, priorWindow, backButton, isForm = true, back = true

	switch (levelFour) {
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICES_Q.X:
			return <MobileMessagePage message={<><div style={{fontSize: "4vh", paddingBottom: "2vh"}}>
				{translate(DICTIONARY.ORGANIZATION_SERVICES_Q_HEADER.X)}</div>
				<div>{translate(DICTIONARY.ORGANIZATION_SERVICES_Q_BODY_PT_1.X)}</div><div style={{paddingTop: "2vh"}}>
					{translate(DICTIONARY.ORGANIZATION_SERVICES_Q_BODY_PT_2.X)}</div></>}
					next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICES.X}
					back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ACCOUNT_TYPE.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICES.X:
			return <MobileLoginOrgServices
				header={<MobileHeaderBanner icon={ICONS.CONCIERGE_BELL} text={translate(DICTIONARY.SERVICES.X)}/>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_STEP_1.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICES_Q.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_1.X:
			return <MobileMessagePage message={<><div style={{fontSize: "4vh", padding: "2vh 0vh"}}>
				{translate(DICTIONARY.WELCOME.X)}</div>
				<div>{translate(DICTIONARY.ORGANIZATION_STEP_1_BODY.X)}</div></>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICES.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL.X:
			return <MobileLoginNewEmail message={translate(DICTIONARY.ORGANIZATION_EMAIL_MESSAGE.X)}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL_VERIFICATION.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_STEP_1.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL_VERIFICATION.X:
			return <MobileLoginNewEmailVerification next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_USERNAME.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_USERNAME.X:
			return <MobileLoginNewUsername message={translate(DICTIONARY.ORGANIZATION_USERNAME_MESSAGE.X)}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_PASSWORD.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_PASSWORD.X:
			return <MobileLoginNewPassword message={translate(DICTIONARY.ORGANIZATION_PASSWORD_MESSAGE.X)}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_PERSONAL_SECURITY_PHRASE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_USERNAME.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_PERSONAL_SECURITY_PHRASE.X:
			return <MobileLoginPersonalSecurityPhrase
				message={translate(DICTIONARY.NEW_LOGIN_PERSONAL_SECURITY_PHRASE_MESSAGE.X)}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_NAME.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_NAME.X:
			return <MobileLoginNewName next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_TITLE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.PERSONAL_SECURITY_PHRASE.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_TITLE.X:
			return <MobileLoginOrgAdminTitle next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_BIRTHDATE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_NAME.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_BIRTHDATE.X:
			return <MobileLoginDateOfBirth next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_TERMS_AND_CONDITIONS.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_ADMIN_TITLE.X}/>
		/* TODO: add "I legally represent *Company Name* an am authorized to open and administer a Paralian account on its behalf.  */
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_TERMS_AND_CONDITIONS.X:
			return <MobileLoginNewTandC login={false} next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_STEP_2.X}
				accountType={ACCOUNT_TYPE.ORGANIZATION}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_2.X:
			return <MobileMessagePage message={<><div style={{fontSize: "4vh", padding: "2vh 0vh"}}>
				<MobileFontAwesome icon={ICONS.CHECK} style={{paddingRight: "2vh"}}/>
				{translate(DICTIONARY.ORGANIZATION_STEP_2_HEADER.X)}</div>
				<div>{translate(DICTIONARY.ORGANIZATION_STEP_2_BODY.X)}</div></>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_TYPE.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_TYPE.X:
			return <MobileLoginOrgType next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_STEP_2.X}/>
				/* TODO: get contact info of person making account, particularly if business type is individual */
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO.X:
			return <MobileLoginOrgContactInfo
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO_VERIFICATION.X}
				header={<MobileHeaderBanner icon={ICONS.USER_HEADSET} text={translate(DICTIONARY.CONTACT_INFO.X)}/>}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO_VERIFICATION.X:
			return <MobileLoginOrgContactInfoVerify message={translate(DICTIONARY.CONTACT_INFO_VERIFY_MESSAGE.X)}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO.X}
				header={<MobileHeaderBanner icon={ICONS.BADGE_CHECK}
					text={`${translate(DICTIONARY.CONTACT_INFO.X)} - ${translate(DICTIONARY.VERIFICATION.X)}`}/>}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS.X:
			/* TODO: include "this is support address"  & "this is mailing address" */
			/* TODO: include toggle for "make this address public" */
			return <MobileLoginOrgAddress next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_CONFIRM_LOCATION.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO.X} type={ADDRESS_TYPE.PHYSICAL}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_CONFIRM_LOCATION.X:
			return <MobileLoginConfirmLocation message={true}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS.X}
				header={<MobileHeaderBanner icon={ICONS.MAP_MARKER_ALT} text={translate(DICTIONARY.CONFIRM_LOCATION.X)}/>}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q.X:
			return <MobileLoginPhysicalAddressVisitQ back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_CONFIRM_LOCATION.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICE_AREA.X:
			return <MobileLoginServiceArea next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_CURRENCY_AND_TIMEZONE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_CURRENCY_AND_TIMEZONE.X:
			let country, defaultTimezone = {zone: null, name: null}
			try {
				const timezone = getFromStorage("timezone", "session")
				const location = getFromStorage("location", "session")
				country = location.country
				defaultTimezone = {zone: timezone ? timezone.zone : null, name: timezone ? timezone.name : null}
			} catch (e) {
				console.log(e)
			}
			return <MobileLoginCurrencyAndTimeZone country={country} defaultTimezone={defaultTimezone}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_MAILING_ADDRESS_Q.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICE_AREA.X}
				header={<MobileHeaderBanner icon={ICONS.GLOBE_AMERICAS}
					text={`${translate(DICTIONARY.CURRENCY.X)} & ${translate(DICTIONARY.TIME_ZONE.X)}`}/>}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAILING_ADDRESS_Q.X:
			return <MobileLoginMailingAddressQ back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_CURRENCY_AND_TIMEZONE.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAILING_ADDRESS.X:
			return <MobileLoginOrgAddress next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS_Q.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_MAILING_ADDRESS_Q.X} type={ADDRESS_TYPE.MAILING}/>
		/* TODO: change to service location */
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS_Q.X:
			return <MobileLoginSupportAddressQ/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS.X:
			return <MobileLoginOrgAddress next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_HOURS_OF_OPERATION.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS_Q.X} type={ADDRESS_TYPE.SUPPORT}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_HOURS_OF_OPERATION.X:
			return <MobileLoginHoursOfOperation next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_HOLIDAYS.X}
				message={translate(DICTIONARY.HOURS_OF_OPERATION_MESSAGE.X)}
				header={<MobileHeaderBanner icon={ICONS.DOOR_OPEN} text={translate(DICTIONARY.HOURS_OF_OPERATION.X)}/>}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_HOLIDAYS.X:
			return <MobileLoginHolidays next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_PRICING.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_HOURS_OF_OPERATION.X}
				message={translate(DICTIONARY.HOLIDAYS_MESSAGE.X)}
				header={<MobileHeaderBanner icon={ICONS.UMBRELLA_BEACH} text={translate(DICTIONARY.HOLIDAYS.X)}/>}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_PRICING.X:
			return <MobileLoginOrgPricing	message={translate(DICTIONARY.PRICING_MESSAGE.X)}
				header={<MobileHeaderBanner icon={ICONS.TAGS} text={translate(DICTIONARY.PRICING.X)}/>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICE_MARINAS.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_HOLIDAYS.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICE_MARINAS.X:
			return <MobileLoginOrgServiceMarinas
				message={translate(DICTIONARY.ORGANIZATION_SERVICE_MARINA_MESSAGE.X)}
				header={<MobileHeaderBanner icon={ICONS.ANCHOR} text={translate(DICTIONARY.MARINAS_AND_STORAGE_FACILITIES.X)}/>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_REGISTRATION_DOCUMENTS.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_PRICING.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_REGISTRATION_DOCUMENTS.X:
			return <MobileMessagePage message={<><div style={{fontSize: "4vh", padding: "2vh 0vh"}}>
				{translate(DICTIONARY.DOCUMENTS.X)}</div>
				<div>{translate(DICTIONARY.NEW_ORG_ACCOUNT_DOCUMENTS_MESSAGE.X)}</div></>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_STEP_3.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICE_MARINAS.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_3.X:
			return <MobileMessagePage message={<>
				<div style={{fontSize: "4vh", padding: "2vh 0vh"}}>
					<MobileFontAwesome icon={ICONS.CHECK} style={{paddingRight: "2vh"}}/>
					{translate(DICTIONARY.ORGANIZATION_STEP_3_HEADER.X)}
				</div>
				<div>{translate(DICTIONARY.ORGANIZATION_STEP_3_BODY.X)}</div></>}
					next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_COLORS.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_COLORS.X:
			return <MobileLoginOrgColor
				header={<MobileHeaderBanner icon={ICONS.PALETTE} text={translate(DICTIONARY.BRAND_COLORS.X)}/>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_LOGO.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_STEP_4.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_LOGO.X:
			return <MobileLoginOrgImage
				header={<MobileHeaderBanner icon={ICONS.PENCIL_PAINTBRUSH} text={translate(DICTIONARY.LOGO.X)}/>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_IMAGE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_COLORS.X} category={IMAGE_CATEGORY.ORGANIZATION_LOGO}/>
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_IMAGE.X:
			return <MobileLoginOrgImage
				header={<MobileHeaderBanner icon={ICONS.IMAGE} text={translate(DICTIONARY.IMAGE.X)}/>}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_CONNECT_WITH_STRIPE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_LOGO.X} category={IMAGE_CATEGORY.ORGANIZATION_IMAGE}/>
		/* TODO: include ICON */
		/* TODO: explain ability to pursue at later time */
		case PAGES.COMMON.LOGIN.NEW_USER.ORGANIZATION_CONNECT_WITH_STRIPE.X:
			return <MobileLoginOrgCreateStripeAccount	message={translate(DICTIONARY.ORGANIZATION_STEP_4_BODY.X)}/>
		/* TODO: add activate button on main organization page or on fist login or a go live email after stripe confirms account setup */
		case PAGES.COMMON.LOGIN.NEW_USER.ACCOUNT_TYPE.X:
			return <MobileLoginAccountType back={PAGE_STATES[levelOne].LOGIN.NEW_USER.INVITATION_Q.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.INVITATION_Q.X:
			// priorWindowDisplayName = translate(DICTIONARY.LOGIN.X)
			return <MobileLoginInvitationQ back={PAGE_STATES[levelOne].LOGIN.NEW_USER.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.INVITATION_CODE.X:
			content =
				<div className={"w3-display-middle w3-animate-opacity"} style={{width: "100%", padding: "0vh 4vh"}}>
					<MobileInputCode id={"invitation-code"} className={"paralian-theme-level-1-flipped"} name={"code"}
						left={{icon: ICONS.SHIELD}} verificationType={VERIFICATION_TYPE.NEW_USER_INVITATION} autoFocus={true}
						showLabel={false} placeholder={translate(DICTIONARY.CODE.X)} label={translate(DICTIONARY.CODE.X)}
						onFailure={() => {}}
						onSuccess={payload => {
							setAccountType(payload.type, false)
							setToStorage("name", payload.name, "session")
						 	store.dispatch({
								type: actions.SET_CURRENT_WINDOW,
								values: {window: PAGE_STATES[levelOne].LOGIN.NEW_USER.USERNAME.X}
						 })
						}}/>
				</div>
			priorWindow = PAGE_STATES[levelOne].LOGIN.NEW_USER.INVITATION_Q.X
			isForm = false
			break
		case PAGES.COMMON.LOGIN.NEW_USER.EMAIL.X:
			return <MobileLoginNewEmail message={translate(DICTIONARY.NEW_LOGIN_EMAIL_MESSAGE.X)}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.ACCOUNT_TYPE.X}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.EMAIL_VERIFICATION.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.EMAIL_VERIFICATION.X:
			return <MobileLoginNewEmailVerification back={PAGE_STATES[levelOne].LOGIN.NEW_USER.EMAIL.X}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.USERNAME.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.USERNAME.X:
			return <MobileLoginNewUsername message={translate(DICTIONARY.NEW_USERNAME_MESSAGE.X)}
 				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.PASSWORD.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.PASSWORD.X:
			return <MobileLoginNewPassword message={translate(DICTIONARY.NEW_LOGIN_PASSWORD_MESSAGE.X)}
				next={PAGE_STATES[levelOne].LOGIN.NEW_USER.PERSONAL_SECURITY_PHRASE.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.USERNAME.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.PERSONAL_SECURITY_PHRASE.X:
			return <MobileLoginPersonalSecurityPhrase next={PAGE_STATES[levelOne].LOGIN.NEW_USER.NAME.X}
				message={translate(DICTIONARY.NEW_LOGIN_PERSONAL_SECURITY_PHRASE_MESSAGE.X)}/>
		case PAGES.COMMON.LOGIN.NEW_USER.NAME.X:
			return <MobileLoginNewName next={PAGE_STATES[levelOne].LOGIN.NEW_USER.TERMS_AND_CONDITIONS.X}
				back={PAGE_STATES[levelOne].LOGIN.NEW_USER.PERSONAL_SECURITY_PHRASE.X}/>
		case PAGES.COMMON.LOGIN.NEW_USER.TERMS_AND_CONDITIONS.X:
			/* TODO: I'm pretty sure we dont ALWAYS want to go to logged in mode from here */
			return <MobileLoginNewTandC login={true} next={PAGE_STATES[levelOne].MAIN.REQUESTS.X} accountType={ACCOUNT_TYPE.PERSONAL}/>
		default:
			return <MobileLoginStart/>
	}
	if (isForm) {
		content =
			<form id={"form"} className={"LoginContentBody w3-display-middle w3-animate-opacity"}
				style={{width: "100%"}} onSubmit={formProps.onSubmit} encType={formProps.encType}>
				{content}
				<div className={"formSubmitContainer w3-center"} style={{width: "100%"}}>
					<button id={"submit"} type={"submit"} className={"formSubmitButton paralian-theme-level-1 w3-circle w3-ripple"}
						style={{transition: "background-color 2s", textAlign: "center", width: "10vh",
							border: "unset", padding: "unset", fontSize: "6vh", outline: "transparent"}}>
						<MobileFontAwesome icon={ICONS.CHEVRON_CIRCLE_RIGHT}/>
					</button>
				</div>
			</form>
	}
	if (back) {
		backButton = <MobileBackButton window={priorWindow}/>
	}
	const _content = <><MobileHeaderIcon/>{content}{backButton}<BottomQuote/></>
	return <MobileParentContainer content={_content}/>
}

export default MobileNewUser