/**
 *
 * @param {string} initMap - initMap function in string form
 * @return {{scriptImport: HTMLElement, scriptBody: HTMLElement}}
 */
import {appendScript} from "./localFunctions"

const googleMapsSetup = initMap => {
	const localScriptId = "google-maps-local-script"
	let scriptBody = document.getElementById(localScriptId)
	if (scriptBody === null) {
		scriptBody = document.createElement('script')
		scriptBody.id = localScriptId
		scriptBody.innerHTML = initMap
		scriptBody.async = true
		// scriptBody.defer = true
		document.head.appendChild(scriptBody)
	}
	const path = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap`
	const scriptImport = appendScript(path,{}, "google-maps-import-script")
	return {
		scriptImport: scriptImport,
		scriptBody: scriptBody
	}
}

export default googleMapsSetup