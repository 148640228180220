import {enumOrNull} from '../../functions/shared/local'
import Hour from './textInputFilters/Hour'
import Minute from './textInputFilters/Minute'
const XM = Object.freeze({AM: "AM", PM: "PM"})
/* TODO: privatize with properties_ with  when supported */

class Time {
	/**
	 *
	 * @param {Time} [time = null]
	 * @param {number} [time.hour]
	 * @param {number} [time.minute]
	 * @param {string} [time.xm] - "AM", "PM" or null
	 */
	constructor (time = null) {
		try {
			this.hour_ = new Hour(time.hour || time.hour_).number
			this.minute_ = new Minute(time.minute || time.minute_).number || 0
			this.xm_ = enumOrNull(time.xm || time.xm_, XM)
		} catch (e) {
			this.hour_ = null
			this.minute_ = null
			this.xm_ = null
		}
	}

	get xm(){
		return this.xm_
	}

	set xm(xm) {
		this.xm_ = enumOrNull(xm, XM)
	}

	get hour(){
		return this.hour_
	}

	set hour(hour) {
		this.hour_ = new Hour(hour).number
	}

	get minute(){
		return this.minute_
	}

	set minute(minute) {
		this.minute_ = new Minute(minute).number || 0
	}

	/**
	 *
	 * @returns {number|null}
	 */
	get hourAsXM() {
		return this.hour_ !== null ? this.hour_ % 12 || 12 : null
	}

	/**
	 *
	 * @return {string}
	 */
	get xmString() {
		return this.xm_ === null ? this.hour_ < 12 ? "AM" : "PM" : this.xm_
	}

	/**
	 *
	 * @returns {string|null}
	 */
	get asXMString() {
		return (this.hour_ !== null && this.minute_ !== null) ?
			`${this.hourAsXM > 9 ? this.hourAsXM : `0${this.hourAsXM}`}:${this.minute_ > 9 ? this.minute_ : `0${this.minute_}`} ${this.xmString}` :
			null
	}

	/**
	 *
	 * @returns {number|null}
	 */
	get hourAs24hr() {
		if (this.xm_ === null) {return this.hour_}
		let hour24
		if (this.hour_ === 12) {hour24 = this.xm_ === "AM" ? 0 : 12}
		else {hour24 = this.xm_ === "AM" ? this.hour_ : this.hour_ + 12}
		return this.hour_ !== null ? hour24 : null
	}

	/**
	 *
	 * @returns {number|null}
	 */
	get as24hrNumber() {
		return (this.hour_ !== null && this.minute_ !== null) ? (this.hourAs24hr*100) + this.minute_ : null
	}

	/**
	 *
	 * @returns {string|null}
	 */
	get as24hrString() {
		return (this.hour_ !== null && this.minute_ !== null) ?
			`${this.hourAs24hr > 9 ? this.hourAs24hr : `0${this.hourAs24hr}`}:${this.minute_ > 9 ? this.minute_ : `0${this.minute_}`}` :
			null
	}
}

export default Time;