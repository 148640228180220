import Percent from "../textInputFilters/Percent"
/* TODO: privatize with properties_ with  when supported */
class Proportions {
	/**
	 *
	 * @param {Proportions} [proportions = null]
	 * @param {number} proportions.population - as percentage
	 * @param {number} proportions.sample - as percentage
	 */
	constructor(proportions = null) {
		try {
			this.population = new Percent(proportions.population).number
			this.sample = new Percent(proportions.sample).number
		} catch (e) {
			this.population = null
			this.sample = null
		}
	}

	asText(fraction) {
		try {
			return fraction.toLocaleString(undefined,{style: 'percent', minimumFractionDigits: 0})
		} catch (e) {
			return "- %"
		}
	}

	get populationAsText() {
		return this.asText(this.population)
	}

	get sampleAsText() {
		return this.asText(this.sample)
	}
}

export default Proportions