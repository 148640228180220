import {DOCUMENT_CONTAINER, DOCUMENT_TYPE, DOCUMENT_TYPE_MINIMAL} from "../../../globals/constants/shared/enumerators"

const containerAndMinimalTypeToDocumentType = (container, type) => {
	switch (container) {
		case DOCUMENT_CONTAINER.VESSELS:
			switch (type) {
				case DOCUMENT_TYPE.VESSEL_TITLES:
					return DOCUMENT_TYPE.VESSEL_TITLES
				case DOCUMENT_TYPE.VESSEL_REGISTRATIONS:
					return DOCUMENT_TYPE.VESSEL_REGISTRATIONS
				case DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS:
					return DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS
				case DOCUMENT_TYPE_MINIMAL.TITLES:
					return DOCUMENT_TYPE.VESSEL_TITLES
				case DOCUMENT_TYPE_MINIMAL.REGISTRATIONS:
					return DOCUMENT_TYPE.VESSEL_REGISTRATIONS
				case DOCUMENT_TYPE_MINIMAL.DOCUMENTATIONS:
					return DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS
				case DOCUMENT_TYPE_MINIMAL.PUBLIC:
					return DOCUMENT_TYPE.PUBLIC
				case DOCUMENT_TYPE_MINIMAL.INSURANCE:
					return DOCUMENT_TYPE.INSURANCE
				case DOCUMENT_TYPE_MINIMAL.CERTIFICATES:
					return DOCUMENT_TYPE.CERTIFICATES
				case DOCUMENT_TYPE_MINIMAL.UPKEEP:
					return DOCUMENT_TYPE.UPKEEP
				default:
					console.log("document type error")
					return
			}
		case DOCUMENT_CONTAINER.ORGANIZATIONS:
			switch (type) {
				case DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS:
					return DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS
				case DOCUMENT_TYPE_MINIMAL.REGISTRATIONS:
					return DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS
				case DOCUMENT_TYPE_MINIMAL.CONTRACTS:
					return DOCUMENT_TYPE.CONTRACTS
				case DOCUMENT_TYPE_MINIMAL.CERTIFICATES:
					return DOCUMENT_TYPE.CERTIFICATES
				case DOCUMENT_TYPE_MINIMAL.BONDS:
					return DOCUMENT_TYPE.BONDS
				case DOCUMENT_TYPE_MINIMAL.INSURANCE:
					return DOCUMENT_TYPE.INSURANCE
				case DOCUMENT_TYPE_MINIMAL.PUBLIC:
					return DOCUMENT_TYPE.PUBLIC
				default:
					console.log("document type error")
					return
			}
		default:
			switch (type) {
				case DOCUMENT_TYPE_MINIMAL.CERTIFICATES:
					return DOCUMENT_TYPE.CERTIFICATES
				case DOCUMENT_TYPE_MINIMAL.BONDS:
					return DOCUMENT_TYPE.BONDS
				case DOCUMENT_TYPE_MINIMAL.CONTRACTS:
					return DOCUMENT_TYPE.CONTRACTS
				case DOCUMENT_TYPE_MINIMAL.PUBLIC:
					return DOCUMENT_TYPE.PUBLIC
				case DOCUMENT_TYPE_MINIMAL.INSURANCE:
					return DOCUMENT_TYPE.INSURANCE
				default:
					console.log("document type error")
					return
			}
	}
}

export default containerAndMinimalTypeToDocumentType