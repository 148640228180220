import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselHullDimensionsFields from "./MobileVesselHullDimensionsFields"
import MobileVesselHullDimensionsEdit from "./MobileVesselHullDimensionsEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import HullDimensions from "../../../../globals/classes/shared/vessels/HullDimensions"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {HullDimensions} props.dimensions
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullDimensions = props => {
	const [dimensions, setDimensions] = useState(props.dimensions)
	const [edit, setEdit] = useState(false)
	const content = edit ? <MobileVesselHullDimensionsEdit id={props.id} hin={dimensions} units={props.units}
		dimensions={props.dimensions} className={"paralian-theme-level-1"} onSuccess={dimensions => {
			props.refresh().then()
			setDimensions(new HullDimensions(dimensions))
			setEdit(false)
	}}/> :
		<MobileVesselHullDimensionsFields units={props.units} dimensions={dimensions}/>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.DIMENSIONS.X)]}/>
		{content}
	</div>
}

export default MobileVesselHullDimensions