import {getTimeFormat} from "../../../globals/functions/client/localFunctions"
import Week from "../../../globals/classes/shared/Week"
import Time from "../../../globals/classes/shared/Time"

const weekInputCapture = id => {
	const getState = day => {
		return document.getElementById(`${day}-${id}-state`).getAttribute("data-state")
	}
	const getHoursOfOperation = (day, action) => {
		const state = document.getElementById(`${day}-${id}-state`).getAttribute("data-state")
		if (state === "open") {
			// console.log(getTimeFormat())
			let use24Hr = getTimeFormat() !== "xm"
			// console.log(use24Hr)
			try {
				use24Hr = document.getElementById(`time-format-${id}`).getAttribute("data-is-checked")
			} catch (e) {
				// console.log(e)
			}
			// console.log(use24Hr)
			const hour = document.getElementById(`${day}-${id}-${action}-hour-input`).value
			const minute = document.getElementById(`${day}-${id}-${action}-minute-input`).value
			const xm = use24Hr ? null : document.getElementById(`${day}-${id}-${action}-format-input`).value
			return new Time({hour: hour, minute: minute, xm: xm})
		} else if (state === "closed") {
			return new Time({hour: 0, minute: 0, xm: null})
		} else {
			return action === "open" ? new Time({hour: 0, minute: 0, xm: null}) : new Time({hour: 23, minute: 59, xm: null})
		}
	}
	return new Week({
		monday: {
			isOpen: getState("monday") !== "closed",
			open: getHoursOfOperation("monday", "open"),
			close: getHoursOfOperation("monday", "close")
		},
		tuesday: {
			isOpen: getState("tuesday") !== "closed",
			open: getHoursOfOperation("tuesday", "open"),
			close: getHoursOfOperation("tuesday", "close")
		},
		wednesday: {
			isOpen: getState("wednesday") !== "closed",
			open: getHoursOfOperation("wednesday", "open"),
			close: getHoursOfOperation("wednesday", "close")
		},
		thursday: {
			isOpen: getState("thursday") !== "closed",
			open: getHoursOfOperation("thursday", "open"),
			close: getHoursOfOperation("thursday", "close")
		},
		friday: {
			isOpen: getState("friday") !== "closed",
			open: getHoursOfOperation("friday", "open"),
			close: getHoursOfOperation("friday", "close")
		},
		saturday: {
			isOpen: getState("saturday") !== "closed",
			open: getHoursOfOperation("saturday", "open"),
			close: getHoursOfOperation("saturday", "close")
		},
		sunday: {
			isOpen: getState("sunday") !== "closed",
			open: getHoursOfOperation("sunday", "open"),
			close: getHoursOfOperation("sunday", "close")
		}})
}

export default weekInputCapture