import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputDateOfBirthEdit from "./MobileInputDateOfBirthEdit"
import MobileDateOfBirthField from "./MobileDateOfBirthField"
import MobileInfoCardHeader from "../MobileInfoCardHeader"

const MobileDateOfBirth = props => {
	const [dateOfBirth, setDateOfBirth] = useState(props.birthdate)
	const [edit, setEdit] = useState(false)
	const content = edit ?
		<MobileInputDateOfBirthEdit refresh={props.refresh} setDateOfBirth={setDateOfBirth} setEdit={setEdit}/> :
		<MobileDateOfBirthField birthdate={dateOfBirth}/>
	return <div className={"paralian-mobile-info-card"} style={{textAlign: "center", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.DATE_OF_BIRTH.X)]}/>
		{content}
	</div>
}

export default MobileDateOfBirth