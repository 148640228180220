import {constructArray} from "../../functions/shared/local"
import DocumentRequirement from "./DocumentRequirement"
import Document from "./Document"

class DocumentRequirements {
	/**
	 *
	 * @param {DocumentRequirements} [requirements = null]
	 * @param {Document[]} [requirements.documents]
	 * @param {DocumentRequirement[]} [requirements.requirements]
	 */
	constructor(requirements = null) {
		try {
			this.documents = constructArray(requirements.documents, v => new Document(v))
			this.requirements = constructArray(requirements.requirements, v => new DocumentRequirement(v))
		} catch (e) {
			this.documents = []
			this.requirements = []
		}
	}
}

export default DocumentRequirements