import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileRigMainsailEdit from "./MobileRigMainsailEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileRigMainsail = props => {
	const [edit, setEdit] = useState(false)
	const [mainsail, setMainsail] = useState({P: props.rig.P, E: props.rig.E})
	useEffect(() => {
		setMainsail({P: props.rig.P, E: props.rig.E})
	}, [props.rig.P, props.rig.E])
	const content = edit ?
		<MobileRigMainsailEdit id={props.id} rig={props.rig} units={props.units} onSuccess={(p, e) => {
			props.refresh().then()
			setMainsail({P: p, E: e})
			setEdit(false)}}/> : <>
			<MobileSubtitleAndValue units={props.units} subtitle={`P (${translate(DICTIONARY.HOIST.X)})`}
				value={{number: mainsail.P, units: "m", digits: 3}}/>
			<MobileSubtitleAndValue units={props.units} subtitle={`E (${translate(DICTIONARY.FOOT.X)})`}
				value={{number: mainsail.E, units: "m", digits: 3}}/>
		</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.MAINSAIL.X)]}/>
		{content}
	</div>
}

export default MobileRigMainsail