import { isObject, typeOrNull } from '../../functions/shared/local';

class State {
	/**
	 *
	 * @param {State} [state = null]
	 * @param {string} [state.name]
	 * @param {string} [state.code] - ISO 3166 3-letter code
	 * @param {string} [state.country] - 2-Letter ISO code
	 */
	constructor (state = null) {
		if (isObject(state)) {
			this.name = typeOrNull(state.name, "string")
			this.code = typeOrNull(state.code, "string")
			this.country = typeOrNull(state.country, "string")
		} else {
			this.name = null
			this.code = null
			this.country = null
		}
	}
}

export default State;