import {ACCOUNT_TYPE} from "../../../constants/shared/enumerators"
import {typeOrNull, enumArray} from "../../../functions/shared/local"

class Restriction {
	/**
	 *
	 * @param {Restriction} [restriction = null]
	 * @param {number} [restriction.maxUnits]
	 * @param {number} [restriction.minUnits]
	 * @param {number} [restriction.groupSize]
	 * @param {string} [restriction.accountType] - ACCOUNT-TYPE
	 * @param {boolean} [restriction.trialLimited]
	 */
	constructor (restriction = null) {
		try {
			this.maxUnits = typeOrNull(restriction.maxUnits, "number")
			this.minUnits = typeOrNull(restriction.minUnits, "number")
			this.groupSize = typeOrNull(restriction.groupSize, "number")
			this.accountType = enumArray(restriction.accountType, ACCOUNT_TYPE)
			this.trialLimited = !!restriction.trialLimited
		} catch (e) {
			this.maxUnits = null
			this.minUnits = null
			this.groupSize = null
			this.accountType = []
			this.trialLimited = null
		}
	}
}

export default Restriction