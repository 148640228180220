import TextInputFilter from './TextInputFilter';
/**
 *
 * @param {string} state
 * @returns {Promise<Object[]>}
 */
class ISO2 extends TextInputFilter {
	minLength = 2
	maxLength = 2
	regexp = /[^A-Z]/gi
}

export default ISO2;