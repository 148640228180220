import { isObject, typeOrNull } from '../../../functions/shared/local';
import Image from '../Image';
import Country from '../Country';
import State from '../State';
import Name from '../Name';

class VesselHome {
	/**
		*
		* @param {VesselHome} [home = null]
		* @param {number} [home.id]
	  * @param {Name} [home.name]
		*	@param {Image} [home.logo]
		*	@param {Country} [home.country]
		*	@param {State} [home.state]
		*	@param {string} [home.city]
		* @param {string} [home.facility]
		* @param {number} [home.facilityId]
		* @param {string} [home.unit]
		* @param {number} [home.unitId]
	  * @param {number|string|Date} [home.expires]
		*	@param {boolean} [home.isVerified]
		*	@param {boolean} [home.isCurrent]
	*/
	constructor (home = null) {
		if (isObject(home)) {
			this.id = typeOrNull(home.id, "number")
			this.name = new Name(home.name)
			this.logo = new Image(home.logo)
			this.country = new Country(home.country)
			this.state = new State(home.state)
			this.city = typeOrNull(home.city, "string")
			this.facility = typeOrNull(home.facility, "string")
			this.facilityId = typeOrNull(home.facilityId, "number")
			this.unit = typeOrNull(home.unit, "string")
			this.unitId = typeOrNull(home.unitId, "number")
			this.priority = typeOrNull(home.priority, "number")
			this.expires = typeOrNull(home.expires, "date")
			this.isVerified = typeOrNull(home.isVerified, "boolean")
			this.isCurrent = typeOrNull(home.isCurrent, "boolean")
		} else {
			this.id = null
			this.name = new Name()
			this.logo = new Image()
			this.country = new Country()
			this.state = new State()
			this.city = null
			this.facility = null
			this.facilityId = null
			this.unit = null
			this.unitId = null
			this.priority = null
			this.expires = null
			this.isVerified = null
			this.isCurrent = null
		}
	}

	get expired(){
		return this.expires !== null ? (new Date(this.expires).getTime() - Date.now()) < 0 : false
	}

}

export default VesselHome;