import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselHullWeightEdit from "./MobileVesselHullWeightEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units
 * @param {number} props.weight
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullWeight = props => {
	const [weight, setWeight] = useState(props.weight)
	const [edit, setEdit] = useState(false)
	const content = edit ? <MobileVesselHullWeightEdit id={props.id} units={props.units} weight={weight}
		onSuccess={weight => {
			props.refresh().then()
			setWeight(weight)
			setEdit(false)
	}}/> : <div style={{margin: "1vh 4vw", fontSize: "3vh"}} className={"w3-animate-opacity"}>
		{convertToPreferredUnits(weight, "kg", props.units, 0)}
	</div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.DRY_WEIGHT.X)]}/>
		{content}
	</div>
}

export default MobileVesselHullWeight