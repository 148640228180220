import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputPricingEdit from "../pricing/MobileInputPricingEdit"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import submitOrgServicePricesUpdate from "../pricing/submitOrgServicePricesUpdate"

/**
 *
 * @param {Object} props
 * @param {Servicio} props.service
 * @param {function} props.onUndo
 * @param {function} props.setPricing
 * @param {function} props.setStatus
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileServiceEdit = props => {
	const content = <>
		<div style={{width: "100%", height: "5vh", margin: "1vh 0vh", padding: "1vh 0vh", borderRadius: "1vh"}}
				 className={"w3-display-container paralian-theme-level-1"}>
			<MobileInputSlider id={`${props.service.id}-isActive`} label={translate(DICTIONARY.ACTIVE.X)} value={true}
				padding={"0.25vh 0vh 0.25vh 2vh"}	height={"3vh"} width={"8vh"} className={"w3-display-middle"}
				defaultChecked={props.service.isActive} onChange={() => {}}/>
		</div>
		<MobileInputPricingEdit id={props.service.id} form={`${props.service.id}-service-edit-form`} service={props.service}
			currency={props.currency} defaultPricingStructures={props.defaultPricingStructures}/>
	</>
	/* TODO: automatically deactivate when updating composite pricing */
	/* TODO: warn user that service will be automatically deactivated if updating composite pricing */
	return <MobileInputForm id={`${props.service.id}-service-edit`} content={content} className={" "}
		onSubmit={async event => await submitOrgServicePricesUpdate(event, props.service, props.defaultPricingStructures,
			props.currency, props.onSubmit)}
	/>
}

export default MobileServiceEdit