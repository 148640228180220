import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {ICONS, DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage,		translate} from "../../../../globals/functions/client/localFunctions"
import {updateOrgSchedule} from "../../../../globals/functions/client/serverFunctions"
import weekInputCapture from "../../../main/calendar/weekInputCapture"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputWeek from "../../../main/calendar/MobileInputWeek"
import Schedule from "../../../../globals/classes/shared/Schedule"

const MobileLoginHoursOfOperation = props => {
	/* TODO: use MobileInputWeek/Day */
	useEffect(() => {
		if (props.message) {
			newMessage("hours-of-operation-info-message", translate(DICTIONARY.HOURS_OF_OPERATION.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}
	}, [props.message])
	// const currentPage = store.getState().window.current
	const onSubmit = async event => {
		event.preventDefault()
		const week = weekInputCapture("login-hoo")
		// console.log(week)
		const keys = Object.keys(week)
		// console.log(keys)
		for (let i = 0; i < keys.length; i++) {
			if (week[keys[i]].close.as24hrNumber < week[keys[i]].open.as24hrNumber) {
				newMessage("hooError", translate(DICTIONARY.INPUT_ERROR.X),
					translate(DICTIONARY.HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE.X)	+ translate(keys[i].toUpperCase()),
					"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
				return
			}
		}
		const response = await updateOrgSchedule(new Schedule({week: week}))
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
		}
	}
	const content = <MobileInputWeek week={null} use24Hr={false} id={"login-hoo"} form={"form"}/>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginHoursOfOperation