import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import Currency from "../../../globals/classes/shared/Currency"
import Servicio from "../../../globals/classes/shared/Servicio"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Currency} props.order.currency
 * @param {Object} props.item
 * @param {number} props.item.item
 * @param {number} props.item.charge
 * @param {number} props.item.quantity
 * @param {number} props.item.fee
 * @param {boolean} props.limitPriceAndQuantity
 * @param {IndexedArray} props.indexedServices
 * @return {JSX.Element}
 * @constructor
 */
const MobileOrderAdjustPriceCard = props => {
	const [currency, setCurrency] = useState(new Currency(props.order.currency))
	const [quantity, setQuantity] = useState(props.item.quantity)
	const [unitPrice, setUnitPrice] = useState(currency.unitAsNumber(props.item.charge - props.item.fee, props.item.quantity))
	useEffect(() => {
		setCurrency(new Currency(props.order.currency))
	}, [props.order.currency])
	useEffect(() => {
		setQuantity(props.item.quantity)
	}, [props.item.quantity])
	useEffect(() => {
			setUnitPrice(currency.unitAsNumber(props.item.charge - props.item.fee, props.item.quantity))
		},
		[props.item.charge, props.item.quantity, props.item.fee, currency])//props.order.currency])
	const service = new Servicio(props.indexedServices.byIndex(props.item.item))
	// console.log("currency", currency, props.order.currency, "quantity", quantity, "unitPrice", unitPrice)
	// console.log("props.indexedServices", props.indexedServices)
	return <div key={`order-${props.order.id}-item-${props.item.item}-adjust`}
		className={"paralian-theme-level-2"} style={{padding: "1vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
		<div style={{fontSize: "2vh", padding: "2vh 0vh"}}>
			<b><i>{service.labelsAsString}</i></b>
		</div>
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
				{translate(DICTIONARY.QUANTITY.X)}
			</div>
			<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
				{props.item.quantity.toLocaleString(undefined)}
			</div>
		</div>
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
				{translate(DICTIONARY.UNIT_PRICE_EXCL_TAXES_PARALIAN_FEE.X)}
			</div>
			<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
				{currency.unitAsText(props.item.charge - props.item.fee, props.item.quantity)}
			</div>
		</div>
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
				{translate(DICTIONARY.ITEM_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE.X)}
			</div>
			<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
				{currency.asText(props.item.charge - props.item.fee)}
			</div>
		</div>
		<div style={{textAlign: "left", paddingLeft: "0.5vh", paddingBottom: "1vh"}}>
			{translate(DICTIONARY.PROPOSED_QUANTITY.X)}
		</div>
		<div className={"paralian-theme-level-1"} style={{padding: "1.5vh", display: "inline-flex", width: "100%", borderRadius: "1vh"}}>
			<input type={"number"} id={`order-${props.order.id}-item-${props.item.item}-adjust-quantity`} name={"name"}
				className={"paralian-input-field"} min={"0"} form={"form-"} autoFocus={false} maxLength={8}
			 	max={props.limitPriceAndQuantity ? props.item.quantity : "999999.99"}
			 	required={true} step={"0.01"}	placeholder={"###.##"} style={{color: "inherit"}}
				defaultValue={props.item.quantity} onInputCapture={() => setQuantity(document.getElementById(
				`order-${props.order.id}-item-${props.item.item}-adjust-quantity`).value)}/>
		</div>
		<div style={{textAlign: "left", paddingLeft: "0.5vh", padding: "1vh 0vh"}}>
			{translate(DICTIONARY.PROPOSED_UNIT_PRICE_EXCL_TAXES_PARALIAN_FEE.X)}
		</div>
		<div className={"paralian-theme-level-1"} style={{padding: "1.5vh", display: "inline-flex", width: "100%", borderRadius: "1vh"}}>
			<span>{currency.asString}</span>
			<input type={"number"} id={`order-${props.order.id}-item-${props.item.item}-adjust-unit-price`} step={"0.01"}
				className={"paralian-input-field"} min={"0"} name={"name"} form={"form-"} autoFocus={false}
			 	max={props.limitPriceAndQuantity ? props.item.charge - props.item.fee : "999999.99"}
			 	maxLength={8} required={true} placeholder={"###.##"} style={{color: "inherit"}}
				defaultValue={currency.unitAsNumber(props.item.charge - props.item.fee, props.item.quantity)}
				onInputCapture={() => setUnitPrice(document.getElementById(
				`order-${props.order.id}-item-${props.item.item}-adjust-unit-price`).value)}/>
		</div>
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
				{translate(DICTIONARY.PROPOSED_SUBTOTAL_EXCL_TAXES_PARALIAN_FEE.X)}
			</div>
			<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
				{currency.totalAsText(unitPrice*(currency.zeroDecimal ? 1 : 100), quantity)}
			</div>
		</div>
	</div>
}

export default MobileOrderAdjustPriceCard