import {constructArray, typeOrNull} from '../../functions/shared/local'

class Constraints {
	/**
	 *
	 * @param {Constraints} [constraint = null]
	 * @param {number[][]} [constraint.inclusive]
	 * @param {number[][]} [constraint.exclusive]
	 * @param {number[][]} [constraint.post]
	 */
	constructor (constraint = null) {
		try {
			this.inclusive = constructArray(constraint.inclusive,
				array => array.map(item => typeOrNull(item, "number")))
			this.exclusive = constructArray(constraint.exclusive,
				array => array.map(item => typeOrNull(item, "number")))
			this.post = constructArray(constraint.post,
				array => array.map(item => typeOrNull(item, "number")))
		} catch (e) {
			this.inclusive = []
			this.exclusive = []
			this.post = []
		}
	}
}

export default Constraints