import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class CallSign extends TextInputFilter {
	minLength = 3
	maxLength = 7
	regexp = /[^0-9A-Z]/gi
}

export default CallSign;