import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {ACCOUNT_STATE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {deleteOrgAdmin} from "../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileAdminEdit from "./MobileAdminEdit"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Employee from "../../../globals/classes/shared/Employee"

/**
 *
 * @param {Object} props
 * @param {Account} props.admin
 * @param {function} props.onEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdmin = props => {
	const [edit, setEdit] = useState(false)
	const [admin, setAdmin] = useState(new Employee(props.admin))
	useEffect(() => {setAdmin(new Employee(props.admin))}, [props.admin])
	const login = admin.contact.contactInfo.emails.primary.address
	const name = admin.contact.name.given
	const username = admin.status === ACCOUNT_STATE.NEW || admin.status === ACCOUNT_STATE.INCOMPLETE ? "" : admin.username
	const title = admin.title
	const content = <>
		{edit ? <></> : <div style={{fontSize: "3vh"}}>{name}</div>}
		{edit ? <></> : <div style={{fontSize: "2.5vh"}}><i>{title}</i></div>}
		<div style={{fontSize: "1.5vh"}}>{username}</div>
		{edit ? <MobileAdminEdit id={`admin-${admin.id}`} admin={admin} style={{marginTop: "1vh"}}
			 className={"paralian-theme-level-1"} onSubmit={null}/> : <></>}
		{edit ? <></> : <div style={{padding: "1vh", marginTop: edit ? "1vh" : "0vh", textAlign: "center", fontSize: "3vh"}}
					className={`w3-animate-opacity`}>
			<span>
				<MobileFontAwesome icon={admin.status === ACCOUNT_STATE.ACTIVE ? ICONS.CHECK :
					[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(admin.status) ? ICONS.BAN :
						ICONS.EXCLAMATION_TRIANGLE} style={{paddingRight: "1.5vh"}}/>
				{admin.status === ACCOUNT_STATE.ACTIVE ? translate(DICTIONARY.ACTIVE.X) :
					[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(admin.status) ?
						translate(DICTIONARY.DEACTIVATED.X) : translate(DICTIONARY.PENDING.X)}
			</span>
		</div>}
	</>
	const onDelete = async () => {
		const confirmed = window.confirm(`${translate(DICTIONARY.DELETE_ADMIN_ACCOUNT_CONFIRM_QUERY.X)}${username || name}?`)
		if (confirmed) {

			const response = await deleteOrgAdmin(admin.id)

			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onDelete(admin.id)
			}
		}}
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} onDelete={onDelete}
			labels={[edit ? translate(DICTIONARY.EDIT_ADMIN.X) : login]}/>
		{content}
	</div>
}

export default MobileAdmin