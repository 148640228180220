import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileAddNewBanner from "../../MobileAddNewBanner"
import MobileVesselEngineAdd from "./MobileVesselEngineAdd"
import Modal from "../../../../globals/components/Modal"
import MobileEngineSummary from "./MobileEngineSummary"
import Engine from "../../../../globals/classes/shared/vessels/Engine"

const MobileEngines = props => {
	const [expanded, setExpanded] = useState(false)
	const [add, setAdd] = useState(false)
	const [engines, setEngines] = useState(props.engines)
	useEffect(() => {setEngines(props.engines)}, [props.engines])
	const removeEngine = id => {
		setEngines(engines.filter(engine => engine.id !== id))
	}
	const banner = props.canEdit ? <MobileAddNewBanner text={`${translate(DICTIONARY.ADD.X)} ${translate(DICTIONARY.ENGINE.X)}`}
		onClick={() => setAdd(!add)}/> : <></>
	const add_ = add ? <Modal id={"add-engine-modal"} icon={ICONS.BOLT} onClick={() => setAdd(false)} padding={"0vh"}
		title={`${translate(DICTIONARY.ADD.X)} ${translate(DICTIONARY.ENGINE.X)}`} body={
		<MobileVesselEngineAdd id={props.id} units={props.units}
			zIndex={props.zIndex + engines.length + 1} onSuccess={engine => {
			props.refresh().then()
			setEngines([...engines, engine])
			setAdd(false)
}}/>
	}/> : <></>
	const content = engines.map((engine, index) => <MobileEngineSummary key={`engine-${engine.id}`} units={props.units}
		id={props.id} engine={new Engine(engine)}	index={index} vesselId={props.id} top={"12.7vh"} canEdit={props.canEdit}
		refresh={props.refresh} zIndex={props.zIndex} sticky={!add} onDelete={() => removeEngine(engine.id)}/>)
	const content_ = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"} style={{fontSize: "2vh"}}>
		{content}
		{add_}
		{banner}
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.ENGINES.X)} top={props.top}
			onClick={() => setExpanded(!expanded)}/>
		{content_}
	</>
}

export default MobileEngines