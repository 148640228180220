import React, {useState} from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {deleteAccount, logOut, setContactName, updateUsername} from "../../../globals/functions/client/serverFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileUpdatePassword from "./MobileUpdatePassword"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileInputUpdateUsername from "../../shared/input/text/MobileInputUpdateUsername"
/**
 *
 * @param {Object} props
 * @param {boolean} props.locked
 * @param {React.HTMLProps.style.top} props.top
 * @param {React.HTMLProps.style.zIndex} props.zIndex
 * @param {string} props.name
 * @param {string} props.username
 * @param {ContactInfo} props.contactInfo
 * @param {function} props.setName
 * @param {function} props.setUsername
 * @param {function} props.refresh
 * @returns {JSX.Element}
 */
const MobileAccountControl = props => {
	const [expanded, setExpanded] = useState(false)
	const [isReversion, setIsReversion] = useState(false)
	const id = "account-control"
	/* TODO: offer dual-factor authentication */
	/* TODO: lock account */
	const content = !expanded ? <></> :
		<div className={"w3-animate-top"} style={{fontSize: "2vh", textAlign: "left"}}>
			<div className={"changePassword paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{width: "100%"}}>
					<div className={"paralian-mobile-info-card-header"}>{translate(DICTIONARY.CHANGE_PASSWORD.X)}</div>
				</div>
				<p className={"w3-display-container"}>{translate(TEXT_FIELD_PROPERTIES.password.hoverMessage)}</p>
				<MobileUpdatePassword username={props.username} className={"paralian-theme-level-1"}
					contactInfo={props.contactInfo}/>
			</div>
			<div className={"paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{width: "100%"}}>
					<div className={"paralian-mobile-info-card-header"}>{translate(DICTIONARY.CHANGE_USERNAME.X)}</div>
				</div>
				<p className={"w3-display-container"}>{translate(TEXT_FIELD_PROPERTIES.username.hoverMessage)}</p>
				<form id={`${id}-username-form`} className="" onSubmit={async event => {
					event.preventDefault()
					const confirmed = window.confirm(translate(DICTIONARY.CHANGE_USERNAME_PROMPT.X))
					if (confirmed) {
						const response = await updateUsername(document.getElementById(`${id}-username-input`).value)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							fadingMessage("username-change-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "",
								"w3-pale-green", ICONS.CHECK)
							props.refresh().then()
							props.setUsername(document.getElementById(`${id}-username-input`).value)
						}
						if (response.status === SERVER_RESPONSE_STATE.FAIL) {
							newMessage("username-in-use", translate(DICTIONARY.USERNAME_UNAVAILABLE.X),
								translate(DICTIONARY.USERNAME_UNAVAILABLE_MESSAGE.X),
								"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
						}}}}>
					<MobileInputUpdateUsername id={`${id}-username`} name={"username"} style={{margin: "1vh 0vw"}}
						className={"paralian-theme-level-1"} showLabel={false} autoFocus={false} topRadius={"1vh"}
						bottomRadius={"1vh"} right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT, onClick: () => {
						document.getElementById(`${id}-username-submit`).click()
						}}}
						form={`${id}-username-form`} label={translate(DICTIONARY.USERNAME.X)}/>
					<button form={`${id}-username-form`} id={`${id}-username-submit`} type="submit" style={{display: "none"}}/>
				</form>
			</div>
			<div className={"changeName paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{width: "100%"}}>
					<div className={"paralian-mobile-info-card-header"}>{translate(DICTIONARY.CHANGE_NAME.X)}</div>
				</div>
				<p>{translate(DICTIONARY.FULL_NAME_GUIDELINES.X)}</p>
				<p><i>{translate(DICTIONARY.NAME_CHANGE_NOTICE.X)}</i></p>
				<form id={`${id}-name-form`} onSubmit={async event => {
					event.preventDefault()
					const confirmed = window.confirm(translate(DICTIONARY.NAME_CHANGE_PROMPT.X))
					if (confirmed) {
						const response = await setContactName(document.getElementById(`${id}-name-input`).value)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							fadingMessage("name-change-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "",
								"w3-pale-green", ICONS.CHECK)
							props.refresh().then()
							props.setName(document.getElementById(`${id}-name-input`).value)
						}}}}>
					<MobileInputText id={`${id}-name`} name={"name"} showLabel={false} bottomRadius={"1vh"} topRadius={"1vh"}
						label={translate(DICTIONARY.CHANGE_NAME.X)} className={"paralian-theme-level-1"} style={{margin: "1vh 0vw"}}
						right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT,
							onClick: () => {document.getElementById(`${id}-name-submit`).click()}}}
						form={`${id}-name-form`} autoComplete={"off"} required={false} defaultValue={null}
						placeholder={translate(DICTIONARY.FULL_NAME.X)} maxLength={"120"} autoFocus={false}/>
					<button form={`${id}-name-form`} id={`${id}-name-submit`} type="submit" style={{display: "none"}}/>
				</form>
			</div>
			<div className={"paralian-mobile-info-card"}>
				<div className={"w3-display-container"} style={{display: "flex", width: "100%", height: "3vh"}}>
					<MobileInputSlider id={`${id}-delete`} label={translate(DICTIONARY.DELETE_ACCOUNT.X)} height={"3vh"} width={"8vh"}
						className={"paralian-mobile-info-card-header w3-display-left"} defaultChecked={false}
						padding={"0.25vh 0vh 0.25vh 2vh"} form={`${id}-delete-form`} onChange={async element => {
						if (isReversion) {setIsReversion(false); return}
						const confirmed = window.confirm(translate(DICTIONARY.DELETE_ACCOUNT_PROMPT_1.X))
						if (confirmed) {
							const yetAgain = window.confirm(translate(DICTIONARY.DELETE_ACCOUNT_PROMPT_1.X))
							if (yetAgain) {
								const response = await deleteAccount()
								if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
									logOut()
								} else {
									setIsReversion(true)
									setTimeout(() => element.click(), 10)
								}
							} else {
								setIsReversion(true)
								setTimeout(() => element.click(), 10)
							}
						} else {
							setIsReversion(true)
							setTimeout(() => element.click(), 10)
						}}}/>
				</div>
				<p>{translate(DICTIONARY.DELETE_ACCOUNT_NOTIFICATION_1.X)}</p>
				<p><i>{translate(DICTIONARY.DELETE_ACCOUNT_NOTIFICATION_2.X)}</i></p>
			</div>
		</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} top={props.top} zIndex={props.zIndex} text={translate(DICTIONARY.ACCOUNT_CONTROL.X)}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileAccountControl