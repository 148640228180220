import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {getUnits, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {Area} props.area
 * @param {string|number} props.zIndex
 * @returns {JSX.Element}
 * @constructor
 */
const MobileServiceAreaFields = props => {
	const units = getUnits()
	const distanceText = units === "SI" ? props.area.distanceKmAsText : props.area.distanceMiAsText
	const unitsText = units === "SI" ? translate(DICTIONARY.KILOMETERS.X) : translate(DICTIONARY.MILES.X)
	return <div className={"w3-animate-opacity w3-center"} style={{fontSize: "3vh"}}>
		<div>
			<MobileFontAwesome icon={ICONS.RADAR} style={{fontSize: "7vh"}}/>
				<span style={{fontSize: "7vh"}}>{props.area.radius ? " " + distanceText : "0"}</span>
				<span>{` ${unitsText}`}</span>
		</div>
		{props.area.state.name !== null ? <>
			<div style={{fontSize: "2vh", paddingBottom: "1vh"}}><i>{translate(DICTIONARY.LIMITED_TO.X) + ": "}</i></div>
			<div>{props.area.state.name}</div>
		</> :
			props.area.country.name !== null ? <>
				<div style={{fontSize: "2vh", paddingBottom: "1vh"}}><i>{translate(DICTIONARY.LIMITED_TO.X) + ": "}</i></div>
				<div>{props.area.country.name}</div>
			</> : <></>}
	</div>
}

export default MobileServiceAreaFields