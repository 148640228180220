import { isObject, typeOrNull } from '../../../functions/shared/local';
import Document from '../Document';
import Country from '../Country';
import State from '../State';

class OrganizationRegistration {
	/**
	 *
	 * @param {OrganizationRegistration} [registration = null]
	 * @param {number} [registration.registrationAuthority]
	 * @param {string} [registration.registrationAuthorityName]
	 * @param {string} [registration.number]
	 * @param {number|string|Date} [registration.issued] - date
	 * @param {Country} [registration.country]
	 * @param {State} [registration.state]
	 * @param {string} [registration.legalName]
	 * @param {Document} [registration.document]
	 *
	 */
	constructor(registration = null) {
		if (isObject(registration)) {
			this.registrationAuthority = typeOrNull(registration.registrationAuthority, "number")
			this.registrationAuthorityName = typeOrNull(registration.registrationAuthorityName, "string")
			this.number = typeOrNull(registration.number, "string")
			this.issued = typeOrNull(registration.issued, "date")
			this.country = new Country(registration.country)
			this.state = new State(registration.state)
			this.document = new Document(registration.document)
		} else {
			this.registrationAuthority = null
			this.registrationAuthorityName = null
			this.number = null
			this.issued = null
			this.country = new Country()
			this.state = new State()
			this.document = new Document()
		}
	}
}

export default OrganizationRegistration;