import {DOCUMENT_CONTAINER, DOCUMENT_TYPE_MINIMAL} from "../../constants/shared/enumerators"
import {enumOrNull, typeOrNull} from "../../functions/shared/local"
import ItemId from "./textInputFilters/ItemId"
import FullName from "./textInputFilters/FullName"

class DocumentRequirement {
	/**
	 *
	 * @param {DocumentRequirement} [requirement = null]
	 * @param {number} [requirement.id]
	 * @param {number} [requirement.organization]
	 * @param {string} [requirement.container]
	 * @param {string} [requirement.type]
	 * @param {string} [requirement.label]
	 * @param {number} [requirement.specifications]
	 * @param {boolean} [requirement.customers]
	 * @param {boolean} [requirement.providers]
	 */
	constructor(requirement = null) {
		try {
			this.id = new ItemId(requirement.id).number
			this.organization = new ItemId(requirement.organization).number
			this.container = enumOrNull(requirement.container, DOCUMENT_CONTAINER)
			this.type = enumOrNull(requirement.type, DOCUMENT_TYPE_MINIMAL)
			this.label = new FullName(requirement.label).text
			this.specifications = new ItemId(requirement.specifications).number
			this.customers = typeOrNull(requirement.customers, "boolean")
			this.providers = typeOrNull(requirement.providers, "boolean")
		} catch (e) {
			this.id = null
			this.organization = null
			this.container = null
			this.type = null
			this.label = null
			this.specifications = null
			this.customers = null
			this.providers = null
		}
	}
}

export default DocumentRequirement