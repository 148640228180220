import Address from "../../../globals/classes/shared/Address"

const addressInputCapture = id => {
	let label = null, line3 = null, isPrimary = false, isBilling = false, isSupport = false, display = false, isMailing = false, isPhysical = false
	try {
		line3 = document.getElementById(`${id}-line3-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		label = document.getElementById(`${id}-label-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		// console.log("display", document.getElementById(`${id}-show`).getAttribute("data-is-checked") === "true")
		display = document.getElementById(`${id}-show`).getAttribute("data-is-checked") === "true"
	} catch (e) {
		console.log(e)
	}
	try {
		isPrimary = document.getElementById(`${id}-isPrimary`).getAttribute("data-is-checked") === "true"
	} catch (e) {
		console.log(e)
	}
	try {
		isBilling = document.getElementById(`${id}-isBilling`).getAttribute("data-is-checked") === "true"
	} catch (e) {
		console.log(e)
	}
	try {
		isSupport = document.getElementById(`${id}-isSupport`).getAttribute("data-is-checked") === "true"
	} catch (e) {
		console.log(e)
	}
	try {
		isMailing = document.getElementById(`${id}-isMailing`).getAttribute("data-is-checked") === "true"
	} catch (e) {
		console.log(e)
	}
	try {
		isPhysical = document.getElementById(`${id}-isPhysical`).getAttribute("data-is-checked") === "true"
	} catch (e) {
		console.log(e)
	}
	const line1 = document.getElementById(`${id}-line1-input`).value
	const line2 = document.getElementById(`${id}-line2-input`).value
	const country = {
		code: document.getElementById(`${id}-country-input`).getAttribute("data-id"),
		code2: document.getElementById(`${id}-country-input`).getAttribute("data-c"),
		parent: document.getElementById(`${id}-country-input`).getAttribute("data-b"),
		name: document.getElementById(`${id}-country-input`).getAttribute("data-a"),
	}
	const state = {
		code: document.getElementById(`${id}-state-input`).getAttribute("data-id"),
		name: document.getElementById(`${id}-state-input`).getAttribute("data-a"),
		country: document.getElementById(`${id}-state-input`).getAttribute("data-b"),
	}
	const city = document.getElementById(`${id}-city-input`).value
	const postCode = document.getElementById(`${id}-postCode-input`).value

	return new Address({label : label, line1: line1, line2: line2, line3: line3, city: city, postCode: postCode,
		country: country, state: state, isActive: true,	isBilling: isBilling, isMailing: isMailing, isPrimary: isPrimary,
		isPhysical: isPhysical, isSupport: isSupport,	isVerified: false, display: display})
}

export default addressInputCapture