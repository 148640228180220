import React from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.favorite
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const MarkUnpaidButton = props => {
	return props.order.buttons.unpaid ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"unpaid"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.MARK_UNPAID.X)}}
				left={{icon: ICONS.STAMP}} className={"paralian-theme-level-1"}
				padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}} onClick={async () => {
				try {
					const response = await editOrder(props.order.id, ORDER_BUTTON.MARK_UNPAID)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						fadingMessage("provider-unpaid-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
						props.refreshOrders()
					}
				} catch (e) {
					console.log(e)
				}
			}}/>
		</div> : <></>
}

export default MarkUnpaidButton