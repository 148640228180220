import {osVersion, osName, fullBrowserVersion, browserName, mobileVendor, mobileModel, engineName, engineVersion} from 'react-device-detect'
const getDeviceData = () => {
	return {
		os: {
			name: osName,
			version: osVersion
		},
		browser: {
			name: browserName,
			version: fullBrowserVersion
		},
		device: {
			vendor: mobileVendor,
			model: mobileModel
		},
		engine: {
			name: engineName,
			version: engineVersion
		}
	}
}

export default getDeviceData