import {SERVER_RESPONSE_STATE} from "../../constants/shared/enumerators"

/**
 *
 * @param {Event} event
 * @param {*} parameters
 * @param {function} sendData - function which communicates with server, takes parameters as sole parameter, returns ServerResponse
 * @param {function} onSubmit - function which is called regardless of result of sendData and before onSuccess and onFail, takes parameters as sole parameter
 * @param {function} onSuccess - function which is called when status value of sendData is "success", takes parameters as sole parameter
 * @param {function} onFail - function which is called when status value of sendData is NOT "success", takes status value of sendData as sole parameter
 * @returns {Promise<void>}
 */
const submitToServer = async (event, parameters, sendData, onSuccess = null, onFail = null, onSubmit = null) => {
	event.preventDefault()
	const response = await sendData(parameters)
	if (onSubmit) {onSubmit(parameters)}
	if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
		if (onSuccess) {onSuccess(parameters, response)}
	} else {
		if (onFail) {onFail(response)}
	}
}

export default submitToServer