import { isObject, typeOrNull } from '../../functions/shared/local';

class Birthdate {
	/**
	 *
	 * @param {Birthdate} [birthdate = null]
	 * @param {Date|string|number} [birthdate.date]
	 * @param {boolean} [birthdate.verified]
	 */
	constructor(birthdate = null) {
		if (isObject(birthdate)) {
			this.date = typeOrNull(birthdate.date, "date")
			this.verified = typeOrNull(birthdate.verified, "boolean")
		} else {
			this.date = null
			this.verified = null
		}
	}

	get asDate() {
		return this.date !== null ? new Date(this.date) : null
	}

	get asLocaleString() {
		return this.date !== null ?
			this.asDate.toLocaleString(undefined, {timeZone: "UTC", day: "numeric", month: "long", year: "numeric"}) : ""
	}

	get asNumber() {
		return this.asDate !== null ? this.asDate.getTime() : null
	}

}

export default Birthdate;