import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"

/**
 *
 * @param {object} props
 * @param {React.HTMLProps.id} props.id
 * @param {React.HTMLProps.form} props.form
 * @param {React.HTMLProps.style} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLProps.className} props.className - className(s) of container's top level element
 * @param {string} [props.label]
 * @param {boolean} props.showLabel
 * @param {Organization[]} props.marinas
 * @param {string} props.autoComplete
 * @param {string} props.defaultValue - id of default marina
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {function} props.onChange
 * @param {Icon} [props.left = {icon: " ", onClick: () => {}}]
 * @param {Icon} props.right
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputGenericMarinasDropdown = props => {
	if (props.marinas.length < 1) {return <></>}
	const defaultIndex_ = props.marinas.findIndex(marina => marina.id === props.defaultValue)
	const defaultIndex = defaultIndex_ === -1 ? 0 : defaultIndex_
	const defaultItem = props.marinas[defaultIndex]
	const list = props.marinas.map((marina, index) => {return {value: index, label: marina.name.display}})
	const label = props.label || translate(DICTIONARY.MARINA.X)
	const selected = props.showLabel ? null : {value: -1, disabled: true, label: label}
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-marina`} className={className} size={"1"} left={left}
		label={label} multiple={false} topRadius={props.topRadius} dataId={defaultItem.id} placeholder={label}
		dataA={defaultItem.name.display} dataB={defaultItem.parent} showLabel={props.showLabel}
		defaultValue={defaultIndex} style={style} list={list} required={true} selected={selected} right={props.right}
		name={`${props.id}-marina`} form={props.form} autoFocus={false} bottomRadius={props.bottomRadius}
		dataIndex={defaultIndex} onChange={() => {
			try {
				const element = document.getElementById(`${props.id}-marina-input`)
				element.setAttribute("data-id", props.marinas[element.value].id)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", props.marinas[element.value].name.display)
				element.setAttribute("data-b", props.marinas[element.value].parent)
				onChange(props.marinas[element.value].id)
			} catch (e) {
				console.log(e)
			}
	}}/>
}

export default MobileInputGenericMarinasDropdown