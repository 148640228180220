import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {setNewContactName} from "../../../../globals/functions/client/serverFunctions"
import {getFromStorage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import submitToServer from "../../../../globals/functions/client/submitToServer"

/**
 * @param {Object} props
 * @param {JSX.Element} props.header
 * @param {Object} props.next
 * @param {Object} props.back
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginNewName = props => {
	useEffect(() => {
		newMessage("full-name-message-auto", translate(DICTIONARY.FULL_NAME.X),
			translate(DICTIONARY.NEW_ACCOUNT_NAME_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
	}, [])
	const onSuccess = () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
	const onSubmit = async event => {
		const name = document.getElementById("fullName-input").value
		await submitToServer(event, name, setNewContactName, onSuccess)
	}
	const name_ = getFromStorage("name", "session") || undefined
	// console.log(name_)
	sessionStorage.removeItem("name")
	const content =	<MobileInputText id={"fullName"} className={"paralian-theme-level-1-flipped"}
		name={"fullName"} pattern={TEXT_FIELD_PROPERTIES.personalSecurityPhrase.pattern} autoFocus={true} showLabel={false}
		left={{icon: ICONS.ID_CARD}} defaultValue={name_} right={{icon: ICONS.INFO_CIRCLE, onClick: () => {
		newMessage("full-name-message", translate(DICTIONARY.FULL_NAME.X),
			translate(DICTIONARY.NEW_ACCOUNT_NAME_CHARACTERS.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}
		placeholder={translate(DICTIONARY.FULL_NAME.X)}	label={translate(DICTIONARY.FULL_NAME.X)}/>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginNewName