import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {deleteEngine} from "../../../../globals/functions/client/serverFunctions"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import MobileEngine from "./MobileEngine"
import MobileFontAwesome from "../../MobileFontAwesome"
import Modal from "../../../../globals/components/Modal"
import Engine from "../../../../globals/classes/shared/vessels/Engine"

const MobileEngineSummary = props => {
	const [expand, setExpand] = useState(false)
	const onDelete = async () => {
		const confirmed = window.confirm(translate(DICTIONARY.DELETE_ENGINE_CONFIRM_QUERY.X))
		if (confirmed) {
			const response = await deleteEngine(props.id, props.engine.id)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onDelete()
			}}}
	const deleteButton = props.canEdit ? <div style={{textAlign: "left", opacity: 0.5, display: "flex",
		marginLeft: "2vh"}}>
			<div onClick={onDelete}  className={"w3-ripple"}>
				<MobileFontAwesome icon={ICONS.TRASH} style={{paddingRight: "1.5vh"}} label={translate(DICTIONARY.DELETE.X)}/>
				<i>{translate(DICTIONARY.DELETE.X)}</i>
			</div>
		</div> : <></>
	// console.log("expand", expand)
	const hours = props.engine.hours ? <div style={{fontSize: "2vh", opacity: 0.5}}>
		<i>{props.engine.hours.toLocaleString() + " " + translate(DICTIONARY.HOURS.X)}</i>
	</div> : <></>
	const content =<div className={"w3-animate-opacity"}>
		<div style={{fontSize: "4vh"}}>{props.engine.brand}</div>
		<div style={{fontSize: "3vh"}}>{props.engine.model}</div>
		<div style={{fontSize: "2vh"}}>{props.engine.year}</div>
		{hours}
	</div>
	const details = expand ? <Modal id={`engine-${props.engine.id}-details-modal`} padding={"0vh"} icon={ICONS.ENGINE}
		title={props.engine.label} onClick={() => setExpand(false)} body={<>{deleteButton}
		<MobileEngine key={`engine-${props.engine.id}`} units={props.units} canEdit={props.canEdit}
			id={props.id} engine={new Engine(props.engine)}	index={props.index} vesselId={props.id} top={"12.7vh"}
			refresh={props.refresh} zIndex={props.zIndex} onDelete={props.onDelete}/></>
	}/> : <></>
	return <>
		{details}
			<div className={"paralian-mobile-info-card w3-ripple"} 
			onClick={() => setExpand(true)}>
			<MobileInfoCardHeader setEdit={() => {}} edit={true} canEdit={false} labels={[props.engine.label]}/>
			{content}
		</div>
	</>
}

export default MobileEngineSummary