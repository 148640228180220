import React from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {setToStorage} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
import MobileButtonGeneric from "../buttons/MobileButtonGeneric"
import MobileLoginFormScaffolding from "../../../login/frames/MobileLoginFormScaffolding"

/**
 *
 * @param {Object} props
 * @param {Object} props.back
 * @param {string} props.question
 * @param {string[]} props.icon
 * @param {string} props.keyname
 * @param {Object} props.left
 * @param {Object} props.left.next
 * @param {string} props.left.text
 * @param {function} props.left.onClick
 * @param {string|number|boolean} props.left.value
 * @param {Object} props.right
 * @param {Object} props.right.next
 * @param {string} props.right.text
 * @param {function} props.right.onClick
 * @param {string|number|boolean} props.right.value
 * @returns {JSX.Element}
 * @constructor
 */
const MobileQuestionTwoOptions = props => {
	const icon = props.icon ?
		<MobileFontAwesome icon={props.icon} style={{display: "block", fontSize: "6vh", padding: "2vh"}}/> : <></>
	const content =	<div className={"w3-center w3-display-middle w3-animate-opacity"}
		style={{fontSize: "3vh", width: "80%"}}>
			{props.question}
			{icon}
			<div style={{fontWeight: "bold", paddingTop: "10vh"}}>
				<MobileButtonGeneric text={{content: props.left.text}}
					style={{width: "33%", borderRadius: "3vh", padding: "1vh", margin: "2vh"}}
					className={`paralian-theme-level-1-flipped`}
					onClick={async () => {
						if (props.left.onClick) {
							const response = await props.left.onClick()
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.left.next}})
							}
						} else {
							setToStorage(props.keyname, props.left.value, "session")
							store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.left.next}})
						}
					}}/>
				<MobileButtonGeneric text={{content: props.right.text}}
					style={{width: "33%", borderRadius: "3vh", padding: "1vh", margin: "2vh"}}
					className={`paralian-theme-level-1-flipped`}
					onClick={async () => {
						if (props.right.onClick) {
							const response = await props.right.onClick()
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.right.next}})
							}
						} else {
							setToStorage(props.keyname, props.right.value, "session")
							store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.right.next}})
						}
					}}/>
			</div>
		</div>
	return <MobileLoginFormScaffolding header={null} content={content} back={props.back}/>
}

export default MobileQuestionTwoOptions