import {DOCUMENT_CONTAINER, DOCUMENT_TYPE} from "../../../globals/constants/shared/enumerators"
import Target from "../../../globals/classes/shared/Target"
/**
 *
 * @param {string} container
 * @param {FormData} formData
 * @param {string} docType
 * @param {number} [containerId = -1]
 * @param {number} [documentId = -1]
 * @param {Object} [share = null]
 */
const setDocumentFormData = (container, formData, docType, containerId = -1, documentId = -1, share = null) => {
	const target = containerId === -1 || containerId > 0 ?
		container === DOCUMENT_CONTAINER.VESSELS ? {vessel: containerId, document: documentId} :
		container === DOCUMENT_CONTAINER.ORGANIZATIONS ? {organization: containerId, document: documentId} :
		container === DOCUMENT_CONTAINER.ACCOUNTS ? {account: containerId, document: documentId} : new Target() : new Target()
	// console.log(container, containerId, target)
	formData.set("type", docType)
	formData.set("target", JSON.stringify(target))
	formData.set("name", document.getElementById(`${container}-label-input`).value)
	if (share) {
		formData.set("shareIdType", share.idType)
		formData.set("shareId", share.id)
		formData.set("shareRequirement", share.requirement)
	}
	switch (docType) {
		case DOCUMENT_TYPE.UPKEEP:
			formData.set('category', document.getElementById(`${container}-category-input`).getAttribute("data-id"))
			formData.set('system', document.getElementById(`${container}-system-input`).getAttribute("data-id"))
			formData.set('id', document.getElementById(`${container}-id-input`).value)
			formData.set('notes', document.getElementById(`${container}-notes-input`).value)
			formData.set('vendor', document.getElementById(`${container}-vendor-input`).value)
			formData.set('issued', document.getElementById(`${container}-issued-input`).value)
			break
		case DOCUMENT_TYPE.CERTIFICATES:
			formData.set('country', document.getElementById(`${container}-country-input`).getAttribute("data-id"))
			formData.set('state', document.getElementById(`${container}-state-input`).getAttribute("data-id"))
			formData.set('certifyingEntityName', document.getElementById(`${container}-certifyingEntity-input`).value)
			formData.set('variety', document.getElementById(`${container}-type-input`).value)
			formData.set('number', document.getElementById(`${container}-number-input`).value)
			formData.set('awarded', document.getElementById(`${container}-awarded-input`).value)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.BONDS:
			formData.set('issuerOrganizationName', document.getElementById(`${container}-issuerOrganizationName-input`).value)
			formData.set('insuranceAgencyName', document.getElementById(`${container}-insuranceAgencyName-input`).value)
			formData.set('purpose', document.getElementById(`${container}-purpose-input`).value)
			formData.set('start', document.getElementById(`${container}-start-input`).value)
			formData.set('number', document.getElementById(`${container}-number-input`).value)
			formData.set('currency', document.getElementById(`${container}-currency-input`).getAttribute("data-id"))
			const amount = document.getElementById(`${container}-amount-whole-input`).value
			formData.set('amount', amount ? amount.replace(/,/g, "").replace(/\./g, "") : undefined)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.INSURANCE:
			formData.set('issuerOrganizationName', document.getElementById(`${container}-issuerOrganizationName-input`).value)
			formData.set('insuranceAgencyName', document.getElementById(`${container}-insuranceAgencyName-input`).value)
			formData.set('start', document.getElementById(`${container}-start-input`).value)
			formData.set('number', document.getElementById(`${container}-number-input`).value)
			formData.set('currency', document.getElementById(`${container}-currency-input`).getAttribute("data-id"))
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.VESSEL_TITLES:
			formData.set('titlingAuthorityName', document.getElementById(`${container}-titlingAuthorityName-input`).value)
			formData.set('number', document.getElementById(`${container}-titleNumber-input`).value)
			formData.set('purchased', document.getElementById(`${container}-vesselPurchaseDate-input`).value)
			break
		case DOCUMENT_TYPE.VESSEL_REGISTRATIONS:
			formData.set('registrationAuthorityName', document.getElementById(`${container}-registrationAuthorityName-input`).value)
			formData.set('registration', document.getElementById(`${container}-registration-input`).value)
			formData.set('tag', document.getElementById(`${container}-tag-input`).value)
			formData.set('issued', document.getElementById(`${container}-issued-input`).value)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS:
			formData.set('documentationAuthorityName', document.getElementById(`${container}-documentationAuthorityName-input`).value)
			formData.set('documentation', document.getElementById(`${container}-documentation-input`).value)
			formData.set('imo', document.getElementById(`${container}-IMO-input`).value)
			formData.set('callSign', document.getElementById(`${container}-callSign-input`).value)
			formData.set('issued', document.getElementById(`${container}-issued-input`).value)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS:
			// formData.set('legalName', document.getElementById(`${container}-legalName-input`).value)
			formData.set('country', document.getElementById(`${container}-country-input`).getAttribute("data-id"))
			formData.set('state', document.getElementById(`${container}-state-input`).getAttribute("data-id"))
			formData.set('registrationAuthorityName', document.getElementById(`${container}-registrationAuthorityName-input`).value)
			formData.set('number', document.getElementById(`${container}-registration-input`).value)
			formData.set('issued', document.getElementById(`${container}-issued-input`).value)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.CONTRACTS:
			formData.set('start', document.getElementById(`${container}-start-input`).value)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		case DOCUMENT_TYPE.PUBLIC:
			formData.set('published', document.getElementById(`${container}-published-input`).value)
			formData.set('expiration', document.getElementById(`${container}-expiration-input`).value)
			break
		default:
			break
	}
}

export default setDocumentFormData