import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class Filename extends TextInputFilter {
	minLength = 5
	maxLength = 17
	regexp = /[^\w.]/gi
}

export default Filename;