import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage,	translate} from "../../../../globals/functions/client/localFunctions"
import {addOrgServiceMarinas,	getMarinasWithinOrgServiceArea} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileNearbyMarinaCard from "../../../main/marinas/MobileNearbyMarinaCard"

const MobileLoginOrgServiceMarinas = props => {
	/* TODO: use MobileInputSelectServiceMarinas */
	const [marinas, setMarinas] = useState([])
	const [selected, setSelected] = useState([])
	// console.log("selected", selected)
	useEffect(() => {
		if (props.message) {
			newMessage("service-marinas-message", translate(DICTIONARY.MARINAS_AND_STORAGE_FACILITIES.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}
		// const form = getFromStorage("form", "session")
		// TODO: handle what happens when we get an empty array or no response
		getMarinasWithinOrgServiceArea().then(response => {
			// console.log(response.payload)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				if (response.payload.length > 0) {
					setMarinas(response.payload)
				} else {
					/* TODO: allow users to input the name, and contact info of the marinas where they would like to work */
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
				}
			} else {
				/* TODO: show refresh icon */
			}
		})
	}, [props.message, props.next])
	const onSubmit = async event => {
		event.preventDefault()
		// setToStorage("selected-marinas", selected, "session")
		if (selected.length > 0) {
			const response = await addOrgServiceMarinas(selected)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			}
		} else {
			const confirmed = window.confirm(translate(DICTIONARY.NO_SELECTED_MARINAS_PROMPT.X))
			if (confirmed) {
				store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
			}
		}
	}
	const content =
			<div id={"selected-marinas"} style={{minHeight: "15vh"}} data-selected="">
			{marinas.map(marina => <MobileNearbyMarinaCard key={`marina-card-${marina.id}`} marina={marina}
				selected={selected} setSelected={setSelected}/>)}
			</div>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgServiceMarinas