import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileVesselsFleetCard from "../vessels/fleet/MobileVesselsFleetCard"
import MobileCustomerInvite from "./MobileCustomerInvite"

const MobileMyCustomers = props => {
	const [expanded, setExpanded] = useState(false)
	const [invite, setInvite] = useState(false)
	const [customers, setCustomers] = useState(props.customers || [])
	useEffect(() => {setCustomers(props.customers || [])}, [props.customers])
	const content = customers.length > 0 ? customers.map((customer, index) =>
		<MobileVesselsFleetCard	refresh={props.refresh}	key={`customer-fleet-${customer.id}`} customer={customer}
		setIndex={props.setIndex} index={index}/>) : <></>
	const invite_ = invite ? <MobileCustomerInvite onUndo={() => setInvite(false)} onAdd={customer => {
		props.refresh().then()
		setCustomers([...customers, customer])
		setInvite(false)
	}}/> : <></>
	const content_ = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"}>
		{content}
		{invite_}
		{props.canInvite ? <MobileAddNewBanner text={translate(DICTIONARY.INVITE_CUSTOMER.X)} onClick={() => setInvite(!invite)}/> : <></>}
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.CUSTOMERS.X)} top={props.top}
			onClick={() => setExpanded(!expanded)}/>
		{content_}
	</>
}

export default MobileMyCustomers