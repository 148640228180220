import { isObject, typeOrNull } from '../../../functions/shared/local';
import HullCapacity from './HullCapacity';
import HullDimensions from './HullDimensions';

class Hull {
	/**
		*
		* @param {Hull} [hull = null]
		* @param {string} [hull.HIN]
		* @param {string} [hull.brand]
		* @param {string} [hull.modelLine]
		* @param {string} [hull.model]
		* @param {string} [hull.trim]
		* @param {number} [hull.year]
		* @param {HullCapacity} [hull.capacity]
		* @param {string} [hull.color]
		* @param {HullDimensions} [hull.dimensions]
		* @param {number} [hull.dryWeight]
		* @param {number} [hull.number]
		* @param {string} [hull.type]
	*/
	constructor (hull = null) {
		if (isObject(hull)) {
			this.HIN = typeOrNull(hull.HIN, "string")
			this.brand = typeOrNull(hull.brand, "string")
			this.modelLine = typeOrNull(hull.modelLine, "string")
			this.model = typeOrNull(hull.model, "string")
			this.trim = typeOrNull(hull.trim, "string")
			this.year =  typeOrNull(hull.year, "number")
			this.capacity = new HullCapacity(hull.capacity)
			this.color = typeOrNull(hull.color, "string")
			this.dimensions = new HullDimensions(hull.dimensions)
			this.dryWeight = typeOrNull(hull.dryWeight, "number")
			this.number = typeOrNull(hull.number, "number")
			this.type =  typeOrNull(hull.type, "string")
		} else {
			this.HIN = null
			this.brand = null
			this.modelLine = null
			this.model = null
			this.trim = null
			this.year =  null
			this.capacity = null
			this.color = null
			this.dimensions = null
			this.dryWeight = null
			this.number = null
			this.type =  null
		}
	}
}

export default Hull;