import React from "react"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {Object} [props.style = {margin: "0.5vh 0vh"}]
 * @param {number} props.number
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {boolean} props.showLabel
 * @param {boolean} [props.autoComplete = "off"]
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAddressLine = props => {
	const style = props.style || {margin: "0.5vh 0vh"}
	const autoComplete = props.autoComplete === true ? `address_line${props.number}` : "off"
	const label = `${translate(DICTIONARY.ADDRESS.X)} - ${translate(DICTIONARY.LINE.X)} ${props.number}`
	return <MobileInputText id={`${props.id}-line${props.number}`} form={props.form} name={`add${props.number}`}
		label={label} showLabel={props.showLabel} autoComplete={autoComplete} autoFocus={props.number === 1}
		required={props.number === 1}	pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} placeholder={label}
		maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX} text={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE}
		defaultValue={props.defaultValue} className={props.className} style={style}/>
}

export default MobileInputAddressLine