import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import {setHullDryWeight} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputWeight from "../MobileInputWeight"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {number} props.weight
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullWeightEdit = props => {
	// console.log(props.weight)
	const onSubmit = async event => {
		event.preventDefault()
		const element = document.getElementById("hull-weight-whole-input")
		let weight = Number(`${element.value}`.replace(/[,.]/g,''))
		if (props.units !== "SI") {
			weight = Number(convertToPreferredUnits(weight, "lb", "SI", 6, false, false))
		}
		const response = await setHullDryWeight(props.id, weight)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess(weight)
		}
	}
	const content = <div className={"paralian-theme-level-1"} style={{padding: "0vh 2vh", fontSize: "2.5vh"}}>
		<MobileInputWeight id={"hull-weight"} form={"hull-weight-form"} units={props.units}
			label={translate(DICTIONARY.DRY_WEIGHT.X)} value={props.weight}/>
	</div>
	return <MobileInputForm id={`hull-weight-form`} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselHullWeightEdit