import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {uploadDocument} from "../../../globals/functions/client/serverFunctions"
import setDocumentFormData from "./setDocumentFormData"
/**
 *
 * @param {FormData} formData
 * @param {string} id
 * @param {function} onSuccess
 * @param {string} docType - DOCUMENT_TYPE
 * @param {string} container - DOCUMENT_CONTAINER
 * @param {number} containerId
 * @param {number|null} [docId = null]
 * @param {Object|null} [share = null]
 */
const submitDocumentUpload = (formData, id, onSuccess, container, docType, containerId, docId = null, share = null) => {
	try {
		setDocumentFormData(container, formData, docType, containerId, docId ? docId : -1, share)
		uploadDocument(formData, docId ? "replace" : "upload").then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				onSuccess(response.payload)
			}
		})
	}	catch(e) {
		console.log(e)
	}
}

export default submitDocumentUpload