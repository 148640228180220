import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import submitToServer from "../../../../globals/functions/client/submitToServer"
import {setCustomerVesselState} from "../../../../globals/functions/client/serverFunctions"

const MobileCustomerVesselState = props => {
	const [state, setState] = useState(props.state)
	useEffect(() => setState(props.state), [props.state])
	const onSubmit = async event => {
		await submitToServer(event, {vessel: props.vessel, state: state}, setCustomerVesselState, props.onSuccess)
	}
	const content = <div style={{fontSize: "10vh"}}>
		<div style={{fontSize: "3vh", textAlign: "right"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.CUSTOMER_VESSEL_STATE.X)}
				style={{padding: "0vh 1vh"}} onClick={() => newMessage("customer-state-info-message",
				translate(DICTIONARY.CUSTOMER_VESSEL_STATE.X), translate(DICTIONARY.CUSTOMER_VESSEL_STATE_INFO.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
		</div>
		<MobileFontAwesome icon={state === "TEMPORARY" ? ICONS.SHIELD_SLASH : ICONS.SHIELD}
			label={translate(DICTIONARY[state].X)} style={{padding: "0vh 1vh"}}
			onClick={() => setState(state === "TEMPORARY" ? "PERMANENT" : "TEMPORARY")}/>
		<div style={{fontSize: "3vh"}}>{translate(DICTIONARY[state].X)}</div>
	</div>
	return <MobileInputForm id={"customer-vessel-state"} onSubmit={onSubmit} content={content}/>

}

export default MobileCustomerVesselState