import { isObject, typeOrNull } from '../../../functions/shared/local';
import Coordinates from '../Coordinates';

class AIS {
	/**
	 *
	 * @param {AIS} [ais = null]
	 * @param {number} [ais.mmsi]
	 * @param {string|number|Date} [ais.timestamp]
	 * @param {Coordinates} [ais.coordinates]
	 * @param {number} [ais.course]
	 * @param {number} [ais.speed]
	 * @param {number} [ais.heading]
	 * @param {number} [ais.imo]
	 * @param {string} [ais.name]
	 * @param {number} [ais.type]
	 * @param {string} [ais.callSign]
	 * @param {number} [ais.A]
	 * @param {number} [ais.B]
	 * @param {number} [ais.C]
	 * @param {number} [ais.D]
	 * @param {number} [ais.draught]
	 * @param {string} [ais.destination]
	 * @param {string} [ais.eta_ais]
	 * @param {string} [ais.eta]
	 * @param {string} [ais.src]
	 */
	constructor (ais = null) {
		if (isObject(ais)) {
			this.mmsi = typeOrNull(ais.mmsi, "number")
			this.timestamp = typeOrNull(ais.timestamp, "date")
			this.coordinates = new Coordinates(ais.coordinates)
			this.course = typeOrNull(ais.course, "number")
			this.speed = typeOrNull(ais.speed, "number")
			this.heading = typeOrNull(ais.heading, "number")
			this.imo = typeOrNull(ais.imo, "number")
			this.name = typeOrNull(ais.name, "string")
			this.type = typeOrNull(ais.type, "number")
			this.callSign = typeOrNull(ais.callSign, "string")
			this.A = typeOrNull(ais.A, "number")
			this.B = typeOrNull(ais.B, "number")
			this.C = typeOrNull(ais.C, "number")
			this.D = typeOrNull(ais.D, "number")
			this.draught = typeOrNull(ais.draught, "number")
			this.destination = typeOrNull(ais.destination, "string")
			this.eta_ais = typeOrNull(ais.eta_ais, "string")
			this.eta = typeOrNull(ais.eta, "string")
			this.src = typeOrNull(ais.src, "string")
		} else {
			this.mmsi = null
			this.timestamp = null
			this.coordinates = new Coordinates()
			this.course = null
			this.speed = null
			this.heading = null
			this.imo = null
			this.name = null
			this.type = null
			this.callSign = null
			this.A = null
			this.B = null
			this.C = null
			this.D = null
			this.draught = null
			this.destination = null
			this.eta_ais = null
			this.eta = null
			this.src = null
		}
	}
}

export default AIS;