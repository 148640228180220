import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {getServiceProviders} from "../../../../globals/functions/client/serverFunctions"
import {getFromStorage,	newMessage,	setToStorage, share, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileProviderSelectionCard from "../../MobileProviderSelectionCard"
import OrderMetrics from "../../../../globals/classes/shared/metrics/OrderMetrics"
import Organization from "../../../../globals/classes/shared/Organization"
import Rating from "../../../../globals/classes/shared/metrics/Rating"
/* TODO: get data from localStorage instead of constants */
/* TODO: sort & filter */
/* TODO: translate */
/* 	Sort:
			Paralian Score
			Overall Rating
			Reviews
			Average Response Time
			Price
		Filter:
			Paralian Preferred
			Marina Recommended
			Favorites
 */
const MobileVesselCleaningProviders = props => {
	const [refresh, setRefresh] = useState(0)
	const [providers, setProviders] = useState([])
	const [selected, setSelected] = useState(-1)
	useEffect(() => {
		const order = getFromStorage("order", "session")
		order.items = [...order.servicesExterior, ...order.servicesInterior].filter(v => v.id !== -1 && v.id !== -2)
		if (order.engineFlush) {order.items = [...order.items, ...order.engineFlush]}
		setToStorage("order", order, "session")
		getServiceProviders(order).then(response => {
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				try {
					if (response.payload.providers.length < 1) {
						newMessage("no-providers", translate(DICTIONARY.NO_PROVIDERS.X),
							translate(DICTIONARY.NO_PROVIDERS_MESSAGE.X), "w3-pale-yellow", ICONS.FROWN,
							[{id: "share", icon: null, text: translate(DICTIONARY.SEND_INVITE.X),
								onClick: () => share({title: translate(DICTIONARY.JOIN_PARALIAN_EXCLAMATION.X),
								text: translate(DICTIONARY.PROVIDER_INVITE_MESSAGE.X), url: process.env.REACT_APP_PUBLIC_URL}).then()}])
						props.setDisabled(true)
					}
					if (response.payload.missingKeys.VESSEL.length > 0) {
						newMessage("missing-keys-vessel", translate(DICTIONARY.MISSING_DATA_VESSEL.X),
							translate(DICTIONARY.MISSING_DATA_VESSEL_MESSAGE.X) +
							response.payload.missingKeys.VESSEL.map(key => translate(key)).join(", "),
							"w3-pale-yellow", ICONS.SHIP)
					}
					if (response.payload.missingKeys.ACCOUNT.length > 0) {
						newMessage("missing-keys-account", translate(DICTIONARY.MISSING_DATA_ACCOUNT.X),
							translate(DICTIONARY.MISSING_DATA_ACCOUNT_MESSAGE.X) +
							response.payload.missingKeys.ACCOUNT.map(key => translate(key)).join(", "),
							"w3-pale-yellow", ICONS.USER)
					}
					setProviders(response.payload.providers.map(v => {
						return {
							metrics: new OrderMetrics(v.metrics),
							organization: new Organization(v.organization),
							rating: new Rating({
								overall: v.metrics.rating.overall.mean,
								service: v.metrics.rating.service.mean,
								quality: v.metrics.rating.quality.mean,
								value: v.metrics.rating.value.mean,
								reviews: v.metrics.rating.overall.samples
							}),
							price: v.price,
							isFavorite: v.isFavorite
						}}))
				} catch (e) {
					console.log(e)
				}
			} else if (response.status === SERVER_RESPONSE_STATE.BAD) {
				if (response.payload.missingKeys.VESSEL.length > 0) {
					newMessage("missing-keys-vessel", translate(DICTIONARY.MISSING_DATA_VESSEL.X),
						translate(DICTIONARY.MISSING_DATA_VESSEL_MESSAGE.X) +
						response.payload.missingKeys.VESSEL.map(key => translate(key)).join(", "),
						"w3-pale-yellow", ICONS.SHIP)
				}
				if (response.payload.missingKeys.ACCOUNT.length > 0) {
					newMessage("missing-keys-account", translate(DICTIONARY.MISSING_DATA_ACCOUNT.X),
						translate(DICTIONARY.MISSING_DATA_ACCOUNT_MESSAGE.X) +
						response.payload.missingKeys.ACCOUNT.map(key => translate(key)).join(", "),
						"w3-pale-yellow", ICONS.USER)
				}
			}
		})
	}, [refresh])
	useEffect(() => {if (selected.length > 0) {props.setDisabled(false)}}, [selected])
	/* TODO: allow ranked selection of providers */
	/* TODO: turn into a list with map */
	// console.log(providers)

	return providers.length > 0 ?
		providers.map(provider => <MobileProviderSelectionCard key={provider.organization.id}
			provider={provider}	selected={selected} setSelected={setSelected}/>) :
		<MobileFontAwesome id={"refresh"} icon={ICONS.SYNC}	style={{fontSize: "10vh"}}
			onClick={() => setRefresh(refresh + 1)} label={translate(DICTIONARY.REFRESH.X)}/>
}

export default MobileVesselCleaningProviders