import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {ccBrandToLabelAndIcon, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {detachStripePaymentMethod, updateStripeDefaultPaymentMethod} from "../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {stripe.Card} props.card
 * @param {string} props.defaultSource
 * @param {boolean} props.isOrganization
 * @param {function} props.refresh
 * @param {function} props.removeCard
 * @param {function} props.setDefaultPaymentMethod
 * @return {JSX.Element}
 * @constructor
 */
const MobilePaymentMethodCard = props => {
	/* TODO: allow update of address and expiration date */
	const date = new Date()
	date.setUTCMonth(props.card.exp_month - 1)
	date.setUTCFullYear(props.card.exp_year)
	const brandDisplay = ccBrandToLabelAndIcon(props.card.brand)
	return <div key={props.card.id} className="paralian-mobile-info-card">
		{
			props.card.id === props.defaultSource ?
				<div className={"paralian-theme-level-1"} style={{padding: "1vh",borderRadius: "1vh", marginBottom: "1vh",
					fontSize: "2vh"}}>
					<MobileFontAwesome icon={ICONS.STAR[1]}	label={translate(DICTIONARY.DEFAULT.X)}
						style={{fontSize: "2vh", marginRight: "1vh"}}	onClick={() => {
							newMessage("defaultPM", translate(DICTIONARY.DEFAULT.X),
								translate(DICTIONARY.PAYMENT_METHOD_DEFAULT_MESSAGE.X), "w3-pale-blue", ICONS.CREDIT_CARD)}}/>
					<span>{translate(DICTIONARY.PREFERRED_PAYMENT_METHOD.X)}</span>
				</div> : <></>
		}
		<div style={{display: "flex"}}>
			<div style={{display: "flex", width: "100%"}}>
				<MobileFontAwesome icon={brandDisplay.icon} label={brandDisplay.label} style={{marginRight: "2vh", fontSize: "6vh"}}/>
				<div style={{display: "block", textAlign: "left", margin: "auto 0vw", width: "100%", fontSize: "2vh"}}>
					<div><b>{brandDisplay.label} </b><b>*{props.card.last4}</b></div>
					<div>
						{`${translate(DICTIONARY.EXPIRES.X)} ${date.toLocaleString(undefined, 
							{timeZone: "UTC", month: "short", year: "numeric"})}`}
					</div>
					{
						props.card.id === props.defaultSource ? <></> :
							<div onClick={async () => {
								 const confirmed = window.confirm(translate(DICTIONARY.PAYMENT_METHOD_DEFAULT_QUERY.X))
								 if (confirmed) {
									 const response = await updateStripeDefaultPaymentMethod(props.card.id, props.isOrganization)
									 if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
										 props.refresh().then()
										 props.setDefaultPaymentMethod(props.card.id)
									 }}}}>
								<i>{translate(DICTIONARY.SET_AS_PREFERRED.X)}</i>
								<MobileFontAwesome icon={["far", ICONS.STAR[1]]} label={translate(DICTIONARY.SET_AS_PREFERRED.X)}
								 style={{fontSize: "2vh", marginLeft: "1vh"}}/>
							</div>
					}
				</div>
			</div>
			<div style={{minWidth: "4vh"}}>
				<MobileFontAwesome icon={ICONS.TRASH} label={translate(DICTIONARY.DELETE.X)} style={{fontSize: "2vh"}}
					onClick={async () => {
						const confirmed = window.confirm(translate(DICTIONARY.PAYMENT_METHOD_DELETE_QUERY.X))
						if (confirmed) {
							const response = await detachStripePaymentMethod(props.card.id, props.isOrganization)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								props.refresh().then()
								props.removeCard(props.card.id)
							}}}}/>
			</div>
		</div>
	</div>
}

export default MobilePaymentMethodCard