import React from "react"
import MobileSVGIcon from "./MobileSVGIcon"

const TrailerIcon = props => {
	const icon = <>
		<defs>
			<filter height={"200%"} width={"200%"} y={"-50%"} x={"-50%"} id={"svg_24_blur"}>
				<feGaussianBlur stdDeviation={"10"} in={"SourceGraphic"}/>
			</filter>
			<filter height={"200%"} width={"200%"} y={"-50%"} x={"-50%"} id={"svg_22_blur"}>
				<feGaussianBlur stdDeviation={"1"} in={"SourceGraphic"}/>
			</filter>
		</defs>
		<g>
			<line strokeLinecap={"null"} strokeLinejoin={"null"} id={"svg_21"} y2={"249.9536"} x2={"475.49984"}
				y1={"314.9536"} x1={"436.49984"} fillOpacity={"null"} strokeOpacity={"null"} strokeWidth={"10"}
				stroke={"currentColor"} fill={"none"}/>
			<line stroke={"currentColor"} filter={"url(#svg_24_blur)"} strokeWidth={"20"} strokeLinecap={"null"}
				strokeLinejoin={"null"} id={"svg_24"} y2={"120.99175"} x2={"361.50246"} y1={"126.4454"} x1={"167.50246"}
				fillOpacity={"0.4"} strokeOpacity={"null"} fill={"none"}/>
			<g id={"svg_25"}>
				<ellipse ry={"28"} rx={"28.5"} id={"svg_15"} cy={"335.45365"} cx={"129.49998"} strokeOpacity={"null"}
					strokeWidth={"0"} stroke={"currentColor"} fill={"currentColor"}/>
				<ellipse ry={"28"} rx={"28.5"} id={"svg_16"} cy={"335.45365"} cx={"193.50002"} strokeOpacity={"null"}
					strokeWidth={"0"} stroke={"currentColor"} fill={"currentColor"}/>
				<ellipse ry={"28"} rx={"28.5"} id={"svg_17"} cy={"335.45365"} cx={"257.50004"} strokeOpacity={"null"}
					strokeWidth={"0"} stroke={"currentColor"} fill={"currentColor"}/>
				<path id={"svg_19"} d={"m92.5,341.09427l-9.5,0.90626l0,-26l35,-24l144,0l42,25.49999l0,24.50001l-10,0l0," +
					"-21l-32,-19l-143,0l-26,17l-0.5,23z"} fillOpacity={"null"} strokeOpacity={"null"} strokeWidth={"0"}
					stroke={"currentColor"} fill={"currentColor"}/>
				<line strokeWidth={"10"} strokeLinecap={"null"} strokeLinejoin={"null"} id={"svg_20"} y2={"312.45365"}
					x2={"495.5"} y1={"315.45365"} x1={"36.5"} fillOpacity={"null"} strokeOpacity={"null"}
					stroke={"currentColor"} fill={"none"}/>
				<path filter={"url(#svg_22_blur)"} id={"svg_22"} d={"m16.5,311.45366l30.5,-50.45313c187.5,-6.54687 158.5," +
					"-44.54687 231.5,-60.54687c73,-16 201,-4 200.5,-4.45313c0.5,0.45313 -51.5,85.45313 -57.5,93.45313c-6,8 -12,32" +
					" -82.5,31.54687l-305,2l0,-11l-17.5,-0.54687z"} fillOpacity={"0.4"} strokeOpacity={"null"} strokeWidth={"0"}
					stroke={"currentColor"} fill={"currentColor"}/>
			</g>
		</g>
	</>
	return <MobileSVGIcon icon={icon} onClick={props.onClick}/>
}

export default TrailerIcon