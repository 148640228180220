import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {getAllCountries,} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {function} props.onChange
 * @param {function} props.setName
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {string} props.bottomRadius
 * @param {string} props.topRadius
 * @param {boolean} props.showLabel
 * @param {string} props.label
 * @param {Object} props.right
 * @param {string[]} props.right.icon
 * @param {function} props.right.onClick
 * @param {Object} props.left
 * @param {string[]} props.left.icon
 * @param {function} props.left.onClick
 * @param {React.HTMLAttributes.style} props.style
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCountryCodeDropdown = props => {
	const [codes, setCodes] = useState([])
	useEffect(() => {
		getAllCountries().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const list = []
				for (let i = 0; i < response.payload.length; i++) {
					list[i] = {
						id: response.payload[i].code,
						label: `+ ${response.payload[i].phoneCountryCode} (${response.payload[i].code})`,
						value: i,
						name: response.payload[i].name,
						code: response.payload[i].phoneCountryCode
					}
				}
				setCodes([...list])
			}
		})
	},[])
	const onChange = props.onChange ? props.onChange : () => {}
	const setName = props.setName ? props.setName : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	const defaultValue = props.defaultValue ? props.defaultValue.number ?
		`+ ${props.defaultValue.number} (${props.defaultValue.code})` : "+ 1 (USA)" : "+ 1 (USA)"
	const defaultIndex = codes.findIndex(item => item.label === defaultValue)
	const defaultItem = (!!defaultIndex && defaultIndex !== -1) ? codes[defaultIndex] : {label: "", value: "", id: "", code: ""}
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}`} className={className} size={"1"} dataA={defaultItem.code}
		label={translate(DICTIONARY.COUNTRY_CODE.X)} multiple={false} topRadius={props.topRadius} dataId={defaultValue}
		defaultValue={defaultIndex} style={props.style} showLabel={props.showLabel} right={props.right} dataB={defaultItem.id}
		name={"countryCodes"} form={props.form} autoFocus={false} required={true} list={codes} left={props.left}
		bottomRadius={props.bottomRadius} dataIndex={defaultIndex} onChange={() => {
			try {
				const element = document.getElementById(`${props.id}-input`)
				element.setAttribute("data-id", codes[element.value].id)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", codes[element.value].code)
				element.setAttribute("data-b", codes[element.value].name)
				setName(codes[element.value].name)
				onChange()
			} catch (e) {
				console.log(e)
			}
		}}/>
}

export default MobileInputCountryCodeDropdown