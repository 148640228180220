import { isObject, typeOrNull } from '../../functions/shared/local';

class ViewAccess {
	/**
	 *
	 * @param {ViewAccess} [viewAccess = null]
	 * @param {ViewAccessor[]} [viewAccess.restricted]
	 * @param {ViewAccessor[]} [viewAccess.full]
	 */
	constructor(viewAccess = null) {
		if (isObject(viewAccess)) {
			this.restricted = typeOrNull(viewAccess.restricted, "array")
			this.full = typeOrNull(viewAccess.full, "array")
		} else {
			this.restricted = []
			this.full = []
		}
	}
}

export default ViewAccess;