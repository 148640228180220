import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import Servicio from "../../../globals/classes/shared/Servicio"
/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {Object} props.service
 * @param {Servicio[]} props.services
 */
const MobileEmployeeServiceAccessFields = props => {
	try {
		const service_ = new Servicio(props.services.find(service__ => props.service.itemCategory === service__.id))
		const label = service_.labelsAsString
		const assign = props.service.access.includes("ASSIGN") ?
			<MobileFontAwesome icon={ICONS.PEOPLE_ARROWS} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage("assign", translate(DICTIONARY.ASSIGN.X), translate(DICTIONARY.ASSIGN_MESSAGE.X),
					"w3-pale-blue", ICONS.PEOPLE_ARROWS)
			}}/> : <></>
		const accept = props.service.access.includes("ACCEPT") ?
			<MobileFontAwesome icon={ICONS.COMMENT_ALT_CHECK} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage("accept", translate(DICTIONARY.ACCEPT.X), translate(DICTIONARY.ACCEPT_MESSAGE.X),
					"w3-pale-blue", ICONS.COMMENT_ALT_CHECK)
			}}/> : <></>
		const reject = props.service.access.includes("REJECT") ?
			<MobileFontAwesome icon={ICONS.COMMENT_ALT_TIMES} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage("reject", translate(DICTIONARY.REJECT.X), translate(DICTIONARY.REJECT_MESSAGE.X),
					"w3-pale-blue", ICONS.COMMENT_ALT_TIMES)
			}}/> : <></>
		const view = props.service.access.includes("VIEW") ?
			<MobileFontAwesome icon={ICONS.EYE} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage("view", translate(DICTIONARY.VIEW.X), translate(DICTIONARY.VIEW_MESSAGE.X),
					"w3-pale-blue", ICONS.EYE)
			}}/> : <></>
		return <div key={`${props.id}-${props.service.service}`} className={"paralian-theme-level-1"}
			style={{margin: "1vh 0vh", padding: "1vh", borderRadius: "1vh"}}>
			<div className={"paralian-theme-level-1-flipped"} style={{margin: "1vh", padding: "1vh", borderRadius: "1vh"}}>
				<b>{label}</b>
			</div>
			<div style={{fontSize: "5vh", margin: "2vh 0vh"}}>
				{view}
				{accept}
				{reject}
				{assign}
			</div>
		</div>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileEmployeeServiceAccessFields