import React, {useEffect, useState} from "react"
import {DEVICE_TYPE} from "../constants/client/constants"
import {store} from "../../index"
import {manageCrash} from "../functions/client/localFunctions"
import getMode from "../functions/client/mode/getMode"
import SplashScreen from "./SplashScreen"
import Mobile from "../../mobile/Mobile"
// import Desktop from "../desktop/Desktop"
/**
 * Checks login status and device type. If unavailable, it gathers the information and stores.
 * Once the information is available, it moves on to Mobile or Desktop depending on device type.
 * Stays in Splash screen while this is happening.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const Welcome = () => {
	// console.log("Welcome rendered")
	const [mode, setMode] = useState(getMode())
	const state_ = store.getState()
	const device_ = state_.device.type
	const account_ = state_.account.type
	const loggedIn_ = state_.device.loggedIn
	useEffect( () => {
		// console.log("Welcome useEffect called")
		try {
			const thisMode = getMode()
			if (!thisMode.loggedIn && !thisMode.device) {
				thisMode.refreshMode().then(() => setMode({...thisMode}))
			}	else if (!thisMode.loggedIn) {
				thisMode.refreshLoggedIn().then(() => setMode({...thisMode}))
			} else if (!thisMode.device) {
				thisMode.refreshDevice()
				setMode({...thisMode})
			}
		} catch (e) {
			manageCrash(e, "Welcome useEffect", "error")
		}
	}, [device_, account_, loggedIn_])

	try {
		if (mode.device === DEVICE_TYPE.DESKTOP && typeof mode.loggedIn === "boolean") {
			/* TODO: Desktop code not available. Routing to Mobile */
			return <Mobile/> // <Desktop/>
		} else if (mode.device === DEVICE_TYPE.MOBILE && typeof mode.loggedIn === "boolean") {
			return <Mobile/>
		} else {
			return <SplashScreen/>
		}
	} catch (e) {
		manageCrash(e, "Welcome", "error")
		return <></>
	}
}

export default Welcome