import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.defaultFeet {string}
 * @param props.defaultInches {string}
 * @param props.required {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.onChange {Object} - function
 * @returns {*}
 * @constructor
 */
const MobileInputFeetInches = props => {
	return <div style={{display: "inline-flex"}}>
		<MobileInputGeneric type={"number"} id={`${props.id}-whole`} name={`${props.name}-whole`} form={props.form}
			autoFocus={props.autoFocus} maxLength={"3"}	autoComplete={"off"} pattern={"([0-9]){1,3}"} step={"1"}
			required={props.required} placeholder={"###"} min={"0"} max={"999"} defaultValue={props.defaultFeet}
			onChange={props.onChange}	label={`${props.label} - ${translate(DICTIONARY.FEET.X)}`}
			showLabel={false} className={props.className} style={props.style} bottomRadius={props.bottomRadius}
			topRadius={props.topRadius}/><b style={{margin: "auto"}}>{"'"}</b>
		<MobileInputGeneric type={"number"} id={`${props.id}-fraction`} name={`${props.name}-fraction`} form={props.form}
			autoFocus={false} maxLength={"2"} autoComplete={"off"} pattern={"([0-9]){1,2}"} step={"1"}
			required={props.required} placeholder={"##"} min={"0"} max={"11"} defaultValue={props.defaultInches}
			onChange={props.onChange}	label={`${props.label} - ${translate(DICTIONARY.INCHES.X)}`}
			showLabel={false} className={props.className} style={props.style} bottomRadius={props.bottomRadius}
			topRadius={props.topRadius}/><b style={{margin: "auto", paddingRight: "2vh"}}>{'"'}</b>
	</div>
}

export default MobileInputFeetInches