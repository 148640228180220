import React from "react"
import {getFromStorage} from "../../../globals/functions/client/localFunctions"
import confirmLoginPassword from "./confirmLoginPassword"
import MobileInputPassword from "../../shared/input/text/MobileInputPassword"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"

const MobilePasswordEntry = () => {
	let username
	const form = getFromStorage("form", "session")
	try {
		username = form.account.username
	} catch (e) {
		console.log(e)
	}
	const content = <MobileInputPassword id={"password"} className={"paralian-theme-level-1-flipped"}
		form={"password-form"} autoFocus={true} name={"password"} showLabel={false} username={username}/>
	return <MobileInputForm id={"password"} content={content} onSubmit={confirmLoginPassword} buttonSize={"6vh"}
		style={{width: "100%", padding: "0vh 4vh"}} className={"w3-display-middle w3-animate-opacity"}/>
}

export default MobilePasswordEntry