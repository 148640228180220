import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {deLinkMarina, setAccountDefaultMarina} from "../../../globals/functions/client/serverFunctions"
import {getFromStorage, newMessage, setToStorage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileImage from "../account/MobileImage"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileParalianMarinaSearch from "./MobileParalianMarinaSearch"
import Modal from "../../../globals/components/Modal"
import Image from "../../../globals/classes/shared/Image"
import logoPlaceholder from "../../../images/pencil-paintbrush-duotone.svg"
import State from "../../../globals/classes/shared/State"
import Country from "../../../globals/classes/shared/Country"

const MobileMyMarinas = props => {
	const [expanded, setExpanded] = useState(false)
	const [add, setAdd] = useState(false)
	const [marinas, setMarinas] = useState(props.marinas)
	const [primary, setPrimary] = useState(props.primary)
	useEffect(() => setMarinas(props.marinas), [props.marinas])
	useEffect(() => setPrimary(props.primary), [props.primary])
	useEffect(() => setExpanded((props.marinas || []).length === 0), [props.marinas])
	const onUnlink = id => {
		props.refresh().then()
		const marinas_ = [...marinas]
		setMarinas([...marinas_.splice(marinas_.findIndex(marina => marina.id === id), 1)])
	}
	const onSetPrimary = id => {
		props.refresh().then()
		setPrimary(id)
		props.setIndex(id)
	}
	const genMarinasBox = marina => {
		return <div className={"paralian-mobile-info-card"} key={`my-marina-${marina.id}`} >
			<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
				textAlign: "center", marginBottom: "1vh"}}>
				<div style={{minWidth: "10%"}}>
					{props.storageMarinas.includes(marina.id) ? <></> :
						<MobileFontAwesome icon={ICONS.UNLINK} style={{fontSize: "3vh"}} onClick={async () => {
						const confirmed = window.confirm(translate(DICTIONARY.UNLINK_ACCOUNT_QUERY.X))
						if (confirmed) {
							const response = await deLinkMarina(marina.id)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								onUnlink(marina.id)
							}
						}
					}}/>}
				</div>
				<div style={{width: "100%", padding: "0.5vh"}}>
					<i>{marina.name.display}</i>
				</div>
				<div style={{minWidth: "10%"}}>
					<MobileFontAwesome icon={ICONS.RANDOM} style={{fontSize: "3vh"}} onClick={() => props.setIndex(marina.id)}/>
				</div>
			</div>
			{
				primary === marina.id ?
					<div className={"paralian-theme-level-1"} style={{padding: "1vh",borderRadius: "1vh", marginBottom: "1vh",
						fontSize: "2vh"}}>
						<MobileFontAwesome icon={ICONS.STAR[1]}	label={translate(DICTIONARY.DEFAULT.X)}
							tyle={{fontSize: "2vh", marginRight: "1vh"}} onClick={() => {
								newMessage("primary-marina", translate(DICTIONARY.PRIMARY_MARINA.X),
									translate(DICTIONARY.PRIMARY_MARINA_MESSAGE.X), "w3-pale-blue", ICONS.ANCHOR)}}/>
						<span>{" " + translate(DICTIONARY.PRIMARY.X)}</span>
					</div> :
					<div onClick={async () => {
						const confirmed = window.confirm(translate(DICTIONARY.PRIMARY_MARINA_QUERY.X))
						if (confirmed) {
							const response = await setAccountDefaultMarina(marina.id)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								const logo = getFromStorage("logo")
								if (!logo.path && marina.logo.path && marina.isParalianCustomer) {
									setToStorage("logo", marina.logo.path)
								}
								onSetPrimary(marina.id)
							}
						}}}>
						<i>{translate(DICTIONARY.SET_AS_PRIMARY.X) + " "}</i>
						<MobileFontAwesome icon={["far", ICONS.STAR[1]]} label={translate(DICTIONARY.SET_AS_PRIMARY.X)}
							tyle={{fontSize: "2vh", marginLeft: "1vh"}}/>
					</div>
			}
			<div style={{display: "inline-flex", width: "100%", maxWidth: "36vh"}}>
				<MobileImage id={marina.id} placeholder={logoPlaceholder}
			 		image={new Image(marina.logo)} size={"10vh"} alt={"profile"} style={{margin: "auto 1vh"}}/>
				<div style={{width: "100%", textAlign: "right", margin: "auto"}}>
					<div>
						<i>{marina.addresses.physical.city}</i>
					</div>
					<div style={{fontSize: "3vh"}}>
						{marina.addresses.physical.state.name || marina.addresses.physical.country.name}
					</div>
				</div>
			</div>
		</div>
	}
	const newMarinas = add ?
		<Modal id={"add-marina-modal"} icon={ICONS.ANCHOR} title={translate(DICTIONARY.ADD_MARINA.X)} padding={"0vh"}
			 onClick={() => setAdd(false)} body={
			<div className={"w3-animate-opacity"} style={{zIndex: "200", display: "absolute"}}>
				<MobileParalianMarinaSearch id={"marina-search"} country={new Country()} state={new State()}
					className={"paralian-theme-level-1"} onSuccess={() => {
					props.refresh().then()
					setAdd(false)
				}}/>
			</div>
		}/>  : <></>
	const add_ =	props.canAdd ? <MobileAddNewBanner text={translate(DICTIONARY.ADD_MARINA.X)}
		onClick={() => {setAdd(!add)}}/> : <></>
	const content = !expanded ? <></> :
		<div className={"w3-animate-top w3-animate-opacity"}>
			<div>
				{props.marinas.map(marina => genMarinasBox(marina))}
			</div>
			{newMarinas}
			{add_}
		</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top}
			text={props.label || translate(DICTIONARY.MY_MARINAS.X)}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileMyMarinas