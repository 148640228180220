import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import Accesses from "../../../../globals/classes/shared/Accesses"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {PhysicalAccess} props.physicalAccess
 * @param {DataAccess} props.dataAccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselAccessInputFields = props => {
	const [data, setData] = useState("")
	const [physical, setPhysical] = useState("")
	const [order, setOrder] = useState("")
	const [owner, setOwner] = useState("")
	const [accesses, setAccesses] = useState(new Accesses(props.accesses))
	useEffect(() => {
		setAccesses(new Accesses(props.accesses))
	}, [props.accesses])
	const isOwner = accesses.isOwner
	// console.log(accesses)
	const accInputs = <>
		<div style={{textAlign: "center", marginTop: "1vh"}}>
			<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.ORDERS.X)}</div>
			<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{order}</div>
			<div style={{fontSize: "6vh"}}>
				<MobileFontAwesome id={`${props.id}-oa-place`} icon={ICONS.PAPER_PLANE} label={translate(DICTIONARY.PLACE_ORDER.X)}
					style={{margin: "1vh"}} className={accesses.orders.access.includes("PLACE") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const oa = acc.orders.access
						const oaCanPlace = oa.includes("PLACE")
						oaCanPlace ? acc.orders.access.splice(acc.orders.access.findIndex(item => item === "PLACE"), 1) :
						acc.orders.access.push("PLACE")
						const element = document.getElementById(`${props.id}-oa-place`)
						element.setAttribute("data-a", `${!oaCanPlace}`)
						element.className = !oaCanPlace ? null : "paralian-text-blue"
						setAccesses(acc)
						setOrder(`${translate(DICTIONARY.PLACE_ORDERS.X)}${!oaCanPlace ? "" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
			</div>
		</div>
		<div style={{textAlign: "center", marginTop: "1vh"}}>
			<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.DATA_ACCESS.X)}</div>
			<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{data}</div>
			<div style={{fontSize: "6vh"}}>
				<MobileFontAwesome id={`${props.id}-da-viewDocuments`} icon={ICONS.FILE_PDF}
					label={translate(DICTIONARY.DOCUMENT_VIEW_ACCESS.X)} dataA={accesses.data.fullProfile.includes("VIEW")}
					style={{margin: "1vh"}} className={accesses.data.fullProfile.includes("VIEW") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const fp = acc.data.fullProfile
						const fpCanView = fp.includes("VIEW")
						const lp = acc.data.limitedProfile
						const lpCanView = lp.includes("VIEW")
						fpCanView ? acc.data.fullProfile.splice(acc.data.fullProfile.findIndex(item => item === "VIEW"), 1) :
						acc.data.fullProfile.push("VIEW")
						const element = document.getElementById(`${props.id}-da-viewDocuments`)
						element.setAttribute("data-a", `${!fpCanView}`)
						element.className = !fpCanView ? null : "paralian-text-blue"
						const daProfileElement = document.getElementById(`${props.id}-da-viewProfile`)
						const daProfile = daProfileElement.getAttribute("data-a")
						if (daProfile === "false" && !fpCanView) {
						daProfileElement.click()
						daProfileElement.className = null
						if (!lpCanView) {acc.data.limitedProfile.push("VIEW")}
						}
						setAccesses(acc)
						setData(`${translate(DICTIONARY.DOCUMENT_VIEW_ACCESS.X)}${!fpCanView ?	"" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
				<MobileFontAwesome id={`${props.id}-da-viewProfile`} icon={ICONS.SHIP}
					label={translate(DICTIONARY.PROFILE_VIEW_ACCESS.X)} dataA={accesses.data.limitedProfile.includes("VIEW")}
					style={{margin: "1vh"}} className={accesses.data.limitedProfile.includes("VIEW") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const lp = acc.data.limitedProfile
						const lpCanView = lp.includes("VIEW")
						lpCanView ? acc.data.limitedProfile.splice(acc.data.limitedProfile.findIndex(item => item === "VIEW"), 1) :
						acc.data.limitedProfile.push("VIEW")
						document.getElementById(`${props.id}-da-viewProfile`).setAttribute("data-a", `${!lpCanView}`)
						const daDocumentElement = document.getElementById(`${props.id}-da-viewDocuments`)
						const daDocument = daDocumentElement.getAttribute("data-a")
						if (daDocument === "true" && lpCanView) {
						daDocumentElement.click()
						daDocumentElement.className = "paralian-text-blue"
						// if (!lpCanView) {acc.data.limitedProfile.push("VIEW")}
						}
						setAccesses(acc)
						setData(`${translate(DICTIONARY.PROFILE_VIEW_ACCESS.X)}${!lpCanView ? "" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
			</div>
		</div>
		<div style={{textAlign: "center", marginTop: "1vh"}}>
			<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.PHYSICAL_ACCESS.X)}</div>
			<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{physical}</div>
			<div style={{fontSize: "6vh"}}>
				<MobileFontAwesome id={`${props.id}-pa-moveWithinPremises`} icon={ICONS.FORKLIFT}
					label={translate(DICTIONARY.MOVE_WITHIN_PREMISES.X)} dataA={accesses.physical.access.includes("MOVE")}
					style={{margin: "1vh"}} className={accesses.physical.access.includes("MOVE") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const pa = acc.physical.access
						const paCanMove = pa.includes("MOVE")
						paCanMove ? acc.physical.access.splice(acc.physical.access.findIndex(item => item === "MOVE"), 1) :
						acc.physical.access.push("MOVE")
						document.getElementById(`${props.id}-pa-moveWithinPremises`).setAttribute("data-a", `${!paCanMove}`)
						setAccesses(acc)
						setPhysical(`${translate(DICTIONARY.MOVE_WITHIN_PREMISES.X)}${!paCanMove ? "" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
				<MobileFontAwesome id={`${props.id}-pa-leavePremises`} icon={ICONS.ROUTE}
					label={translate(DICTIONARY.LEAVE_PREMISES.X)} dataA={accesses.physical.access.includes("LEAVE")}
					style={{margin: "1vh"}} className={accesses.physical.access.includes("LEAVE") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const pa = acc.physical.access
						const paCanLeave = pa.includes("LEAVE")
						paCanLeave ? acc.physical.access.splice(acc.physical.access.findIndex(item => item === "LEAVE"), 1) :
						acc.physical.access.push("LEAVE")
						document.getElementById(`${props.id}-pa-leavePremises`).setAttribute("data-a", `${!paCanLeave}`)
						setAccesses(acc)
						setPhysical(`${translate(DICTIONARY.LEAVE_PREMISES.X)}${!paCanLeave ? "" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
				<MobileFontAwesome id={`${props.id}-pa-board`} icon={ICONS.SIGN_IN_ALT}
					label={translate(DICTIONARY.BOARD.X)} dataA={accesses.physical.access.includes("BOARD")}
					style={{margin: "1vh"}} className={accesses.physical.access.includes("BOARD") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const pa = acc.physical.access
						const paCanBoard = pa.includes("BOARD")
						paCanBoard ? acc.physical.access.splice(acc.physical.access.findIndex(item => item === "BOARD"), 1) :
						acc.physical.access.push("BOARD")
						document.getElementById(`${props.id}-pa-board`).setAttribute("data-a", `${!paCanBoard}`)
						setAccesses(acc)
						setPhysical(`${translate(DICTIONARY.BOARD.X)}${!paCanBoard ?	"" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
				<MobileFontAwesome id={`${props.id}-pa-service`} icon={ICONS.WRENCH}
					label={translate(DICTIONARY.SERVICE.X)} dataA={accesses.physical.access.includes("SERVICE")}
					style={{margin: "1vh"}} className={accesses.physical.access.includes("SERVICE") ? null : "paralian-text-blue"}
					onClick={() => {
						const acc = new Accesses(accesses)
						const pa = acc.physical.access
						const paCanService = pa.includes("SERVICE")
						paCanService ? acc.physical.access.splice(acc.physical.access.findIndex(item => item === "SERVICE"), 1) :
						acc.physical.access.push("SERVICE")
						document.getElementById(`${props.id}-pa-service`).setAttribute("data-a", `${!paCanService}`)
						setAccesses(acc)
						setPhysical(`${translate(DICTIONARY.SERVICE.X)}${!paCanService ? "" :
						` - ${translate(DICTIONARY.DENIED.X)}`}`)
					}}/>
			</div>
		</div>
	</>
	return <div>
		<div style={{textAlign: "center", marginTop: "1vh"}}>
			<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.OWNER.X)}</div>
			<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{owner}</div>
			<div style={{fontSize: "6vh"}}>
				<MobileFontAwesome id={`${props.id}-owner`} icon={ICONS.KEY} label={translate(DICTIONARY.OWNER.X)}
				style={{margin: "1vh"}} className={accesses.isOwner ? null : "paralian-text-blue"}
				onClick={() => {
					const acc = accesses
					// const oa = acc.ownership
					// const oaIsOwner = !accesses.isOwner
					// oaIsOwner ? oa.id = null : oa.id = 1
					acc.isOwner = !accesses.isOwner
					const element = document.getElementById(`${props.id}-owner`)
					element.setAttribute("data-a", `${acc.isOwner}`)
					setOwner(acc.isOwner ? translate(DICTIONARY.IS_OWNER.X) : translate(DICTIONARY.IS_NOT_OWNER.X))
					setAccesses(acc)
				}}/>
			</div>
		</div>
		{isOwner || props.toOrganization ? <></> : accInputs}
	</div>
}

export default MobileVesselAccessInputFields