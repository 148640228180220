import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE, PARALIAN_PRODUCT_NAME} from "../../../globals/constants/shared/enumerators"
import {ccBrandToLabelAndIcon, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {activateParalianProducts, updateParalianProductLimit, updateParalianProductPaymentMethod} from "../../../globals/functions/client/serverFunctions"
import {useElements, useStripe} from "@stripe/react-stripe-js"
import submitNewPaymentMethod from "../payment/submitNewPaymentMethod"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import MobilePaymentMethodSelectNew from "../payment/MobilePaymentMethodSelectNew"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import Modal from "../../../globals/components/Modal"
import Address from "../../../globals/classes/shared/Address"
/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {string|number} props.zIndex
 * @param {Object[]} props.paymentMethods
 * @param {function} props.refresh
 * @param {boolean} props.isOrganization
 * @returns {JSX.Element}
 * @constructor
 */
const MobileParalianSubscriptionFieldsToggle = props => {
	const stripe = useStripe()
	const elements = useElements()
	const [error, setError] = useState(null)
	const [cardComplete, setCardComplete] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [billingDetails, setBillingDetails] = useState({name: props.parentObject ? props.isOrganization ?
			props.parentObject.name.display :	props.parentObject.contact.name.given : null})
	const [billingAddress, setBillingAddress] = useState(props.parentObject ?
		props.parentObject.addresses.asArray.length > 0 ?
		props.parentObject.addresses.asArray.find(address => address.isBilling) || new Address({id: -1}) :
			new Address({id: -1}) : new Address({id: -1}))
	const [showActivateModal, setShowActivateModal] = useState(false)
	useEffect(() => {
		try {
			const ok = props.isOrganization ? props.parentObject.name.display : props.parentObject.contact.name.given
			setBillingDetails({name: ok ? ok : null})
		} catch (e) {
			setBillingDetails({name: null})
		}
	}, [props.parentObject])
	useEffect(() => {
		try {
			const ok = props.parentObject.addresses.asArray.length > 0
			setBillingAddress(ok ? props.parentObject.addresses.asArray.find(address => address.isBilling) ||
				new Address({id: -1}) : new Address({id: -1}))
		} catch (e) {
			setBillingAddress(new Address({id: -1}))
		}
	}, [props.parentObject, props.isOrganization])
	const onUpdatePaymentMethod = async event => {
		event.preventDefault()
		try {
			const oldPaymentMethod = subscription.paymentMethod
			const newPaymentMethod = document.getElementById("payment-method-input").getAttribute("data-id")
			let responsePaymentMethod = true
			const updatePaymentMethod = `${oldPaymentMethod}` !== newPaymentMethod
			// console.log(oldPaymentMethod, newPaymentMethod, updatePaymentMethod)
			if (updatePaymentMethod) {
				if (newPaymentMethod === "-1") {
					if (error || !cardComplete) {
						responsePaymentMethod = false
					} else {
						// new payment method, store payment method
						const onSuccess = async (obj, pm_) => await updateParalianProductPaymentMethod(subscription.id, pm_, props.isOrganization)
						const response = await submitNewPaymentMethod(stripe, elements, setProcessing, billingAddress, billingDetails, onSuccess, props.isOrganization)
						responsePaymentMethod = !!response
						// console.log("responsePaymentMethod", responsePaymentMethod)
					}
				} else {
					responsePaymentMethod = await updateParalianProductPaymentMethod(subscription.id, newPaymentMethod, props.isOrganization)
					if (responsePaymentMethod.status !== SERVER_RESPONSE_STATE.SUCCESS) {
						responsePaymentMethod = false
					}
				}
			}
			if (responsePaymentMethod) {
				props.onSuccess()
			}
		} catch (e) {
			console.log(e)
		}
	}
	const onUpdateLimit = async (event, id) => {
		event.preventDefault()
		try {
			const limit = document.getElementById(`limit-${id}-input`).getAttribute("data-a")
			const response = await updateParalianProductLimit(subscription.id, id, limit, props.isOrganization)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess()
			}
		} catch (e) {
			console.log(e)
		}
	}
	const onActivate = async event => {
		event.preventDefault()
		try {
			let paymentMethod = document.getElementById("payment-method-input").getAttribute("data-id")
			if (paymentMethod === "-1" && !error && cardComplete) {
				paymentMethod = await submitNewPaymentMethod(stripe, elements, setProcessing, billingAddress,	billingDetails,
					() => {}, props.isOrganization)
			}
			if (!paymentMethod || paymentMethod === "-1") {return}
			const response = await activateParalianProducts(paymentMethod, props.isOrganization)
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setShowActivateModal(false)
				props.onSuccess()
			}
		} catch (e) {
			console.log(e)
		}
	}
	// if trial, flash red
	const subscription = props.parentObject.subscription
	// console.log(subscription)
	const products = subscription.products
	const mainProducts = subscription.products.filter(v => v.isMainProduct)
	const isTrial = subscription.status === "TRIAL"
	const activate = isTrial ?
		<MobileButtonGeneric id={"close"} type={"button"} left={{icon: ICONS.BELL_ON_SHAKE, style: {paddingRight: "2vh"}}}
			right={{icon: ICONS.BELL_ON_SHAKE, style: {paddingLeft: "0vh"}}}
			text={{style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.ACTIVATE.X)}}
			className={"paralian-theme-level-1-flipped"}
			padding={"1vh"} style={{width: "100%"}} onClick={() => setShowActivateModal(true)}/> : <></>
	/* TODO: specify quantity and limit for each main product */
	const orderIgnoreProduct = products.find(v => v.name === PARALIAN_PRODUCT_NAME.ORDER_IGNORE_PENALTY)
	const ignored = !orderIgnoreProduct ? <></> :
		<div className={"paralian-theme-level-1"} style={{margin: "1vh 0vh", padding: "1vh 0vh", fontSize: "2vh",
			borderRadius: "1vh"}}>
			<i style={{paddingRight: "1vh"}}>{translate(DICTIONARY.ORDERS_IGNORED.X) + ":"}</i>
			<i>{orderIgnoreProduct.quantity.toLocaleString()}</i>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.ORDERS_IGNORED.X)}
				style={{paddingLeft: "1vh"}} onClick={() => newMessage("ignored-message",
				translate(DICTIONARY.ORDERS_IGNORED_BILLING_CYCLE.X),
				translate(DICTIONARY.ORDERS_IGNORED_BILLING_CYCLE_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
		</div>
	const quantitySet = mainProducts.map(v => <div key={`limitSet-${v.stripeId}`} className={"paralian-theme-level-1"}
		style={{margin: "1vh 0vh", padding: "2vh", borderRadius: "1vh"}}>
		<div style={{fontSize: "2.5vh", opacity: 0.7, padding: "1vh"}}>
			{translate(v.levels[subscription.level])}
		</div>
		<MobileInputForm id={`limitSet-${v.stripeId}`} className={" "} disabled={processing} content={
			<MobileInputGeneric type={"long"} id={`limit-${v.stripeId}`} name={"limit"} form={`limitSet-${v.stripeId}`}
				ignoreZero={true}	autoFocus={false} maxLength={"10"} autoComplete={"off"} pattern={`([0-9,]){1,10}`}
				max={v.restrictions.maxUnits || "9".repeat(10)} min={v.free}
				required={false} placeholder={v.limit || v.free} defaultValue={v.limit || v.free} dataA={v.limit || v.free}
				className={"paralian-theme-level-2"} step={v.restrictions.groupSize}
				label={translate(DICTIONARY.LIMIT.X)} showLabel={true}
				right={{icon: ICONS.BAN, onClick: () => {
					const element = document.getElementById(`limit-${v.stripeId}-input`)
						element.value = null
						element.placeholder = translate(DICTIONARY.NO_LIMIT_APPLIED.X)
						element.setAttribute("data-a", "-1")
					}}} onChange={(x, pass) =>
				document.getElementById(`limit-${v.stripeId}-input`).setAttribute("data-a", pass ? x : -1)}/>
		} onSubmit={event => onUpdateLimit(event, v.stripeId)}/>
	</div>)
	const quantityView = mainProducts.map(v => <div key={`quantityView-${v.stripeId}`}
		className={"paralian-theme-level-1"} style={{margin: "1vh 0vh", padding: "1vh 0vh", borderRadius: "1vh"}}>
		<div style={{fontSize: "2.5vh", opacity: 0.7, padding: "1vh"}}>
			{translate(v.levels[subscription.level])}
		</div>
		<div style={{fontSize: "7vh"}}>
			{v.quantity.toLocaleString()}
		</div>
		<div style={{fontSize: "2vh"}}>
			<i>{translate(DICTIONARY.ACTIVE_CUSTOMERS.X)}</i>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.ACTIVE_CUSTOMERS.X)} style={{paddingLeft: "1vh"}}
				onClick={() => newMessage("used-message", translate(DICTIONARY.ACTIVE_CUSTOMERS.X),
				 translate(DICTIONARY.ACTIVE_CUSTOMER_SUBSCRIPTIONS.X) +
				 new Date(props.parentObject.timestamp).toLocaleString(undefined, {
					 day: "numeric",
					 month: "short",
					 year: "numeric",
					 hour: "numeric",
					 minute: "numeric",
					 timeZoneName: "short"
				 }), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
		</div>
		<div style={{fontSize: "2vh", padding: "1vh 0vh"}}>
			<i>{v.limit ? translate(DICTIONARY.LIMIT.X) + ": " + v.limit.toLocaleString() :
				translate(DICTIONARY.NO_LIMIT_APPLIED.X)}</i>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.LIMIT.X)} style={{paddingLeft: "1vh"}}
				onClick={() => newMessage("limit", translate(DICTIONARY.LIMIT.X),
				translate(DICTIONARY.BILLING_CYCLE_LIMIT.X) + " " + translate(DICTIONARY.BILLING_CYCLE_LIMIT_MESSAGE.X),
					"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
		</div>
	</div>)
	const quantity = props.edit && !isTrial ? quantitySet : quantityView
	const paymentMethods = props.parentObject.payment.methods.cards || []
	const card = paymentMethods.find(pm => pm.id === subscription.paymentMethod)
	// console.log(subscription.paymentMethod, paymentMethods, card)
	const date = new Date()
	if (card) {
		date.setUTCMonth(card.exp_month - 1)
		date.setUTCFullYear(card.exp_year)
	}
	const brandDisplay = card ? ccBrandToLabelAndIcon(card.brand) : null
	const cardInput = <MobilePaymentMethodSelectNew parentObject={props.parentObject} form={"paymentMethod-form"}
		defaultPaymentMethod={subscription.paymentMethod} showLabel={true} isOrganization={props.isOrganization}
		className={"paralian-theme-level-2"} style={{padding: "0vh 2vh", fontSize: "2.5vh"}}
		setBillingDetails={setBillingDetails}	setBillingAddress={setBillingAddress}
		setError={setError} setCardComplete={setCardComplete}	setSavePaymentMethod={() => {}}/>
	const activateModal =	showActivateModal ? <Modal id={"activate-modal"} title={translate(DICTIONARY.ACTIVATE.X)}
		body={<MobileInputForm id={"paymentMethod"} className={" "} content={cardInput} onSubmit={onActivate}/>}
			padding={"0vh"} onClick={() => setShowActivateModal(false)}/> : <></>
	const paymentMethod = card ?
		<div style={{display: "flex"}}>
			<MobileFontAwesome icon={brandDisplay.icon} label={brandDisplay.label}
				style={{marginRight: "2vh", marginLeft: "auto", fontSize: "6vh"}}/>
			<div style={{display: "block", textAlign: "left", margin: "auto auto auto 0vw", fontSize: "2vh"}}>
				<div><b>{brandDisplay.label}</b><b>{`* ${card.last4}`}</b></div>
				<div>
					{`${translate(DICTIONARY.EXPIRES.X)} ${date.toLocaleString(undefined, {
						timeZone: "UTC",
						month: "short",
						year: "numeric"
					})}`}
				</div>
			</div>
		</div> : <></>
	const paymentMethod_ = <>
		{props.edit && !isTrial ? cardInput : <div style={{padding: "1vh 0vh"}}>{paymentMethod}</div>}
		{props.edit && !isTrial ? <></> : <div style={{fontSize: "2vh", paddingBottom: "1vh"}}>
			<i>{translate(DICTIONARY.PAYMENT_METHOD.X)}</i>
		</div>}
	</>
	const paymentMethod__ = <div className={"paralian-theme-level-1"} style={{padding: "1vh 0vh", margin: "1vh 0vh"}}>
		{props.edit && !isTrial ?
			<MobileInputForm id={"payment-method"} className={" "} content={paymentMethod_} onSubmit={onUpdatePaymentMethod}/> :
			paymentMethod_}
	</div>
	const autoBilling = isTrial ? <></> :
		<div className={"paralian-theme-level-1"} style={{margin: "1vh 0vh", padding: "1vh 0vh"}}>
			<div style={{fontSize: "3vh", padding: "1vh 0vh", color: subscription.autoBills ? "green" : "red"}}>
				{subscription.autoBills ? translate(DICTIONARY.ENABLED.X) : translate(DICTIONARY.DISABLED.X)}
			</div>
			<div style={{fontSize: "2vh", paddingBottom: "1vh"}}>
				<i>{translate(DICTIONARY.AUTO_RENEW.X)}</i>
				<MobileFontAwesome icon={ICONS.INFO_CIRCLE} label={translate(subscription.type) + " - " +
				translate(DICTIONARY.AUTO_RENEW.X)} style={{paddingLeft: "1vh"}}
					onClick={() => newMessage("autorenew-message", translate(subscription.type) + " - " +
						translate(DICTIONARY.AUTO_RENEW.X),
						subscription.type === PARALIAN_PRODUCT_NAME.PERSONAL_PREMIUM ?
							translate(DICTIONARY.AUTOMATIC_BILLING_MESSAGE_1.X) :
								translate(DICTIONARY.AUTOMATIC_BILLING_MESSAGE_1.X) +
								translate(DICTIONARY.AUTOMATIC_BILLING_MESSAGE_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
			</div>
			<div style={{fontSize: "2vh", paddingBottom: "1vh"}}>
				<i style={{paddingRight: "1vh"}}>
					{(subscription.autoBills ? translate(DICTIONARY.NEXT_BILL.X) : translate(DICTIONARY.EXPIRES.X)) + ":"}
				</i>
				<i>{subscription.end ?
					new Date(subscription.end).toLocaleString(undefined, {
						day: "numeric",
						month: "short",
						year: "numeric"
					}) : ""}
				</i>
			</div>
		</div>
	return <div className={"w3-animate-opacity"} style={{fontSize: "3vh", textAlign: "center"}}>
		{activateModal}
		{isTrial ? activate : <></>}
		{quantity}
		{ignored}
		{autoBilling}
		{subscription.autoBills && !isTrial ? paymentMethod__ : <></>}
	</div>
}

export default MobileParalianSubscriptionFieldsToggle