import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {newMessage, translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "./MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {number} props.zIndex
 * @param {string} props.top - top margin for sticky property
 * @param {string} props.text
 * @param {function} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
const MobileStickyBannerExpandDown = props => {
	// const [expanded, setExpanded] = useState(false)
	return <div className={"stickyBannerExpandDown paralian-theme-level-1-flipped stickyLevel-2 w3-ripple"}
		style={{fontSize: "2vh", padding: "1.5vh",	margin: "1.5vh 0vh", zIndex: props.zIndex, top: props.top,
			textAlign: "center", cursor: "pointer"}}	onClick={() => {
			props.onClick()
			// setExpanded(!expanded)
		}}>
		<div style={{maxWidth: "90%", display:"inline-flex", margin: "0vh auto"}}>
			<div style={{padding: "1vh 0vh"}}>
				{props.premium ? <MobileFontAwesome style={{padding: "1.5vh"}} icon={ICONS.CROWN}
					label={translate(DICTIONARY.PREMIUM.X)} onClick={() => {
					newMessage(`${props.text}-premium-access`, translate(DICTIONARY.PREMIUM_MEMBERSHIP_REQUIRED.X),
						translate(DICTIONARY.PREMIUM_MEMBERSHIP_REQUIRED_MESSAGE.X), "w3-pale-yellow", ICONS.CROWN)
				}}/> : <></>}
				{props.text}
			</div>
			<MobileFontAwesome style={{padding: "1.5vh"}} icon={!props.expanded ? ICONS.ANGLE_DOUBLE_DOWN : ICONS.ANGLE_DOUBLE_UP}
				label={!props.expanded ? translate(DICTIONARY.EXPAND.X) : translate(DICTIONARY.RETRACT.X)}/>
		</div>
	</div>
}

export default MobileStickyBannerExpandDown