import React from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {getVesselsByOwner} from "../../../../globals/functions/client/serverFunctions"
import submitToServer from "../../../../globals/functions/client/submitToServer"
import MobileInputEmail from "../../../shared/input/text/MobileInputEmail"
import MobileInputPhoneField from "../../contact/MobileInputPhoneField"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import Phone from "../../../../globals/classes/shared/Phone"
import Emails from "../../../../globals/classes/shared/Emails"
import Phones from "../../../../globals/classes/shared/Phones"
import Country from "../../../../globals/classes/shared/Country"
import State from "../../../../globals/classes/shared/State"

/**
 *
 * @param {Object} props
 * @param {function} props.setPage
 * @param {function} props.setOwner
 * @param {function} props.setVesselsFound
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselInviteOwnerInput = props => {
	const onSubmit = async event => {
		event.preventDefault()
		const primaryEmail = document.getElementById("primary-email-input").value
		const primaryPhone = new Phone({
			countryCode: document.getElementById("primary-country-code-input").getAttribute("data-a"),
			nationalNumber: document.getElementById("primary-national-number-input").value,
			iso: document.getElementById("primary-country-code-input").getAttribute("data-b")
		})
		const secondaryEmail = document.getElementById("secondary-email-input").value
		const secondaryPhone = new Phone({
			countryCode: document.getElementById("secondary-country-code-input").getAttribute("data-a"),
			nationalNumber: document.getElementById("secondary-national-number-input").value,
			iso: document.getElementById("secondary-country-code-input").getAttribute("data-b")
		})

		const contactInfo = {
			emails: new Emails({primary: {address: primaryEmail}, secondary: {address: secondaryEmail}}),
			phones: new Phones({primary: primaryPhone, secondary: secondaryPhone})
		}
		const onSuccess = (parameters, response) => {
			console.log(response)
			props.setVesselsFound(response.payload)
			props.setOwner({id: null, username: null, email: contactInfo.emails.primary, phone: contactInfo.phones.primary,
				country: new Country(), state: new State()})
			props.setPage("VESSEL_SELECT")
		}
		// props.setPage("VESSEL_SELECT")
		await submitToServer(event, contactInfo, getVesselsByOwner, onSuccess)
	}
	const primaryEmailInput = <MobileInputEmail id={"primary"} form={"owner-form"} autoComplete={"off"} autoFocus={true}
		required={true} placeholder={translate(DICTIONARY.EMAIL.X)} name={"loginEmail"}
		label={translate(DICTIONARY.EMAIL.X)} showLabel={true} className={"paralian-theme-level-1"}
		left={{icon: ICONS.EMAIL, onClick: undefined}} right={{icon: ICONS.INFO_CIRCLE,
		onClick: () => newMessage("email", translate(TEXT_FIELD_PROPERTIES.eMail.hoverTitle),
			translate(TEXT_FIELD_PROPERTIES.eMail.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}}/>
	const secondaryEmailInput = <MobileInputEmail id={"secondary"} form={"owner-form"} autoComplete={"off"}
		required={false} placeholder={translate(DICTIONARY.ALTERNATE_EMAIL.X)} name={"secondaryEmail"} autoFocus={false}
		label={translate(DICTIONARY.ALTERNATE_EMAIL.X)} showLabel={true} className={"paralian-theme-level-1"}
		left={{icon: ICONS.EMAIL, onClick: undefined}} right={{icon: ICONS.INFO_CIRCLE,
		onClick: () => newMessage("email", translate(TEXT_FIELD_PROPERTIES.eMail.hoverTitle),
			translate(TEXT_FIELD_PROPERTIES.eMail.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}}/>
	const primaryPhoneInput = <MobileInputPhoneField id={"primary"} form={"owner-form"} required={true}
		autoComplete={"off"} className={"paralian-theme-level-1"} left={{icon: ICONS.PHONE, onClick: () => {
			newMessage("primary-phone-message", translate(DICTIONARY.PHONE.X), "",
				"w3-pale-blue", ICONS.INFO_CIRCLE)}}}
		right={{icon: "",	onClick: () => {}}} showLabel={true} autoFocus={false}
		countryCode={{placeholder: translate(DICTIONARY.COUNTRY_CODE.X), defaultValue: null,
			label: translate(DICTIONARY.COUNTRY_CODE.X)}} label={translate(DICTIONARY.PHONE_NUMBER.X)}
		nationalNumber={{defaultValue: null, autoFocus: false, label: translate(DICTIONARY.PHONE_NUMBER.X),
			placeholder: translate(DICTIONARY.PHONE_NUMBER.X)}}/>
	const secondaryPhoneInput = <MobileInputPhoneField id={"secondary"} form={"owner-form"} required={false}
		autoComplete={"off"} className={"paralian-theme-level-1"} left={{icon: ICONS.PHONE, onClick: () => {
			newMessage("secondary-phone-message", translate(DICTIONARY.PHONE.X), "",
			"w3-pale-blue", ICONS.INFO_CIRCLE)}}}
		right={{icon: "",	onClick: () => {}}} showLabel={true} autoFocus={false}
		countryCode={{placeholder: translate(DICTIONARY.COUNTRY_CODE.X), defaultValue: null,
			label: translate(DICTIONARY.COUNTRY_CODE.X)}} label={translate(DICTIONARY.ALTERNATE_PHONE_NUMBER.X)}
		nationalNumber={{defaultValue: null, autoFocus: false, label: translate(DICTIONARY.ALTERNATE_PHONE_NUMBER.X),
			placeholder: translate(DICTIONARY.ALTERNATE_PHONE_NUMBER.X)}}/>
	const ownerInput = <>
		<div style={{margin: "1vh 0vh"}}>
			{primaryEmailInput}
		</div>
		<div style={{margin: "1vh 0vh"}}>
			{secondaryEmailInput}
		</div>
		<div style={{margin: "1vh 0vh"}}>
			{primaryPhoneInput}
		</div>
		<div style={{margin: "1vh 0vh"}}>
			{secondaryPhoneInput}
		</div>
	</>
	const content = <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader edit={true} labels={[translate(DICTIONARY.OWNER_CONTACT_INFO.X)]}/>
			{/*subtitle={<span style={{fontSize: "2vh"}}>{"Insert organization contact info if owned by organization."}</span>}/>*/}
		{ownerInput}
	</div>
	return <MobileInputForm id={"owner"} content={content} className={" "} onSubmit={onSubmit} style={{paddingBottom: "2vh"}}/>
}

export default MobileVesselInviteOwnerInput