import Statistics from "./Statistics"
import RatingStatistics from "./RatingStatistics"
import Proportions from "./Proportions"
/* TODO: privatize with properties_ with  when supported */
class OrderMetrics {
	/**
	 *
	 * @param {OrderMetrics} [metrics = null]
	 * @param {Statistics} [metrics.responseTime]
	 * @param {Rating} metrics.rating
	 * @param {Statistics} metrics.responseTime
	 * @param {Proportions} metrics.acceptRate
	 * @param {Proportions} metrics.cancelRate
	 * @param {Proportions} metrics.cancelRate
	 * @param {Proportions} metrics.disputeRate
	 */
	constructor(metrics = null) {
		try {
			this.rating = new RatingStatistics(metrics.rating)
			this.responseTime = new Statistics(metrics.responseTime)
			this.acceptRate = new Proportions(metrics.acceptRate)
			this.providerCancelRate = new Proportions(metrics.providerCancelRate)
			this.customerCancelRate = new Proportions(metrics.customerCancelRate)
			this.disputeRate = new Proportions(metrics.disputeRate)
		} catch (e) {
			this.rating = new RatingStatistics()
			this.responseTime = new Statistics()
			this.acceptRate =  new Proportions()
			this.providerCancelRate = new Proportions()
			this.customerCancelRate = new Proportions()
			this.disputeRate = new Proportions()
		}
	}
}

export default OrderMetrics