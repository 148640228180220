import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getFromStorage, getLanguage, newMessage, setToStorage, translate} from "../../../../globals/functions/client/localFunctions"
import {getTimezone, updateOrgLocation} from "../../../../globals/functions/client/serverFunctions"
import googleMapsSetup from "../../../../globals/functions/client/googleMapsSetup"
import googleMapsCleanup from "../../../../globals/functions/client/mode/googleMapsCleanup"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import Coordinates from "../../../../globals/classes/shared/Coordinates"

/**
 *
 * @param {Object} props
 * @param {Object} props.next - page object
 * @param {Object} props.back - page object
 * @param {boolean} props.message
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginConfirmLocation = props => {
	/* TODO: fix sporadic fail to render map */
	useEffect(() => {
		const coordinates = new Coordinates(getFromStorage("coordinates_", "session"))
		// console.log(coordinates)
		const accurate = getFromStorage("accurate", "session")
		if (props.message) {
			if (accurate) {
				newMessage("coordinates-message", translate(DICTIONARY.LOCATION.X),
					translate(DICTIONARY.COORDINATES_MESSAGE_SUCCESS.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
			} else {
				newMessage("coordinates-message", translate(DICTIONARY.LOCATION.X),
					translate(DICTIONARY.COORDINATES_MESSAGE_FAILURE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
			}
		}
		const initMap =
			`initMap = async () => {
				try {
					let latlng = new google.maps.LatLng(${parseFloat(coordinates.latitude) || 25.8}, ${parseFloat(coordinates.longitude) || -80.1})
					const myOptions = {
						zoom: 19,
						center: latlng,
						mapTypeId: google.maps.MapTypeId.SATELLITE,
						gestureHandling: 'cooperative'
					}
					map = new google.maps.Map(document.getElementById("map"), myOptions)
					const marker = new google.maps.Marker({
						position: latlng, map: map, title: "location", animation: google.maps.Animation.DROP, 
						draggable: true,
						icon: {url: 'https://maps.google.com/mapfiles/ms/micons/red.png',
							size: new google.maps.Size(32, 32), labelOrigin: new google.maps.Point(16, 40),
							anchor: new google.maps.Point(16, 32), scaledSize: new google.maps.Size(32, 32)}
						})
					google.maps.event.addListener(marker, 'dragend', (e) => {
						const newPos = marker.getPosition().toUrlValue(6)
						window.sessionStorage.setItem("coordinates", "["+newPos+"]")
					})
				} catch {
				}
      }`
		const mapScripts = googleMapsSetup(initMap)
		document.getElementById("form").scrollTo(0,0)
		return googleMapsCleanup(mapScripts)
	}, [props.message])
	const onSubmit = async event => {
		event.preventDefault()
		const xy = getFromStorage("coordinates", "session")
		const coordinates = new Coordinates(getFromStorage("coordinates_", "session"))
		if (xy[0] && xy[1]) {
			coordinates.latitude = xy[0]
			coordinates.longitude = xy[1]
		}
		window.sessionStorage.removeItem("coordinates")
		window.sessionStorage.removeItem("coordinates_")
		window.sessionStorage.removeItem("accurate")
		const language = getLanguage() // window.navigator.languages === undefined ? window.navigator.language : window.navigator.languages[0]
		const timezoneResponse = await getTimezone(coordinates, language)
		// console.log(timezoneResponse)
		if (timezoneResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setToStorage("timezone", timezoneResponse.payload, "session")
		}
		if (xy[0] && xy[1]) {
			const locationResponse = await updateOrgLocation(coordinates)
			if (locationResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
				store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			}
		} else {
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
		}
	}
	const content = <div className={"paralian-theme-level-1-flipped"} id={"map"} style={{fontSize: "3vh", height: "46vh"}}/>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginConfirmLocation