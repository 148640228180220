import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputDate from "../../../shared/input/text/MobileInputDate"
import MobileTimeField from "../../calendar/MobileTimeField"
import Time from "../../../../globals/classes/shared/Time"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.legend
 * @param {string} props.defaultDate
 * @param {string} props.minDate
 * @param {string} props.maxDate
 * @param {string} props.defaultDate
 * @param {Time} props.defaultTime
 * @param {boolean} props.use24Hr
 * @param {function} [props.setDate]
 * @param {function} [props.setTime]
 * @return {JSX.Element}
 * @constructor
 */
const DateAndTime = props => {
	return <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh", fontSize: "2.5vh"}} >{props.legend}</legend>
		<div style={{padding: "1vh"}}>
			<MobileInputDate id={`${props.id}-date`} label={translate(DICTIONARY.DATE.X)}
				showLabel={false} className={"paralian-theme-level-2"} onChange={props.setDate}
				autoComplete={"off"} required={true} defaultValue={props.defaultDate}
				min={props.minDate} max={props.maxDate} autoFocus={false} form={"form"}/>
		</div>
		<div style={{padding: "1vh"}}>
			<MobileTimeField id={`${props.id}-time`} form={"form"} className={"paralian-theme-level-2"}
				time={new Time(props.defaultTime)} style={{fontSize: "3vh", height: "6vh"}}
			 	use24Hr={props.use24Hr} setTime={props.setTime}/>
		</div>
	</fieldset>
}

export default DateAndTime