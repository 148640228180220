import React from "react"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"

/**
 *
 * @param {object} props
 * @param {React.HTMLAttributes.style} props.style - style object of container's top level element
 * @param {React.HTMLAttributes.className} props.className - className(s) of container's top level element
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.label
 * @param {boolean} props.showLabel
 * @param {string} props.name
 * @param {boolean} props.autoFocus
 * @param {boolean} props.required
 * @param {boolean} props.topRadius
 * @param {boolean} props.bottomRadius
 * @param {Color} props.color
 * @param {Object} props.left
 * @param {string[]} props.left.icon - FontAwesome icon classname
 * @param {function} props.left.onClick
 * @param {Object} props.right
 * @param {string[]} props.right.icon - FontAwesome icon classname
 * @param {function} props.right.onClick
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputColorPicker = props => {
	const color = props.color ? props.color.asHex : null
	return <MobileInputGeneric type={"color"} id={props.id} name={props.name} label={props.label} showLabel={props.showLabel}
		className={props.className} style={props.style} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} form={props.form} required={props.required} defaultValue={color}
		autoFocus={props.autoFocus} onChange={props.onChange}/>
}

export default MobileInputColorPicker