import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {translate} from "../../globals/functions/client/localFunctions"
import StarRating from "../../globals/components/StarRating"
import MobileFontAwesome from "./MobileFontAwesome"

const MobileBusinessRating = props => {
	const [expand, setExpand] = useState(false)
	const expanded = expand ?
		<div className={"w3-animate-opacity"}>
			<div style={{fontSize: "2vh", padding: "0.5vh"}}>
				<span>{`${props.rating.overall.mean || "-"} ${translate(DICTIONARY.OUT_OF.X)} 5`}</span>
				<span>{` (${props.rating.overall.samples || " -"} ${translate(DICTIONARY.REVIEWS.X)})`}</span>
			</div>
			<div style={{padding: "1vh 0vh"}}>
				<div className={"rating"} style={{fontSize: "2vh", padding: "0.5vh"}}>
					<span>{`${translate(DICTIONARY.QUALITY_RATING.X)}: `}</span>
					<StarRating id={"quality"} rating={props.rating.quality.mean} style={{padding: "0.25vh"}}/>
				</div>
				<div className={"rating"} style={{fontSize: "2vh", padding: "0.5vh"}}>
					<span>{`${translate(DICTIONARY.SERVICE_RATING.X)}: `}</span>
					<StarRating id={"service"} rating={props.rating.service.mean} style={{padding: "0.25vh"}}/>
				</div>
				<div className={"rating"} style={{fontSize: "2vh", padding: "0.5vh"}}>
					<span>{`${translate(DICTIONARY.VALUE_RATING.X)}: `}</span>
					<StarRating id={"value"} rating={props.rating.value.mean} style={{padding: "0.25vh"}}/>
				</div>
			</div>
		</div> : <></>
	return <>
		<div style={props.style || {fontSize: "3vh", margin: "1vh"}}>
			<StarRating rating={props.rating.overall.mean} style={props.padding ? {padding: props.padding} : null}/>
			<MobileFontAwesome icon={expand ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN}
				style={{padding: "0vh 1.5vh"}} onClick={() => setExpand(!expand)}/>
		</div>
		{expanded}
	</>
}

export default MobileBusinessRating