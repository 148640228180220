import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getMarinaStorageFacilities} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDatalist from "../../../shared/input/datalist/MobileInputDatalist"

/**
 *
 * @param props {Object}
 * @param props.setUnits {Object} - function to set the state which holds the list of units
 * @param props.showUnitsDatalist {Object} - function to set the state which controls the display of the units datalist
 * @param props.marinaId {string|number}
 * @param props.onChange {Object} - function
 * @param props.id {string}
 * @param props.className {string}
 * @param props.style {Object}
 * @param props.list {Object[]}
 * @param props.label {string}
 * @param props.showLabel {boolean}
 * @param props.bottomRadius {string}
 * @param props.topRadius {string}
 * @param props.left {Object}
 * @param props.left.icon {string} - FontAwesome icon classname
 * @param props.left.onClick {Object} - function
 * @param props.right {Object}
 * @param props.right.icon {string} - FontAwesome icon classname
 * @param props.right.onClick {Object} - function
 * @param props.name {string}
 * @param props.form {string}
 * @param props.required {boolean}
 * @param props.placeholder {string|number}
 * @param props.defaultValue {string|number}
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputMarinaVSUFacilitiesDatalist = props => {
	const [list, setList] = useState([])
	useEffect(() => {
		if (props.marina && props.marina !== -1) {
			getMarinaStorageFacilities(props.marina).then(response => {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					setList(response.payload.map(unit => {return {code: unit.id, label: unit.name}}))
				}
			}).catch(reason => console.log(reason))
		}
	}, [props.marina])
	const onChange = props.onChange ? props.onChange : () => {}
	return <MobileInputDatalist id={props.id} className={props.className} style={props.style} list={list}
		label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} name={props.name} form={props.form} translate={"no"}
		pattern={null} autoFocus={false} maxLength={"120"} required={props.required} placeholder={props.placeholder}
		defaultValue={props.defaultValue} autoComplete={"off"} dataId={"-1"} dataA={"text"} onChange={() => {
		try {
			const field = document.getElementById(`${props.id}-input`)
			const index = list.findIndex(item => item.label === field.value)
			if (index !== -1) {
				const option = document.getElementById(`${props.id}-${field.value}`)
				field.setAttribute("data-id", option.getAttribute("data-a"))
				field.setAttribute("data-a", "id")
				onChange(Number(option.getAttribute("data-a")))
			} else {
				field.setAttribute("data-id", "-1")
				field.setAttribute("data-a", "text")
			}
		} catch (e) {
			console.log(e)
		}
	}}/>
}

export default MobileInputMarinaVSUFacilitiesDatalist