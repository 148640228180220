import { isObject, typeOrNull } from '../../functions/shared/local';

class Image {
	/**
	 *
	 * @param {Image} [image = null]
	 * @param {string} [image.path]
	 * @param {string} [image.file]
	 * @param {boolean} [image.verified]
	 */
	constructor (image = null) {
		if (isObject(image)) {
			this.path = typeOrNull(image.path, "string")
			this.file = typeOrNull(image.file, "string")
			this.verified = typeOrNull(image.verified, "boolean")
		} else {
			this.path = null
			this.file = null
			this.verified = null
		}
	}
}

export default Image;