import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {Icon} props.right
 * @param {function} props.onChange
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAccessLevelDropdown = props => {
	const list = [
		{label: translate(DICTIONARY.ADMIN.X), value: "admin"},
		{label: translate(DICTIONARY.EMPLOYEE.X), value: "employee"}
	]
	return <MobileInputGeneric type={"dropdown"} id={props.id} className={props.className || "paralian-theme-level-1-flipped"}
		label={translate(DICTIONARY.ACCESS_LEVEL.X)} multiple={false} dataId={"employee"} showLabel={true} size={"1"}
		name={"date"} form={props.form} autoFocus={false} required={true} defaultValue={props.defaultValue || "employee"}
		left={{icon: ICONS.KEY}} list={list} onChange={props.onChange}/>
}

export default MobileInputAccessLevelDropdown