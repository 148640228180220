import { TARGET_TYPE } from '../../constants/shared/enumerators'
import ItemId from './textInputFilters/ItemId'
import Filename from './textInputFilters/Filename'

class Target {
	/**
	 *
	 * @param {Target} [target = null]
	 * @param {number} [target.account] - account id
	 * @param {number} [target.organization] - organization id
	 * @param {number} [target.vessel] - vessel id
	 * @param {number} [target.document] - document id
	 * @param {number} [target.address] - address id
	 * @param {number} [target.image] - image filename
	 */
	constructor (target = null) {
		try {
			this.account_ = (target.account || target.account_) === -1 ? -1 : new ItemId(target.account || target.account_).number
			this.organization_ = (target.organization || target.organization_) === -1 ? -1 : new ItemId(target.organization || target.organization_).number
			this.vessel_ = new ItemId(target.vessel || target.vessel_).number
			this.document_ = new ItemId(target.document || target.document_).number
			this.address_ = new ItemId(target.address || target.address_).number
			this.image_ = new Filename(target.image || target.image_).conforming
			this.order_ = new ItemId(target.order || target.order_).number
		} catch (e) {
			this.account_ = null
			this.organization_ = null
			this.vessel_ = null
			this.document_ = null
			this.address_ = null
			this.image_ = null
			this.order_ = null
		}
	}

	get account() {
		return this.account_
	}

	set account(account) {
		this.account_ = account === -1 ? -1 : new ItemId(account).number
	}

	get organization() {
		return this.organization_
	}

	set organization(organization) {
		this.organization_ = organization === -1 ? -1 : new ItemId(organization).number
	}

	get vessel() {
		return this.vessel_
	}

	set vessel(vessel) {
		this.vessel_ = new ItemId(vessel).number
	}

	get document() {
		return this.document_
	}

	set document(document) {
		this.document_ = new ItemId(document).number
	}

	get address() {
		return this.address_
	}

	set address(address) {
		this.address_ = new ItemId(address).number
	}

	get image() {
		return this.image_
	}

	set image(image) {
		this.image_ = new Filename(image).conforming
	}

	get order() {
		return this.order_
	}

	set order(order) {
		this.order_ = new ItemId(order).number
	}

	get hasTarget() {
		return !!(this.account || this.vessel || this.document || this.image || this.organization || this.address)
	}

	get target() {
		let target = {type: TARGET_TYPE.NONE, id: null}
		// account
		if (this.account > 0) {
			return this.vessel > 0 ? {type: TARGET_TYPE.ACCOUNT_VESSEL, id: this.vessel} :
				this.document > 0 ? {type: TARGET_TYPE.ACCOUNT_DOCUMENT, id: this.document} :
					this.address > 0 ? {type: TARGET_TYPE.ACCOUNT_ADDRESS, id: this.address} :
						this.image > 0 ? {type: TARGET_TYPE.ACCOUNT_IMAGE, id: this.image} :
							{type: TARGET_TYPE.ACCOUNT, id: this.account}
		} else if (this.account === -1) {
			return this.vessel > 0 ? {type: TARGET_TYPE.SOURCE_ACCOUNT_VESSEL, id: this.vessel} :
				this.document > 0 ? {type: TARGET_TYPE.SOURCE_ACCOUNT_DOCUMENT, id: this.document} :
					this.address > 0 ? {type: TARGET_TYPE.SOURCE_ACCOUNT_ADDRESS, id: this.address} :
						this.image > 0 ? {type: TARGET_TYPE.SOURCE_ACCOUNT_IMAGE, id: this.image} : target
		}
		// organization
		if (this.organization > 0) {
			return this.vessel > 0 ? {type: TARGET_TYPE.ORGANIZATION_VESSEL, id: this.vessel} :
				this.document > 0 ? {type: TARGET_TYPE.ORGANIZATION_DOCUMENT, id: this.document} :
					this.address > 0 ? {type: TARGET_TYPE.ORGANIZATION_ADDRESS, id: this.address} :
						this.image > 0 ? {type: TARGET_TYPE.ORGANIZATION_IMAGE, id: this.image} :
							{type: TARGET_TYPE.ORGANIZATION, id: this.organization}
		} else if (this.organization === -1) {
			return this.vessel > 0 ? {type: TARGET_TYPE.SOURCE_ORGANIZATION_VESSEL, id: this.vessel} :
				this.document > 0 ? {type: TARGET_TYPE.SOURCE_ORGANIZATION_DOCUMENT, id: this.document} :
					this.address > 0 ? {type: TARGET_TYPE.SOURCE_ORGANIZATION_ADDRESS, id: this.address} :
						this.image > 0 ? {type: TARGET_TYPE.SOURCE_ORGANIZATION_IMAGE, id: this.image} :
							{type: TARGET_TYPE.ORGANIZATION, id: this.organization}
		}
		// vessel
		if (this.vessel > 0) {
			return this.document > 0 ? {type: TARGET_TYPE.VESSEL_DOCUMENT, id: this.document} :
				this.image > 0 ? {type: TARGET_TYPE.VESSEL_IMAGE, id: this.image} :
					{type: TARGET_TYPE.VESSEL, id: this.vessel}
		}
		// document, address, image
		return this.document > 0 ? {type: TARGET_TYPE.DOCUMENT, id: this.document} :
			this.address > 0 ? {type: TARGET_TYPE.ADDRESS, id: this.address} :
				this.image > 0 ? {type: TARGET_TYPE.IMAGE, id: this.image} : target
	}
}

export default Target;