import TextInputFilter from './TextInputFilter';
/**
 *
 * @param {string} state
 * @returns {Promise<Object[]>}
 */
class ISO3 extends TextInputFilter {
	minLength = 3
	maxLength = 3
	regexp = /[^A-Z]/gi
}

export default ISO3;