import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {manageCrash, translate} from "../../../globals/functions/client/localFunctions"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileAddressToggle from "./MobileAddressToggle"
import MobileInputAddressSave from "./MobileInputAddressSave"
import Modal from "../../../globals/components/Modal"
import Address from "../../../globals/classes/shared/Address"
/**
 *
 * @param {Object} props
 * @param {Address[]} props.addresses
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @param {boolean} props.isOrganization
 * @param {boolean} props.showAddBanner
 * @param {boolean} props.allowDelete
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileAddressList = props => {
  const [expanded, setExpanded] = useState(false)
  const [addresses, setAddresses] = useState(props.addresses)
  const [add, setAdd] = useState(false) // use this to control toggles using edit button
  useEffect(() => {setAddresses(props.addresses)}, [props.addresses])
  let state = null, country = null
  try {
    country = addresses[0].country
    state = addresses[0].state
  } catch (e) {
    console.log(e)
  }
  try {
    const insert = add ? <Modal id={"address-save-modal"} icon={ICONS.MAP_PIN} onClick={() => setAdd(false)}
      padding={"0vh"} title={translate(DICTIONARY.ADD_ADDRESS.X)} body={
      <MobileInputAddressSave id={"address-save"} form={"form"} showLabel={false}
        hasLabel={true} autoSave={true} showTypes={true} autoComplete={true} showLine3={true}
        isOrganization={props.isOrganization} address={new Address({country: country, state: state})}
        style={null} className={"paralian-theme-level-1"} onUndo={() => setAdd(false)} onSubmit={() => {
      props.refresh().then()
      // setAddresses(addresses_)
      setAdd(false)}}/>
    }/> : <></>
    const banner = props.canEdit ?
      <MobileAddNewBanner className={`linkedAccounts`} text={translate(DICTIONARY.ADD_ADDRESS.X)}
        onClick={() => {add ? setAdd(false) : setAdd(true)}}/> : <></>
    const addressesLength = addresses ? addresses.length : 0
    const content = addressesLength > 0 ?
      addresses.map(address => <MobileAddressToggle key={`${address.id}-${address.line1}-address-key`} address={address}
        form={"address-edit-form"} showLabel={false} setAddresses={setAddresses} isOrganization={props.isOrganization}
        className={"paralian-theme-level-2"} allowDelete={props.allowDelete} refresh={props.refresh} canEdit={props.canEdit}/>) : <></>
    const content_ = !expanded ? <></> : <div className="w3-animate-top">{content}{insert}{banner}</div>
    return <>
      <MobileStickyBannerExpandDown expanded={expanded} id={"addresses"} zIndex={props.zIndex} text={translate(DICTIONARY.ADDRESSES.X)}
        top={props.top} onClick={() => setExpanded(!expanded)}/>
      {content_}
    </>
  } catch (e) {
    console.log("------------START--------------")
    console.log("MobileAddressList" || "COMPONENT", e)
    console.log("------------END----------------")
    manageCrash(e, "MobileAddressList")
  }
}

export default MobileAddressList