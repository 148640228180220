import { isObject, typeOrNull } from '../../functions/shared/local';

class Name {
	/**
	 *
	 * @param {Name} [name = null]
	 * @param {string} [name.given]
	 * @param {string} [name.legal]
	 * @param {string} [name.display]
	 * @param {boolean} [name.verified]
	 */
	constructor (name = null) {
		if (isObject(name)) {
			this.given = typeOrNull(name.given, "string")
			this.legal = typeOrNull(name.legal, "string")
			this.display = typeOrNull(name.display, "string")
			this.verified = typeOrNull(name.verified, "boolean")
		} else {
			this.given = null
			this.legal = null
			this.display = null
			this.verified = null
		}
	}
}

export default Name;