import React from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputActiveStateButton from "../../shared/input/buttons/MobileInputActiveStateButton"
import MobileInputName from "../../shared/input/text/MobileInputName"
import MobileInputEmail from "../../shared/input/text/MobileInputEmail"
import MobileInputJobTitle from "./MobileInputJobTitle"

/**
 *
 * @param {Object} props
 * @param {Employee} props.admin
 * @param {string} props.name
 * @param {string} props.login - account's login email address
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {boolean} props.showEmail
 * @param {boolean} props.showLabel
 * @param {boolean} props.showActive
 * @param {boolean} props.showName
 * @param {boolean} props.state
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdminInput = props => {
	const id = props.admin ? props.admin.id || props.id : props.id
	const title = props.admin ? props.admin.title : null
	const status = props.admin ? props.admin.status : null
	const email = <MobileInputEmail id={`${id}-employee`} form={`${id}-form`} autoComplete={"off"} autoFocus={false}
		required={true} placeholder={translate(DICTIONARY.EMAIL.X)} name={"loginEmail"}
		label={translate(DICTIONARY.EMAIL.X)} showLabel={props.showLabel} className={"paralian-theme-level-1"}
		left={{icon: ICONS.EMAIL, onClick: undefined}} right={{icon: ICONS.INFO_CIRCLE,
			onClick: () => newMessage("login", translate(TEXT_FIELD_PROPERTIES.eMail.hoverTitle),
			translate(TEXT_FIELD_PROPERTIES.eMail.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}}/>
	return <>
		{props.showName ? <div style={{margin: "1vh 0vh"}}>
			<MobileInputName id={`${id}-employee`} form={`${id}-form`} left={null} right={null} required={true}
				defaultValue={props.name}	autoComplete={false} className={props.className} showLabel={props.showLabel}/>
		</div> : <></>}
		<div style={{marginBottom: "1vh", marginTop: props.showName ? "0vh" : "2vh"}}>
			<MobileInputJobTitle id={`${id}-employee`} form={`${id}-form`} autoFocus={false} showLabel={true}
				className={"paralian-theme-level-1"} defaultValue={title} required={true}/>
		</div>
		<input type={"text"} id={`${id}-account-access-input`} style={{display: "none"}} data-a={"admin"}/>
		{props.showEmail ? email : <></>}
		{props.showActive ? <MobileInputActiveStateButton id={id} style={props.style} className={props.className}
			state={status}/> : <></>}
		</>
}

export default MobileAdminInput