import React from "react"
import {ICONS} from "../../globals/constants/client/constants"
import MobileFontAwesome from "./MobileFontAwesome"
import MobileImage from "./account/MobileImage"

/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {Image} props.image
 * @param {string} props.placeholder - placeholder url
 * @param {Object} props.upload
 * @param {boolean} props.hide
 * @param {boolean} props.upload.include
 * @param {function} props.upload.onClick
 * @returns {JSX.Element}
 */
const MobileProfileImage = props => {
	const uploadButton = props.upload.include ?
		<MobileFontAwesome icon={ICONS.UPLOAD} className={"w3-center"}
			style={{marginTop: props.upload.margin || "5vh", marginLeft: "-17.375vh", fontSize: "10vh",
				opacity: props.image ? "0" : "100", minHeight: "10vh", minWidth: "10vh"}} onClick={props.upload.onClick}/> : <></>
	return <div className={props.className} style={{...props.style, display: "inline-flex", margin: "1.5vh auto 0vh auto",
			minHeight: "24.75vh", minWidth: "24.75vh"}}>
		<MobileImage image={props.image} size={"22.5vh"} alt={"profile"} style={{}} placeholder={props.placeholder}
			id={props.id} showUpload={props.upload.include}/>
		{uploadButton}
		{/*profileImageAlert*/}
	</div>
}

export default MobileProfileImage