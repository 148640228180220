import {
	BROKER_SUBSCRIPTION_LEVEL,
	MARINA_SUBSCRIPTION_LEVEL,
	PARALIAN_PRODUCT_NAME, PERSONAL_SUBSCRIPTION_LEVEL, PROVIDER_SUBSCRIPTION_LEVEL,
	SUBSCRIPTION_FREQUENCY, SUBSCRIPTION_INTERVAL, SUBSCRIPTION_NAME,
	SUBSCRIPTION_STATUS,
	SUBSCRIPTION_TYPE
} from "../../../constants/shared/enumerators"
import {ICONS} from "../../../constants/client/constants"
import {typeOrNull, enumOrNull, constructArray, enumArray} from "../../../functions/shared/local"
import ancreBlue from "../../../../images/ancre_logo_circle_gold.svg"
import ancreGold from "../../../../images/ancre_logo_circle_blue.svg"
import ancreGoldRedDot from "../../../../images/ancre_logo_circle_gold_red_dot.svg"
import Product from "./Product"
/* TODO: privatize with properties_ with  when supported */

class Subscription {
	/**
	 *
	 * @param {Subscription} [subscription = null]
	 * @param {number} [subscription.id]
	 * @param {string} [subscription.name]
	 * @param {string} [subscription.customer]
	 * @param {string} [subscription.type] - SUBSCRIPTION_TYPE
	 * @param {number} [subscription.level]
	 * @param {string[]} [subscription.levels]
	 * @param {string} [subscription.status] - SUBSCRIPTION_STATUS
	 * @param {string} [subscription.subscription] - subscription id
	 * @param {string} [subscription.paymentMethod] - paymentMethod id
	 * @param {Date|string|number} [subscription.start]
	 * @param {Date|string|number} [subscription.end]
	 * @param {Date|string|number} [subscription.activated]
	 * @param {Date|string|number} [subscription.expires]
	 * @param {number} [subscription.balance]
	 * @param {boolean} [subscription.autoRenews]
	 * @param {boolean} [subscription.autoBills]
	 * @param {string} [subscription.interval] - SUBSCRIPTION_FREQUENCY
	 * @param {string} [subscription.frequency] - SUBSCRIPTION_FREQUENCY
	 * @param {Date|string|number} [subscription.created]
	 * @param {Date|string|number} [subscription.updated]
	 * @param {Product[]} [subscription.products]
	 * @param {boolean} [subscription.isMetered]
	 */
	constructor (subscription = null) {
		try {
			this.id = typeOrNull(subscription.id, "number")
			this.name = enumOrNull(subscription.name, SUBSCRIPTION_NAME)
			this.type = enumOrNull(subscription.type, SUBSCRIPTION_TYPE)
			this.level = typeOrNull(subscription.level, "number")
			this.levels = enumArray(subscription.levels, {...PROVIDER_SUBSCRIPTION_LEVEL,
				...MARINA_SUBSCRIPTION_LEVEL, ...PERSONAL_SUBSCRIPTION_LEVEL, ...BROKER_SUBSCRIPTION_LEVEL})
			this.status = enumOrNull(subscription.status, {...SUBSCRIPTION_STATUS, TRIAL: "TRIAL"})
			this.subscription = typeOrNull(subscription.subscription, "string")
			this.paymentMethod = typeOrNull(subscription.paymentMethod, "string")
			this.start = typeOrNull(subscription.start, "date")
			this.end = typeOrNull(subscription.end, "date")
			this.activated = typeOrNull(subscription.activated, "date")
			this.expires = typeOrNull(subscription.expires, "date")
			this.autoRenews = typeOrNull(subscription.autoRenews, "boolean")
			this.autoBills = typeOrNull(subscription.autoRenews, "boolean")
			this.balance = typeOrNull(subscription.balance, "number")
			this.interval = enumOrNull(subscription.interval, SUBSCRIPTION_INTERVAL)
			this.frequency = enumOrNull(subscription.frequency, SUBSCRIPTION_FREQUENCY)
			this.created = typeOrNull(subscription.created, "date")
			this.updated = typeOrNull(subscription.updated, "date")
			this.products = constructArray(subscription.products, v => new Product(v))
			this.isMetered = typeOrNull(subscription.isMetered, "boolean")
		} catch (e) {
			this.id = null
			this.name = null
			this.type = null
			this.level = null
			this.levels = []
			this.status = null
			this.subscription = null
			this.paymentMethod = null
			this.start = null
			this.end = null
			this.activated = null
			this.expires = null
			this.autoRenews = null
			this.autoBills = null
			this.balance = null
			this.interval = null
			this.frequency = null
			this.created = null
			this.updated = null
			this.products = []
			this.isMetered = null
		}
	}

	get icon() {
		switch (this.type) {
			case SUBSCRIPTION_TYPE.MARINA:
				return ICONS.ID_BADGE
			case SUBSCRIPTION_TYPE.BUSINESS:
				return ICONS.USERS
			case SUBSCRIPTION_TYPE.PERSONAL:
				return ICONS.CROWN
			default:
				return ICONS.STAR
		}
	}

	get image() {
		switch (this.type) {
			case PARALIAN_PRODUCT_NAME.MARINA:
				return ancreBlue
			case PARALIAN_PRODUCT_NAME.BUSINESS:
				return ancreGold
			case PARALIAN_PRODUCT_NAME.PERSONAL:
				return ancreGoldRedDot
			default:
				return ancreBlue
		}
	}
}

export default Subscription