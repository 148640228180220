import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES, PAGES} from "../../../../globals/constants/client/constants"
import {sendConciergeServiceRequest} from "../../../../globals/functions/client/serverFunctions"
import {getFromStorage, newMessage, replaceInStorage,	translate} from "../../../../globals/functions/client/localFunctions"
import submitToServer from "../../../../globals/functions/client/submitToServer"
import Success from "../../vessels/Success"
import VesselSelect from "../cleaning/VesselSelect"
import Message from "../cleaning/Message"
import MarinaSelect from "../cleaning/MarinaSelect"
import ConciergeForm from "./ConciergeForm"
import ConciergeContact from "./ConciergeContact"

const ConciergeHome = props => {
	/* TODO: translate */
	useEffect(() => {
		/* TODO: have each of these generate sender & paralian codes and send those codes to me along with the sender's account number */
		newMessage("concierge-in-a-hurry",
			translate(DICTIONARY.CONCIERGE_SERVICE.X), translate(DICTIONARY.CONCIERGE_IN_A_HURRY.X), "w3-pale-blue",
			ICONS.INFO_CIRCLE,
			[
				{id: "concierge-call", icon: null, text: translate(DICTIONARY.CALL_VERB.X),
					onClick: () => {
						window.open("tel:+17866282792", "_blank").focus()
					}},
				{id: "concierge-text", icon: null, text: translate(DICTIONARY.MESSAGE_VERB.X),
					onClick: () => {
						window.open("https://wa.me/17865982314", "_blank").focus()
					}}
		])
	}, [props.account])
	const onSuccess = () => store.dispatch({type: actions.SET_CURRENT_WINDOW,
		values: {window: PAGE_STATES[levelOne].MAIN.REQUESTS.CONCIERGE.SUCCESS.X}})
	const currentState = store.getState()
	const levelOne = currentState.window.current.levelOne
	const parentPage = PAGE_STATES[levelOne].MAIN.REQUESTS
	const isOrganization = props.account.isAdminOrEmployee
	const session = getFromStorage("order", "session")
	const [disabled, setDisabled] = useState(false)
	// console.log("session", session)
	let content, back, next, onSubmit, onSubmitPromise
	let onSubmit_ = event => {
		try {
			event.preventDefault()
			if (event.target.id === "form") {
				if (onSubmitPromise) {onSubmitPromise().then(() =>
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})).catch(e => console.log(e))
				} else {
					if (onSubmit) {onSubmit()}
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	/* TODO: vessel location (checkmark for if in water) */
	switch (currentState.window.current.levelFive) {
		case PAGES.COMMON.MAIN.REQUESTS.CONCIERGE.SUCCESS.X:
			content = <Success label={translate(DICTIONARY.REQUEST_SENT_EXCLAMATION.X)}/>
			onSubmit_ = null
			break
		case PAGES.COMMON.MAIN.REQUESTS.CONCIERGE.VESSEL.X:
			content = <VesselSelect account={props.account} vessels={props.vessels} refresh={props.refreshVessels}
				setDisabled={setDisabled}/>
			back = parentPage.CONCIERGE.MARINA.X
			next = parentPage.CONCIERGE.CONTACT.X
			break
		case PAGES.COMMON.MAIN.REQUESTS.CONCIERGE.MESSAGE.X:
			content = <Message id={"vessel-cleaning"} legend={translate(DICTIONARY.REQUEST.X)}
				placeholder={translate(DICTIONARY.REQUEST_MESSAGE.X)}
				onChange={() => {
					replaceInStorage("order", "message",
						document.getElementById("vessel-cleaning-message").value, "session")
				}}/>
			back = parentPage.CONCIERGE.CONTACT.X
			next = parentPage.CONCIERGE.SUCCESS.X
			onSubmit_ = async event => {
				await submitToServer(event, session, sendConciergeServiceRequest, onSuccess)
			}
			break
		case PAGES.COMMON.MAIN.REQUESTS.CONCIERGE.CONTACT.X:
			content = <ConciergeContact account={props.account} refreshAccount={props.refreshAccount} setDisabled={setDisabled}/>
			back = parentPage.CONCIERGE.VESSEL.X
			next = parentPage.CONCIERGE.MESSAGE.X
			break
		default:
			content = <MarinaSelect account={props.account} marinas={props.marinas} refresh={props.refreshMarinas}
				setDisabled={setDisabled}/>
			back = PAGE_STATES[levelOne].MAIN.REQUESTS.X
			next = parentPage.CONCIERGE.VESSEL.X
	}
	return <ConciergeForm content={content} back={back} isOrganization={isOrganization} onSubmit={onSubmit_}
		disabled={disabled}/>
}

export default ConciergeHome