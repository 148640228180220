import React, {useState} from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputEmail from "../../shared/input/text/MobileInputEmail"
import MobileServiceAccess from "../service/MobileServiceAccess"
import MobileInputName from "../../shared/input/text/MobileInputName"
import MobileInputAccessLevelDropdown from "./MobileInputAccessLevelDropdown"
import MobileInputJobTitle from "./MobileInputJobTitle"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string} props.className
 * @param {Object[]} props.services
 * @param {number[]} props.employees
 * @param {number} props.employee
 * @param {string} props.sticky
 * @param {boolean} props.showAccessLevel
 * @param {function} props.setEmployees
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputNewEmployee = props => {
	const [access, setAccess] = useState("employee")
	const className = props.className || "paralian-theme-level-1-flipped"
	return <>
		<div className={props.sticky ? "sticky" : ""} style={{width: "100%", padding: "1vh 0vh", zIndex: props.sticky}}>
			<MobileInputName id={`${props.id}-employee`} form={"form"} required={true} left={{icon: ICONS.TRASH, onClick: () => {
				const arr = [...props.employees]
				const index = arr.findIndex(item => item === props.id)
				arr.splice(index, 1)
				props.setEmployees([...arr])
			}}}
				right={null} defaultValue={null} autoComplete={false} className={className} showLabel={true}/>
		</div>
		<MobileInputJobTitle id={`${props.id}-employee`} className={className} showLabel={true} autoFocus={true}/>
		<div style={{marginTop: "1vh"}}>
			<MobileInputEmail id={`${props.id}-employee`} form={"form"} autoComplete={"off"} autoFocus={false}
				required={true} placeholder={translate(DICTIONARY.EMAIL.X)} name={"loginEmail"}
				label={translate(DICTIONARY.EMAIL.X)} showLabel={true} className={className}
				left={{icon: ICONS.EMAIL, onClick: undefined}} style={{marginBottom: "1vh"}}
				right={{icon: ICONS.INFO_CIRCLE, onClick: () => newMessage("login",
				translate(TEXT_FIELD_PROPERTIES.eMail.hoverTitle), translate(TEXT_FIELD_PROPERTIES.eMail.hoverMessage),
				"w3-pale-blue", ICONS.INFO_CIRCLE)}}/>
		</div>
		{props.showAccessLevel ? <MobileInputAccessLevelDropdown id={`${props.id}-account-access`} form={"form"}
			className={className} defaultValue={"employee"} right={null} style={{marginBottom: "1vh"}}
			onChange={() => {
				const element = document.getElementById(`${props.id}-account-access-input`)
				element.setAttribute("data-id", element.value)
				setAccess(element.value)
			}}/> : <></>}
		{access === "admin" ? <></> : props.services.map(service => {
			return <MobileServiceAccess key={`${props.id}-employee-service-${service.id}-access`}
				id={`${props.id}-employee-service-${service.id}-access`} service={service}
				className={"paralian-theme-level-1"}/>
			})}
	</>
}

export default MobileInputNewEmployee