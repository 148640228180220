import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileVesselAccessCard from "./MobileVesselAccessCard"
import MobileAddNewBanner from "../../MobileAddNewBanner"
import MobileVesselAccessAdd from "./MobileVesselAccessAdd"
import Modal from "../../../../globals/components/Modal"

const MobileVesselAccessOrganization = props => {
	const [expanded, setExpanded] = useState(false)
	const [add, setAdd] = useState(false)
	useEffect(() => {
		if (add) {
			newMessage("organization-access-note", translate(DICTIONARY.ORGANIZATION_ACCESS.X),
				translate(DICTIONARY.ORGANIZATION_VESSEL_ACCESS_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
		}
	}, [add])

	const organizationsContent = props.organizations.map(organization =>
		<MobileVesselAccessCard id={props.id} key={`access-${organization.id}-${organization.username}}`}
			accesses={organization} account={props.account} onEdit={props.onEdit} canEdit={false}/>)
	return <>
		<div className={"paralian-theme-level-2 w3-ripple w3-center"} style={{padding: "2vh", margin: "2vh",
			cursor: "pointer", borderRadius: "1vh"}} onClick={() => setExpanded(!expanded)}>
			{translate(DICTIONARY.ORGANIZATIONS.X)}
			<MobileFontAwesome icon={expanded ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN} style={{padding: "1.5vh"}}/>
		</div>
		{!expanded ? <></> :<div className={"w3-animate-top w3-animate-opacity"}>
			{organizationsContent}
			{add ? <Modal id={"grant-access-organization-modal"} icon={ICONS.KEY} title={translate(DICTIONARY.GRANT_ACCESS.X)}
				onClick={() => setAdd(false)} padding={"0vh"} body={
				<MobileVesselAccessAdd id={props.id} accesses={props.accountAccess} account={props.account}
					onAdd={() => {props.onAdd(); setAdd(false)}} toOrganization={true}/>
			}/> : <></>}
			{props.canEdit ? <MobileAddNewBanner text={translate(DICTIONARY.GRANT_ACCESS.X)} onClick={() => setAdd(!add)}/> : <></>}
		</div>}
	</>
}

export default MobileVesselAccessOrganization