import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileVesselStorageFacilities from "./MobileVesselStorageFacilities"
import MobilePhysicalFacilitiesAndAmenities from "./MobilePhysicalFacilitiesAndAmenities"

const MobileMarinaFacilities = props => {
	/* TODO: edit functionality with warning when data doesn't match kml */
	/* TODO: allow addition of specific facilities and units with later access section */
	const [expanded, setExpanded] = useState(false)
	const content = !expanded ? <></> : <div className={"w3-animate-opacity w3-animate-top"}>
		<MobileVesselStorageFacilities vesselStorageFacilities={props.vesselStorageFacilities} canEdit={props.canEdit}
			refresh={props.refresh} units={props.units}/>
		<MobilePhysicalFacilitiesAndAmenities physicalFacilities={props.physicalFacilities} canEdit={props.canEdit}
			refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.FACILITIES_AND_AMENITIES.X)}
			top={props.top} onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileMarinaFacilities