import React from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {handleCanvas, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
/* TODO: merge content with ImageUpload */
const MobileCanvas = () => {
	return <>
		<input type={"file"} id={"file"} name={"file"} accept={"image/png, image/jpeg"} style={{display: "none"}} onChange={() => {
			document.getElementById("image").src = URL.createObjectURL(document.getElementById("file").files[0])
		}}/>
		<div className={"w3-display-container"} style={{maxHeight: "40vmax", maxWidth: "40vmax", margin: "auto", height: "85vw",
			width: "85vw"}}>
			<MobileFontAwesome icon={ICONS.UPLOAD} label={translate(DICTIONARY.UPLOAD.X)}
				className={`w3-display-middle`} style={{fontSize: "10vh", padding: "1.5vh", display: "block", zIndex: "11"}}
				onClick={() => {document.getElementById("file").click()}}/>
			<canvas id={"canvas"} className={"w3-display-middle"} width={"300"} height={"300"}
				style={{height: "85vw", width: "85vw", maxWidth: "40vmax", maxHeight: "40vmax",
					borderRadius: "50%", overflow: "hidden", background: "white"}}>
				<img id={"image"} src={''} alt={"canvas"} // crossOrigin="anonymous"
					onLoad={() => {handleCanvas("mobileParentContainer", "image", "canvas")}}/>
			</canvas>
		</div>
	</>
}

export default MobileCanvas