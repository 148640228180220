import React from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileInputFormWithHeader from "../../../shared/input/forms/MobileInputFormWithHeader"

const StorageReservationsForm = props => {
	const header = <div className={"paralian-theme-level-1-flipped w3-display-topmiddle"}
		style={{fontSize: "2vh", marginTop: "8vh", width: "100%", textAlign: "center", padding: "2.5vh"}}>
		<MobileFontAwesome icon={ICONS.CALENDAR_ALT} style={{paddingRight: "2vh", fontSize: "3vh"}}/>
		{translate(DICTIONARY.VESSEL_STORAGE_UNIT_RESERVATION.X)}
	</div>
	return <MobileInputFormWithHeader marginTop={"0vh"} maxHeight={"56vh"} height={"56vh"} hideIcon={true} header={header}
		content={props.content} back={props.back} onSubmit={props.onSubmit} disabled={props.disabled}/>
}

export default StorageReservationsForm