import {typeOrNull} from '../../functions/shared/local'
import Tax from "./Tax"
class TaxExemption {
	/**
	 *
	 * @param {TaxExemption} [exemption = null]
	 * @param {number} [exemption.tax]
	 * @param {Week} [exemption.week]
	 * @param {Timezone} [exemption.timezone]
	 */
	constructor(exemption = null) {
		try {
			this.tax = new Tax(exemption.tax)
			this.goods = typeOrNull(exemption.goods, "boolean")
			this.services = typeOrNull(exemption.services, "boolean")
		} catch (e) {
			this.tax = new Tax()
			this.goods = null
			this.services = null
		}
	}
}

export default TaxExemption