import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileNewLinkedAccount from "./MobileNewLinkedAccount"
import MobileLinkedAccount from "./MobileLinkedAccount"
import Modal from "../../../globals/components/Modal"
/**
 *
 * @param {Object} props
 * @param {Object[]} props.linkedAccounts
 * @param {number} props.linkedAccounts[].id
 * @param {string} props.linkedAccounts[].username
 * @param {string} props.linkedAccounts[].image - image path
 * @param {string} props.linkedAccounts[].type - account type
 * @param {string} props.linkedAccounts[].organization - name
 * @param {number} props.linkedAccounts[].notifications - number of pending notifications
 * @param {string} props.top
 * @param {number} props.zIndex
 * @param {function} props.refresh
 * @param {function} props.setAccount
 * @return {JSX.Element}
 * @constructor
 */
const MobileUsersLinkedAccounts = props => {
	const [expanded, setExpanded] = useState(false)
	const [linkedAccounts, setLinkedAccounts] = useState(props.linkedAccounts || [])
	const [add, setAdd] = useState(false)
	useEffect(() => setLinkedAccounts(props.linkedAccounts || []), [props.linkedAccounts])
	// console.log(linkedAccounts)
	const removeAccount = id => {
		setLinkedAccounts(linkedAccounts.filter(account => account.id !== id))
	}
	const add_ = <MobileAddNewBanner className={`linkedAccounts`} text={translate(DICTIONARY.ADD_LINKED_ACCOUNT.X)}
		onClick={() => {add ? setAdd(false) : setAdd(true)}}/>
	const insert = add ? <Modal id={"add-linked-account-modal"} title={translate(DICTIONARY.ADD_LINKED_ACCOUNT.X)}
		onClick={() => setAdd(false)} icon={ICONS.LINK} padding={"0vh"} body={
		<MobileNewLinkedAccount onSuccess={payload => {
			props.refresh().then()
			setLinkedAccounts(payload)
			setAdd(false)
		}}/>
	}/>   : <></>
	const content = !expanded ? <></> : <div className={"w3-animate-top"}>
		{
			linkedAccounts.map(account => {
				return <MobileLinkedAccount key={`linked-account-${account.id}`} account={account}
					setAccount={props.setAccount}	setLinkedAccounts={setLinkedAccounts}
					removeAccount={() => removeAccount(account.id)} refresh={props.refresh}/>
			})
		}
		{insert}{add_}</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top}	text={translate(DICTIONARY.LINKED_ACCOUNTS.X)}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileUsersLinkedAccounts