import React from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "./MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {function} props.onClick
 * @param {string} props.text
 * @returns {JSX.Element}
 * @constructor
 */
const MobileAddNewBanner = props => {
	return <div className={`mobile-add-new-banner w3-ripple paralian-theme-level-4-flipped ${props.className}`}
		style={{fontSize: "2vh", padding: "1.5vh",	margin: "1.5vh 2vh", borderRadius: "1vh",
			textAlign: "center", ...props.style}} onClick={props.onClick}>
		<div style={{display:"inline-flex", margin: "0vh auto"}}>
			<div style={{padding: "1vh 0vh"}}>{props.text}</div>
			<MobileFontAwesome style={{padding: "1vh", margin: "auto"}} icon={ICONS.PLUS_CIRCLE}
				label={translate(DICTIONARY.ADD.X)}/>
		</div>
	</div>
}

export default MobileAddNewBanner