import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class Longitude extends TextInputFilter {
	minLength = 1
	maxLength = 32
	regexp = /[^0-9-.]/gi
	minVal = -180
	maxVal = 180
}

export default Longitude;