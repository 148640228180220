import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {deleteOrgHoliday} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileHolidayFields from "./MobileHolidayFields"
import MobileInputHolidayEdit from "./MobileInputHolidayEdit"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {Holiday} props.holiday
 * @param {boolean} props.use24Hr
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileHoliday = props => {
	const [edit, setEdit] = useState(false)
	const [holiday, setHoliday] = useState(props.holiday)
	useEffect(() => {setHoliday(props.holiday)}, [props.holiday])
	const onDelete = async () => {
		const confirmed = window.confirm(translate(DICTIONARY.DELETE_HOLIDAY_CONFIRM_QUERY.X))
		if (confirmed) {

			const response = await deleteOrgHoliday(holiday.id)

			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setEdit(false)
				if (props.refresh) {props.refresh().then()}
			}
		}
	}
	return <div id={"service-area"} className={"w3-animate-top paralian-mobile-info-card"}
		style={{textAlign: "center", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} onDelete={onDelete}
			labels={[holiday.name]}/>
		{edit ? <MobileInputHolidayEdit id={props.id} form={`holiday-${props.id}`} holiday={holiday}
			use24Hr={props.use24Hr} left={null} className={props.className} onSubmit={holiday => {
				setEdit(false)
				setHoliday(holiday)
				if (props.refresh) {props.refresh()}
			}}/> :
			<MobileHolidayFields holiday={holiday} use24Hr={props.use24Hr}/>}
	</div>

}

export default MobileHoliday