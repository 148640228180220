import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getTimezones} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import Timezone from "../../../globals/classes/shared/Timezone"
import Country from "../../../globals/classes/shared/Country"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} [props.country = "USA"] - ISO3166 3-letter code
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {Timezone} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {boolean} props.appendToLabel
 * @param {boolean} props.showCountry
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputTimezoneDropdown = props => {
	// console.log(props.defaultValue)
	// console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
	const [timezones, setTimezones] = useState({index: 0,
		list: [{label: "America - New York", name: "United States", value: 0, dataId: "America/New_York"}]})
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	const label = props.showLabel || !props.appendToLabel ? "" : `${translate(DICTIONARY.TIME_ZONE.X)}: `
	useEffect(() => {
		const country_ = new Country(props.country)
		getTimezones(country_.code2 || "US").then(response => {
			// console.log(response)
			try {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					const list = []
					const timezone_ = new Timezone(props.defaultValue)
					const defaultValue = timezone_.zone || `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
					for (let i = 0; i < response.payload.length; i++) {
						list.push({label: `${label}${response.payload[i].zone.replace("/", " - ")
								.replace(/[_]/gi, " ")} (${response.payload[i].country})`,
							value: i, name: response.payload[i].name,	dataId: response.payload[i].zone})
					}
					const index = list.findIndex(item => item.dataId === defaultValue)
					const indexNyc = list.findIndex(item => item.dataId === "America/New_York")
					setTimezones({index: index !== -1 ? index : indexNyc !== -1 ? indexNyc : 0, list: [...list]})
				}
			} catch (e) {
				console.log(e)
			}
		})
	}, [label, props.country, props.defaultValue])
	const country = props.showCountry ? <div className={className} style={{textAlign: "center",
		padding: "1vh 0vh", borderBottomRightRadius: props.bottomRadius, borderBottomLeftRadius: props.bottomRadius}}>
		{timezones.list[timezones.index].name}</div> : <></>
	return <>
		<MobileInputGeneric type={"dropdown"} id={props.id} className={className} size={"1"} showLabel={props.showLabel}
			label={translate(DICTIONARY.TIME_ZONE.X)} multiple={false} topRadius={props.topRadius} name={"timezone"}
			dataId={timezones.list[timezones.index].dataId} list={timezones.list} left={props.left} style={props.style}
			form={props.form} autoFocus={false} required={true} defaultValue={timezones.index} dataIndex={timezones.index}
			bottomRadius={props.showCountry ? "0vh" : props.bottomRadius} onChange={() => {
				try {
					const element = document.getElementById(`${props.id}-input`)
					element.setAttribute("data-id", timezones.list[element.value].dataId)
					element.setAttribute("data-index", element.value)
					setTimezones({list: [...timezones.list], index: element.value})
					onChange()
				} catch (e) {
					console.log(e)
				}
			}}/>
		{country}
	</>
}

export default MobileInputTimezoneDropdown