import React from "react"
import MobileSVGIcon from "./MobileSVGIcon"

const SlipIcon = props => {
	const icon = <g>
		<path stroke={"currentColor"} fill={"currentColor"} strokeWidth={"null"} d={"m256.87034,73.20395c-64.33219," +
			"31.77822 -92.24204,105.515 -76.19388,203.23011c33.07317,207.25237 23.86292,215.46625 58.61068," +
			"216.77878l35.16641,0c38.82827,1.31253 27.2121,-10.42965 58.61068,-217.68202c10.46619,-96.23792 " +
			"-8.37295,-173.42302 -76.19388,-202.32686z"} id={"svg_5"}/>
		<path stroke={"currentColor"} id={"svg_2"} d={"m131.5,315.03623l0,-234.91001c0,-22.2675 25.38349," +
			"-42.33274 46.53641,-41.10925l152.30097,0c34.24757,-1.22349 49.75971,25.44859 50.16262,41.10925l0,234.91001"}
			opacity={"0.5"} fillOpacity={"0"} strokeOpacity={"null"} strokeWidth={"50"} fill={"currentColor"}/>
	</g>
	return <MobileSVGIcon icon={icon} onClick={props.onClick}/>
}

export default SlipIcon