import { isObject, typeOrNull } from '../../functions/shared/local';
import Method from './payments/Methods';

class PaymentMethods {
	/**
	 *
	 * @param {PaymentMethods} [paymentMethods = null]
	 * @param {string} [paymentMethods.defaultPaymentMethod]
	 * @param {Methods} [paymentMethods.methods]
	 */
	constructor (paymentMethods = null) {
		if (isObject(paymentMethods)) {
			this.defaultPaymentMethod = typeOrNull(paymentMethods.defaultPaymentMethod, "string")
			this.methods = new Method(paymentMethods.methods)
		} else {
			this.defaultPaymentMethod = null
			this.methods = new Method()
		}
	}
}

export default PaymentMethods;