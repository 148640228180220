import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {updateOrgCurrency} from "../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputCurrencyDropdownAndSymbol from "./MobileInputCurrencyDropdownAndSymbol"
import Currency from "../../../globals/classes/shared/Currency"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} [props.country = "USA"] - ISO3166 3-letter code
 * @param {Currency} props.currency
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileCurrencyEdit = props => {
	const onSubmit = props.onSubmit ? props.onSubmit : () => {}
	const content = <MobileInputCurrencyDropdownAndSymbol id={props.id} form={props.id} appendToLabel={false}
		showLabel={false} className={"paralian-theme-level-1"} country={props.country} defaultCurrency={props.currency}/>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={async event => {
		try {
			event.preventDefault()
			const element = document.getElementById(`${props.id}-currency-input`)
			const symbol = element.getAttribute("data-a")
			const name = element.getAttribute("data-b")
			const code = element.getAttribute("data-id")
			const response = await updateOrgCurrency(code)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				onSubmit(new Currency({code: code, symbol: symbol, name: name}))
			}
		} catch (e) {
			console.log(e)
		}
	}}/>
}

export default MobileCurrencyEdit