import {store} from "../../../../index"
import {DEVICE_TYPE} from "../../../constants/client/constants"
import {getFromStorage} from "../localFunctions"
import Mode from "../../../classes/client/Mode"

/**
 * Retrieves the type of device using the application based on the display aspect ratio information from the browser
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @returns {string|null}
 */
const getDeviceType = () => {
	let state, local
	try {
		// get redux device type
		state = store.getState().device.type
		// if redux device type is available, return redux device type
		if (Object.values(DEVICE_TYPE).includes(state)) {return state}
		// if redux device type is not available, get device type from localStorage...
		// where it is kept as failsafe for when the browser is refreshed and the device type is reset to its original...
		// undefined state
		local = new Mode({device: getFromStorage("mode").device})
		// return local.device, which will be null if what was returned from localStorage was not a DEVICE_TYPE value
		return local.device
	} catch (e) {
		console.log(e)
		return null
	}
}

export default getDeviceType