import Name from './Name';
import Birthdate from './Birthdate';
import ContactInfo from './ContactInfo';
import { isObject, typeOrNull } from '../../functions/shared/local';

class Contact {
	/**
	 *
	 * @param {Contact} [contact = null]
	 * @param {number} [contact.id]
	 * @param {Name} [contact.name]
	 * @param {Birthdate} [contact.birthdate]
	 * @param {ContactInfo} [contact.contactInfo]
	 *
	 */
	constructor (contact = null) {
		if (isObject(contact)) {
			this.id = typeOrNull(contact.id, "S/N")
			this.name = new Name(contact.name)
			this.birthdate = new Birthdate(contact.birthdate)
			this.contactInfo = new ContactInfo(contact.contactInfo)
		} else {
			this.id = null
			this.name = new Name()
			this.birthdate = new Birthdate()
			this.contactInfo = new ContactInfo()
		}
	}
}

export default Contact;