import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import Accesses from "../../../../globals/classes/shared/Accesses"
/**
 *
 * @param {Object} props
 * @param {Accesses} props.accesses
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselAccessCardFields = props => {
	const [data, setData] = useState("")
	const [physical, setPhysical] = useState("")
	const [orders, setOrders] = useState("")
	const accesses = new Accesses(props.accesses)
	return accesses.isOwner ?
		<div style={{textAlign: "center", marginTop: "1vh"}}><b>{translate(DICTIONARY.OWNER.X)}</b></div> :
		<div>
			{accesses.orders.any ? <div style={{textAlign: "center", marginTop: "1vh"}}>
				<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.ORDERS.X)}</div>
				<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{orders}</div>
				<div style={{fontSize: "6vh"}}>
					<MobileFontAwesome icon={ICONS.PAPER_PLANE} label={translate(DICTIONARY.PLACE_ORDER.X)}
						style={{margin: "1vh"}} className={accesses.orders.access.includes("PLACE") ? null : "paralian-text-blue"}
						onClick={() => setOrders(`${translate(DICTIONARY.PLACE_ORDER.X)}${accesses.orders.access.includes("PLACE") ?
							"" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
				</div>
			</div> : <></> }
			{accesses.data.any ? <div style={{textAlign: "center", marginTop: "1vh"}}>
				<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.DATA_ACCESS.X)}</div>
				<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{data}</div>
				<div style={{fontSize: "6vh"}}>
					<MobileFontAwesome icon={ICONS.FILE_PDF} label={translate(DICTIONARY.DOCUMENT_VIEW_ACCESS.X)}
						style={{margin: "1vh"}} className={accesses.data.fullProfile.includes("VIEW") ? null : "paralian-text-blue"}
						onClick={() => setData(`${translate(DICTIONARY.DOCUMENT_VIEW_ACCESS.X)}${accesses.data.fullProfile.includes("VIEW") ?
							"" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
					<MobileFontAwesome icon={ICONS.SHIP} label={translate(DICTIONARY.PROFILE_VIEW_ACCESS.X)}
						style={{margin: "1vh"}} className={accesses.data.limitedProfile.includes("VIEW") ||
							accesses.data.fullProfile.includes("VIEW") ? null : "paralian-text-blue"}
						onClick={() => setData(`${translate(DICTIONARY.PROFILE_VIEW_ACCESS.X)}${accesses.data.limitedProfile.includes("VIEW") ||
							accesses.data.fullProfile.includes("VIEW") ? "" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
				</div>
			</div> : <></> }
			{accesses.physical.any ? <div style={{textAlign: "center", marginTop: "1vh"}}>
				<div style={{margin: "1vh 0vh"}}>{translate(DICTIONARY.PHYSICAL_ACCESS.X)}</div>
				<div style={{margin: "1vh 0vh", opacity: "0.6"}} className={"w3-animate-opacity"}>{physical}</div>
				<div style={{fontSize: "6vh"}}>
					<MobileFontAwesome icon={ICONS.FORKLIFT} label={translate(DICTIONARY.MOVE_WITHIN_PREMISES.X)}
						style={{margin: "1vh"}} className={accesses.physical.access.includes("MOVE") ? null : "paralian-text-blue"}
						onClick={() => setPhysical(`${translate(DICTIONARY.MOVE_WITHIN_PREMISES.X)}${accesses.physical.access.includes("MOVE") ?
							"" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
					<MobileFontAwesome icon={ICONS.ROUTE} label={translate(DICTIONARY.LEAVE_PREMISES.X)}
						style={{margin: "1vh"}} className={accesses.physical.access.includes("LEAVE") ? null : "paralian-text-blue"}
						onClick={() => setPhysical(`${translate(DICTIONARY.LEAVE_PREMISES.X)}${accesses.physical.access.includes("LEAVE") ?
							"" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
					<MobileFontAwesome icon={ICONS.SIGN_IN_ALT} label={translate(DICTIONARY.BOARD.X)}
						style={{margin: "1vh"}} className={accesses.physical.access.includes("BOARD") ? null : "paralian-text-blue"}
						onClick={() => setPhysical(`${translate(DICTIONARY.BOARD.X)}${accesses.physical.access.includes("BOARD") ?
							"" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
					<MobileFontAwesome icon={ICONS.WRENCH} label={translate(DICTIONARY.SERVICE.X)}
						style={{margin: "1vh"}} className={accesses.physical.access.includes("SERVICE") ? null : "paralian-text-blue"}
						onClick={() => setPhysical(`${translate(DICTIONARY.SERVICE.X)}${accesses.physical.access.includes("SERVICE") ?
							"" : ` - ${translate(DICTIONARY.DENIED.X)}`}`)}/>
				</div>
			</div> : <></> }
{/*
			<div>
				{accesses.data.asLocaleString(accesses.data.starts || accesses.data.granted)}
				{accesses.data.asLocaleString(accesses.data.ends)}
				{accesses.physical.asLocaleString(accesses.physical.starts || accesses.physical.granted)}
				{accesses.physical.asLocaleString(accesses.physical.ends)}
			</div>
*/}
		</div>
}

export default MobileVesselAccessCardFields