import React, {useEffect, useState} from "react"
import {ICONS} from "../../globals/constants/client/constants"
import MobileFontAwesome from "./MobileFontAwesome"
import QrScanner from "qr-scanner"
/*

TODO: on scan
	if personal to personal : ask if they want to provide vessel access
	if personal to employee : if organization/employee account ask if they want to add to list of preferred providers
			(or marinas if marina), would like to make a payment, would like to provide kudos (specific types of likes)
	if org/employee to personal: log interaction [account_1, account_2, "qr scan", timestamp], show services, show access
	if access point to personal/employee: og interaction [account_1, account_2, "qr scan", timestamp, approved/denied],
			show approved/denied
	if personal to vessel: log position of vessel using geolocation
	if employee to vessel: log position of vessel using geolocation, if check if order is open with
			employee/organization, ask to update order
*/

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
const MobileQR = props => {
	const [qr, setQr] = useState(`${process.env.REACT_APP_IMAGES_PATH}/qrcode-duotone.svg`)
	const [scanner, setScanner] = useState(null)
	const [scannerStatus, setScannerStatus] = useState("stop")

	useEffect(() => {
		const now = new Date()
		const year = now.getUTCFullYear()
		const month = now.getUTCMonth()
		const date = now.getUTCDate()
		const today = new Date(year, month, date,0,0,0,0)
		const msText = today.getTime()
		setQr(`${process.env.REACT_APP_API_PATH}/get/restricted/accounts/qr/${props.account.id}/${msText}.svg`)
	}, [props.account.id])

	useEffect(() => {
		if (!scanner) {
			const qrScanner = new QrScanner(document.getElementById("scanner"), result => {
				// console.log('decoded qr code:', result)
				qrScanner.stop()
				setScannerStatus("stop")
				console.log("Scanner stop")
			})
			setScanner(qrScanner)
			qrScanner.setInversionMode("invert")
			console.log("Scanner created on useEffect")
		} else {
			return () => {
				scanner.destroy()
				console.log("Scanner destroyed on useEffect")
			}
		}
	}, [scanner])

	let displayQR = "inherit", displayCamera = "none"
	if (scannerStatus === "start") {
		displayQR = "none"
		displayCamera = "inherit"
	} else {
		displayCamera = "none"
		displayQR = "inherit"
	}

	const cap = str => str.replace(/(^|[\s-])\S/g, match => match.toUpperCase())
	/*
  console.log(Object.values(ICONS).filter(v => v[0] === "fab").map(v => {
		const iconName = "fa" + cap(`${v[1].charAt(0).toUpperCase() + v[1].slice(1)}`).replace(/-/g, "")
		return `${iconName}: <__FontAwesomeIcon icon={${iconName}}/>`.replace("__", "")
	}))
*/
	return <>
		<div>
			<MobileFontAwesome id={"scan"} icon={ICONS.CAMERA_ALT} style={{fontSize: "5vh", marginTop: "22vh", zIndex: "10"}}
				onClick={() => {
				console.log("Scan QR Code Clicked")
				/*                    if (scannerStatus === "stop") {
				/!*                        QrScanner.scanImage("/images/qr.svg")
																.then(result => console.log(result))
																.catch(error => console.log(error || 'No QR code found.'));*!/
																scanner.start().then(() => {
																		setScannerStatus("start")
																		console.log("Scanner start")
																})
														} else if (scannerStatus === "start") {
																scanner.stop()
																setScannerStatus("stop")
																console.log("Scanner stop")
														}*/
			}}/>
		</div>
		<div className={"w3-display-middle w3-animate-opacity"} style={{width: "100%"}}>
			<video id={"scanner"} style={{width: "80%", display: displayCamera}}/>
			<picture style={{display: displayQR}}>
				<img src={qr} alt={"QR"} style={{width: "80%"}}/>
			</picture>
		</div>
	</>
}

export default MobileQR