import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.changePaymentMethod
 * @param {boolean} props.order.buttons.pay
 * @param {function} props.setShowChangePaymentMethodModal
 * @return {JSX.Element}
 * @constructor
 */
const ChangePaymentMethodButton = props => {
	return props.order.buttons.changePaymentMethod && !props.order.buttons.pay && !props.order.buttons.adjust.respond ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"changePaymentMethod"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.UPDATE_ORDER_PAYMENT_METHOD.X)}} left={{icon: ICONS.CREDIT_CARD_SHAKE}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}}
				onClick={() => props.setShowChangePaymentMethodModal(true)}/>
		</div> : <></>
}

export default ChangePaymentMethodButton