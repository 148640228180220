import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {addVesselStorageUnit} from "../../../../globals/functions/client/serverFunctions"
import MobileInputMarinaVSUFacilitiesDatalist from "../../marinas/facilities/MobileInputMarinaVSUFacilitiesDatalist"
import MobileInputGenericMarinasDropdown from "../../marinas/MobileInputGenericMarinasDropdown"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputMarinaVSUnitsDatalist from "../../marinas/facilities/MobileInputMarinaVSUnitsDatalist"
import VesselHome from "../../../../globals/classes/shared/vessels/VesselHome"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {marinas} props.marinas
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageFacilitiesAdd = props => {
	// console.log("props", props)
	const [marina, setMarina] = useState(props.marinas.length > 0 ?
		props.defaultMarina || props.marinas[0].id : null) // marina id
	const [facility, setFacility] = useState(-1) // facility id

	const onSubmit = async event => {
		event.preventDefault()
		const facilityElement = document.getElementById("add-unit-facility-input")
		const facilityName = facilityElement.value
		const facilityId = facilityElement.getAttribute("data-id") === "-1" ? null : Number(facilityElement.getAttribute("data-id"))
		const unitElement = document.getElementById("add-unit-unit-input")
		const unitName = unitElement.value
		const unitId = unitElement.getAttribute("data-id") === "-1" ? null : Number(unitElement.getAttribute("data-id"))
		const response = await addVesselStorageUnit(props.id, marina, facilityName, facilityId, unitName, unitId)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess(new VesselHome(response.payload))
		}
	}
	const content = <>
		<MobileInputGenericMarinasDropdown id={"add-unit"} marinas={props.marinas} showLabel={true} className={"paralian-theme-level-1"}
			onChange={id => setMarina(id)} defaultValue={marina}/>
		{/* TODO: Gather marina vessel facilities facilities list. If selected from list, send id. If not, send text */}
		<MobileInputMarinaVSUFacilitiesDatalist id={"add-unit-facility"} className={"paralian-theme-level-1"} marina={marina}
			bottomRadius={"1vh"} topRadius={"1vh"} showLabel={true} placeholder={translate(DICTIONARY.SLIPS.X)}
			name={"add-unit-facilities"} form={`add-unit-${props.id}-form`} required={true} label={translate(DICTIONARY.FACILITY.X)}
			onChange={id => {
				setFacility(id)
			}}/>
		<MobileInputMarinaVSUnitsDatalist id={"add-unit-unit"} className={"paralian-theme-level-1"} marina={marina}
			bottomRadius={"1vh"} topRadius={"1vh"} showLabel={true} placeholder={"B-2301"} facility={facility}
			name={"add-unit-unit"} form={`add-unit-${props.id}-form`} required={true} label={translate(DICTIONARY.UNIT.X)}/>
		</>
	return <MobileInputForm id={props.id} content={content} onSubmit={onSubmit}/>
}

export default MobileVesselStorageFacilitiesAdd