import React, {useState} from "react"
import {DICTIONARY, ICONS, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {DOCUMENT_CONTAINER, DOCUMENT_TYPE, DOCUMENT_TYPE_MINIMAL} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {enumOrNull} from "../../../globals/functions/shared/local"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileFileUploadCanvas from "../../shared/input/file/MobileFileUploadCanvas"
import Modal from "../../../globals/components/Modal"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileInputDocumentFields from "./MobileInputDocumentFields"
import containerAndMinimalTypeToDocumentType from "./containerAndMinimalTypeToDocumentType"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {number} props.selected
 * @param {IndexedArray[Document]} props.documents
 * @param {string} props.info
 * @param {string} props.title
 * @param {string} props.type - DOCUMENT_TYPE
 * @param {string} props.container - DOCUMENT_CONTAINER
 * @param {Country} props.country
 * @param {Currency} props.currency
 * @param {function} props.onSubmit
 * @param {function} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
const MobileDocumentSelectOrUpload = props => {
	const [value, setValue] = useState(props.selected || "-1")
	const className = props.className || "paralian-theme-level-1"
	const id = `${props.id}-doc-select-upload`
	const container = props.container || DOCUMENT_CONTAINER.ORGANIZATIONS
	const type = enumOrNull(props.type, DOCUMENT_TYPE) ||
		containerAndMinimalTypeToDocumentType(container, props.type || DOCUMENT_TYPE_MINIMAL.PUBLIC)
	// console.log("type", type)
	const form = `${id}-form`
	// {value: "-2", label: "** " + translate(DICTIONARY.NONE.X) + " **"}
	const list = [...props.documents.values.map(v => {return {value: v.id, label: v.name}}),
		{value: "-1", label: "** " + translate(DICTIONARY.UPLOAD_NEW.X) + " **"}].filter(v => !!v.value)
	const selection = list.find(v => v.value === props.selected)
	const canvas = value === "-1" ? <div className={"w3-animate-opacity"}>
		<MobileFileUploadCanvas id={props.id}
			label={translate(DICTIONARY.FILE_UPLOAD.X)} className={"paralian-theme-level-1"}
			form={form} showLabel={true} info={props.info}/>
		<MobileInputText id={`${container}-label`} name={"label"} showLabel={true} bottomRadius={"1vh"} autoComplete={"off"}
			label={translate(DICTIONARY.LABEL.X)} className={className} style={{margin: "1vh 0vw"}} topRadius={"1vh"}
			form={form} required={false} defaultValue={props.label} placeholder={translate(DICTIONARY.LABEL.X)}
			maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX} title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE}
			pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} autoFocus={false}/>
		<MobileInputDocumentFields docType={type}	container={container} className={className}
			country={props.country} state={props.state} currency={props.currency}/>
	</div> : <></>
	const content = <div>
		<MobileInputGeneric type={"dropdown"} id={`${id}-dropdown`}	className={className} size={"1"} form={form}
			label={props.label} multiple={false} dataId={props.selected ? selection.value : ""} showLabel={true} list={list}
			dataA={props.selected ? selection.label : ""}	defaultValue={value} autoFocus={false} name={`${id}-dropdown`}
			required={true} onChange={() => {
				try {
					const value_ = document.getElementById(`${id}-dropdown-input`).value
					setValue(value_)
				} catch (e) {
					console.log(e)
				}
			}}/>
		{canvas}

	</div>
	return <Modal icon={ICONS.UPLOAD} title={props.title}	onClick={() => props.onClick(false)}
		id={`${id}-modal`} padding={"0vh"} body={<MobileInputForm id={id} encType={"multipart/form-data"}
		onSubmit={props.onSubmit}	content={content}/>}/>
}

export default MobileDocumentSelectOrUpload