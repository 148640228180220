import Email from './Email';
import { isObject } from '../../functions/shared/local';

class Emails {
	/**
	 *
	 * @param {Emails} [emails = null]
	 * @param {Email} [emails.primary]
	 * @param {Email} [emails.secondary]
	 */
	constructor(emails = null) {
		if (isObject(emails)) {
			this.primary = new Email(emails.primary)
			this.secondary = new Email(emails.secondary)
		} else {
			this.primary = new Email()
			this.secondary = new Email()
		}
	}
}

export default Emails;