import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {addFavoriteMarina} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import getPosition from "../../../globals/functions/client/getPosition"
import MobileInputCountryDropdown from "../address/MobileInputCountryDropdown"
import MobileInputStateDropdown from "../address/MobileInputStateDropdown"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import MobileInputParalianMarinasDropdown from "./MobileInputParalianMarinasDropdown"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import State from "../../../globals/classes/shared/State"
import Coordinates from "../../../globals/classes/shared/Coordinates"
import Country from "../../../globals/classes/shared/Country"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.id} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {Country} props.country
 * @param {State} props.state
 * @param {function} props.setMarina
 * @returns {JSX.Element}
 * @constructor
 */
const MobileParalianMarinaSearch = props => {
	// TODO: set default selections based on selected marina if available
	const [useLocation, setUseLocation] = useState(false)
	const [country, setCountry] = useState(new Country(props.country))
	const [state, setState] = useState(props.state || new State())
	const [distance, setDistance] = useState(10)
	const [units, setUnits] = useState("miles")
	const [coordinates, setCoordinates] = useState(new Coordinates())
	useEffect(() => {
		try {
			setCountry(props.country)
		} catch (e) {
			setCountry(new Country())
		}
	}, [props.country])
	useEffect(() => {
		try {
			setState(props.state)
		} catch (e) {
			setState(new State())
		}
	}, [props.state])
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const marina = document.getElementById("marina-search-marina-input").getAttribute("data-id")
			const response = await addFavoriteMarina(marina)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess()
			}
		} catch (e) {
			console.log(e)
		}
	}
	const className = props.className || "paralian-theme-level-1-flipped"
	const style = {margin: "0.5vh 0vw"}
	const countryElement = !useLocation ? <MobileInputCountryDropdown required={true} className={className}
		id={`${props.id}-country`} showLabel={true}	autoFocus={false} setCountry={setCountry} style={style}
		 form={`${props.id}-form`} defaultValue={country.code || props.country.code || "USA"}	autoComplete={"off"}/> : <></>
	const stateElement = !useLocation ? <MobileInputStateDropdown required={true}
		className={className} country={country}	id={`${props.id}-state`} showLabel={true}
		autoFocus={false}	style={style} form={`${props.id}-form`}	defaultValue={state.code || props.state.code || "US-AK"}
		autoComplete={"off"} onChange={state => setState(new State({code: state.id}))}/> : <></>
	const content = <div className={"w3-center w3-animate-opacity"} style={{fontSize: "2.5vh"}}>
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "right", marginBottom: "1vh"}} className={"w3-display-container"}>
				<MobileInputSlider id={`${props.id}-use-location`} form={`${props.id}-form`}
					label={translate(DICTIONARY.PROXIMITY_SEARCH.X)}
					defaultChecked={false} height={"3vh"}
					className={"paralian-mobile-info-card-header w3-display-middle"} width={"8vh"}
					padding={"0.25vh 0vh 0.25vh 2vh"} onChange={async () => {
						// console.log("location use status", useLocation)
					if (!useLocation) {
						const response = await getPosition()
						// console.log("getPosition", response)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							setCoordinates(response.payload)
						}
					}
					setUseLocation(!useLocation)
				}}/>
			</div>
		</div>
		{useLocation ? <div style={{display: "flex", margin: "0.5vh 0vh", width: "100%"}}>
			<div style={{minWidth: "16vh"}}>
				<MobileInputGeneric type={"dropdown"} id={`${props.id}-distance`} className={"paralian-theme-level-1"}
					size={"1"} label={translate(DICTIONARY.DISTANCE.X)} multiple={false} showLabel={false}
					defaultValue={distance || 0} style={{height: "12vh", width: "100%", padding: "3vh 0vh"}}
					name={"distance"} form={`${props.id}-form`} autoFocus={false} required={true}
					list={[{value: "10", label: "10"}, {value: "25", label: "25"}, {value: "50", label: "50"},
						{value: "100", label: "100"}, {value: "200", label: "200"}]}
					placeholder={translate(DICTIONARY.DISTANCE.X)} onChange={() => {
					setDistance(document.getElementById(`${props.id}-distance-input`).value)
				}}/>
			</div>
			<div style={{marginLeft: "1vh", width: "100%"}}>
				<MobileInputGeneric type={"dropdown"} id={`${props.id}-units`} className={"paralian-theme-level-1"} size={"1"}
					label={translate(DICTIONARY.UNITS.X)} multiple={false} dataId={"miles"} showLabel={false}
					defaultValue={units || 0} style={{height: "12vh", width: "100%", padding: "3vh 0vh"}}
					name={"units"} form={`${props.id}-form`} autoFocus={false} required={true}
					list={[{value: "miles", label: translate(DICTIONARY.MILES.X)},
						{value: "kilometers", label: translate(DICTIONARY.KILOMETERS.X)}]}
					placeholder={translate(DICTIONARY.COUNTRY.X)} onChange={() => {
					setUnits(document.getElementById(`${props.id}-units-input`).value)
				}}/>
			</div>
		</div> : <></>}
		{countryElement}
		{stateElement}
		<MobileInputParalianMarinasDropdown id={props.id} form={`${props.id}-form`}
			distance={distance * 1000 * (units === "miles" ? 1.60934 : 1)} state={state} showLabel={true}
			style={{fontSize: "2.5vh"}}	coordinates={coordinates} className={"paralian-theme-level-1"}/>
		<div style={{paddingTop: "1.5vh", fontSize: "2vh"}}>
			<div><i>{translate(DICTIONARY.CANT_FIND_MARINA.X)}</i></div>
			<div><i>{translate(DICTIONARY.HELP_SPREAD_WORD.X)}</i></div>
		</div>
	</div>
	return <MobileInputForm id={"marina-search"} content={content} onSubmit={onSubmit}/>
}

export default MobileParalianMarinaSearch