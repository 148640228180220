import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {addressAdd} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import addressInputCapture from "./addressInputCapture"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputAddressFields from "./MobileInputAddressFields"
import MobileInputAddressType from "./MobileInputAddressType"
import MobileInputAddressLabel from "./MobileInputAddressLabel"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Address from "../../../globals/classes/shared/Address"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {Address} props.address
 * @param {boolean} props.showLabel
 * @param {boolean} props.autoSave
 * @param {boolean} props.autoComplete
 * @param {boolean} props.hasLabel
 * @param {boolean} props.showTypes
 * @param {boolean} props.showLine3
 * @param {boolean} props.isOrganization
 * @param {function} props.onUndo
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAddressSave = props => {
	const [save, setSave] = useState(props.autoSave || false)
	// useEffect(() => {}, [])
	const onSubmit = props.onSubmit ? props.onSubmit : () => {}
	const saveSlider = props.autoSave ? <></> :
		<div className="w3-display-container" style={{margin: "2vh 0vw 1vh 0vw", height: "3vh"}}>
			<MobileInputSlider id={"saveInDatabase"} label={translate(DICTIONARY.SAVE.X)} value={true} defaultChecked={false}
				padding={"0.25vh 0vh 0.25vh 2vh"}	height={"3vh"} width={"8vh"} className={"w3-display-middle"}
				onChange={element => {setSave(element.getAttribute("data-is-checked") === "true")}}/>
		</div>
	const hasLabel = props.hasLabel && save ? <MobileInputAddressLabel id={props.id} form={props.form}
		showLabel={props.showLabel} style={props.style} className={props.className}/> : <></>
	const showTypes = props.showTypes && save ? <MobileInputAddressType id={props.id} address={new Address()}/> : <></>
	const content = <>
		{hasLabel}
		{showTypes}
		<MobileInputAddressFields id={props.id} form={props.form} showLabel={props.showLabel} showLine3={props.showLine3}
			address={props.address}
			autoComplete={props.autoComplete} style={props.style} className={props.className}/>
		{saveSlider}
	</>
	return <MobileInputForm id={props.id} content={content} onSubmit={async event => {
		event.preventDefault()
		const response = await addressAdd(addressInputCapture(props.id), props.isOrganization)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			onSubmit()
		}
	}}/>
}

export default MobileInputAddressSave