import {DOCUMENT_TYPE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"

const translateDocumentType = type => {
	switch (type) {
		case DOCUMENT_TYPE.CERTIFICATES:
			return translate(DICTIONARY.LICENSES_PERMITS_CERTIFICATES.X)
		case DOCUMENT_TYPE.BONDS:
			return translate(DICTIONARY.BONDS.X)
		case DOCUMENT_TYPE.INSURANCE:
			return translate(DICTIONARY.INSURANCE_DOCUMENTS.X)
		case DOCUMENT_TYPE.VESSEL_TITLES:
			return translate(DICTIONARY.TITLE.X)
		case DOCUMENT_TYPE.VESSEL_REGISTRATIONS:
			return translate(DICTIONARY.REGISTRATION.X)
		case DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS:
			return translate(DICTIONARY.DOCUMENTATION.X)
		case DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS:
			return translate(DICTIONARY.ORGANIZATION_REGISTRATION_DOCUMENTS.X)
		case DOCUMENT_TYPE.CONTRACTS:
			return translate(DICTIONARY.CONTRACTS_AGREEMENTS.X)
		case DOCUMENT_TYPE.PUBLIC:
			return translate(DICTIONARY.PUBLIC_DOCUMENTS.X)
		case DOCUMENT_TYPE.WARRANTIES:
			return translate(DICTIONARY.WARRANTIES.X)
		case DOCUMENT_TYPE.UPKEEP:
			return translate(DICTIONARY.MAINTENANCE_REPAIR_AND_IMPROVEMENT_RECORDS.X)
		default:
			return ""
	}
}

export default translateDocumentType