import React, {useRef} from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import {Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js/pure"
import Modal from "../../../../../globals/components/Modal"
import MobileStripeUpdateOrderPaymentMethod from "../../../payment/MobileStripeUpdateOrderPaymentMethod"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.provider
 * @param {string} props.order.provider.connectId
 * @param {IndexedArray} props.services
 * @param {Organization} props.organization
 * @param {Account} props.account
 * @param {Currency} props.currency
 * @param {boolean} props.showAdjustRespondAcceptModal
 * @param {function} props.refreshOrders
 * @param {function} props.refreshAccount
 * @param {function} props.setShowAdjustRespondAcceptModal
 * @param {function} props.setShowAdjustRespondModal
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdjustRespondAcceptModal = props => {
	const stripePromise = useRef(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
	const getSecret = async paymentMethod => {
		try {
			const response = await editOrder(props.order.id, ORDER_BUTTON.ADJUST_RESPOND_ACCEPT,
				{paymentMethod: paymentMethod})
			console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				return response.payload
			}
			return null
		} catch (e) {
			console.log(e)
			return null
		}
	}
	const body =
		<div style={{margin: "2vh", padding: "2vh 0vh", borderRadius: "1vh"}} className={"paralian-theme-level-2"}>
			<Elements stripe={stripePromise.current}>
				<MobileStripeUpdateOrderPaymentMethod parentObject={props.account} currency={props.currency.code}
					hideSaveOption={false} onSuccess={() => {
					props.refreshOrders()
					props.setShowAdjustRespondAcceptModal(false)
					props.setShowAdjustRespondModal(false)
					fadingMessage("payment-method-update-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
				}} refreshAccount={props.refreshAccount} isOrganization={false} getSecret={getSecret}/>
			</Elements>
		</div>
	return <Modal id={`order-${props.order.id}-payment-method-change-modal`} padding={"0vh"}
			title={translate(DICTIONARY.ACCEPT_CHANGES.X)} icon={ICONS.CHECK_DOUBLE}
			onClick={() => props.setShowAdjustRespondAcceptModal(false)} body={body}/>
}

export default MobileAdjustRespondAcceptModal