import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileColor from "./MobileColor"

/**
 *
 * @param {Object} props
 * @param {Colors} props.colors
 * @param {number} props.zIndex
 * @param {string} props.top
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileColors = props => {
	const [expanded, setExpanded] = useState(false)
	const content = !expanded ? <></> : <div id={"colors"} className={"w3-animate-top"}>
		<MobileColor canEdit={props.canEdit} color={props.colors.primary} subtitle={"primary"} refresh={props.refresh}/>
		<MobileColor canEdit={props.canEdit} color={props.colors.secondary} subtitle={"secondary"} refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} id={"colors"} zIndex={props.zIndex} top={props.top}
			text={translate(DICTIONARY.BRAND_COLORS.X)} onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileColors