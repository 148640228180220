import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselEngineHoursEdit from "./MobileVesselEngineHoursEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileVesselEngineHours = props => {
	const [edit, setEdit] = useState(false)
	const [hours, setHours] = useState(props.engine.hours)
	const content = edit ?
		<MobileVesselEngineHoursEdit id={props.id} engine={props.engine} onSuccess={hrs => {
			props.refresh().then()
			setHours(hrs)
			setEdit(false)
		}} /> :
		<div className={"w3-animate-opacity"}><b>{hours ? hours.toLocaleString() : "#"}</b></div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.HOURS.X)]}/>
		{content}
	</div>
}

export default MobileVesselEngineHours