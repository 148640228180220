import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {CardElement} from "@stripe/react-stripe-js"
import {getStripePaymentMethods, newStripeSetupIntent} from "../../../globals/functions/client/serverFunctions"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import getPaymentMethodData from "./getPaymentMethodData"

const submitNewPaymentMethod = async (stripe, elements, setProcessing, billingAddress, billingDetails,
	onSuccess = () => {}, isOrganization = false) => {
	try {
		const siResponse = await newStripeSetupIntent(isOrganization)
		if (siResponse.status !== SERVER_RESPONSE_STATE.SUCCESS) {console.log("setup intent error"); setProcessing(false); return}
		const setupIntent = siResponse.payload
		// console.log("setupIntent", setupIntent)
		if (!setupIntent) {console.log("setup intent error"); setProcessing(false); return}
		const secret =  setupIntent.clientSecret
		// console.log(billingAddress)
		const data = getPaymentMethodData(billingDetails.name, billingAddress, elements.getElement(CardElement))
		// console.log("data", data)
		const result = await stripe.confirmCardSetup(secret, data)
		// console.log("result", result)
		// console.log("pm", result.setupIntent.payment_method)
		if (result.error) {
			newMessage("setupError", translate(DICTIONARY.UNABLE_TO_SAVE_PAYMENT_METHOD.X),
				result.error.message + translate(DICTIONARY.PAYMENT_DECLINED_MESSAGE.X),
				"w3-pale-yellow", ICONS.CREDIT_CARD)
		} else if (result.setupIntent.status === 'succeeded') {
			const response = await getStripePaymentMethods(isOrganization)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				// console.log("to onSuccess: ", response.payload, result.setupIntent.payment_method)
				await onSuccess(response.payload, result.setupIntent.payment_method)
				// console.log("card", response.payload)
				return result.setupIntent.payment_method
			}
		}
		setProcessing(false)
	} catch (e) {
		console.log(e)
	}
}

export default submitNewPaymentMethod