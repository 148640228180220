import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../globals/constants/client/constants"
import {translate} from "../../globals/functions/client/localFunctions"
import {stripeOnboardConnectAccount} from "../../globals/functions/client/serverFunctions"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {string} props.text
 * @returns {JSX.Element}
 * @constructor
 */
const MobileConnectNewAccount = props => {
	const [text, setText] = useState(props.text)
	return <>
		<label htmlFor={props.id} style={{display: "none"}}>{props.text}</label>
		<button id={props.id} className={`w3-display-container w3-center ${props.className}`}
			style={{...props.style, outline: "transparent",
				borderTopLeftRadius: props.topRadius, borderBottomLeftRadius: props.bottomRadius,
				borderTopRightRadius: props.topRadius, borderBottomRightRadius: props.bottomRadius}}
				onClick={() => {
					const element = document.getElementById(props.id)
					element.setAttribute("disabled", "disabled")
					setText(translate(DICTIONARY.OPENING_ELLIPSIS.X))
					stripeOnboardConnectAccount().then(response => {
						// console.log("response", response)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							if (response.payload.url) {
								window.location = response.payload.url
							} else {
								element.removeAttribute("disabled")
								element.textContent = translate(DICTIONARY.SOMETHING_WENT_WRONG.X)
								// console.log("response", response)
							}
						}
					})
				}}>
			<i><b>{text}</b></i>
		</button>
	</>
}

export default MobileConnectNewAccount