import {typeOrNull, enumOrNull, constructArray} from '../../functions/shared/local'
import {ACCOUNT_TYPE, ACCOUNT_STATE} from '../../constants/shared/enumerators'
import Image from './Image'
import Acceptance from './Acceptance'
import Preferences from './Preferences'
import ViewAccess from './ViewAccess'
import Contact from './Contact'
import Documents from './Documents'
import PaymentMethods from './PaymentMethods'
import Rating from './metrics/Rating'
import Schedule from './Schedule'
import Title from './textInputFilters/Title'
import Subscription from "./payments/Subscription"
import Address from "./Address"
/* TODO: privatize with properties_ with # when supported */

class Account {
	/**
	 *
	 * @param {Account} [account = null]
	 * @param {number} [account.id]
	 * @param {string} [account.username]
	 * @param {string} [account.loginEmail]
	 * @param {string} [account.loginPhone]
	 * @param {string} [account.type]
	 * @param {Image} [account.image]
	 * @param {Contact} [account.contact]
	 * @param {Address[]} [account.addresses]
	 * @param {number} [account.rating]
	 * @param {boolean} [account.isVerified]
	 * @param {boolean} [account.isCustomer]
	 * @param {boolean} [account.isParalianVerified]
	 * @param {boolean} [account.isActive]
	 * @param {string} [account.standing]
	 * @param {string} [account.membership]
	 * @param {number} [account.parent]
	 * @param {string} [account.securityPhrase]
	 * @param {number|string|Date} [account.creationDate]
	 * @param {Acceptance} [account.terms]
	 * @param {string} [account.status]
	 * @param {string} [account.qr]
	 * @param {Preferences} [account.preferences]
	 * @param {Object} [account.payment]
	 * @param {ViewAccess} [account.viewAccess]
	 * @param {Verification[]} [account.verifications]
	 * @param {Documents} [account.documents]
	 * @param {Object[]} [account.linkedAccounts]
	 * @param {Subscription} [account.subscription]
	 */
	constructor(account = null) {
		try {
			this.id = typeOrNull(account.id, "number")
			this.username = typeOrNull(account.username, "string")
			this.loginEmail = typeOrNull(account.loginEmail, "string")
			this.loginPhone = typeOrNull(account.loginPhone, "string")
			this.type_ = enumOrNull(account.type || account.type_, ACCOUNT_TYPE)
			this.contact = new Contact(account.contact)
			this.image = new Image(account.image)
			this.addresses = {asArray: constructArray(account.addresses ? account.addresses.asArray : null, item => new Address(item))}
			this.rating = new Rating(account.rating)
			this.isVerified = typeOrNull(account.isVerified, "boolean")
			this.isCustomer = typeOrNull(account.isCustomer, "boolean")
			this.isParalianVerified = typeOrNull(account.isParalianVerified, "boolean")
			this.isActive = typeOrNull(account.isActive, "boolean")
			this.standing = typeOrNull(account.standing, "string")
			this.membership = typeOrNull(account.membership, "string")
			this.parent = typeOrNull(account.parent, "number")
			this.status_ = enumOrNull(account.status || account.status_, ACCOUNT_STATE)
			this.securityPhrase = typeOrNull(account.securityPhrase, "string")
			this.created = typeOrNull(account.created, "string")
			this.terms = new Acceptance(account.terms)
			this.qr = typeOrNull(account.qr, "string")
			this.preferences = new Preferences(account.preferences)
			this.payment = new PaymentMethods(account.payment)
			this.viewAccess = new ViewAccess(account.viewAccess)
			this.verifications = typeOrNull(account.verifications, "array")
			this.documents = new Documents(account.documents)
			this.linkedAccounts = typeOrNull(account.linkedAccounts, "array")
			this.title_ = new Title(account.title || account.title_).filtered
			this.schedule = new Schedule(account.schedule)
			this.employeeSince = typeOrNull(account.employeeSince, "date")
			this.services = typeOrNull(account.services, "array")
			this.subscription = new Subscription(account.subscription)
		} catch (e) {
			this.id = null
			this.username = null
			this.loginEmail = null
			this.loginPhone = null
			this.type_ = null
			this.contact = new Contact()
			this.image = new Image()
			this.addresses = {asArray: []}
			this.rating = new Rating()
			this.isVerified = null
			this.isCustomer = null
			this.isParalianVerified = null
			this.isActive = null
			this.standing = null
			this.membership = null
			this.parent = null
			this.status_ = null
			this.securityPrhase = null
			this.created = null
			this.terms = new Acceptance()
			this.qr = null
			this.preferences = new Preferences()
			this.payment = new PaymentMethods()
			this.viewAccess = new ViewAccess()
			this.verifications = []
			this.documents = new Documents()
			this.linkedAccounts = []
			this.title_ = null
			this.schedule = new Schedule()
			this.employeeSince = null
			this.services = null
			this.subscription = new Subscription()
		}
	}

	get type(){
		return this.type_
	}

	set type(type) {
		this.type_ = enumOrNull(type, ACCOUNT_TYPE)
	}

	get status(){
		return this.status_
	}

	set status(status) {
		this.status_ = enumOrNull(status, ACCOUNT_STATE)
	}

	get title(){
		return this.title_
	}

	set title(title) {
		this.title_ = new Title(title).filtered
	}

	get isMarina() {
		return this.type_ === ACCOUNT_TYPE.MARINA_ADMIN || this.type_ === ACCOUNT_TYPE.MARINA_EMPLOYEE
	}

	get isBusiness() {
		return this.type_ === ACCOUNT_TYPE.BUSINESS_ADMIN || this.type_ === ACCOUNT_TYPE.BUSINESS_EMPLOYEE
	}

	get isAdmin() {
		return this.type_ === ACCOUNT_TYPE.MARINA_ADMIN || this.type_ === ACCOUNT_TYPE.BUSINESS_ADMIN
	}

	get isEmployee() {
		return this.type_ === ACCOUNT_TYPE.MARINA_EMPLOYEE || this.type_ === ACCOUNT_TYPE.BUSINESS_EMPLOYEE
	}

	get isAdminOrEmployee() {
		return this.isAdmin || this.isEmployee
	}

	get isPersonal() {
		return this.type_ === ACCOUNT_TYPE.PERSONAL
	}

}

export default Account