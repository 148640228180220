import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileVesselStorageSpaceAndCapacity from "./MobileVesselStorageSpaceAndCapacity"
import MobileVesselStorageFacilities from "./MobileVesselStorageFacilities"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {React.HTMLProps.style.top} props.top
 * @param {React.HTMLProps.style.zIndex} props.zIndex
 * @param {string} props.units - SI or US
 * @param {Storage} props.facilities
 * @param {marinas} props.marinas
 * @param {VesselHome[]} props.homes
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageSpecs = props => {
	const [expanded, setExpanded] = useState(false)
	const content = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"} style={{fontSize: "2vh"}}>
		<MobileVesselStorageSpaceAndCapacity id={props.id} storage={props.storage} units={props.units}
			canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileVesselStorageFacilities id={props.id} homes={props.homes} marinas={props.marinas}
			canEdit={props.canEdit} refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.VESSEL_STORAGE.X)} top={props.top}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileVesselStorageSpecs