import {getFromStorage} from "../../../globals/functions/client/localFunctions"

const getBillingAddress = () => {
	try {
		const postCode = getFromStorage("postCode", "session")
		return {
			line1: document.getElementById("cc-line1-input").value,
			line2: document.getElementById("cc-line2-input").value,
			city: document.getElementById("cc-city-input").value,
			country: document.getElementById("cc-country-input").getAttribute("data-c"),
			postal_code: postCode,
			state: document.getElementById("cc-state-input").getAttribute("data-id")
		}
	} catch (e) {
		console.log(e)
	}
}

export default getBillingAddress