import React, {useEffect, useState} from "react"
import {ICONS} from "../../../globals/constants/client/constants"
import {DOCUMENT_TYPE, DOCUMENT_CONTAINER, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getCustomers} from "../../../globals/functions/client/serverFunctions"
import MobileProfileImage from "../MobileProfileImage"
import MobileDocuments from "../documents/MobileDocuments"
import StarRating from "../../../globals/components/StarRating"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileMyCustomers from "./MobileMyCustomers"
import MobileOrderHistory from "../orders/MobileOrderHistory"
import Account from "../../../globals/classes/shared/Account"
import Image from "../../../globals/classes/shared/Image"
import placeholder from "../../../images/user.svg"

const MobileCustomers = props => {
	const top = "7vh"
	const [customers, setCustomers] = useState([])
	const [index, setIndex] = useState(0)
	const [showRefreshButton, setShowRefreshButton] = useState(false)
	const customer = new Account(customers.length > 0 ? customers[index] : null)
	useEffect(() => getCustomers(true).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setShowRefreshButton(false)
			setCustomers(response.payload.accounts)
		} else {
			setShowRefreshButton(true)
		}
	}), [props.account.type])
	const refresh = () => getCustomers(false).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setShowRefreshButton(false)
			setCustomers(response.payload)
		}
	})
	if (showRefreshButton) {
		return <div className={"w3-display-middle w3-animate-opacity"}>
			<MobileFontAwesome icon={ICONS.SYNC} style={{fontSize: "15vh"}} onClick={() => {
				getCustomers(false).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.setAccount(new Account(response.payload))
					}
				})
			}}/>
		</div>
	}
	const profile = customers.length > 0 ? <>
		{/* Profile Image */}
		<MobileProfileImage image={customer.image.path ? customer.image :
			new Image({path: "https://paralian.app/images/user.svg"})} id={customer.id}
			placeholder={placeholder} upload={{include: false, onClick: () => {}}}/>
		{/* Username and Account Type */}
		<div style={{zIndex: "20", padding: "1vh 0vh", top: "0.5vh", height: "7vh", fontSize: "2vh"}}
				 className={"sticky paralian-theme-level-1"}>
			<i style={{margin: "1vh 0vh"}} translate={"no"}>
				{customer.username}
			</i>
		</div>
		{/* Name */}
		<div style={{fontSize: "4vh", padding: "0vh 1vh 1vh 1vh"}} translate={"no"}>
			{customer.contact.name.given}
		</div>
		{/* Rating */}
		<div style={{fontSize: "3vh", marginBottom: "1vh"}}>
			<StarRating rating={customer.rating.overall} style={{padding: "0vh 1vh"}}/>
		</div>
		{/* Documents */}
		<MobileDocuments documents={customer.documents.contracts} container={DOCUMENT_CONTAINER.ORGANIZATIONS}
			docType={DOCUMENT_TYPE.CONTRACTS} zIndex={8} top={top} canEdit={false}/>
		{/* Order History */}
		<MobileOrderHistory/>
	</> : <></>
	return <>
		<div id={"customers-container"} className={"w3-display-middle w3-animate-opacity paralian-mobile-content " +
			"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}>
			{profile}
			{/* TODO: divide customers into individuals/organizations */}
			<MobileMyCustomers customers={customers} zIndex={12}
			 	top={customers.length > 0 ? top : "0vh"} refresh={refresh} canInvite={true} setIndex={id => {
				const index_ = customers.findIndex(vessel => vessel.id === id)
				setIndex(index_ !== -1 ? index_ : index)
			}}/>
		</div>
	</>

}

export default MobileCustomers