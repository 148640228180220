import { typeOrNull } from '../../../functions/shared/local'

class Storage {
	/**
	*
	* @param {Storage} [storage = null]
	* @param {number} [storage.draught]
	* @param {number} [storage.height]
	* @param {number} [storage.length]
	* @param {number} [storage.weight]
	* @param {number} [storage.width]
	* @param {number} [storage.clearance]
	*/
	constructor (storage = null) {
		try {
			this.draught = typeOrNull(storage.draught, "number")
			this.height = typeOrNull(storage.height, "number")
			this.length = typeOrNull(storage.length, "number")
			this.weight = typeOrNull(storage.weight, "number")
			this.width = typeOrNull(storage.width, "number")
			this.clearance = typeOrNull(storage.clearance, "number")
		} catch (e) {
			this.draught = null
			this.height = null
			this.length = null
			this.weight = null
			this.width = null
			this.clearance = null
		}
	}
}

export default Storage