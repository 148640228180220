import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {RECURRENCE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileRecurrenceDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const onChange = props.onChange ? props.onChange : () => {}
	let list = [
		{value: RECURRENCE.DAILY, label: translate(DICTIONARY.DAYS.X)},
		{value: RECURRENCE.WEEKLY, label: translate(DICTIONARY.WEEKS.X)},
		{value: RECURRENCE.MONTHLY, label: translate(DICTIONARY.MONTHS.X)},
		{value: RECURRENCE.YEARLY, label: translate(DICTIONARY.YEARS.X)}
	]
	try {
		list = list.filter(i => !props.exclude.includes(i.value))
	} catch (e) {
		console.log(e)
	}
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}`} className={className} size={"1"} showLabel={false}
		label={translate(DICTIONARY.DATE.X)} multiple={false} dataId={props.defaultValue}
		name={"date"} form={props.form} autoFocus={false} required={true}
		list={list} left={props.left}	defaultValue={props.defaultValue}
		bottomRadius={props.bottomRadius} dataA={props.defaultValue} onChange={() => {
		const element = document.getElementById(`${props.id}-input`)
		element.setAttribute("data-a", element.value)
		onChange(element.value)
	}}/>

}

export default MobileRecurrenceDropdown