import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getMarinaStorageUnits} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDatalist from "../../../shared/input/datalist/MobileInputDatalist"

const MobileInputMarinaVSUnitsDatalist = props => {
	const [list, setList] = useState([])
	useEffect(() => {
		if (props.marina && props.marina !== -1 && props.facility) {
			getMarinaStorageUnits(props.marina, props.facility).then(response => {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					setList(response.payload.map(unit => {return {code: unit.id, label: unit.name}}))
				}
			}).catch(reason => console.log(reason))
		}
	}, [props.marina, props.facility])
	const onChange = props.onChange ? props.onChange : () => {}
	return <MobileInputDatalist id={props.id} className={props.className} style={props.style} list={list}
		label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} name={props.name} form={props.form} translate={"no"}
		pattern={null} autoFocus={false} maxLength={"120"} required={props.required} placeholder={props.placeholder}
		defaultValue={props.defaultValue} autoComplete={"off"} dataId={"-1"} dataA={"text"} onChange={() => {
			try {
				const field = document.getElementById(`${props.id}-input`)
				const index = list.findIndex(item => item.label === field.value)
				if (index !== -1) {
					const option = document.getElementById(`${props.id}-${field.value}`)
					field.setAttribute("data-id", option.getAttribute("data-a"))
					field.setAttribute("data-a", "id")
				} else {
					field.setAttribute("data-id", "-1")
					field.setAttribute("data-a", "text")
				}
			} catch (e) {
				console.log(e)
			}
			onChange()
	}}/>
}

export default MobileInputMarinaVSUnitsDatalist