import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {ORGANIZATION_STRUCTURE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {function} props.onChange
 * @param {boolean} props.showLabel
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {function} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @returns {JSX.Element}
 * @constructor
 */
const MobileBusinessStructureDropdown = props => {
	const onChange = props.onChange ? props.onChange : () => {}
	const list = Object.values(ORGANIZATION_STRUCTURE).map(structure => {return {label: translate(structure), value: structure}})

	return <MobileInputGeneric type={"dropdown"} id={`${props.id}`} className={"paralian-theme-level-1-flipped"}
		size={"1"} showLabel={props.showLabel} label={translate(DICTIONARY.BUSINESS_STRUCTURE.X)} multiple={false}
		topRadius={props.topRadius}	name={"vessel"} form={props.form} autoFocus={false} required={true} list={list}
		left={props.left}	defaultValue={list[0].value} bottomRadius={props.bottomRadius} onChange={onChange}/>
}

export default MobileBusinessStructureDropdown