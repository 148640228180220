import Week from './Week';
import Timezone from './Timezone';
import ItemId from './textInputFilters/ItemId';

class Schedule {
	/**
	 *
	 * @param {Schedule} [schedule = null]
	 * @param {number} [schedule.id]
	 * @param {Week} [schedule.week]
	 * @param {Timezone} [schedule.timezone]
	 */
	constructor(schedule = null) {
		try {
			this.id = new ItemId(schedule.id).number
			this.week = new Week(schedule.week)
			this.timezone = new Timezone(schedule.timezone)
		} catch (e) {
			this.id = null
			this.week = new Week()
			this.timezone = new Timezone()
		}
	}
}

export default Schedule;