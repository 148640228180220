import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {setStorageRequirements} from "../../../../globals/functions/client/serverFunctions"
import {convertToPreferredUnits, parsedLengthToMeters, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputWeight from "../MobileInputWeight"
import MobileInputDistance from "../MobileInputDistance"
import MobileSubtitleAndContent from "../../MobileSubtitleAndContent"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {Storage} props.facilities
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageSpaceAndCapacityEdit = props => {
const onSubmit = async event => {
	event.preventDefault()
	let lengthFraction = 0, widthFraction = 0, draughtFraction = 0, heightFraction = 0
	let weight = Number(`${document.getElementById("sc-weight-whole-input").value}`.replace(/[,.]/g,''))
	const lengthWhole = Number(document.getElementById("sc-length-whole-input").value)
	try {
		lengthFraction = Number(document.getElementById("sc-length-fraction-input").value)
	} catch (e) {}
	const widthWhole = Number(document.getElementById("sc-width-whole-input").value)
	try {
		widthFraction = Number(document.getElementById("sc-width-fraction-input").value)
	} catch (e) {}
	const draughtWhole = Number(document.getElementById("sc-draught-whole-input").value)
	try {
		draughtFraction = Number(document.getElementById("sc-draught-fraction-input").value)
	} catch (e) {}
	const heightWhole = Number(document.getElementById("sc-height-whole-input").value)
	try {
		heightFraction = Number(document.getElementById("sc-height-fraction-input").value)
	} catch (e) {}
	const length = props.units === "SI" ? lengthWhole : parsedLengthToMeters(lengthWhole, lengthFraction, props.units)
	const width = props.units === "SI" ? widthWhole : parsedLengthToMeters(widthWhole, widthFraction, props.units)
	const draught = props.units === "SI" ? draughtWhole : parsedLengthToMeters(draughtWhole, draughtFraction, props.units)
	const height = props.units === "SI" ? heightWhole : parsedLengthToMeters(heightWhole, heightFraction, props.units)
	if (props.units !== "SI") {
		weight = Number(convertToPreferredUnits(weight, "lb", "SI", 6, false, false))
	}
	const response = await setStorageRequirements(props.id, weight, length, width, draught, height)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess({weight: weight, length: length, width: width, draught: draught, height: height})
		}
	}
	const weightInput = <MobileInputWeight id={"sc-weight"} units={props.units} form={"facilities-space-form"}
		label={translate(DICTIONARY.WEIGHT.X)} value={props.storage.weight}/>
	const lengthInput = <MobileInputDistance id={"sc-length"} units={props.units} form={"facilities-space-form"}
		label={translate(DICTIONARY.LENGTH.X)} value={props.storage.length}/>
	const widthInput = <MobileInputDistance id={"sc-width"} units={props.units} form={"facilities-space-form"}
		label={translate(DICTIONARY.WIDTH.X)} value={props.storage.width}/>
	const heightInput = <MobileInputDistance id={"sc-height"} units={props.units} form={"facilities-space-form"}
		label={translate(DICTIONARY.CLEARANCE.X)} value={props.storage.height}/>
	const draughtInput = <MobileInputDistance id={"sc-draught"} units={props.units} form={"facilities-space-form"}
		label={translate(DICTIONARY.DRAUGHT.X)} value={props.storage.draught}/>
	const content = <>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.WEIGHT.X)}
			content={weightInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.LENGTH.X)}
			content={lengthInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.WIDTH.X)}
			content={widthInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.CLEARANCE.X)}
			content={heightInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.DRAUGHT.X)}
			content={draughtInput}/>
	</>
	return <MobileInputForm id={`storage-space-form`} content={content} className={" "} disableAnimateOpacity={true}
		onSubmit={onSubmit}/>
}

export default MobileVesselStorageSpaceAndCapacityEdit