import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"
/**
 *
 * @param {object} props
 * @param {React.HTMLAttributes.style} props.style - style object of container's top level element
 * @param {React.HTMLAttributes.className} props.className - className(s) of container's top level element
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.label
 * @param {boolean} props.showLabel
 * @param {string} props.name
 * @param {boolean} props.autoFocus
 * @param {string} props.maxLength
 * @param {string} props.autoComplete
 * @param {string} props.placeholder
 * @param {string} props.defaultValue
 * @param {string} props.invalidMessage
 * @param {string} props.title
 * @param {boolean} props.required
 * @param {boolean} props.topRadius
 * @param {boolean} props.bottomRadius
 * @param {string} props.pattern - regex input filtering pattern
 * @param {Object} props.left
 * @param {string[]} props.left.icon - FontAwesome icon classname
 * @param {function} props.left.onClick
 * @param {Object} props.right
 * @param {string[]} props.right.icon - FontAwesome icon classname
 * @param {function} props.right.onClick
 * @param {function} props.onChange
 * @param {string} props.inputMode
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputText = props => <MobileInputGeneric type={"text"} id={props.id} name={props.name} label={props.label}
	showLabel={props.showLabel}	className={props.className} bottomRadius={props.bottomRadius} left={props.left}
	right={props.right} form={props.form} autoComplete={props.autoComplete} pattern={props.pattern}
	required={props.required} defaultValue={props.defaultValue} placeholder={props.placeholder} style={props.style}
	invalidMessage={props.invalidMessage} topRadius={props.topRadius}	maxLength={props.maxLength}
	autoFocus={props.autoFocus} onChange={props.onChange} title={props.title} inputMode={props.inputMode}/>

export default MobileInputText