import React from "react"
import upload from "../../../images/upload-duotone.svg"
import Image from "../../../globals/classes/shared/Image"
/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {function} props.onClick
 * @param {Image} props.image
 * @param {string} props.alt
 * @param {string} props.size
 * @param {string} props.radius
 * @param {string} props.placeholder - placeholder url
 * @param {boolean} props.showUpload
 * @return {JSX.Element}
 * @constructor
 */
const MobileImageOverlay = props => {
	const image = new Image(props.image)
	const path = image ? image.path : props.showUpload ? upload : null
	const num = Number(props.size.replace(/[^0-9.]/gi, ""))
	const unit = props.size.replace(/[0-9.]/gi, "")
	const margin = `${image.path ? 0 : 0.25*num}${unit}`
	const padding = `${0.05*num}${unit}`
	const size = `${image.path ? num : 0.5*num}${unit}`
	const pictureSize = `${1.1*num}${unit}`
	return <div style={{borderRadius: props.radius || "50%", padding: padding,
			minHeight: pictureSize, minWidth: pictureSize, maxHeight: pictureSize, maxWidth: pictureSize, ...props.style}}
		className={props.className} onClick={props.onClick}>
		<picture>
			<img id={`${props.id}-img`} src={path} alt={props.alt} style={{minHeight: size, minWidth: size, height: size, width: size,
				margin: margin, backgroundColor: "white", padding: padding, borderRadius: image.path ?
					props.radius || "50%" : "0vh", objectFit: "contain"}}
			 	onError={() => document.getElementById(`${props.id}-img`).src = props.placeholder}/>
		</picture>
	</div>
}

export default MobileImageOverlay