import React from "react"
import {store} from "../../index"
import {actions} from "../../globals/constants/client/reduxData"
import MobileLoginFormScaffolding from "../login/frames/MobileLoginFormScaffolding"

/**
 *
 * @param {Object} props
 * @param {Object} props.next - page object
 * @param {Object} props.back - page object
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.message
 * @returns {JSX.Element}
 * @constructor
 */
const MobileMessagePage = props => {
	const onSubmit = event => {
		event.preventDefault()
		store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
	}
	const content =
		<div className={props.className} style={{fontSize: "3vh", ...props.style}}>
			{props.message}
		</div>
	return <MobileLoginFormScaffolding header={null} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileMessagePage