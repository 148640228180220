import Color from './Color';

class Colors {
	/**
	 *
	 * @param {Colors} [colors = null]
	 * @param {Color} [colors.primary]
	 * @param {Color} [colors.secondary]
	 */
	constructor(colors = null) {
		try {
			this.primary = new Color(colors.primary)
			this.secondary = new Color(colors.secondary)
		} catch (e) {
			this.primary = new Color()
			this.secondary = new Color()
		}
	}

	isEmpty() {
		return [this.primary, this.secondary].every(element => element.isEmpty())
	}

}

export default Colors;