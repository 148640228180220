import React, {useEffect} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {useState} from "react"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {evalConstraints} from "../../../../globals/functions/shared/local"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
import MobileMultipleInputSelectModal from "./MobileMultipleInputSelectModal"
import Modal from "../../../../globals/components/Modal"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.label
 * @param {Object[]} props.list
 * @param {number} props.list.id
 * @param {string} props.list.label
 * @param {Constraints} props.list.constraints
 * @param {number[]} props.selected
 * @param {Object[]} props.items
 * @param {number} props.items.id
 * @param {string} props.items.label
 * @param {Constraints} props.items.constraints
 * @param {function} props.setSelected
 * @param {boolean} props.ignoreExcludes
 * @constructor
 */
const MobileMultipleSelect = props => {
	const [expanded, setExpanded] = useState(false)
	const [modal, setModal] = useState(<></>)
	const [showModal, setShowModal] = useState(false)
	const [selected, setSelected] = useState([])
	// console.log("selected", selected)
	const preSelected = props.selected || []
	// const selected = props.selected.filter(value => props.list.some(item => item.id === value))
	const itemsIndexedArray = new IndexedArray({array: props.items, key: "id"})
	const evalSelectedConstraints = (_selected_, ignoreExcludes) => {
		try {
			for (let i = 0; i < _selected_.length; i++) {
				const constraints = evalConstraints(itemsIndexedArray.byIndex(_selected_[i]), _selected_, ignoreExcludes)
				// console.log("constraints", constraints)
				if (constraints[0].length > 0 || constraints[1].length > 0) {
					setModal(<MobileMultipleInputSelectModal constraints={constraints} version={1}
					setSelected={setSelected} selected={_selected_} action={{wasSelected: null, id: null}}
					indexedArray={itemsIndexedArray} setShowModal={setShowModal}/>)
					setShowModal(true)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	useEffect(() => {
			if (!props.ignoreConstraints) {evalSelectedConstraints(preSelected, props.ignoreExcludes)}
		},
		[props.selected, props.items, props.ignoreExcludes, props.ignoreConstraints])
	useEffect(() => {
		if (!props.ignoreConstraints) {evalSelectedConstraints(selected, props.ignoreExcludes)}
		},
		[selected, props.items, props.ignoreExcludes, props.ignoreConstraints])
	const label = (props.label ? `${props.label}: ` : "") + (selected.length === 0 ?
		translate(DICTIONARY.NONE.X) : `(${selected.length}${props.label ? "" : " " + translate(DICTIONARY.SELECTED.X)})`)
	const expander = <div id={`multiple-select-${props.id}`} data-a={selected.join(",")}
		style={{height: "6vh", width: "100%", display: "inline-flex", cursor: "pointer"}}
		className={"paralian-theme-level-2 w3-ripple"} onClick={() => setExpanded(!expanded)}>
		<div style={{width: "10%"}}/>
		<div style={{width: "80%", margin: "auto"}}>{label}</div>
		<div style={{width: "10%", margin: "auto"}}>
			<MobileFontAwesome icon={expanded ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN}
				label={expanded ? translate(DICTIONARY.RETRACT.X) :
				translate(DICTIONARY.EXPAND.X)}/>
		</div>
	</div>
	const body = props.list.map(item => {
		const id = `${props.label}-${item.id}`
		const included = selected.includes(item.id)
		return <div key={id} id={id} style={{height: "6vh", width: "100%", paddingLeft: "2vh", display: "inline-flex", cursor: "pointer"}}
			className={"w3-ripple " + (included ? "paralian-theme-level-2-flipped" : "paralian-theme-level-2")}
				onClick={() => setSelected(included ? selected.filter(value => value !== item.id) :
					[...selected, item.id])}>
			<span style={{margin: "auto 0vh"}}>
				{included ? <MobileFontAwesome icon={ICONS.CHECK} style={{paddingRight: "1.5vh"}}/> : <></>}
				{translate(item.label)}
			</span>
		</div>
	})
	return <>
		{showModal ? modal : <></>}
		{expander}
		{expanded ? <Modal id={`multiple-select-${props.id}-main-modal`} title={props.label} padding={"0vh"}
			onClick={() => setExpanded(false)} body={
			<div style={{margin: "2vh"}}>{body}</div>
		}/> : <></>}
	</>
}

export default MobileMultipleSelect