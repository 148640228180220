import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../../../globals/constants/client/constants"
import {getFromStorage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import RadioButton from "./RadioButton"
const InteriorCleaningType = props => {
	const currentState = store.getState()
	const levelOne = currentState.window.current.levelOne
	const parentPage = PAGE_STATES[levelOne].MAIN.REQUESTS
	const [selected, setSelected] = useState(null)
	// console.log(selected)
	const onClick = id => {
		// console.log(id)
		setSelected(id)
		if (props.onClick) {props.onClick(id)}
	}
	useEffect(() => {
		const session = getFromStorage("order", "session")
		const services = session.servicesInterior
		if (!services || services.length === 0) {
			setSelected("clean")
		} else if (services.some(v => v.id === -1)) {
			setSelected("interior-none")
		} else if (services.some(v => v.id === 1011)) {
			setSelected("housekeeping")
		} else {
			setSelected("clean")
		}
	}, [])
	return <>
		<RadioButton id={"clean"} title={translate(DICTIONARY.CLEANING.X)} onClick={onClick} selected={selected === "clean"}
			defaultChecked={selected === "clean"} name={"interiorCleaningType"}/>
		<RadioButton id={"housekeeping"} title={translate(DICTIONARY.HOUSEKEEPING.X)}
			subtitle={translate(DICTIONARY.DISHES_LAUNDRY.X)} onClick={onClick}
			selected={selected === "housekeeping"} name={"interiorCleaningType"} disabled={true}
			onClickDisabled={() => newMessage("ist-carpet-clean-disabled",
				translate(DICTIONARY.COMING_SOON.X), translate(DICTIONARY.HOUSEKEEPING_COMING_SOON_MESSAGE.X), "w3-pale-blue",
				ICONS.INFO_CIRCLE, [{id: "concierge-houskeeping", icon: null, text: translate(DICTIONARY.VISIT_CONCIERGE.X),
					onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: parentPage.CONCIERGE.X}})}])}
			defaultChecked={selected === "housekeeping"}/>
		<RadioButton id={"carpetClean"} title={translate(DICTIONARY.CARPET_DEEP_LEAN.X)} onClick={onClick}
			selected={selected === "carpetClean"}	name={"interiorCleaningType"} disabled={true}
			onClickDisabled={() => newMessage("ist-carpet-clean-disabled",
				translate(DICTIONARY.COMING_SOON.X), translate(DICTIONARY.CARPET_DEEP_LEAN_COMING_SOON_MESSAGE.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE, [{id: "concierge-houskeeping", icon: null,
					text: translate(DICTIONARY.VISIT_CONCIERGE.X),
				onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: parentPage.CONCIERGE.X}})}])}
			defaultChecked={selected === "carpetClean"}/>
		<RadioButton id={"interior-none"} title={translate(DICTIONARY.NONE.X)} onClick={onClick}
			selected={selected === "interior-none"}	name={"interiorCleaningType"} defaultChecked={selected === "interior-none"}/>
	</>
}

export default InteriorCleaningType