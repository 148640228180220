import React from "react"
import MobileVesselEngineLabel from "./MobileVesselEngineLabel"
import MobileVesselEngineBMY from "./MobileVesselEngineBMY"
import MobileVesselEngineSN from "./MobileVesselEngineSN"
import MobileVesselEngineMN from "./MobileVesselEngineMN"
import MobileVesselEngineSpecs from "./MobileVesselEngineSpecs"
import MobileVesselEngineHours from "./MobileVesselEngineHours"

const MobileEngine = props => {
	return <>
		<MobileVesselEngineLabel id={props.id} engine={props.engine} canEdit={props.canEdit} refresh={props.refresh}
			top={props.top} index={props.index} zIndex={props.zIndex} sticky={props.sticky} onDelete={props.onDelete}/>
		<MobileVesselEngineBMY id={props.id} engine={props.engine} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileVesselEngineHours id={props.id} engine={props.engine} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileVesselEngineSN id={props.id} engine={props.engine} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileVesselEngineMN id={props.id} engine={props.engine} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileVesselEngineSpecs id={props.id} engine={props.engine} canEdit={props.canEdit} refresh={props.refresh}
			units={props.units}/>
		{/* TODO: Warranty documents - after v.1.0 */}
	</>
}

export default MobileEngine