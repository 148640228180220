import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"
/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.showLabel {boolean}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.autoComplete {string}
 * @param props.placeholder {string}
 * @param props.defaultValue {string}
 * @param props.required {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.left {Object}
 * @param props.left.icon {string} - FontAwesome icon classname
 * @param props.left.onClick {Object} - function
 * @param props.right {Object}
 * @param props.right.icon {string} - FontAwesome icon classname
 * @param props.right.onClick {Object} - function
 * @returns {*}
 * @constructor
 */
const MobileInputEmail = props => {
	return <MobileInputGeneric type={"email"} id={`${props.id}-email`} name={props.name} label={props.label}
		className={props.className} style={props.style} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} autoComplete={props.autoComplete} showLabel={props.showLabel}
		required={props.required} defaultValue={props.defaultValue} placeholder={props.placeholder} form={props.form}
		autoFocus={props.autoFocus}/>
}

export default MobileInputEmail