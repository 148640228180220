import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {setRigForetriangle} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDistance from "../MobileInputDistance"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileSubtitleAndContent from "../../MobileSubtitleAndContent"
import convert from "convert-units"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {Rig} props.rig
 * @param {string} props.units - SI or US
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileRigForetriangleEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		let i, j
		if (props.units !== "SI") {
			i = convert(Number(document.getElementById("i-whole-input").value)+
				Number(document.getElementById("i-fraction-input").value)/12).from("ft").to("m")
			j = convert(Number(document.getElementById("j-whole-input").value)+
				Number(document.getElementById("j-fraction-input").value)/12).from("ft").to("m")
		} else {
			i = document.getElementById("i-whole-input").value
			j = document.getElementById("j-whole-input").value
		}
		const response = await setRigForetriangle(props.id, Number(i), Number(j))
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess(i, j)
		}
	}
	// console.log(props.rig)
	const iInput = <MobileInputDistance id={"i"} label={`I (${translate(DICTIONARY.HEIGHT.X)})`}
		form={`${props.rig.id}-rig-foretriangle-form`} units={props.units} value={props.rig.I} displayDigits={3}/>
	const jInput = <MobileInputDistance id={"j"} label={`J (${translate(DICTIONARY.BASE.X)})`}
		form={`${props.rig.id}-rig-foretriangle-form`} units={props.units} value={props.rig.J} displayDigits={3}/>
	const content = <>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={`I (${translate(DICTIONARY.HEIGHT.X)})`}
			content={iInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={`J (${translate(DICTIONARY.BASE.X)})`}
			content={jInput}/>
	</>
	return <MobileInputForm id={`${props.rig.id}-rig-foretriangle`} content={content} className={" "}
		disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileRigForetriangleEdit