import {typeOrNull} from "../../../functions/shared/local"

class UnitPrice {
	/**
	 *
	 * @param {UnitPrice} [unitPrice = null]
	 * @param {number} [unitPrice.firstUnit]
	 * @param {number} [unitPrice.lastUnit]
	 * @param {number} [unitPrice.perUnit] - in smallest currency unit (cents for USD)
	 * @param {number} [unitPrice.flatFee]
	 * @param {number} [unitPrice.packageSize]
	 */
	constructor (unitPrice = null) {
		try {
			this.firstUnit = typeOrNull(unitPrice.firstUnit, "number")
			this.lastUnit = typeOrNull(unitPrice.lastUnit, "number")
			this.perUnit = typeOrNull(unitPrice.perUnit, "number")
			this.flatFee = typeOrNull(unitPrice.flatFee, "number")
			this.packageSize = typeOrNull(unitPrice.packageSize, "number")
		} catch (e) {
			this.firstUnit = null
			this.lastUnit = null
			this.perUnit = null
			this.flatFee = null
			this.packageSize = null
		}
	}

	/**
	 * provides per unit price in base denomination (dollars for USD)
	 * @return {number|null}
	 */
	get currencyPerUnit() {
		try {
			return this.perUnit/100
		} catch (e) {
			return null
		}
	}

	/**
	 * sets perUnit value taking base denomination as input (dollars in USD)
	 */
	set currencyPerUnit(perUnit) {
		try {
			this.perUnit = perUnit*100
		} catch (e) {
			this.perUnit = null
		}
	}

	/**
	 * provides per unit price in smallest denomination (cents for USD)
	 * @return {number}
	 */
	get centsPerUnit() {
		return this.perUnit
	}

	/**
	 * sets perUnit value taking smallest denomination as input (cents in USD)
	 */
	set centsPerUnit(perUnit) {
		this.perUnit = typeOrNull(perUnit, "number")
	}

	/**
	 *
	 * @param {string} currency - ISO Code
	 * @return {string|*}
	 */
	perUnitFormatted(currency = "USD") {
		try {
			return this.currencyPerUnit.toLocaleString(undefined, {style: "currency", currency: currency, maximumFractionDigits: 2})
		} catch (e) {
			return ""
		}
	}

}

export default UnitPrice