import React from "react"
import MobilePricingFields from "../pricing/MobilePricingFields"
import MobileServiceEdit from "./MobileServiceEdit"

/**
 *
 * @param {Object} props
 * @param {boolean} props.edit
 * @param {Servicio} props.service
 * @param {Currency} props.currency
 * @param {IndexedArray} props.defaultPricingStructures
 * @param {function} props.onUndo
 * @param {function} props.setService
 * @param {function} props.setStatus
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileServiceToggle = props => {
	return props.edit ?
		<MobileServiceEdit service={props.service} setPricing={props.setService} setStatus={props.setStatus}
			currency={props.currency} defaultPricingStructures={props.defaultPricingStructures} onSubmit={props.onSubmit}
			onUndo={props.onUndo}/> :
		<MobilePricingFields pricing={props.service.pricing} style={{padding: "1vh"}}/>
}

export default MobileServiceToggle