import { typeOrNull, enumArray } from '../../functions/shared/local';
import { COUNTRY_DIVISION } from '../../constants/shared/enumerators';
import Currency from './Currency';
import ISO2 from './textInputFilters/ISO2';
import ISO3 from './textInputFilters/ISO3';
import PhoneCountryCode from './textInputFilters/PhoneCountryCode';

class Country {
	/**
	 *
	 * @param {Country} [country = null]
	 * @param {string} [country.name]
	 * @param {string} [country.code] - ISO 3166 3-letter code
	 * @param {string} [country.code2] - ISO 2-letter code
	 * @param {Currency} [country.currency]
	 * @param {string} [country.parent]
	 * @param {string|number} [country.phoneCountryCode]
	 * @param {string[]} [country.divisions]
	 */
	constructor (country = null) {
		try {
			this.name = typeOrNull(country.name, "string")
			this.code = new ISO3(country.code).filtered
			this.code2 = new ISO2(country.code2).filtered
			this.currency = new Currency(country.currency)
			this.parent = new ISO3(country.parent).filtered
			this.phoneCountryCode = new PhoneCountryCode(country.phoneCountryCode).number
			this.divisions = enumArray(country.divisions, COUNTRY_DIVISION)
		} catch (e) {
			this.name = null
			this.code = null
			this.code2 = null
			this.currency = new Currency()
			this.parent = null
			this.phoneCountryCode = null
			this.divisions = []
		}
	}

	get translatedName() {
		const translator = new Intl.DisplayNames(undefined, { type: 'region' })
		return this.code2 ? translator.of(this.code2) : ""
	}

	get countryCodeLabel() {
		return this.phoneCountryCode && this.code ? `+ ${this.phoneCountryCode} (${this.code})` : null
	}

}

export default Country;