import {PRICING_STRUCTURE_UNITS} from "../../../globals/constants/shared/enumerators"
import Units from "../../../globals/classes/client/Units"
import Pricing from "../../../globals/classes/shared/Pricing"

const getServicePricingInput = (service, currency, defaultPricingStructures) => {
	const id = service.id
	const ps = defaultPricingStructures ? defaultPricingStructures.byIndex(service.defaultPricingStructure) : null
	const psUnit = ps ? ps.unit : PRICING_STRUCTURE_UNITS.METER
	const UNITS = new Units()
	const isComposite = document.getElementById(`${id}-isComposite`).getAttribute("data-is-checked") === "true"
	if (!isComposite) {
		const price = Number(document.getElementById(`${id}-price-input`).value)
		const unit = document.getElementById(`${id}-unit-input`).getAttribute("data-b")
		const floor = Number(document.getElementById(`${id}-min-input`).value)
		return {
			service: id,
			type: "BASIC",
			message: null,
			pricing: new Pricing({
				unit: psUnit,
				price: currency.asZeroDecimal(UNITS.convert(price, psUnit, unit, true)),
				floor: currency.asZeroDecimal(floor),
			})
		}
	} else {
		return {
			service: id,
			type: "COMPOSITE",
			message: document.getElementById(`${id}-formula`).value,
			pricing: new Pricing()
		}
	}
}

export default getServicePricingInput