import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.digits {number}
 * @param props.decimals {number}
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.defaultLong {string}
 * @param props.defaultDecimal {string}
 * @param props.required {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.onChange {Object} - function
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputDecimal = props => {
	/* TODO: look into why label had "- Whole" added to it */
	const type = props.decimals > 0 ? "number" : "long"
	const pattern = props.decimals > 0 ? `([0-9]){1,${props.digits}}` : `([0-9,.]){1,${props.digits}}`
	return <div style={{display: "inline-flex", width: "100%"}}>
		<MobileInputGeneric type={type} id={`${props.id}-whole`} name={`${props.name}-whole`} form={props.form}
			autoFocus={props.autoFocus} maxLength={`${props.digits}`} autoComplete={"off"} pattern={pattern}
			required={props.required} placeholder={"#".repeat(3)} min={"0"} max={"9".repeat(props.digits)}
			step={type === "long" ? "1" : `0.${"0".repeat(props.decimals - 1)}1`}
			defaultValue={props.defaultLong} // label={`${props.label} - ${translate(DICTIONARY.WHOLE.X)}`}
			onChange={props.onChange} label={props.label} units={props.units === "l" ? "L" : props.units}
			showLabel={props.showLabel} className={props.className} bottomRadius={props.bottomRadius}
			style={{textAlign: type === "long" ? "center" : "right", ...props.style}}
			topRadius={props.topRadius} width={"100%"}/>
		{/*decimals*/}
		{
			/*
			props.units ?
			<b style={{margin: "auto", paddingRight: "2vh"}}>{props.units === "l" ? "L" : props.units}</b> : <></>
			*/
		}
	</div>
}

export default MobileInputDecimal