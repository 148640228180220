import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {requestDateOfBirthUpdate} from "../../../globals/functions/client/serverFunctions"
import {dateToYYYYMMDD, getDateDelta, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputDate from "../../shared/input/text/MobileInputDate"
import Birthdate from "../../../globals/classes/shared/Birthdate"
/**
 *
 * @param {Object} props
 * @param {function} props.refresh
 * @param {function} props.setDateOfBirth
 * @param {function} props.setEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputDateOfBirthEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		try {
			// console.log(document.getElementById("dateOfBirth-input").value)
			const response = await requestDateOfBirthUpdate(document.getElementById("dateOfBirth-input").value)
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.refresh().then()
				props.setDateOfBirth(new Birthdate(response.payload))
				props.setEdit(false)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <MobileInputDate id={`dateOfBirth`} required={true} name={"dateOfBirth"} autoComplete={"bday"}
		min={"1900-01-01"} max={dateToYYYYMMDD(getDateDelta(-12, 1, 0))} className={"paralian-theme-level-1"}
		label={translate(DICTIONARY.DATE_OF_BIRTH.X)} showLabel={false} form={"accountDOB-form"} style={{marginTop: "1vh"}}/>
	return <MobileInputForm id={"accountDOB"} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileInputDateOfBirthEdit