import {PRICING_STRUCTURE_UNITS, UNIT_SYSTEM} from "../../constants/shared/enumerators"
import {translate} from "../../functions/shared/local"
import convert from "convert-units"
const UNITS = Object.freeze({
	HOUR: {
		SI: {
			SHORT: "hr",
			CAPS: "HOUR"
		},
		US: {
			SHORT: "hr",
			CAPS: "HOUR"
		}
	},
	METER: {
		SI: {
			SHORT: "m",
			CAPS: "METER"
		},
		US: {
			SHORT: "ft",
			CAPS: "FOOT"
		}
	},
	SQUARE_METER: {
		SI: {
			SHORT: "m2",
			CAPS: "SQUARE_METER"
		},
		US: {
			SHORT: "ft2",
			CAPS: "SQUARE_FOOT"
		}
	},
	CUBIC_METER: {
		SI: {
			SHORT: "m3",
			CAPS: "CUBIC_METER"
		},
		US: {
			SHORT: "ft3",
			CAPS: "CUBIC_FOOT"
		}
	},
	LITER: {
		SI: {
			SHORT: "l",
			CAPS: "LITER"
		},
		US: {
			SHORT: "gal",
			CAPS: "GALLON"
		}
	},
	KILOGRAM: {
		SI: {
			SHORT: "kg",
			CAPS: "KILOGRAM"
		},
		US: {
			SHORT: "lb",
			CAPS: "POUND"
		}
	},
	KILOWATT: {
		SI: {
			SHORT: "kW",
			CAPS: "KILOWATT"
		},
		US: {
			SHORT: "hp",
			CAPS: "HORSEPOWER"
		}
	},
	UNIT: {
		SI: {
			SHORT: "ea",
			CAPS: "UNIT"
		},
		US: {
			SHORT: "ea",
			CAPS: "UNIT"
		}
	}
})

class Units {

	/**
	 *
	 * @param {string} type - PRICING_STRUCTURE_UNITS
	 * @return {{SI: {SHORT: string, CAPS: string}, US: {SHORT: string, CAPS: string}}}
	 */
	ofType(type) {
		return UNITS[type]
	}

	/**
	 *
	 * @param {string} source - PRICING_STRUCTURE_UNITS
	 * @param {string} unitSystem - UNIT_SYSTEM
	 * @return {string}
	 */
	translated(source, unitSystem) {
		try {
			return translate(UNITS[source][unitSystem].CAPS)
		} catch (e) {
			console.log(e)
			return ""
		}
	}

	/**
	 *
	 * @param {number} value
	 * @param {string} type - PRICING_STRUCTURE_UNITS
	 * @param {string} source - m, ft, m2, ft2, m3, ft3, l, gal, kg, lb
	 * @param {boolean} reverse - reverses conversion order
	 * @param {string} target - UNIT_SYSTEM
	 * @return {number}
	 */
	convert(value, type, source, reverse = false, target = UNIT_SYSTEM.SI) {
		if (type === PRICING_STRUCTURE_UNITS.HOUR) {
			return value
		}
		if (type === PRICING_STRUCTURE_UNITS.UNIT) {
			return value
		}
		if (type === PRICING_STRUCTURE_UNITS.KILOWATT) {
			if (source === UNITS.KILOWATT.SI.SHORT) {
				return target === UNIT_SYSTEM.SI ? value : value * 1.34102
			}
			if (source === UNITS.KILOWATT.US.SHORT) {
				return target === UNIT_SYSTEM.SI ? value * 0.7457 : value
			}
		}
		const from = source
		const to = UNITS[type][target].SHORT
		return from === to ? value : convert(value).from(reverse ? to : from).to(reverse ? from : to)
	}
}

export default Units