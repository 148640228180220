import React from 'react'
import {createRoot} from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import {FIREBASE_CONFIG, ICONS} from "./globals/constants/client/constants"
import {manageCrash, newMessage} from "./globals/functions/client/localFunctions"
import {reducer} from "./globals/constants/client/reduxData"
import {getNotifications, getOrders} from "./globals/functions/client/serverFunctions"
import {getMessaging, onMessage} from "firebase/messaging"
import {createStore} from "redux"
import {loadStripe} from "@stripe/stripe-js/pure"
import {initializeApp} from "firebase/app"
import Welcome from "./globals/components/Welcome"
import './style.css'
export const store = createStore(reducer)
/* TODO: replace with StripeElementWrapper in places where it is used */
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
let firebase, messaging
try {
	firebase = initializeApp(FIREBASE_CONFIG)
	messaging = getMessaging()
} catch (e) {
	console.log(e)
}
const fcm = () => {
	try {
		if (!messaging || !firebase) {return}
		onMessage(messaging, payload => {
			// console.log('Message received. ', payload)
			getNotifications(false).then().catch()
			newMessage(payload.notification.tag, payload.notification.title, payload.notification.body, "w3-pale-blue",
				ICONS.INFO_CIRCLE)
			getOrders(false).then().catch()
		})
	} catch (e) {
		console.log(e)
	}
}
fcm()
// appendScript("https://app.termly.io/embed.min.js", {autoBlock: "on", websiteUuid: "c00a05f7-7258-4563-8769-6efa8bf53023"})
/*
   TODO: 1. About menu on paralian logo
         2. Profile / change role / logout menu on user name
         3. Switch marina menu on marina logo
         4. Allow for a way to manually change position in queue of order
         5. Make manager profile type for those who manage other's boats
*/
const root = createRoot(document.getElementById('root'))
const render = () => {
	try {
		root.render(<Welcome/>)
	} catch (e) {
		manageCrash(e, "index")
	}
	// console.log("state", store.getState().window.current, store.getState().window.prior)
}

store.subscribe(render)
render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

