/**
 *
 * @param {string} id
 * @param {FormData} formData
 */
const appendFileUploadFormData = async (id, formData) => {
	try {
		const file = document.getElementById(`${id}-file-input`).files[0]
		const fileBlob = new Blob([file],{type: "application/pdf"})
		const canvas = document.getElementById(`${id}-file-input-canvas`).childNodes[0].childNodes[0].childNodes[0]
		const imageBlob = await new Promise(resolve => canvas.toBlob(blob => resolve(blob), "image/png"))
		formData.append('doc', fileBlob, `${Date.now()}.pdf`)
		formData.append('image', imageBlob, `${Date.now()}.png`)
		return formData
	}	catch(e) {
		console.log(e)
	}
}

export default appendFileUploadFormData