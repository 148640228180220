import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../globals/constants/client/constants"
import {translate} from "../globals/functions/client/localFunctions"
import Currency from "../globals/classes/shared/Currency"
import Servicio from "../globals/classes/shared/Servicio"

const MobileOrderAdjustmentsCard = props => {
	const [currency, setCurrency] = useState(new Currency(props.order.currency))
	useEffect(() => {
		setCurrency(new Currency(props.order.currency))
	}, [props.order.currency])
	try {
		const totalChanged = props.order.charges.total.total !== props.order.adjustments.total.total
		const total = props.order.adjustments.items.length > 0 ?
			<div className={"paralian-theme-level-2"} style={{padding: "1vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
			<div style={{padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
					{translate(DICTIONARY.ORDER_TOTAL_TAXES.X)}
				</div>
				{totalChanged ? <div style={{textAlign: "right"}}>{translate(DICTIONARY.CURRENT.X)}</div> : <></>}
				<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
					{currency.asText(props.order.charges.total.total)}
				</div>
				{totalChanged ?
					<>
						<div style={{textAlign: "right"}}>{translate(DICTIONARY.PROPOSED.X)}</div>
						<div style={{fontSize: "5vh", padding: "1vh 0vh"}}>
							{currency.asText(props.order.adjustments.total.total)}
						</div>
					</> : <div>{translate(DICTIONARY.UNCHANGED.X)}</div>}
			</div>
		</div> : <></>
		const dueDate = props.order.adjustments.dueDate ?
			<div style={{padding: "2vh 1vh", borderRadius: "1vh"}} className={"paralian-theme-level-2"}>
			<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
				{translate(DICTIONARY.DUE_DATE.X)}
			</div>
			{props.order.adjustments.dueDate ?
				<div style={{textAlign: "right"}}>
					{translate(DICTIONARY.CURRENT.X)}
				</div> : <></>}
			<div style={{fontSize: "4vh", paddingTop: "1vh"}}>
				{new Date(props.order.dueDate).toLocaleString(undefined,
					{day: "numeric", month: "short", year: "numeric"})}
			</div>
			<div style={{fontSize: "2vh", paddingBottom: "1vh"}}>
				{new Date(props.order.dueDate).toLocaleString(undefined,
				{hour: "numeric", minute: "numeric",	timeZoneName: "short"})}
			</div>
			{
				props.order.adjustments.dueDate ? <>
					<div style={{textAlign: "right"}}>
						{translate(DICTIONARY.PROPOSED.X)}
					</div>
					<div style={{fontSize: "5vh", paddingTop: "1vh"}}>
						{new Date(props.order.adjustments.dueDate).toLocaleString(undefined,
						{day: "numeric", month: "short", year: "numeric"})}
					</div>
					<div style={{fontSize: "3vh", paddingBottom: "1vh"}}>
						{new Date(props.order.adjustments.dueDate).toLocaleString(undefined,
						{hour: "numeric", minute: "numeric",	timeZoneName: "short"})}
					</div
				></> : <div>{translate(DICTIONARY.UNCHANGED.X)}</div>
			}
		</div> : <></>
		const charges = props.order.adjustments.items.length > 0 ? props.order.charges.items.map(item => {
			const adjustment = props.order.adjustments.items.find(adjustment => adjustment.item === item.item)
			const service = new Servicio(props.indexedServices.byIndex(item.item))
			const itemChanged = adjustment ? ((item.charge !== adjustment.charge) ||
				(item.quantity !== adjustment.quantity)) : false
			return <div key={`order-${props.order.id}-item-${item.item}-adjust`}
				className={"paralian-theme-level-2"} style={{padding: "1vh 2vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
				<div style={{fontSize: "2vh", padding: "2vh 0vh"}}>
					<b><i>{service.labelsAsString}</i></b>
				</div>
				<div style={{textAlign: "left"}}>
					{`${translate(DICTIONARY.CURRENT.X)} (${translate(DICTIONARY.EXCLUDING_TAXES.X).toLowerCase()})`}
				</div>
				<div style={{display: "inline-flex", padding: "1vh 1vh", width: "100%"}}>
					<div style={{textAlign: "left", width: "100%"}}>
						{currency.unitAsText(item.charge, item.quantity) + " (" +
						item.quantity.toLocaleString(undefined) + ")"}
					</div>
					<div>
						{currency.asText(item.charge)}
					</div>
				</div>
				{itemChanged ? <>
					<div style={{textAlign: "left"}}>
						{`${translate(DICTIONARY.PROPOSED.X)} (${translate(DICTIONARY.EXCLUDING_TAXES.X).toLowerCase()})`}
					</div>
					<div style={{display: "inline-flex", padding: "1vh", width: "100%"}}>
						<div style={{textAlign: "left", width: "100%"}}>
							{currency.unitAsText(adjustment.charge, adjustment.quantity) + " (" +
							adjustment.quantity.toLocaleString(undefined) + ")"}
						</div>
						<div>
							{currency.asText(adjustment.charge)}
						</div>
					</div>
				</> : <div style={{padding: "1vh"}}>{translate(DICTIONARY.UNCHANGED.X)}</div>}
			</div>
		}) : <></>
		return <>{dueDate}{charges}{total}</>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileOrderAdjustmentsCard