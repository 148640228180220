import React, {useEffect, useMemo, useRef, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {ACCOUNT_TYPE, DOCUMENT_CONTAINER, DOCUMENT_TYPE} from "../../../globals/constants/shared/enumerators"
import {getFromStorage, getUnits, setToStorage, toggleOverflow, translate} from "../../../globals/functions/client/localFunctions"
import MobileAddressList from "../address/MobileAddressList"
import MobileMarinaFacilities from "./facilities/MobileMarinaFacilities"
import MobileDocuments from "../documents/MobileDocuments"
import MobileMyMarinas from "./MobileMyMarinas"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileImageAndLogo from "../organization/MobileImageAndLogo"
import MobileBusinessRating from "../MobileBusinessRating"
import MobileContactInfo from "../contact/MobileContactInfo"
import MobileCurrencyAndTimezone from "../currency/MobileCurrencyAndTimezone"
import MobileSchedule from "../calendar/MobileSchedule"
import mapPlaceholder from "../../../images/map-marked-alt-duotone.svg"
import imagePlaceholder from "../../../images/image-duotone.svg"
import logoPlaceholder from "../../../images/pencil-paintbrush-duotone.svg"
import Organization from "../../../globals/classes/shared/Organization"
import googleMapsSetup from "../../../globals/functions/client/googleMapsSetup"
import googleMapsCleanup from "../../../globals/functions/client/mode/googleMapsCleanup"
/* TODO: move rules & regulations to documents (public or agreements, depending on the marina) */
/* TODO: add link to website / instagram */
/* TODO: add payment option with recurring payments and stripe ACH payments */
/* TODO: unlink marina */
/* TODO: add marina access section where documents can be shared with marina similarly to how organizations do */
const MobileMarinas = props => {
	const top = "4.95vh"
	const state_ = getFromStorage("state")
	const state = Object.keys(state_).length === 0 ? {vessel: {index: 0}, marina: {index: 0}} : state_
	const [index, setIndex] = useState(state.marina.index || 0)
	const marina = useMemo(() => props.marinas.length > 0 ?
		new Organization(props.marinas[index === -1 ? 0 : index]) : new Organization(), [props.marinas, index])
	// console.log(state, index, props.marinas, marina)
	const publicDocumentsRef = useRef(null)
	const addressArray = [marina.addresses.physical]
	if (marina.addresses.physical.id !== marina.addresses.mailing.id &&
		marina.addresses.mailing.id) {
		addressArray.push(marina.addresses.mailing)
	}
	if (addressArray.length === 2 && marina.addresses.support.id) {
		if (marina.addresses.support.id !== marina.addresses.physical.id &&
			marina.addresses.support.id !== marina.addresses.mailing.id) {
			addressArray.push(marina.addresses.support)
		}
	} else if (marina.addresses.support.id !== marina.addresses.physical.id &&
		marina.addresses.support.id) {
		addressArray.push(marina.addresses.support)
	}
	const refresh = () => props.refreshAccount().then(() => props.refreshMarinas().then())
	useEffect(() => {
		const initMap =
			`initMap = () => {
			  console.log("initMap called")
				try {
					const center = new google.maps.LatLng(${marina.coordinates.latitude}, ${marina.coordinates.longitude})
					const myOptions = {
						gestureHandling: "cooperative", 
						zoom: 16, 
						center: center, 
						mapTypeId: google.maps.MapTypeId.SATELLITE
					}
					const map = new google.maps.Map(document.getElementById("map"), myOptions)
					const kmlPath = "${marina.kml}"
					const mapLayer = kmlPath === "null" ? null : new google.maps.KmlLayer(kmlPath, {preserveViewport: false, map: map})
				} catch (e) {
					console.log(e)
				}
			}`

		const mapScripts = googleMapsSetup(initMap)
		return googleMapsCleanup(mapScripts)
	}, [marina])
	if (props.account.id === null) {
		return <div className={"w3-display-middle w3-animate-opacity"}>
			<MobileFontAwesome icon={ICONS.SYNC} style={{fontSize: "15vh"}} onClick={refresh}/>
		</div>
	}
	const profile = marina.id ? <>
		{/* Image and Logo */}
		<MobileImageAndLogo id={marina.id} image={marina.image}	imagePlaceholder={imagePlaceholder} logo={marina.logo}
			logoPlaceholder={logoPlaceholder} onClick={() => {}} showUpload={false}/>
		{/* Display name */}
		<div id={"display-name"} style={{fontSize: "4vh", padding: "1vh 2vh", zIndex: "20", top: "-2vh"}} translate={"no"}
			className={"paralian-theme-level-1 sticky paralian-overflow-nowrap-ellipsis"}
		 	onClick={() => toggleOverflow("display-name")}>
			{marina.name.display}
		</div>
		{/* Rating */}
		{/* TODO: Job Acceptance Rate & Response Time (w/ percentile) */}
		<MobileBusinessRating rating={marina.metrics.rating}/>
		{/* Icons */}
		<div style={{fontSize: "3vh", display: "inline-flex"}}>
			<MobileFontAwesome icon={ICONS.FILE_CERTIFICATE} style={{padding: "0vh 1vh"}} onClick={() => {
				// document.getElementById("user-container").scrollTo(0, certificatesRef.current.offsetTop)
			}}/>
			<MobileFontAwesome icon={ICONS.ID_CARD}	style={{padding: "0vh 1vh"}} onClick={() => {
				// document.getElementById("user-container").scrollTo(0, idVerificationRef.current.offsetTop)
			}}/>
			<MobileFontAwesome icon={["fas", ICONS.SHIELD_CHECK[1]]}	style={{fontSize: "2vh", marginLeft: "-2vh"}}/>
			<MobileFontAwesome icon={ICONS.SYNC} style={{padding: "0vh 1vh"}} onClick={() => props.refreshMarinas().then()}/>
		</div>
		{/* Contact Info */}
		<MobileContactInfo contactInfo={marina.contactInfo} canEdit={false}/>
		{/* TODO: include VHF in contact info */}
		{/* Map */}
		{/*<GoogleMapWrapper/>*/}
		<div className={"paralian-theme-level-1-flipped"} id={"map"} style={{height: "46vh", margin: "2vh",
			backgroundImage: `url(${mapPlaceholder})`, backgroundSize: "50%", backgroundPosition: "center",
			backgroundRepeat: "no-repeat", borderRadius: "1vh"}}/>
		{/* Address */}
		<MobileAddressList addresses={addressArray} top={top} zIndex={2} allowDelete={false} isOrganization={true}
			canEdit={false}/>
		{/* Schedule */}
		<MobileSchedule schedule={marina.schedule} holidays={marina.holidays} format={"amPM"}
			top={top} zIndex={3}/>
		{/* TODO: Paralian-Served Services */}
		{/* Currency & Timezone */}
		<MobileCurrencyAndTimezone currency={marina.currency} timezone={marina.timezone}
			country={marina.addresses.physical.country} top={top} zIndex={4} canEdit={false}/>
		{/* Facilities */}
		{/* TODO: include edit capability with option to add facilities & units */}
		<div ref={publicDocumentsRef}>
			<MobileMarinaFacilities physicalFacilities={marina.physicalFacilities} units={getUnits(props.account)}
				vesselStorageFacilities={marina.vesselStorageFacilities} zIndex={6} top={top}/>
		</div>
		{/* Documents */}
		<MobileDocuments documents={marina.documents.contracts} container={DOCUMENT_CONTAINER.ORGANIZATIONS}
			docType={DOCUMENT_TYPE.CONTRACTS} zIndex={8} top={top} canEdit={false}/>
		<MobileDocuments documents={marina.documents.publicDocuments} container={DOCUMENT_CONTAINER.ORGANIZATIONS}
			docType={DOCUMENT_TYPE.PUBLIC} zIndex={9} top={top} canEdit={false}/>
	</> : <></>
	const storageMarinas = props.vessels.owned.length > 0 ?
		Array.from(new Set(props.vessels.owned.map(vessel => vessel.homes.map(home => home.id)).flat())) : []
	return <div id={"marina-container"} className={"w3-display-middle w3-animate-opacity paralian-mobile-content " +
		"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}>
		{profile}
		{/* My Marinas */}
		<MobileMyMarinas marinas={props.marinas} canAdd={props.account.type === ACCOUNT_TYPE.PERSONAL} account={props.account}
			storageMarinas={storageMarinas} zIndex={10} top={props.marinas.length > 0 ? top : "0vh"}
		 	label={props.account.type === ACCOUNT_TYPE.PERSONAL ? null : translate(DICTIONARY.MARINAS.X)}
			primary={props.account.preferences.defaults.marina} refresh={refresh}
			setIndex={id => {
				const index_ = props.marinas.findIndex(marina => marina.id === id)
				state.marina.index = index_ !== -1 ? index_ : index
				setToStorage("state", state)
				setIndex(index_ !== -1 ? index_ : index)
		}}/>
	</div>
}

export default MobileMarinas