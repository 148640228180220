import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {
	getFromStorage,
	getVesselIndex,
	replaceInStorage,
	translate
} from "../../../../globals/functions/client/localFunctions"
import ModalSelect from "../../../shared/input/ModalSelect/ModalSelect"
import MobileVesselAdd from "../../vessels/fleet/MobileVesselAdd"
import Modal from "../../../../globals/components/Modal"
import vesselImagePlaceholder from "../../../../images/ship-duotone.svg"

const VesselSelect = props => {
	const [vessels, setVessels] = useState([...props.vessels.owned, ...props.vessels.customer])
	const [showAddNew, setShowAddNew] = useState(false)
	// console.log("showAddNew", showAddNew)
	const session = getFromStorage("order", "session")
	useEffect(() => {
		const vessels_ = [...props.vessels.owned, ...props.vessels.customer]
		if (vessels_.length > 0) {
			const id = session.vessels ? session.vessels[0].id : null
			const index_ = id ? vessels_.findIndex(v => v.id === id) : getVesselIndex()
			const index = index_ > -1 ? index_ : 0
			replaceInStorage("order", "vessels",
			[{id: vessels_[index].id, label: vessels_[index].name.display}], "session")
			props.setDisabled(false)
		} else {
			props.setDisabled(true)
		}
		setVessels(vessels_)
	}, [props.vessels])
	const vesselsList = vessels.map(v => {
		const text = []
		if (v.registration || v.documentation) {text.push(v.registration || v.documentation)}
		if (v.hull.brand) {text.push(v.hull.brand)}
		if (v.hull.model) {text.push(v.hull.model)}
		if (v.hull.year) {text.push(v.hull.year)}
		return {
			id: v.id,
			title: v.name.display,
			text: text,
			image: v.image
		}
	})
	const addNew = showAddNew ? <Modal id={`${props.id}-addNew`} title={translate(DICTIONARY.ADD_VESSEL.X)}
		icon={ICONS.SHIP} padding={"0vh"} body={<MobileVesselAdd isOrganization={props.isOrganization}
		onAdd={() => {
			props.refresh()
			setShowAddNew(false)
	}}/>}
 		onClick={() => setShowAddNew(false)}/> : <></>
	return <>
		{addNew}
		<fieldset style={{margin: "1vh 0vh"}}>
			<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.VESSEL.X)}</legend>
			<ModalSelect imagePlaceholder={vesselImagePlaceholder} icon={ICONS.SHIP} id={"vessels"}
				title={translate(DICTIONARY.SELECT_VESSEL.X)} selected={session.vessels ? session.vessels.map(v => v.id) : []}
				list={vesselsList} multiSelect={false}
				setShowAddNew={setShowAddNew} onApply={v => {
				replaceInStorage("order", "vessels",
					v.map(x => {
						const item_ = vesselsList.find(y => y.id === x)
						return {id: item_.id, label: item_.title}
					}), "session")
				props.setDisabled(false)
			}}/>
		</fieldset>
	</>
}

export default VesselSelect