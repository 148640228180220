import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {ACCOUNT_TYPE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {getCurrentAccount, getMarinas, getNotifications, getOrders, getVessels, getOrganization, selectAccount, getCustomers} from "../../../globals/functions/client/serverFunctions"
import {getFromStorage, newMessage,	replaceInStorage, setLanguage, setTimeFormat, setToStorage, setUnits, setVesselIndex, translate} from "../../../globals/functions/client/localFunctions"
import setLoggedIn from "../../../globals/functions/client/mode/setLoggedIn"
import setAccountType from "../../../globals/functions/client/mode/setAccountType"
import Account from "../../../globals/classes/shared/Account"
/**
 *
 * @param {number} id - account id
 */
const selectLoginAccount = async id => {
	const window = store.getState().window.current
	const selectResponse = await selectAccount(id)
	if (selectResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
		getCurrentAccount(false).then(getAccountResponse => {
			if (getAccountResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const account = new Account(getAccountResponse.payload)
				const vesselId = account.preferences.defaults.vessel || 0
				const marinaId = account.preferences.defaults.marina || 0
				const ids = [vesselId, marinaId]
				setToStorage("state", {vessel: {index: null, id: vesselId}, marina: {index: null, id: marinaId}})
				setLanguage(account.preferences.language, false)
				setUnits(account.preferences.units, false)
				setTimeFormat(account.preferences.use24Hr ? "24hr" : "xm", false)
				setAccountType(account.type)
				if (account.type === ACCOUNT_TYPE.ORGANIZATION || account.type === ACCOUNT_TYPE.EMPLOYEE) {
					getOrganization(false).then(orgResponse => {
						if (orgResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
							if (orgResponse.payload.logo.path) {
								setToStorage("logo", orgResponse.payload.logo.path)
							}
							if (!orgResponse.payload.stripeStatus) {
								newMessage("org-needs-stripe", translate(DICTIONARY.PAYMENTS_SETUP_INCOMPLETE.X),
									translate(DICTIONARY.PAYMENTS_SETUP_INCOMPLETE_MESSAGE.X), "w3-pale-yellow", ICONS.STRIPE)
							}
							if (!orgResponse.payload.products.length === 0) {
								newMessage("org-needs-product", translate(DICTIONARY.NO_PRODUCTS.X),
									translate(DICTIONARY.NO_PRODUCTS_MESSAGE.X), "w3-pale-yellow", ICONS.CROWN)
							}
						}
					}).catch(e => console.log(e))
					getCustomers(false).then()
				}
				getVessels(false, account.isPersonal).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS &&
						(response.payload.owned.length + response.payload.customer.length) > 0) {
						setVesselIndex(ids[0], response.payload)
					} else if (response.status === SERVER_RESPONSE_STATE.SUCCESS && account.type === ACCOUNT_TYPE.PERSONAL &&
						(response.payload.owned.length + response.payload.customer.length) === 0) {
						newMessage("no-vessel-message", translate(DICTIONARY.NO_VESSELS.X),
							translate(DICTIONARY.NO_VESSELS_MESSAGE.X), "w3-pale-yellow", ICONS.SHIP)
					}
				}).catch(e => console.log(e))
				getMarinas(false, account.isPersonal).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS && response.payload.length > 0) {
						const index = response.payload.findIndex(marina => marina.organization.id === ids[1])
						const marina = response.payload[index === -1 ? 0 : index]
						const logo = getFromStorage("logo")
						if (!logo.path && marina.organization.logo.path && marina.organization.isParalianCustomer) {
							setToStorage("logo", marina.organization.logo.path)
						}
						replaceInStorage("state", "marina", {index: index, id: ids[1]})
					} else if (response.status === SERVER_RESPONSE_STATE.SUCCESS && account.type === ACCOUNT_TYPE.PERSONAL &&
						response.payload.length === 0) {
						newMessage("no-marina-message", translate(DICTIONARY.NO_MARINAS.X),
							translate(DICTIONARY.NO_MARINAS_MESSAGE.X), "w3-pale-yellow", ICONS.ANCHOR)
					}
				}).catch(e => console.log(e))
				getOrders(false, account.type).then()
				getNotifications(false, account.type).then()
				setLoggedIn(true)
				store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: {levelOne: window.levelOne,
					levelTwo: "MAIN", levelThree: "requests", levelFour: "", levelFive: "", levelSix: ""}}})
			}
		})
	}
}

export default selectLoginAccount