import React from "react"
import MobileInputPricing from "./MobileInputPricing"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Servicio} props.service
 * @param {Currency} props.currency
 * @param {IndexedArray} props.defaultPricingStructures
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputPricingEdit = props => {
	return <MobileInputPricing id={props.id} form={props.form} service={props.service} currency={props.currency}
		defaultPricingStructures={props.defaultPricingStructures} className={"paralian-theme-level-1"} showLabel={false}/>
}

export default MobileInputPricingEdit