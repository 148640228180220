import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {Object} props.order.buttons
 * @param {Object} props.order.buttons.adjust
 * @param {boolean} props.order.buttons.adjust.respond
 * @param {function} props.setShowAdjustRespondModal
 * @return {JSX.Element}
 * @constructor
 */
const RespondToOrderAdjustButton = props => {
	return props.order.buttons.adjust.respond ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"adjust-respond"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.RESPOND_TO_PROPOSED_CHANGES.X)}} left={{icon: ICONS.REPLY}}
					className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}}
					onClick={() => props.setShowAdjustRespondModal(true)}/>
		</div> : <></>
}

export default RespondToOrderAdjustButton
