import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {DOCUMENT_TYPE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {deleteDocument} from "../../../globals/functions/client/serverFunctions"
import {translate, valOrNA} from "../../../globals/functions/client/localFunctions"
import MobileHeaderAndContent from "../MobileHeaderAndContent"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileDocumentUploadForm from "./MobileDocumentUploadForm"
import Modal from "../../../globals/components/Modal"
import doc from "../../../images/file-pdf-duotone.svg"
import Country from "../../../globals/classes/shared/Country"
import Birthdate from "../../../globals/classes/shared/Birthdate"
import State from "../../../globals/classes/shared/State"
import Currency from "../../../globals/classes/shared/Currency"
/**
 *
 * @param {Object} props
 * @param {Certificate|Bond|InsurancePolicy|VesselTitle|VesselRegistration|
 * VesselDocumentation|OrganizationRegistration|Contract|PublicDocument} props.document
 * @param {string} props.container - one of: "ACCOUNTS", "VESSELS", "ORGANIZATIONS"
 * @param {number} props.parentId - id of container
 * @param {Country[]} props.countries
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @param {function} props.appendDocument
 * @param {function} props.removeDocument
 * @param {function} props.replaceDocument
 * @return {JSX.Element}
 * @constructor
 */
const MobileDocument = props => {
	const [replace, setReplace] = useState(false)
	const [showImage, setShowImage] = useState(false)
	const country = new Country(props.document.country)
	const divisions = country.divisions.map(division => translate(division)).join("/") ||
		`${translate(DICTIONARY.STATE.X)} (${translate(DICTIONARY.OR_EQUIVALENT.X)})`
	const state = new State(props.document.state)
	const currency = new Currency(props.document.currency)
	const diffDays = props.document.document.expiration ?
		Math.trunc((new Date(props.document.document.expiration) - new Date()) / (1000 * 3600 * 24)) : "N/A"
	/*
	const [share, setShare] = useState('canShare' in window.navigator &&
		window.navigator.canShare({title: "title", text: "text", files: []}))
	*/
	let daysToExpiration, docContent
/*
	let renewalReminder =
		<div style={{width: "100%", padding: "1.5vh"}}>
			<div> Renewal Reminder </div>
			<div style={{paddingBottom: "1.5vh"}}><b>{"1 Month Before Expiration"}</b></div>
			<div style={{display: "flex", margin: "1vh"}}>
				<MobileInputSlider id={`${props.document.document.id}-SMS`} form={null} className={""} label={<b>SMS</b>}
					defaultChecked={props.document.document.smsReminder} height={"3vh"} width={"8vh"} padding={"0vh 1vh"} onChange={element => {
					setDocRenewalReminder(props.document.document.id, props.container, props.parentId,
						element.getAttribute("data-is-checked"), null).then(response => {
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refresh().then()
						} else {
							element.getAttribute("data-is-checked") === "true" ?
								element.setAttribute("data-is-checked", "false") :
								element.setAttribute("data-is-checked", "true")
							element.checked = !element.checked
						}
					})
				}}/>
			</div>
			<div className="w3-display-container" style={{display: "flex", margin: "1vh"}}>
				<MobileInputSlider id={`${props.document.document.id}-email`} form={null} className={""} label={<b>e-mail</b>}
					defaultChecked={props.document.document.emailReminder} height={"3vh"} width={"8vh"} padding={"0vh 1vh"} onChange={element => {
					setDocRenewalReminder(props.document.document.id, props.container, props.parentId,
						null, element.getAttribute("data-is-checked")).then(response => {
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refresh().then()
						} else {
							element.getAttribute("data-is-checked") === "true" ?
								element.setAttribute("data-is-checked", "false") :
								element.setAttribute("data-is-checked", "true")
							element.checked = !element.checked
						}
					})
				}}/>
			</div>
		</div>
*/
	if (typeof diffDays === "string" || diffDays > 32) {
		daysToExpiration = <></>
	}
	else if (typeof diffDays === "number" && diffDays > 0 && diffDays < 32) {
		daysToExpiration =
			<div style={{background: "orange", textAlign: "center", color: "black",
				borderRadius: "0.5vh", padding: "0.5vh", marginBottom: "1vh"}}>
				{translate(DICTIONARY.EXPIRES_IN.X) + ": "}<b style={{fontSize: "2vh"}}>{diffDays}</b>{" " + translate(DICTIONARY.DAYS.X)}
				<MobileFontAwesome icon={ICONS.EXCLAMATION_CIRCLE} style={{fontSize: "2vh", paddingLeft: "1vh"}}/>
			</div>
	}
	else {
		daysToExpiration =
			<div style={{background: "orange", textAlign: "center", color: "black",
				borderRadius: "0.5vh", padding: "0.5vh", marginBottom: "1vh"}}>
				{translate(DICTIONARY.EXPIRED.X)}
				<MobileFontAwesome icon={ICONS.EXCLAMATION_TRIANGLE} style={{fontSize: "2vh", paddingLeft: "1vh"}}/>
			</div>
	}
	const label = <MobileHeaderAndContent name={"name"} header={translate(DICTIONARY.LABEL.X)}
		content={valOrNA(props.document.document.name)}/>

	switch (props.docType) {
		case DOCUMENT_TYPE.UPKEEP:
			const upkeepRecordIssued = props.document.issued ?
				new Birthdate({date: new Date(props.document.issued)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"category"} header={translate(DICTIONARY.CATEGORY.X)}
						content={valOrNA(translate(props.document.category))}/>
					<MobileHeaderAndContent name={"system"} header={translate(DICTIONARY.SYSTEM.X)}
						content={valOrNA(translate(props.document.system))}/>
					<MobileHeaderAndContent name={"id"} header={translate(DICTIONARY.IDENTIFICATION_NUMBER.X)}
						content={valOrNA(props.document.id)}/>
					<MobileHeaderAndContent name={"notes"} header={translate(DICTIONARY.NOTES.X)}
						content={valOrNA(props.document.notes)}/>
					<MobileHeaderAndContent name={"vendor"} header={translate(DICTIONARY.VENDOR.X)}
						content={valOrNA(props.document.vendor)}/>
					<MobileHeaderAndContent name={"issued"} header={translate(DICTIONARY.ISSUE_DATE.X)}
						content={upkeepRecordIssued}/>
				</>
			break
		case DOCUMENT_TYPE.CERTIFICATES:
			const certificateDateAwardedFormatted = props.document.awarded ?
				new Birthdate({date: new Date(props.document.awarded)}).asLocaleString : "N/A"
			docContent=
				<>
					{/*<div>
						{label}
						<MobileFontAwesome icon={ICONS.FILE_PDF} style={{paddingLeft: "1.5vh"}}
							onClick={() => setShowImage(true)}/>
					</div>*/}
					<MobileHeaderAndContent name={"countryOfIssue"} header={translate(DICTIONARY.COUNTRY_OF_ISSUE.X)}
						content={valOrNA(country.translatedName)}/>
					<MobileHeaderAndContent name={"firstOrderAdminDivOfIssue"} header={divisions} content={valOrNA(state.name)}/>
					<MobileHeaderAndContent name={"certifyingEntity"} header={translate(DICTIONARY.CERTIFYING_ENTITY.X)}
						content={valOrNA(props.document.certifyingEntityName)}/>
					<MobileHeaderAndContent name={"certificateType"} header={translate(DICTIONARY.CERTIFICATE_TYPE.X)}
						content={valOrNA(props.document.variety)}/>
					<MobileHeaderAndContent name={"certificateNumber"} header={translate(DICTIONARY.CERTIFICATE_NUMBER.X)}
						content={valOrNA(props.document.number)}/>
					<MobileHeaderAndContent name={"certificationDate"} header={translate(DICTIONARY.CERTIFICATION_DATE.X)}
						content={certificateDateAwardedFormatted}/>
				</>
			break
		case DOCUMENT_TYPE.BONDS:
			// console.log(props.document)
			const bondStartDateFormatted = props.document.start ?
				new Birthdate({date: new Date(props.document.start)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"issuerOrganizationName"} header={translate(DICTIONARY.ISSUING_ORGANIZATION.X)}
						content={valOrNA(props.document.issuerOrganizationName)}/>
					<MobileHeaderAndContent name={"insuranceAgencyName"} header={translate(DICTIONARY.INSURANCE_AGENCY.X)}
						content={valOrNA(props.document.insuranceAgencyName)}/>
					<MobileHeaderAndContent name={"purpose"} header={translate(DICTIONARY.PURPOSE.X)}
						content={valOrNA(props.document.purpose)}/>
					<MobileHeaderAndContent name={"startDate"} header={translate(DICTIONARY.START_DATE.X)}
						content={valOrNA(bondStartDateFormatted)}/>
					<MobileHeaderAndContent name={"bondNumber"} header={translate(DICTIONARY.BOND_NUMBER.X)}
						content={valOrNA(props.document.number)}/>
					<MobileHeaderAndContent name={"bondAmount"} header={translate(DICTIONARY.AMOUNT.X)}
						content={valOrNA(props.document.amount ?
							props.document.amount.toLocaleString(undefined, { style: 'currency', currency: currency.code }) : null)}/>
					<MobileHeaderAndContent name={"currency"} header={translate(DICTIONARY.CURRENCY.X)} content={valOrNA(currency.name)}/>
				</>
			break
		case DOCUMENT_TYPE.INSURANCE:
			const insuranceStartDateFormatted = props.document.start ?
				new Birthdate({date: new Date(props.document.start)}).asLocaleString : "N/A"
			// console.log(props.document.currency)
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"issuerOrganizationName"} header={translate(DICTIONARY.ISSUING_ORGANIZATION.X)}
						content={valOrNA(props.document.issuerOrganizationName)}/>
					<MobileHeaderAndContent name={"insuranceAgencyName"} header={translate(DICTIONARY.INSURANCE_AGENCY.X)}
						content={valOrNA(props.document.insuranceAgencyName)}/>
					<MobileHeaderAndContent name={"startDate"} header={translate(DICTIONARY.START_DATE.X)}
						content={insuranceStartDateFormatted}/>
					<MobileHeaderAndContent name={"policyNumber"} header={translate(DICTIONARY.POLICY_NUMBER.X)}
						content={valOrNA(props.document.number)}/>
					<MobileHeaderAndContent name={"currency"} header={translate(DICTIONARY.CURRENCY.X)}
						content={valOrNA(currency.name)}/>
				</>
			break
		case DOCUMENT_TYPE.VESSEL_TITLES:
			const vesselPurchaseDateFormatted = props.document.purchased ?
				new Birthdate({date: new Date(props.document.purchased)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"titlingAuthorityName"} header={translate(DICTIONARY.TITLING_AUTHORITY.X)}
						content={valOrNA(props.document.titlingAuthorityName)}/>
					<MobileHeaderAndContent name={"titleNumber"} header={translate(DICTIONARY.TITLE_NUMBER.X)}
						content={valOrNA(props.document.number)}/>
					<MobileHeaderAndContent name={"vesselPurchaseDate"} header={translate(DICTIONARY.PURCHASE_DATE.X)}
						content={vesselPurchaseDateFormatted}/>
				</>
			/* TODO: Vessel ownership verification */
			// renewalReminder = <></>
			break
		case DOCUMENT_TYPE.VESSEL_REGISTRATIONS:
			const registrationIssueDateFormatted = props.document.issued ?
				new Birthdate({date: new Date(props.document.issued)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"registrationAuthorityName"} header={translate(DICTIONARY.REGISTRATION_AUTHORITY.X)}
						content={valOrNA(props.document.registrationAuthorityName)}/>
					<MobileHeaderAndContent name={"registration"} header={translate(DICTIONARY.REGISTRATION_NUMBER.X)}
						content={valOrNA(props.document.registration)}/>
					<MobileHeaderAndContent name={"tagNumber"} header={translate(DICTIONARY.TAG_NUMBER.X)}
						content={valOrNA(props.document.tag)}/>
					<MobileHeaderAndContent name={"issueDate"} header={translate(DICTIONARY.ISSUE_DATE.X)}
						content={registrationIssueDateFormatted}/>
				</>
			break
		case DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS:
			const documentationIssueDateFormatted = props.document.issued ?
				new Birthdate({date: new Date(props.document.issued)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"documentationAuthorityName"} header={translate(DICTIONARY.DOCUMENTATION_AUTHORITY.X)}
						content={valOrNA(props.document.documentationAuthorityName)}/>
					<MobileHeaderAndContent name={"documentation"} header={translate(DICTIONARY.DOCUMENTATION_NUMBER.X)}
							content={valOrNA(props.document.documentation)}/>
					<MobileHeaderAndContent name={"IMO"} header={translate(DICTIONARY.IMO_NUMBER.X)}
						content={valOrNA(props.document.imo)}/>
					<MobileHeaderAndContent name={"callSign"} header={translate(DICTIONARY.CALL_SIGN.X)}
						content={valOrNA(props.document.callSign)}/>
					<MobileHeaderAndContent name={"issueDate"} header={translate(DICTIONARY.ISSUE_DATE.X)}
						content={documentationIssueDateFormatted}/>
				</>
			break
		case DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS:
			const organizationRegIssueDateFormatted = props.document.issued ? new Birthdate({date: new Date(props.document.issued)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					{/*<MobileHeaderAndContent name={"legalName"} header={translate(DICTIONARY.LEGAL_NAME.X)}
						content={valOrNA(props.document.legalName)}/>*/}
					<MobileHeaderAndContent name={"countryOfRegistration"} header={translate(DICTIONARY.COUNTRY_OF_REGISTRATION.X)}
						content={valOrNA(country.translatedName)}/>
					<MobileHeaderAndContent name={"firstOrderAdminDivOfRegistration"} content={valOrNA(state.name)}
						header={`${translate(DICTIONARY.STATE.X)} (${translate(DICTIONARY.OR_EQUIVALENT.X)})`}/>
					<MobileHeaderAndContent name={"registrationAuthorityName"} header={translate(DICTIONARY.REGISTRATION_AUTHORITY.X)}
						content={valOrNA(props.document.registrationAuthorityName)}/>
					<MobileHeaderAndContent name={"registrationNumber"} header={translate(DICTIONARY.REGISTRATION_NUMBER.X)}
						content={valOrNA(props.document.number)}/>
					<MobileHeaderAndContent name={"issueDate"} header={translate(DICTIONARY.ISSUE_DATE.X)}
						content={organizationRegIssueDateFormatted}/>
				</>
			break
		case DOCUMENT_TYPE.CONTRACTS:
			const contractStartDateFormatted = props.document.start ?
				new Birthdate({date: new Date(props.document.start)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"startDate"} header={translate(DICTIONARY.START_DATE.X)}
						content={contractStartDateFormatted}/>
				</>
			break
		case DOCUMENT_TYPE.PUBLIC:
			const publicationDateFormatted = props.document.published ?
				new Birthdate({date: new Date(props.document.published)}).asLocaleString : "N/A"
			docContent=
				<>
					{label}
					<MobileHeaderAndContent name={"publicationDate"} header={translate(DICTIONARY.PUBLICATION_DATE.X)}
						content={publicationDateFormatted}/>
				</>
			// renewalReminder = <></>
			break
		default:
			docContent = <></>
	}
	/* TODO: list organizations with specific view access and allow removal of this access */
	/* TODO: when requesting service access at marina as organization, have fields for specific documents and allow...
	 *   organizations to select from their list of documents or upload new ones */
	/* TODO: when a personal or organization account (or vessel) is set up as marina customer generate fields where...
	 *   customer can supply required documents or select from current documents to share */
	/* TODO: share using a link which anyone with the link can use but is only good for a few minutes */
	const replace_ = replace ? <Modal icon={ICONS.REPEAT} title={translate(DICTIONARY.REPLACE_DOCUMENT.X)}
		onClick={() => setReplace(false)} id={`upload-${props.docType}-modal`} padding={"0vh"}
		body={<MobileDocumentUploadForm id={`upload-${props.docType}`} action={"replace"} docData={props.document}
			form={`form-${props.docType}`} docType={props.docType} container={props.container}
			className={"paralian-theme-level-1"} containerId={props.vesselId} onSuccess={() => {
				props.refresh().then()
				setReplace(false)
			}}/>}/> : <></>
	const documentImage = showImage && props.document.document.image.path ?
		<Modal onClick={() => setShowImage(false)} id={`show-${props.docType}-modal`} padding={"0vh"}
			body={<div className={"w3-display-container"} style={{minHeight: "10vh", width: "100%", padding: "1.5vh"}}>
				<picture style={{width: "22.5vh", paddingTop: "1.5vh"}}>
					{
						props.document.document.path ?
							<a href={props.document.document.path} download={`${props.docType}.pdf`} target={"_blank"}
								rel={"noreferrer"}>
								<MobileFontAwesome icon={ICONS.DOWNLOAD} className={"w3-display-middle"}
									style={{padding: "1.5vh", zIndex: "3", fontSize: "10vh"}}/>
							</a> : <></>
					}
					<img id={`${props.document.document.id}-img`} className={"w3-display-container"} alt={props.docType}
						src={props.document.document.image.path || doc} style={{height: "100%", width: "100%"}}
						onError={() => document.getElementById(`${props.document.document.id}-img`).src = doc}/>
				</picture>
			</div>}/> : <></>
	return <div id={`${props.document.document.id}`} className={"paralian-mobile-info-card"} style={{textAlign: "left"}}>
		{replace_}
		{documentImage}
		{daysToExpiration}
		<div style={{width: "100%", padding: "0vh 1.5vh", display: "flex"}}>
			{props.canEdit ? <>
				<div style={{width: "50%"}}>
					<MobileFontAwesome icon={ICONS.TRASH} style={{padding: "1.5vh", fontSize: "3vh", opacity: 0.5}}
						onClick={async () => {
						 const confirmed = window.confirm(translate(DICTIONARY.DELETE_DOCUMENT_CONFIRM_QUERY.X))
						 if (confirmed) {
							 const response = await deleteDocument(props.document.document.id)
							 // console.log(response)
							 if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								 props.refresh().then()
								 props.removeDocument(props.document.document.id)
							 }
						 }
						}}/>
				</div>
				<div className={"paralian-mobile-info-card-header"} style={{width: "50%", textAlign: "right"}}>
					<div className={"w3-ripple"} onClick={() => setReplace(true)}>
						{translate(DICTIONARY.REPLACE.X)}
						<MobileFontAwesome icon={ICONS.REPEAT} style={{padding: "1.5vh", fontSize: "3vh"}}/>
					</div>
				</div>
			</> : <></>}
		</div>
		<div className={"w3-display-container"} style={{margin: "0vh auto", maxWidth: "30vh", padding: "1.5vh"}}>
			<picture style={{width: "22.5vh", paddingTop: "1.5vh"}}>
				<img id={`${props.document.document.id}-img`} className={"w3-display-container"} alt={props.docType}
					src={props.document.document.image.path || doc} style={{height: "100%", width: "100%"}}
					onError={() => document.getElementById(`${props.document.document.id}-img`).src = doc}
					onClick={() => props.document.document.path ? setShowImage(true) : null}/>
			</picture>
		</div>
		{docContent}
		{props.document.document.expiration ?
			<MobileHeaderAndContent name={"expirationDate"} header={translate(DICTIONARY.EXPIRATION_DATE.X)}
				content={new Birthdate({date: props.document.document.expiration}).asLocaleString}/> : <></>}
		{/* renewalReminder */}
	</div>
}

export default MobileDocument