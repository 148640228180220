import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {addressEdit} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputAddressLabel from "./MobileInputAddressLabel"
import MobileInputAddressType from "./MobileInputAddressType"
import MobileInputAddressFields from "./MobileInputAddressFields"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import addressInputCapture from "./addressInputCapture"
import MobileFontAwesome from "../MobileFontAwesome"
import Addresses from "../../../globals/classes/shared/Addresses"

/**
 *
 * @param {Object} props
 * @param {string} props.form
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {Address} props.address - default address
 * @param {boolean} props.showLabel
 * @param {boolean} props.isOrganization
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAddressEdit = props => {
	const onSuccess = props.onSubmit ? props.onSubmit : () => {}
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const address = addressInputCapture(`address-${props.address.id}`)
			address.id = props.address.id
			const response = await addressEdit(address, props.isOrganization)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				onSuccess(props.isOrganization ? new Addresses(response.payload) : response.payload)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <>
		{props.allowDelete ? <div style={{textAlign: "left", opacity: 0.5, display: "flex",
			margin: "1vh 0vh"}}>
			<div onClick={props.onDelete}  className={"w3-ripple"}>
				<MobileFontAwesome icon={ICONS.TRASH} style={{paddingRight: "1.5vh"}} label={translate(DICTIONARY.DELETE.X)}/>
				<i>{translate(DICTIONARY.DELETE.X)}</i>
			</div>
		</div> : <></>}
		<div className={"w3-animate-opacity w3-center"}>
			<MobileInputAddressLabel id={`address-${props.address.id}`} form={props.form} showLabel={props.showLabel}
				defaultValue={props.address.label} style={props.style} className={props.className}/>
			<MobileInputAddressType id={`address-${props.address.id}`} address={props.address}
				isOrganization={props.isOrganization}/>
		</div>
		<MobileInputAddressFields id={`address-${props.address.id}`} form={`address-${props.address.id}-form`}
			showLabel={props.showLabel} style={props.style}	address={props.address}
			autoComplete={false} className={props.className} showLine3={true}/>
	</>
	return <MobileInputForm id={`address-${props.address.id}`} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileInputAddressEdit