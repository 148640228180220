import {typeOrNull} from "../../functions/shared/local"

class ServiceApprovals {
	/**
	 *
	 * @param {State} [requests = null]
	 * @param {ServiceApproval[]} [requests.sent]
	 * @param {ServiceApproval[]} [requests.received]
	 */
	constructor (requests = null) {
		try {
			this.sent = typeOrNull(requests.sent, "array")
			this.received = typeOrNull(requests.received, "array")
		} catch (e) {
			this.sent = []
			this.received = []
		}
	}
}

export default ServiceApprovals