import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {getServices} from "../../../globals/functions/client/serverFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileEmployee from "./MobileEmployee"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileEmployeeAdd from "./MobileEmployeeAdd"
import Modal from "../../../globals/components/Modal"
import Employee from "../../../globals/classes/shared/Employee"
import IndexedArray from "../../../globals/classes/shared/IndexedArray"
import Servicio from "../../../globals/classes/shared/Servicio"
/**
 *
 * @param {Object} props
 * @param {Account[]} props.employees
 * @param {Servicio[]} props.services
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @param {function} props.onAdd
 * @return {JSX.Element}
 * @constructor
 */
const MobileEmployees = props => {
	const [add, setAdd] = useState(false)
	const [employees, setEmployees] = useState(props.employees)
	const [expanded, setExpanded] = useState(false)
	const [categories, setCategories] = useState([])
	useEffect(() => {
		getServices().then(response => {
			// console.log("response", response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const services = new IndexedArray({obj: response.payload.asIndexedArray.obj})
				const categories = Array.from(new Set(props.services.map(service => service.category)))
				setCategories(categories.map(id => new Servicio(services.byIndex(id))))
			}})},[props.services])
	useEffect(() => {setEmployees(props.employees)}, [props.employees])

	const add_ = add ? <Modal id={"employee-add-modal"} padding={"0vh"} icon={ICONS.USER_ASTRONAUT}
		title={translate(DICTIONARY.ADD_EMPLOYEE.X)} onClick={() => setAdd(false)} body={
		<MobileEmployeeAdd id={"add"} services={categories} onUndo={() => setAdd(false)}
			onSuccess={employee => {
					if (props.refresh) {props.refresh().then()}
					setEmployees([...employees, employee])
					setAdd(false)
				}}/>}/> : <></>
	const content = employees.map(employee => <div key={`employee-${employee.id}`}>
		<MobileEmployee employee={new Employee(employee)}	services={categories} canEdit={props.canEdit}
			onEdit={() => {}} refresh={props.refresh}/></div>)
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} id={"employees"} zIndex={props.zIndex} top={props.top}
			text={translate(DICTIONARY.EMPLOYEES.X)} onClick={() => setExpanded(!expanded)}/>
		{expanded ? <div id={"employees"} className={"w3-animate-opacity w3-animate-top"}>
			{content}
			{add_}
			{props.canEdit ?
				<MobileAddNewBanner text={translate(DICTIONARY.ADD_EMPLOYEE.X)} onClick={() => setAdd(!add)}/> : <></>}
		</div> : <></>}
	</>
}

export default MobileEmployees