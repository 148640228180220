import React from 'react'
import {store} from "../../index"
import {actions} from "../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../globals/constants/client/constants"
import {getFromStorage, setToStorage, translate} from "../../globals/functions/client/localFunctions"
import MobileBackButton from "./MobileBackButton"
import MobileFontAwesome from "../main/MobileFontAwesome"

const MobilePasswordPageInfo = props => {
  const currentState = store.getState()
  const levelOne = currentState.window.current.levelOne
  const form = getFromStorage("form", "session")
  // console.log("form", form)
  return <>
    <MobileBackButton text={form.account.username}
      onClick={() => {
        setToStorage("form", {account: {username: null, securityPhrase: null},
          loginAttempt: 0, authentication: "FIRST_LOGIN_ATTEMPT", userIdProvided: false, userIdFound: false}, "session")
          store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.window}})
    }}/>
    <div className={`securityPhrase w3-display-middle w3-center`} style={{marginTop: "-32vh"}}>
      <i style={{fontSize: "1.5vh", opacity: "0.5", display: "block"}}>
          {translate(DICTIONARY.PERSONAL_SECURITY_PHRASE.X)}
      </i>
      <span style={{fontSize: "1.5vh", marginTop: "18vh"}}>
          {`${form.account.securityPhrase}`}
    </span>
    </div>
    <div className={`help w3-display-right w3-center`}
         style={{fontSize: "3.5vh", marginTop: "46vh", marginRight: "2vh", opacity: "0.5", zIndex: "3"}}>
      <MobileFontAwesome id={"helpButton"} icon={ICONS.QUESTION_CIRCLE} className={null}
        style={{padding: "1vh 0vw", fontSize: "3vh", height: "6vh", minWidth: "6vh"}}
        onClick={() => store.dispatch({type: actions.SET_CURRENT_WINDOW,
          values: {window: PAGE_STATES[levelOne].LOGIN.EXISTING_USER.HELP.X}})}/>
    </div>
  </>
}

export default MobilePasswordPageInfo