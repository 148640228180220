import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.refunds
 * @param {string[]} props.order.refunds.receipts
 * @param {Account} props.order.provider
 * @param {Name} props.order.provider.name
 * @return {JSX.Element}
 * @constructor
 */
const OrderReceiptButtons = props => {
	const order = props.order
	return order.refunds.receipts.length > 0 && order.refunds.receipts[0] ?
		order.refunds.receipts.map((receipt, index) => <div key={`order-${order.id}-details-receipt-${index}`}
			style={{margin: "1vh 2vh"}}>
			<a href={receipt}
				 download={`order-${order.id}-receipt-${order.provider.name.display}.pdf`} target={"_blank"} rel={"noreferrer"}>
				<MobileButtonGeneric id={"receipt"} type={"button"} text={{style: {paddingLeft: "2vh"},
					content: order.refunds.receipts.length > 1 ? `${translate(DICTIONARY.DOWNLOAD_RECEIPT.X)} (${index + 1})` :
						translate(DICTIONARY.DOWNLOAD_RECEIPT.X)}} left={{icon: ICONS.DOWNLOAD}}
					className={props.className ? props.className : "paralian-theme-level-1"}
					padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}} onClick={() => {}}/>
			</a>
		</div>) : <></>
}

export default OrderReceiptButtons