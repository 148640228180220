import React, {useState} from "react"
import {translate} from "../../../../globals/functions/client/localFunctions"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {IndexedArray} props.indexedArray
 * @param {number[]} props.options
 * @param {*[]} props.selected
 * @param {string} props.title
 * @return {JSX.Element}
 * @constructor
 */
const MobileSelectSingleOrMultiple = props => {
	const [selected, setSelected] = useState(null)
	const options = props.options.map(option => {
		const key = `${props.id}-option-${option}`
		return <div key={key} style={{padding: "2vh 0vh"}} onClick={() => {
			setSelected(option)
			props.setSelected(option, props.options)
		}}
		className={props.options.length === 1 ? "" : option === selected ?
			"paralian-theme-level-4-flipped" : "paralian-theme-level-2"}>
			{translate(props.indexedArray.byIndex(option).label)}
		</div>
	})
	return <div key={`${props.id}-main`} style={{padding: "2vh", margin: "1vh 0vh"}}
		className={props.options.length === 1 && props.options[0] === selected ?
			"paralian-theme-level-2-flipped" : selected === null ?
				"paralian-theme-level-2" : "paralian-theme-level-2-flipped"}
		onClick={() => {
			if (props.options.length === 1) {
				setSelected(props.options[0])
				props.setSelected(props.options[0], props.options)
			}
		}}>
		<div style={{padding: "1vh 0vh"}}>
			<b>{props.title}</b>
		</div>
		{options}
	</div>
}

export default MobileSelectSingleOrMultiple