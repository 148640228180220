import React from "react"
import {FUEL} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {boolean} props.autoFocus
 * @param {boolean} props.required
 * @param {boolean} props.showLabel
 * @returns {JSX.Element}
 * @constructor
 */
const MobileFuelDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const defaultValue = props.defaultValue || FUEL.PETROL_87
	const id = `${props.id}-engine-fuel`
	const list = Object.values(FUEL).map(value => {return {value: value, label: translate(value)}})
	const onChange = props.setValue ? () => {
		try {
			const element = document.getElementById(`${id}-input`)
			props.setValue(element.value)
		} catch (e) {
			console.log(e)
		}
	} : undefined
	return <MobileInputGeneric type={"dropdown"} id={id} name={id} form={props.form} autoFocus={props.autoFocus}
		required={props.required} defaultValue={defaultValue} multiple={false} className={className} size={"1"}
		showLabel={props.showLabel}	list={list} label={translate(DICTIONARY.FUEL.X)} style={props.style} onChange={onChange}/>
}

export default MobileFuelDropdown