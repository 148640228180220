import React from "react"
import MobileSVGIcon from "./MobileSVGIcon"

const BoatyardIcon = props => {
	const icon = <g>
		<path id={"svg_8"} d={"m254.99802,52.70313l-187,-0.45313c-25.5,251.45313 120.5,180.45313 186,301l0,-300.54687z"}
			fillOpacity={"null"} strokeOpacity={"null"} strokeWidth={"null"} stroke={"currentColor"} fill={"currentColor"}/>
		<path stroke={"currentColor"} id={"svg_9"} d={"m253.60047,52.29688l186.39755,0.90626c21.08275,251.45313 " +
			"-133.65345,179.45313 -186,300l0,-300z"} fillOpacity={"0.4"} strokeOpacity={"null"} strokeWidth={"0"}
			fill={"currentColor"}/>
		<path stroke={"currentColor"} id={"svg_12"} d={"m13,447.20314l88.5,-172.45313c6.5,-15.54687 -0.5,-25.54687 " +
			"-16,-34l8,-14l66,46l-11,10c-16.5,-10.54687 -26.5,-11.54687 -34,4l-88,173l-16.5,-5.54687z"} fillOpacity={"0.4"}
			strokeOpacity={"null"} strokeWidth={"0"} fill={"currentColor"}/>
		<path stroke={"currentColor"} id={"svg_13"} d={"m498.73913,447.20314l-96.19565,-172.45313c-7.06522,-15.54687 " +
			"0.54348,-25.54687 17.3913,-34l-8.69565,-14l-71.73913,46l11.95652,10c17.93478,-10.54687 28.80435,-11.54687 " +
			"36.95652,4l95.65217,173l17.93478,-5.54687z"} strokeOpacity={"null"} strokeWidth={"0"} fill={"currentColor"}/>
	</g>
	return <MobileSVGIcon icon={icon} onClick={props.onClick}/>
}

export default BoatyardIcon