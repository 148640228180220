import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {setEngineSn} from "../../../../globals/functions/client/serverFunctions"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.engine
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselEngineSNEdit = props => {
	const onSubmit = async event => {
		try {
			event.preventDefault()
			const serialNumber = document.getElementById(`${props.engine.id}-engine-sn-input`).value
			const response = await setEngineSn(props.id, props.engine.id, serialNumber)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess(serialNumber)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <MobileInputText id={`${props.engine.id}-engine-sn`} name={`${props.engine.id}-engine-sn`}
		showLabel={false} maxLength={"24"} required={true} placeholder={null} autoFocus={false}
		label={translate(DICTIONARY.SERIAL_NUMBER.X)} className={"paralian-theme-level-1"} style={{margin: "1vh 0vw"}}
		form={`${props.id}-engine-sn-form`}	autoComplete={"off"} defaultValue={props.engine.serialNumber}/>
	return <MobileInputForm id={`${props.id}-engine-sn`} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselEngineSNEdit