import { typeOrNull, enumOrNull } from '../../functions/shared/local';
import { ID_TYPE } from '../../constants/shared/enumerators';
import DataAccess from './DataAccess';
import PhysicalAccess from './PhysicalAccess';
import Image from './Image';
import Name from './Name';
import OrdersAccess from './OrdersAccess';
import OwnershipAccess from './OwnershipAccess';

class Accesses {
	/**
	 *
	 * @param {Accesses} [accesses = null]
	 * @param {number} [accesses.id]
	 * @param {string} [accesses.username]
	 * @param {Name} [accesses.name]
	 * @param {Image} [accesses.image]
	 * @param {DataAccess} [accesses.data]
	 * @param {PhysicalAccess} [accesses.physical]
	 * @param {OwnershipAccess} [accesses.ownership]
	 * @param {boolean} [accesses.isOwner]
	 */
	constructor(accesses = null) {
		try {
			this.id = typeOrNull(accesses.id, "number")
			this.idType_ = enumOrNull(accesses.idType || accesses.idType_, ID_TYPE)
			this.username = typeOrNull(accesses.username, "string")
			this.name = new Name(accesses.name)
			this.image = new Image(accesses.image)
			this.orders_ = new OrdersAccess(accesses.orders || accesses.orders_)
			this.data_ = new DataAccess(accesses.data || accesses.data_)
			this.physical_ = new PhysicalAccess(accesses.physical || accesses.physical_)
			this.ownership = new OwnershipAccess(accesses.ownership)
			this.isOwner = typeOrNull(accesses.isOwner, "boolean")
		} catch (e) {
			this.id = null
			this.idType_ = null
			this.username = null
			this.name = new Name()
			this.image = new Image()
			this.orders_ = new OrdersAccess()
			this.data_ = new DataAccess()
			this.physical_ = new PhysicalAccess()
			this.ownership = new OwnershipAccess()
			this.isOwner = null
		}
	}

	get orders() {
		return this.orders_
	}

	set orders(orders) {
		this.orders_ = new OrdersAccess(orders)
	}

	get idType() {
		return this.idType_
	}

	set idType(idType) {
		this.idType_ = enumOrNull(idType, ID_TYPE)
	}

	get data() {
		return this.data_
	}

	set data(data) {
		this.data_ = new DataAccess(data)
	}

	get physical() {
		return this.physical_
	}

	set physical(physical) {
		this.physical_ = new PhysicalAccess(physical)
	}
}

export default Accesses