import {typeOrNull} from '../../../functions/shared/local'

class Statistics {
	/**
	 *
	 * @param {Statistics} [statistics = null]
	 * @param {number} [statistics.population]
	 * @param {number} [statistics.count]
	 * @param {number} [statistics.mean]
	 * @param {number} [statistics.median]
	 * @param {number} [statistics.mode]
	 * @param {number} [statistics.standardDeviation]
	 * @param {number} [statistics.min]
	 * @param {number} [statistics.max]
	 * @param {number} [statistics.tenthPercentile]
	 * @param {number} [statistics.ninetiethPercentile]
	 * @param {number} [statistics.interquartileRange]
	 * @param {number} [statistics.skewness]
	 * @param {number} [statistics.kurtosis]
	 */
	constructor (statistics = null) {
		try {
			this.population = typeOrNull(statistics.population, "number")
			this.samples = typeOrNull(statistics.samples, "number")
			this.mean = typeOrNull(statistics.mean, "number")
			this.median = typeOrNull(statistics.median, "number")
			this.mode = typeOrNull(statistics.mode, "number")
			this.standardDeviation = typeOrNull(statistics.standardDeviation, "number")
			this.min = typeOrNull(statistics.min, "number")
			this.max = typeOrNull(statistics.max, "number")
			this.tenthPercentile = typeOrNull(statistics.tenthPercentile, "number")
			this.ninetiethPercentile = typeOrNull(statistics.ninetiethPercentile, "number")
			this.interquartileRange = typeOrNull(statistics.interquartileRange, "number")
			this.skewness = typeOrNull(statistics.skewness, "number")
			this.kurtosis = typeOrNull(statistics.kurtosis, "number")
		} catch (e) {
			this.population = null
			this.samples = null
			this.mean = null
			this.median = null
			this.mode = null
			this.standardDeviation = null
			this.min = null
			this.max = null
			this.tenthPercentile = null
			this.ninetiethPercentile = null
			this.interquartileRange = null
			this.skewness = null
			this.kurtosis = null
		}
	}

	get range() {
		return this.min && this.max ? this.max - this.min : null
	}

	get oneStdevRange() {
		return this.standardDeviation && this.mean ?
			{min: this.mean - this.standardDeviation, max: this.mean + this.standardDeviation} : {min: null, max: null}
	}

	get twoStdevRange() {
		return this.standardDeviation && this.mean ?
			{min: this.mean - 2*this.standardDeviation, max: this.mean + 2*this.standardDeviation} : {min: null, max: null}
	}

	get threeStdevRange() {
		return this.standardDeviation && this.mean ?
			{min: this.mean - 3*this.standardDeviation, max: this.mean + 3*this.standardDeviation} : {min: null, max: null}
	}
}

export default Statistics;