import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, PAGE_STATES} from "../../../../globals/constants/client/constants"
import {getFromStorage, translate} from "../../../../globals/functions/client/localFunctions"
import RadioButton from "./RadioButton"

const ExteriorCleaningType = props => {
	const [selected, setSelected] = useState(null)
	const currentState = store.getState()
	const levelOne = currentState.window.current.levelOne
	const parentPage = PAGE_STATES[levelOne].MAIN.REQUESTS
	// console.log(selected)
	const onClick = id => {
		setSelected(id)
		if (props.onClick) {props.onClick(id)}
	}
	useEffect(() => {
		const session = getFromStorage("order", "session")
		const services = session.servicesExterior
		// console.log("services", services)
		if (!services || services.length === 0) {
			setSelected("wash")
		} else if (services.some(v => v.id === -1)) {
			setSelected("exterior-none")
		} else if (services.some(v => v.id === 1105)) {
			setSelected("detailed-wash")
		} else if (services.some(v => v.id === 1015)) {
			setSelected("bottomScrub")
		} else if (services.some(v => [1099, 1101, 1007, 999999, 1006, 1100, 1012, 1102, 1103].includes(v.id))) {
			setSelected("detail")
		} else {
			setSelected("wash")
		}
		// console.log(selected)
	}, [])
	return <>
		<RadioButton id={"wash"} title={translate(DICTIONARY.STANDARD_WASH.X)} onClick={onClick} selected={selected === "wash"}
			defaultChecked={selected === "wash"} name={"exteriorCleaningType"}/>
		<RadioButton id={"detailed-wash"} title={translate(DICTIONARY.DETAIL_WASH.X)} onClick={onClick} selected={selected === "detailed-wash"}
			defaultChecked={selected === "detailed-wash"} name={"exteriorCleaningType"}/>
		<RadioButton id={"detail"} title={translate(DICTIONARY.DETAILING_SHINE.X)} onClick={onClick} selected={selected === "detail"}
			name={"exteriorCleaningType"} defaultChecked={selected === "detail"}/>
		<RadioButton id={"exterior-none"} title={translate(DICTIONARY.NONE.X)} onClick={onClick}
			selected={selected === "exterior-none"}	name={"exteriorCleaningType"} defaultChecked={selected === "exterior-none"}/>
		{props.isOrganization ?
			<div style={{padding: "4vh 0vh 2vh"}}
				onClick={() => store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: parentPage.VESSEL_CLEANING.ADVANCED.X }})}>
				<i>{translate(DICTIONARY.ADVANCED_SELECTION.X)}</i>
		</div> : <></>}
	</>
	//		<RadioButton id={"bottomScrub"} title={translate(DICTIONARY.BOTTOM_SCRUB_BARNACLE_REMOVAL.X)} onClick={onClick}
	// 			selected={selected === "bottomScrub"}	name={"exteriorCleaningType"} defaultChecked={selected === "bottomScrub"}/>
}

export default ExteriorCleaningType