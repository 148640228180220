import React from "react"
import {convertToPreferredUnits} from "../../globals/functions/client/localFunctions"

/**
 *
 * @param {Object} props
 * @param {string} props.units - UNIT_SYSTEM
 * @param {Object} props.value
 * @param {number} props.value.number
 * @param {string} props.value.units
 * @param {number} props.value.digits
 * @param {string} props.subtitle
 * @return {JSX.Element}
 * @constructor
 */
const MobileSubtitleAndValue = props => {
	let value
	/*    props.value.number ? value = <b>{convertToPreferredUnits(props.value.number, props.value.units)}</b> :
					value = <b>{props.value.text}</b>*/
	props.value.number ? value = convertToPreferredUnits(props.value.number, props.value.units, props.units, props.value.digits) :
		value = props.value.text

	return <div style={{margin: "2vh"}}>
		<div style={{textAlign: "left"}}><i>{props.subtitle}</i></div>
		<div style={{textAlign: "right", fontSize: "3vh"}} className={"w3-animate-opacity"}>
			{value || "#"}
		</div>
	</div>
}

export default MobileSubtitleAndValue