import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselEngineBMYEdit from "./MobileVesselEngineBMYEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileVesselEngineBMY = props => {
	const [edit, setEdit] = useState(false) // use this to control toggles using edit button
	const [bmy, setBmy] = useState({brand: props.engine.brand, model: props.engine.model, year: props.engine.year})
	const content = !edit ?
		<div className={"w3-animate-opacity"}>
			<div style={{fontSize: "4vh"}}>{bmy.brand}</div>
			<div style={{fontSize: "3vh"}}>{bmy.model}</div>
			<div style={{fontSize: "2vh"}}>{bmy.year}</div>
		</div> : <MobileVesselEngineBMYEdit id={props.id} engine={props.engine} onSuccess={bmy => {
			props.refresh().then()
			setBmy(bmy)
			setEdit(false)
		}}/>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.BRAND_MODEL_MODEL_YEAR.X)]}/>
		{content}
	</div>
}

export default MobileVesselEngineBMY