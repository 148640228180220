import React, {useEffect, useState, useTransition} from "react"
import {store} from "../../index"
import {PAGES} from "../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {getCurrentAccount, getMarinas, getNotifications, getOrders, getOrganization, getVessels}
	from "../../globals/functions/client/serverFunctions"
import {appendScript, getMarinaId, getMarinaIndex, getVesselId, getVesselIndex, setLanguage, setMarinaIndex,
	setTimeFormat, setUnits, setVesselIndex} from "../../globals/functions/client/localFunctions"
import {constructArray} from "../../globals/functions/shared/local"
import MobileAccounts from "./account/MobileAccounts"
import MobileVessels from "./vessels/MobileVessels"
import MobileMarinas from "./marinas/MobileMarinas"
import MobileQR from "./MobileQR"
import MobileOrders from "./orders/MobileOrders"
import MobileRequests from "./requests/MobileRequests"
import MobileTools from "./MobileTools"
import MobileMainScaffolding from "./frames/MobileMainScaffolding"
import MobileOrganization from "./organization/MobileOrganization"
// import MobileEmployees from "./employee/MobileEmployees"
import MobileCustomers from "./customers/MobileCustomers"
import MobileNotifications from "./MobileNotifications"
import MobileVerifications from "./MobileVerifications"
// import SplashScreen from "../../globals/components/SplashScreen"
import Account from "../../globals/classes/shared/Account"
import Vessel from "../../globals/classes/shared/Vessel"
import Organization from "../../globals/classes/shared/Organization"
// import {constructArray} from "../../shared/es6/functions/local"
// import MobileAdmin from "./employee/MobileAdmin"

/* TODO: welcome message and tutorial for first login attempt */

const MobileMain = props => {
	const [account, setAccount] = useState(new Account())
	const [organization, setOrganization] = useState(new Organization())
	const [vessels, setVessels] = useState({owned: [], customer: []})
	const [marinas, setMarinas] = useState([])
	const [orders, setOrders] = useState({placed: [], assigned: [], received: [], marina: []})
	const [notifications, setNotifications] = useState([])
	const refreshAccount = () => getCurrentAccount(false).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setAccount(new Account(response.payload))
		}
	})
	const refreshVessels = () => getVessels(false, account.isPersonal).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			const owned = constructArray(response.payload.owned, v => new Vessel(v))
			const customer = constructArray(response.payload.customer, v => new Vessel(v))
			setVessels({owned: owned, customer: customer})
		}
	})
	const refreshMarinas = () => getMarinas(false, account.isPersonal).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setMarinas(constructArray(response.payload, m => new Organization(m)))
		}
	})
	const refreshOrders = () => getOrders(false).then(response => {
		// console.log(response)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			/*
			props.setOrders({
				requester: constructArray(response.payload.requester, order => new Order(order)),
				requestee: constructArray(response.payload.requestee, order => new Order(order))
			})
			*/
			setOrders(response.payload)
		}
	})
	const refreshOrganization = () => getOrganization(false).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setOrganization(new Organization(response.payload))
		}
	})
	const refreshNotifications = () => getNotifications(false).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setNotifications(response.payload)
		}
	})
	// <script  type="text/javascript"  src="https://app.termly.io/resource-blocker/YOUR-WEBSITE-UUID?autoBlock=on"></script>
	appendScript(`https://app.termly.io/resource-blocker/c00a05f7-7258-4563-8769-6efa8bf53023?autoBlock=on`,
		[], "termly-script")
	useEffect(() => {
		getCurrentAccount(true).then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const account_ = new Account(response.payload)
				setUnits(account_.preferences.units, false)
				setLanguage(account_.preferences.language, false)
				setTimeFormat(account_.preferences.use24Hr ? "24hr" : "xm", false)
				// console.log(account_)
				getVessels(true, account_.isPersonal).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						const owned = constructArray(response.payload.owned, v => new Vessel(v))
						const customer = constructArray(response.payload.customer, v => new Vessel(v))
						// console.log(response.payload)
						if (owned.length > 0 && getVesselIndex() === -1) {
							setVesselIndex(getVesselId(), response.payload)
						} else if (customer.length > 0 && getVesselIndex() === -1) {
							setVesselIndex(getVesselId(), response.payload, "customer")
						}
						setVessels({owned: owned, customer: customer})
					}
				})
				getMarinas(true, account_.isPersonal).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						if (response.payload.length > 0 && getMarinaIndex() === -1) {
							setMarinaIndex(getMarinaId(), response.payload)
						}
						setMarinas(constructArray(response.payload, m => new Organization(m)))
					}
				})
				getNotifications(true, account_.type).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						setNotifications(response.payload)
					}
				})
				if (account_.isAdminOrEmployee) {
					getOrganization(true).then(response => {
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							setOrganization(new Organization(response.payload))
						}
					})
				}
				setAccount(account_)
			}
		})
	}, [])
	let window = store.getState().window.current
	if (!window.levelOne) {
		window = props.origin
	}
	let selected = null, content
	// console.log(window.levelThree)

	const [isPending, startTransition] = useTransition({timeoutMs: 3000})
	// const MobileVessels = React.lazy(() => import("./vessels/MobileVessels"))
	// const MobileAccounts = React.lazy(() => import("./vessels/MobileVessels"))
	// console.log("isTransitionPending", isPending)
	switch (window.levelThree) {
		case PAGES.COMMON.MAIN.ADMIN.X:
			selected = 10
			content = <MobileAccounts account={account} refreshAccount={refreshAccount}/> // <MobileAdmin/>
			break
		case PAGES.COMMON.MAIN.EMPLOYEE.X:
			selected = 10
			content = <MobileAccounts account={account} refreshAccount={refreshAccount}/>
			// content = <MobileEmployees account={account}/>
			break
		case PAGES.COMMON.MAIN.CUSTOMERS.X:
			selected = 12
			content = <MobileCustomers account={account}/> // <MobileCustomers/>
			break
		case PAGES.COMMON.MAIN.ORGANIZATION.X:
			selected = 11
			content = <MobileOrganization account={account} organization={organization} refreshOrganization={refreshOrganization}/>
			break
		case PAGES.COMMON.MAIN.USERS.X:
			selected = 2
			content = <MobileAccounts account={account} refreshAccount={refreshAccount}/>
			break
		case PAGES.COMMON.MAIN.VESSELS.X:
			selected = 3
			/*			content = <Suspense fallback={<SplashScreen/>}>
							<MobileVessels account={account} organization={organization} vessels={vessels} marinas={marinas}
								refreshAccount={refreshAccount} refreshVessels={refreshVessels}/>
						</Suspense>*/
			content = <MobileVessels account={account} organization={organization} vessels={vessels} marinas={marinas}
															 refreshAccount={refreshAccount} refreshVessels={refreshVessels}/>
			break
		case PAGES.COMMON.MAIN.MARINAS.X:
			selected = 4
			content = <MobileMarinas account={account} vessels={vessels} marinas={marinas}
															 refreshAccount={refreshAccount} refreshMarinas={refreshMarinas}/>
			break
		case PAGES.COMMON.MAIN.QR.X:
			selected = 5
			content = <MobileQR account={account}/>
			break
		case PAGES.COMMON.MAIN.ORDERS.X:
			selected = 6
			content = <MobileOrders account={account} organization={organization} orders={orders} vessels={vessels}
															marinas={marinas} refreshOrders={refreshOrders} refreshAccount={refreshAccount}
															refreshOrganization={refreshOrganization}/>
			break
		case PAGES.COMMON.MAIN.NOTIFICATIONS.X:
			selected = 8
			content = <MobileNotifications notifications={notifications} refreshNotifications={refreshNotifications}/>
			break
		case PAGES.COMMON.MAIN.TOOLS.X:
			selected = 9
			content = <MobileTools organization={organization} vessels={vessels} marinas={marinas}/>
			break
		case PAGES.COMMON.MAIN.VERIFICATIONS.X:
			selected = 13
			content = <MobileVerifications organization={organization} refreshOrganization={refreshOrganization}
				canEdit={true}/>
			break
		case PAGES.COMMON.MAIN.REQUESTS.X:
			selected = 7
			content = <MobileRequests origin={window} account={account} organization={organization} vessels={vessels}
				marinas={account.isMarina ? [organization] : marinas} refreshAccount={refreshAccount}
				refreshOrganization={refreshOrganization} refreshMarinas={refreshMarinas}
				refreshVessels={refreshVessels}/>
			break
		default :
			selected = (account.isEmployee || account.isAdmin) ? 6 : 7
			content = (account.isEmployee || account.isAdmin) ?
			<MobileOrders account={account} organization={organization} orders={orders} vessels={vessels}
				marinas={marinas} refreshOrders={refreshOrders} refreshAccount={refreshAccount}
				refreshOrganization={refreshOrganization}/> :
			<MobileRequests origin={window} account={account} organization={organization} vessels={vessels}
				marinas={account.isMarina ? [organization] : marinas} refreshAccount={refreshAccount}
				refreshOrganization={refreshOrganization} refreshMarinas={refreshMarinas}
				refreshVessels={refreshVessels}/>
			break
	}
	return <MobileMainScaffolding account={account} refreshAccount={refreshAccount} selected={selected} content={content}
		page={window} notificationsDot={notifications.length > 0}
		startTransition={startTransition}
		ordersContent={<MobileOrders account={account} organization={organization} orders={orders} vessels={vessels}
			marinas={marinas} refreshOrders={refreshOrders} refreshAccount={refreshAccount}
			refreshOrganization={refreshOrganization}/>}/>
}

export default MobileMain