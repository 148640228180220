import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.id
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {string} props.label
 * @param {boolean} props.showLabel
 * @param {boolean} props.hideInnerLabel
 * @param {Object[]} props.options
 * @param {string} props.options.label
 * @param {number} props.options.id
 * @param {string} [props.options.value]
 * @param {string} [props.options.dataA]
 * @param {string} [props.options.dataB]
 * @param {string} [props.options.dataC]
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileGenericDropdown = props => {
	const options = props.options || []
	const list = []
	const onChange = props.onChange ? props.onChange : () => {}
	const defaultValue = props.defaultValue > -1 ? props.defaultValue : 0
	try {
		const label = props.showLabel || props.hideInnerLabel ? "" : `${props.label}: `
		for (let i = 0; i < options.length; i++) {
			list[i] = {label: `${label}${options[i].label}`, value: i, dataId: options[i].id,
				dataA: options[i].dataA, dataB: options[i].dataB, dataC: options[i].dataC}
		}
	} catch (e) {
		console.log("error generating vessels list", e)
	}
	return list.length > 0 ? <MobileInputGeneric type={"dropdown"} id={`${props.id}`} required={true} list={list}
		className={props.className || "paralian-theme-level-1-flipped"} size={"1"} showLabel={props.showLabel}
		label={props.label} multiple={false} topRadius={props.topRadius} defaultValue={list[defaultValue].value}
		name={props.label} form={props.form} autoFocus={false} left={props.left} bottomRadius={props.bottomRadius}
		dataId={list[0].dataId} dataIndex={list[defaultValue].value} dataA={list[defaultValue].dataA}
		dataB={list[defaultValue].dataB} dataC={list[defaultValue].dataC} onChange={() => {
			try {
				const element = document.getElementById(`${props.id}-input`)
				const id = list[element.value].dataId
				element.setAttribute("data-id", id)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", list[element.value].dataA)
				element.setAttribute("data-b", list[element.value].dataB)
				element.setAttribute("data-c", list[element.value].dataC)
				onChange(id)
			} catch (e) {
				console.log(e)
			}
	}}/> : <></>
}

export default MobileGenericDropdown