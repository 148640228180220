import React, {useEffect, useMemo, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputHours from "./MobileInputHours"
import MobileInputNewHoliday from "./MobileInputNewHoliday"
import MobileHolidayDropdown from "../../shared/input/dropdown/MobileHolidayDropdown"
import Time from "../../../globals/classes/shared/Time"
import Day from "../../../globals/classes/shared/Day"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string|null} props.name
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Day|null} props.day
 * @param {Holiday|null} props.holiday
 * @param {Object} props.left
 * @param {boolean} props.use24Hr
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputDay = props => {
	const [state, setState] = useState("open")
	const [other, setOther] = useState(false)
	const day_ = useMemo(() => props.holiday ? new Day(props.holiday.day) : new Day(props.day),
		[props.holiday, props.day])
	const className = props.className || "paralian-theme-level-1-flipped"
	let subtitle, next, color = "paralian-theme-level-4-flipped", open, close
	useEffect(() => {
		const day__ = props.holiday ? new Day(props.holiday.day) : new Day(props.day)
		setState(day__.isOpen24Hrs ? "open 24 hours" : day__.isOpen ? "open" : "closed")
	}, [props.holiday, props.day])
	switch (state) {
		case "closed":
			subtitle = translate(DICTIONARY.CLOSED.X)
			color = className === "paralian-theme-level-1-flipped" ? "paralian-theme-level-2" : "paralian-theme-level-1"
			next = "open"
			open = new Time({hour: 0, minute: 0, xm: props.use24Hr ? null : "AM"})
			close = new Time({hour: 0, minute: 0, xm: props.use24Hr ? null : "PM"})
			break
		case "open 24 hours":
			subtitle = translate(DICTIONARY.OPEN_24_HR.X)
			next = "closed"
			color = "paralian-theme-level-1-flipped"
			open = new Time({hour: 0, minute: 0, xm: props.use24Hr ? null : "AM"})
			close = new Time({hour: props.use24Hr ? 23 : 11, minute: 59, xm: props.use24Hr ? null : "PM"})
			break
		default:
			subtitle = translate(DICTIONARY.OPEN_ALT.X)
			next = "open 24 hours"
			open = typeof day_.open.hour === "number" ? day_.open : new Time({hour: 8, minute: 0, xm: props.use24Hr ? null : "AM"})
			close = typeof day_.close.hour === "number" ? day_.close : new Time({hour: props.use24Hr ? 17 : 5, minute: 0, xm: props.use24Hr ? null : "PM"})

	}
	const _other = props.holiday ? other || props.holiday.isCustom ?
		<MobileInputNewHoliday id={`${props.id}-new`} holiday={props.holiday} className={className}/> : <></> : <></>
	return <div style={{textAlign: "center", margin: "2vh 0vh"}}>
		<input id={`${props.id}-state`} style={{display: "none"}} type={"text"} name={"state"} data-state={state}/>
		{props.holiday ? <MobileHolidayDropdown id={`${props.id}-holiday`} left={props.left} className={color}
			disabled={props.disableHolidayDropdown}	showLabel={false} form={props.form} setOther={setOther}
			style={{fontWeight: "bold"}} holiday={props.holiday} topRadius={"1vh"} bottomRadius={"0vh"}/> : <></>}
		<div style={{fontSize: "4vh", padding: "1vh 0vh", borderBottomLeftRadius: "1vh", borderBottomRightRadius: "1vh",
			borderTopLeftRadius: props.holiday ? "0vh" : "1vh", borderTopRightRadius: props.holiday ? "0vh" : "1vh"}}
		 	className={`${color} w3-ripple`}
			onClick={() => {
				setState(next)
			}}>
			{props.holiday ? <></> : <div>{props.name}</div>}
			<div style={{fontSize: "2vh"}}>{subtitle}</div>
		</div>
		{state === "open" ? <MobileInputHours id={props.id} form={props.form} use24Hr={props.use24Hr}
			open={open} close={close} className={className} /> : <></>}
		{_other}
	</div>
}

export default MobileInputDay