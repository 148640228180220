import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {PRICING_STRUCTURE_UNITS, UNIT_SYSTEM} from "../../../globals/constants/shared/enumerators"
import {getUnits, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputCurrency from "../../shared/input/text/MobileInputCurrency"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import MobilePricingExample from "./MobilePricingExample"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import Servicio from "../../../globals/classes/shared/Servicio"
import Currency from "../../../globals/classes/shared/Currency"
import Units from "../../../globals/classes/client/Units"
/**
 *
 * @param {Object} props
 * @param {string} props.form
 * @param {Currency} props.currency
 * @param {Servicio} props.service
 * @param {React.HTMLAttributes.className} props.className
 * @param {IndexedArray} props.defaultPricingStructures
 * @param {boolean} props.showLabel
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputPricing = props => {
	const service = new Servicio(props.service)
	const UNITS = new Units()
	const dps = props.defaultPricingStructures.byIndex(service.defaultPricingStructure)
	const dpsUnit = dps ? dps.unit : PRICING_STRUCTURE_UNITS.METER
	const currency = service.pricing.currency.code ? service.pricing.currency : new Currency(props.currency)
	const globalUnitSystem = getUnits()
	const [localUnitSystem, setLocalUnitSystem] = useState(globalUnitSystem)
	const [price, setPrice] = useState(currency.asZeroDecimal(currency.asNumber(UNITS.convert(service.pricing.price, dpsUnit,
		UNITS.ofType(dpsUnit).SI.SHORT, true, localUnitSystem))) || 0)
	const [min, setMin] = useState(service.pricing.floor || 0)
	const [toggle, setToggle] = useState(service.id ? service.pricing.unit && service.pricing.price ?
		"BASIC" : service.pricing.formula ? "COMPOSITE" : !service.defaultPricingStructure ? "COMPOSITE" : "BASIC" : "BASIC")
	const translatedLabels = service.labelsAsString
	const label = props.showLabel ?
		<div className={"paralian-theme-level-4-flipped"} style={{width: "100%", padding: "1vh", margin: "1vh 0vh",
			fontSize: "2vh", borderRadius: "1vh"}}>
			<b>{translatedLabels}</b>
		</div> : <></>
	const input = toggle === "COMPOSITE" ?
		<textarea id={`${service.id}-formula`} form={props.form} name={"formula"} required={true}
		style={{padding: "2vh", width: "100%", height: "30vh", borderRadius: "1vh"}} className={props.className ||
			"paralian-theme-level-1-flipped"} wrap={"soft"}
		placeholder={service.pricing.formula && !(service.pricing.unit && service.pricing.price) ?
			service.pricing.formula : translate(DICTIONARY.COMPOSITE_PRICING_MESSAGE.X)}/> :
		<div style={{fontSize: "3vh", borderRadius: "1vh"}}>
			<div style={{display: "flex"}}>
				<MobileInputCurrency id={`${service.id}-price`} form={`${service.id}-form`} currency={currency}
					setValue={value => setPrice(dps.canEdit ? currency.asZeroDecimal(value) : 1)}
					defaultValue={dps.canEdit ? (currency.asNumber(UNITS.convert(service.pricing.price, dpsUnit,
						UNITS.ofType(dpsUnit).SI.SHORT, true, localUnitSystem)) || 0) : 1} className={props.className}/>
				<div className={"paralian-theme-level-1-flipped"} style={{width: "100%", marginLeft: "1vh", borderRadius: "1vh"}}>
					<div style={{margin: "auto"}}>
						<div>{`- ${translate(DICTIONARY.PER.X)} -`}</div>
						<div style={{paddingTop: "1vh"}}>
							<MobileInputGeneric type={"dropdown"} id={`${service.id}-unit`} size={"1"} showLabel={false}
								name={"unit"}	form={props.form}	label={translate(DICTIONARY.UNIT.X)}
								multiple={false} autoFocus={false} required={true}
								list={UNITS.translated(dpsUnit, UNIT_SYSTEM.SI) === UNITS.translated(dpsUnit, UNIT_SYSTEM.US) ?
									[{value: UNIT_SYSTEM.SI, label: UNITS.translated(dpsUnit, UNIT_SYSTEM.SI)}] :
									[{value: UNIT_SYSTEM.SI, label: UNITS.translated(dpsUnit, UNIT_SYSTEM.SI)},
									{value: UNIT_SYSTEM.US, label: UNITS.translated(dpsUnit, UNIT_SYSTEM.US)}]}
								defaultValue={localUnitSystem}
								labelStyle={{textAlign: "center", fontSize: "1.5vh", fontStyle: "italic"}}
								dataA={UNITS.ofType(dpsUnit)[localUnitSystem].CAPS} dataB={UNITS.ofType(dpsUnit)[localUnitSystem].SHORT}
								onChange={() => {
									try {
										const element = document.getElementById(`${service.id}-unit-input`)
										element.setAttribute("data-a", UNITS.ofType(dpsUnit)[element.value].CAPS)
										element.setAttribute("data-b", UNITS.ofType(dpsUnit)[element.value].SHORT)
										setLocalUnitSystem(element.value)
									} catch (e) {
										console.log(e)
									}
								}}/>
						</div>
					</div>
				</div>
			</div>
			<div style={{display: "flex", marginTop: "1vh"}}>
				<MobileInputCurrency id={`${service.id}-min`} form={props.form} currency={currency} className={props.className}
					setValue={value => setMin(dps.canEdit ? currency.asZeroDecimal(value) : 0)}
					defaultValue={dps.canEdit ? (currency.asNumber(service.pricing.floor) || 0) : 0}/>
				<div className={"paralian-theme-level-1-flipped"} style={{width: "100%", marginLeft: "1vh",
					padding: "4vh 0vh", borderRadius: "1vh"}}>
					{translate(DICTIONARY.MINIMUM.X)}
				</div>
			</div>
			{dps.showExample ? <MobilePricingExample id={`${service.id}-example`} dpsUnit={dpsUnit}
				flipColor={props.exampleFlipColor} unitSystem={localUnitSystem} price={price} min={min}
				currency={currency}/> : <></>}
		</div>
	return <div style={{textAlign: "center", marginBottom: "4vh"}}>
		{label}
		<div style={{width: "100%", height: "5vh", margin: "1vh 0vh", padding: "1vh 0vh", borderRadius: "1vh"}}
				 className={"w3-display-container paralian-theme-level-1"}>
			<MobileInputSlider id={`${service.id}-isComposite`} label={translate(DICTIONARY.COMPOSITE_PRICING.X)}
				height={"3vh"} width={"8vh"} className={"w3-display-middle"} form={props.form}
				defaultChecked={service.id ? service.pricing.unit && service.pricing.price ?
					false : !!service.pricing.formula : false}
				onChange={() => setToggle(toggle === "BASIC" ? "COMPOSITE" : "BASIC")} padding={"0.25vh 0vh 0.25vh 2vh"}/>
		</div>
		{input}
	</div>
}

export default MobileInputPricing