import { STANDARD_ACCESS } from '../../constants/shared/enumerators';
import { enumArray, typeOrNull } from '../../functions/shared/local';

class DataAccess {
	/**
	 *
	 * @param {DataAccess} [access = null]
	 * @param {boolean} [access.fullProfile]
	 * @param {boolean} [access.limitedProfile]
	 * @param {boolean} [access.minimalProfile]
	 * @param {number|string|Date} [access.granted]
	 * @param {number|string|Date} [access.starts]
	 * @param {number|string|Date} [access.ends]
	 */
	constructor (access = null) {
		try {
			this.fullProfile = enumArray(access.fullProfile, STANDARD_ACCESS)
			this.limitedProfile = enumArray(access.limitedProfile, STANDARD_ACCESS)
			this.minimalProfile = enumArray(access.minimalProfile, STANDARD_ACCESS)
			this.dateGranted = typeOrNull(access.dateGranted, "date")
			this.startDate = typeOrNull(access.startDate, "date")
			this.endDate = typeOrNull(access.endDate, "date")
		} catch (e) {
			this.fullProfile = []
			this.limitedProfile = []
			this.minimalProfile = []
			this.dateGranted = null
			this.startDate = null
			this.endDate = null
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	get any() {
		return (this.fullProfile.length + this.limitedProfile.length + this.minimalProfile.length) > 0 &&
			(!this.endDate || (new Date(this.endDate)).getTime() > Date.now())
	}

	/**
	 *
	 * @param {number|string|Date} date
	 * @return {Date|null}
	 */
	asDate(date) {
		return date !== null ? new Date(date) : null
	}

	/**
	 *
	 * @param {number|string|Date} date
	 * @return {number|null}
	 */
	asNumber(date) {
		return this.asDate(date) !== null ? this.asDate(date).getTime() : null
	}

	/**
	 *
	 * @return {boolean}
	 */
	get isActive() {
		return this.endDate === null ? true : (this.asNumber(this.endDate) - Date.now()) > 0
	}

	/**
	 *
	 * @param {number|string|Date} date
	 * @return {string}
	 */
	asLocaleString(date) {
		return date !== null ?
			this.asDate(date).toLocaleString(undefined, {timeZone: "UTC", day: "numeric", month: "long", year: "numeric"}) : ""
	}
}

export default DataAccess;