import React, {useEffect, useState} from "react"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../../globals/constants/client/constants"
import {checkIfPushEnabled,	getFromStorage,	manageCrash, translate} from "../../../globals/functions/client/localFunctions"
import MobileParentContainer from "../../shared/frames/MobileParentContainer"
import MobileFontAwesome from "../MobileFontAwesome"
import Modal from "../../../globals/components/Modal"
import MobileMenu from "../MobileMenu"
import MobileImage from "../account/MobileImage"
import imagePlaceholder from "../../../images/user.svg"
import paralianLogo from "../../../images/bow-logo-dark-standalone-3d-glow.png"
import {ACCOUNT_TYPE} from "../../../globals/constants/shared/enumerators"
/**
 * 
 * @param {Object} props
 * @param {Account} props.account
 * @param {number[]} props.opacity
 * @param {object} props.page
 * @param {JSX.Element} props.content
 * @return {JSX.Element}
 * @constructor
 */
const MobileMainScaffolding = props => {
  const [showMenu, setShowMenu] = useState(false)
  const [showProfiles, setShowProfiles] = useState(false)
	useEffect(() => {checkIfPushEnabled().then()}, [])
	const transfer = obj => props.startTransition(() => {
		store.dispatch(obj)
	})
	// useEffect(() => console.log("fullscreen", document.fullscreenElement), [])
  try {
		// console.log("fullscreen", document.fullscreenElement)
    const menu = showMenu ? <Modal id={"main-menu"} icon={ICONS.BARS} padding={"0vh"} onClick={() => setShowMenu(false)}
      title={translate(DICTIONARY.MENU.X)} body={<MobileMenu account={props.account} refreshAccount={props.refreshAccount}/>}/> : <></>
    const orgLogo = getFromStorage("logo")
    const icon = orgLogo || paralianLogo
    const state = store.getState()
    const iconStyle = {padding: "1.5vh", height: "6vh", width: "6vh", fontSize: "3.5vh"}
    let window = state.window.current
    if (!window.levelOne) {window = props.page}
    const levelOne = window.levelOne
		// console.log("props.account", props.account, "props.account.isBusiness", props.account.isBusiness, "props.account.isEmployee", props.account.isEmployee)
    const bottomRow = [
			<MobileFontAwesome key={"tools-icon"} id={"tools-icon"} style={{...iconStyle}} selected={props.selected === 9}
				label={translate(DICTIONARY.TOOLS.X)} onClick={() => transfer({type:
				actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].MAIN.TOOLS.X}})} icon={ICONS.TOOLS}/>,
			<MobileFontAwesome key={"services-icon"} id={"services-icon"} style={{...iconStyle}} selected={props.selected === 6}
        label={translate(DICTIONARY.ORDERS.X)} onClick={() => transfer({type:
        actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].MAIN.ORDERS.X}})} icon={ICONS.CALENDAR_ALT}/>]
		if (props.account.isPersonal) {
			bottomRow.push(<MobileFontAwesome key={"requests-icon"} id={"requests-icon"} style={{...iconStyle}} selected={props.selected === 7}
				label={translate(DICTIONARY.REQUESTS.X)} onClick={() => {
				// setToStorage("order", {}, "session")
				transfer({type:	actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].MAIN.REQUESTS.X}
				})
			}} icon={ICONS.TRUCK}/>)
		}
		bottomRow.push(<MobileFontAwesome key={"notifications-icon"} id={"notifications-icon"} style={{...iconStyle}}
			selected={props.selected === 8} label={translate(DICTIONARY.NOTIFICATIONS.X)} onClick={() => transfer({type:
			actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].MAIN.NOTIFICATIONS.X}})}
			icon={props.notificationsDot ? ICONS.BELL_ON_SHAKE : ICONS.BELL}/>)
		if (`${props.account.id}` === process.env.REACT_APP_ADMIN_ID) {
			bottomRow.push(<MobileFontAwesome key={"concierge-icon"} id={"concierge-icon"} style={{width: "6.5vh"}}
				selected={props.selected === 14} label={translate(DICTIONARY.ACCOUNT_PROFILE.X)} icon={ICONS.CONCIERGE_BELL}
				onClick={() => {
					transfer({
						type: actions.SET_CURRENT_WINDOW,
						values: {window: PAGE_STATES[levelOne].MAIN.CONCIERGE.X}
					})
				}}/>)
		}
    let topRow = [
      <MobileFontAwesome key={"vessel-icon"} id={"vessel-icon"} style={{width: "6.5vh"}}
				selected={props.selected === 3} label={translate(DICTIONARY.VESSELS.X)} icon={ICONS.SHIP}
        onClick={() => {
					setShowProfiles(false)
					transfer({
						type: actions.SET_CURRENT_WINDOW,
						values: {window: PAGE_STATES[levelOne].MAIN.VESSELS.X}
					})
				}}/>]
    if (props.account.isPersonal) {
      topRow = [
        <MobileFontAwesome key={"user-icon"} id={"user-icon"} style={{width: "6.5vh"}}
					selected={props.selected === 2} label={translate(DICTIONARY.ACCOUNT_PROFILE.X)} icon={ICONS.USER}
          onClick={() => {
						setShowProfiles(false)
						transfer({
							type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[levelOne].MAIN.USERS.X}
						})
					}}/>, ...topRow
      ]
    }
    if (props.account.isEmployee) {
      topRow = [
        <MobileFontAwesome key={"employee-icon"} id={"employee-icon"} style={{width: "6.5vh"}}
					selected={props.selected === 10} label={translate(DICTIONARY.EMPLOYEE_PROFILE.X)} icon={ICONS.USER_ASTRONAUT}
          onClick={() => {
						setShowProfiles(false)
						transfer({
							type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[levelOne].MAIN.EMPLOYEE.X}
						})
					}}/>, ...topRow
      ]
    }
    if (props.account.isAdmin) {
      topRow = [
        <MobileFontAwesome key={"admin-icon"} id={"admin-icon"} style={{width: "6.5vh"}}
					selected={props.selected === 10} label={translate(DICTIONARY.ADMIN.X)} icon={ICONS.USER_SHIELD}
          onClick={() => {
						setShowProfiles(false)
						transfer({
							type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[levelOne].MAIN.ADMIN.X}
						})
					}}/>, ...topRow]
    }
    if (props.account.isMarina) {
      topRow = [
        <MobileFontAwesome key={"organization-icon"} id={"organization-icon"} style={{
          width: "6.5vh"}} selected={props.selected === 11} label={translate(DICTIONARY.ORGANIZATION_PROFILE.X)}
					icon={ICONS.ANCHOR} onClick={() => {
						setShowProfiles(false)
						transfer({
							type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[levelOne].MAIN.ORGANIZATION.X}
						})
					}}/>, ...topRow]
      bottomRow.splice(4, 0, <MobileFontAwesome key={"approval-icon"} id={"approval-icon"}
        style={{...iconStyle}} selected={props.selected === 13} label={translate(DICTIONARY.MARINA_SERVICE_ACCESS.X)}
        onClick={() => transfer({type: actions.SET_CURRENT_WINDOW,
          values: {window: PAGE_STATES[levelOne].MAIN.VERIFICATIONS.X}})} icon={ICONS.SHIELD_CHECK}/>)
    }
    if (props.account.isBusiness) {
      topRow = [
        <MobileFontAwesome key={"organization-icon"} id={"organization-icon"} style={{
          width: "6.5vh"}} selected={props.selected === 11} label={translate(DICTIONARY.ORGANIZATION_PROFILE.X)}
					icon={ICONS.STORE} onClick={() => {
						setShowProfiles(false)
						transfer({
							type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[levelOne].MAIN.ORGANIZATION.X}
						})
					}}/>, ...topRow]
    }
		if (!props.account.isMarina) {
			topRow = [
				...topRow,
				<MobileFontAwesome key={"marina-icon"} id={"marina-icon"} style={{width: "6.5vh"}}
					selected={props.selected === 4} label={translate(DICTIONARY.MARINAS.X)} icon={ICONS.ANCHOR}
					onClick={() => {
					setShowProfiles(false)
					transfer({
						type: actions.SET_CURRENT_WINDOW,
						values: {window: PAGE_STATES[levelOne].MAIN.MARINAS.X}
					})
				}}/>]
		}
		topRow = [...topRow,
			<MobileFontAwesome key={"qr-icon"} id={"qr-icon"} style={{width: "6.5vh"}}
				selected={props.selected === 5} label={translate(DICTIONARY.QR_CODE.X)} icon={ICONS.QR_CODE} onClick={() => {
					setShowProfiles(false)
					transfer({
						type:
						actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].MAIN.QR.X}
					})
			}}/>
		]
		const header = <div className={"headerMainCenter w3-display-topmiddle paralian-theme-level-1"}
			style={{width: "100%", height: "8vh", paddingTop: "3vh", zIndex: "2", fontSize: "3.5vh"}}>
			<div className={"headerOneMainCenter w3-display-container"} style={{width: "100%", height: "50%"}}>
				<div className={"bell w3-display-left"}>
					<MobileFontAwesome id={"menu-icon"} style={{height: "6vh", width: "6vh",
						zIndex: "11", padding: "3vh"}} selected={props.selected === 0} label={translate(DICTIONARY.MENU.X)}
						onClick={() => setShowMenu(!showMenu)} icon={ICONS.BARS}/>
				</div>
				<picture className={"w3-display-middle"}>
					<img src={icon} alt={"icon"} style={{height: "6vh", maxWidth: "300px"}}/>
				</picture>
				<div className={"user w3-display-right"}>
					<div className={"w3-ripple"} style={{padding: "0vh 3vh"}} onClick={() => setShowProfiles(!showProfiles)}>
						<MobileImage image={props.account.image.path ? props.account.image : {path: imagePlaceholder}} style={{fontSize: "unset"}}
							size={"4vh"} alt={"profile"} hideBackground={true} placeholder={imagePlaceholder}/>
					</div>
					{
						showProfiles ?
						<div className={"w3-animate-opacity"} style={{position: "absolute", top: "6vh", right: "2.125vh"}}>
						{topRow.map((v, i) => <div key={`top-row-${i}`} className={"paralian-theme-level-2"}
							style={{margin: "1vh 0vh", padding: "0.5vh 0vh", border: "solid",
							borderRadius: "50%", width: "6vh", height: "6vh"}}>{v}</div>)}
						</div> : <></>
					}
				</div>
			</div>
		</div>
		const footer = <div className={"mainCenterFooter w3-display-bottommiddle paralian-theme-level-1"}
			style={{width: "100%", paddingBottom: "1.5vh", fontSize: "3vh", display: "inline-flex", maxWidth: "50vh"}}>
			{bottomRow.map((v, i) => <div key={`footer-${i}`} style={{margin: "0vh auto"}}>{v}</div>)}
		</div>
		return <>
			{menu}
			<MobileParentContainer header={header} content={props.selected === 7 && (props.account.isEmployee || props.account.isAdmin) ?
				props.orders : props.content} footer={footer}/>
		</>
  } catch (e) {
    console.log("------------START--------------")
    console.log("MobileMainScaffolding" || "COMPONENT", e)
    console.log("------------END----------------")
    manageCrash(e, "MobileMainScaffolding")
  }
}

export default MobileMainScaffolding