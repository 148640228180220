import React, {useState} from "react"
import MobileVesselInviteOwnerInput from "./MobileVesselInviteOwnerInput"
import MobileVesselInviteBMY from "./MobileVesselInviteBMY"
import MobileVesselInviteOwnerOrgQuestion from "./MobileVesselInviteOwnerOrgQuestion"
import MobileVesselInviteSelect from "./MobileVesselInviteSelect"
import Phone from "../../../../globals/classes/shared/Phone"
import Country from "../../../../globals/classes/shared/Country"
import State from "../../../../globals/classes/shared/State"

/**
 *
 * @param {Object} props
 * @param {Account} props.account
 * @param {Organization} props.organization
 * @param {Organization[]} props.marinas
 * @param {function} props.refreshVessels
 * @param {function} props.setInvite
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselInvite = props => {
	const [page, setPage] = useState("IDENTIFIER")
	const [owner, setOwner] = useState({id: null, username: null, email: null, phone: new Phone(),
		country: new Country(), state: new State()})
	const [vesselsFound, setVesselsFound] = useState(false)
	// console.log([page, owner, vesselsFound])
	// first get vessel identifiers and check if vessel exists in database
	// if vessel exists in database, check if vessel has confirmed owner
	// if vessel has owner, notify that vessel has confirmed owner
	// if vessel does not have owner, ask for owner data
	// if vessel does not exist in database, ask for owner data

	// create vessel with data from database
	// allow edit of data before sending invite
	// allow businesses to propose edits to vessel profile with customer approval, hold edits in business' database...
	// ... and show differences while not reconciled
	/* TODO: add final confirmation page */
	switch (page) {
		case "VESSEL_SELECT":
			return <MobileVesselInviteSelect setPage={setPage} vesselsFound={vesselsFound} setInvite={props.setInvite}
				refreshVessels={props.refreshVessels}/>
		case "MORE_ABOUT_OWNER":
			return <MobileVesselInviteOwnerOrgQuestion setPage={setPage} owner={owner} setOwner={setOwner}/>
		case "BMY":
			// Vessel not found - complete profile then send invite
			return <MobileVesselInviteBMY setInvite={props.setInvite} refreshVessels={props.refreshVessels} owner={owner}/>
		default:
			return <MobileVesselInviteOwnerInput setPage={setPage} setOwner={setOwner} setVesselsFound={setVesselsFound}/>
	}
}

export default MobileVesselInvite