import React, {useState} from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {newMessage, togglePasswordVisibility, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"
import Icon from "../../../../globals/classes/shared/Icon"
/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.showLabel {boolean}
 * @param props.name {string}
 * @param props.username {string}
 * @param props.autoFocus {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @param {function} props.setPasswordsMatch
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputNewPassword = props => {
	const [icon, setIcon] = useState(ICONS.EYE_SLASH)
	const right = new Icon(props.right)
	const setPasswordsMatch = props.setPasswordsMatch ? props.setPasswordsMatch : () => {}
	return <>
		<MobileInputGeneric type={"password"} id={props.id} name={props.name} label={translate(DICTIONARY.NEW_PASSWORD.X)}
			showLabel={props.showLabel}	className={props.className} style={props.style} bottomRadius={"0vh"} form={props.form}
			topRadius={props.topRadius} left={{icon: ICONS.KEY, onClick: () => {newMessage("login",
				translate(TEXT_FIELD_PROPERTIES.password.hoverTitle),
				translate(TEXT_FIELD_PROPERTIES.password.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}
			username={props.username} autoFocus={true} autoComplete={"new-password"} placeholder={translate(DICTIONARY.NEW_PASSWORD.X)}
			 right={{icon: icon, onClick: () => {
				togglePasswordVisibility(`${props.id}-input`,`${props.id}-right`, setIcon)
				right.onClick()
			}}}/>
		<MobileInputGeneric type={"password"} id={`${props.id}-confirm`} className={props.className}
			style={{height: "6vh", borderBottomLeftRadius: props.bottomRadius, borderTopRightRadius: props.topRadius}}
			name={`${props.name}-confirm`} form={props.form} autoFocus={false} maxLength={"64"}	autoComplete={"off"}
			required={true} label={translate(DICTIONARY.CONFIRM_PASSWORD.X)} onChange={() => {
				const main = document.getElementById(`${props.id}-input`).value
				const confirm = document.getElementById(`${props.id}-confirm-input`).value
				setPasswordsMatch(main === confirm && main.length > 7)
			}}/>
	</>
}

export default MobileInputNewPassword