import React from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {updateOrgColors} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputColorPicker from "../../../main/color/MobileInputColorPicker"
import Colors from "../../../../globals/classes/shared/Colors"

const MobileLoginOrgColor = props => {
	const onSubmit = async event => {
		event.preventDefault()
		const primary = document.getElementById("color-primary-input").value
		const secondary = document.getElementById("color-secondary-input").value
		const colors = new Colors({primary: {hex: primary}, secondary: {hex: secondary}})
		const response = await updateOrgColors(colors)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
		}
	}
	const content =
		<div style={{fontSize: "3vh"}}>
			<MobileInputColorPicker id={"color-primary"} form={"form"} className={"paralian-theme-level-2"}
				label={translate(DICTIONARY.PRIMARY_COLOR.X)} showLabel={true} autoFocus={true} required={false}
				name={"color-primary"} left={{icon: ICONS.PAINT_ROLLER}} bottomRadius={"1vh"} topRadius={"1vh"}/>
			<MobileInputColorPicker id={"color-secondary"} form={"form"} className={"paralian-theme-level-2"}
				label={translate(DICTIONARY.SECONDARY_COLOR.X)} showLabel={true} autoFocus={true} required={false}
				name={"color-secondary"} left={{icon: ICONS.PAINT_BRUSH}} bottomRadius={"1vh"} topRadius={"1vh"}/>
		</div>
	return <MobileLoginFormScaffolding header={props.header}	content={content}	back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgColor