import {typeOrNull, enumOrNull, constructArray} from '../../functions/shared/local'
import { ORGANIZATION_TYPE, ORGANIZATION_CATEGORY, ORGANIZATION_STRUCTURE } from '../../constants/shared/enumerators'
import Addresses from './Addresses'
import Name from './Name'
import Image from './Image'
import Currency from './Currency'
import Coordinates from './Coordinates'
import OrganizationRegistration from './documents/OrganizationRegistration'
import Colors from './Colors'
import Timezone from './Timezone'
import Schedule from './Schedule'
import ContactInfo from './ContactInfo'
import Websites from './Websites'
import Documents from './Documents'
import OrderMetrics from './metrics/OrderMetrics'
import Area from './Area'
import Subscription from "./payments/Subscription"
import PaymentMethods from "./PaymentMethods"
import VesselStorageFacilities from "./marinas/VesselStorageFacilities"
import DocumentRequirements from "./DocumentRequirements"
import ServiceApprovals from "./ServiceApprovals"
import Servicio from "./Servicio"
import Employee from "./Employee"
import Holiday from "./Holiday"
import TaxExemption from "./TaxExemption"
/* TODO: privatize with properties_ with  when supported */
class Organization {
	/**
	 *
	 * @param {Organization} [organization = null]
	 * @param {number} [organization.id]
	 * @param {Name} [organization.name]
	 * @param {number} [organization.parent]
	 * @param {OrganizationRegistration} [organization.registration]
	 * @param {Websites} [organization.websites]
	 * @param {string} [organization.emailDomain]
	 * @param {Addresses} [organization.addresses]
	 * @param {Documents} [organization.documents]
	 * @param {ContactInfo} [organization.contactInfo]
	 * @param {Schedule} [organization.schedule]
	 * @param {Holiday[]} [organization.holidays]
	 * @param {Currency} [organization.currency]
	 * @param {Timezone} [organization.timezone]
	 * @param {Colors} [organization.colors]
	 * @param {Image} [organization.logo]
	 * @param {Image} [organization.image]
	 * @param {Image} [organization.icon]
	 * @param {Coordinates} [organization.coordinates]
	 * @param {string} [organization.structure]
	 * @param {string|number|Date} [organization.created]
	 * @param {string} [organization.type]
	 * @param {string} [organization.category]
	 * @param {boolean} [organization.isActive]
	 * @param {boolean} [organization.isPaused]
	 * @param {boolean} [organization.isTrustedVerifier]
	 * @param {Rating} [organization.rating]
	 * @param {OrgToOrgServiceApproval[]} [organization.serviceApprovals]
	 * @param {Object} [organization.payment]
	 * @param {Object[]} [organization.admins]
	 * @param {Object[]} [organization.employees]
	 * @param {Subscription} [organization.subscription]
	 * @param {string} [organization.kml]
	 * @param {MarinaPhysicalFacilities} [organization.physicalFacilities]
	 * @param {VesselStorageFacilities} [organization.vesselStorageFacilities]
	 * @param {DocumentRequirements} [organization.documentRequirements]
	 */
	constructor(organization = null) {
		try {
			this.id = organization.id
			this.name = new Name(organization.name)
			this.parent = typeOrNull(organization.parent, "number")
			this.registration = new OrganizationRegistration(organization.registration)
			this.websites = new Websites(organization.websites)
			this.emailDomain = typeOrNull(organization.emailDomain, "string")
			this.addresses = new Addresses(organization.addresses)
			this.documents = new Documents(organization.documents)
			this.contactInfo = new ContactInfo(organization.contactInfo)
			this.schedule = new Schedule(organization.schedule)
			this.holidays = constructArray(organization.holidays, v => new Holiday(v))
			this.currency = new Currency(organization.currency)
			this.timezone = new Timezone(organization.timezone)
			this.colors = new Colors(organization.colors)
			this.logo = new Image(organization.logo)
			this.icon = new Image(organization.icon)
			this.image = new Image(organization.image)
			this.coordinates = new Coordinates(organization.coordinates)
			this.created = typeOrNull(organization.created, "date")
			this.type_ = enumOrNull(organization.type || organization.type_, ORGANIZATION_TYPE)
			this.structure_ = enumOrNull(organization.structure || organization.structure_, ORGANIZATION_STRUCTURE)
			this.category_ = enumOrNull(organization.category || organization.category_, ORGANIZATION_CATEGORY)
			this.isActive = typeOrNull(organization.isActive, "boolean")
			this.isPaused = typeOrNull(organization.isPaused, "boolean")
			this.isTrustedVerifier = typeOrNull(organization.isTrustedVerifier, "boolean")
			this.metrics = new OrderMetrics(organization.metrics)
			this.payment = new PaymentMethods(organization.payment)
			this.stripeStatus = typeOrNull(organization.stripeStatus, "boolean")
			this.stripeLink = typeOrNull(organization.stripeLink, "string")
			this.serviceArea = new Area(organization.serviceArea)
			this.services = constructArray(organization.services, v => new Servicio(v))
			this.categories = constructArray(organization.categories, v => new Servicio(v))
			this.serviceApprovals = new ServiceApprovals(organization.serviceApprovals)
			this.admins = constructArray(organization.admins, v => new Employee(v))
			this.employees = constructArray(organization.employees, v => new Employee(v))
			this.isParalianCustomer = typeOrNull(organization.isParalianCustomer, "boolean")
			this.subscription = new Subscription(organization.subscription)
			this.score = typeOrNull(organization.isParalianCustomer, "number")
			this.connectId = typeOrNull(organization.connectId, "string")
			this.customerId = typeOrNull(organization.customerId, "string")
			this.timestamp = typeOrNull(organization.timestamp, "date")
			this.kml = typeOrNull(organization.kml, "string")
			this.physicalFacilities = constructArray(organization.physicalFacilities,
				v => typeOrNull(v, "string")).filter(v => v !== null)
			this.vesselStorageFacilities = new VesselStorageFacilities(organization.vesselStorageFacilities)
			this.documentRequirements = new DocumentRequirements(organization.documentRequirements)
			this.taxExemptions = constructArray(organization.taxExemptions, v => new TaxExemption(v))
		} catch (e) {
			this.id = null
			this.name = new Name()
			this.parent = null
			this.registration = new OrganizationRegistration()
			this.websites = new Websites()
			this.emailDomain = null
			this.addresses = new Addresses()
			this.documents = new Documents()
			this.contactInfo = new ContactInfo()
			this.schedule = new Schedule()
			this.holidays = []
			this.currency = new Currency()
			this.timezone = new Timezone()
			this.colors = new Colors()
			this.logo = new Image()
			this.icon = new Image()
			this.image = new Image()
			this.coordinates = new Coordinates()
			this.created = null
			this.type_ = null
			this.structure_ = null
			this.category_ = null
			this.isActive = null
			this.isPaused = null
			this.isTrustedVerifier = null
			this.metrics = new OrderMetrics()
			this.payment = new PaymentMethods()
			this.stripeStatus = null
			this.stripeLink = null
			this.serviceArea = new Area()
			this.services = []
			this.categories = []
			this.serviceApprovals = new ServiceApprovals()
			this.admins = []
			this.employees = []
			this.isParalianCustomer = null
			this.subscription = new Subscription()
			this.score = null
			this.connectId = null
			this.customerId = null
			this.timestamp = null
			this.kml = null
			this.physicalFacilities = []
			this.vesselStorageFacilities = new VesselStorageFacilities()
			this.documentRequirements = new DocumentRequirements()
			this.taxExemptions = []
		}
	}

	get type(){
		return this.type_
	}

	set type(type) {
		this.type_ = enumOrNull(type, ORGANIZATION_TYPE)
	}

	get structure() {
		return this.structure_
	}

	set structure(structure) {
		this.structure_ = enumOrNull(structure, ORGANIZATION_STRUCTURE)
	}

	get category() {
		return this.category_
	}

	set category(category) {
		this.category_ = enumOrNull(category, ORGANIZATION_CATEGORY)
	}

}

export default Organization