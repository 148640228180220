import TextInputFilter from './TextInputFilter'
/**
 * @extends TextInputFilter
 */
class Percent extends TextInputFilter {
	regexp = /[^0-9.]/gi
	minVal = 0
	maxVal = 1

	get formatted() {
		try {
			return this.number.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 0})
		} catch (e) {
			return ""
		}
	}
}

export default Percent