import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileVesselHullDimensions from "./MobileVesselHullDimensions"
import MobileVesselHullWeight from "./MobileVesselHullWeight"
import MobileVesselHullCapacity from "./MobileVesselHullCapacity"
/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel Id
 * @param {React.HTMLProps.style.zIndex} props.zIndex
 * @param {React.HTMLProps.style.top} props.top
 * @param {Hull} props.hull
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileHullSpecifications = props => {
	const [expanded, setExpanded] = useState(false)
	const content = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"} style={{fontSize: "2vh"}}>
		<MobileVesselHullDimensions id={props.id} dimensions={props.hull.dimensions} units={props.units}
			canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileVesselHullWeight id={props.id} weight={props.hull.dryWeight} units={props.units} canEdit={props.canEdit}
			refresh={props.refresh}/>
		<MobileVesselHullCapacity id={props.id} capacity={props.hull.capacity} units={props.units} canEdit={props.canEdit}
			refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.HULL_SPECIFICATIONS.X)}
			top={props.top} onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileHullSpecifications