import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import {setEngineSpecs} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputPower from "../MobileInputPower"
import MobileInputWeight from "../MobileInputWeight"
import MobileSubtitleAndContent from "../../MobileSubtitleAndContent"
import MobileEngineClassDropdown from "../../../shared/input/dropdown/MobileEngineClassDropdown"
import MobileFuelDropdown from "../../../shared/input/dropdown/MobileFuelDropdown"
import MobileEngineRotationDropdown from "../../../shared/input/dropdown/MobileEngineRotationDropdown"
/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {Engine} props.engine
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselEngineSpecsEdit = props => {
	const [classification, setClassification] = useState(props.engine.classification)
	const [fuel, setFuel] = useState(props.engine.classification)
	const [rotation, setRotation] = useState(props.engine.classification)
	const specs = {power: props.engine.power, weight: props.engine.weight,
		rotation: props.engine.isCounterRotating, fuel: props.engine.fuel, classification: props.engine.classification}
	const className = "paralian-theme-level-1"
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const power_ = document.getElementById(`${props.engine.id}-engine-power-whole-input`).value.replace(/[a-zA-Z,. ]/g,'')
			const power = Number(convertToPreferredUnits(power_, props.units === "SI" ? "kW" : "hp", "SI",
				4, false, false))
			const engineClass = document.getElementById(`${props.engine.id}-engine-class-input`).value
			const fuel_ = document.getElementById(`${props.engine.id}-engine-fuel-input`).value
			const rotation_ = document.getElementById(`${props.engine.id}-engine-rotation-input`).value
			const weight_ = document.getElementById(`${props.engine.id}-engine-weight-whole-input`).value.replace(/[a-zA-Z,. ]/g,'')
			const weight = Number(convertToPreferredUnits(weight_, props.units === "SI" ? "kg" : "lb", "SI",
				4, false, false))
			const response = await setEngineSpecs(props.id, props.engine.id, engineClass, fuel_, rotation_, power, weight)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess({power: power, weight: weight, rotation: rotation_, fuel: fuel_, classification: engineClass})
			}
		} catch (e) {
			console.log(e)
		}
	}
	const classInput = <MobileEngineClassDropdown id={props.engine.id} form={null} autoFocus={false} required={true}
		defaultValue={classification} className={className} setValue={setClassification}/>
	const fuelInput = <MobileFuelDropdown type={"dropdown"} id={props.engine.id} className={className} form={null}
		autoFocus={false} required={true}	defaultValue={fuel} setValue={setFuel}/>
	const rotationInput = <MobileEngineRotationDropdown id={props.engine.id} form={null} autoFocus={false} required={true}
		defaultValue={rotation} className={className} setValue={setRotation}/>
	const powerInput = <MobileInputPower id={`${props.engine.id}-engine-power`} label={translate(DICTIONARY.POWER.X)}
		form={`${props.engine.id}-specifications-form`} units={props.units} value={specs.power} className={"paralian-theme-level-1"}/>
	const weightInput = <MobileInputWeight id={`${props.engine.id}-engine-weight`} value={specs.weight} units={props.units}
		form={`${props.engine.id}-specifications-form`} label={translate(DICTIONARY.WEIGHT.X)} className={"paralian-theme-level-1"}/>
	const content = <>
		<MobileSubtitleAndContent key={"class"} className={"paralian-theme-level-1"}
			subtitle={translate(DICTIONARY.CLASS.X)} content={classInput}/>
		<MobileSubtitleAndContent key={"fuel"} className={"paralian-theme-level-1"}
			subtitle={translate(DICTIONARY.FUEL.X)} content={fuelInput}/>
		<MobileSubtitleAndContent key={"rotation"} className={"paralian-theme-level-1"}
			subtitle={translate(DICTIONARY.ROTATION.X)} content={rotationInput}/>
		<MobileSubtitleAndContent key={"power"} className={"paralian-theme-level-1"}
			subtitle={translate(DICTIONARY.POWER.X)} content={powerInput}/>
		<MobileSubtitleAndContent key={"weight"} className={"paralian-theme-level-1"}
			subtitle={translate(DICTIONARY.WEIGHT.X)} content={weightInput}/>
	</>
	return <MobileInputForm id={`${props.engine.id}-specifications`} content={content} disableAnimateOpacity={true}
		className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselEngineSpecsEdit