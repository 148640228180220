import {store} from "../../../../index"
import {ACCOUNT_TYPE} from "../../../constants/shared/enumerators"
import {getFromStorage} from "../localFunctions"
import {logOut} from "../serverFunctions"
import getLoggedIn from "./getLoggedIn"
import Mode from "../../../classes/client/Mode"

const getAccountType = (autoLogout = true) => {
	let state, local
	try {
		// get redux account type
		state = store.getState().account.type
		// if redux account type is available, return redux account type
		if (Object.values(ACCOUNT_TYPE).includes(state)) {return state}
		// if redux account type is not available, get account type from localStorage...
		// where it is kept as failsafe for when the browser is refreshed and the account type is reset to its original...
		// undefined state
		local = new Mode({account: getFromStorage("mode").account})
		if (local.account === null && autoLogout && getLoggedIn()) {
			// if localStorage account type is unavailable and autoLogout is set to true...
			// but the application is still logged in, log out of the account
			logOut()
			return
		}
		// return local.account, which will be null if what was returned from localStorage was not a ACCOUNT_TYPE value
		return local.account
	} catch (e) {
		console.log(e)
		return null
	}
}

export default getAccountType