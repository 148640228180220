import React from "react"
import MobileFontAwesome from "../../main/MobileFontAwesome"

/**
 *
 * @param props
 * @param {Icon} props.icon
 * @param {string} props.text
 * @returns {JSX.Element}
 * @constructor
 */
const MobileHeaderBanner = props => {
	return <div className={"paralian-theme-level-1-flipped w3-display-topmiddle"}
			style={{fontSize: "2vh", marginTop: "17vh", width: "100%", textAlign: "center", padding: "2.5vh"}}>
		<MobileFontAwesome icon={props.icon} style={{paddingRight: "2vh", fontSize: "3vh"}}/>
		{props.text}
	</div>
}

export default MobileHeaderBanner