import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileEmployeeServiceAccessFields from "./MobileEmployeeServiceAccessFields"
/**
 *
 * @param {Object} props
 * @param {Account} props.account
 * @param {Servicio[]} props.services
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @param {function} props.onAdd
 * @return {JSX.Element}
 * @constructor
 */
const MobileEmployeeServiceAccess = props => {
	const [expanded, setExpanded] = useState(false)
	const accessFields = props.account.services.map(service =>
		<MobileEmployeeServiceAccessFields key={`employee-${props.account.id}-service-${service.service}`}
			id={props.account.id} service={service}
			services={props.services}/>)
	const content = <div className={"paralian-mobile-info-card"}>{accessFields}</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} id={"services"} zIndex={props.zIndex} top={props.top}
			text={translate(DICTIONARY.SERVICES.X)} onClick={() => setExpanded(!expanded)}/>
			{expanded ? <div id={"services"} className={"w3-animate-opacity w3-animate-top"}>
			{content}
		</div> : <></>}
	</>
}

export default MobileEmployeeServiceAccess