import VesselStorageFacility from './VesselStorageFacility'

class VesselStorageFacilities {
	/**
		*
		* @param {VesselStorageFacilities} [facilities = null]
		* @param {VesselStorageFacility} [facilities.MOORINGS]
		* @param {VesselStorageFacility} [facilities.TRAILERS]
		* @param {VesselStorageFacility} [facilities.RACKS]
		* @param {VesselStorageFacility} [facilities.CRADLES]
		* @param {VesselStorageFacility} [facilities.SLIPS]
		* @param {VesselStorageFacility} [facilities.BOAT_YARD]
	*/
	constructor(facilities = null) {
		try {
			this.MOORINGS = new VesselStorageFacility(facilities.MOORINGS)
			this.TRAILERS = new VesselStorageFacility(facilities.TRAILERS)
			this.RACKS = new VesselStorageFacility(facilities.RACKS)
			this.CRADLES = new VesselStorageFacility(facilities.CRADLES)
			this.SLIPS = new VesselStorageFacility(facilities.SLIPS)
			this.BOAT_YARD = new VesselStorageFacility(facilities.BOAT_YARD)
		} catch (e) {
			this.MOORINGS = new VesselStorageFacility()
			this.TRAILERS = new VesselStorageFacility()
			this.RACKS = new VesselStorageFacility()
			this.CRADLES = new VesselStorageFacility()
			this.SLIPS = new VesselStorageFacility()
			this.BOAT_YARD = new VesselStorageFacility()
		}
	}
}

export default VesselStorageFacilities