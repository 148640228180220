import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import {confirmOrder} from "../../../globals/functions/client/serverFunctions"
import Modal from "../../../globals/components/Modal"
import MobileStripeCreditCardPayment from "./MobileStripeCreditCardPayment"
import Currency from "../../../globals/classes/shared/Currency"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Currency} props.order.currency
 * @param {Object} props.order.provider
 * @param {Name} props.order.provider.name
 * @param {Object} props.order.marina
 * @param {Name} props.order.marina.name
 * @param {Object} props.order.vessel
 * @param {Name} props.order.vessel.name
 * @param {Object} props.order.charges
 * @param {Object[]} props.order.charges.items
 * @param {number} props.order.charges.items.item
 * @param {Object} props.order.charges.total
 * @param {number} props.order.charges.total.total
 * @param {IndexedArray} props.services
 * @param {Organization} props.organization
 * @param {Account} props.account
 * @param {boolean} props.isOrganization
 * @param {function} props.refreshAccount
 * @param {function} props.refreshOrganization
 * @param {function} props.refreshOrders
 * @param {function} props.setShowModal
 * @return {JSX.Element}
 * @constructor
 */
const StripeConfirmOrder = props => {
	// show payment methods dropdown
	// if payment method is other, allow entry of new payment method
	// on submit, create payment intent for that specific order
	// show order summary
	// show terms of sale
	const [showTermsOfSale, setShowTermsOfSale] = useState(false)
	const order = props.order
	const currency = new Currency(order.currency)
	const termsOfSale = <Modal title={translate(DICTIONARY.TERMS_OF_SALE.X)} icon={ICONS.HANDSHAKE} padding={"0vh"}
		onClick={() => setShowTermsOfSale(false)} id={"terms-of-sale"} background={"white"}
		body={
		 <div style={{textAlign: "justify", padding: "0vh 2vh"}}>
			 <p>{translate(DICTIONARY.TERMS_OF_SALE_MESSAGE_1.X)}</p>
			 <p>{translate(DICTIONARY.TERMS_OF_SALE_MESSAGE_2.X)}</p>
			 <p>{translate(DICTIONARY.TERMS_OF_SALE_THIRD_PARTY_PART_1.X)}</p>
			 <p>{translate(DICTIONARY.TERMS_OF_SALE_THIRD_PARTY_PART_2.X)}</p>
		 </div>
		}/>
	// console.log({stripeAccount: order.provider.stripeId})
	const services = []
	for (let i = 0; i < order.charges.items.length; i++) {
		const item = props.services.byIndex(order.charges.items[i].item)
		services.push(<li key={item.label}>{`${translate(item.label)}`}</li>)
	}
	const summary = <div className={"paralian-theme-level-1"} style={{padding: "2vh", textAlign: "left"}}>
		<div>{`→ ${translate(DICTIONARY.VESSEL.X)}: ${order.vessel.name.display}`}</div>
		<div>{`→ ${translate(DICTIONARY.MARINA.X)}: ${order.marina.name.display}`}</div>
		<div>{`→ ${translate(DICTIONARY.PROVIDER.X)}: ${order.provider.name.display}`}</div>
		<div>{`→ ${translate(DICTIONARY.SERVICES.X)}:`}</div>
		<ul style={{margin: "unset"}}>{services}</ul>
		<div style={{marginTop: "2vh"}}>
			{`→ ${translate(DICTIONARY.TOTAL_DUE.X)}: ${currency.asText(order.charges.total.total)}`}
		</div>
	</div>
	const isOrganization = !!props.organization.id
	const parentObject = isOrganization ? props.organization : props.account
	const refreshParent = isOrganization ? props.refreshOrganization : props.refreshAccount
	const onSuccess = () => {
		fadingMessage("accept-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
		props.refreshOrders()
		props.setShowModal(false)
		// success fading message
	}
	const getSecret = async paymentMethod => {
		try {
			const response = await confirmOrder(order.id, paymentMethod)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				return response.payload
			}
			else if (response.status === SERVER_RESPONSE_STATE.FAIL && response.payload.reason === "paid") {
				onSuccess()
				return null
			}
			return null
		} catch (e) {
			console.log(e)
			return null
		}
	}
	return <div>
		<MobileStripeCreditCardPayment parentObject={parentObject} currency={currency.code} termsOfSale={termsOfSale}
			setShowTermsOfSale={setShowTermsOfSale}	showTermsOfSale={showTermsOfSale} summary={summary} //  setShow={() => {}}
			onSuccess={onSuccess} refreshAccount={() => refreshParent().then()} isOrganization={isOrganization}
			getSecret={getSecret} confirmPayment={false}/>
	</div>
}

export default StripeConfirmOrder