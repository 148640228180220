import React from "react"
import {store} from "../../index"
import {actions} from "../constants/client/reduxData"
import {hideByElementId, manageCrash} from "../functions/client/localFunctions"
import MobileFontAwesome from "../../mobile/main/MobileFontAwesome"
import MobileButtonGeneric from "../../mobile/shared/input/buttons/MobileButtonGeneric"
/**
 * In-app message
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} message
 * @param {string} message.id
 * @param {string} message.color - w3 color class,
 * @param {string} message.title
 * @param {string} message.text
 * @param {string[]} message.icon - ICONS
 * @return {JSX.Element}
 * @constructor
 */
const Messages = message => {
	// console.log(message)
	const buttons = message.buttons ? <div style={{display: message.buttons.length > 1 ? "flex" : "block"}}>
		{message.buttons.map(v => <div key={`${message.id}-${v.id}`} style={{width: "100%"}}>
			<MobileButtonGeneric id={v.id} type={"button"}
				style={{width: "100%", fontStyle: "italic", fontWeight: "bold", textAlign: message.buttons.length > 1 ? "left" : "center"}}
				text={{style: {paddingRight: "2vh"}, content: v.text}} padding={"2vh 0vh 0vh"} onClick={v.onClick}/>
		</div>)}
	</div> : <></>
	return <div className={`messages w3-display-container w3-display-topmiddle w3-animate-opacity w3-animate-top ${message.color}`}
		id={message.id} style={{width: "100%", position: "relative", zIndex: "5000", minHeight: "8vh", fontSize: "2vh",
		padding: "2vh", marginBottom: "2vh", opacity: "0.9", borderRadius: "2vh"}}
		key={`alertMessage-${message.id}`}>
		<h3 style={{fontSize: "3vh"}}>
			<MobileFontAwesome icon={message.icon}/>
			{message.title}
		</h3>
		<p style={{textAlign: "justify", display: "inline-flex", margin: "auto"}}>{message.text}</p>
		<span className={"w3-display-topright"} style={{backgroundColor: "unset", padding: "1vh 1.5vh"}}
			onClick={() => {
				store.dispatch({type: actions.DELETE_MESSAGE, values: {id: message.id}})
				if (store.getState().messages.length === 0) {hideByElementId("messageContainer")}
			}}>&times;</span>
		{buttons}
	</div>
}
/**
 * Container for in-app notifications
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const MessagesContainer = () => {
	try {
		return <div className={"messagesContainer w3-display-topmiddle"} id={"messageContainer"}
			style={{width: "100%", zIndex: "5000", padding: "2vh", display: "none"}}>
			{store.getState().messages.map(message => Messages(message))}
		</div>
	} catch (e) {
		manageCrash(e, "MessagesContainer", "warning")
		return <></>
	}
}

export default MessagesContainer