import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileAddNewBanner from "../../MobileAddNewBanner"
import MobileVesselsFleetCard from "./MobileVesselsFleetCard"
import MobileVesselAdd from "./MobileVesselAdd"
import Modal from "../../../../globals/components/Modal"
import MobileVesselInvite from "../invite/MobileVesselInvite"
import MobileButtonGeneric from "../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Account} props.account
 * @param {Organization} props.organization
 * @param {Vessel[]} props.vessels
 * @param {number} props.primary - default vessel id
 * @param {number} props.zIndex
 * @param {number} props.top
 * @param {function} props.refreshAccount
 * @param {function} props.refresh - refresh vessels
 * @param {function} props.setIndex
 * @param {boolean} props.canEdit
 * @param {boolean} props.isOrganization
 * @param {boolean} props.areCustomers
 * @return {JSX.Element}
 * @constructor
 */

const MobileMyFleet = props => {
	/* TODO: to add vessel, request access using MMSI or HIN or create from scratch */
	/* TODO: don't delete verified vessels as they are in some record, simply remove from vessels access for that account */
	const [expanded, setExpanded] = useState(false)
	const [add, setAdd] = useState(false)
	const [invite, setInvite] = useState(false)
	const [vessels, setVessels] = useState(props.vessels || [])
	const [primary, setPrimary] = useState(props.primary)
	useEffect(() => {setVessels(props.vessels || [])}, [props.vessels])
	useEffect(() => {
		setPrimary(props.primary)
	}, [props.primary])
	// useEffect(() => setExpanded((props.vessels || []).length === 0), [props.vessels])
	const setPrimaryVessel = id => {
		props.refresh().then()
		props.refreshAccount().then()
		setPrimary(id)
		props.setIndex(id)
	}
	const unlinkVessel = id => {
		props.refresh().then()
		// const vessels_ = [...vessels]
		// setVessels([...vessels_.splice(vessels_.findIndex(item => item.id === id), 1)])
	}
	const content = vessels.length > 0 ? vessels.map((vessel, index) => <MobileVesselsFleetCard primary={primary}
		refresh={props.refresh}	setPrimaryVessel={setPrimaryVessel}	key={`vessel-fleet-${vessel.id}`} vessel={vessel}
		setIndex={props.setIndex} index={index} unlinkVessel={unlinkVessel} canEdit={props.canEdit} areCustomers={props.areCustomers}/>) : <></>
	const add_ = add ? <Modal id={"add-vessel-modal"} icon={ICONS.SHIP} title={translate(DICTIONARY.ADD_VESSEL.X)}
		padding={"0vh"} onClick={() => setAdd(false)} body={
			<MobileVesselAdd isOrganization={props.isOrganization} onAdd={vessel => {
				props.refresh().then()
				setVessels([...vessels, vessel])
				setAdd(false)
			}}/>}/>  : <></>
	const invite_ = invite ? <Modal id={"add-vessel-modal"} icon={ICONS.SHIP} title={translate(DICTIONARY.ADD_CUSTOMER_VESSEL.X)}
		padding={"0vh"} onClick={() => setInvite(false)} body={<MobileVesselInvite account={props.account} refreshVessels={props.refresh}
		organization={props.organization} refreshAccount={props.refreshAccount} marinas={props.marinas} setInvite={setInvite} />}/> : <></>
	const content_ = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"}>
		{content}
		{add_}
		{invite_}
		{	// TODO: expose once fixed
			/*props.canEdit || props.canInvite ? <MobileAddNewBanner text={translate(DICTIONARY.ADD_VESSEL.X)}
			onClick={() => props.canEdit ? setAdd(!add) : setInvite(!invite)}/> : <></>*/}
		<div style={{margin: "0vh 2vh"}}>
			<MobileButtonGeneric type={"button"} text={{style: {paddingRight: "1vh"}, content: translate(DICTIONARY.REFRESH.X)}}
				right={{icon: ICONS.SYNC}} className={"paralian-theme-level-4-flipped"} padding={"1vh"} style={{width: "100%"}}
				onClick={() => props.refresh().then()}/>
		</div>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={props.label || translate(DICTIONARY.MY_FLEET.X)} top={props.top}
			onClick={() => setExpanded(!expanded)}/>
		{content_}
	</>
}

export default MobileMyFleet