import {typeOrNull} from '../../functions/shared/local'

class Capacity {
	/**
	 *
	 * @param {Capacity} [capacity = null]
	 * @param {Object} [capacity.weight]
	 * @param {Object} [capacity.length]
	 * @param {Object} [capacity.width]
	 * @param {Object} [capacity.depth]
	 * @param {Object} [capacity.height]
	 */
	constructor(capacity = null) {
		try {
			this.weight = {
				min: typeOrNull(capacity.weight.min, "number"),
				max: typeOrNull(capacity.weight.max, "number")
			}
			this.length = {
				min: typeOrNull(capacity.length.min, "number"),
				max: typeOrNull(capacity.length.max, "number")
			}
			this.width = {
				min: typeOrNull(capacity.width.min, "number"),
				max: typeOrNull(capacity.width.max, "number")
			}
			this.depth = {
				min: typeOrNull(capacity.depth.min, "number"),
				max: typeOrNull(capacity.depth.max, "number")
			}
			this.height = {
				min: typeOrNull(capacity.height.min, "number"),
				max: typeOrNull(capacity.height.max, "number")
			}
		} catch (e) {
			this.weight = {
				min: null,
				max: null
			}
			this.length = {
				min: null,
				max: null
			}
			this.width = {
				min: null,
				max: null
			}
			this.depth = {
				min: null,
				max: null
			}
			this.height = {
				min: null,
				max: null
			}
		}
	}

	get hasMaxInfo() {
		return !!this.weight.max || !!this.length.max || !!this.width.max || !!this.depth.max || !!this.height.max
	}

}

export default Capacity