import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import Units from "../../../globals/classes/client/Units"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.dpsUnit - PRICING_STRUCTURE_UNITS
 * @param {string} props.unitSystem - UNIT_SYSTEM
 * @param {number} props.price - in integer from
 * @param {number} props.min - in integer form
 * @param {Currency} props.currency
 * @return {JSX.Element}
 * @constructor
 */
const MobilePricingExample = props => {
	const UNITS = new Units()
	const [example, setExample] = useState(7.6962)
	const converted = UNITS.convert(example, props.dpsUnit,	UNITS.ofType(props.dpsUnit)[props.unitSystem].SHORT,
		true)
	const calculatedTotal = props.currency.totalAsNumber(props.price, converted)
	const minimumTotal = props.currency.asNumber(props.min)
	const total = props.currency.asText(props.currency.asZeroDecimal(Math.max(calculatedTotal, minimumTotal)))
	return <div className={props.flipColor ? "paralian-theme-level-2" : "paralian-theme-level-1"}
		style={{width: "100%", marginTop: "1vh", borderRadius: "1vh"}}>
		<MobileInputGeneric type={"dropdown"} id={props.id} size={"1"} showLabel={true} name={"example"} defaultValue={example}
			label={translate(DICTIONARY.EXAMPLE.X)} multiple={false} autoFocus={false}
			className={props.flipColor ? "paralian-theme-level-2" : "paralian-theme-level-1"}
			required={false} list={[{value: 7.6962, label: "Contender 25T"},
			{value: 19.858, label: "Hatteras GT65 Carolina"}, {value: 17.1158, label: "Beneteau Oceanis Yacht 54"}]}
			labelStyle={{textAlign: "center", fontSize: "1.5vh", fontStyle: "italic"}}
			onChange={() => {
				const eg = document.getElementById(`${props.id}-input`)
				eg.setAttribute("data-a", eg.value)
				setExample(eg.value)
			}}/>
		<div style={{padding: "1vh 0vh"}}>
			{total}
		</div>
	</div>
}

export default MobilePricingExample
