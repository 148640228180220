import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

const MobileCustomersProvidersDropdown = props => {
	const list = [
		{label: translate(DICTIONARY.PROVIDER.X), value: "PROVIDERS"},
		{label: translate(DICTIONARY.CUSTOMER.X), value: "CUSTOMERS"},
		{label: translate(DICTIONARY.CUSTOMER.X) + " " + translate(DICTIONARY.OR.X) + " " +
			translate(DICTIONARY.PROVIDER.X), value: "ALL"}
	]
	const label = props.label || translate(DICTIONARY.CAPACITY.X)
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-capacity`} className={className} size={"1"}
		left={left}	label={label} multiple={false} topRadius={props.topRadius} showLabel={props.showLabel}
		right={props.right}	style={style} list={list} placeholder={label}	name={"customers-providers"} form={props.form}
		autoFocus={false} required={true}	bottomRadius={props.bottomRadius} dataId={list[0].value} onChange={() => {
		try {
			const element = document.getElementById(`${props.id}-capacity-input`)
			element.setAttribute("data-id", element.value)
			onChange(element.value)
		} catch (e) {
			console.log(e)
		}
	}}/>
}

export default MobileCustomersProvidersDropdown