import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {refundOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileInputText from "../../../../shared/input/text/MobileInputText"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
import Modal from "../../../../../globals/components/Modal"
/**
 *
 * @param {Object} props
 * @param {Organization} props.organization
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.charges
 * @param {{order: number, item: number, isAdjustment: boolean, quantity: number, charge: number}[]} props.order.charges.items
 * @param {{items: number, taxes: number, total: number}} props.order.charges.total
 * @param {{available: number, captured: number, credits: number, onHold: number, refunded: number, receipts: string[]}} props.order.refunds
 * @param {Currency} props.currency
 * @param {boolean} props.showRefundModal
 * @param {function} props.setShowRefundModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @return {JSX.Element}
 * @constructor
 */
const RefundOrderModal = props => {
	const order = props.order
	const orderTotal = order.charges.total.total
	const currency = props.currency
	return props.showRefundModal ? <Modal title={translate(DICTIONARY.REFUND.X)} icon={ICONS.HAND_HOLDING_USD}
		onClick={() => props.setShowRefundModal(false)} id={`order-${order.id}-refund-modal`} padding={"0vh"}
		body={
			<div style={{padding: "2vh"}}>
				<div className={"paralian-theme-level-2"} style={{padding: "1vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
					<form id={"refund-form"}>
						<div style={{padding: "1vh"}}>
							<div style={{padding: "2vh 0vh"}}>
								<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
									{translate(DICTIONARY.ORDER_TOTAL_TAXES.X)}
								</div>
								<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
									{currency.asText(orderTotal)}
								</div>
								<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>
									{translate(DICTIONARY.REFUNDS.X)}
								</div>
								<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>
									{currency.asText(order.charges.total.total - (order.refunds.available + order.refunds.onHold))}
								</div>
							</div>
							<div style={{textAlign: "left", paddingLeft: "0.5vh", paddingBottom: "1vh"}}>
								{translate(DICTIONARY.REFUND_AMOUNT_TAXES.X)}
							</div>
							<div className={"paralian-theme-level-1"}
									 style={{padding: "1.5vh", display: "inline-flex", width: "100%", borderRadius: "1vh"}}>
								<span>{currency.asString}</span>
								<input type={"number"} id={`order-${order.id}-refund-input`}
									 className={"paralian-input-field "} min={"0"} placeholder={"###.##"} style={{color: "inherit"}}
									 max={currency.asNumber(order.refunds.available + order.refunds.onHold)}
									 defaultValue={currency.asNumber(order.refunds.available + order.refunds.onHold)}
									 name={"name"} form={"refund-form"} autoFocus={false} maxLength={8} required={true} step={"0.01"}
									 onInputCapture={() => {
									 }}/>
							</div>
						</div>
						<div style={{padding: "1vh", margin: "1vh 0vh"}}>
							<MobileInputText id={`order-${order.id}-refund-reason`} style={{borderRadius: "1vh"}}
								 label={translate(DICTIONARY.MESSAGE_TO_CUSTOMER.X)} className={"paralian-theme-level-1"}
								 required={true} pattern={"([a-zA-ZÀ-ž0-9-_\.]){2,128}"} showLabel={true}
								 placeholder={"Why?"} form={"refund-form"}/>
						</div>
						<div style={{padding: "1vh"}}>
							<MobileButtonGeneric id={"submit-refund"} type={"submit"} className={"paralian-theme-level-1"}
								 padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}}
								 text={{content: translate(DICTIONARY.SUBMIT.X)}} form={"refund-form"}
								 onClick={async event => {
									 event.preventDefault()
									 try {
										 const refund = currency.asZeroDecimal(document.getElementById(`order-${order.id}-refund-input`).value)
										 const message = document.getElementById(`order-${order.id}-refund-reason-input`).value
										 const response = await refundOrder(order.id, refund, message)
										 // console.log(response)
										 if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
											 props.refreshOrders()
											 props.setShowRefundModal(false)
											 fadingMessage("refund-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
										 }
									 } catch (e) {
										 console.log(e)
									 }
								 }}/>
						</div>

					</form>
				</div>
			</div>
					}/> : <></>
					}

					export default RefundOrderModal