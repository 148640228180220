import Address from './Address'
import {removeDuplicateObjectsFromArray} from "../../functions/shared/local"

class Addresses {
	/**
	 *
	 * @param {Addresses} [addresses = null]
	 * @param {Address} [addresses.physical]
	 * @param {Address} [addresses.mailing]
	 * @param {Address} [addresses.support]
	 */
	constructor(addresses = null) {
		try {
			this.physical = new Address(addresses.physical)
			this.mailing = new Address(addresses.mailing)
			this.support = new Address(addresses.support)
			this.billing = new Address(addresses.billing)
		} catch (e) {
			this.physical = new Address()
			this.mailing = new Address()
			this.support = new Address()
			this.billing = new Address()
		}
	}

	get asArray() {
		try {
			return removeDuplicateObjectsFromArray([this.physical, this.mailing, this.support, this.billing]).filter(address => address.id)
		} catch (e) {
			return []
		}
	}
}

export default Addresses