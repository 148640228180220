import {DEVICE_TYPE} from "../../constants/client/constants"
import {ACCOUNT_TYPE} from "../../constants/shared/enumerators"
import {checkLoginStatus} from "../../functions/client/serverFunctions"
import {enumOrNull, typeOrNull} from "../../functions/shared/local"
import setDeviceType from "../../functions/client/mode/setDeviceType"

class Mode {
	/**
	 *
	 * @param {Mode} [mode = null]
	 * @param {string} [mode.device] - device type: one of "DESKTOP", "MOBILE" (case-insensitive)
	 * @param {string} [mode.account] - account type: one of "PERSONAL", "ORGANIZATION", "EMPLOYEE" (case-insensitive)
	 * @param {boolean} [mode.loggedIn] - true if user has successfully logged in
	 */
	constructor(mode = null) {
		try {
			this.device = enumOrNull(mode.device, DEVICE_TYPE)
			this.account = enumOrNull(mode.account, ACCOUNT_TYPE)
			this.loggedIn = typeOrNull(mode.loggedIn, "boolean")
		} catch (e) {
			this.device = window.screen.availWidth/window.screen.availHeight > 1 ? DEVICE_TYPE.DESKTOP : DEVICE_TYPE.MOBILE
			this.account = null
			this.loggedIn = null
		}
	}

	/**
	 *
	 * @return {string}
	 */
	refreshDevice = () => {
		this.device = window.screen.availWidth/window.screen.availHeight > 1 ? DEVICE_TYPE.DESKTOP : DEVICE_TYPE.MOBILE
		setDeviceType(this.device)
		return this.device
	}

	/**
	 *
	 * @return {Promise<boolean>}
	 */
	refreshLoggedIn = async () => {
		this.loggedIn = await checkLoginStatus(false)
		return this.loggedIn
	}

	/**
	 *
	 * @return {Promise<Mode>}
	 */
	refreshMode = async () => {
		this.refreshDevice()
		await this.refreshLoggedIn()
		return this
	}

}

export default Mode