import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {getVersion, logOut} from "../../globals/functions/client/serverFunctions"
// import {getVersion, logOut, runTest} from "../../globals/functions/client/serverFunctions"
import {checkIfPushEnabled, translate} from "../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../shared/input/buttons/MobileButtonGeneric"
import Modal from "../../globals/components/Modal"
import MobileSettings from "./MobileSettings"

const MobileMenu = props => {
	const [showModal, setShowModal] = useState(false)
	const [showSettings, setShowSettings] = useState(false)
	const [modalContent, setModalContent] = useState({id: "", src: "", label: ""})
	const [version, setVersion] = useState(null)
	const canNotify = "Notification" in window
	useEffect(() => {
		getVersion().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setVersion(response.payload.version)
			}
		})
	}, [])
	const settings = showSettings ? <Modal id={"main-menu-settings"} icon={ICONS.SLIDERS_SIMPLE} padding={"0vh"}
		onClick={() => setShowSettings(false)} title={translate(DICTIONARY.SETTINGS.X)}
		body={<MobileSettings account={props.account} refreshAccount={props.refreshAccount}/>}/> : <></>
	const modal = showModal ? <Modal onClick={() => setShowModal(false)} id={modalContent.id} padding={"0vh"}
		 background={"white"} body={<iframe title={modalContent.label} src={modalContent.src} style={{height: "55vh",
			width: "100%", border: "unset", background: "white"}}/>}/> : <></>
	// console.log("notification permission", Notification.permission)
	return <>
		{modal}
		{settings}
		<div className={"w3-animate-opacity"} style={{padding: "2vh", width: "100%"}}>
			<div style={{margin: "1vh 0vh"}}>
				<MobileButtonGeneric id={"settings"} type={"button"} left={{icon: ICONS.SLIDERS_SIMPLE}} style={{width: "100%"}}
					text={{content: translate(DICTIONARY.SETTINGS.X)}} right={{icon: null}}
					className={"paralian-theme-level-2"} padding={"1vh"} onClick={() => setShowSettings(true)}/>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileButtonGeneric id={"refresh-version"} type={"button"} left={{icon: ICONS.SYNC}}
					text={{content: translate(DICTIONARY.REFRESH.X)}} right={{icon: null}}
					className={"paralian-theme-level-2"}
					padding={"1vh"} style={{width: "100%"}} onClick={() => window.location.reload()}/>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileButtonGeneric id={"logout"} type={"button"} left={{icon: ICONS.SIGN_OUT}} style={{width: "100%"}}
					text={{content: translate(DICTIONARY.LOG_OUT.X)}} right={{icon: null}}
					className={"paralian-theme-level-2"} padding={"1vh"} onClick={() => {logOut()}}/>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileButtonGeneric id={"termly-cookie-preference"} type={"button"} className={"paralian-theme-level-2"}
					text={{content: translate(DICTIONARY.MANAGE_COOKIE_PREFERENCES.X)}}
					padding={"1vh"} style={{width: "100%"}} onClick={window.displayPreferenceModal}/>
			</div>
			{/*
				process.env.REACT_APP_PUBLIC_URL === "https://testing.paralian.app" ?
				<div style={{margin: "1vh 0vh"}}>
					<MobileButtonGeneric id={"test"} type={"button"} text={{content: "Test"}}
						className={"paralian-theme-level-2"} padding={"1vh"} style={{width: "100%"}}
					 	onClick={async () => await runTest()}/>
				</div> : <></>
			*/}
			{
				canNotify ? Notification.permission !== "granted" ?
					<div style={{margin: "1vh 0vh"}}>
						<MobileButtonGeneric id={"test"} type={"button"} text={{content: translate(DICTIONARY.ENABLE_PUSH_NOTIFICATIONS.X)}}
							className={"paralian-theme-level-2"} padding={"1vh"} style={{width: "100%"}}
							onClick={async () => await checkIfPushEnabled()}/>
					</div> : <></> : <></>
			}
		</div>
		<div className={"w3-animate-opacity"} style={{padding: "0vh 2vh", width: "100%"}}>
			<div>
				{version}
			</div>
			<div style={{padding: "4vh 0vh", width: "100%"}}>
				<span style={{padding: "0.5vh", textDecoration: "underline", cursor: "pointer"}} className={"w3-ripple"}
					onClick={() => {
					try {
						setModalContent({
							id: "terms-of-use",
							src: process.env.REACT_APP_TERMS_OF_USE,
							label: translate(DICTIONARY.TERMS_OF_USE.X)
						})
						setShowModal(true)
					} catch (e) {}}}>
					{translate(DICTIONARY.TERMS_OF_USE.X)}
				</span>
				<span style={{padding: "0.5vh", textDecoration: "underline", cursor: "pointer"}} className={"w3-ripple"}
					onClick={() => {
					try {
						setModalContent({
							id: "privacy-policy",
							src: process.env.REACT_APP_PRIVACY_POLICY,
							label: translate(DICTIONARY.PRIVACY_POLICY.X)
						})
						setShowModal(true)
					} catch (e) {}}}>
					{translate(DICTIONARY.PRIVACY_POLICY.X)}
				</span>
				<span style={{padding: "0.5vh", textDecoration: "underline", cursor: "pointer"}} className={"w3-ripple"}
					onClick={() => {
					try {
						setModalContent({
							id: "cookie-policy",
							src: process.env.REACT_APP_COOKIE_POLICY,
							label: translate(DICTIONARY.COOKIE_POLICY.X)
						})
						setShowModal(true)
					} catch (e) {}}}>
					{translate(DICTIONARY.COOKIE_POLICY.X)}
				</span>
				<span style={{padding: "0.5vh", textDecoration: "underline", cursor: "pointer"}} className={"w3-ripple"}
					onClick={() => {
					try {
						setModalContent({
							id: "disclaimers",
							src: process.env.REACT_APP_DISCLAIMERS,
							label: translate(DICTIONARY.DISCLAIMERS.X)
						})
						setShowModal(true)
					} catch (e) {}}}>
					{translate(DICTIONARY.DISCLAIMERS.X)}
				</span>
				<a href={process.env.REACT_APP_DO_NOT_SELL} target={"_blank"}
					 rel={"noreferrer"} style={{padding: "0.5vh"}}>
					{translate(DICTIONARY.DO_NOT_SHARE_MY_INFO.X)}
				</a>
			</div>
	</div>
</>
}

export default MobileMenu