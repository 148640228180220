import { PARALIAN_LANGUAGE, UNIT_SYSTEM } from '../../constants/shared/enumerators';
import { isObject, typeOrNull, enumOrNull } from '../../functions/shared/local';
import Defaults from './Defaults';
/* TODO: privatize with properties_ with  when supported */

class Preferences {
	/**
	 *
	 * @param {Preferences} [preferences = null]
	 * @param {string} [preferences.language]
	 * @param {boolean} [preferences.use24Hr]
	 * @param {string} [preferences.units]
	 * @param {Defaults} [preferences.defaults]
	 */
	constructor(preferences = null) {
		if (isObject(preferences)) {
			this.language_ = enumOrNull(preferences.language || preferences.language_, PARALIAN_LANGUAGE)
			this.use24Hr = typeOrNull(preferences.use24Hr, "boolean")
			this.units_ = enumOrNull(preferences.units || preferences.units_, UNIT_SYSTEM)
			this.defaults = new Defaults(preferences.defaults)
		} else {
			this.language_ = null
			this.use24Hr = null
			this.units_ = null
			this.defaults = new Defaults()
		}
	}

	get units(){
		return this.units_
	}

	set units(units) {
		this.units_ = enumOrNull(units, UNIT_SYSTEM)
	}

	get language(){
		return this.language_
	}

	set language(language) {
		this.language_ = enumOrNull(language, PARALIAN_LANGUAGE)
	}
}

export default Preferences;