import React from "react"

const MobileSVGIcon = props => {
	return <i style={{fontSize: props.fontSize, margin: "1vh"}} onClick={props.onClick}>
		<svg aria-hidden={true} focusable={false} role={"img"} viewBox={"0 0 512 512"} xmlns={"http://www.w3.org/2000/svg"}
				 style={{width: "1em", height: "1em", display: "inline-block", fontSize: "inherit", overflow: "visible"}}>
			{props.icon}
		</svg>
	</i>
}

export default MobileSVGIcon