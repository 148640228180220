import React, {useState} from "react"
import {DICTIONARY, ICONS, LANGUAGE, TIME, UNITS} from "../../globals/constants/client/constants"
import {getLanguage, getTimeFormat, getUnits, setLanguage, setTimeFormat, setUnits, translate} from "../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../shared/input/MobileInputGeneric"
const MobileSettings = props => {
  const [language_, setLanguage_] = useState(getLanguage(props.account))
  const [units_, setUnits_] = useState(getUnits(props.account))
  const [time_, setTime_] = useState(getTimeFormat(props.account))
  // console.log(language_, units_, time_)
  return <div className={"w3-center w3-animate-opacity"} style={{width: "100%", padding: "0vh 4vh"}}>
      <div style={{margin: "1vh 0vh"}}>
        <MobileInputGeneric id={"language"} type={"dropdown"} className={"paralian-theme-level-2"}
          left={{icon: ICONS.LANGUAGE}} autoFocus={false} label={translate(DICTIONARY.LANGUAGE.X)} showLabel={true}
          defaultValue={language_} list={LANGUAGE.map(language => {return {value: language.value, label: language.name}})}
          onChange={() => {
            try {
              const value = document.getElementById("language-input").value
              setLanguage(value, true, props.refreshAccount)
              setLanguage_(value)
            } catch (e) {
              console.log(e)
            }
          }}/>
      </div>
      <div style={{margin: "1vh 0vh"}}>
        <MobileInputGeneric id={"units"} type={"dropdown"} className={"paralian-theme-level-2"} showLabel={true}
          left={{icon: ICONS.RULER_TRIANGLE}} autoFocus={false} label={translate(DICTIONARY.UNIT_SYSTEM.X)}
          list={UNITS.map(unit => {return {value: unit.value, label: translate(unit.name)}})} defaultValue={units_}
          onChange={() => {
            try {
              const value = document.getElementById("units-input").value
              setUnits(value, true, props.refreshAccount)
              setUnits_(value)
            } catch (e) {
              console.log(e)
            }
          }}/>
      </div>
      <div style={{margin: "1vh 0vh"}}>
        <MobileInputGeneric id={"time"} type={"dropdown"} className={"paralian-theme-level-2"} showLabel={true}
          left={{icon: ICONS.CLOCK}} autoFocus={false} label={translate(DICTIONARY.TIME_FORMAT.X)}
          list={TIME.map(time => {return {value: time.value, label: translate(time.name)}})} defaultValue={time_}
          onChange={() => {
            try {
              const value = document.getElementById("time-input").value
              setTimeFormat(value, true, props.refreshAccount)
              setTime_(value)
            } catch (e) {
              console.log(e)
            }
          }}/>
      </div>
    </div>
}

export default MobileSettings