import React from "react"
import {DICTIONARY, IMAGES} from "../../../../globals/constants/client/constants"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import MobileHorizontalPictureAndText from "../../MobileHorizontalPictureAndText"

/**
 *
 * @param {Object} props
 * @param {string} props.units - SI or US
 * @param {HullDimensions} props.dimensions
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullDimensionsFields = props => {
	const length = convertToPreferredUnits(props.dimensions.length, "m", props.units, 2)
	const beam = convertToPreferredUnits(props.dimensions.beam, "m", props.units, 2)
	const draught = convertToPreferredUnits(props.dimensions.draught, "m", props.units, 2)
	const clearance = convertToPreferredUnits(props.dimensions.clearance, "m", props.units, 2)
	// console.log(height)
	return <>
		<MobileHorizontalPictureAndText imagePath={IMAGES.HULL_LOA} label={translate(DICTIONARY.HULL_LENGTH_OVER_ALL.X)} content={length}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.BEAM} label={translate(DICTIONARY.BEAM.X)} content={beam}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.DRAUGHT} label={translate(DICTIONARY.DRAUGHT.X)} content={draught}/>
		<MobileHorizontalPictureAndText imagePath={IMAGES.HULL_CLEARANCE} label={translate(DICTIONARY.CLEARANCE.X)} content={clearance}/>
	</>
}

export default MobileVesselHullDimensionsFields