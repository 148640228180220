import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobilePaymentMethodCard from "./MobilePaymentMethodCard"
import StripePaymentMethodAdd from "./StripePaymentMethodAdd"
import Modal from "../../../globals/components/Modal"

/**
 *
 * @param {Object} props
 * @param {React.HTMLProps.style.top} props.top
 * @param {React.HTMLProps.style.zIndex} props.zIndex
 * @param {Account|Organization} props.parentObject
 * @param {PaymentMethods} props.parentObject.payment
 * @param {Name} props.parentObject.name
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobilePaymentMethods = props => {
	const [expanded, setExpanded] = useState(false)
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(props.parentObject.payment.defaultPaymentMethod)
	const [paymentMethods, setPaymentMethods] = useState(props.parentObject.payment.methods.cards)
	const [add, setAdd] = useState(false) // use this to control toggles using edit button
	useEffect(() => setDefaultPaymentMethod(props.parentObject.payment.defaultPaymentMethod), [props.parentObject])
	useEffect(() => setPaymentMethods(props.parentObject.payment.methods.cards), [props.parentObject])
	const removeCard = id => {setPaymentMethods(paymentMethods.filter(method => method.id !== id))}
	const insert = add ? <Modal id={"payment-method-add-modal"} icon={ICONS.CREDIT_CARD} padding={"0vh"}
		title={translate(DICTIONARY.ADD_PAYMENT_METHOD.X)} onClick={() => setAdd(false)}
		body={<StripePaymentMethodAdd parentObject={props.parentObject} isOrganization={props.isOrganization}
			onSuccess={
			() => {
				props.refresh().then()
				// setPaymentMethods(card)
				setAdd(false)
			}
	}/>}/> : <></>
	const add_ = <MobileAddNewBanner text={translate(DICTIONARY.ADD_PAYMENT_METHOD.X)} onClick={() => {setAdd(!add)}}/>
	const numPaymentMethods = paymentMethods ? paymentMethods.length : 0
	const content = numPaymentMethods > 0 ?
		paymentMethods.map(card => <MobilePaymentMethodCard key={`pm-card-${card.id}`}
			setDefaultPaymentMethod={setDefaultPaymentMethod}	card={card}	defaultSource={defaultPaymentMethod}
			refresh={props.refresh} removeCard={removeCard} isOrganization={props.isOrganization}/>) : <></>
	const content_ = !expanded ? <></> : <div className={"w3-animate-top"}>{content}{insert}{add_}</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} top={props.top} zIndex={props.zIndex}
			onClick={() => setExpanded(!expanded)} text={translate(DICTIONARY.PAYMENT_METHODS.X)}/>
		{content_}
	</>
}

export default MobilePaymentMethods
