import React, {useEffect, useState} from "react"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS, PAGE_STATES, PAGES} from "../../../globals/constants/client/constants"
import {newMessage, setToStorage, translate} from "../../../globals/functions/client/localFunctions"
import {getServices} from "../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import MobileVesselCleaning from "./cleaning/MobileVesselCleaning"
import ConciergeHome from "./concierge/ConciergeHome"
import ShuttleHome from "./shuttle/ShuttleHome"
import LaunchHome from "./launch/LaunchHome"
import StorageReservationsHome from "./storage/StorageReservationsHome"
import ProvisionsHome from "./provisions/ProvisionsHome"
import FuelingHome from "./fuel/FuelingHome"
import IndexedArray from "../../../globals/classes/shared/IndexedArray"

const MobileRequests = props => {
	const parentPage = PAGE_STATES.MOBILE.MAIN.REQUESTS
	const [disabled, setDisabled] = useState(true)
	const [services, setServices] = useState(null)
	useEffect(() => {
		getServices().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setServices(new IndexedArray({obj: response.payload.asIndexedArray.obj}))
			}}
		)
	}, [])
	useEffect(() => {
		try {
			setDisabled(!(props.account.id && services))
		} catch (e) {
			setDisabled(true)
		}
	}, [props.account, props.vessels, props.organization, props.marinas, services])
	useEffect(() => {setToStorage("order", {}, "session")}, [])
	const onClick = next => {
		try {
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})
		} catch (e) {
			console.log(e)
		}
	}
	/* TODO: manage issue where variables haven't loaded and user clicks on something */
	let window_ = store.getState().window.current
	if (!window_.levelOne) {window_ = props.origin}
	const buttonStyle = {width: "100%", textAlign: "left", height: "8vh",
		margin: "0.5vh 0vh", borderRadius: "1vh"}
	const buttonClassName = "paralian-theme-level-2"
	switch (window_.levelFour) {
		case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X:
			return <MobileVesselCleaning account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas} services={services}/>
		case PAGES.COMMON.MAIN.REQUESTS.CONCIERGE.X:
			return <ConciergeHome account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas}/>
		case PAGES.COMMON.MAIN.REQUESTS.SHUTTLE_SERVICE.X:
			return <ShuttleHome account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas}/>
		case PAGES.COMMON.MAIN.REQUESTS.VESSEL_TRANSPORT.X:
			return <LaunchHome account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas}/>
		case PAGES.COMMON.MAIN.REQUESTS.VESSEL_FUELING.X:
			return <FuelingHome account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas}/>
		case PAGES.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X:
			return <StorageReservationsHome account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas}/>
		case PAGES.COMMON.MAIN.REQUESTS.VESSEL_PROVISIONING.X:
			return <ProvisionsHome account={props.account} refreshAccount={props.refreshAccount}
				organization={props.organization} vessels={props.vessels} refreshVessels={props.refreshVessels}
				marinas={props.marinas} refreshMarinas={props.refreshMarinas}/>
		default:
			return <div className={"w3-display-middle paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}
				style={{width: "100%", padding: "2vh", fontSize: "2.5vh", maxHeight: "84vh"}}>
				<MobileButtonGeneric id={"concierge"} type={"button"} left={{icon: ICONS.CONCIERGE_BELL,
					style: {paddingLeft: "1vh"}}} text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.CONCIERGE.X)}}
					className={buttonClassName} padding={"1vh"} style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.CONCIERGE.X)}
					disabled={disabled}/>
				<MobileButtonGeneric id={"wash"} type={"button"} left={{icon: ICONS.SOAP, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.WASH_DETAIL.X)}} padding={"1vh"}
					className={buttonClassName} style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.VESSEL_CLEANING.X)}
					disabled={disabled}/>
				<MobileButtonGeneric id={"repair"} type={"button"} left={{icon: ICONS.TOOLS, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"},	content: translate(DICTIONARY.REPAIR_INSTALL_MAINTAIN.X)}}
					className={buttonClassName} padding={"1vh"} style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.VESSEL_REPAIR.X)}
					onClickDisabled={() => newMessage("repair-concierge",
					translate(DICTIONARY.COMING_SOON.X), translate(DICTIONARY.REPAIR_COMING_SOON_MESSAGE.X), "w3-pale-blue",
					ICONS.INFO_CIRCLE, [{id: "concierge-repair", icon: null, text: translate(DICTIONARY.VISIT_CONCIERGE.X),
					onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: parentPage.CONCIERGE.X}})
					}])} disabled={true}/>
				<MobileButtonGeneric id={"launch"} type={"button"} left={{icon: ICONS.FORKLIFT, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.LAUNCH_MOVE_TRANSPORT.X)}} padding={"1vh"}
					className={buttonClassName}
					style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.VESSEL_TRANSPORT.X)}	disabled={false}/>
				<MobileButtonGeneric id={"fuel"} type={"button"} left={{icon: ICONS.GAS_PUMP, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.FUEL_WATER_PUMP_OUT.X)}} padding={"1vh"}
					className={buttonClassName}
					style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.VESSEL_FUELING.X)}	disabled={false}/>
				<MobileButtonGeneric id={"reserve"} type={"button"} className={buttonClassName} padding={"1vh"}
					left={{icon: ICONS.CALENDAR_ALT, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.RESERVE_SLIP_MOORING.X)}}
					style={{width: "100%", textAlign: "left", height: "8vh", margin: "0.5vh 0vh"}}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.VESSEL_STORAGE.X)}	disabled={false}/>
				<MobileButtonGeneric id={"shuttle"} type={"button"} className={buttonClassName} padding={"1vh"}
					left={{icon: ICONS.SHUTTLE_VAN, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.SHUTTLE_SERVICE.X)}}
					style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.SHUTTLE_SERVICE.X)} disabled={false}/>
				<MobileButtonGeneric id={"ice"} type={"button"} left={{icon: ICONS.ICE, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.ICE_FOOD_PROVISIONS.X)}} padding={"1vh"}
					className={buttonClassName} style={buttonStyle}
					onClick={() => onClick(PAGE_STATES[window_.levelOne].MAIN.REQUESTS.VESSEL_PROVISIONING.X)}
					disabled={false}/>
				<MobileButtonGeneric id={"crew"} type={"button"} left={{icon: ICONS.PILOT, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.CREW.X)}} className={buttonClassName}
					style={buttonStyle} padding={"1vh"}
					onClickDisabled={() => newMessage("crew-concierge", translate(DICTIONARY.COMING_SOON.X),
						translate(DICTIONARY.CREW_COMING_SOON_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE,
						[{id: "concierge-crew", icon: null, text: translate(DICTIONARY.VISIT_CONCIERGE.X),
					onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: parentPage.CONCIERGE.X}})}])}
					disabled={true}/>
				<MobileButtonGeneric id={"financing"} type={"button"} left={{icon: ICONS.FILE, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.FINANCING_INSURANCE_SURVEY.X)}}
					className={buttonClassName} padding={"1vh"} style={buttonStyle}
					onClickDisabled={() => newMessage("financing-insurace-survey-concierge",
					translate(DICTIONARY.COMING_SOON.X), translate(DICTIONARY.FINANCING_COMING_SOON_MESSAGE.X),
					"w3-pale-blue", ICONS.INFO_CIRCLE, [{id: "concierge-financing-insurace-survey", icon: null,
						text: translate(DICTIONARY.VISIT_CONCIERGE.X),
						onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: parentPage.CONCIERGE.X}})
					}])} disabled={true}/>
				<MobileButtonGeneric id={"listings"} type={"button"} left={{icon: ICONS.STORE, style: {paddingLeft: "1vh"}}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.LISTINGS_MARKETPLACE.X)}} padding={"1vh"}
					className={buttonClassName} style={buttonStyle}
					onClickDisabled={() => newMessage("listings-marketplace-message",
					translate(DICTIONARY.COMING_SOON.X), translate(DICTIONARY.LISTINGS_COMING_SOON_MESSAGE.X),
					"w3-pale-blue", ICONS.INFO_CIRCLE, [{	id: "view-listings", icon: null,
						text: translate(DICTIONARY.VIEW_LISTINGS.X),
						onClick: () => {window.open("https://www.paralian.io/yacht-listings", "_blank").focus()}},
							{id: "concierge-marketplace", icon: null, text: translate(DICTIONARY.VISIT_CONCIERGE.X),
					onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: parentPage.CONCIERGE.X}})}])}
					disabled={true}/>
			</div>
	}
}

export default MobileRequests