import React, {useEffect, useMemo, useState} from "react"
import MobileInputAddressLine from "./MobileInputAddressLine"
import MobileInputCountryDropdown from "./MobileInputCountryDropdown"
import MobileInputStateDropdown from "./MobileInputStateDropdown"
import MobileInputCity from "./MobileInputCity"
import MobileInputPostCode from "./MobileInputPostCode"
import Country from "../../../globals/classes/shared/Country"
import Address from "../../../globals/classes/shared/Address"
import State from "../../../globals/classes/shared/State"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Address} props.address - placeholder address (in case of edit)
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {boolean} props.autoComplete
 * @param {boolean} props.showLabel
 * @param {boolean} props.showLine3
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputAddressFields = props => {
	const address = useMemo(() => new Address(props.address), [props.address])
	const [country, setCountry] = useState(new Country(address.country))
	const [state, setSate] = useState(new State(address.state))
/*	useEffect(() => {
			setCountry(new Country(props.address.country))
	}, [props.address])
	useEffect(() => {
		setSate(new State(props.address.state))
	}, [props.address])*/
	// console.log("main", country, props.country)
	const form = props.form || "form"
	const className = props.className || "paralian-theme-level-1"
	const addressLine3 = props.showLine3 || address.line3 ?
		<MobileInputAddressLine id={props.id} number={3} form={form} showLabel={props.showLabel}
			autoComplete={props.autoComplete} defaultValue={address.line3} style={props.style} className={className}/> : <></>
	const postCodeCode = props.hidePostCode ? <></> : <MobileInputPostCode id={props.id} form={form} style={props.style}
		showLabel={props.showLabel} autoComplete={props.autoComplete} defaultValue={address.postCode} className={className}/>
	return <>
		<MobileInputAddressLine id={props.id} number={1} form={form} showLabel={props.showLabel}
			autoComplete={props.autoComplete} defaultValue={address.line1} style={props.style} className={className}/>
		<MobileInputAddressLine id={props.id} number={2} form={form} showLabel={props.showLabel}
			autoComplete={props.autoComplete} defaultValue={address.line2} style={props.style} className={className}/>
		{addressLine3}
		<MobileInputCountryDropdown id={props.id} form={form} showLabel={props.showLabel} defaultValue={country.code} autoFocus={false}
			setCountry={setCountry} style={props.style} className={className} autoComplete={props.autoComplete}/>
		<MobileInputStateDropdown id={props.id} form={form} showLabel={props.showLabel} defaultValue={state.code} autoFocus={false}
			country={country} style={props.style} className={className} autoComplete={props.autoComplete} onChange={v => setSate(new State(v))}/>
		<MobileInputCity id={props.id} form={form} showLabel={props.showLabel} autoComplete={props.autoComplete}
			defaultValue={address.city} style={props.style} className={className}/>
		{postCodeCode}
		</>
}

export default MobileInputAddressFields