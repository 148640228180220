import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import Modal from "../../../../../globals/components/Modal"
import StripeConfirmOrder from "../../../payment/StripeConfirmOrder"
import StripeElementWrapper from "../../../payment/StripeElementWrapper"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.provider
 * @param {string} props.order.provider.connectId
 * @param {IndexedArray} props.services
 * @param {Organization} props.organization
 * @param {Account} props.account
 * @param {boolean} props.showConfirmOrderModal
 * @param {function} props.refreshOrders
 * @param {function} props.refreshAccount
 * @param {function} props.refreshOrganization
 * @param {function} props.setShowConfirmOrderModal
 * @return {JSX.Element}
 * @constructor
 */
const ConfirmOrderModal = props => {
	return props.showConfirmOrderModal ? <Modal id={`order-${props.order.id}-rate-modal`} padding={"0vh"}
		title={translate(DICTIONARY.CONFIRM.X)} icon={ICONS.CHECK_DOUBLE} onClick={() => props.setShowConfirmOrderModal(false)}
		body={<StripeElementWrapper element={
			<StripeConfirmOrder order={props.order} services={props.services} organization={props.organization}
				isOrganization={!!props.organization.id} refreshOrders={props.refreshOrders} account={props.account}
				refreshAccount={props.refreshAccount} refreshOrganization={props.refreshOrganization}
				setShowModal={props.setShowConfirmOrderModal}/>
		} connectId={props.order.provider.connectId}/>}/> : <></>
}

export default ConfirmOrderModal