import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileVesselStorageSpaceAndCapacityEdit from "./MobileVesselStorageSpaceAndCapacityEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import Storage from "../../../../globals/classes/shared/vessels/Storage"
import MobileVesselStorageSpaceAndCapacityFields from "./MobileVesselStorageSpaceAndCapacityFields"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {Storage} props.facilities
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageSpaceAndCapacity = props => {
	const [storage, setStorage] = useState(props.storage)
	const [edit, setEdit] = useState(false)
	useEffect(() => setStorage(props.storage), [props.storage])
	const content = edit ? <MobileVesselStorageSpaceAndCapacityEdit id={props.id} units={props.units} storage={storage}
		onSuccess={storage => {
			props.refresh().then()
			setStorage(new Storage(storage))
			setEdit(false)
		}}/> : <MobileVesselStorageSpaceAndCapacityFields storage={storage} units={props.units}/>

	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.SPACE_AND_CAPACITY.X)]} subtitle={translate(DICTIONARY.MINIMUM_REQUIREMENTS.X)}/>
		{content}
	</div>

	/*
	<i className={ICONS.INFO_CIRCLE} style={{paddingLeft: "1vh"}}
					 onClick={() => newMessage("facilities", "Storage Space & Capacity", "Storage " +
						 "space and capacity values pertain to the maximum of each category. Storage weight " +
						 "pertains to the vessel's maximum unoccupied weight. " +
						 "This includes hull, equipment, and fluids. Storage dimensions of length, width, " +
						 "height, draught pertain to the vessel's \"overall\" dimensions. These take into account " +
						 "all equipment such as engines, antennae, etc.",
						 "w3-pale-blue", ICONS.INFO_CIRCLE)}> </i>
	 */
}

export default MobileVesselStorageSpaceAndCapacity
