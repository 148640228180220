import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {deleteMessage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileVesselAccessCard from "./MobileVesselAccessCard"
import MobileVesselAccessIndividual from "./MobileVesselAccessIndividual"
import MobileVesselAccessOrganization from "./MobileVesselAccessOrganization"
import VesselAccess from "../../../../globals/classes/shared/vessels/VesselAccess"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {VesselAccess} props.vesselAccess
 * @param {Account} props.account - account name
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselAccess = props => {
	const [vesselAccess, setVesselAccess] = useState(new VesselAccess(props.vesselAccess))
	const [expanded, setExpanded] = useState(false)
	// const [add, setAdd] = useState(false)
	const accountAccess = {...vesselAccess.account}
	accountAccess.name = props.account.contact.name
	useEffect(() => {setVesselAccess(new VesselAccess(props.vesselAccess))}, [props.vesselAccess])
	// console.log("vesselAccess", vesselAccess)
	const onRemove = (id, type) => {
		props.refresh().then()
		const vesselAccess_ = {...vesselAccess}
		const access_ = type === "individuals" ? vesselAccess_.individuals : vesselAccess_.organizations
		const filtered = access_.filter(access => access.id !== id)
		type === "individuals" ? vesselAccess_.individuals = filtered : vesselAccess_.organizations = filtered
		setVesselAccess(vesselAccess_)
	}
	const onEdit = (access, type) => {
		props.refresh().then()
		const vesselAccess_ = {...vesselAccess}
		const access_ = type === "individuals" ? vesselAccess_.individuals : vesselAccess_.organizations
		const index = access_.findIndex(access__ => access__.id === access.id)
		type === "individuals" ? vesselAccess_.individuals[index] = access : vesselAccess_.organizations[index] = access
		setVesselAccess(vesselAccess_)
	}
	const onAdd = () => {
		props.refresh().then()
		// const vesselAccess_ = {...vesselAccess}
		// vesselAccess_.individuals = [...vesselAccess_.individuals, access]
		// setVesselAccess(vesselAccess_)
		newMessage("access-add-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "", "w3-pale-green", ICONS.CHECK)
		setTimeout(() => deleteMessage("access-add-success"), 3000)
	}
	const content_ = !expanded ? <></> : <div id={"vesselAccess"} className={"w3-animate-top w3-animate-opacity"}
		style={{fontSize: "2vh", textAlign: "left"}}>
		<MobileVesselAccessCard id={props.id} key={`access-currentAccount`} account={props.account} accesses={accountAccess}
			canEdit={props.canEdit} onRemove={onRemove}/>
		<MobileVesselAccessIndividual id={props.id} onRemove={onRemove} canEdit={props.canEdit} account={props.account} onAdd={onAdd}
			individuals={vesselAccess.individuals.filter(accesses => accesses.id !== props.account.id)} onEdit={onEdit} accountAccess={accountAccess}/>
		<MobileVesselAccessOrganization id={props.id} organizations={vesselAccess.organizations} onRemove={onRemove} onAdd={onAdd}
			onEdit={onEdit} canEdit={props.canEdit} account={props.account} accountAccess={accountAccess}/>
	</div>
	// <MobileAddNewBanner text={translate(DICTIONARY.GRANT_ACCESS.X)}	onClick={() => setAdd(!add)}/>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.ACCESS.X)} top={props.top}
			onClick={() => setExpanded(!expanded)}/>
		{content_}
	</>
}

export default MobileVesselAccess