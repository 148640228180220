import {store} from "../../../../index"
import {getFromStorage} from "../localFunctions"
import Mode from "../../../classes/client/Mode"

/**
 * Retrieves loggedIn status of application
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @returns {boolean|null}
 */
const getLoggedIn = () => {
	let state, local
	try {
		// get redux loggedIn state
		state = store.getState().device.loggedIn
		// if redux loggedIn state is available, return redux loggedIn state
		if (typeof state === "boolean") {return state}
		if (state === "true" || state === "false") {return state === "true"}
		// if redux loggedIn state is not available, get loggedIn from localStorage...
		// where it is kept as failsafe for when the browser is refreshed and the redux state is reset to its original...
		// undefined state
		local = new Mode({loggedIn: getFromStorage("mode").loggedIn})
		// return local.loggedIn, which will be null if what was returned from localStorage was not a boolean value
		return local.loggedIn
	} catch (e) {
		console.log(e)
		return null
	}
}

export default getLoggedIn