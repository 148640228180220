import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {setVesselHIN} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputVesselHIN from "../../../shared/input/text/MobileInputVesselHIN"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.hin
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHinEdit = props => {
	const onSubmit = async event => {
		try {
			event.preventDefault()
			const hin = document.getElementById("hin-input").value
			const response = await setVesselHIN(props.id, hin)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess(hin)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <MobileInputVesselHIN required={true} autoFocus={false} form={"hin-form"}
		className={"paralian-theme-level-1"} defaultValue={props.hin}/>
	return <MobileInputForm id={"hin"} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselHinEdit