import React from 'react'
import {PAGE_STATES} from "../globals/constants/client/constants"
import getMode from "../globals/functions/client/mode/getMode"
import Welcome from "../globals/components/Welcome"
import MobileMain from "./main/MobileMain"
import MobileLogin from "./login/base/MobileLogin"

/**
 * Moves on to either MobileMain or MobileLogin based on login status
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const Mobile = () => {
	// console.log("Mobile rendered")
	const mode = getMode()
	if (mode.loggedIn === true) {
		return <MobileMain origin={PAGE_STATES.MOBILE.X}/>
		/*
	const MobileMain = React.lazy(() => import("./main/MobileMain"))
		return <Suspense fallback={<SplashScreen/>}>
			<MobileMain origin={PAGE_STATES.MOBILE.X}/>
		</Suspense>
	 */
	}
	if (mode.loggedIn === false) {
		return <MobileLogin origin={PAGE_STATES.MOBILE.X}/>
	}
	return <Welcome/>

}

export default Mobile