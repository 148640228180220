import Day from './Day'

class Week {
	/**
	 *
	 * @param {Week} [week = null]
	 * @param {Day} [week.monday]
	 * @param {Day} [week.tuesday]
	 * @param {Day} [week.wednesday]
	 * @param {Day} [week.thursday]
	 * @param {Day} [week.friday]
	 * @param {Day} [week.saturday]
	 * @param {Day} [week.sunday]
	 */
	constructor (week = null) {
		try {
			this.monday = new Day(week.monday)
			this.tuesday = new Day(week.tuesday)
			this.wednesday = new Day(week.wednesday)
			this.thursday = new Day(week.thursday)
			this.friday = new Day(week.friday)
			this.saturday = new Day(week.saturday)
			this.sunday = new Day(week.sunday)
		} catch (e) {
			this.monday = new Day()
			this.tuesday = new Day()
			this.wednesday = new Day()
			this.thursday = new Day()
			this.friday = new Day()
			this.saturday = new Day()
			this.sunday = new Day()
		}
	}
}

export default Week