import React from "react"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputText from "./MobileInputText"
/**
 *
 * @param {Object} props
 * @param {string} props.form
 * @param {string} props.className
 * @param {string} props.defaultValue
 * @param {boolean} props.required
 * @param {boolean} props.autoFocus
 * @param {boolean} props.showLabel
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputVesselHIN = props => {
	const params = INPUT_ELEMENT_PARAMETERS.HIN
	return <MobileInputText id={"hin"} name={"hin"} required={props.required} style={{margin: "1vh 0vw"}}
		invalidMessage={translate(DICTIONARY.HIN_DESCRIPTION.X)} defaultValue={props.defaultValue} form={props.form}
		className={props.className || "paralian-theme-level-1"} showLabel={props.showLabel}	autoComplete={"off"}
		label={translate(DICTIONARY.HULL_IDENTIFICATION_NUMBER.X)} autoFocus={props.autoFocus}
		placeholder={params.PLACEHOLDER} title={params.TITLE} maxLength={params.LENGTH.MAX} pattern={params.PATTERN}/>
}

export default MobileInputVesselHIN