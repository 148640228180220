import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {getDefaultPricingStructures, getServices} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileService from "./MobileService"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileInputServiceAdd from "./MobileInputServiceAdd"
import Modal from "../../../globals/components/Modal"
import IndexedArray from "../../../globals/classes/shared/IndexedArray"
import Servicio from "../../../globals/classes/shared/Servicio"

/**
 *
 * @param {Object} props
 * @param {Servicio[]} props.services - organization's services
 * @param {Servicio[]} props.parents - all available services
 * @param {Area} props.area
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @param {boolean} props.canEdit
 * @param {Country} props.country
 * @param {Currency} props.currency
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileServices = props => {
	const [allServices, setAllServices] = useState({asArray: [], asIndexedArray: new IndexedArray(),
		contained: new Servicio(), children: new IndexedArray()})
	const [services, setServices] = useState([])
	const [add, setAdd] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [defaultPricingStructures, setDefaultPricingStructures] = useState(new IndexedArray())
	// console.log("defaultPricingStructures", defaultPricingStructures)
	// console.log("services", services)
	// console.log("allServices", allServices)
	const getChildren = parent => {
		let children = []
		const _children = [...parent.children.parents, ...parent.children.singles]
		for (let i = 0; i < _children.length; i++) {
			children.push({id: _children[i].id, isParent: _children[i].hasChildren})
			if (_children[i].hasChildren) {children = [...children, ...getChildren(_children[i])]}
		}
		return children
	}
	useEffect(() => {
		getDefaultPricingStructures().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setDefaultPricingStructures(new IndexedArray({obj: response.payload}))
			}
		})
	}, [])
	useEffect(() => {setServices(props.services)}, [props.services])
	useEffect(() => {
		getServices().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const asArray = response.payload.asArray.map(v => new Servicio(v))
				setAllServices({
					asArray: asArray,
					asIndexedArray: new IndexedArray({array: asArray, key: "id"}),
					contained: response.payload.contained.map(v => new Servicio(v)),
					children: new IndexedArray({array: asArray.map(v => {
							return {
								id: v.id,
								children: getChildren(v)
							}
						}), key: "id"})
				})
			}
		})
	}, [])
	// console.log(parents)
	const preSelected = []
	const content = services.map(service => {
		// console.log("service", service)
		preSelected.push(service.id || service.item)
		// service={}
		return <MobileService key={`service-${service.id || service.item}`} canEdit={props.canEdit}
			service={service.id ? service : allServices.asIndexedArray.byIndex(service.item)} className={""}
			defaultPricingStructures={defaultPricingStructures} currency={props.currency} refresh={props.refresh}/>
	})
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} id={"services"} zIndex={props.zIndex} top={props.top}
			text={translate(DICTIONARY.SERVICES.X)} onClick={() => {setExpanded(!expanded)}}/>
		{!expanded ? <></> :
			<div id={"services"} className={"w3-animate-top"} style={{textAlign: "left"}}>
				{content}
				{add && props.canEdit ? <Modal id={"service-add-modal"} padding={"0vh"} icon={ICONS.TRUCK}
					title={translate(DICTIONARY.ADD_SERVICE.X)} onClick={() => setAdd(false)} body={
					<MobileInputServiceAdd onUndo={() => setAdd(false)} allServices={allServices}
						currency={props.currency} defaultPricingStructures={defaultPricingStructures}
						country={props.country} preSelected={preSelected} onSubmit={service => {
							if (props.refresh) {props.refresh().then()}
							setServices([...services, service])
							setAdd(false)
						}}/>
				}/> : <></>}
				{props.canEdit ? <MobileAddNewBanner className={`services`} text={translate(DICTIONARY.ADD_SERVICE.X)}
					onClick={() => {
						add ? setAdd(false) : setAdd(true)
					}}/> : <></>}
			</div>}
	</>
}

export default MobileServices