import Mode from "../../../classes/client/Mode"
import getAccountType from "./getAccountType"
import getDeviceType from "./getDeviceType"
import getLoggedIn from "./getLoggedIn"

/**
 * Retrieves application's Mode, which specifies the account type of the account which is logged in (if logged in)...
 * the device type being used, and the loggedIn state of the application
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @returns {Mode}
 */
const getMode = () => {
	return new Mode({
		account: getAccountType(false),
		device: getDeviceType(),
		loggedIn: getLoggedIn()
	})
}

export default getMode