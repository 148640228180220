import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate, arrayRange} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {number} props.start
 * @param {number} props.stop
 * @param {number} props.step
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileNumberRangeDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const onChange = props.onChange ? props.onChange : () => {}
	const list = arrayRange(props.start, props.stop, props.step).map(number => {
		return	{value: `${number}`, label: `${number}`}})
	const defaultValue = props.defaultValue || "1"
	return <MobileInputGeneric type={"dropdown"} id={props.id} className={className} size={"1"} showLabel={false}
		label={translate(DICTIONARY.DATE.X)} multiple={false} dataId={1} name={"date"} form={props.form} autoFocus={false}
		required={true} left={props.left}	defaultValue={defaultValue} list={list}	bottomRadius={props.bottomRadius}
		dataA={defaultValue} onChange={() => {
		const element = document.getElementById(`${props.id}-input`)
		element.setAttribute("data-a", element.value)
		onChange(element.value)
	}}/>
}

export default MobileNumberRangeDropdown