import React from "react"
import {store} from "../../index"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../globals/constants/client/constants"
import {ADDRESS_TYPE} from "../../globals/constants/shared/enumerators"
import {translate} from "../../globals/functions/client/localFunctions"
import {matchOrgAddress} from "../../globals/functions/client/serverFunctions"
import MobileQuestionTwoOptions from "../shared/input/combination/MobileQuestionTwoOptions"

const MobileLoginSupportAddressQ = props => {
	const levelOne = store.getState().window.current.levelOne
	return <MobileQuestionTwoOptions icon={ICONS.USER_HEADSET} keyname={"support"}
		question={translate(DICTIONARY.SUPPORT_ADDRESS_Q_MESSAGE.X)} back={props.back}
		left={{next: PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS.X,
			text: translate(DICTIONARY.NO.X), value: false}}
		right={{next: PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_HOURS_OF_OPERATION.X,
			text: translate(DICTIONARY.YES.X), value: true,
			onClick: async () => await matchOrgAddress(ADDRESS_TYPE.MAILING, ADDRESS_TYPE.SUPPORT)}} />
}

export default MobileLoginSupportAddressQ