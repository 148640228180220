import React from "react"
import MobileHoliday from "./MobileHoliday"
import Holiday from "../../../globals/classes/shared/Holiday"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {Holiday[]} props.holidays
 * @param {boolean} props.use24Hr
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileHolidays = props => {
	try {
		return props.holidays.map(holiday => <MobileHoliday key={`${holiday.id}-${holiday.name}`} id={props.id}
			refresh={props.refresh}	use24Hr={props.use24Hr} holiday={new Holiday(holiday)} className={props.className}
			canEdit={props.canEdit}/>)
	} catch (e) {
		return <></>
	}
}

export default MobileHolidays