import React from "react"
import MobileDayFields from "./MobileDayFields"

/**
 *
 * @param {Object} props
 * @param {Holiday} props.holiday
 * @param {boolean} props.use24Hr
 * @returns {JSX.Element}
 * @constructor
 */
const MobileHolidayFields = props => {
	// console.log(props.holiday.day)
	return <div className={"w3-animate-opacity"}>
		<div>{props.holiday.asRecurrenceString}</div>
		<MobileDayFields day={props.holiday.day} use24Hr={props.use24Hr} style={{textAlign: "center"}}/>
	</div>
}

export default MobileHolidayFields