import React from "react"
// import {IMAGES} from "../../../globals/constants/client/constants"
import paralianLogo from "../../../images/bow-logo-dark-standalone-3d-glow.png"
/**
 * Displays top icon.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const MobileHeaderIcon = () => {
	// <img src={IMAGES.LOGO_MOBILE} alt={"PARALIAN"} style={{width: "100%", maxWidth: "10vh"}}/>
	return <picture className={"w3-display-topmiddle"} style={{marginTop: "4vh"}}>
		<img src={paralianLogo} alt={"PARALIAN"} style={{width: "100%", maxWidth: "10vh"}}/>
	</picture>
}

export default MobileHeaderIcon