import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {Object} props.order.charges
 * @param {Object} props.order.charges.total
 * @param {number} props.order.charges.total.items
 * @param {function} props.setShowOrderDetails
 * @return {JSX.Element}
 * @constructor
 */
const OrderDetailsButton = props => {
	return props.order.charges.total.items !== null ? <div style={{margin: "1vh 2vh", borderRadius: "1vh"}}>
		<MobileButtonGeneric id={"adjust-propose"} type={"button"} text={{style: {paddingLeft: "2vh"},
			content: translate(DICTIONARY.SHOW_ORDER_DETAILS.X)}}	left={{icon: ICONS.INFO_CIRCLE}}
			className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}}
			onClick={() => props.setShowOrderDetails(true)}/></div> : <></>
}

export default OrderDetailsButton