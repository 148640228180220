import Dictionary from '../../constants/shared/dictionary'
import {PRICING_STRUCTURE_UNITS} from "../../constants/shared/enumerators"
import {translate} from '../../functions/shared/local'
import {typeOrNull, enumOrNull} from '../../functions/shared/local'
import Currency from './Currency'
/* TODO: privatize with properties_ with  when supported */

class Pricing {
	/**
	 * @param {Pricing} [pricing = null]
	 * @param {number} [pricing.id]
	 * @param {number} [pricing.price] - for basic pricing, unit price
	 * @param {string} [pricing.unit] - for basic pricing, unit of measure
	 * @param {number} [pricing.maxDiscount] - for advanced pricing, max % discount applied using calendar price variations
	 * @param {number} [pricing.maxPremium] - for advanced pricing, max % premium applied using calendar price variations
	 * @param {number} [pricing.floor] - for advanced pricing, floor value of final result after evaluating formula
	 * @param {number} [pricing.ceiling] - for advanced pricing, max value of final result after evaluating formula
	 * @param {Currency} [pricing.currency]
	 * @param {string} [pricing.formula]
	 */
	constructor(pricing = null) {
		try {
			this.id = typeOrNull(pricing.id, "number")
			this.price = typeOrNull(pricing.price, "number")
			this.unit_ = enumOrNull(pricing.unit || pricing.unit_, PRICING_STRUCTURE_UNITS)
			this.floor = typeOrNull(pricing.floor, "number")
			this.ceiling = typeOrNull(pricing.ceiling, "number")
			this.maxDiscount = typeOrNull(pricing.maxDiscount, "number")
			this.maxPremium = typeOrNull(pricing.maxPremium, "number")
			this.currency_ = new Currency(pricing.currency || pricing.currency_)
			this.formula = typeOrNull(pricing.formula, "string")
		} catch (e) {
			this.id = null
			this.price = null
			this.unit_ = null
			this.floor = null
			this.ceiling = null
			this.maxDiscount = null
			this.maxPremium = null
			this.currency_ = new Currency()
			this.formula = null
		}
	}

	get unit(){
		return this.unit_
	}

	set unit(unit) {
		this.unit_ = enumOrNull(unit, PRICING_STRUCTURE_UNITS)
	}

	get currency() {
		return this.currency_
	}

	set currency(currency) {
		this.currency_ = new Currency(currency)
	}

	/**
	 *
	 * @returns {string|null}
	 */
	get rateAsString() {
		return (this.price !== null && this.unit !== null) ?
			this.currency.unitAsText(this.price, 1) + ` ${translate(Dictionary.PER.X)} ${translate(this.unit.toUpperCase())}` :
			this.formula
	}

	/**
	 *
	 * @returns {string}
	 */
	get minAsString() {
		return this.floor !== null ?
			this.currency.unitAsText(this.floor, 1) + " " +	translate(Dictionary.MINIMUM.X) : ""
	}

	/**
	 *
	 * @param {number} measurement
	 * @returns {number|null}
	 */
	calcTotal(measurement) {
		return (this.price !== null && this.unit !== null && this.floor !== null) ?
			((measurement * this.price) > this.floor) ? measurement * this.price : this.floor : null
	}
}

export default Pricing