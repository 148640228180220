const Icons = {
	ENGINE: ["fad", "engine"],
	PILOT: ["fad", "user-pilot"],
	ICE: ["fad", "whiskey-glass-ice"],
	PUMP: ["fad", "pump"],
	WHATSAPP: ["fab", "whatsapp"],
	MESSAGE_TEXT: ["fad", "message-text"],
	SCROLL: ["fad", "chevrons-down-bounce"],
	SHIELD_SLASH: ["fad", "shield-slash"],
	DOVE: ["fad", "dove"],
	SLIDERS_SIMPLE: ["fad", "sliders-simple"],
	FOLDER_OPEN: ["fad", "folder-open"],
	I_CURSOR: ["fad", "i-cursor"],
	REPEAT: ["fad", "repeat-alt"],
	FIRE_EXTINGUISHER: ["fad", "fire-extinguisher"],
	FIRE: ["fad", "fire"],
	PLUG: ["fad", "plug"],
	BURN: ["fad", "burn"],
	GIFT: ["fad", "gift"],
	BALLOT_CHECK: ["fad", "ballot-check"],
	MOUNTAIN: ["fad", "mountain"],
	DOT_CIRCLE: ["fad", "dot-circle"],
	MINUS_CIRCLE: ["fad", "minus-circle"],
	CASH_REGISTER: ["fad", "cash-register"],
	COIN: ["fad", "coin"],
	BELL_SLASH: ["fad", "bell-slash"],
	DOLLY_FLATBED_EMPTY: ["fad", "dolly-flatbed-empty"],
	HAND_HOLDING_USD: ["fad", "hand-holding-usd"],
	REPLY: ["fad", "reply"],
	COMMENT_DOTS: ["fad", "comment-dots"],
	USER_HARD_HAT: ["fad", "user-hard-hat"],
	CLIPBOARD_LIST_CHECK: ["fad", "clipboard-list-check"],
	HEART_CIRCLE: ["fad", "heart-circle"],
	FROWN: ["fad", "frown"],
	AWARD: ["fad", "award"],
	TROPHY: ["fad", "trophy-alt"],
	COOKIE_BITE: ["fad", "cookie-bite"],
	HANDSHAKE: ["fad", "handshake-alt"],
	OCTAGON: ["fad", "octagon"],
	SHARE_ALT: ["fad", "share-alt"],
	SHARE: ["fad", "share"],
	CROWN: ["fad", "crown"],
	COMMENT_ALT_CHECK: ["fad", "comment-alt-check"],
	COMMENT_ALT_TIMES: ["fad", "comment-alt-times"],
	PEOPLE_ARROWS: ["fad", "people-arrows"],
	USERS: ["fad", "users"],
	PAPER_PLANE: ["fad", "paper-plane"],
	RADAR: ["fad", "radar"],
	ID_BADGE: ["fad", "id-badge"],
	STRIPE_S: ["fab", "stripe-s"],
	STRIPE: ["fab", "stripe"],
	STRIPE_BEAT: ["fab", "stripe-beat"],
	ARROWS_ALT: ["fad", "arrows-alt"],
	PLAY: ["fad", "play"],
	PAUSE: ["fad", "pause"],
	STOP: ["fad", "stop"],
	STEP_FORWARD: ["fad", "step-forward"],
	STEP_BACKWARD: ["fad", "step-backward"],
	SIGN_IN_ALT: ["fad", "sign-in-alt"],
	STREET_VIEW: ["fad", "street-view"],
	DOG_LEASHED: ["fad", "dog-leashed"],
	ACCESSIBLE_ICON: ["fad", "accessible-icon"],
	RACQUET: ["fad", "racquet"],
	GOLF_CLUB: ["fad", "golf-club"],
	BASKETBALL_BALL: ["fad", "basketball-ball"],
	SWIMMER: ["fad", "swimmer"],
	WATER_LOWER: ["fad", "water-lower"],
	LANDMARK: ["fad", "landmark"],
	PRESCRIPTION: ["fad", "prescription"],
	DOG: ["fad", "dog"],
	CAT_SPACE: ["fad", "cat-space"],
	PAW: ["fad", "paw"],
	CHILD: ["fad", "child"],
	HASHTAG: ["fad", "hashtag"],
	HAND_SPARKLES: ["fad", "hand-sparkles"],
	SPA: ["fad", "spa"],
	MEDKIT: ["fad", "medkit"],
	TOOTH: ["fad", "tooth"],
	CUT: ["fad", "cut"],
	LANDMARK_ALT: ["fad", "landmark-alt"],
	CAR_WASH: ["fad", "car-wash"],
	BED: ["fad", "bed"],
	GAMEPAD_ALT: ["fad", "gamepad-alt"],
	FORT_AWESOME: ["fab", "fort-awesome"],
	FISH: ["fad", "fish"],
	TOILET: ["fad", "toilet"],
	SHOWER: ["fad", "shower"],
	PASSPORT: ["fad", "passport"],
	HELICOPTER: ["fad", "helicopter"],
	SNOWFLAKES: ["fad", "snowflakes"],
	WASHER: ["fad", "washer"],
	DRYER_ALT: ["fad", "dryer-alt"],
	TRUCK_RAMP: ["fad", "truck-ramp"],
	TINT_SLASH: ["fad", "tint-slash"],
	OIL_CAN: ["fad", "oil-can"],
	DUMPSTER: ["fad", "dumpster"],
	SAC_DOLLAR: ["fad", "sack-dollar"],
	SAC_DOLLAR_SHAKE: ["fad", "sack-dollar-shake"],
	MONITOR: ["fad", "desktop"],
	DUMBBELL: ["fad", "dumbbell"],
	CAR: ["fad", "car"],
	GLASS_MARTINI_ALT: ["fad", "glass-martini-alt"],
	UTENSILS: ["fad", "utensils"],
	SHIELD_CHECK: ["fad", "shield-check"],
	USER_SHIELD: ["fad", "user-shield"],
	USER_UNLOCK: ["fad", "user-unlock"],
	BROWSER: ["fad", "browser"],
	COINS: ["fad", "coins"],
	DUCK: ["fad", "duck"],
	WHALE: ["fad", "whale"],
	ELLIPSIS_H: ["fad", "ellipsis-h"],
	EMAIL: ["fad", "at"],
	USER_ASTRONAUT: ["fad", "user-astronaut"],
	IMAGE: ["fad", "image"],
	CIRCLE_NOTCH: ["fad", "circle-notch"],
	PENCIL_PAINTBRUSH: ["fad", "pencil-paintbrush"],
	TAGS: ["fad", "tags"],
	UMBRELLA_BEACH: ["fad", "umbrella-beach"],
	DOOR_OPEN: ["fad", "door-open"],
	PALETTE: ["fad", "palette"],
	BADGE_CHECK: ["fad", "badge-check"],
	GLOBE_AMERICAS: ["fad", "globe-americas"],
	USER_HEADSET: ["fad", "user-headset"],
	MAILBOX: ["fad", "mailbox"],
	STORE_ALT: ["fad", "store-alt"],
	PAINT_ROLLER: ["fad", "paint-roller"],
	PAINT_BRUSH: ["fad", "paint-brush"],
	HORIZONTAL_RULE: ["fad", "horizontal-rule"],
	CHECK_DOUBLE: ["fad", "check-double"],
	USER_TIE: ["fad", "user-tie"],
	CC_AMAZON_PAY: ["fab", "cc-amazon-pay"],
	CC_APPLE_PAY: ["fab", "cc-apple-pay"],
	CC_DINERS_CLUB: ["fab", "cc-diners-club"],
	CC_JCB: ["fab", "cc-jcb"],
	CC_PAYPAL: ["fab", "cc-paypal"],
	CC_STRIPE: ["fab", "cc-stripe"],
	CC_DISCOVER: ["fab", "cc-discover"],
	CC_AMEX: ["fab", "cc-amex"],
	CC_MASTERCARD: ["fab", "cc-mastercard"],
	CC_VISA: ["fab", "cc-visa"],
	WALLET: ["fad", "wallet"],
	CREDIT_CARD: ["fad", "credit-card"],
	CREDIT_CARD_SHAKE: ["fad", "credit-card-shake"],
	SIM_CARD: ["fad", "sim-card"],
	SAD_TEAR: ["fad", "sad-tear"],
	ALARM_CLOCK: ["fad", "alarm-clock"],
	CALENDAR_STAR: ["fad", "calendar-star"],
	CLOCK: ["fad", "clock"],
	STAMP: ["fad", "stamp"],
	CAMERA_ALT: ["fad", "camera-alt"],
	CONCIERGE_BELL: ["fad", "concierge-bell"],
	MAP_MARKER_ALT: ["fad", "map-marker-alt"],
	MAP_MARKER_ALT_BOUNCE: ["fad", "map-marker-alt-bounce"],
	CROSSHAIRS: ["fad", "crosshairs"],
	TACHOMETER: ["fad", "tachometer"],
	TACHOMETER_SLOWEST: ["fad", "tachometer-slowest"],
	TACHOMETER_SLOW: ["fad", "tachometer-slow"],
	TACHOMETER_FAST: ["fad", "tachometer-fast"],
	TACHOMETER_FASTEST: ["fad", "tachometer-fastest"],
	TACHOMETER_AVERAGE: ["fad", "tachometer-average"],
	FILE_PDF: ["fad", "file-pdf"],
	SHIELD: ["fad", "shield"],
	USER_SECRET: ["fad", "user-secret"],
	EYE: ["fad", "eye"],
	PHONE_FAR: ["far", "phone"],
	UNDO: ["fad", "undo"],
	BACK: ["fad", "arrow-alt-left"],
	UPLOAD: ["fad", "upload"],
	CHECK: ["fad", "check"],
	SIGN_OUT: ["fad", "sign-out"],
	MAP_PIN_FAR: ["far", "map-pin"],
	MAP_PIN: ["fad", "map-pin"],
	STORE: ["fad", "store"],
	MAP_MARKER: ["fad", "map-marker"],
	STAR: ["fad", "star"],
	STAR_EXCLAMATION: ["fad", "star-exclamation"],
	STAR_HALF_ALT: ["fad", "star-half-alt"],
	RULER_TRIANGLE: ["fad", "ruler-triangle"],
	BARS: ["fad", "bars"],
	COG: ["fad", "cog"],
	ENVELOPE_FAR: ["far", "envelope"],
	UNLOCK_ALT: ["fad", "unlock-alt"],
	QUESTION: ["fad", "question"],
	QUESTION_CIRCLE: ["fad", "question-circle"],
	EXCLAMATION: ["fad", "exclamation"],
	EYE_SLASH: ["fad", "eye-slash"],
	INFO: ["fad", "info"],
	PHONE: ["fad", "phone"],
	ENVELOPE: ["fad", "envelope"],
	CHEVRON_CIRCLE_RIGHT: ["fad", "chevron-circle-right"],
	CHEVRON_CIRCLE_LEFT: ["fad", "chevron-circle-left"],
	LANGUAGE: ["fad", "language"],
	PLUS: ["fad", "plus"],
	KEY: ["fad", "key"],
	TRASH: ["fad", "trash"],
	USER: ["fad", "user"],
	SHIP: ["fad", "ship"],
	ROUTE: ["fad", "route"],
	ANCHOR: ["fad", "anchor"],
	QR_CODE: ["fad", "qrcode"],
	CALENDAR: ["fad", "calendar"],
	CALENDAR_ALT: ["fad", "calendar-alt"],
	TRUCK: ["fad", "truck"],
	TRUCK_FAST: ["fad", "truck-fast"],
	BELL_ON: ["fad", "bell-on"],
	BELL_ON_SHAKE: ["fad", "bell-on-shake"],
	BELL: ["fad", "bell"],
	TOOLS: ["fad", "tools"],
	EXCLAMATION_TRIANGLE: ["fad", "exclamation-triangle"],
	DOWNLOAD: ["fad", "download"],
	FILE: ["fad", "file"],
	ID_CARD: ["fad", "id-card"],
	FILE_ALT: ["fad", "file-alt"],
	CERTIFICATE: ["fad", "certificate"],
	FILE_CERTIFICATE: ["fad", "file-certificate"],
	DIPLOMA: ["fad", "diploma"],
	SYNC: ["fad", "sync-alt"],
	PENCIL: ["fad", "pencil"],
	COMPASS: ["fad", "compass"],
	PLUS_CIRCLE: ["fad", "plus-circle"],
	ANGLE_DOUBLE_DOWN: ["fad", "angle-double-down"],
	ANGLE_DOUBLE_UP: ["fad", "angle-double-up"],
	RANDOM: ["fad", "random"],
	LINK: ["fad", "link"],
	UNLINK: ["fad", "unlink"],
	INFO_CIRCLE: ["fad", "info-circle"],
	BAN: ["fad", "ban"],
	GLOBE: ["fad", "globe"],
	LOCATION_ARROW: ["fad", "location-arrow"],
	WIND: ["fad", "wind"],
	WATER: ["fad", "water"],
	MAP_MARKED_ALT: ["fad", "map-marked-alt"],
	CHECK_CIRCLE: ["fad", "check-circle"],
	SORT_UP: ["fad", "sort-up"],
	SORT_DOWN: ["fad", "sort-down"],
	FILTER: ["fad", "filter"],
	FAUCET: ["fad", "faucet"],
	WIFI: ["fad", "wifi"],
	NETWORK_WIRED: ["fad", "network-wired"],
	TV_RETRO: ["fad", "tv-retro"],
	BOLT: ["fad", "bolt"],
	OUTLET: ["fad", "outlet"],
	TIMES_CIRCLE: ["fad", "times-circle"],
	EXCLAMATION_CIRCLE: ["fad", "exclamation-circle"],
	SPINNER: ["fad", "spinner"],
	HOURGLASS_START: ["fad", "hourglass-start"],
	HOURGLASS_HALF: ["fad", "hourglass-half"],
	HOURGLASS_END: ["fad", "hourglass-end"],
	WRENCH: ["fad", "wrench"],
	PARKING: ["fad", "parking"],
	SNOWBOARDING: ["fad", "snowboarding"],
	FORKLIFT: ["fad", "forklift"],
	GAS_PUMP: ["fad", "gas-pump"],
	SOAP: ["fad", "soap"],
	SHUTTLE_VAN: ["fad", "shuttle-van"],
	ENVELOPE_OPEN: ["fad", "envelope-open"],
	SPARKLES: ["fad", "sparkles"]
}

export default Icons