const getEmployeeInput = params => {
	// console.log(params.id)
	let servicesInput = [], name = null, title = null, email = null, isAdmin = false, status = null
	try {
		name = document.getElementById(`${params.id}-employee-name-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		title = document.getElementById(`${params.id}-employee-title-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		email = document.getElementById(`${params.id}-employee-email-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		isAdmin = document.getElementById(`${params.id}-account-access-input`).getAttribute("data-a") === "admin"
	} catch (e) {
		console.log(e)
	}
	try {
		status = document.getElementById(`active-button-admin-${params.id}`).getAttribute("data-active")
	} catch (e) {
		console.log(e)
	}
	// console.log("services", params.services)
	for (let i = 0; i < params.services.length; i++) {
		const category = params.services[i].id
		let canAssign = false, canAcceptReject = false, canView = false
		if (isAdmin) {
			canAssign = true
			canAcceptReject = true
			canView = true
		} else {
			// console.log("canAssign", `assign-${params.id}-employee-service-${category}-access`)
			canAssign = document.getElementById(`assign-${params.id}-employee-service-${category}-access`)
				.getAttribute("data-is-checked") === "true"
			canAcceptReject = document.getElementById(`accept-reject-${params.id}-employee-service-${category}-access`)
				.getAttribute("data-is-checked") === "true"
			canView = document.getElementById(`no-access-${params.id}-employee-service-${category}-access`)
				.getAttribute("data-is-checked") === "false"
		}
		servicesInput.push({id: category, canAssign: canAssign,	canAcceptReject: canAcceptReject, canView: canView})
	}
	return {name: name, email: email, isAdmin: isAdmin, title: title, services: servicesInput, status: status, id: params.id}
}

export default getEmployeeInput