import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileRequiredDocumentsAdd from "./MobileRequiredDocumentsAdd"
import MobileRequiredDocuments from "./MobileRequiredDocuments"
import Modal from "../../../globals/components/Modal"

const MobileRequiredDocumentsSection = props => {
	const [add, setAdd] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const onSuccess = () => {
		props.refresh().then()
		setAdd(false)
	}
	const content = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"}>
		<MobileRequiredDocuments documentRequirements={props.documentRequirements} refresh={props.refresh} canEdit={props.canEdit}/>
		{add ? <Modal body={<MobileRequiredDocumentsAdd onSuccess={onSuccess}/>} onClick={() => setAdd(false)}
			icon={ICONS.PLUS_CIRCLE} title={translate(DICTIONARY.ADD_REQUIRED_DOCUMENT.X)} padding={"0vh"}/> : <></>}
		{props.canEdit ? <MobileAddNewBanner text={translate(DICTIONARY.ADD_REQUIRED_DOCUMENT.X)} onClick={() => {
			setAdd(!add)
		}}/> : <></>}
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top}	text={translate(DICTIONARY.REQUIRED_DOCUMENTS.X)}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileRequiredDocumentsSection