import { isObject, typeOrNull } from '../../../functions/shared/local';

class Rig {
	/**
		*
		* @param {Rig} [rig = null]
		* @param {number} [rig.id]
		* @param {number} [rig.I]
		* @param {number} [rig.J]
		* @param {number} [rig.Is]
		* @param {number} [rig.Js]
		* @param {number} [rig.P]
		* @param {number} [rig.E]
		* @param {number} [rig.Py]
		* @param {number} [rig.Ey]
		* @param {number} [rig.ISP]
		* @param {number} [rig.SPL]
		* @param {number} [rig.STL]
	*/
	constructor (rig = null) {
		if (isObject(rig)) {
			this.id = typeOrNull(rig.id, "number")
			this.I = typeOrNull(rig.I, "number")
			this.J = typeOrNull(rig.J, "number")
			this.Is = typeOrNull(rig.Is || rig.Is_, "number")
			this.Js = typeOrNull(rig.Js, "number")
			this.P = typeOrNull(rig.P, "number")
			this.E = typeOrNull(rig.E, "number")
			this.Py = typeOrNull(rig.Py, "number")
			this.Ey = typeOrNull(rig.Ey, "number")
			this.ISP = typeOrNull(rig.ISP, "number")
			this.SPL = typeOrNull(rig.SPL, "number")
			this.STL = typeOrNull(rig.STL, "number")
		} else {
			this.id = null
			this.I = null
			this.J = null
			this.Is = null
			this.Js = null
			this.P = null
			this.E = null
			this.Py = null
			this.Ey = null
			this.ISP = null
			this.SPL = null
			this.STL = null
		}
	}
}

export default Rig;