import Emails from './Emails'
import Phones from './Phones'
import VHF from "./VHF"
import {typeOrNull} from '../../functions/shared/local'

class ContactInfo {
	/**
	 *
	 * @param {ContactInfo} [contactInfo = null]
	 * @param {number} [contactInfo.id]
	 * @param {Emails} [contactInfo.emails]
	 * @param {Phones} [contactInfo.phones]
	 * @param {VHF} [contactInfo.vhf]
	 */
	constructor(contactInfo = null) {
		try {
			this.id = typeOrNull(contactInfo.id, "number")
			this.emails = new Emails(contactInfo.emails)
			this.phones = new Phones(contactInfo.phones)
			this.vhf = new VHF(contactInfo.vhf)
		} catch (e) {
			this.id = null
			this.emails = new Emails()
			this.phones = new Phones()
			this.vhf = new VHF()
		}
	}

	get bestDestination() {
		return this.emails.primary.address && this.emails.primary.verified ? "PRIMARY_EMAIL" :
			this.phones.primary.nationalNumber && this.phones.primary.verified ? "PRIMARY_PHONE" :
				this.emails.secondary.address && this.emails.secondary.verified ? "SECONDARY_EMAIL" :
					this.phones.secondary.nationalNumber && this.phones.secondary.verified ? "SECONDARY_PHONE" : null
	}
}

export default ContactInfo;