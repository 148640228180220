import React from "react"
/**
 * Displays rotating loader
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const Loader = () => <div id={"loader"} className={"w3-display-middle"} style={{zIndex: "10000", display: "none"}}>
      <div className={"Loader w3-spin"}> </div>
    </div>
export default Loader