import React, {useMemo} from "react"
import {DICTIONARY, TIME_NAMES} from "../../../globals/constants/client/constants"
import {getTimeFormat, translate} from "../../../globals/functions/client/localFunctions"
// import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputDay from "./MobileInputDay"
import Week from "../../../globals/classes/shared/Week"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Week|null} props.week
 * @param {boolean} props.use24Hr
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputWeek = props => {
	const week_ = useMemo(() => new Week(props.week), [props.week])
	const use24Hr = props.use24Hr || getTimeFormat() !== "xm"
	return <div className={"w3-animate-opacity"}>
{/*
		<div style={{width: "100%", height: "3vh"}} className={"w3-display-container"}>
			<MobileInputSlider id={`time-format-${props.id}`} label={translate(DICTIONARY.TIME_FORMAT.X)}
				height={"3vh"} width={"8vh"} defaultChecked={false} className={"w3-display-middle"}
				onChange={() => setUse24Hr(!use24Hr)} padding={"0.25vh 0vh 0.25vh 2vh"} form={"form"}/>
		</div>
*/}
		<div>
			{TIME_NAMES.day.map(day => <MobileInputDay key={day} form={props.form} id={`${day}-${props.id}`}
				name={translate(DICTIONARY[day.toUpperCase()].X)} left={null} isHoliday={false}	use24Hr={use24Hr}
				day={week_[day]} date={translate(DICTIONARY[day.toUpperCase()].X)} className={props.className}/>)}
		</div>
	</div>
}

export default MobileInputWeek