import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {getCountryStates} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import {isObject} from "../../../globals/functions/shared/local"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"

/**
 *
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {Country} props.country
 * @param {Object} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLAttributes.className} props.className - className(s) of container's top level element
 * @param {string} props.autoComplete
 * @param {string} props.defaultValue
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {string} [props.label]
 * @param {boolean} props.showLabel
 * @param {function} props.onChange
 * @param {Icon} [props.left = {icon: " ", onClick: () => {}}]
 * @param {Icon} props.right
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputStateDropdown = props => {
	/* TODO: find translator for states */
	const [states, setStates] = useState([])
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange || (() => {})
	const country = props.country
	// console.log(country)
	let divisions = `${translate(DICTIONARY.STATE.X)} (${translate(DICTIONARY.OR_EQUIVALENT.X)})`
	try {
		if (country.divisions.length > 0) {divisions = country.divisions.map(division => translate(division)).join("/")}
	} catch (e) {}
	// console.log(divisions)
	const label = props.label ? props.label : divisions
	const selected = props.showLabel ? null : {value: -1, disabled: true, label: label}
	const className = props.className || "paralian-theme-level-1-flipped"
	useEffect(() => {
		if (isObject(country)) {
			if (country.code2) {
				getCountryStates(country.code2)
					.then(response => {
						if (response) {
							const list = response.payload
							let _list = []
							for (let i = 0; i < list.length; i++) {
								_list[i] = {
									label: list[i].name,
									value: i,
									name: list[i].name,
									code: list[i].code,
									id: list[i].code,
									country: list[i].country,
									countryCode: list[i].country}}
							setStates(_list)}}).catch(reason => console.log(reason))
			}}},[country, props.id])
	const defaultValue = props.defaultValue || "blank"
	const defaultIndex = defaultValue === "blank" ? states.length > 0 ? 0 : false : states.findIndex(item => item.id === defaultValue)
	const defaultItem = (defaultIndex !== false && defaultIndex !== -1) ? states[defaultIndex] : {label: "", value: "", id: "", countryCode: ""}
	// console.log(states)
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-state`} className={className} size={"1"}
		dataA={defaultItem.label} dataB={defaultItem.countryCode} dataId={defaultItem.id} dataIndex={defaultIndex}
		label={label} multiple={false} topRadius={props.topRadius} selected={selected}
		autoComplete={props.autoComplete ? "address-level1" : "off"}
		style={style} showLabel={props.showLabel} right={props.right} placeholder={label}
		name={"state"} form={props.form} autoFocus={false} required={false} list={states} left={left}
		bottomRadius={props.bottomRadius} defaultValue={defaultIndex} onChange={() => {
			const element = document.getElementById(`${props.id}-state-input`)
			if (element.value > -1) {
				element.setAttribute("data-id", states[element.value].id)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", states[element.value].label)
				element.setAttribute("data-b", states[element.value].countryCode)
				onChange(states[element.value])
			}
	}}/>
}

export default MobileInputStateDropdown