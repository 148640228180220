import React, {useEffect, useRef, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {PARALIAN_PRODUCT_NAME, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {stripePromise} from "../../../index"
import {Elements} from "@stripe/react-stripe-js"
import {translate} from "../../../globals/functions/client/localFunctions"
import {getParalianInvoices, getParalianProducts} from "../../../globals/functions/client/serverFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileParalianSubscription from "./MobileParalianSubscription"
import MobileParalianSubscriptionSignup from "./MobileParalianSubscriptionSignup"
import MobileSubscriptionSignupCard from "./MobileSubscriptionSignupCard"
import Modal from "../../../globals/components/Modal"
import Subscription from "../../../globals/classes/shared/payments/Subscription"
/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {function} props.refresh
 * @param {boolean} props.isOrganization
 * @return {JSX.Element}
 * @constructor
 */
const MobileParalianSubscriptions = props => {
	const [invoices, setInvoices] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [subscription, setSubscription] = useState(props.parentObject.subscription)
	const [paralianSubscription, setParalianSubscription] = useState(null)
	const [expanded, setExpanded] = useState(false)
	const [showPayment, setShowPayment] = useState(false)
	const payRef = useRef(null)
	const label = translate(DICTIONARY.PARALIAN_PRODUCTS.X)
	// console.log("paralianSubscription", paralianSubscription)
	useEffect(() => {
		getParalianProducts().then(response => {
			// console.log("response", response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setParalianSubscription(new Subscription(response.payload[0]))
			}
		})}, [])
	useEffect(() => {
		/* TODO: get a separate refresh button */
		getParalianInvoices(true, props.isOrganization).then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				// console.log(response)
				setInvoices(response.payload.map(v => {
					return {
						invoice: v.invoice,
						paid: v.paid,
						url: v.url,
						created: v.created,
						updated: v.updated
					}
				}))
			}
		})}, [])
	useEffect(() => {setSubscription(props.parentObject.subscription)}, [props.parentObject])
	const modal = showModal && invoices.length > 0 ?
		<Modal title={translate(DICTIONARY.PARALIAN_INVOICES.X)} padding={"0vh"} onClick={() => setShowModal(false)}
			body={invoices.map(v => <div key={v.invoice} className={"w3-ripple " + (v.paid ? "w3-pale-green" : "w3-pale-red")}
		 	style={{margin: "1vh", padding: "1vh"}} onClick={() => window.open(v.url, '_blank').focus()}>
				{new Date(v.created).toLocaleDateString()}
			</div>)}/> : <></>
	// console.log("subscription", paralianSubscriptions, "product", product)
	// const scrollToPayRef = () => showPayment ? document.getElementById("mobileOrganization").scrollTo(0, payRef.current.offsetTop - 200) : null
	const subscription_ = subscription.id ? <MobileParalianSubscription canDelete={props.canDelete}
		refresh={props.refresh} canEdit={props.canEdit} parentObject={props.parentObject}
		isOrganization={props.isOrganization}/> :
		<div id={"paralian-subscription"} style={{textAlign: "center", padding: "2vh"}}
			className={"w3-animate-opacity paralian-mobile-info-card"}>
			{translate(DICTIONARY.NO_ACTIVE_PARALIAN_PRODUCTS.X)}
		</div>
	const purchase = expanded && !props.isOrganization && !subscription.id ?
		<div style={{margin: "0vh 2vh"}}>
			<MobileSubscriptionSignupCard subscription={paralianSubscription} onClick={() => setShowPayment(!showPayment)}/>
		</div> : <></>
	const pay = showPayment && expanded ? <Elements stripe={stripePromise}>
		<MobileParalianSubscriptionSignup isOrganization={props.isOrganization} parentObject={props.parentObject}
			currency={"USD"} subscription={paralianSubscription} refresh={props.refresh}
			setShow={() => {setShowPayment(false) /*setAdd(true)*/}}/>
	</Elements> : <></>
	const invoicesButton = invoices.length > 0 ? <div className={"paralian-mobile-info-card w3-ripple"}
		onClick={() => setShowModal(true)}>
		{translate(DICTIONARY.PARALIAN_INVOICES.X)}
	</div> : <></>
	const content = expanded ? <div className={"w3-animate-top"} style={{fontSize: "2vh"}}>
		{subscription_}
		{invoicesButton}
	</div> : <></>
	return <>
		{modal}
		{props.hideBanner ? <></> :
			<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top} text={label}
				onClick={() => setExpanded(!expanded)}/>}
		{content}
		{purchase}
		<div ref={payRef}/>
		{pay}
	</>
}

export default MobileParalianSubscriptions