import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselEngineMNEdit from "./MobileVesselEngineMNEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileVesselEngineMN = props => {
	const [edit, setEdit] = useState(false)
	const [modelNumber, setModelNumber] = useState(props.engine.modelNumber)
	const content = edit ?
		<MobileVesselEngineMNEdit id={props.id} engine={props.engine} onSuccess={modelNumber => {
			props.refresh().then()
			setModelNumber(modelNumber)
			setEdit(false)
		}} /> :
		<div className={"w3-animate-opacity"}><b>{modelNumber}</b></div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.MODEL_NUMBER.X)]}/>
		{content}
	</div>
}

export default MobileVesselEngineMN