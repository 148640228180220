import React from "react"
import {PRICING_STRUCTURE_UNITS, UNIT_SYSTEM} from "../../../../globals/constants/shared/enumerators"
import {setVesselStorageFacilities} from "../../../../globals/functions/client/serverFunctions"
import submitToServer from "../../../../globals/functions/client/submitToServer"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileVesselStorageFacilitiesEditCard from "./MobileVesselStorageFacilitiesEditCard"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"
import Units from "../../../../globals/classes/client/Units"

const MobileVesselStorageFacilitiesEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const lengthUnits = props.units === UNIT_SYSTEM.SI ? "m" : "ft"
			const weightUnits = props.units === UNIT_SYSTEM.SI ? "kg" : "lb"
			const UNITS = new Units()
			const facilities = new IndexedArray({obj: props.facilities.obj})
			const keys = facilities.keys
			for (let i = 0; i < keys.length; i++) {
				facilities.obj[keys[i]].quantity =
					Number(document.getElementById(`${keys[i]}-quantity-whole-input`).value.replace(/[^\d]/gi,""))
				if (keys[i] === "BOAT_YARD" && lengthUnits === "ft") {
					facilities.obj[keys[i]].quantity = facilities.obj[keys[i]].quantity/10.763910417
				}
			}
			for (let i = 0; i < keys.length; i++) {
				if (facilities.obj[keys[i]].quantity > 0) {
					facilities.obj[keys[i]].weight =
						UNITS.convert(Number(document.getElementById(`${keys[i]}-weight-whole-input`).value.replace(/[^\d]/gi,"")),
							PRICING_STRUCTURE_UNITS.KILOGRAM, weightUnits)
					facilities.obj[keys[i]].length =
						UNITS.convert(Number(document.getElementById(`${keys[i]}-length-whole-input`).value.replace(/[^\d]/gi,"")),
							PRICING_STRUCTURE_UNITS.METER, lengthUnits)
					facilities.obj[keys[i]].width =
						UNITS.convert(Number(document.getElementById(`${keys[i]}-width-whole-input`).value.replace(/[^\d]/gi,"")),
							PRICING_STRUCTURE_UNITS.METER, lengthUnits)
					facilities.obj[keys[i]].draught =
						UNITS.convert(Number(document.getElementById(`${keys[i]}-draught-whole-input`).value.replace(/[^\d]/gi,"")),
							PRICING_STRUCTURE_UNITS.METER, lengthUnits)
					facilities.obj[keys[i]].height =
						UNITS.convert(Number(document.getElementById(`${keys[i]}-height-whole-input`).value.replace(/[^\d]/gi,"")),
							PRICING_STRUCTURE_UNITS.METER, lengthUnits)
				}
			}
			await submitToServer(event, facilities.values, setVesselStorageFacilities, props.onSuccess)
		} catch (e) {
			console.log(e)
		}
	}
	const content = props.facilities.values.map(facility => <MobileVesselStorageFacilitiesEditCard units={props.units}
		key={`${facility.type}-edit`} facility={facility} current={props.vesselStorageFacilities[facility.type]}/>)
	return <MobileInputForm id={"vessel-storage-facilities-edit"} content={content} className={" "}
		disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileVesselStorageFacilitiesEdit