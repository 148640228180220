import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileRigInnerForetriangleEdit from "./MobileRigInnerForetriangleEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileRigInnerForetriangle = props => {
	const [edit, setEdit] = useState(false)
	const [innerForetriangle, setInnerForetriangle] = useState({Is: props.rig.Is, Js: props.rig.Js})
	useEffect(() => {
		setInnerForetriangle({Is: props.rig.Is, Js: props.rig.Js})
	}, [props.rig.Is, props.rig.Js])
	const content = edit ?
		<MobileRigInnerForetriangleEdit id={props.id} rig={props.rig} units={props.units} onSuccess={(is, js) => {
			props.refresh().then()
			setInnerForetriangle({Is: is, Js: js})
			setEdit(false)}}/> : <>
			<MobileSubtitleAndValue units={props.units} subtitle={`Is (${translate(DICTIONARY.HEIGHT.X)})`}
				value={{number: innerForetriangle.Is, units: "m", digits: 3}}/>
			<MobileSubtitleAndValue units={props.units} subtitle={`Js (${translate(DICTIONARY.BASE.X)})`}
				value={{number: innerForetriangle.Js, units: "m", digits: 3}}/>
		</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.INNER_FORETRIANGLE.X)]}/>
		{content}
	</div>
}

export default MobileRigInnerForetriangle