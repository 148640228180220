import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {setRigSpinnaker} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDistance from "../MobileInputDistance"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileSubtitleAndContent from "../../MobileSubtitleAndContent"
import convert from "convert-units"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {Rig} props.rig
 * @param {string} props.units - SI or US
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileRigSpinnakerEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		let isp, spl, stl
		if (props.units !== "SI") {
			isp = convert(Number(document.getElementById("isp-whole-input").value)+
				Number(document.getElementById("isp-fraction-input").value)/12).from("ft").to("m")
			spl = convert(Number(document.getElementById("spl-whole-input").value)+
				Number(document.getElementById("spl-fraction-input").value)/12).from("ft").to("m")
			stl = convert(Number(document.getElementById("stl-whole-input").value)+
				Number(document.getElementById("stl-fraction-input").value)/12).from("ft").to("m")
		} else {
			isp = document.getElementById("isp-whole-input").value
			spl = document.getElementById("spl-whole-input").value
			stl = document.getElementById("stl-whole-input").value
		}
		const response = await setRigSpinnaker(props.id, Number(isp), Number(spl), Number(stl))
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {props.onSuccess(isp, spl, stl)}
	}
	const ispInput = <MobileInputDistance id={"isp"}
		label={`ISP (${translate(DICTIONARY.HALYARD.X)} - ${translate(DICTIONARY.ELEVATION.X)})`}
		form={`${props.rig.id}-rig-spinnaker-form`} units={props.units} value={props.rig.ISP} displayDigits={3}/>
	const splInput = <MobileInputDistance id={"spl"}
		label={`SPL (${translate(DICTIONARY.POLE.X)} - ${translate(DICTIONARY.LENGTH.X)})`}
		form={`${props.rig.id}-rig-spinnaker-form`} units={props.units} value={props.rig.SPL} displayDigits={3}/>
	const stlInput = <MobileInputDistance id={"stl"}
		label={`STL (${translate(DICTIONARY.TACK.X)} - ${translate(DICTIONARY.LENGTH.X)})`}
		form={`${props.rig.id}-rig-spinnaker-form`} units={props.units} value={props.rig.STL} displayDigits={3}/>
	const content = <>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"}
			subtitle={`ISP (${translate(DICTIONARY.HALYARD.X)} - ${translate(DICTIONARY.ELEVATION.X)})`} content={ispInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"}
			subtitle={`SPL (${translate(DICTIONARY.POLE.X)} - ${translate(DICTIONARY.LENGTH.X)})`} content={splInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"}
			subtitle={`STL (${translate(DICTIONARY.TACK.X)} - ${translate(DICTIONARY.LENGTH.X)})`} content={stlInput}/>
	</>
	return <MobileInputForm id={`${props.rig.id}-rig-spinnaker`} content={content} className={" "}
		disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileRigSpinnakerEdit