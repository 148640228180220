import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {subtractMultipleFromArray} from "../../../../globals/functions/shared/local"
import MobileSelectSimple from "./MobileSelectSimple"
/**
 *
 * @param {Object} props
 * @param {Object} props.allServices
 * @param {Servicio[]} props.allServices.asArray
 * @param {IndexedArray[]} props.allServices.asIndexedArrayArray
 * @param {Servicio[]} props.allServices.contained
 * @param {IndexedArray[]} props.allServices.children
 * @param {number[]} props.preSelected
 * @param {number[]} props.selected
 * @param {boolean} props.singleSelect
 * @param {function} props.setSelected
 * @returns {JSX.Element}
 * @constructor
 */
const MobileServicesTreeSimple = props => {
	const [hidden, setHidden] = useState([])
	const addSelected = v => props.setSelected(Array.from(new Set([...props.selected, ...v])))
	const subtractSelected = v => props.setSelected(subtractMultipleFromArray(v, props.selected))
	const replaceWithSelected = (a, b) => props.setSelected(Array.from(new Set([...subtractMultipleFromArray(a, props.selected), ...b])))
	const hide = v => setHidden(Array.from(new Set([...hidden, ...v])))
	const show = v => setHidden(subtractMultipleFromArray(v, hidden))
	const getChildren = parent => {
		let children = []
		const _children = [...parent.children.parents, ...parent.children.singles]
		for (let i = 0; i < _children.length; i++) {
			children.push({id: _children[i].id, isParent: _children[i].hasChildren})
			if (_children[i].hasChildren) {children = [...children, ...getChildren(_children[i])]}
		}
		return children
	}
	const content = props.allServices.asArray.map(item => {
		const children = props.allServices.children.byIndex(item.id).children
		return <MobileSelectSimple key={`leaf-${item.id}`} item={item} children={children} singleSelect={props.singleSelect}
			selected={props.selected} preSelected={props.preSelected} addSelected={addSelected} subtractSelected={subtractSelected}
			replaceWithSelected={replaceWithSelected} hidden={hidden} hide={hide} show={show}/>
	})
	content.push(<div key={"q-service-feedback"} style={{padding: "2vh 0vh", textAlign: "center"}}>
		<i>
			<div>{translate(DICTIONARY.DONT_SEE_SERVICES_QUERY.X)}</div>
			<div><a href={"mailto:support@paralian.io"}>
				{translate(DICTIONARY.SEND_US_AN_EMAIL.X)}
			</a>
			</div>
		</i>
	</div>)
	return content
}

export default MobileServicesTreeSimple