import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {
	getFromStorage,
	getMarinaIndex,
	replaceInStorage,
	translate
} from "../../../../globals/functions/client/localFunctions"
import MobileParalianMarinaSearch from "../../marinas/MobileParalianMarinaSearch"
import ModalSelect from "../../../shared/input/ModalSelect/ModalSelect"
import Modal from "../../../../globals/components/Modal"
import vesselImagePlaceholder from "../../../../images/ship-duotone.svg"
import Country from "../../../../globals/classes/shared/Country"
import State from "../../../../globals/classes/shared/State"

const MarinaSelect = props => {
	const [marinas, setMarinas] = useState(props.marinas)
	const [showAddNew, setShowAddNew] = useState(false)
	const session = getFromStorage("order", "session")
	// console.log(marinas)
	useEffect(() => {
		if (props.marinas.length > 0) {
			const id = session.marinas ? session.marinas[0].id : null
			const index_ = id ? props.marinas.findIndex(v => v.id === id) : getMarinaIndex()
			const index = index_ > -1 ? index_ : 0
			replaceInStorage("order", "marinas",
				[{id: props.marinas[index].id, label: props.marinas[index].name.display}], "session")
			// console.log(marina)
			if (props.ifNotCustomer) {
				const marina = props.marinas.find(y => y.id === props.marinas[index].id)
				if (marina.services.some(y => props.services.includes(y))) {
					props.setDisabled(false)
				} else {
					props.ifNotCustomer(marina.contactInfo.emails.primary.address)
					props.setDisabled(true)
				}
			}
		} else {
			props.setDisabled(true)
		}
		setMarinas(props.marinas)
	}, [props.marinas])
	const marinasList = marinas.map(v => {
		const text = []
		if (v.addresses.physical.city) {text.push(v.addresses.physical.city)}
		if (v.addresses.physical.state.name) {text.push(v.addresses.physical.state.name)}
		return {
			id: v.id,
			title: v.name.display,
			text: text,
			image: v.image
		}
	})
	const addNew = showAddNew ? <Modal id={`${props.id}-addNew`} title={translate(DICTIONARY.ADD_VESSEL.X)}
		icon={ICONS.SHIP} padding={"0vh"} body={<MobileParalianMarinaSearch id={"marina-search"} country={new Country()}
			state={new State()}	className={"paralian-theme-level-1"} onSuccess={() => {
				props.refresh()
				setShowAddNew(false)
	}}/>} onClick={() => setShowAddNew(false)}/> : <></>
	return <>
		{addNew}
		<fieldset style={{margin: "1vh 0vh"}}>
			<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.MARINA.X)}</legend>
			<ModalSelect imagePlaceholder={vesselImagePlaceholder} icon={ICONS.ANCHOR} id={"marinas"}
				title={translate(DICTIONARY.SELECT_MARINA.X)}	selected={session.marinas ? session.marinas.map(v => v.id) : []}
			 	list={marinasList} multiSelect={false} setShowAddNew={setShowAddNew} onApply={v => {
				replaceInStorage("order", "marinas",
					v.map(x => {
						const item_ = marinasList.find(y => y.id === x)
						return {id: item_.id, label: item_.title}
					}), "session")
				if (props.ifNotCustomer) {
					const marina = props.marinas.find(y => y.id === v[0])
					if (marina.services.some(y => props.services.includes(y))) {
						props.setDisabled(false)
					} else {
						props.ifNotCustomer(marina.contactInfo.emails.primary.address)
						props.setDisabled(true)
					}
				}
			}}/>
		</fieldset>
	</>
}

export default MarinaSelect