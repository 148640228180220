import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselEngineLabelEdit from "./MobileVesselEngineLabelEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileVesselEngineLabel = props => {
	const [edit, setEdit] = useState(false)
	const [label, setLabel] = useState(props.engine.label)
	const content = edit ?
		<MobileVesselEngineLabelEdit id={props.id} engine={props.engine} onSuccess={lbl => {
			props.refresh().then()
			setLabel(lbl)
			setEdit(false)
		}}/> :
		<span className={"w3-animate-opacity"} style={{fontSize: "3vh"}}>{label}</span>
	return 	<div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}	labels={[translate(DICTIONARY.LABEL.X)]}/>
		{content}
	</div>
}

export default MobileVesselEngineLabel