import React, {useState} from "react"
import {ICONS} from "../../../../globals/constants/client/constants"
import {activateStatusLoaderById, checkVerificationCode, replaceStatusLoaderById} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.label
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {string} props.verificationType - specifies what it is the code is verifying
 * @param {boolean} props.showLabel
 * @param {boolean} props.autoFocus
 * @param {boolean} props.required
 * @param {boolean} props.isOrganization
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {string} props.inputMode
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @param {function} props.onSuccess
 * @param {function} props.onFailure
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCode = props => {
	const [statusIcon, setStatusIcon] = useState([" ", " "])
	let rightOnClick = () => {}
	if (props.right) {
		if (props.right.onClick) {
			rightOnClick = props.right.onClick
		}
	}
	return <MobileInputGeneric type={"text"} id={props.id} name={props.name} label={props.label} showLabel={props.showLabel}
		className={props.className} style={props.style} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={{icon: statusIcon, onClick: rightOnClick}} form={props.form} autoComplete={"one-time-code"}
		required={props.required} defaultValue={null} placeholder={props.placeholder} inputMode={props.inputMode}
		maxLength={"8"} autoFocus={props.autoFocus} pattern={"([a-zA-Z0-9-!#$%&'*+\/=?^_`{|}~@\.]){8}"} disabled={props.disabled}
		onChange={async () => {
			try {
				const code = document.getElementById(`${props.id}-input`).value
				if (code.length === 8) {
					activateStatusLoaderById(`${props.id}-right`)
					const response = await checkVerificationCode(code,	props.verificationType, props.isOrganization)
					// console.log("mobileinputcode", response)
					replaceStatusLoaderById(`${props.id}-right`, response)
					if (response) {
						setStatusIcon(ICONS.CHECK)
						if (props.onSuccess) {props.onSuccess(response.payload)}
					} else {
						setStatusIcon(ICONS.BAN)
						if (props.onFailure) {props.onFailure()}
					}
				}
			} catch (e) {
				console.log(e)
			}
	}}/>
}

export default MobileInputCode