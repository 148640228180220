const defaultState = {
	/* proposed START */
	account: {
		type: "undefined"
	},
	request: true,
	showUpload: false,
	showLoginCard: false,
	device: {
		type: "undefined",
		loggedIn: "undefined",
		devicePixelRatio: window.devicePixelRatio,
		deviceAvailHeight: window.screen.availHeight,
		deviceAvailWidth: window.screen.availWidth,
		deviceHeight: window.screen.height,
		deviceWidth: window.screen.width,
		deviceAspectRatio: window.screen.availWidth/window.screen.availHeight,
		location: {
			latitude: 18.3478,
			longitude: -65.6351
		},
		language: window.navigator.language.slice(0, 2),
		onLIne: window.navigator.onLine,
		platform: window.navigator.platform,
		cookieEnabled: window.navigator.cookieEnabled,
		timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		locale: window.navigator.languages === undefined ? window.navigator.language : window.navigator.languages[0]
	},
	window: {
		current: {
			levelOne: "",
			levelTwo: "",
			levelThree: "",
			levelFour: "",
			levelFive: "",
			levelSix: ""
		},
		prior: {
			levelOne: "",
			levelTwo: "",
			levelThree: "",
			levelFour: "",
			levelFive: "",
			levelSix: ""
		}
	},
	messages: [],
	/* proposed END */
	/* TODO: move into component with useState */
	textForecast: {
		productText: null,
		headers: null
	},
	tools: {},
	form: {
		submitButtonActive: false,
	},
	time: "xm",
	units: "SI",
	language: window.navigator.language.slice(0, 2)
}

export const actions = {
	// REQUEST
	SET_REQUEST_TRUE: "SET_REQUEST_TRUE",
	SET_REQUEST_FALSE: "SET_REQUEST_TRUE",
	// SHOW_UPLOAD
	TOGGLE_SHOW_UPLOAD: "TOGGLE_SHOW_UPLOAD",
	TOGGLE_LOGIN_CARD: "TOGGLE_LOGIN_CARD",
	SHOW_LOGIN_CARD: "SHOW_LOGIN_CARD",
	HIDE_LOGIN_CARD: "HIDE_LOGIN_CARD",
	LOGIN_USERNAME_MATCHES: "LOGIN_USERNAME_MATCHES",
	LOGIN_USERNAME_NO_MATCH: "LOGIN_USERNAME_NO_MATCH",
	LOGIN_RESET_USERNAME: "LOGIN_RESET_USERNAME",
	LOGIN_PASSWORD_MATCHES: "LOGIN_PASSWORD_MATCHES",
	LOGIN_PASSWORD_NO_MATCH: "LOGIN_PASSWORD_NO_MATCH",
	// TOOLS
	NEXT_WAVE_MAP: "NEXT_WAVE_MAP",
	PREVIOUS_WAVE_MAP: "PREVIOUS_WAVE_MAP",
	STOP_WAVE_MAP: "STOP_WAVE_MAP",
	PAUSE_WAVE_MAP: "PAUSE_WAVE_MAP",
	PLAY_WAVE_MAP: "PLAY_WAVE_MAP",
	NEXT_WIND_MAP: "NEXT_WIND_MAP",
	PREVIOUS_WIND_MAP: "PREVIOUS_WIND_MAP",
	STOP_WIND_MAP: "STOP_WIND_MAP",
	PAUSE_WIND_MAP: "PAUSE_WIND_MAP",
	PLAY_WIND_MAP: "PLAY_WIND_MAP",
	UPDATE_TEXT_FORECAST: "UPDATE_TEXT_FORECAST",
	// MISC
	SET_LANGUAGE: "SET_LANGUAGE",
	SET_UNITS: "SET_UNITS",
	SET_TIME: "SET_TIME",
	// DEVICE
	SET_DEVICE_TYPE: "SET_DEVICE_TYPE",
	SET_DEVICE_LOGGED_IN: "SET_DEVICE_LOGGED_IN",
	SET_DEVICE_TYPE_AND_LOGGED_IN: "SET_DEVICE_TYPE_AND_LOGGED_IN",
	// WINDOW
	SET_CURRENT_WINDOW: "SET_CURRENT_WINDOW",
	SET_PRIOR_WINDOW: "SET_PRIOR_WINDOW",
	// ACCOUNT
	ADD_ACCOUNT: "ADD_ACCOUNT",
	SET_ACCOUNT_TYPE: "SET_ACCOUNT_TYPE",
	SET_ACCOUNT_USERNAME: "SET_ACCOUNT_USERNAME",
	SET_ACCOUNT_NAME: "SET_ACCOUNT_NAME",
	SET_ACCOUNT_PRIMARY_EMAIL: "SET_ACCOUNT_PRIMARY_EMAIL",
	SET_ACCOUNT_BACKUP_EMAIL: "SET_ACCOUNT_BACKUP_EMAIL",
	SET_ACCOUNT_PRIMARY_PHONE: "SET_ACCOUNT_PRIMARY_PHONE",
	SET_ACCOUNT_CONTACT_INFO: "SET_ACCOUNT_CONTACT_INFO",
	SET_ACCOUNT_QR_CODE: "SET_ACCOUNT_QR_CODE",
	SET_WINDOW_OBJECT: "SET_WINDOW_OBJECT",
	SET_ACCOUNT_IMAGE_PATH: "SET_ACCOUNT_IMAGE_PATH",
	SET_ACCOUNT_DATE_OF_BIRTH: "SET_ACCOUNT_DATE_OF_BIRTH",
	// DOCUMENTS
	DELETE_DOCUMENT: "DELETE_DOCUMENT",
	ADD_DOCUMENT: "ADD_DOCUMENT",
	// CURRENT INDICES
	SET_CURRENT_INDICES_ACCOUNT: "SET_CURRENT_INDICES_ACCOUNT",
	SET_CURRENT_INDICES_VESSEL: "SET_CURRENT_INDICES_VESSEL",
	SET_CURRENT_INDICES_MARINA: "SET_CURRENT_INDICES_MARINA",
	SET_CURRENT_INDICES_ORGANIZATION: "SET_CURRENT_INDICES_ORGANIZATION",
	SET_CURRENT_INDICES_ORDER: "SET_CURRENT_INDICES_ORDER",
	// ORDER
	ADD_ORDER: "ADD_ORDER",
	SET_ORDER_VESSEL_ORIGIN: "SET_ORDER_VESSEL_ORIGIN",
	SET_ORDER_VESSEL_DESTINATION: "SET_ORDER_VESSEL_DESTINATION",
	SET_ORDER_REQUESTER: "SET_ORDER_REQUESTER",
	SET_ORDER_VESSEL: "SET_ORDER_VESSEL",
	SET_ORDER_COMPLETE_BY_DATETIME: "SET_ORDER_COMPLETE_BY_DATETIME",
	// MESSAGE
	ADD_MESSAGE: "ADD_MESSAGE",
	DELETE_MESSAGE: "DELETE_MESSAGE",
	CLEAR_MESSAGES: "CLEAR_MESSAGES",
	// FORM
	FORM_RESET: "FORM_RESET",
	FORM_APPEND: "FORM_APPEND",
	FORM_EDIT: "FORM_EDIT",
	FORM_DELETE: "FORM_DELETE",
	// CARDS
	SET_LEFT_CARD: "SET_LEFT_CARD",
	ACTIVATE_LEFT_CARD: "ACTIVATE_LEFT_CARD"
};

export const reducer = (_state = defaultState, _action) => {
	const newState = {..._state}
	switch (_action.type) {
		// REQUEST
		case actions.SET_REQUEST_TRUE:
			newState.request = true
			return newState
		case actions.SET_REQUEST_FALSE:
			newState.request = true
			return newState
		// OTHERS
		/* TODO: move this into container with useState - START */
		case actions.ACTIVATE_LEFT_CARD:
			newState.showLeftCard = true
			return newState
		case actions.SET_LEFT_CARD:
			newState.leftCard = _action.values
			return newState
		case actions.UPDATE_TEXT_FORECAST:
			newState.textForecast.productText = _action.values.productText
			newState.textForecast.headers = _action.values.headers
			return newState
		case actions.PLAY_WIND_MAP:
			newState.tools.intervalId = _action.values.intervalId
			return newState
		case actions.STOP_WIND_MAP:
			newState.tools.windMapNumber = 1
			newState.tools.windMapPath = "https://graphical.weather.gov/images/puertorico/WindSpd1_puertorico.png"
			return newState
		case actions.STOP_WAVE_MAP:
			newState.tools.waveMapNumber = 1
			newState.tools.waveMapPath = "https://graphical.weather.gov/images/puertorico/WaveHeight1_puertorico.png"
			return newState
		case actions.PLAY_WAVE_MAP:
			newState.tools.intervalId = _action.values.intervalId
			return newState
		case actions.PREVIOUS_WAVE_MAP:
			let previousWaveNumber
			if (_state.tools.waveMapNumber === 1) {
				previousWaveNumber = 20
			} else {
				previousWaveNumber = _state.tools.waveMapNumber - 1
			}
			newState.tools.waveMapPath = `https://graphical.weather.gov/images/puertorico/WaveHeight${previousWaveNumber}_puertorico.png`
			newState.tools.waveMapNumber = previousWaveNumber
			return newState
		case actions.PREVIOUS_WIND_MAP:
			let previousWindNumber
			if (_state.tools.windMapNumber === 1) {
				previousWindNumber = 20
			} else {
				previousWindNumber = _state.tools.windMapNumber - 1
			}
			newState.tools.windMapPath = `https://graphical.weather.gov/images/puertorico/WindSpd${previousWindNumber}_puertorico.png`
			newState.tools.windMapNumber = previousWindNumber
			return newState
		case actions.NEXT_WAVE_MAP:
			let nextWaveNumber
			if (_state.tools.waveMapNumber === 20) {
				nextWaveNumber = 1
			} else {
				nextWaveNumber = _state.tools.waveMapNumber + 1
			}
			newState.tools.waveMapPath = `https://graphical.weather.gov/images/puertorico/WaveHeight${nextWaveNumber}_puertorico.png`
			newState.tools.waveMapNumber = nextWaveNumber
			return newState
		case actions.NEXT_WIND_MAP:
			let nextWindNumber
			if (_state.tools.windMapNumber === 20) {
				nextWindNumber = 1
			} else {
				nextWindNumber = _state.tools.windMapNumber + 1
			}
			newState.tools.windMapPath = `https://graphical.weather.gov/images/puertorico/WindSpd${nextWindNumber}_puertorico.png`
			newState.tools.windMapNumber = nextWindNumber
			return newState
		// SHOW_UPLOAD
		case actions.TOGGLE_SHOW_UPLOAD:
			newState.showUpload = !newState.showUpload
			return newState
		// SHOW_LOGIN_CARD
		case actions.TOGGLE_LOGIN_CARD:
			newState.showLoginCard = !newState.showLoginCard
			return newState
		case actions.SHOW_LOGIN_CARD:
			newState.showLoginCard = true
			return newState
		case actions.HIDE_LOGIN_CARD:
			newState.showLoginCard = false
			return newState
		// FORM
		case actions.FORM_RESET:
			newState.form = null
			return newState
		case actions.FORM_APPEND:
			newState.form = {...newState.form, ..._action.values}
			return newState
		case actions.FORM_EDIT:
			newState.form[_action.values.key] = _action.values.value
			return newState
		case actions.FORM_DELETE:
			delete newState.form[_action.values.key]
			return newState
		// DOCUMENTS
		// {document: {doc: doc, index: props.index, type: props.docType}, container: {name: props.container, index: containerIndex}}
		case actions.ADD_DOCUMENT:
			newState[_action.values.container.name][_action.values.container.index].documents[_action.values.document.type] =
				[
					...[newState[_action.values.container.name][_action.values.container.index].documents[_action.values.document.type]],
					_action.values.document.doc
				]
			return newState
		case actions.DELETE_DOCUMENT:
			newState[_action.values.container.name][_action.values.container.index].documents[_action.values.document.type] =
				[
					...newState[_action.values.container.name][_action.values.container.index].documents[_action.values.document.type].slice(0, _action.values.document.index),
					...newState[_action.values.container.name][_action.values.container.index].documents[_action.values.document.type].slice(_action.values.document.index)
				]
			return newState
		// WINDOW
		case actions.SET_PRIOR_WINDOW:
			newState.window.prior.levelOne = _action.values.window.levelOne
			newState.window.prior.levelTwo = _action.values.window.levelTwo
			newState.window.prior.levelThree = _action.values.window.levelThree
			newState.window.prior.levelFour = _action.values.window.levelFour
			newState.window.prior.levelFive = _action.values.window.levelFive
			newState.window.prior.levelSix = _action.values.window.levelSix
			return newState
		case actions.SET_CURRENT_WINDOW:
			newState.window.prior.levelOne = newState.window.current.levelOne
			newState.window.prior.levelTwo = newState.window.current.levelTwo
			newState.window.prior.levelThree = newState.window.current.levelThree
			newState.window.prior.levelFour = newState.window.current.levelFour
			newState.window.prior.levelFive = newState.window.current.levelFive
			newState.window.prior.levelSix = newState.window.current.levelSix
			newState.window.current.levelOne = _action.values.window.levelOne
			newState.window.current.levelTwo = _action.values.window.levelTwo
			newState.window.current.levelThree = _action.values.window.levelThree
			newState.window.current.levelFour = _action.values.window.levelFour
			newState.window.current.levelFive = _action.values.window.levelFive
			newState.window.current.levelSix = _action.values.window.levelSix
			newState.messages = []
			return newState
		// ACCOUNT
		case actions.SET_ACCOUNT_TYPE:
			newState.account.type = _action.values.type
			return newState
		case actions.ADD_ACCOUNT:
			/* TODO: replace that whole mess with a single object */
			newState.accounts = [...newState.accounts, {
				userName: _action.values.userName,
				imagePath: _action.values.imagePath,
				type: _action.values.type,
				numNotifications: _action.values.numNotifications,
				organization: {
					id: _action.values.organization.id,
					name: _action.values.organization.name,
					logoPath: _action.values.organization.logoPath
				},
			}]
			return newState
		case actions.SET_ACCOUNT_CONTACT_INFO:
			newState.accounts[0].email.primary.email = _action.values.email.primary.email
			newState.accounts[0].email.recovery.email = _action.values.email.recovery.email
			newState.accounts[0].phone.primary.nationalNumber = _action.values.phone.primary.nationalNumber
			newState.accounts[0].phone.primary.countryCode = _action.values.phone.primary.countryCode
			return newState
		case actions.SET_ACCOUNT_PRIMARY_EMAIL:
			newState.accounts[0].email.primary.email = _action.values.email.primary.email
			newState.accounts[0].email.primary.verified = _action.values.email.primary.verified
			return newState
		case actions.SET_ACCOUNT_BACKUP_EMAIL:
			newState.accounts[0].email.recovery.email = _action.values.email.recovery.email
			newState.accounts[0].email.recovery.verified = _action.values.email.recovery.verified
			return newState
		case actions.SET_ACCOUNT_PRIMARY_PHONE:
			newState.accounts[0].phone.primary.nationalNumber = _action.values.phone.primary.nationalNumber
			newState.accounts[0].phone.primary.countryCode = _action.values.phone.primary.countryCode
			newState.accounts[0].phone.primary.verified = _action.values.phone.primary.verified
			return newState
		case actions.SET_ACCOUNT_NAME:
			newState.accounts[0].name = _action.values.name
			return newState
		case actions.SET_ACCOUNT_USERNAME:
			newState.accounts[0].userName = _action.values.userName
			return newState
		case actions.SET_ACCOUNT_IMAGE_PATH:
			newState.accounts[0].imagePath = _action.values.imagePath
			newState.accounts[0].profileImageVerified = _action.values.profileImageVerified
			return newState
		case actions.SET_ACCOUNT_QR_CODE:
			newState.accounts[0].qrCodePath = _action.values.qrCodePath
			return newState
		case actions.SET_ACCOUNT_DATE_OF_BIRTH:
			newState.accounts[0].dateOfBirth = _action.values.dateOfBirth
			return newState
		// DEVICE
		case actions.SET_DEVICE_TYPE_AND_LOGGED_IN:
			newState.device.type = _action.values.type
			newState.device.loggedIn = _action.values.loggedIn
			return newState
		case actions.SET_DEVICE_TYPE:
			newState.device.type = _action.values.type
			return newState
		case actions.SET_DEVICE_LOGGED_IN:
			newState.device.loggedIn = _action.values.loggedIn
			return newState
		// MISC
		case actions.SET_LANGUAGE:
			newState.language = _action.values.language
			return newState
		case actions.SET_UNITS:
			newState.units = _action.values.units
			return newState
		case actions.SET_TIME:
			newState.time = _action.values.time
			return newState
		// CURRENT INDICES
		case actions.SET_CURRENT_INDICES_ACCOUNT:
			newState.currentIndices.account = _action.values.index
			return newState
		case actions.SET_CURRENT_INDICES_VESSEL:
			newState.currentIndices.vessel = _action.values.index
			return newState
		case actions.SET_CURRENT_INDICES_MARINA:
			newState.currentIndices.organization = _action.values.index
			return newState
		case actions.SET_CURRENT_INDICES_ORGANIZATION:
			newState.currentIndices.marina = _action.values.index
			return newState
		case actions.SET_CURRENT_INDICES_ORDER:
			newState.currentIndices.order = _action.values.index
			return newState
		// MESSAGE
		case actions.ADD_MESSAGE:
			if (_state.messages) {
				// if a message array already exists
				const messageIndex = _state.messages.findIndex(message => message.id === _action.values.id)
				if (messageIndex !== -1) {
					// if a message exists with its id, delete this message first
					newState.messages.splice(messageIndex, 1)
				}
				// insert the new message into the messages array for display
				newState.messages = [..._state.messages, {
					id: _action.values.id,
					color: _action.values.color,
					title: _action.values.title,
					text: _action.values.text,
					icon: _action.values.icon,
					buttons: _action.values.buttons
				}]
			} else {
				// create a new message array with the new message for display
				newState.messages = [{
					id: _action.values.id,
					color: _action.values.color,
					title: _action.values.title,
					text: _action.values.text,
					icon: _action.values.icon,
					buttons: _action.values.buttons
				}]
			}
			return newState
		case actions.DELETE_MESSAGE:
			if (_state.messages) {
				if (_state.messages.length > 0) {
					const messageIndex = newState.messages.findIndex(message => message.id === _action.values.id)
					if (messageIndex !== -1) {newState.messages.splice(messageIndex, 1)}
				}
			}
			return newState
		case actions.CLEAR_MESSAGES:
			if (newState.messages.length > 0) {newState.messages = []}
			return newState
		// ORDER
		case actions.ADD_ORDER:
			newState.orders = [...newState.orders, _action.values.order]
			newState.currentIndices.order = newState.orders.length - 1
			return newState
		case actions.SET_ORDER_VESSEL_ORIGIN:
			newState.orders[_action.values.index].vesselOrigin.facility = _action.values.facility
			newState.orders[_action.values.index].vesselOrigin.unit = _action.values.unit
			newState.orders[_action.values.index].vesselOrigin.latitude = _action.values.latitude
			newState.orders[_action.values.index].vesselOrigin.longitude = _action.values.longitude
			newState.orders[_action.values.index].vesselOrigin.image = _action.values.image
			newState.orders[_action.values.index].vesselOrigin.id= _action.values.id
			newState.orders[_action.values.index].vesselOrigin.alert = {
				type: _action.values.alert.type,
				name: _action.values.alert.name,
				color: _action.values.alert.color,
				message: _action.values.alert.message,
				icon: _action.values.alert.icon
			}
			return newState
		case actions.SET_ORDER_VESSEL_DESTINATION:
			newState.orders[_action.values.index].vesselDestination.facility = _action.values.facility
			newState.orders[_action.values.index].vesselDestination.unit = _action.values.unit
			newState.orders[_action.values.index].vesselDestination.latitude = _action.values.latitude
			newState.orders[_action.values.index].vesselDestination.longitude = _action.values.longitude
			newState.orders[_action.values.index].vesselDestination.image = _action.values.image
			newState.orders[_action.values.index].vesselDestination.id= _action.values.id
			newState.orders[_action.values.index].vesselDestination.alert = {
				type: _action.values.alert.type,
				name: _action.values.alert.name,
				color: _action.values.alert.color,
				message: _action.values.alert.message,
				icon: _action.values.alert.icon
			}
			return newState
		case actions.SET_ORDER_REQUESTER:
			newState.requester.name = _action.values.name
			newState.requester.phone = _action.values.phone
			newState.requester.email = _action.values.email
			newState.requester.image = _action.values.image
			newState.requester.id= _action.values.id
			newState.requester.alert = {
				type: _action.values.alert.type,
				name: _action.values.alert.name,
				color: _action.values.alert.color,
				message: _action.values.alert.message,
				icon: _action.values.alert.icon
			}
			// console.log(newState)
			return newState
		case actions.SET_ORDER_VESSEL:
			newState.vessel.name = _action.values.name
			newState.vessel.registration = _action.values.registration
			newState.vessel.brand = _action.values.brand
			newState.vessel.model = _action.values.model
			newState.vessel.image = _action.values.image
			newState.vessel.id = _action.values.id
			newState.vessel.alert = {
				type: _action.values.alert.type,
				name: _action.values.alert.name,
				color: _action.values.alert.color,
				message: _action.values.alert.message,
				icon: _action.values.alert.icon
			}
			newState.vessel.weight = _action.values.weight
			newState.vessel.weightUnits = _action.values.weightUnits
			newState.vessel.length = _action.values.length
			newState.vessel.lengthUnits = _action.values.lengthUnits
			newState.vessel.width = _action.values.width
			newState.vessel.widthUnits = _action.values.widthUnits
			// console.log(newState)
			return newState
		case actions.SET_ORDER_COMPLETE_BY_DATETIME:
			newState.completeByDateTime = _action.values.completeByDateTime
			// console.log(newState)
			return newState
		default:
			return(_state)
	}
}