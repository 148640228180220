import React, {useState} from "react"
import {DOCUMENT_CONTAINER, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {uploadDocument} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileDocumentContainerDropdown from "../../shared/input/dropdown/MobileDocumentContainerDropdown"
import MobileDocumentTypeDropdown from "../../shared/input/dropdown/MobileDocumentTypeDropdown"
import MobileCustomersProvidersDropdown from "../../shared/input/dropdown/MobileCustomersProvidersDropdown"
import MobileFileUploadCanvas from "../../shared/input/file/MobileFileUploadCanvas"
import appendFileUploadFormData from "../../shared/input/file/appendFileUploadFormData"

const MobileRequiredDocumentsAdd = props => {
	const [container, setContainer] = useState(DOCUMENT_CONTAINER.ORGANIZATIONS)
	const onSubmit = event => {
		event.preventDefault()
		try {
			const target =  {organization: -1}
			const formData = new FormData()
			appendFileUploadFormData("specifications", formData).then(updatedFormData => {
				updatedFormData.set("target", JSON.stringify(target))
				updatedFormData.set("label", document.getElementById(`required-documents-add-label-input`).value)
				updatedFormData.set("container", document.getElementById(`required-documents-add-container-input`).value)
				updatedFormData.set("capacity", document.getElementById(`required-documents-add-capacity-input`).value)
				updatedFormData.set("type", document.getElementById(`required-documents-add-type-input`).value)
				uploadDocument(updatedFormData, "requirements/upload").then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.onSuccess()
					}
				})
			})
		}
		catch(e) {
			console.log(e)
		}
	}
	const content = <>
		<MobileInputText id={"required-documents-add-label"} name={"label"} showLabel={true}
			label={translate(DICTIONARY.LABEL.X)} className={"paralian-theme-level-1"} style={{margin: "1vh 0vw"}}
			form={"required-documents-add-form"} autoComplete={"off"} required={true} topRadius={"0vh"} autoFocus={true}
			placeholder={translate(DICTIONARY.LABEL.X)} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX}
			pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE}/>
		<MobileDocumentContainerDropdown id={"required-documents-add"} showLabel={true}
			className={"paralian-theme-level-1"} onChange={c => setContainer(c)}/>
		<MobileCustomersProvidersDropdown id={"required-documents-add"} showLabel={true}
			className={"paralian-theme-level-1"}/>
		<MobileDocumentTypeDropdown id={"required-documents-add"} showLabel={true}
			className={"paralian-theme-level-1"} container={container}/>
		<MobileFileUploadCanvas id={"specifications"} label={translate(DICTIONARY.REQUIREMENTS.X)}
			className={"paralian-theme-level-1"}
			form={"required-documents-add-form"} showLabel={true}
				info={translate(DICTIONARY.PROVIDE_REQUIRED_DOCUMENT_SPEC_DOC.X)}/>
	</>
	return <MobileInputForm id={"required-documents-add-form"} content={content} encType={"multipart/form-data"}
		onSubmit={onSubmit}/>
}

export default MobileRequiredDocumentsAdd