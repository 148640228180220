import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {setRigInnerForetriangle} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDistance from "../MobileInputDistance"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileSubtitleAndContent from "../../MobileSubtitleAndContent"
import convert from "convert-units"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {Rig} props.rig
 * @param {string} props.units - SI or US
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileRigInnerForetriangleEdit = props => {
	const onSubmit = async event => {
		/* TODO: look at MobileVesselStorageFacilitiesEdit for more elegant solution */
		event.preventDefault()
		let is, js
		if (props.units !== "SI") {
			is = convert(Number(document.getElementById("is-whole-input").value)+
				Number(document.getElementById("is-fraction-input").value)/12).from("ft").to("m")
			js = convert(Number(document.getElementById("js-whole-input").value)+
				Number(document.getElementById("js-fraction-input").value)/12).from("ft").to("m")
		} else {
			is = document.getElementById("is-whole-input").value
			js = document.getElementById("js-whole-input").value
		}
		const response = await setRigInnerForetriangle(props.id, Number(is), Number(js))
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess(is, js)
		}
	}
	const isInput = <MobileInputDistance id={"is"} label={`Is (${translate(DICTIONARY.HEIGHT.X)})`}
		form={`${props.rig.id}-rig-inner-foretriangle-form`} units={props.units} value={props.rig.Is} displayDigits={3}/>
	const jsInput = <MobileInputDistance id={"js"} label={`Js (${translate(DICTIONARY.BASE.X)})`}
		form={`${props.rig.id}-rig-inner-foretriangle-form`} units={props.units} value={props.rig.Js} displayDigits={3}/>
	const content = <>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={`Is (${translate(DICTIONARY.HEIGHT.X)})`}
			content={isInput}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={`Js (${translate(DICTIONARY.BASE.X)})`}
			content={jsInput}/>
	</>
	return <MobileInputForm id={`${props.rig.id}-rig-inner-foretriangle`} content={content} className={" "}
		disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileRigInnerForetriangleEdit