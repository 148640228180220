import React, {useEffect, useState} from "react"
import {meanZeroHeightOneBellCurve, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"

/**
 * Displays a scrolling list of icons which change size and opacity with distance from center of div
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} props
 * @param {Object[]} props.items - Array of objects describing the items which will be scrolled
 * @param {string} props.items[].id
 * @param {Object} props.items[].onClick
 * @param {string[]} props.items[].icon - array describing FontAwesome icon
 * @param {string} props.items[].text
 * @param {boolean} props.items[].active - true if option is active
 * @param {Object} props.items[].initialConditions
 * @param {number} props.items[].initialConditions.opacity
 * @param {string} props.items[].initialConditions.textWidth
 * @param {string} props.items[].initialConditions.textFontSize
 * @param {string} props.items[].initialConditions.iconFontSize
 * @param {string} props.selection - single/multiple
 * @param {boolean} props.displayId - If true, will display the item's id above the item's text
 * @returns {JSX.Element}
 * @constructor
 */
const MobileZoomScrollIcons = props => {
	const [scrollStarted, setScrollStarted] = useState(false)
	const [_opacity, _setOpacity] = useState([])
	useEffect(() => {
		_setOpacity(props.items.map(v => v.initialConditions.opacity))
	}, [props.items])
	// console.log(_opacity)
	useEffect(() => {
		if (props.scroll) {
			const element = document.getElementById("mainCenterScrollDiv")
			const height = element.offsetHeight
			const scroll = (props.scroll / 100) * height
			element.scrollTo(0, scroll)
		}
		if (props.setShowScroll) {
			window.setTimeout(() => {
				setScrollStarted(false)
				props.setShowScroll(true)
			}, 1000)
		}
	}, [props.scroll, props.setShowScroll])
	// console.log(scrollStarted)
	const createContent = (item, index) => {
		// console.log(index, _opacity[index])
		return <div id={item.id} key={item.id} style={{marginTop: "4vh", cursor: "pointer"}} className={"w3-ripple"}
			onClick={item.onClick}>
			<MobileFontAwesome id={`${item.id}-icon`} style={{fontSize: item.initialConditions.iconFontSize,
				opacity: _opacity[index], padding: "1vh"}} icon={item.icon}
				label={translate(item.text)}/>
			<div id={`${item.id}-text`} style={{fontSize: item.initialConditions.textFontSize,
				opacity: _opacity[index]}}>
				<div id={`${item.id}-text-width`} style={{display: "inline-block", width: item.initialConditions.textWidth}}>
					{translate(item.text)}
				</div>
			</div>
		</div>
	}
	const scrollFunction = items => {
		try {
			if (!scrollStarted && props.setShowScroll) {
				setScrollStarted(true)
				props.setShowScroll(false)
			}
			const parentElementRect = document.getElementById("mainCenterScrollDiv").getBoundingClientRect()
			const centerY = parentElementRect.top + parentElementRect.height/2
			let iconFontSize = [], textFontSize = [], opacity = [], textWidth = []
			for (let i = 0; i < items.length; i++) {
				const currentRect = document.getElementById(items[i].id).getBoundingClientRect()
				const currentCenterY = currentRect.top + currentRect.height/2
				const currentDistFromCenter = currentCenterY - centerY
				const calcProps = prop => 1 + Number(props.items[0].initialConditions[prop].replace(/[a-zA-Z]/g,"")) *
					meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + "vh"
				iconFontSize[i] = calcProps("iconFontSize")
				textFontSize[i] = calcProps("textFontSize")
				textWidth[i] = 20 + 60 * meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + "%"
				opacity[i] = meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + ""
			}
			const opacity_ = [..._opacity]
			for (let i = 0; i < items.length; i++) {
				document.getElementById(`${items[i].id}-icon`).style.fontSize = iconFontSize[i]
				document.getElementById(`${items[i].id}-text`).style.fontSize = textFontSize[i]
				// document.getElementById(`${items[i].id}-icon`).style.opacity = opacity[i]
				opacity_[i] = opacity[i]
				_setOpacity(opacity_)
				document.getElementById(`${items[i].id}-text`).style.opacity = opacity[i]
				document.getElementById(`${items[i].id}-text-width`).style.width = textWidth[i]
			}
		} catch (e) {
			console.log(e)
		}
	}
	const scrollContent = props.items.map((item, index) => createContent(item, index))
	return <div id={"mainCenterScrollDiv"} className={"w3-display-middle w3-animate-opacity w3-center paralian-scroll " +
		"paralian-hide-scroll-ms paralian-hide-scroll-chrome"} style={{width: "100%", height: props.height || "65vh",
		fontWeight: "bold"}} onScroll={() => {scrollFunction(props.items)}}>
		<div id={"inside-scroll-div"} style={{paddingTop: "20vh", paddingBottom: "20vh"}}>
			{scrollContent}
		</div>
	</div>
}

export default MobileZoomScrollIcons