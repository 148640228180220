import React, {useEffect, useState} from "react"
import {meanZeroHeightOneBellCurve} from "../../../../globals/functions/client/localFunctions"

/**
 *
 * @param {Object} props
 * @param {Object[]} props.items - Array of objects describing the items which will be scrolled
 * @param {string} props.items[].id
 * @param {boolean} props.displayId - If true, will display the item's id above the item's text
 * @param {Object} props.items[].onClick
 * @param {string} props.items[].image - path to image
 * @param {string} props.items[].text
 * @param {boolean} props.items[].active - true if option is active
 * @param {Object} props.items[].initialConditions
 * @param {number} props.items[].initialConditions.opacity
 * @param {string} props.items[].initialConditions.imageSize - Image is forced to have a 1:1 ratio. It is not stretched.
 * @param {string} props.items[].initialConditions.textFontSize
 * @param {string} props.items[].initialConditions.textWidth
 * @param {number} [props.scroll]
 * @param {string} props.selection - single/multiple
 * @returns {JSX.Element}
 * @constructor
 */
const MobileZoomScrollImages = props => {
  const [scrollStarted, setScrollStarted] = useState(false)
  useEffect(() => {
    if (props.scroll) {
      const element = document.getElementById("mainCenterScrollDiv")
      const height = element.offsetHeight
      const scroll = (props.scroll / 100) * height
      element.scrollTo(0, scroll)
    }
    if (props.setShowScroll) {
      window.setTimeout(() => {
        setScrollStarted(false)
        props.setShowScroll(true)
      }, 1000)
    }
  }, [props.scroll, props.setShowScroll])
  const displayId = props.displayId ? "inherit" : "none"
  const createContent = item => {
    const onClick = item.onClick ? item.onClick : () => {}
    const imageOnClick = !item.active ? () => {} : onClickFn => {
      const element = document.getElementById("mainCenterScrollDiv")
      const selected = element.getAttribute("data-id").split(",")
      const active = selected.findIndex(id => id === `${item.id}`)
      const current = document.getElementById(`${item.id}-image`)
      current.style.border = active === -1 ? "solid" : "none"
      if (props.selection === "single") {
        for (let i = 0; i < props.items.length; i++) {
          if (props.items[i].id === item.id) {continue}
          const other = document.getElementById(`${props.items[i].id}-image`)
          other.style.border = "none"
        }
        element.setAttribute("data-id", active === -1 ? item.id : "")
      } else {
        if (active !== -1) {
          selected.splice(active, 1)
        } else {
          selected.push(item.id)
        }
        element.setAttribute("data-id", selected.toString())
      }
      onClickFn()
    }
    const filter = item.active ? "none" : "grayscale(1) blur(0.3vh)"
    return <div id={item.id} key={item.id} style={{marginTop: "4vh", cursor: "pointer"}} className={"w3-ripple"}
         onClick={() => imageOnClick(onClick)}>
      {/* TODO: find a way to use MobileImage to maintain aspect ratio even when there's no image */}
      {/*<MobileImage image={props.image} size={"22.5vh"} alt={"profile"} style={{margin: "1.5vh auto 0vh auto"}}/>*/}
      <picture>
        <img id={`${item.id}-image`} src={item.image} alt={""}
          style={{minHeight: item.initialConditions.imageSize, maxHeight: item.initialConditions.imageSize, borderRadius: "50%", objectFit: "contain",
            backgroundColor: "white", opacity: item.initialConditions.opacity, filter: filter,
            minWidth: item.initialConditions.imageSize, maxWidth: item.initialConditions.imageSize}}/>
      </picture>
      <div>
        <div id={`${item.id}-text-width`} style={{width: item.initialConditions.textWidth,
          display: "inline-block", opacity: item.initialConditions.opacity}}>
          <i id={`${item.id}-id`} style={{display: displayId, fontSize: item.initialConditions.textFontSize}}>
            {item.id}
          </i>
          <div id={`${item.id}-text`} style={{fontSize: item.initialConditions.textFontSize}}>
            {item.text}
          </div>
        </div>
      </div>
    </div>
  }
  const scrollFunction = items => {
    try {
      if (!scrollStarted && props.setShowScroll) {
        setScrollStarted(true)
        props.setShowScroll(false)
      }
      const parentElementRect = document.getElementById("mainCenterScrollDiv").getBoundingClientRect()
      const centerY = parentElementRect.top + parentElementRect.height/2
      let imageHeight = [], textFontSize = [], textWidth = [], opacity = []
      for (let i = 0; i < items.length; i++) {
        const currentRect = document.getElementById(`${items[i].id}`).getBoundingClientRect()
        const currentCenterY = currentRect.top + currentRect.height/2
        const currentDistFromCenter = currentCenterY - centerY
        imageHeight[i] = 1 + Number(props.items[0].initialConditions.imageSize.replace(/[a-zA-Z]/g,"")) *
          meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + "vh"
        textFontSize[i] = 1 + Number(props.items[0].initialConditions.textFontSize.replace(/[a-zA-Z]/g,"")) *
          meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + "vh"
        opacity[i] = meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + ""
        textWidth[i] = 20 + 60 * meanZeroHeightOneBellCurve(currentDistFromCenter, centerY / 5) + "%"
      }
      for (let i = 0; i < items.length; i++) {
        document.getElementById(`${items[i].id}-image`).style.minHeight = imageHeight[i]
        document.getElementById(`${items[i].id}-image`).style.minWidth = imageHeight[i]
        document.getElementById(`${items[i].id}-image`).style.maxHeight = imageHeight[i]
        document.getElementById(`${items[i].id}-image`).style.maxWidth = imageHeight[i]
        document.getElementById(`${items[i].id}-text`).style.fontSize = textFontSize[i]
        document.getElementById(`${items[i].id}-id`).style.fontSize =
          `${Number(textFontSize[i].replace(/[a-zA-Z]/g, "")) * 0.5}vh`
        document.getElementById(`${items[i].id}-image`).style.opacity = opacity[i]
        document.getElementById(`${items[i].id}-text-width`).style.opacity = opacity[i]
        document.getElementById(`${items[i].id}-text-width`).style.width = textWidth[i]
      }
    } catch (e) {
      console.log(e)
    }
  }
  const scrollContent = props.items.map(item => createContent(item))
  return <div id={"mainCenterScrollDiv"} className={"w3-animate-opacity w3-center paralian-scroll " +
    "paralian-hide-scroll-ms paralian-hide-scroll-chrome"} style={{height: props.height || "100vh",
    padding: props.padding || "32vh 0"}} onScroll={() => {scrollFunction(props.items)}} data-id={''} data-a={''}>
    {scrollContent}
  </div>
/*  return <div id="mainCenterScrollDiv" className="w3-animate-opacity w3-center paralian-scroll
      paralian-hide-scroll-ms paralian-hide-scroll-chrome" style={{width: "100%", height: "100%",
    padding: "36% 0"}} onScroll={() => {scrollFunction(props.items)}} data-id={''} data-a={''}>
    {scrollContent}
  </div>*/
}

export default MobileZoomScrollImages