import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getAllCountries} from "../../../globals/functions/client/serverFunctions"
import {getLanguage, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import Country from "../../../globals/classes/shared/Country"
/**
 *
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.style} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLAttributes.className} props.className - className(s) of container's top level element
 * @param {string} [props.label]
 * @param {boolean} props.showLabel
 * @param {string} props.autoComplete
 * @param {string} props.defaultValue
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {function} props.onChange
 * @param {Object} [props.left = {icon: " ", onClick: () => {}}]
 * @param {string[]} props.left.icon - FontAwesome icon classname
 * @param {function} props.left.onClick
 * @param {Object} props.right
 * @param {string[]} props.right.icon - FontAwesome icon classname
 * @param {function} props.right.onClick
 * @param {function} props.setCountry
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCountryDropdown = props => {
	const [info, setInfo] = useState({countries: null, defaultIndex: null, defaultItem: null})
	useEffect(() => {
		getAllCountries()
			.then(response => {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					const list = response.payload
					const translator = new Intl.DisplayNames([getLanguage()], { type: "region" })
					list.sort((a,b) => {
						const aLabel = translator.of(a.code2) || a.name
						const bLabel = translator.of(b.code2) || b.name
						return aLabel > bLabel ? 1 : (bLabel > aLabel ? -1 : 0)
					})
					let _list = []
					for (let i = 0; i < list.length; i++) {
						_list[i] = {
							label: translator.of(list[i].code2) || list[i].name,
							value: i,
							id: list[i].code,
							parent: list[i].parent,
							name: list[i].name,
							code: list[i].code,
							code2: list[i].code2,
							currency: list[i].currency,
							phoneCountryCode: list[i].phoneCountryCode,
							divisions: list[i].divisions
						}
					}
					return _list
				}
			}).then(countries => {
				const defaultValue = props.defaultValue || props.showLabel ? props.defaultValue || "USA" : "blank"
				const defaultIndex = defaultValue === "blank" ? undefined :
					countries.findIndex(item => item.id === defaultValue)
				const defaultItem = (!!defaultIndex && defaultIndex !== -1) ? countries[defaultIndex] :
					{label: "", value: "", id: "", countryCode: "", parent: "", divisions: ""}
				if (props.setCountry) {props.setCountry(new Country(defaultItem))}
				setInfo({countries: countries, defaultIndex: defaultIndex, defaultItem: defaultItem})
		}).catch(reason => console.log(reason))
	},[props.defaultValue, props.showLabel, props.setCountry])
	const label = props.label || translate(DICTIONARY.COUNTRY.X)
	const selected = props.showLabel ? null : {value: -1, disabled: true, label: label}
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-country`} className={className} size={"1"} left={left}
		label={label} multiple={false} topRadius={props.topRadius} dataId={info.defaultItem ? info.defaultItem.id : ""}
		dataA={info.defaultItem ? info.defaultItem.label : ""} dataB={info.defaultItem ? info.defaultItem.parent: ""}
		dataC={info.defaultItem ? info.defaultItem.code2: ""} showLabel={props.showLabel} right={props.right}
	 	defaultValue={info.defaultIndex ? info.defaultIndex : ""} style={style} list={info.countries} placeholder={label}
		name={"country"} form={props.form} autoFocus={false} required={true} selected={selected}
		autoComplete={props.autoComplete ? "country" : "off"}
		bottomRadius={props.bottomRadius} dataIndex={info.defaultIndex ? info.defaultIndex : ""}
		onChange={() => {
			const element = document.getElementById(`${props.id}-country-input`)
			if (element.value > -1) {
				element.setAttribute("data-id", info.countries[element.value].id)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", info.countries[element.value].name)
				element.setAttribute("data-b", info.countries[element.value].parent)
				element.setAttribute("data-c", info.countries[element.value].code2)
				if (props.setCountry) {props.setCountry(new Country(info.countries[element.value]))}
			}
			onChange()
	}}/>
}

export default MobileInputCountryDropdown