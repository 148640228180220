import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import React, {useState} from "react"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE, VERIFICATION_TYPE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
// import {deleteMessage} from "../../../globals/functions/localFunctions"
import {sendPasswordResetRequest} from "../../../globals/functions/client/serverFunctions"
import MobileInputCode from "../../shared/input/text/MobileInputCode"
import MobileInputNewPassword from "../../shared/input/text/MobileInputNewPassword"

/* TODO: handle disabled accounts, resend code, send code to phone */
const MobileLoginHelp = () => {
	const currentState = store.getState()
	const window = currentState.window.current
	const levelOne = window.levelOne
	const [step, setStep] = useState(0)
	const [destination, setDestination] = useState(translate(DICTIONARY.PRIMARY_EMAIL.X))
	// console.log(step)
	const resetPassword = step === 1 ?
		<MobileInputNewPassword id={"password-help"} autoFocus={true} className={"paralian-theme-level-2"}
			setPasswordsMatch={async match => {
				if (match) {
					const password = document.getElementById("password-help-input").value
					const response = await sendPasswordResetRequest(password)
					// console.log(response)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						newMessage("password-reset-message", translate(DICTIONARY.PASSWORD_RESET_CODE.X),
							translate(DICTIONARY.PASSWORD_RESET_CODE_MESSAGE_PT1.X) + destination +
							translate(DICTIONARY.PASSWORD_RESET_CODE_MESSAGE_PT2.X),
							"w3-pale-blue", ICONS.INFO_CIRCLE)
						setDestination(translate(response.payload.destination))
						setStep(2)
					}
				}
			}}/> : <></>
	const insertCode = step === 2 ?
		<MobileInputCode id={"password-reset-code"} className={"paralian-theme-level-2"}
			label={translate(DICTIONARY.PASSWORD_RESET_CODE.X)} autoFocus={true} left={{icon: ICONS.SHIELD}}
			placeholder={translate(DICTIONARY.PASSWORD_RESET_CODE.X)} onFailure={() => {}}
			verificationType={VERIFICATION_TYPE.PASSWORD_RESET}	onSuccess={async () => {
				fadingMessage("password-reset-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X),
					translate(DICTIONARY.PASSWORD_RESET_SUCCESS.X))
				setTimeout(() => store.dispatch({type: actions.SET_CURRENT_WINDOW,
					values: {window: PAGE_STATES[levelOne].LOGIN.EXISTING_USER.PASSWORD.X}}), 3500)
		}}/> : <></>
  return <div className={"w3-display-middle"} style={{padding: "0vh 4vh", width: "100%"}}>
		<div style={{padding: "2vh"}} onClick={() => {
			newMessage("password-reset-message", translate(DICTIONARY.PASSWORD_RESET.X),
				translate(DICTIONARY.PASSWORD_RESET_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
			// sent to your primary email/recovery email/primary phone/recovery phone
			if (step === 0) {setStep(1)}
		}}>
			<b>{translate(DICTIONARY.FORGOT_PASSWORD.X)}</b>
		</div>
		<div className="w3-animate-opacity">
			{resetPassword}
			{insertCode}
		</div>
	</div>
}

export default MobileLoginHelp