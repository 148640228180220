import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {storeUsername} from "../../../../globals/functions/client/serverFunctions"
import MobileInputUsername from "../../../shared/input/text/MobileInputUsername"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import submitToServer from "../../../../globals/functions/client/submitToServer"

/**
 *
 * @param {Object} props
 * @param {Object} props.next
 * @param {string} props.message
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginNewUsername = props => {
	useEffect(() => {
		if (props.message) {
			newMessage("username-message", translate(DICTIONARY.USERNAME.X), props.message, "w3-pale-blue",
				ICONS.INFO_CIRCLE)
		}}, [props.message])
	const onSuccess = () => store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
	const onFail = response => {
		try {
			if (response.status === SERVER_RESPONSE_STATE.FAIL) {
				switch (response.payload.status) {
					case "username in use":
						newMessage("username", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "😕 " +
							translate(DICTIONARY.USERNAME_UNAVAILABLE_MESSAGE.X), "w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE)
						break
					default:
						console.log("unexpected response")
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	const onSubmit = async event => {
		const username = document.getElementById("username-input").value
		await submitToServer(event, username, storeUsername, onSuccess, onFail)
	}
	const content = <MobileInputUsername form={"form"} autoComplete={"off"} showLabel={false}	autoFocus={true}
			required={true} className={"paralian-theme-level-1-flipped"}/>

	return <MobileLoginFormScaffolding header={null} content={content} onSubmit={onSubmit}/>
}

export default MobileLoginNewUsername