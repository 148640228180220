import getBillingAddress from "./getBillingAddress"
import getPaymentMethodId from "./getPaymentMethodId"
import {isObject} from "../../../globals/functions/shared/local"
/**
 *
 * @param {string} name
 * @param {Address} billingAddress
 * @param {Object} card
 * @return {{payment_method: string|{card: Object, billing_details: {name: string, address: Object}}}}
 */
const getPaymentMethodData = (name, billingAddress = undefined, card = undefined) => {
	const formAddress = getBillingAddress()
	const savedAddress = isObject(billingAddress) ? {
		line1: billingAddress.line1,
		line2: billingAddress.line2,
		city: billingAddress.city,
		country: billingAddress.country.code2,
		postal_code: billingAddress.postCode,
		state: billingAddress.state.name
	} : null
	const address = formAddress || savedAddress
	let paymentMethod = getPaymentMethodId()
	if (paymentMethod === "-1") {
		paymentMethod = {card: card, billing_details: {name: name, address: address}}
	}
	return {payment_method: paymentMethod}
/*
	if (save) {data.payment_method.setup_future_usage = "off_session"}
	return data
	*/
}

export default getPaymentMethodData