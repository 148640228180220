import React from "react"

/**
 *
 * @param {Object} props
 * @param {Address} props.address
 * @returns {JSX.Element}
 * @constructor
 */
const MobileAddressFields = props => {
	const addressLine3 = props.address.line3 ?
		<div id={"address-addressLine3"} translate={"no"}>
			{props.address.line3}
		</div> : <></>
	return <div style={{fontSize: "3vh", width: "100%", padding: "2vh"}}>
		<div translate={"no"}>
			{props.address.line1}
		</div>
		<div translate={"no"}>
			{props.address.line2}
		</div>
		{addressLine3}
		<div translate={"no"}>
			{props.address.city}
		</div>
		<div>
			{props.address.state.name}
		</div>
		<div translate={"no"}>
			{props.address.postCode}
		</div>
		<div>
			{props.address.country.name}
		</div>
	</div>
}

export default MobileAddressFields