import { isObject, typeOrNull } from '../../functions/shared/local';

class Documents {
	/**
	 *
	 * @param {Documents} [documents = null]
	 * @param {Bond[]} [documents.bonds]
	 * @param {Certificate[]} [documents.certificates]
	 * @param {Insurance[]} [documents.insurancePolicies]
	 * @param {VesselTitle[]} [documents.vesselTitles]
	 * @param {VesselRegistration[]} [documents.vesselRegistrations]
	 * @param {VesselDocumentation[]} [documents.vesselDocumentations]
	 * @param {OrganizationRegistration[]} [documents.organizationRegistrations]
	 * @param {Contract[]} [documents.contracts]
	 * @param {PublicDocument[]} [documents.publicDocuments]
	 * @param {Upkeep[]} [documents.upkeep]
	 */
	constructor(documents = null) {
		if (isObject(documents)) {
			this.bonds = typeOrNull(documents.bonds, "array")
			this.certificates = typeOrNull(documents.certificates, "array")
			this.insurancePolicies = typeOrNull(documents.insurancePolicies, "array")
			this.vesselTitles = typeOrNull(documents.vesselTitles, "array")
			this.vesselRegistrations = typeOrNull(documents.vesselRegistrations, "array")
			this.vesselDocumentations = typeOrNull(documents.vesselDocumentations, "array")
			this.organizationRegistrations = typeOrNull(documents.organizationRegistrations, "array")
			this.contracts = typeOrNull(documents.contracts, "array")
			this.publicDocuments = typeOrNull(documents.publicDocuments, "array")
			this.upkeep = typeOrNull(documents.upkeep, "array")
		} else {
			this.bonds = []
			this.certificates = []
			this.insurancePolicies = []
			this.vesselTitles = []
			this.vesselRegistrations = []
			this.vesselDocumentations = []
			this.organizationRegistrations = []
			this.contracts = []
			this.publicDocuments = []
			this.upkeep = []
		}
	}
}

export default Documents;