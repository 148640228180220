import React from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {countMatchingBetweenArrays, subtractMultipleFromArray} from "../../../../globals/functions/shared/local"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
/**
 *
 * @param {Object} props
 * @param {Servicio} props.item
 * @param {number[]} props.selected
 * @param {number[]} props.preSelected
 * @param {Object[]} props.children
 * @param {number} props.children[].id
 * @param {boolean} props.children[].isParent
 * @param {function} props.addSelected
 * @param {function} props.subtractSelected
 * @param {boolean} props.singleSelect
 * @returns {JSX.Element}
 * @constructor
 */
const MobileSelectSimple = props => {
	if (props.hidden.includes(props.item.id)) {return <></>}
	const hasChildren = props.children.length > 0
	const allChildren = props.children.map(v => v.id)
	const singleChildren = props.children.filter(v => !v.isParent).map(v => v.id)
	const expanded = hasChildren && (countMatchingBetweenArrays(props.hidden, allChildren) < allChildren.length)
	const matchingChildren = hasChildren ? countMatchingBetweenArrays(props.selected, singleChildren) : 0
	const directlySelected = props.selected.includes(props.item.id)
	const indirectlySelected = matchingChildren > 0
	const isSelected = indirectlySelected || directlySelected
	const isSelectedFrozen = (props.preSelected.includes(props.item.id) || hasChildren) && props.singleSelect
	const check_ = hasChildren ?
		(matchingChildren === singleChildren.length ? ["fas", ICONS.CHECK_DOUBLE[1]] :
			(matchingChildren > 0 ? ICONS.CHECK_DOUBLE : ICONS.CHECK)) : ICONS.CHECK
	const check = isSelected ? check_ : ICONS.HORIZONTAL_RULE
	const background_ = hasChildren ?
		(matchingChildren === singleChildren.length ? "paralian-theme-level-1-flipped" :
			(matchingChildren > 0 ? "paralian-theme-level-4-flipped" : "paralian-theme-level-1-flipped")) :
		"paralian-theme-level-1-flipped"
	const background = isSelected ? background_ : (isSelectedFrozen ? "paralian-theme-level-2" : "paralian-theme-level-1")
	const expand = hasChildren ?
		<MobileFontAwesome icon={expanded ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN}
			style={{marginRight: "0vh", marginLeft: "auto"}} label={expanded ? translate(DICTIONARY.RETRACT.X) :
			translate(DICTIONARY.EXPAND.X)} onClick={() => {
				expanded ? props.hide(allChildren) : props.show(allChildren)
		}}/> : <></>
	const onClick = () => {
		if (!isSelectedFrozen) {
			if (props.singleSelect) {
				if (!hasChildren && !isSelected) {
					const currentSelection = subtractMultipleFromArray(props.preSelected, props.selected)
					if (currentSelection.length > 0) {
						props.replaceWithSelected(currentSelection, [props.item.id])
					} else {
						props.addSelected([props.item.id])
					}
				}
			}
			else {
				if (!hasChildren) {
					const group = [props.item.id, ...props.item.constraints.inclusive.flat()]
					console.log(group)
					isSelected ? props.subtractSelected(group) : props.addSelected(group)
				}
				else {
					isSelected ? props.subtractSelected(singleChildren) : props.addSelected(singleChildren)
				}
			}
		}
	}
	return <div key={props.item.id} className={`${background} w3-animate-opacity`} style={{display: "flex",
		width: "100%", padding: "1vh", border: "solid", borderWidth: "thin", borderColor: "rgba(251,240,214,0.5)"}}>
		<div style={{minWidth: `${props.item.level * 5}%`}}> </div>
		<div style={{minWidth: "4vh"}} onClick={onClick}>
			<MobileFontAwesome icon={check}/>
		</div>
		<div style={{width: "100%", textAlign: "left", overflowWrap: "break-word"}} onClick={onClick}>
			{translate(props.item.label.replace(/\W/g, '_').replace('___', '_').replace('__', '_').toUpperCase())}
		</div>
		<div style={{minWidth: "4vh"}}>
			{expand}
		</div>
	</div>
}

export default MobileSelectSimple