import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {PAGE_STATES} from "../../../globals/constants/client/constants"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {checkPassword} from "../../../globals/functions/client/serverFunctions"
import {getFromStorage,	setToStorage} from "../../../globals/functions/client/localFunctions"
import respondToPasswordCheck from "./respondToPasswordCheck"

/**
 * Used during initial login
 * Verifies login password against data in database and proceeds accordingly
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @constructor
 */
const confirmLoginPassword = async event => {
	event.preventDefault()
	try {
		const levelOne = store.getState().window.current.levelOne
		const password = document.getElementById("password-input").value
		const response = await checkPassword(password)
		// console.log(response)
		if (response.status !== SERVER_RESPONSE_STATE.BAD) {
			const form = getFromStorage("form", "session")
			const loginAttempt = form ? form.loginAttempt : 0
			const passwordMatch = response.status === SERVER_RESPONSE_STATE.SUCCESS
			if (passwordMatch) {
				setToStorage("accounts", response.payload.accounts, "session")
				form.loginAttempt = 0
			} else {
				form.loginAttempt = loginAttempt + 1
			}
			form.authentication = response.payload.status
			form.passwordMatch = passwordMatch
			form.passwordProvided = true
			setToStorage("form", form, "session")
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				store.dispatch({type: actions.SET_CURRENT_WINDOW,
					values: {window: PAGE_STATES[levelOne].LOGIN.EXISTING_USER.ROLE.X}})
			} else {
				respondToPasswordCheck(response.payload.status)
			}
		} else {
			// store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: PAGE_STATES[levelOne].LOGIN.EXISTING_USER.USERNAME.X}})
		}
	} catch (e) {
		console.log(e)
	}
}

export default confirmLoginPassword