import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
/**
 *
 * @param {Object} props
 * @param {Day} props.day
 * @param {string} props.name - day name from TIME_NAMES.day
 * @param {React.HTMLAttributes.style} props.style
 * @param {boolean} props.use24Hr
 * @returns {JSX.Element}
 * @constructor
 */
const MobileDayFields = props => {
	const open = props.use24Hr ? props.day.open.as24hrString : props.day.open.asXMString
	const close = props.use24Hr ? props.day.close.as24hrString : props.day.close.asXMString
	const style = props.style || {textAlign: "right"}
	return <div style={style}>
		<div style={{padding: "0.5vh 0vh"}}>{`${props.day.isOpen ? `${open} - ${close}` : translate(DICTIONARY.CLOSED.X)}`}</div>
	</div>

}

export default MobileDayFields