import TextInputFilter from './TextInputFilter';
/**
 *
 * @param {string} state
 * @returns {Promise<Object[]>}
 */
class PhoneCountryCode extends TextInputFilter {
	minLength = 1
	maxLength = 4
	regexp = /[^0-9+]/gi
}

export default PhoneCountryCode;