import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileRigForetriangleEdit from "./MobileRigForetriangleEdit"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileRigForetriangle = props => {
	const [edit, setEdit] = useState(false)
	const [foretriangle, setForetriangle] = useState({I: props.rig.I, J: props.rig.J})
	useEffect(() => {
		setForetriangle({I: props.rig.I, J: props.rig.J})
	}, [props.rig.I, props.rig.J])
	const content = edit ?
		<MobileRigForetriangleEdit id={props.id} rig={props.rig} units={props.units} onSuccess={(i, j) => {
			props.refresh().then()
			setForetriangle({I: i, J: j})
			setEdit(false)}}/> : <>
		<MobileSubtitleAndValue units={props.units} subtitle={`I (${translate(DICTIONARY.HEIGHT.X)})`}
			value={{number: foretriangle.I, units: "m", digits: 3}}/>
		<MobileSubtitleAndValue units={props.units} subtitle={`J (${translate(DICTIONARY.BASE.X)})`}
			value={{number: foretriangle.J, units: "m", digits: 3}}/>
	</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.FORETRIANGLE.X)]}/>
		{content}
	</div>
}

export default MobileRigForetriangle