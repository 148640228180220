import React from "react"
import {store} from "../../index"
import {actions} from "../../globals/constants/client/reduxData"
import {ACCOUNT_TYPE} from "../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../globals/constants/client/constants"
import {setToStorage,	translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../main/MobileFontAwesome"
import MobileLoginFormScaffolding from "./frames/MobileLoginFormScaffolding"

/**
 *
 * @param {Object} props
 * @param {Object} props.back - page state object of page visited before this
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginAccountType = props => {
	const currentPage = store.getState().window.current
	const content =
		<div className={"w3-center w3-display-middle w3-animate-opacity"}
			style={{fontSize: "2.5vh", width: "100%"}}>
			<div className={"paralian-theme-level-1-flipped w3-ripple paralian-button"}
				style={{margin: "4vh", borderRadius: "3vh", padding: "2vh"}}
				onClick={() => {
					setToStorage("type", ACCOUNT_TYPE.PERSONAL,"session")
					store.dispatch({type: actions.SET_CURRENT_WINDOW,
						values: {window: PAGE_STATES[currentPage.levelOne].LOGIN.NEW_USER.EMAIL.X
					}})}}>
				<MobileFontAwesome icon={ICONS.USER} style={{fontSize: "10vh"}}/>
				<div>{translate(DICTIONARY.OPEN_PERSONAL_ACCOUNT_OPTION.X)}</div>
			</div>
			<div className={"paralian-theme-level-1-flipped w3-ripple paralian-button"}
				style={{margin: "4vh", borderRadius: "3vh", padding: "2vh"}}
					onClick={() => {
						setToStorage("type", ACCOUNT_TYPE.BUSINESS_ADMIN,"session")
						store.dispatch({type: actions.SET_CURRENT_WINDOW,
							values: {window: PAGE_STATES[currentPage.levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICES_Q.X
					}})}}>
				<MobileFontAwesome icon={ICONS.USER_TIE} style={{fontSize: "10vh"}}/>
				<div>{translate(DICTIONARY.REGISTER_BUSINESS_OPTION.X)}</div>
			</div>
		</div>
	return <MobileLoginFormScaffolding header={null} content={content} back={props.back}/>
}

export default MobileLoginAccountType