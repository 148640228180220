import React from "react"
import {store} from "../../../index"
import Loader from "../../../globals/components/Loader"
import MessagesContainer from "../../../globals/components/MessagesContainer"
import MobileLoginCard from "../../login/expired/MobileLoginCard"
/**
 * Main display frames used for mobile application
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} props
 * @param {JSX.Element} props.header
 * @param {JSX.Element} props.content
 * @param {JSX.Element} props.footer
 * @return {JSX.Element}
 * @constructor
 */
const MobileParentContainer = props => {
/*	return <div id="mobileParentContainer" className="w3-display-container paralian-theme-level-1"
		style={{height: "100vh", width: "100vw", maxWidth: "62vh", margin: "auto", fontSize: "2.0vh"}}>
		<Loader/>
		<MessagesContainer/>
		{props.content}
	</div>*/
	// const mode = getMode() // new Mode({account: "ORGANIZATION", loggedIn: true})
	const state = store.getState()
	const loginCard = state.showLoginCard ? <MobileLoginCard/> : <></>
	// checkIfPushEnabled().then(enabled => {if (!enabled && mode.loggedIn) {subscribeToPushNotifications()}})
	/*
	<div id={"mainCenterBackground"} className={"background paralian-theme-level-1"} style={{height: "100vh", width: "100vw",
			backgroundSize: "cover", backgroundPosition: "center", zIndex: "-1", position: "fixed",
			backgroundImage: mode.device === DEVICE_TYPE.DESKTOP ?
				`linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.25) ), url(${IMAGES.BACKGROUND_DESKTOP})` : null
		}}/>
	<div id={"mobileParentContainer"} className={"MainCenter w3-display-container w3-center paralian-theme-level-1"}
		 style={{height: "100vh", width: "100vw", maxWidth: "100vh", margin: "auto", fontSize: "2.0vh",
			 boxShadow: "rgb(0 0 40) 0vh 0vh 2vh 2vh"}}>
	 */
	return <>
		{loginCard}
		<div id={"mainCenterBackground"} className={"background paralian-theme-level-1"} style={{height: "100vh",
			width: "100vw", backgroundSize: "cover", backgroundPosition: "center", zIndex: "-1", position: "fixed"}}/>
		<div id={"mobileParentContainer"} className={"MainCenter w3-display-container w3-center paralian-theme-level-1"}
			 style={{height: "100vh", width: "100vw", maxWidth: "100vh", margin: "auto", fontSize: "2.0vh"}}>
			<MessagesContainer/>
			<Loader/>
			{props.header}
			{props.content}
			{props.footer}
		</div>
	</>
}

export default MobileParentContainer