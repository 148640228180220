import React, {useState} from "react"
import MobilePaymentMethodsDropdown from "../../shared/input/dropdown/MobilePaymentMethodsDropdown"
import MobileInputCreditCard from "./MobileInputCreditCard"
/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {string} props.form
 * @param {function} props.setBillingDetails
 * @param {function} props.setCardComplete
 * @param {function} props.setError
 * @param {function} props.setBillingAddress
 * @param {function} props.setSavePaymentMethod
 * @param {boolean} props.isOrganization
 * @returns {JSX.Element}
 * @constructor
 */
const MobilePaymentMethodSelectNew = props => {
	const [newPaymentMethod, setNewPaymentMethod] = useState(false)
	const content = newPaymentMethod ? <MobileInputCreditCard setCardComplete={props.setCardComplete}
		className={props.className} setError={props.setError} setBillingDetails={props.setBillingDetails}
		setBillingAddress={props.setBillingAddress}	setSavePaymentMethod={props.setSavePaymentMethod}
		parentObject={props.parentObject} isOrganization={props.isOrganization}/> : <></>
	return <div>
		<div style={props.style || {padding: "2vh", fontSize: "2.5vh"}}>
			<MobilePaymentMethodsDropdown id={"payment-method"} form={props.form} showLabel={props.showLabel || false}
				defaultValue={newPaymentMethod ? -1 : props.defaultPaymentMethod}	setNewPaymentMethod={setNewPaymentMethod}
				parentObject={props.parentObject} addLabel={false} className={props.className}/>
		</div>
		{content}
	</div>
}

export default MobilePaymentMethodSelectNew