import DICTIONARY from '../../constants/shared/dictionary';
import IndexedArray from "../../classes/shared/IndexedArray"
/**
 *
 * @param entry
 * @param language
 * @return {*}
 */
const translate = (entry, language = null) => {
	try {
		let lang = language
		try {
			lang = window.navigator.language.slice(0, 2)
		} catch (e) {}
		return DICTIONARY[entry][lang]
	} catch (e) {
		return entry
	}
}
const isObject = object => {
	return object != null && typeof object === 'object'
}
/**
 *
 * @param {Object} O1
 * @param {Object} O2
 * @return {boolean}
 */
const compareObjects = (O1, O2) => {
	const keys1 = Object.keys(O1)
	const keys2 = Object.keys(O2)
	if (keys1.length !== keys2.length) {
		return false
	}
	for (const key of keys1) {
		const V1 = O1[key]
		const V2 = O2[key]
		const areObjects = isObject(V1) && isObject(V2)
		if ((areObjects && !compareObjects(V1, V2)) || (!areObjects && V1 !== V2)) {return false}
	}
	return true
}
/**
 *
 * @param {any} item
 * @param {Readonly<Object>} type
 * @return {any}
 */
const typeOrNull = (item, type) => {
	try {
		if (type === "S/N") {
			return typeof item === "string" || typeof item === "number" ? item : null
		}
		if (type === "number" && typeof item === "string") {
			const newItem = Number(item)
			return isNaN(newItem) ? null : newItem
		}
		if (type === "hexColor" && typeof item === "string") {
			return /^#[0-9A-F]{6}$/i.test(item) ? item : null
		}
		if (type === "date") {
			const date = typeof item
			return date === "string" || date === "number" || isObject(item) ? item : null
		}
		if (type === "array") {
			return Array.isArray(item) ? item : []
		}
		if (type === "function") {
			return typeof item === type ? item : () => {}
		}
		if (type === "object") {
			return isObject(item) ? item : null
		}
		if (type === "string" && typeof item === "number") {
			return `${item}`
		}
		return typeof item === type ? item : null
	} catch (e) {
		console.log(e)
		return null
	}
}
/**
 *
 * @param {string} string
 * @param {Object} frozen - frozen object
 * @return {string|null}
 */
const enumOrNull = (string, frozen) => {
	try {
		const _string = typeof string === "string" ? frozen[string.toUpperCase()] : null
		return typeOrNull(_string, "string")
	} catch (e) {
		console.log(e)
		return null
	}
}
const enumArray = (array, frozen) => {
	// process.logToConsole("enumArray called")
	try {
		if (!array) {return []}
		let inArray = []
		if (Array.isArray(array)) {
			// process.logToConsole("isArray")
			inArray = array
		} else if (typeof array === "string") {
			// process.logToConsole("isString")
			inArray = array.split(",")
		} else {
			// process.logToConsole("isNeither")
			return inArray
		}
		// process.logToConsole(inArray)
		if (inArray.length < 1) {return []}
		return inArray.map(item => enumOrNull(item, frozen)).filter(item => item != null)
	} catch (e) {
		console.log(e)
		return []
	}
}
/**
 * turns all items inside array into objects of the specified class in the constructor parameter
 * @param {Array} array
 * @param {function} constructor - item => new Class(item)
 * @return {*[]}
 */
const constructArray = (array, constructor) => {
	try {
		if (!array || !Array.isArray(array) || !constructor) {return []}
		return array.map(item => constructor(item))
	} catch (e) {
		console.log(e)
		return []
	}
}
const intersectArrays = (arr1, arr2) => arr1.filter(x => arr2.includes(x))
/**
 * difference of arrays
 * @param {*[]} arr1
 * @param {*[]} arr2
 * @returns {Object[]}
 */
const subtractArrays = (arr1, arr2) => arr1.filter(x => !arr2.includes(x))
/**
 *
 * @param {Object} item
 * @param {number} item.id
 * @param {Constraints} item.constraints
 * @param {number[]} selected
 * @param {boolean} [ignoreExcludes = false]
 * @returns {[[number[]],[number[]]]}
 */
/**
 * Adds value if not in array, removes value if in array
 * @param {*[]} arr
 * @param {*} val
 * @param {Object} [options]
 * @param {boolean} options.addOnly
 * @param {boolean} options.removeOnly
 * @param {*[]} options.exclude - items to exclude
 * @return {*[]}
 */
const toggleInArray = (arr, val, options) => {
	const valIsThere = arr.includes(val)
	// console.log("arr", arr)
	// console.log("val", val)
	// console.log("options", options)
	// console.log("valIsThere", valIsThere)
	if (valIsThere && !options.addOnly) {
		return arr.filter(v => v !== v)
	} else if (!valIsThere && !options.removeOnly) {
		const newArr = [...arr, val]
		if (options.exclude) {
			return subtractArrays(newArr, options.exclude.filter(v => v !== val))
		}
		return newArr
	}
	return arr
}
/**
 *
 * @param number
 * @return {string}
 */
const numberToOrdinal = number => {
	let result = " "
	switch (number) {
		case 1:
			result = translate(DICTIONARY.FIRST.X)
			break
		case 2:
			result = translate(DICTIONARY.SECOND.X)
			break
		case 3:
			result = translate(DICTIONARY.THIRD.X)
			break
		case -1:
			result = translate(DICTIONARY.LAST.X)
			break
		default:
			break
	}
	return result
}
/**
 * Recursively removes all null items from object
 * @param {Object} obj
 * @returns {Object}
 */
const cleanObject = obj => {
	return Object.fromEntries(
		Object.entries(obj)
		.filter(([_, v]) => v != null)
		.map(([k, v]) => [k, v === Object(v) ? cleanObject(v) : v])
	)
}
/**
 * symmetric differencing of array
 * @param {Object[]} arr
 * @param {string} uniqueKey - key name of value which should not repeat
 * @returns {Object[]}
 */
const removeDuplicateObjectsFromArray = (arr, uniqueKey = "id") => {
	return Array.from(new Set(arr.map(element => element[uniqueKey])))
	.map(uniqueValue => arr.find(element => element[uniqueKey] === uniqueValue))
}
/**
 * symmetric differencing of array by indexing
 * @param {Object[]} arr
 * @param {string} uniqueKey - key name of value which should not repeat
 * @returns {Object[]}
 */
const removeDuplicateObjectsFromArrayByIndexing = (arr, uniqueKey = "id") => {
	const indexed = new IndexedArray({array: arr, key: uniqueKey})
	return indexed.values
}
/**
 * intersection of arrays
 * @param {Object} item
 * @param {number[]} selected
 * @param {boolean} [ignoreExcludes = false]
 * @returns {Object[]}
 */
const evalConstraints = (item, selected, ignoreExcludes = false) => {
	const inclusives = item.constraints.inclusive
	const exclusives = item.constraints.exclusive
	// console.log("inclusives", inclusives)
	// console.log("exclusives", exclusives)
	// if there aren't any constraints, return true
	const inclusiveMet = inclusives.length === 0
	const exclusiveMet = exclusives.length === 0
	if (inclusiveMet && (exclusiveMet || ignoreExcludes)) {
		// console.log("no constraints to consider")
		return [[],[]]
	}
	// find out if all of the inclusive conditions are met
	const iUnmet = []
	for (let i = 0; i < inclusives.length; i++) {
		const met = intersectArrays(inclusives[i], selected).length > 0 || inclusiveMet
		if (!met) {
			iUnmet.push(inclusives[i])
		}
	}
	// console.log("iUnmet", iUnmet)
	// find out if all of the exclusive conditions are met
	const eUnmet = []
	if (!ignoreExcludes) {
		for (let i = 0; i < exclusives.length; i++) {
			const met = intersectArrays(exclusives[i], selected).length === 0 || exclusiveMet
			if (!met) {
				eUnmet.push(exclusives[i])
			}
		}
	}
	// console.log("eUnmet", eUnmet)
	return [iUnmet, eUnmet]
/*	// if some constraint is still unmet...
	let requirements = []
	// return all non-conflicted (conflicted: where same id is in both inclusives and exclusives)...
	// 	combinations of inclusives and exclusives
	// only go down to the first level as the component itself will act recursively
	//  exclusives only go down one level but may have multiple options in that level
	//  inclusives may go down unlimited levels and may have multiple options in each level
	if (inclusives.length === 0 && exclusives.length > 0) {
		// if only requirements are exclusives
		// console.log("only requirements are exclusives")
		for (let i = 0; i < exclusives.length; i++) {
			// remove values of those which are not selected
			const newRequirements = exclusives[i].filter(item => selected.includes(item))
			if (newRequirements.length > 0) {
				requirements.push([newRequirements, []])
			}
		}
		return requirements
	}
	if (inclusives.length > 0 && (exclusives.length === 0 || ignoreExcludes)) {
		// if only requirements are inclusives
		// console.log("only requirements are inclusives")
		for (let i = 0; i < inclusives.length; i++) {
			// remove values of those which are already selected
			const newRequirements = inclusives[i].filter(item => !selected.includes(item))
			if (newRequirements.length > 0) {
				requirements.push([newRequirements, []])
			}
		}
		return requirements
	}
	if (inclusives.length > 0) {
		// if there are both inclusive and exclusive requirements
		// console.log("there are both inclusive and exclusive requirements")
		// let index = 0
		for (let i = 0; i < inclusives.length; i++) {
			for (let j = 0; i < exclusives.length; i++) {
				const currentInclusives = inclusives[i]
				const currentExclusives = exclusives[j]
				const sizeOfFullSet = new Set([...currentInclusives, ...currentExclusives]).size
				if (sizeOfFullSet === currentInclusives.length + currentExclusives.length) {
					// only include option if there is no conflict between inclusives and exclusives
					// remove values of those which are already selected
					const newInclusiveRequirements = inclusives[i].filter(item => !selected.includes(item))
					// remove values of those which are not selected
					const newExclusiveRequirements = exclusives[j].filter(item => selected.includes(item))
					if (newInclusiveRequirements.length > 0 || newExclusiveRequirements.length > 0) {
						requirements.push([newInclusiveRequirements, newExclusiveRequirements])
					}
					// index++
				}
			}
		}
	}
	return requirements*/
}
/**
 *
 * @param {string[]|number[]} filters
 * @param {*[]} array
 * @param {string|null} [key = null]
 * @returns {*[]}
 */
const subtractMultipleFromArray = (filters, array, key = null) => {
	try {
		// turn filters into indexedArray
		const indexedFilters = new IndexedArray({array: filters.map(v => {return {k: v}}), key: "k"})
		const result = []
		// place those with no match in result array
		for (let i = 0; i < array.length; i++) {
			const value = key ? array[i][key] : array[i]
			if (!indexedFilters.byIndex(value)) {
				result.push(array[i])
			}
		}
		return result
	} catch (e) {
		console.error(e)
		return []
	}
}
/**
 *
 * @param {string[]|number[]} a
 * @param {string[]|number[]} b
 * @returns {number}
 */
const countMatchingBetweenArrays = (a, b) => {
	try {
		// turn filters into indexedArray
		const large = a.length >= b.length ? a : b
		const small = a.length >= b.length ? b : a
		const smallIndexed = new IndexedArray({array: small.map(v => {return {k: v}}), key: "k"})
		let result = 0
		for (let i = 0; i < large.length; i++) {
			if (!!smallIndexed.byIndex(large[i])) {
				result++
			}
		}
		return result
	} catch (e) {
		console.error(e)
		return 0
	}
}
/**
 *
 * @param {Date} [jsDate = new Date()]
 * @return {string}
 */
const jsToUCTmySQLdate = (jsDate = new Date()) => {
	return jsDate.toISOString().slice(0, 19).replace('T', ' ')
}
export {evalConstraints, constructArray, isObject, compareObjects, typeOrNull, enumOrNull, enumArray, numberToOrdinal,
	translate, cleanObject, removeDuplicateObjectsFromArray, removeDuplicateObjectsFromArrayByIndexing,
	subtractMultipleFromArray, countMatchingBetweenArrays, jsToUCTmySQLdate, intersectArrays, toggleInArray, subtractArrays}
