import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {getFromStorage, replaceInStorage, translate} from "../../../../globals/functions/client/localFunctions"
import RadioButton from "./RadioButton"
import MobileFontAwesome from "../../MobileFontAwesome"
import Modal from "../../../../globals/components/Modal"

const SurfaceState = props => {
	const [showModal, setShowModal] = useState({show: false, images: [], title: null})
	const [selected, setSelected] = useState(null)
	// console.log(selected, getFromStorage("order", "session").servicesExterior)
	useEffect(() => {
		const session = getFromStorage("order", "session")
		if (session.servicesExterior.some(v => v.id === 1099)) {
			setSelected("stained")
		} else if (session.servicesExterior.some(v => v.id === 1101)) {
			setSelected("scratched")
		} else if (session.servicesExterior.some(v => v.id === 1007)) {
			setSelected("dull")
		} else {
			replaceInStorage("order", "servicesExterior", [{id: 999999, quantity: 1}], "session")
			setSelected("shiny")
		}
	}, [])
	const onClick = id => {
		setSelected(id)
		if (props.onClick) {props.onClick(id)}
	}
	const modal = showModal.show ? <Modal onClick={() => setShowModal({show: false,images: [], title: null})}
		body={showModal.images.map((v, i) => <div key={`example-image-${i}`} style={{position: "relative"}}>
			<div style={{position: "absolute", top: "1vh", padding: "1vh", borderRadius: "1vh",
				right: "1vh", backgroundColor: "rgba(255,255,255,0.3)"}}>
				<div className={"w3-ripple"} style={{padding: "1vh", color: "white", cursor: "pointer"}}
					onClick={() => {
					try {
						const element = document.getElementById(`example-image-${i}`)
						const maxHeight = Number(element.style.maxHeight.replace(/\D/g,''))
						if (maxHeight > 40) {
							element.style.maxHeight = (maxHeight - 10) + "vh"
						}
					} catch (e) {
						console.log(e)
					}
				}}>{"-"}</div>
				<div className={"w3-ripple"} style={{padding: "1vh", color: "white", cursor: "pointer"}}
					onClick={() => {
					try {
						const element = document.getElementById(`example-image-${i}`)
						const maxHeight = Number(element.style.maxHeight.replace(/\D/g,''))
						if (maxHeight < 200) {
							element.style.maxHeight = (maxHeight + 10) + "vh"
						}
					} catch (e) {
						console.log(e)
					}
				}}>{"+"}</div>
			</div>
			<img id={`example-image-${i}`} src={v.path} alt={v.title} style={{maxHeight: "64vh", minWidth: "100%"}}/>
			<div className={"paralian-theme-level-1"} style={{position: "absolute", bottom: "1vh", padding: "1vh",
				right: "1vh", left: "1vh", opacity: "0.75", borderRadius: "1vh"}}>
				{v.title}
			</div>
		</div>)}
		icon={ICONS.IMAGE} title={showModal.title} padding={"0vh"} overflowX={"unset"}/> : <></>
	/* TODO: images */
	return <>
		{modal}
		<fieldset style={{margin: "1vh 0vh"}}>
			<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.CURRENT_SURFACE_STATE.X)}</legend>
			<RadioButton id={"stained"} title={<>
				{translate(DICTIONARY.EXTREMELY_DULL.X)}
				<MobileFontAwesome style={{paddingLeft: "1vh"}} icon={ICONS.IMAGE}
					onClick={() => {
						setShowModal({
							show: true,
							images: [{
								path: `${process.env.REACT_APP_PUBLIC_URL}/images/tiza-a.jpg`,
								title: translate(DICTIONARY.EXTREMELY_DULL_OXIDIZED_SURFACE.X)
							}],
							title: translate(DICTIONARY.EXTREMELY_DULL.X)
						})
					}}/>
			</>}
				subtitle={<div>
					<div>{translate(DICTIONARY.WET_SAND.X)}</div>
					<div>{"- " + translate(DICTIONARY.MOST_COMMON.X) + " -"}</div>
				</div>
				} onClick={onClick}
				selected={selected === "stained"} defaultChecked={true} name={"surfaceState"}/>
			<RadioButton id={"scratched"} title={translate(DICTIONARY.VERY_DULL.X)}
				subtitle={translate(DICTIONARY.COMPOUND.X)} onClick={onClick}
				selected={selected === "scratched"} name={"surfaceState"}/>
			<RadioButton id={"dull"} title={<>
				{translate(DICTIONARY.DULL_BUFFER_TRAILS.X)}
				<MobileFontAwesome style={{paddingLeft: "1vh"}} icon={ICONS.IMAGE}
					onClick={() => {
						setShowModal({
							show: true,
							images: [{
								path: `${process.env.REACT_APP_PUBLIC_URL}/images/hologram-a.jpg`,
								title: translate(DICTIONARY.BUFFER_TRAILS.X)
							},
								{
									path: `${process.env.REACT_APP_PUBLIC_URL}/images/hologram-b.jpg`,
									title: translate(DICTIONARY.BUFFER_TRAILS.X)
								}],
							title: translate(DICTIONARY.DULL_BUFFER_TRAILS.X)
						})
					}}/>
			</>}
				subtitle={"polish"} onClick={onClick}
				selected={selected === "dull"} name={"surfaceState"}/>
			<RadioButton id={"shiny"} title={translate(DICTIONARY.SHINY_SMOOTH.X)}
				subtitle={translate(DICTIONARY.ONE_STEP.X)} onClick={onClick}
				selected={selected === "shiny"} name={"surfaceState"}/>
		</fieldset>
	</>
}

export default SurfaceState