import {typeOrNull} from '../../../functions/shared/local'

class HullCapacity {
	/**
	 *
	 * @param {HullCapacity} [capacity = null]
	 * @param {number} [capacity.freshWater]
	 * @param {number} [capacity.fuel]
	 * @param {number} [capacity.holdingTank]
	 * @param {Object} [capacity.persons]
	 * @param {number} [capacity.persons.quantity]
	 * @param {number} [capacity.persons.weight]
	 * @param {number} [capacity.load]
	 * @param {number} [capacity.motor]
	 * @param {number} [capacity.sleeping]
	 * @param {number} [capacity.livewell]
	 */
	constructor (capacity = null) {
		try {
			this.freshWater = typeOrNull(capacity.freshWater, "number")
			this.fuel = typeOrNull(capacity.fuel, "number")
			this.holdingTank = typeOrNull(capacity.holdingTank, "number")
			this.persons = {
				quantity: typeOrNull(capacity.persons ? capacity.persons.quantity : null, "number"),
				weight: typeOrNull(capacity.persons ? capacity.persons.weight : null, "number")
			}
			this.load = typeOrNull(capacity.load, "number")
			this.motor = typeOrNull(capacity.motor, "number")
			this.sleeping = typeOrNull(capacity.sleeping, "number")
			this.livewell = typeOrNull(capacity.livewell, "number")
		} catch (e) {
			this.freshWater = null
			this.fuel = null
			this.holdingTank = null
			this.persons = {
				quantity: null,
				weight: null
			}
			this.load = null
			this.motor = null
			this.sleeping = null
			this.livewell = null
		}
	}
}

export default HullCapacity