import { typeOrNull, enumOrNull } from '../../functions/shared/local';
import { DOCUMENT_TYPE, DOCUMENT_CONTAINER } from '../../constants/shared/enumerators';
import Image from './Image';
/* TODO: privatize with properties_ with  when supported */

class Document {

	/**
	 *
	 * @param {Document} [document = null]
	 * @param {number} [document.id]
	 * @param {string} [document.name]
	 * @param {number|string|Date} [document.expiration]
	 * @param {string} [document.path]
	 * @param {Image} [document.image]
	 * @param {string} [document.type] - DOCUMENT_TYPE
	 * @param {string} [document.container] - DOCUMENT_CONTAINER
	 * @param {number} [document.owner] - account/organization/vessel id
	 * @param {string} [document.file] - filename
	 * @param {boolean} [document.isVerified]
	 * @param {boolean} [document.isParalianVerified]
	 * @param {boolean} [document.display]
	 * @param {boolean} [document.smsReminder]
	 * @param {boolean} [document.emailReminder]
	 * @param {number|string|Date} [document.timestamp]
	 */
	constructor(document = null) {
		try {
			this.id = typeOrNull(document.id, "number")
			this.name = typeOrNull(document.name, "string")
			this.expiration = typeOrNull(document.expiration, "date")
			this.path = typeOrNull(document.path, "string")
			this.image = new Image(document.image)
			this.type_ = enumOrNull(document.type || document.type_, DOCUMENT_TYPE)
			this.isVerified = typeOrNull(document.isVerified, "boolean")
			this.isParalianVerified = typeOrNull(document.isParalianVerified, "boolean")
			this.display = typeOrNull(document.display, "boolean")
			this.smsReminder = typeOrNull(document.smsReminder, "boolean")
			this.emailReminder = typeOrNull(document.emailReminder, "boolean")
			this.container_ = enumOrNull(document.container || document.container_, DOCUMENT_CONTAINER)
			this.owner = typeOrNull(document.owner, "number")
			this.file = typeOrNull(document.file, "string")
			this.timestamp = typeOrNull(document.timestamp, "date")
		} catch (e) {
			this.id = null
			this.name = null
			this.expiration = null
			this.path = null
			this.image = new Image()
			this.type_ = null
			this.isVerified = null
			this.isParalianVerified = null
			this.display = null
			this.smsReminder = null
			this.emailReminder = null
			this.container_ = null
			this.owner = null
			this.file = null
			this.timestamp = null
		}
	}

	get type(){
		return this.type_
	}

	set type(type) {
		this.type_ = enumOrNull(type, DOCUMENT_TYPE)
	}

	get container(){
		return this.container_
	}

	set container(container) {
		this.container_ = enumOrNull(container, DOCUMENT_CONTAINER)
	}

}

export default Document;