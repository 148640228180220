import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getParalianMarinas} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"

/**
 *
 * @param {object} props
 * @param {React.HTMLProps.id} props.id
 * @param {React.HTMLProps.form} props.form
 * @param {React.HTMLProps.style} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLProps.className} props.className - className(s) of container's top level element
 * @param {string} [props.label]
 * @param {boolean} props.showLabel
 * @param {string} props.autoComplete
 * @param {string} props.defaultValue
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {function} props.onChange
 * @param {Icon} [props.left = {icon: " ", onClick: () => {}}]
 * @param {Icon} props.right
 * @param {State} props.state
 * @param {Coordinates} props.coordinates
 * @param {number} props.distance - in meters
 * @param {function} props.setMarina
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputParalianMarinasDropdown = props => {
	const [info, setInfo] = useState({marinas: [], defaultIndex: null, defaultItem: null})
	useEffect(() => {
		if (props.state.code || (props.coordinates.latitude && props.coordinates.longitude && props.distance)) {
			getParalianMarinas(props.state.code, props.coordinates, props.distance)
				.then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						const list = response.payload
						let _list = []
						for (let i = 0; i < list.length; i++) {
							_list[i] = {
								label: list[i].name,
								value: i,
								id: list[i].id,
								parent: list[i].parent
							}
						}
						const defaultItem = _list.length > 0 ? _list[0] : {
							label: "",
							value: "",
							id: "",
							countryCode: "",
							parent: ""
						}
						setInfo({marinas: _list, defaultIndex: 0, defaultItem: defaultItem})
					} else {
						console.log("do nothing")
					}
				})
		}
	},[props.state.code, props.coordinates, props.distance])
	const label = props.label || translate(DICTIONARY.MARINA.X)
	const selected = props.showLabel ? null : {value: -1, disabled: true, label: label}
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-marina`} className={className} size={"1"} left={left}
		label={label} multiple={false} topRadius={props.topRadius} dataId={info.defaultItem ? info.defaultItem.id : ""}
		dataA={info.defaultItem ? info.defaultItem.label : ""} dataB={info.defaultItem ? info.defaultItem.parent: ""}
  	showLabel={props.showLabel} right={props.right}	defaultValue={info.defaultIndex ? info.defaultIndex : ""}
	 	style={style} list={info.marinas} placeholder={label}	name={"marina"} form={props.form} autoFocus={false}
		required={true} selected={selected}	bottomRadius={props.bottomRadius}
		dataIndex={info.defaultIndex ? info.defaultIndex : ""}
		onChange={() => {
			try {
				const element = document.getElementById(`${props.id}-marina-input`)
				if (element.value > -1) {
					element.setAttribute("data-id", info.marinas[element.value].id)
					element.setAttribute("data-index", element.value)
					element.setAttribute("data-a", info.marinas[element.value].name)
					element.setAttribute("data-b", info.marinas[element.value].parent)
					//props.setMarina(info.marinas[element.value])
				}
				onChange()
			} catch (e) {
				console.log(e)
			}
		}}/>
}

export default MobileInputParalianMarinasDropdown