import {store} from "../../../../index"
import {actions} from "../../../constants/client/reduxData"
import {setToStorage} from "../localFunctions"
import getMode from "./getMode"
/**
 * Sets loggedIn value of Mode on both Redux and localStorage
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {boolean} loggedIn
 */
const setLoggedIn = loggedIn => {
	try {
		const mode = getMode()
		if (mode.loggedIn !== loggedIn) {
			mode.loggedIn = loggedIn
			setToStorage("mode", mode)
		}
	} catch (e) {
		console.log(e)
	} finally {
		if (store.getState().device.loggedIn !== loggedIn) {
			store.dispatch({type: actions.SET_DEVICE_LOGGED_IN, values: {loggedIn: loggedIn}})
		}
	}
}

export default setLoggedIn