import {PRICING_MODEL, SUBSCRIPTION_INTERVAL} from "../../../constants/shared/enumerators"
import {enumOrNull, typeOrNull, constructArray} from "../../../functions/shared/local"
import UnitPrice from "./UnitPrice"

class Price {
	/**
	 *
	 * @param {Price} [price = null]
	 * @param {string} [price.stripeId]
	 * @param {string} [price.interval] - SUBSCRIPTION_INTERVAL
	 * @param {string} [price.model] - PRICING_MODEL
	 * @param {UnitPrice[]} [price.unitPrice]
	 */
	constructor (price = null) {
		try {
			this.stripeId = typeOrNull(price.stripeId, "string")
			this.interval = enumOrNull(price.interval, SUBSCRIPTION_INTERVAL)
			this.model = enumOrNull(price.model, PRICING_MODEL)
			this.unitPrice = constructArray(price.unitPrice, item => new UnitPrice(item))
		} catch (e) {
			this.stripeId = null
			this.interval = null
			this.model = null
			this.unitPrice = []
		}
	}
}

export default Price