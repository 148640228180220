import { ORDER_ACCESS } from '../../constants/shared/enumerators';
import { typeOrNull, enumArray } from '../../functions/shared/local';

class OrdersAccess {
	/**
	 *
	 * @param {OrdersAccess|Object|null} [access = null]
	 * @param {number} [access.id]
	 * @param {string[]} [access.access]
	 * @param {string|number} [access.dateGranted]
	 * @param {string|number} [access.startDate]
	 * @param {string|number} [access.endDate]
	 * @param {string|number} [access.provider]
	 */
	constructor(access = null) {
		try {
			this.id = typeOrNull(access.id, "number")
			this.access = enumArray(access.access, ORDER_ACCESS)
			this.dateGranted = typeOrNull(access.dateGranted, "date")
			this.startDate = typeOrNull(access.startDate, "date")
			this.endDate = typeOrNull(access.endDate, "date")
			this.provider = typeOrNull(access.provider, "number")
		} catch (e) {
			this.id = null
			this.access = []
			this.dateGranted = null
			this.startDate = null
			this.endDate = null
			this.provider = null
		}
	}
	/**
	 *
	 * @return {boolean}
	 */
	get any(){
		return this.access.length > 0 && (!this.endDate || (new Date(this.endDate)).getTime() > Date.now())
	}
}

export default OrdersAccess;