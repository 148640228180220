import {typeOrNull} from '../../functions/shared/local'
import PhoneNumber from './textInputFilters/PhoneNumber'
import PhoneCountryCode from './textInputFilters/PhoneCountryCode'
import ISO3 from './textInputFilters/ISO3'

class Phone {
	/**
	 *
	 * @param {Phone} [phone = null]
	 * @param {string|number} [phone.nationalNumber]
	 * @param {string|number} [phone.countryCode]
	 * @param {string} [phone.iso]
	 * @param {boolean} phone.verified
	 * @param {boolean} phone.login
	 */
	constructor (phone = null) {
		try {
			this.nationalNumber_ = new PhoneNumber(phone.nationalNumber || phone.nationalNumber_).filtered
			this.countryCode_ = new PhoneCountryCode(phone.countryCode || phone.countryCode_).filtered
			this.verified = typeOrNull(phone.verified, "boolean")
			this.login = typeOrNull(phone.login, "boolean")
			this.iso = new ISO3(phone.iso).filtered
		} catch (e) {
			this.nationalNumber_ = null
			this.countryCode_ = null
			this.verified = null
			this.login = null
			this.iso = null
		}
	}

	/**
	 * Get full number
	 * @returns {string|null}
	 */
	get fullNumber() {
		return (this.nationalNumber_ && this.countryCode_) ? `+${this.countryCode_} ${this.nationalNumber_}` : null
	}

	get nationalNumbersOnly() {
		const nn = new PhoneNumber(this.nationalNumber_)
		return (nn.conforms) ? `${nn.numbersOnly}` : null
	}

	get numbersOnly() {
		const nn = new PhoneNumber(this.nationalNumber_)
		return (nn.conforms && this.countryCode_) ? `${this.countryCode_}${nn.numbersOnly}` : null
	}

	get fullNumberNoSpace() {
		const nn = new PhoneNumber(this.nationalNumber_)
		return (nn.conforms && this.countryCode_) ? `+${this.countryCode_}${nn.numbersOnly}` : null
	}

	get countryCodeLabel() {
		return this.countryCode && this.iso ? `+ ${this.countryCode_} (${this.iso})` : null
	}

	get nationalNumber() {
		return this.nationalNumber_
	}

	get countryCode() {
		return this.countryCode_
	}

	get formatted() {
		try {
			let nn = this.nationalNumber_
			// console.log(this.nationalNumber_, this.countryCode_)
			if (this.countryCode_ === "1") {
				nn = `(${this.nationalNumber_.slice(0, 3)}) ${this.nationalNumber_.slice(3, 6)}-${this.nationalNumber_.slice(6)}`
			}
			return this.countryCode_ && this.nationalNumber_ ? `+${this.countryCode_} ${nn}` : null
		} catch (e) {
			return null
		}
	}

	set countryCode(countryCode) {
		this.countryCode_ = new PhoneCountryCode(countryCode).filtered
	}

	set nationalNumber(nationalNumber) {
		this.nationalNumber_ = new PhoneNumber(nationalNumber).filtered
	}
}

export default Phone;