import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {WEEKDAY} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {boolean} props.plural
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileDaysDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const onChange = props.onChange ? props.onChange : () => {}
	const list = [
		{label: translate(props.plural ? DICTIONARY.MONDAYS.X : DICTIONARY.MONDAY.X), value: WEEKDAY.MONDAY, dataId: 2, index: 1},
		{label: translate(props.plural ? DICTIONARY.TUESDAYS.X : DICTIONARY.TUESDAY.X), value: WEEKDAY.TUESDAY, dataId: 3, index: 2},
		{label: translate(props.plural ? DICTIONARY.WEDNESDAYS.X : DICTIONARY.WEDNESDAY.X), value: WEEKDAY.WEDNESDAY, dataId: 4, index: 3},
		{label: translate(props.plural ? DICTIONARY.THURSDAYS.X : DICTIONARY.THURSDAY.X), value: WEEKDAY.THURSDAY, dataId: 5, index: 4},
		{label: translate(props.plural ? DICTIONARY.FRIDAYS.X : DICTIONARY.FRIDAY.X), value: WEEKDAY.FRIDAY, dataId: 6, index: 5},
		{label: translate(props.plural ? DICTIONARY.SATURDAYS.X : DICTIONARY.SATURDAY.X), value: WEEKDAY.SATURDAY, dataId: 7, index: 6},
		{label: translate(props.plural ? DICTIONARY.SUNDAYS.X : DICTIONARY.SUNDAY.X), value: WEEKDAY.SUNDAY, dataId: 1, index: 0}
	]
	const defaultValue = props.defaultValue || WEEKDAY.MONDAY
	const defaultIndex = list.findIndex(item => item.value === defaultValue)
	const defaultItem = list[defaultIndex]
	return <MobileInputGeneric type={"dropdown"} id={props.id} className={className} size={"1"} showLabel={props.showLabel}
		label={translate(DICTIONARY.DAY.X)} multiple={false} topRadius={props.topRadius} dataId={defaultItem.dataId}
		name={"day"} form={props.form} autoFocus={false} required={true} list={list} left={props.left} dataIndex={defaultIndex}
		defaultValue={defaultValue} bottomRadius={props.bottomRadius} dataA={defaultValue} onChange={() => {
			const element = document.getElementById(`${props.id}-input`)
			const value = element.value
			const index = list.findIndex(item => item.value === value)
			element.setAttribute("data-index", `${index}`)
			element.setAttribute("data-id", list[index].dataId)
			element.setAttribute("data-a", value)
			onChange(value)
	}}/>
}

export default MobileDaysDropdown