import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {getTimeFormat, translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileWeek from "./MobileWeek"
import MobileHolidays from "./MobileHolidays"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileInputHolidaySave from "./MobileInputHolidaySave"
import Modal from "../../../globals/components/Modal"
/**
 *
 * @param {Object} props
 * @param {Schedule} props.schedule
 * @param {Holiday[]} props.holidays
 * @param {string} props.format
 * @param {string} props.className
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @param {function} props.onEdit
 * @param {boolean} props.canEdit
 * @param {boolean} props.canAdd
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileSchedule = props => {
	const [add, setAdd] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const use24Hr = getTimeFormat() !== "xm"
	const content = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"}>
		{props.schedule.id ?
			<MobileWeek id={"schedule-week"} use24Hr={use24Hr} week={props.schedule.week} form={"schedule-week"}
				className={props.className || "paralian-theme-level-2"} canEdit={props.canEdit}
				refresh={props.refresh}/> : <></>}
		<MobileHolidays id={"schedule-holidays"} holidays={props.holidays} format={props.format} form={"schedule-holiday"}
			className={props.className || "paralian-theme-level-1"} canEdit={props.canEdit}
			refresh={props.refresh}/>
		{add ? <Modal id={"add-holiday-modal"} padding={"0vh"} icon={ICONS.CALENDAR_STAR}
			title={translate(DICTIONARY.ADD_HOLIDAY.X)} onClick={() => setAdd(false)} body={
			<MobileInputHolidaySave id={"holiday-save"} form={"holiday-save"} name={null} use24Hr={use24Hr}
				className={props.className || "paralian-theme-level-1"} onSubmit={() => {
					props.refresh().then()
					setAdd(false)
			}}/>
		}/> : <></>}
		{props.canEdit ? <MobileAddNewBanner text={translate(DICTIONARY.ADD_HOLIDAY.X)} onClick={() => {
			setAdd(!add)
		}}/> : <></>}
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top}	text={translate(DICTIONARY.HOURS_OF_OPERATION.X)}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileSchedule