import React from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {JSX.Element} props.content
 * @param {string} props.buttonSize
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {HTMLFormElement.enctype} props.encType
 * @param {boolean} props.disabled
 * @param {boolean} props.disableAnimateOpacity
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputForm = props => {
	const className = props.className || "paralian-mobile-info-card"
	const animate = props.disableAnimateOpacity ? "" : "w3-animate-opacity"
	return <form id={`${props.id}-form`} className={`${animate} ${className}`}
		style={props.style} onSubmit={event => {
			try {
				event.preventDefault()
				if (event.target.id === `${props.id}-form`) {
					props.onSubmit(event)
				}
			} catch (e) {
				console.log(e)
			}
	}} encType={props.encType}>
		{props.content}
		{props.hideButton ? <></> : <div style={{textAlign: "center", marginTop: "2vh"}}>
			<button form={`${props.id}-form`} id={`${props.id}-form-submit`} type={"submit"} disabled={props.disabled}
				style={{display: "none"}}/>
			<MobileFontAwesome icon={ICONS.CHEVRON_CIRCLE_RIGHT} label={translate(DICTIONARY.SUBMIT.X)}
				style={{fontSize: props.buttonSize || "4vh", opacity: props.disabled ? 0.5 : null}}
				onClick={() => {
					document.getElementById(`${props.id}-form-submit`).click()
				}}/>
		</div>}
	</form>
}

export default MobileInputForm