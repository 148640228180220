import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {getUnits, translate} from "../../../globals/functions/client/localFunctions"
import Units from "../../../globals/classes/client/Units"
/**
 *
 * @param {Object} props
 * @param {Pricing} props.pricing
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @returns {JSX.Element}
 * @constructor
 */
const MobilePricingFields = props => {
	let content
	const UNITS = new Units()
	const unitSystem = getUnits()
	if (props.pricing.unit && props.pricing.price !== null) {
		const price = props.pricing.currency.asText(UNITS.convert(props.pricing.price, props.pricing.unit,
			UNITS.ofType(props.pricing.unit).SI.SHORT, true, unitSystem))
		const min = props.pricing.currency.asText(props.pricing.floor)
		content = <div className={"w3-animate-opacity"}>
			<div>
				<span style={{fontSize: "6vh"}}>{price}</span>
				{` ${translate(DICTIONARY.PER.X)} ${translate(UNITS.ofType(props.pricing.unit)[unitSystem].CAPS)}`}
			</div>
			<div>
				<span style={{fontSize: "6vh"}}>{min}</span>
				{" " + translate(DICTIONARY.MINIMUM.X)}
			</div>
		</div>
	} else {
		content = <code style={{overflowWrap: "break-word"}}>{props.pricing.formula}</code>
	}
	return <div style={{...props.style, textAlign: "center"}} className={props.className}>{content}</div>
}

export default MobilePricingFields