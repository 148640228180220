import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE, VERIFICATION_TYPE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {changePassword, loginRefresh} from "../../../globals/functions/client/serverFunctions"
import MobileInputCurrentPassword from "../../shared/input/text/MobileInputCurrentPassword"
import MobileInputNewPassword from "../../shared/input/text/MobileInputNewPassword"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputCode from "../../shared/input/text/MobileInputCode"

/* TODO: make it so that old password is not required while jwt-short is valid */
/* TODO: check that passwords match */

/**
 *
 * @param {Object} props
 * @param {ContactInfo} props.contactInfo
 * @param {React.HTMLProps.className} props.className
 * @param {string} props.username
 * @return {JSX.Element}
 * @constructor
 */
const MobileUpdatePassword = props => {
	const [subWindow, setSubWindow] = useState("current")
	const [passwordsMatch, setPasswordsMatch] = useState(false)
	const id = "update-password"
	const username = props.username
	const className = props.className || "paralian-theme-level-1-flipped"
	const onSubmit = event => {
		event.preventDefault()
		if (!passwordsMatch && subWindow === "new") {
			fadingMessage("new-password-no-match", translate(DICTIONARY.PASSWORD_MISMATCH.X), "",
				"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
			return
		}
		switch (subWindow) {
			case "current":
				loginRefresh(document.getElementById(`${id}-current-password-input`).value).then(response => {
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						setSubWindow("new")
					} else if (response.status === SERVER_RESPONSE_STATE.FAIL) {
						fadingMessage("incorrect-password", translate(DICTIONARY.INCORRECT_PASSWORD.X), "",
							"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
					}})
				break
			case "new":
				changePassword(document.getElementById(`${id}-new-password-input`).value).then(response => {
					// console.log(response)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						newMessage(`code-info-message`, translate(DICTIONARY.VERIFICATION_CODE.X),
							translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + response.payload.destination +
							translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
						setSubWindow("code")
					}
				})
				break
			default:
				break
		}
	}
	const currentPassword = <MobileInputCurrentPassword id={`${id}-current-password`} name={`current-password`} style={{}}
		className={className} topRadius={"1vh"} bottomRadius={"1vh"} showLabel={false} username={username}
		autoFocus={false} form={`${id}-form`} left={{icon: " "}}/>
	const newPassword =
		<div className={"w3-animate-opacity"}>
			<MobileInputNewPassword id={`${id}-new-password`} name={`new-password`} className={className} topRadius={"1vh"}
				bottomRadius={"1vh"} showLabel={false} username={username} autoFocus={true} form={`${id}-form`}
				setPasswordsMatch={setPasswordsMatch}/>
		</div>
	const code =
		/* TODO: send message about code being sent, automatically submit on 8 characters, send message on success, ban on fail */
		<div className={"w3-animate-opacity"}>
			<MobileInputCode id={`${id}-code`} className={className} name={"code"} label={translate(DICTIONARY.CODE.X)}
				left={{icon: ICONS.SHIELD}} verificationType={VERIFICATION_TYPE.PASSWORD_UPDATE} autoFocus={true}
				showLabel={false} placeholder={translate(DICTIONARY.CODE.X)} onFailure={()=>{}} topRadius={"1vh"}
				bottomRadius={"1vh"}
				onSuccess={() => {
					fadingMessage("password-change-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "",
						"w3-pale-green", ICONS.CHECK)
					setSubWindow("current")
				}}/>
		</div>
	let content
	switch (subWindow) {
		case "new":
			content = newPassword
			break
		case "code":
			return code
		default:
			content = currentPassword
			break
	}
	return <MobileInputForm id={id} className={" "} content={content}	onSubmit={onSubmit}/>
}

export default MobileUpdatePassword