import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {toggleInArray} from "../../../../globals/functions/shared/local"
import Modal from "../../../../globals/components/Modal"
import MobileButtonGeneric from "../buttons/MobileButtonGeneric"
import ImageAndTextCard from "../../base/ImageAndTextCard"
import MobileImage from "../../../main/account/MobileImage"

/**
 *
 * @param {Object} props
 * @param {boolean} props.multiSelect
 * @param {string[]} props.selected
 * @param {string} props.id
 * @param {string} props.title
 * @param {Icon} props.icon
 * @param {string} props.imagePlaceholder
 * @param {Object[]} props.list
 * @param {string} props.list[].id
 * @param {string} props.list[].title
 * @param {string} props.list[].text
 * @param {string} props.list[].image
 * @param {JSX.Element} [props.list[].middle]
 * @param {JSX.Element} [props.list[].bottom]
 * @param {function} props.onApply
 * @param {function} props.onClose
 * @param {JSX.Element} [props.addNew]
 * @param {function} [props.setShowAddNew]
 * @constructor
 */
const ModalSelect = props => {
	const [showModal, setShowModal] = useState(false)
	const [selected, setSelected] = useState([])
	useEffect(() => {
		try {
			setSelected(props.selected || [])
		} catch (e) {
			console.log(e)
		}
	}, [props.selected])
	// console.log(selected)
	const toggleSelection = v => setSelected(props.multiSelect ? toggleInArray(selected, v) : [v])
	const item = selected.length > 0 ? props.list.find(v => v.id === selected[0]) : props.list[0]
	// console.log("selected", selected, "list", props.list, "item", item)
	/* TODO: manage multi-select */
	const background = props.multiSelect && selected.length > 1 ? <div>{"+4"}</div> :
/*		<div className={"paralian-theme-level-2"} onClick={() => setShowModal(true)}>
			<div className={"paralian-mobile-info-card-header"} style={{paddingTop: "2vh"}}>
				<i>{item.title ? item.title : <></>}</i>
			</div>
			<div style={{display: "inline-flex", width: "100%"}} onClick={() => setShowModal(true)}>
				<ImageAndTextCard text={item.text} image={item.image} bottom={item.bottom} middle={item.middle}
					imagePlaceholder={props.imagePlaceholder} onClick={() => toggleSelection(item.id)}/>
				<div style={{paddingRight: "2vh", fontSize: "4vh", margin: "auto"}}>
					<MobileFontAwesome icon={ICONS.ANGLE_DOUBLE_DOWN}/>
				</div>
			</div>
		</div>*/
		props.list.length > 0 ?
		<div>
			<div style={{textAlign: "right", padding: "0vh 1vh"}}	onClick={() => setShowModal(true)}>
				<i><b>{translate(DICTIONARY.CHANGE.X)}</b></i>
			</div>
			<div>
				<MobileImage image={item.image} size={"20vh"} alt={"profile"} style={{margin: "auto"}}
					placeholder={props.imagePlaceholder}/>
				<div style={{fontSize: "3vh", padding: "1vh"}}>{item.title ? item.title : <></>}</div>
				{item.text.map((v, i) => <div key={`${props.id}-${i}`} style={{fontSize: "2vh", opacity: "0.75"}}>{v}</div>)}
			</div>
		</div> :
		<div>
			<div style={{textAlign: "right", padding: "0vh 1vh"}}	onClick={() => {
				setShowModal(true)
				if (props.setShowAddNew) {setTimeout(() => props.setShowAddNew(true), 500)}
			}}>
				<i><b>{translate(DICTIONARY.ADD_NEW.X)}</b></i>
			</div>
			<div>
				<MobileImage size={"20vh"} alt={"profile"} style={{margin: "auto"}}
					placeholder={props.imagePlaceholder}/>
				<div style={{fontSize: "3vh", padding: "1vh"}}>{translate(DICTIONARY.NONE_AVAILABLE.X)}</div>
				<div style={{fontSize: "2vh", opacity: "0.75"}}>{translate(DICTIONARY.PRESS_ADD_NEW_ABOVE.X)}</div>
			</div>
		</div>
	const body = props.list.map(v => {
		return <div key={`${props.id}-${v.id}`} style={{margin: "2vh"}}>
			<ImageAndTextCard id={props.id} title={v.title} text={v.text} image={v.image} isSelected={selected.includes(v.id)}
			imagePlaceholder={props.imagePlaceholder} bottom={v.bottom} middle={v.middle} onClick={() => toggleSelection(v.id)}/>
		</div>
	})
	const buttons = <div style={{padding: "1vh 2vh"}}>
		{props.setShowAddNew ?
			<MobileButtonGeneric id={"addNew"} type={"button"} className={"paralian-theme-level-1-flipped"} padding={"1vh"}
				style={{width: "100%", margin: "1vh 0vh"}} text={{style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.ADD_NEW.X)}}
				onClick={() => props.setShowAddNew(true)}/> : <></>
		}
		{props.list.length > 0 ? <MobileButtonGeneric id={"apply"} type={"button"}
			className={"paralian-theme-level-1-flipped"} padding={"3vh 0vh"} style={{width: "100%", margin: "1vh 0vh"}}
			text={{style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.APPLY_SELECTION.X)}}
			onClick={() => {
				try {
					props.onApply(selected.length > 0 ? selected : [item.id])
					setShowModal(false)
				} catch (e) {
					console.log(e)
				}
			}}/> : <></>}
	</div>
	const modal = showModal ?
		<Modal id={props.id} title={props.title} icon={props.icon} padding={"0vh"} body={<>{body}{buttons}</>}
 			onClick={() => setShowModal(false)}/> : <></>
	return <>{modal}{background}</>
}

export default ModalSelect