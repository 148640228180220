import React from "react"
import MobileButtonGeneric from "../../../shared/input/buttons/MobileButtonGeneric"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {DICTIONARY} from "../../../../globals/constants/client/constants"

const MobileVesselInviteDecline = props => {
	const onSubmit = event => {
		event.preventDefault()
	}
	const content = <div>
		<div>
			<MobileButtonGeneric id={"close"} type={"button"}
				text={{style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.I_DONT_OWN_THIS_VESSEL.X)}}
				className={"paralian-theme-level-1-flipped"}
				padding={"1vh"} style={{width: "100%"}} onClick={() => {/* TODO: remove account-specific contact info from invite */}}/>
		</div>
		<div>
			<MobileButtonGeneric id={"close"} type={"button"}
				text={{style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.THIS_IS_DUPLICATE_PROFILE.X)}}
				className={"paralian-theme-level-1-flipped"}
				padding={"1vh"} style={{width: "100%"}} onClick={() => {/* TODO: change vessel id on requesters list of customers */}}/>
		</div>
		{/* TODO: show drop-down of vessels */}
	</div>
	return <MobileInputForm id={"owner"} content={content} className={" "} onSubmit={onSubmit} style={{paddingBottom: "2vh"}}/>
}

export default MobileVesselInviteDecline