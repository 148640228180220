import React from "react"
import {DICTIONARY, ICONS, LANGUAGE} from "../../../../globals/constants/client/constants"
import {getLanguage, setLanguage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"
/**
 * Stand-alone language drop-down at bottom of page
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const MobileFooterLanguage = () => {
	const lang = getLanguage()
	const list = LANGUAGE.map((item, i) => {return {label: item.name, value: item.code, dataId: i}})
	return <form id={"lang-form"} className={"w3-display-bottommiddle"} style={{marginBottom: "4vh"}} onSubmit={() => {}}>
		<MobileInputGeneric type={"dropdown"} id={"lang-form-dropdown"} className={"paralian-theme-level-1"} size={"1"}
			showLabel={false}	label={translate(DICTIONARY.LANGUAGE.X)} multiple={false}	name={"language"} form={"lang-form"}
			autoFocus={false} required={true} list={list} fontSize={"1vh"} left={{icon: ICONS.LANGUAGE, onClick: () => {}}}
			defaultValue={lang}	onChange={() => {
				const language = document.getElementById("lang-form-dropdown-input").value
				setLanguage(language, false)
			}}/>
	</form>
}

export default MobileFooterLanguage