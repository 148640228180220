import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.digits {number}
 * @param props.decimals {number}
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.defaultValue {number}
 * @param props.required {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.onChange {Object} - function
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputID = props => {
	const pattern = "([0-9]){1,10}"
	return <div style={{display: "inline-flex", width: "100%"}}>
		<MobileInputGeneric type={"number"} id={`${props.id}-whole`} name={`${props.name}-whole`} form={props.form}
			autoFocus={props.autoFocus} maxLength={`${props.digits}`} autoComplete={"off"} pattern={pattern}
			required={props.required} placeholder={props.placeholder} min={"1"} max={"9".repeat(10)}
			step={1} defaultValue={props.defaultLong} onChange={props.onChange} label={props.label}
			showLabel={props.showLabel} className={props.className} bottomRadius={props.bottomRadius}
			style={{textAlign: "center", ...props.style}}
			topRadius={props.topRadius}/>
	</div>
}

export default MobileInputID