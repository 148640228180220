export const Pages = {
	MOBILE: {
		X: "MOBILE",
	},
	DESKTOP: {
		X: "DESKTOP",
	},
	COMMON: {
		WELCOME: {
			X: "WELCOME"
		},
		MAIN: {
			X: "MAIN",
			USERS: {
				X: "USERS"
			},
			ADMIN: {
				X: "ADMIN"
			},
			EMPLOYEE: {
				X: "EMPLOYEE"
			},
			EMPLOYEES: {
				X: "EMPLOYEES"
			},
			CUSTOMERS: {
				X: "CUSTOMERS"
			},
			ORGANIZATION: {
				X: "ORGANIZATION"
			},
			VESSELS: {
				X: "VESSELS"
			},
			MARINAS: {
				X: "MARINAS"
			},
			QR: {
				X: "QR"
			},
			ORDERS: {
				X: "ORDERS"
			},
			NOTIFICATIONS: {
				X: "NOTIFICATIONS"
			},
			SETTINGS: {
				X: "SETTINGS"
			},
			VERIFICATIONS: {
				X: "VERIFICATIONS"
			},
			MENU: {
				X: "MENU"
			},
			REQUESTS: {
				X: "REQUESTS",
				CONCIERGE: {
					X: "CONCIERGE",
					HOME: {
						X: "HOME"
					},
					SUCCESS: {
						X: "SUCCESS"
					},
					VESSEL: {
						X: "VESSEL"
					},
					MARINA: {
						X: "MARINA"
					},
					MESSAGE: {
						X: "MESSAGE"
					},
					CONTACT: {
						X: "CONTACT"
					}
				},
				EQUIPMENT_RENTAL: {
					X: "EQUIPMENT_RENTAL",
					SUPPLIERS: {
						X: "SUPPLIERS"
					},
					PAYMENT: {
						X: "PAYMENT"
					}
				},
				VESSEL_STORAGE: {
					X: "VESSEL_STORAGE",
					AMENITIES: {
						X: "AMENITIES"
					},
					STORAGE_UNIT: {
						X: "STORAGE_UNIT"
					},
					PAYMENT: {
						X: "PAYMENT"
					},
					DATES: {
						X: "DATES"
					},
					ORDER_SUMMARY: {
						X: "ORDER_SUMMARY"
					},
					ORDER_CONFIRMATION: {
						X: "ORDER_CONFIRMATION"
					},
					STORAGE_TYPES: {
						X: "STORAGE_TYPES"
					},
					MARINA: {
						X: "MARINA"
					}
				},
				SHUTTLE_SERVICE: {
					X: "SHUTTLE_SERVICE",
					PAYMENT: {
						X: "PAYMENT"
					},
					MARINA: {
						X: "MARINA"
					}
				},
				VESSEL_CLEANING: {
					X: "VESSEL_CLEANING",
					VESSEL: {
						X: "VESSEL"
					},
					MARINA: {
						X: "MARINA"
					},
					SERVICE_TYPE_INTERIOR: {
						X: "SERVICE_TYPE_INTERIOR"
					},
					SERVICE_TYPE_EXTERIOR: {
						X: "SERVICE_TYPE_EXTERIOR"
					},
					SURFACE_STATE: {
						X: "SURFACE_STATE"
					},
					PROTECTION_LEVEL: {
						X: "PROTECTION_LEVEL"
					},
					ADVANCED: {
						X: "ADVANCED"
					},
					PAYMENT: {
						X: "PAYMENT"
					},
					INTERIOR: {
						X: "INTERIOR"
					},
					PROVIDERS: {
						X: "PROVIDERS"
					},
					SUCCESS: {
						X: "SUCCESS"
					},
					START: {
						X: "START"
					},
					DUE: {
						X: "DUE"
					},
					MESSAGE: {
						X: "MESSAGE"
					},
					LOCATION: {
						X: "LOCATION"
					},
					LENGTH: {
						X: "LENGTH"
					},
					BEAM: {
						X: "BEAM"
					},
					ENGINE_FLUSH: {
						X: "ENGINE_FLUSH"
					}
				},
				VESSEL_FUELING: {
					X: "VESSEL_FUELING",
					TANKS: {
						X: "TANKS"
					},
					PAYMENT: {
						X: "PAYMENT"
					},
					MARINA: {
						X: "MARINA"
					}
				},
				VESSEL_TRANSPORT: {
					X: "VESSEL_TRANSPORT",
					MARINA: {
						X: "MARINA"
					}
				},
				VESSEL_REPAIR: {
					X: "VESSEL_REPAIR",
					MARINA: {
						X: "MARINA"
					}
				},
				VESSEL_PROVISIONING: {
					X: "VESSEL_PROVISIONING",
					MARINA: {
						X: "MARINA"
					}
				}
			},
			TOOLS: {
				X: "TOOLS",
				WEATHER_MAP: {
					X: "WEATHER_MAP"
				},
				AIS_MAP: {
					X: "AIS_MAP"
				},
				WAVE_MAP: {
					X: "WAVE_MAP"
				},
				WIND_MAP: {
					X: "WIND_MAP"
				},
				CHART: {
					X: "CHART"
				},
				TEXT_FORECAST: {
					X: "TEXT_FORECAST"
				}
			}
		},
		LOGIN: {
			X: "LOGIN",
			HELP: {
				X: "HELP"
			},
			ENTRY: {
				X: "ENTRY"
			},
			START: {
				X: "START"
			},
			EXISTING_USER: {
				X: "EXISTING_USER",
				HELP: {
					X: "HELP"
				},
				USERNAME: {
					X: "USERNAME",
				},
				PASSWORD: {
					X: "PASSWORD"
				},
				ROLE: {
					X: "ROLE"
				}
			},
			NEW_USER: {
				X: "NEW_USER",
				ORGANIZATION_GOING_LIVE: {
					X: "ORGANIZATION_GOING_LIVE"
				},
				ORGANIZATION_EMPLOYEES: {
					X: "ORGANIZATION_EMPLOYEES"
				},
				ORGANIZATION_EMPLOYEES_CONTACT_INFO: {
					X: "ORGANIZATION_EMPLOYEES_CONTACT_INFO"
				},
				ORGANIZATION_EMPLOYEES_SERVICES: {
					X: "ORGANIZATION_EMPLOYEES_SERVICES"
				},
				ORGANIZATION_EMPLOYEES_SERVICES_ACCESS: {
					X: "ORGANIZATION_EMPLOYEES_SERVICES_ACCESS"
				},
				ORGANIZATION_EMPLOYEES_Q: {
					X: "ORGANIZATION_EMPLOYEES_Q"
				},
				ORGANIZATION_ADD_EMPLOYEES: {
					X: "ORGANIZATION_ADD_EMPLOYEES"
				},
				ORGANIZATION_SERVICE_MARINAS: {
					X: "ORGANIZATION_SERVICE_MARINAS"
				},
				ORGANIZATION_NEW_SERVICES: {
					X: "ORGANIZATION_NEW_SERVICES"
				},
				ORGANIZATION_NEW_SERVICES_Q: {
					X: "ORGANIZATION_NEW_SERVICES_Q"
				},
				ORGANIZATION_SERVICES_Q: {
					X: "ORGANIZATION_SERVICES_Q"
				},
				ORGANIZATION_SERVICE_AREA: {
					X: "ORGANIZATION_SERVICE_AREA"
				},
				ORGANIZATION_SUPPORT_ADDRESS_Q: {
					X: "ORGANIZATION_SUPPORT_ADDRESS_Q"
				},
				ORGANIZATION_MAILING_ADDRESS_Q: {
					X: "ORGANIZATION_MAILING_ADDRESS_Q"
				},
				ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q: {
					X: "ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q"
				},
				ORGANIZATION_ADMIN_PASSWORD: {
					X: "ORGANIZATION_ADMIN_PASSWORD"
				},
				ORGANIZATION_ADMIN_NAME: {
					X: "ORGANIZATION_ADMIN_NAME"
				},
				ORGANIZATION_ADMIN_TITLE: {
					X: "ORGANIZATION_ADMIN_TITLE"
				},
				ORGANIZATION_ADMIN_BIRTHDATE: {
					X: "ORGANIZATION_ADMIN_BIRTHDATE"
				},
				ORGANIZATION_ADMIN_PHONE: {
					X: "ORGANIZATION_ADMIN_PHONE"
				},
				ORGANIZATION_ADMIN_PHONE_VERIFICATION: {
					X: "ORGANIZATION_ADMIN_PHONE_VERIFICATION"
				},
				ORGANIZATION_ADMIN_EMAIL: {
					X: "ORGANIZATION_ADMIN_EMAIL"
				},
				ORGANIZATION_ADMIN_EMAIL_VERIFICATION: {
					X: "ORGANIZATION_ADMIN_EMAIL_VERIFICATION"
				},
				ORGANIZATION_ADMIN_PERSONAL_SECURITY_PHRASE: {
					X: "ORGANIZATION_ADMIN_PERSONAL_SECURITY_PHRASE"
				},
				ORGANIZATION_ADMIN_USERNAME: {
					X: "ORGANIZATION_ADMIN_USERNAME"
				},
				ORGANIZATION_TERMS_AND_CONDITIONS: {
					X: "ORGANIZATION_TERMS_AND_CONDITIONS"
				},
				ORGANIZATION_ACCOUNT_CONTACT_INFO: {
					X: "ORGANIZATION_ACCOUNT_CONTACT_INFO"
				},
				ORGANIZATION_STEP_1: {
					X: "ORGANIZATION_STEP_1"
				},
				ORGANIZATION_STEP_2: {
					X: "ORGANIZATION_STEP_2"
				},
				ORGANIZATION_STEP_3: {
					X: "ORGANIZATION_STEP_3"
				},
				ORGANIZATION_STEP_4: {
					X: "ORGANIZATION_STEP_4"
				},
				ORGANIZATION_CONFIRM_LOCATION: {
					X: "ORGANIZATION_CONFIRM_LOCATION"
				},
				ORGANIZATION_HOLIDAYS: {
					X: "ORGANIZATION_HOLIDAYS"
				},
				ORGANIZATION_MARINA_OR_BUSINESS: {
					X: "ORGANIZATION_MARINA_OR_BUSINESS"
				},
				ORGANIZATION_TYPE: {
					X: "ORGANIZATION_TYPE"
				},
				ORGANIZATION_MAIN_CONTACT_INFO: {
					X: "ORGANIZATION_MAIN_CONTACT_INFO"
				},
				ORGANIZATION_MAIN_CONTACT_INFO_VERIFICATION: {
					X: "ORGANIZATION_MAIN_CONTACT_INFO_VERIFICATION"
				},
				ORGANIZATION_PHYSICAL_ADDRESS: {
					X: "ORGANIZATION_PHYSICAL_ADDRESS"
				},
				ORGANIZATION_MAILING_ADDRESS: {
					X: "ORGANIZATION_MAILING_ADDRESS"
				},
				ORGANIZATION_SUPPORT_CONTACT_INFO: {
					X: "ORGANIZATION_SUPPORT_CONTACT_INFO"
				},
				ORGANIZATION_SUPPORT_ADDRESS: {
					X: "ORGANIZATION_SUPPORT_ADDRESS"
				},
				ORGANIZATION_HOURS_OF_OPERATION: {
					X: "ORGANIZATION_HOURS_OF_OPERATION"
				},
				ORGANIZATION_CURRENCY_AND_TIMEZONE: {
					X: "ORGANIZATION_CURRENCY_AND_TIMEZONE"
				},
				ORGANIZATION_IMAGE: {
					X: "ORGANIZATION_IMAGE"
				},
				ORGANIZATION_ICON: {
					X: "ORGANIZATION_ICON"
				},
				ORGANIZATION_LOGO: {
					X: "ORGANIZATION_LOGO"
				},
				ORGANIZATION_COLORS: {
					X: "ORGANIZATION_COLORS"
				},
				ORGANIZATION_REGISTRATION_DOCUMENTS: {
					X: "ORGANIZATION_REGISTRATION_DOCUMENTS"
				},
				ORGANIZATION_SERVICES: {
					X: "ORGANIZATION_SERVICES"
				},
				ORGANIZATION_PRICING: {
					X: "ORGANIZATION_PRICING"
				},
				ORGANIZATION_CONNECT_WITH_STRIPE: {
					X: "ORGANIZATION_CONNECT_WITH_STRIPE"
				},
				ACCOUNT_TYPE: {
					X: "ACCOUNT_TYPE"
				},
				INVITATION_Q: {
					X: "INVITATION_Q"
				},
				INVITATION_CODE: {
					X: "INVITATION_CODE"
				},
				EMAIL: {
					X: "EMAIL"
				},
				EMAIL_VERIFICATION: {
					X: "EMAIL_VERIFICATION"
				},
				USERNAME: {
					X: "USERNAME"
				},
				PASSWORD: {
					X: "PASSWORD"
				},
				TERMS_AND_CONDITIONS: {
					X: "TERMS_AND_CONDITIONS"
				},
				NAME: {
					X: "NAME"
				},
				EMAILS_AND_PHONE_NUMBER: {
					X: "EMAILS_AND_PHONE_NUMBER"
				},
				EMAILS_AND_PHONE_NUMBER_CODE: {
					X: "EMAILS_AND_PHONE_NUMBER_CODE"
				},
				DATE_OF_BIRTH: {
					X: "DATE_OF_BIRTH"
				},
				PROFILE_PICTURE: {
					X: "PROFILE_PICTURE"
				},
				PERSONAL_SECURITY_PHRASE: {
					X: "PERSONAL_SECURITY_PHRASE"
				},
				ADDRESS: {
					X: "ADDRESS"
				}
			}
		}
	}
}

export const PageStates = {
	MOBILE: {
		X: {levelOne: Pages.MOBILE.X, levelTwo: "", levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
		WELCOME: {
			X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.WELCOME.X, levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
		},
		MAIN: {
			X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
			REQUESTS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X, levelFour: "", levelFive: "", levelSix: ""},
				CONCIERGE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: "", levelSix: ""},
					HOME: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.HOME.X, levelSix: ""}},
					SUCCESS: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.SUCCESS.X, levelSix: ""}},
					VESSEL: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.VESSEL.X, levelSix: ""}},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.MARINA.X, levelSix: ""}},
					MESSAGE: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.MESSAGE.X, levelSix: ""}},
					CONTACT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.X, levelFive: Pages.COMMON.MAIN.REQUESTS.CONCIERGE.CONTACT.X, levelSix: ""}}
				},
				EQUIPMENT_RENTAL: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.EQUIPMENT_RENTAL.X, levelFive: "", levelSix: ""},
					SUPPLIERS: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.EQUIPMENT_RENTAL.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.EQUIPMENT_RENTAL.SUPPLIERS.X,
							levelSix: ""}
					},
					PAYMENT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.EQUIPMENT_RENTAL.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.EQUIPMENT_RENTAL.PAYMENT.X,
							levelSix: ""}
					}
				},
				VESSEL_STORAGE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X, levelFive: "", levelSix: ""},
					AMENITIES: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.AMENITIES.X, levelSix: ""}
					},
					STORAGE_UNIT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.STORAGE_UNIT.X, levelSix: ""}
					},
					PAYMENT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.PAYMENT.X, levelSix: ""}
					},
					DATES: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.DATES.X, levelSix: ""}
					},
					ORDER_SUMMARY: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.ORDER_SUMMARY.X, levelSix: ""}
					},
					ORDER_CONFIRMATION: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.ORDER_CONFIRMATION.X, levelSix: ""}
					},
					STORAGE_TYPES: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.STORAGE_TYPES.X, levelSix: ""}
					},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_STORAGE.MARINA.X, levelSix: ""}
					}
				},
				SHUTTLE_SERVICE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.SHUTTLE_SERVICE.X, levelFive: "", levelSix: ""},
					PAYMENT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.SHUTTLE_SERVICE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.SHUTTLE_SERVICE.PAYMENT.X, levelSix: ""}
					},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.SHUTTLE_SERVICE.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.SHUTTLE_SERVICE.MARINA.X, levelSix: ""}
					}
				},
				VESSEL_CLEANING: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X, levelFive: "", levelSix: ""},
					VESSEL: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.VESSEL.X, levelSix: ""}
					},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.MARINA.X, levelSix: ""}
					},
					SERVICE_TYPE_INTERIOR: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X, levelSix: ""}
					},
					SURFACE_STATE: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SURFACE_STATE.X, levelSix: ""}
					},
					PROTECTION_LEVEL: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.PROTECTION_LEVEL.X, levelSix: ""}
					},
					ADVANCED: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.ADVANCED.X, levelSix: ""}
					},
					PAYMENT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.PAYMENT.X, levelSix: ""}
					},
					PROVIDERS: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.PROVIDERS.X, levelSix: ""}
					},
					INTERIOR: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.INTERIOR.X, levelSix: ""}
					},
					SERVICE_TYPE_EXTERIOR: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X, levelSix: ""}
					},
					SUCCESS: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SUCCESS.X, levelSix: ""}
					},
					START: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.START.X, levelSix: ""}
					},
					DUE: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.DUE.X, levelSix: ""}
					},
					MESSAGE: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.MESSAGE.X, levelSix: ""}
					},
					LOCATION: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.LOCATION.X, levelSix: ""}
					},
					LENGTH: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.LENGTH.X, levelSix: ""}
					},
					BEAM: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.BEAM.X, levelSix: ""}
					},
					ENGINE_FLUSH: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.ENGINE_FLUSH.X, levelSix: ""}
					}
				},
				VESSEL_FUELING: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.X, levelFive: "", levelSix: ""},
					TANKS: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.TANKS.X, levelSix: ""}
					},
					PAYMENT: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.PAYMENT.X, levelSix: ""}
					},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_FUELING.MARINA.X, levelSix: ""}
					}
				},
				VESSEL_TRANSPORT: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_TRANSPORT.X, levelFive: "", levelSix: ""},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_TRANSPORT.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_TRANSPORT.MARINA.X, levelSix: ""}
					}
				},
				VESSEL_REPAIR: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_REPAIR.X, levelFive: "", levelSix: ""},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_REPAIR.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_REPAIR.MARINA.X, levelSix: ""}
					}
				},
				VESSEL_PROVISIONING: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
						levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_PROVISIONING.X, levelFive: "", levelSix: ""},
					MARINA: {
						X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.REQUESTS.X,
							levelFour: Pages.COMMON.MAIN.REQUESTS.VESSEL_PROVISIONING.X,
							levelFive: Pages.COMMON.MAIN.REQUESTS.VESSEL_PROVISIONING.MARINA.X, levelSix: ""}
					}
				}
			},
			TOOLS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X, levelFour: "", levelFive: "", levelSix: ""},
				WEATHER_MAP: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X,
						levelFour: Pages.COMMON.MAIN.TOOLS.WEATHER_MAP.X, levelFive: "", levelSix: ""}
				},
				AIS_MAP: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X,
						levelFour: Pages.COMMON.MAIN.TOOLS.AIS_MAP.X, levelFive: "", levelSix: ""}
				},
				WIND_MAP: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X,
						levelFour: Pages.COMMON.MAIN.TOOLS.WIND_MAP.X, levelFive: "", levelSix: ""}
				},
				WAVE_MAP: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X,
						levelFour: Pages.COMMON.MAIN.TOOLS.WAVE_MAP.X, levelFive: "", levelSix: ""}
				},
				CHART: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X,
						levelFour: Pages.COMMON.MAIN.TOOLS.CHART.X, levelFive: "", levelSix: ""}
				},
				TEXT_FORECAST: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.TOOLS.X,
						levelFour: Pages.COMMON.MAIN.TOOLS.TEXT_FORECAST.X, levelFive: "", levelSix: ""}
				}
			},
			ADMIN: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.ADMIN.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			EMPLOYEE: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.EMPLOYEE.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			EMPLOYEES: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.EMPLOYEES.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			CUSTOMERS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.CUSTOMERS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			ORGANIZATION: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.ORGANIZATION.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			USERS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.USERS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			VESSELS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.VESSELS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			MARINAS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.MARINAS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			QR: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.QR.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			ORDERS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.ORDERS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			NOTIFICATIONS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.NOTIFICATIONS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			SETTINGS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.SETTINGS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			VERIFICATIONS: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.VERIFICATIONS.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			MENU: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: Pages.COMMON.MAIN.MENU.X, levelFour: "", levelFive: "", levelSix: ""}
			}
		},
		LOGIN: {
			X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
			START: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.START.X, levelFour: "", levelFive: "", levelSix: ""}
			},
			EXISTING_USER: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.EXISTING_USER.X,
					levelFour: "", levelFive: "", levelSix: ""},
				HELP: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.EXISTING_USER.X,
						levelFour: Pages.COMMON.LOGIN.EXISTING_USER.HELP.X, levelFive: "", levelSix: ""}
				},
				USERNAME: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.EXISTING_USER.X,
						levelFour: Pages.COMMON.LOGIN.EXISTING_USER.USERNAME.X, levelFive: "", levelSix: ""}
				},
				PASSWORD: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.EXISTING_USER.X,
						levelFour: Pages.COMMON.LOGIN.EXISTING_USER.PASSWORD.X, levelFive: "", levelSix: ""}
				},
				ROLE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.EXISTING_USER.X,
						levelFour: Pages.COMMON.LOGIN.EXISTING_USER.ROLE.X, levelFive: "", levelSix: ""}
				}
			},
			NEW_USER: {
				X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
					levelFour: "", levelFive: "", levelSix: ""},
				ORGANIZATION_ADD_EMPLOYEES: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADD_EMPLOYEES.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_IMAGE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_IMAGE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_GOING_LIVE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_GOING_LIVE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_EMPLOYEES: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_EMPLOYEES.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_EMPLOYEES_CONTACT_INFO: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_EMPLOYEES_CONTACT_INFO.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_EMPLOYEES_SERVICES: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_EMPLOYEES_SERVICES.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_EMPLOYEES_SERVICES_ACCESS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_EMPLOYEES_SERVICES_ACCESS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_EMPLOYEES_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_EMPLOYEES_Q.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SERVICE_MARINAS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICE_MARINAS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SUPPORT_ADDRESS_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS_Q.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_NEW_SERVICES: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_NEW_SERVICES.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_NEW_SERVICES_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_NEW_SERVICES_Q.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SERVICES_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICES_Q.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SERVICE_AREA: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICE_AREA.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_MAILING_ADDRESS_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAILING_ADDRESS_Q.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS_VISIT_Q.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_PASSWORD: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_PASSWORD.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_EMAIL: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_EMAIL_VERIFICATION: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_EMAIL_VERIFICATION.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_PERSONAL_SECURITY_PHRASE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_PERSONAL_SECURITY_PHRASE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_USERNAME: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_USERNAME.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_NAME: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_NAME.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_TITLE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_TITLE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_BIRTHDATE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_BIRTHDATE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_PHONE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_PHONE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ADMIN_PHONE_VERIFICATION: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ADMIN_PHONE_VERIFICATION.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ACCOUNT_CONTACT_INFO: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ACCOUNT_CONTACT_INFO.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_TERMS_AND_CONDITIONS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_TERMS_AND_CONDITIONS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_STEP_1: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_1.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_STEP_2: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_2.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_STEP_3: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_3.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_STEP_4: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_STEP_4.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_CONFIRM_LOCATION: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_CONFIRM_LOCATION.X, levelFive: "", levelSix: ""}
				},
				EMAIL_VERIFICATION: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.EMAIL_VERIFICATION.X, levelFive: "", levelSix: ""}
				},
				EMAIL: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.EMAIL.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_PRICING: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_PRICING.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_HOLIDAYS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_HOLIDAYS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_REGISTRATION_DOCUMENTS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_REGISTRATION_DOCUMENTS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_TYPE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_TYPE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SUPPORT_CONTACT_INFO: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SUPPORT_CONTACT_INFO.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SUPPORT_ADDRESS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SUPPORT_ADDRESS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_MARINA_OR_BUSINESS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_MARINA_OR_BUSINESS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_MAIN_CONTACT_INFO: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_MAIN_CONTACT_INFO_VERIFICATION: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAIN_CONTACT_INFO_VERIFICATION.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_PHYSICAL_ADDRESS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_PHYSICAL_ADDRESS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_MAILING_ADDRESS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_MAILING_ADDRESS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_LOGO: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_LOGO.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_ICON: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_ICON.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_HOURS_OF_OPERATION: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_HOURS_OF_OPERATION.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_CURRENCY_AND_TIMEZONE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_CURRENCY_AND_TIMEZONE.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_SERVICES: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_SERVICES.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_COLORS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_COLORS.X, levelFive: "", levelSix: ""}
				},
				ORGANIZATION_CONNECT_WITH_STRIPE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ORGANIZATION_CONNECT_WITH_STRIPE.X, levelFive: "", levelSix: ""}
				},
				ACCOUNT_TYPE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ACCOUNT_TYPE.X, levelFive: "", levelSix: ""}
				},
				INVITATION_Q: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.INVITATION_Q.X, levelFive: "", levelSix: ""}
				},
				INVITATION_CODE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.INVITATION_CODE.X, levelFive: "", levelSix: ""}
				},
				USERNAME: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.USERNAME.X, levelFive: "", levelSix: ""}
				},
				PASSWORD: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.PASSWORD.X, levelFive: "", levelSix: ""}
				},
				TERMS_AND_CONDITIONS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.TERMS_AND_CONDITIONS.X, levelFive: "", levelSix: ""}
				},
				NAME: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.NAME.X, levelFive: "", levelSix: ""}
				},
				EMAILS_AND_PHONE_NUMBER: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.EMAILS_AND_PHONE_NUMBER.X, levelFive: "", levelSix: ""}
				},
				EMAILS_AND_PHONE_NUMBER_CODE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.EMAILS_AND_PHONE_NUMBER_CODE.X, levelFive: "", levelSix: ""}
				},
				DATE_OF_BIRTH: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.DATE_OF_BIRTH.X, levelFive: "", levelSix: ""}
				},
				PROFILE_PICTURE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.PROFILE_PICTURE.X, levelFive: "", levelSix: ""}
				},
				PERSONAL_SECURITY_PHRASE: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.PERSONAL_SECURITY_PHRASE.X, levelFive: "", levelSix: ""}
				},
				ADDRESS: {
					X: {levelOne: Pages.MOBILE.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: Pages.COMMON.LOGIN.NEW_USER.X,
						levelFour: Pages.COMMON.LOGIN.NEW_USER.ADDRESS.X, levelFive: "", levelSix: ""}
				}
			}
		}
	},
	DESKTOP: {
		X: {levelOne: Pages.DESKTOP.X, levelTwo: "", levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
		WELCOME: {
			X: {levelOne: Pages.DESKTOP.X, levelTwo: Pages.COMMON.WELCOME.X, levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
		},
		MAIN: {
			X: {levelOne: Pages.DESKTOP.X, levelTwo: Pages.COMMON.MAIN.X, levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
		},
		LOGIN: {
			X: {levelOne: Pages.DESKTOP.X, levelTwo: Pages.COMMON.LOGIN.X, levelThree: "", levelFour: "", levelFive: "", levelSix: ""},
		}
	}
}