import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {newMessage, setToStorage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputText from "../../../shared/input/text/MobileInputText"

/**
 * @param {Object} props
 * @param {JSX.Element} props.header
 * @param {Object} props.next
 * @param {Object} props.back
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginOrgAdminTitle = props => {
	useEffect(() => {
		newMessage("psp-message-auto", translate(DICTIONARY.JOB_TITLE.X),
			translate(DICTIONARY.ORG_JOB_TITLE_INPUT_MESSAGE.X), "w3-pale-blue", ICONS.ID_BADGE)
	}, [])
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const title = document.getElementById("title-input").value
			setToStorage("title", title, "session")
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
		} catch (e) {
			console.log(e)
		}
	}
	const content =	<MobileInputText id={"title"} className={"paralian-theme-level-1-flipped"} autoComplete={"organization-title"}
		name={"title"} pattern={TEXT_FIELD_PROPERTIES.personalSecurityPhrase.pattern} autoFocus={true} showLabel={false}
		left={{icon: ICONS.ID_BADGE}} right={{icon: ICONS.INFO_CIRCLE, onClick: () => {
		newMessage("psp-message", translate(DICTIONARY.JOB_TITLE.X),
		translate(DICTIONARY.JOB_TITLE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}
		placeholder={translate(DICTIONARY.JOB_TITLE.X)}	label={translate(DICTIONARY.JOB_TITLE.X)}/>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgAdminTitle