import React, {useEffect} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {newMessage,	translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.form {string}
 * @param props.showLabel {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputPasswordConfirm = (props) => {
	useEffect(() => {document.getElementById("submit").disabled = true}, [])
	return <MobileInputGeneric type={"password"} id={"password-match"} name={"password-match"} showLabel={props.showLabel}
		label={translate(DICTIONARY.CONFIRM_PASSWORD.X)} className={props.className} style={props.style}
		bottomRadius={props.bottomRadius} topRadius={props.topRadius} left={{icon: " ", onClick: null}} autoFocus={false}
		placeholder={translate(DICTIONARY.CONFIRM_PASSWORD.X)} form={props.form} autoComplete={"password-new"}
		right={{icon: ICONS.INFO_CIRCLE, onClick: () => {
			newMessage("login", translate(DICTIONARY.PASSWORD.X), translate(DICTIONARY.LOGIN_PASSWORD_INFO_MESSAGE.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)}}}
		onChange={() => {
			try {
				// console.log("password", document.getElementById("password-input").value)
				// console.log("match", document.getElementById("password-match-input").value)
				document.getElementById("password-input").value ===
				document.getElementById("password-match-input").value ?
					document.getElementById("submit").disabled = false :
					document.getElementById("submit").disabled = true
			} catch (e) {
				console.log(e)
			}}}/>
}

export default MobileInputPasswordConfirm