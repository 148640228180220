import React from "react"

/**
 *
 * @param {Object} props
 * @param {React.HTMLProps.className} props.className
 * @param {string} props.subtitle
 * @param {JSX.Element} props.content
 * @return {JSX.Element}
 * @constructor
 */
const MobileSubtitleAndContent = props => {
	return <div style={{margin: "2vh"}}>
		<div style={{textAlign: "left"}}><i>{props.subtitle}</i></div>
		<div style={{textAlign: "right", fontSize: "2.5vh", padding: "0vh 2vh"}} className={`${props.className} w3-animate-opacity`}>
			{props.content}
		</div>
	</div>
}

export default MobileSubtitleAndContent