import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {VERIFICATION_TYPE} from "../../../../globals/constants/shared/enumerators"
import {fadingMessage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputCode from "../../../shared/input/text/MobileInputCode"

/**
 *
 * @param {Object} props
 * @param {Object} props.next
 * @param {Object} props.back
 * @param {string} props.message
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginOrgContactInfoVerify = props => {
	useEffect(() => {
		if (props.message) {
			newMessage("contact-info-message", translate(DICTIONARY.CONTACT_INFO.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}
	}, [props.message])
	const onSubmit = event => {
		event.preventDefault()
		store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
	}
	const content = <>
		<div style={{fontSize: "3vh"}}>
			<div style={{margin: "1vh 0vh"}}>
				<MobileInputCode id={"primary-email-code"} name={"primary-email-code"} className={"paralian-theme-level-1-flipped"}
					label={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.PRIMARY_EMAIL.X)}`} required={true}
					form={"form"} right={{}} left={{icon: ICONS.ENVELOPE, onClick: () => {}}} autoFocus={true}
					showLabel={false} placeholder={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.PRIMARY_EMAIL.X)}`}
					verificationType={VERIFICATION_TYPE.PRIMARY_EMAIL} isOrganization={true} bottomRadius={"1vh"} topRadius={"1vh"}
					onFailure={()=>{document.getElementById("primary-email-code-input").value = ""}}
					onSuccess={() => {
						fadingMessage("primary-email-code-success-message", translate(DICTIONARY.PRIMARY_EMAIL_VERIFIED.X),
						"", "w3-pale-green", ICONS.CHECK_CIRCLE)}}/>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileInputCode id={"support-email-code"} name={"support-email-code"} className={"paralian-theme-level-1-flipped"}
					label={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.SUPPORT_EMAIL.X)}`} required={false}
					form={"form"} right={{}} left={{icon: ICONS.ENVELOPE_FAR, onClick: () => {}}} autoFocus={true}
					showLabel={false} placeholder={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.SUPPORT_EMAIL.X)}`}
					verificationType={VERIFICATION_TYPE.SECONDARY_EMAIL} isOrganization={true} bottomRadius={"1vh"} topRadius={"1vh"}
					onFailure={()=>{document.getElementById("support-email-code-input").value = ""}}
					onSuccess={() => {
						fadingMessage("secondary-email-code-success-message", translate(DICTIONARY.SUPPORT_EMAIL_VERIFIED.X), "",
							"w3-pale-green", ICONS.CHECK_CIRCLE)}}/>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileInputCode id={"primary-phone-code"} name={"primary-phone-code"} className={"paralian-theme-level-1-flipped"}
					label={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.PRIMARY_PHONE.X)}`} bottomRadius={"1vh"} topRadius={"1vh"}
					form={"form"} right={{}} left={{icon: ICONS.PHONE, onClick: () => {}}} autoFocus={true}
					placeholder={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.PRIMARY_PHONE.X)}`}
					verificationType={VERIFICATION_TYPE.PRIMARY_PHONE} showLabel={false} required={false} isOrganization={true}
					onFailure={()=>{document.getElementById("primary-phone-code-input").value = ""}}
					onSuccess={() => {
						fadingMessage("primary-phone-code-success-message", translate(DICTIONARY.PRIMARY_PHONE_VERIFIED.X), "",
							"w3-pale-green", ICONS.CHECK_CIRCLE)}}/>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileInputCode id={"support-phone-code"} name={"support-phone-code"} className={"paralian-theme-level-1-flipped"}
					label={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.SUPPORT_PHONE.X)}`} bottomRadius={"1vh"} topRadius={"1vh"}
					form={"form"} right={{}} left={{icon: ICONS.PHONE_FAR, onClick: () => {}}} autoFocus={true}
					placeholder={`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.SUPPORT_PHONE.X)}`}
					verificationType={VERIFICATION_TYPE.SECONDARY_PHONE} showLabel={false} required={false} isOrganization={true}
					onFailure={()=>{document.getElementById("support-phone-code-input").value = ""}}
					onSuccess={() => {
						fadingMessage("support-phone-code-success-message", translate(DICTIONARY.SUPPORT_PHONE_VERIFIED.X), "",
						 "w3-pale-green", ICONS.CHECK_CIRCLE)}}/>
			</div>
		</div>
	</>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgContactInfoVerify