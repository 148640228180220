import {typeOrNull} from '../../functions/shared/local'
import ISO3 from './textInputFilters/ISO3'
import FullName from './textInputFilters/FullName'

class Currency {
	/**
	 *
	 * @param {Currency} [currency = null]
	 * @param {string} [currency.name]
	 * @param {number} [currency.symbol]
	 * @param {string} currency.code
	 * @param {boolean} currency.zeroDecimal
	 */
	constructor (currency = null) {
		try {
			this.name = new FullName(currency.name).filtered
			this.symbol = typeOrNull(currency.symbol, "string")
			this.code = new ISO3(currency.code).filtered
			this.zeroDecimal = typeOrNull(currency.zeroDecimal, "boolean")
		} catch (e) {
			this.name = null
			this.symbol = null
			this.code = null
			this.zeroDecimal = null
		}
	}

	/**
	 * Returns currency symbol as string
	 * @returns {string}
	 */
	get asString() {
		try {
			return this.symbol !== null ? String.fromCharCode(this.symbol) : ""
		} catch (e) {
			return ""
		}
	}

	/**
	 * Converts value from zero decimal format to currency's format
	 * @param {number} value
	 * @return {number}
	 */
	asNumber(value) {
		try {
			return Math.ceil(value)/(this.zeroDecimal ? 1 : 100)
		} catch (e) {
			console.log(e)
			return NaN
		}
	}

	/**
	 * Converts value from currency's format to zero decimal format
	 * @param {number} value
	 * @return {number}
	 */
	asZeroDecimal(value) {
		try {
			return Math.ceil(value*(this.zeroDecimal ? 1 : 100))
		} catch (e) {
			console.log(e)
			return NaN
		}
	}

	/**
	 * Converts value from zero decimal format to currency's format
	 * Returns result as localeString
	 * @param {number} value
	 * @return {string}
	 */
	asText(value) {
		try {
			return this.asNumber(value).toLocaleString(undefined,
				{style: "currency", currency: this.code})
		} catch (e) {
			console.log(e)
			return "-"
		}
	}

	/**
	 * Multiplies value by quantity & converts result from zero decimal format to currency's format
	 * @param {number} value
	 * @param {number} quantity
	 * @return {number}
	 */
	totalAsNumber(value, quantity) {
		try {
			return this.asNumber(value * quantity)
		} catch (e) {
			console.log(e)
			return NaN
		}
	}

	/**
	 * Multiplies value by quantity & converts result from zero decimal format to currency's format
	 * Returns result as localeString
	 * @param {number} value
	 * @param {number} quantity
	 * @return {string}
	 */
	totalAsText(value, quantity) {
		try {
			return this.totalAsNumber(value, quantity).toLocaleString(undefined,
				{style: "currency", currency: this.code})
		} catch (e) {
			console.log(e)
			return "-"
		}
	}

	/**
	 * Divides value by quantity & converts result from zero decimal format to currency's format
	 * @param {number} value
	 * @param {number} quantity
	 * @return {number}
	 */
	unitAsNumber(value, quantity) {
		try {
			return quantity !== 0 ? this.asNumber(value/quantity) : this.asNumber(value)
		} catch (e) {
			console.log(e)
			return NaN
		}
	}

	/**
	 * Divides value by quantity & converts result from zero decimal format to currency's format
	 * Returns result as localeString
	 * @param {number} value
	 * @param {number} quantity
	 * @return {string}
	 */
	unitAsText(value, quantity) {
		try {
			const asNumber = this.unitAsNumber(value, quantity)
			return asNumber === 0 ? " - " : asNumber.toLocaleString(undefined,
				{style: "currency", currency: this.code})
		} catch (e) {
			console.log(e)
			return " - "
		}
	}
}

export default Currency