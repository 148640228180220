import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {getServices} from "../../../../globals/functions/client/serverFunctions"
import {constructArray} from "../../../../globals/functions/shared/local"
import MobileMultipleSelect from "../../../shared/input/buttons/MobileMultipleSelect"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileInputSlider from "../../../shared/input/switch/MobileInputSlider"
import Servicio from "../../../../globals/classes/shared/Servicio"

const ExteriorCleaningAdvanced = props => {
	const [refresh, setRefresh] = useState(0)
	const [services, setServices] = useState(null)
	const [ignoreConstraints, setIgnoreConstraints] = useState(false)
	useEffect(() => {
		getServices().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				try {
					const maintenance = response.payload.contained.find(element => element.name === "vessel_maintenance")
					if (maintenance) {
						const cleaning = maintenance.children.parents.find(element => element.name === "vessel_cleaning")
						maintenance.children.parents = [cleaning]
						const interior = cleaning.children.parents.find(element => element.name === "vessel_cleaning_interior")
						const exterior = cleaning.children.parents.find(element => element.name === "vessel_cleaning_exterior")
						setServices({interior: new Servicio(interior), exterior: new Servicio(exterior)})
						// setServices({interior: interior, exterior: exterior})
					}
				} catch (e) {
					console.log(e)
				}
			}
		})
	}, [refresh])
	const exterior = services ? constructArray(services.exterior.asArray.slice(1), item => new Servicio(item)) : []
	// console.log(exterior)
	const reArranged = services ? [
		exterior.find(v => v.id === 1005), exterior.find(v => v.id === 1105), exterior.find(v => v.id === 1099),
		exterior.find(v => v.id === 1101), exterior.find(v => v.id === 1007), exterior.find(v => v.id === 1104),
		exterior.find(v => v.id === 1006), exterior.find(v => v.id === 1100), exterior.find(v => v.id === 1012),
		exterior.find(v => v.id === 1102), exterior.find(v => v.id === 1103), exterior.find(v => v.id === 1015),
		exterior.find(v => v.id === 1013),
	] : []
	// console.log(reArranged)
	return services ? <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.EXTERIOR.X)}</legend>
			<div style={{borderBottom: "solid", padding: "1vh", marginBottom: "2vh", fontSize: "2.5vh"}}>
				{translate(DICTIONARY.ADVANCED_SELECTION.X)}
			</div>
		<MobileMultipleSelect items={exterior} list={reArranged} id={"exterior"} ignoreExcludes={false}
			ignoreConstraints={ignoreConstraints}/>
		<div className={"w3-display-container"} style={{margin: "2vh 0vw 1vh 0vw", height: "3vh"}}>
			<MobileInputSlider id={"saveInDatabase"} label={translate(DICTIONARY.IGNORE_CONSTRAINTS.X)} height={"3vh"}
				defaultChecked={ignoreConstraints} padding={"0.25vh 0vh 0.25vh 2vh"} width={"8vh"} className={"w3-display-left"}
				onChange={element => {setIgnoreConstraints(element.getAttribute("data-is-checked") === "true")}}/>
		</div>
	</fieldset> :
	<MobileFontAwesome id={"refresh"} icon={ICONS.SYNC} style={{fontSize: "10vh"}}
		onClick={() => setRefresh(refresh + 1)} label={translate(DICTIONARY.REFRESH.X)}/>
}

export default ExteriorCleaningAdvanced