import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputText from "./MobileInputText"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {string} props.label
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @param {boolean} props.showLabel
 * @param {boolean} props.autoComplete
 * @param {boolean} props.required
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputName = props => {
	const label = props.label || translate(DICTIONARY.FULL_NAME.X)
	return <MobileInputText id={`${props.id}-name`} form={props.form} required={props.required} label={label}
		showLabel={props.showLabel} placeholder={label} autoComplete={props.autoComplete ? "name" : "off"} maxLength={"64"}
		left={props.left} right={props.right} defaultValue={props.defaultValue} pattern={"([a-zA-Z0-9-_\. ]){2,64}"}
		name={"name"} className={props.className || "paralian-theme-level-1-flipped"} onChange={props.onChange}/>
}

export default MobileInputName