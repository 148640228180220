import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {
	getFromStorage,
	newMessage,
	replaceInStorage,
	translate
} from "../../../../globals/functions/client/localFunctions"
import ModalSelect from "../../../shared/input/ModalSelect/ModalSelect"
import Modal from "../../../../globals/components/Modal"
import MobileVesselStorageFacilitiesAdd from "../../vessels/storage/MobileVesselStorageFacilitiesAdd"
import MobileFontAwesome from "../../MobileFontAwesome"
import anchorImagePlaceholder from "../../../../images/map-pin-regular-paralian-gold.svg"

const VesselLocation = props => {
	const [showAddNew, setShowAddNew] = useState(false)
	const session = getFromStorage("order", "session")
	// console.log("session", session)
	const vessel = [...props.vessels.owned, ...props.vessels.customer].find(v => v.id === session.vessels[0].id)
	// console.log("vessels", [...props.vessels.owned, ...props.vessels.customer])
	// console.log("vessel", vessel)
	const homesList = vessel.homes.filter(v => v.id === session.marinas[0].id).map(v => {
		const text = []
		if (v.facility) {text.push(v.facility)}
		if (v.name.display) {text.push(v.name.display)}
		return {
			id: v.priority,
			title: v.unit,
			text: text,
			image: v.logo,
			unit: v.unit,
			facility: v.facility
		}
	})
	useEffect(() => {
		if (homesList.length > 0) {
			replaceInStorage("order", "homes",
				[{priority: homesList[0].id, facility: homesList[0].facility, unit: homesList[0].unit}], "session")
			props.setDisabled(false)
		} else {
			props.setDisabled(true)
		}}, [])
	const addNew = showAddNew ? <Modal id={`${props.id}-addNew`} title={translate(DICTIONARY.ADD_VESSEL_STORAGE_UNIT.X)}
		icon={ICONS.MAP_MARKER} padding={"0vh"} body={
		<MobileVesselStorageFacilitiesAdd id={vessel.id} marinas={props.marinas} defaultMarina={session.marinas[0].id}
			setAdd={setShowAddNew} onSuccess={() => {
				props.refresh()
				setShowAddNew(false)
		}}/>}
			onClick={() => setShowAddNew(false)}/> : <></>
	return <>
		{addNew}
		<fieldset style={{margin: "1vh 0vh"}}>
			<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>
				{translate(DICTIONARY.LOCATION.X)}
				<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{paddingLeft: "1vh"}} onClick={() => newMessage("location-message",
					translate(DICTIONARY.LOCATION.X), translate(DICTIONARY.VESSEL_LOCATION_DEFINE.X), "w3-pale-blue", ICONS.MAP_MARKED_ALT)}/>
			</legend>
			<ModalSelect imagePlaceholder={anchorImagePlaceholder} icon={ICONS.MAP_MARKED_ALT} id={"vessels"}
				title={translate(DICTIONARY.SELECT_LOCATION.X)} selected={[]} list={homesList} multiSelect={false}
				setShowAddNew={setShowAddNew} onApply={v => {
					const home = homesList.find(x => x.id === v[0])
					replaceInStorage("order", "homes",
						[{priority: home.id, facility: home.facility, unit: home.unit}], "session")
					props.setDisabled(false)
			}}/>
		</fieldset>
	</>
}

export default VesselLocation