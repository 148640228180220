import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getServices} from "../../../../globals/functions/client/serverFunctions"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../../main/MobileStickyBannerExpandDown"
import MobileVerificationStatusCard from "../../../main/MobileVerificationStatusCard"
import MobileAddNewBanner from "../../../main/MobileAddNewBanner"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
import MobileOrgAddMarinaServiceRequest from "../../../main/marinas/MobileOrgAddMarinaServiceRequest"
import Modal from "../../../../globals/components/Modal"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"
/**
 *
 * @param {Object} props
 * @param {Object[]} props.serviceApprovals
 * @param {string} props.top - top margin for sticky property of banner
 * @param {number} props.zIndex
 * @returns {JSX.Element}
 * @constructor
 */
const MobileServiceMarinas = props => {
	const [serviceApprovals, setServiceApprovals] = useState(props.serviceApprovals)
	const [add, setAdd] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [services, setServices] = useState(null)
	const [selected, setSelected] = useState(null)
	// console.log("MobileServiceMarinas", serviceApprovals, add, expanded, services, selected)
	useEffect(() => {
		getServices().then(response => {
			// console.log("all", all)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setServices(new IndexedArray({obj: response.payload.asIndexedArray.obj}))
			}}
		)
	}, [])
	useEffect(() => {setServiceApprovals(props.serviceApprovals)} , [props.serviceApprovals])
	/* TODO: get list of all organization/vessel/account documents then filter based on container and type. provide those as documents parameter */
	const modal = selected ? <Modal id={"marina-service-access-request-modal"} padding={"0vh"} icon={ICONS.KEY}
		title={selected.profile.name.display} onClick={() => setSelected(null)} body={
		<MobileVerificationStatusCard setServiceApprovals={setServiceApprovals}
			key={`marina-verification-${selected.profile.id}`} request={selected} services={services} onDelete={() => {
				const index = serviceApprovals.findIndex(element => selected.profile.id === element.profile.id)
				props.refresh().then()
				serviceApprovals.splice(index, 1)
				setServiceApprovals([...serviceApprovals])}}
			refresh={props.refresh} canEdit={props.canEdit} isRequester={true} documents={props.documents}
			currency={props.currency} country={props.country} state={props.state} simple={false}/>
	}/> : <></>
	const content =	serviceApprovals.map(request => <MobileVerificationStatusCard setServiceApprovals={setServiceApprovals}
		key={`marina-verification-${request.profile.id}`} request={request} services={services} onDelete={() => {
			const index = serviceApprovals.findIndex(element => request.profile.id === element.profile.id)
				props.refresh().then()
				serviceApprovals.splice(index, 1)
				setServiceApprovals([...serviceApprovals])}}
		refresh={props.refresh} canEdit={props.canEdit} isRequester={true} documents={props.documents}
		currency={props.currency} country={props.country} state={props.state} simple={true} onClick={() => setSelected(request)}/>)
	/*
	<MobileVerificationStatusCard setServiceApprovals={() => {}}	key={`${v.organization}-verification-card`}
				request={req} services={services} onDelete={() => {}}	documentRequirements={new DocumentRequirements()} refresh={props.refresh}
				canEdit={props.canEdit} resolved={v.resolved} isRequester={false}/>
	 */
	const hiddenIds = serviceApprovals.map(item => item.profile.id)
	const add_ = props.canEdit && add ? <Modal id={"add-marina-service-access-request-modal"} padding={"0vh"} icon={ICONS.ANCHOR}
		title={translate(DICTIONARY.ADD_MARINA.X)} onClick={() => setAdd(false)} body={
		<div className="member paralian-mobile-info-card">
			<MobileOrgAddMarinaServiceRequest id={"marina-service-request"} hidden={hiddenIds}
				onSubmit={() => {
					props.refresh().then()
					setAdd(false)
				}}/>
		</div>
	}/> : <></>
	return <>
		{modal}
		<MobileStickyBannerExpandDown expanded={expanded} id={"service-marinas"} zIndex={props.zIndex} top={props.top}
			text={<>
				{props.isMarina ? <MobileFontAwesome icon={ICONS.CROWN} style={{paddingRight: "1vh"}}/> : <></>}
				{translate(DICTIONARY.MARINA_SERVICE_ACCESS.X)}
			</>} onClick={() => {setExpanded(!expanded)}}/>
		{expanded ? <div id={"service-marinas"} className={"w3-animate-top"} style={{textAlign: "left"}}>
			{content}
			{add_}
			{props.canEdit && !props.isMarina ?
				<MobileAddNewBanner className={`add-service-marinas`} text={translate(DICTIONARY.ADD_MARINA.X)}
				onClick={() => setAdd(!add)}/> : <></>}
		</div> : <></>}
	</>
}

export default MobileServiceMarinas