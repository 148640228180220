import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getFromStorage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {storePassword} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileNewPassword from "../../MobileNewPassword"

/**
 *
 * @param {Object} props
 * @param {Object} props.back
 * @param {Object} props.next
 * @param {string} props.message
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginNewPassword = props => {
	/* TODO: update onSubmit to generalized form */
	useEffect(() => {
		if (props.message) {
			newMessage("username-message", translate(DICTIONARY.PASSWORD.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}, [props.message])
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const password = document.getElementById("password-input").value
			const match = document.getElementById("password-match-input").value
			// const username = document.getElementById("username-input").value
			// const type = getFromStorage("type", "session")
			if (password !== match) {
				newMessage("new user password", translate(DICTIONARY.PASSWORD_MISMATCH.X),
					translate(DICTIONARY.PASSWORD_MISMATCH_MESSAGE.X), "w3-pale-yellow", 0, ICONS.EXCLAMATION)
			} else {
				const response = await storePassword(password)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					window.sessionStorage.removeItem("username")
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
				}
			}
		} catch (e) {
			
			console.log(e)
		}
	}
	const username = getFromStorage("username", "session")
	const content = <MobileNewPassword username={username}/>

	return <MobileLoginFormScaffolding header={null} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginNewPassword