import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileTimezone from "./MobileTimezone"
import MobileCurrency from "./MobileCurrency"
import Currency from "../../../globals/classes/shared/Currency"
import Timezone from "../../../globals/classes/shared/Timezone"

/**
 *
 * @param {Object} props
 * @param {Currency} props.currency
 * @param {Timezone} props.timezone
 * @param {Country} props.country
 * @param {string} props.top - top margin for sticky property of banner
 * @param {string} props.zIndex
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileCurrencyAndTimezone = props => {
	const [expanded, setExpanded] = useState(false)
	const label = `${translate(DICTIONARY.CURRENCY.X)} & ${translate(DICTIONARY.TIME_ZONE.X)}`
	const content = !expanded ? <></> : <div className={"w3-animate-opacity"}>
		<MobileCurrency currency={new Currency(props.currency.code ? props.currency : props.country.currency)}
			country={props.country.code} appendToLabel={false} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileTimezone timezone={new Timezone(props.timezone)} country={props.country.code} appendToLabel={false}
			canEdit={props.canEdit} refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top} text={label}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileCurrencyAndTimezone