import React from "react"
import {addOrgEmployees} from "../../../globals/functions/client/serverFunctions"
import getEmployeeInput from "./getEmployeeInput"
import submitToServer from "../../../globals/functions/client/submitToServer"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileAdminInput from "./MobileAdminInput"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {function} props.onUndo
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdminSave = props => {
	const onSubmit = async event => await submitToServer(event,
		{employees: [getEmployeeInput({id: props.id, services: props.services})]}, addOrgEmployees, props.onSubmit)
	const content = <MobileAdminInput id={props.id} form={`${props.id}-form`} state={true} name={null} showActive={false}
			login={null} style={{marginTop: "1vh"}} className={props.className} showEmail={true} showLabel={true} showName={true}/>
	return <MobileInputForm id={props.id} content={content} className={null} onSubmit={onSubmit}/>

}

export default MobileAdminSave