import { typeOrNull, enumOrNull } from '../../functions/shared/local';
import { ACCOUNT_TYPE, ACCOUNT_STATE } from '../../constants/shared/enumerators';
import Image from './Image';
import Contact from './Contact';
import Schedule from './Schedule';
import Title from './textInputFilters/Title';
/* TODO: privatize with properties_ with # when supported */

class Employee {
	/**
	 *
	 * @param {Employee} [employee = null]
	 * @param {number} [employee.id]
	 * @param {string} [employee.username]
	 * @param {string} [employee.type]
	 * @param {Image} [employee.image]
	 * @param {Contact} [employee.contact]
	 * @param {number} [employee.rating]
	 * @param {boolean} [employee.isVerified]
	 * @param {boolean} [employee.isActive]
	 * @param {string} [employee.standing]
	 * @param {string} [employee.status]
	 * @param {string} [employee.title]
	 * @param {Schedule} [employee.schedule]
	 * @param {string|Date|number} [employee.employeeSince]
	 * @param {ServiceAccess[]} [employee.services]
	 */
	constructor(employee = null) {
		try {
			this.id = typeOrNull(employee.id, "number")
			this.username = typeOrNull(employee.username, "string")
			this.type_ = enumOrNull(employee.type || employee.type_, ACCOUNT_TYPE)
			this.contact = new Contact(employee.contact)
			this.image = new Image(employee.image)
			this.rating = typeOrNull(employee.rating, "number")
			this.isVerified = typeOrNull(employee.isVerified, "boolean")
			this.isActive = typeOrNull(employee.isActive, "boolean")
			this.standing = typeOrNull(employee.standing, "string")
			this.status_ = enumOrNull(employee.status || employee.status_, ACCOUNT_STATE)
			this.title_ = new Title(employee.title || employee.title_).filtered
			this.schedule = new Schedule(employee.schedule)
			this.employeeSince = typeOrNull(employee.employeeSince, "date")
			this.services = typeOrNull(employee.services, "array")
		} catch (e) {
			this.id = null
			this.username = null
			this.type_ = null
			this.contact = new Contact()
			this.image = new Image()
			this.addresses = []
			this.rating = null
			this.isVerified = null
			this.isActive = null
			this.standing = null
			this.status_ = null
			this.title = null
			this.schedule = new Schedule()
			this.employeeSince = null
			this.services = null
		}
	}

	get type(){
		return this.type_
	}

	set type(type) {
		this.type_ = enumOrNull(type, ACCOUNT_TYPE)
	}

	get title(){
		return this.title_
	}

	set title(title) {
		this.title_ = new Title(title).filtered
	}

	get status(){
		return this.status_
	}

	set status(status) {
		this.status_ = enumOrNull(status, ACCOUNT_STATE)
	}
}

export default Employee;