import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {addOrgServiceMarinas} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputSelectServiceMarinas from "./MobileInputSelectServiceMarinas"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"

const MobileOrgAddMarinaServiceRequest = props => {
	const [selected, setSelected] = useState([])
	const onSubmit = async event => {
		event.preventDefault()
		if (selected.length > 0) {
			const response = await addOrgServiceMarinas(selected)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSubmit()
			}
		}
	}
	const content = <MobileInputSelectServiceMarinas setSelected={setSelected} selected={selected} hidden={props.hidden}
		message={translate(DICTIONARY.SELECT_AUTHORIZED.X)}/>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileOrgAddMarinaServiceRequest