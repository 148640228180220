import React from "react"
import MobileVesselAdd from "../fleet/MobileVesselAdd"

/**
 *
 * @param {Object} props
 * @param {Object} props.owner
 * @param {Email} props.owner.email
 * @param {Phone} props.owner.phone
 * @param {string} props.owner.name
 * @param {boolean} props.owner.isOrganization
 * @param {number} props.owner.action
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselInviteBMY = props => {
	return <MobileVesselAdd isInvite={true} owner={props.owner} onAdd={event => {
		/* TODO: success message */
		props.refreshVessels().then()
		props.setInvite(false)
	}}/>
}

export default MobileVesselInviteBMY