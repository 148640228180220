import React, {useEffect, useState} from "react"
import {ICONS} from "../../../../globals/constants/client/constants"
import MobileHeaderIcon from "../../../shared/frames/MobileHeaderIcon"
import MobileBackButton from "../../../login/MobileBackButton"
import MobileButtonGeneric from "../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.padding - padding on scroll area
 * @param {JSX.Element} props.header
 * @param {JSX.Element|JSX.Element[]|JSX.Element[][]} props.content
 * @param {JSX.Element} props.aboveSubmit
 * @param {JSX.Element} props.back
 * @param {function} props.onSubmit
 * @param {string} props.encType
 * @param {boolean} props.disabled - true if submit button should be disabled
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputFormWithHeader = props => {
	const [bottomReached, setBottomReached] = useState(false)
	useEffect(() => {
		const scrollDiv = document.getElementById("inner-scroll-div")
		const bottomReached_ = scrollDiv.scrollHeight - scrollDiv.offsetHeight === scrollDiv.scrollTop
		// console.log(scrollDiv.scrollHeight, scrollDiv.scrollTop, scrollDiv.offsetHeight, bottomReached_)
		// if (bottomReached_) {setBottomReached(true)}
		setBottomReached(bottomReached_)
	}, [props.content])
	const encType = props.encType ? props.encType : "application/x-www-form-urlencoded"
	const onSubmit = props.onSubmit ? props.onSubmit : () => {}
	const aboveSubmit = props.aboveSubmit ? props.aboveSubmit : <></>
	const submit = props.onSubmit ?
		<div style={{width: "100%", textAlign: "center"}}>
			<MobileButtonGeneric left={{icon: ICONS.CHEVRON_CIRCLE_RIGHT}} type={"submit"} form={"form"}
				style={{marginTop: "1vh", fontSize: "6vh"}} disabled={props.disabled || !bottomReached} onClick={() => {}}/>
		</div> : <></>
	const container = props.onSubmit ?
		<form id={"form"} className={`w3-display-middle w3-animate-opacity ${props.className}`} encType={encType}
			style={{width: "100%", marginTop: props.header && !props.hideIcon ? "6vh" : "0vh", ...props.style}} onSubmit={onSubmit}>
			<div id={"inner-scroll-div"} className={"w3-center paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}
				style={{margin: "0vh auto 0vh auto", height: props.height ? props.height : null,
					maxHeight: props.header ? props.maxHeight || "46vh" : "52vh", padding: props.padding || "0vh 2vh"}}
				onScroll={() => {
					try {
						const scrollDiv = document.getElementById("inner-scroll-div")
						const bottomReached_ = scrollDiv.scrollHeight - scrollDiv.offsetHeight === scrollDiv.scrollTop
						if (bottomReached_) {setBottomReached(true)}
						// console.log(scrollDiv.scrollHeight, scrollDiv.scrollTop, scrollDiv.offsetHeight, bottomReached)
					} catch (e) {
						console.log(e)
					}
				}}>
				{props.content}
			</div>
			{aboveSubmit}
			{submit}
		</form> :
		<div id={"not-form"} className={`w3-display-middle w3-animate-opacity ${props.className}`}
				 style={{width: "100%", marginTop: props.header ? props.marginTop || "6vh" : props.marginTop || "0vh", ...props.style}}>
			<div id={"inner-scroll-div"} className={"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}
				 style={{margin: "0vh auto 0vh auto", height: props.height ? props.height : null,
					 maxHeight: props.header ? props.maxHeight || "46vh" : "52vh", padding: props.padding || "0vh 2vh"}}
				onScroll={() => {
					try {
						const scrollDiv = document.getElementById("inner-scroll-div")
						const bottomReached_ = scrollDiv.scrollHeight - scrollDiv.offsetHeight === scrollDiv.scrollTop
						if (bottomReached_) {setBottomReached(true)}
						// console.log(scrollDiv.scrollHeight, scrollDiv.scrollTop, scrollDiv.offsetHeight, bottomReached)
					} catch (e) {
						console.log(e)
					}
			}}>
				{props.content}
			</div>
			{aboveSubmit}
			{submit}
		</div>
	return <div id={"mobileInputFormWithHeader"} className={"w3-display-container w3-center"}
		style={{height: "100vh", width: "100vw", maxWidth: "62vh", margin: "auto", fontSize: "2.0vh"}}>
		{props.hideIcon ? <></> : <MobileHeaderIcon/>}
		{props.header}
		{container}
		{props.back ? <MobileBackButton window={props.back}/> : <></>}
	</div>
}

export default MobileInputFormWithHeader