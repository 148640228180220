import React from "react"
import MobileImage from "../account/MobileImage"
import MobileImageOverlay from "../account/MobileImageOverlay"

/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {Image} props.image
 * @param {string} props.imagePlaceholder
 * @param {Image} props.logo
 * @param {string} props.logoPlaceholder
 * @param {function} props.onClick
 * @param {boolean} props.showUpload
 * @return {JSX.Element}
 * @constructor
 */
const MobileImageAndLogo = props => {
	return <div style={{minHeight: "24.5vh", minWidth: "24.5vh", display: "inline-block"}}>
		<MobileImage id={`${props.id}-main`} image={props.image} alt={"profile"} size={"22.5vh"} style={{margin: "1.5vh auto 0vh auto"}}
			onClick={() => props.onClick("profile")} showUpload={props.showUpload} placeholder={props.imagePlaceholder}/>
		<MobileImageOverlay id={`${props.id}-overlay`} image={props.logo} alt={"logo"} size={"10vh"} showUpload={props.showUpload}
			style={{margin: "-9vh 0vh 0vh 13vh"}} onClick={() => props.onClick("logo")} placeholder={props.logoPlaceholder}/>
	</div>
}

export default MobileImageAndLogo