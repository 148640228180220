import React from "react"
import {store} from "../../../index"
import {DICTIONARY, ICONS, PAGE_STATES, PAGES} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import BottomQuote from "../../../globals/components/BottomQuote"
import MobileLoginRoleSelection from "./MobileLoginRoleSelection"
import MobilePasswordPageInfo from "../MobilePasswordPageInfo"
import MobileHeaderIcon from "../../shared/frames/MobileHeaderIcon"
import MobileParentContainer from "../../shared/frames/MobileParentContainer"
import MobileBackButton from "../MobileBackButton"
import MobileLoginUsernameEntry from "./MobileLoginUsernameEntry"
import MobileLoginPasswordEntry from "./MobileLoginPasswordEntry"
import MobileLoginHelp from "./MobileLoginHelp"
import MobileFontAwesome from "../../main/MobileFontAwesome"

/**
 * Moves by default to Login Start, where user selects option for logging in or creating an account.
 * If of one these has been selected, it will move based on which is selected.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const MobileLoginEntry = () => {
	const currentState = store.getState()
	const window = currentState.window.current
	const levelOne = window.levelOne
	const levelFour = window.levelFour
	let body = <></>, info = <></>
	switch (levelFour) {
		// case PAGES.COMMON.LOGIN.EXISTING_USER.USERNAME.X:
		default:
			body = <MobileLoginUsernameEntry/>
			info = <>
				<MobileBackButton window={PAGE_STATES[levelOne].LOGIN.NEW_USER.X} text={translate(DICTIONARY.NEW_ACCOUNT.X)}/>
				<div className={`help w3-display-right w3-center`}
						 style={{fontSize: "3.5vh", marginTop: "46vh", marginRight: "2vh", opacity: "0.5", zIndex: "3"}}>
					<MobileFontAwesome id="helpButton" icon={ICONS.QUESTION_CIRCLE} className={null}
						style={{padding: "1vh 0vw", fontSize: "3vh", height: "6vh", minWidth: "6vh"}}
						onClick={() => newMessage("forgot-username-message", translate(DICTIONARY.USERNAME.X),
							translate(DICTIONARY.FORGOT_USERNAME_MESSAGE.X), "w3-pale-blue",
							ICONS.INFO_CIRCLE)}/>
				</div>
			</>
			break
		case PAGES.COMMON.LOGIN.EXISTING_USER.PASSWORD.X:
			body = <MobileLoginPasswordEntry/>
			info = <MobilePasswordPageInfo window={PAGE_STATES[levelOne].LOGIN.EXISTING_USER.USERNAME.X}/>
			break
		case PAGES.COMMON.LOGIN.EXISTING_USER.ROLE.X:
			body = <MobileLoginRoleSelection/>
			break
		case PAGES.COMMON.LOGIN.EXISTING_USER.HELP.X:
			body = <MobileLoginHelp/>
			info = <MobileBackButton window={PAGE_STATES[levelOne].LOGIN.EXISTING_USER.PASSWORD.X}
				text={translate(PAGES.COMMON.LOGIN.EXISTING_USER.PASSWORD.X)}/>
			break
	}
	return <MobileParentContainer header={<MobileHeaderIcon/>} content={<>{info}{body}</>} footer={<BottomQuote/>}/>
}

export default MobileLoginEntry