import React from "react"
import {ACCOUNT_STATE} from "../../../globals/constants/shared/enumerators"
import MobileServiceAccess from "../service/MobileServiceAccess"
import MobileInputJobTitle from "./MobileInputJobTitle"
import MobileInputActiveStateButton from "../../shared/input/buttons/MobileInputActiveStateButton"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"

const MobileEmployeeEdit = props => {
	const id = props.employee.id
	const login = props.employee.contact.contactInfo.emails.primary.address
	const name = props.employee.contact.name.given
	const username = props.employee.status === ACCOUNT_STATE.NEW ||
	props.employee.status === ACCOUNT_STATE.INCOMPLETE ? "" : props.employee.username
	const title = props.employee.title
	const content = <>
		<div style={{fontSize: "3vh"}}>{name}</div>
		<div style={{fontSize: "2.5vh"}}>{login}</div>
		<div style={{fontSize: "1.5vh"}}>{username}</div>
		<div style={{marginBottom: "2vh", marginTop: props.showName ? "0vh" : "2vh"}}>
			<MobileInputJobTitle id={`${id}-employee`} form={`${id}-form`} autoFocus={false} showLabel={true}
				className={"paralian-theme-level-1"} defaultValue={title}/>
		</div>
		<MobileInputActiveStateButton id={id} style={props.style} className={props.className}
			state={props.employee.status}/>
		{props.services.map(service => <MobileServiceAccess key={`${id}-employee-service-${service.id}-access`} employee={props.employee}
			service={service}	id={`${id}-employee-service-${service.id}-access`} className={"paralian-theme-level-1"}/>)}
	</>
	return <MobileInputForm id={id} content={content} className={" "} onSubmit={props.onSubmit}/>
}

export default MobileEmployeeEdit