import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {DAY_DATE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {
	getFromStorage,
	getTimeFormat,
	newMessage,
	translate
} from "../../../../globals/functions/client/localFunctions"
import {updateOrgHolidays} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileHolidayHoursOfOperation from "../../../main/calendar/MobileHolidayHoursOfOperation"
import MobileAddNewBanner from "../../../main/MobileAddNewBanner"
import Time from "../../../../globals/classes/shared/Time"
import Holiday from "../../../../globals/classes/shared/Holiday"

const MobileLoginHolidays = props => {
	useEffect(() => {
		if (props.message) {
			newMessage("holidays-info-message", translate(DICTIONARY.HOLIDAYS.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}
	}, [props.message])
	const [holidays, setHolidays] = useState([])
	// const [use24Hr, setUse24Hr] = useState(getTimeFormat() !== "xm" || false)
	const use24Hr = getTimeFormat() !== "xm" || false
	const onSubmit = async event => {
		event.preventDefault()
		if (holidays.length === 0) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			return
		}
		const getState = day => {
			try {
				return document.getElementById(`${day}-state`).getAttribute("data-state")
			} catch (e) {
				return "closed"
			}
		}
		const getHoursOfOperation = (day, action) => {
			try {
				const state = getState(day)
				if (state === "open") {
					// console.log(getTimeFormat())
					let use24Hr = getTimeFormat() !== "xm"
					// console.log(use24Hr)
					try {
						use24Hr = document.getElementById(`time-format`).getAttribute("data-is-checked")
					} catch (e) {
						console.log(e)
					}
					// console.log(use24Hr)
					const hour = document.getElementById(`${day}-${action}-hour-input`).value
					const minute = document.getElementById(`${day}-${action}-minute-input`).value
					const xm = use24Hr ? null : document.getElementById(`${day}-${action}-format-input`).value
					return new Time({hour: hour, minute: minute, xm: xm})
				} else if (state === "closed") {
					return new Time({hour: 0, minute: 0, xm: null})
				} else {
					return action === "open" ? new Time({hour: 0, minute: 0, xm: null}) : new Time({hour: 23, minute: 59, xm: null})
				}
			} catch (e) {
				console.log(e)
			}
		}
		const getRecurrence = holiday => {
			let _holiday = null, recurrence = null, name = null, dateMonth = null, dateDate = null, dayOrdinal = null, dayDay = null, dayMonth = null
			try {
				_holiday = document.getElementById(`${holiday}-holiday-input`).getAttribute("data-id")
			} catch (e) {
				console.log(e)
			}
			try {
				recurrence = document.getElementById(`${holiday}-new-recurrence`).getAttribute("data-recurrence")
				name = document.getElementById(`${holiday}-new-name-input`).value
				dateMonth = document.getElementById(`${holiday}-new-date-month-input`).getAttribute("data-a")
				dateDate = document.getElementById(`${holiday}-new-date-date-input`).getAttribute("data-a")
				dayOrdinal = document.getElementById(`${holiday}-new-day-ordinal-input`).getAttribute("data-a")
				dayDay = document.getElementById(`${holiday}-new-day-day-input`).getAttribute("data-a")
				dayMonth = document.getElementById(`${holiday}-new-day-month-input`).getAttribute("data-a")
				// console.log("recurrence", recurrence, "dateDate", dateDate, "dayOrdinal", dayOrdinal)
			} catch (e) {
				console.log(e)
			}
			return new Holiday({id: _holiday,	recurrence: recurrence,	name: name,
				date: recurrence === DAY_DATE.DATE ? dateDate : dayOrdinal,
				month: recurrence === DAY_DATE.DATE ? dateMonth : dayMonth, weekday: dayDay, day: null})
			// {id: _holiday,	type: type,	name: name,	dateMonth: dateMonth,	dateDate: dateDate,	dayOrdinal: dayOrdinal,
			// 				dayDay: dayDay,	dayMonth: dayMonth}
		}
		const _holidays = holidays.map(holiday => {
			const holiday_ = getRecurrence(holiday)
			// console.log(holiday_)
			holiday_.day = {
				isOpen: getState(holiday) !== "closed",
				open: getHoursOfOperation(holiday, "open"),
				close: getHoursOfOperation(holiday, "close")
			}
			return holiday_
		})
		for (let i = 0; i < _holidays; i++) {
			if (_holidays[i].day.close.as24hrNumber < _holidays[i].day.open.as24hrNumber) {
				newMessage("hooError", translate(DICTIONARY.INPUT_ERROR.X),
					translate(DICTIONARY.HOLIDAY_HOURS_OF_OPERATION_INPUT_ERROR_MESSAGE.X), "w3-pale-yellow",
					ICONS.EXCLAMATION_TRIANGLE)
				return
			}
		}
		// console.log(_holidays)
		const response = await updateOrgHolidays(_holidays)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
		}
	}

	const content =	<>
{/*		<div style={{width: "100%", height: "3vh"}} className={"w3-display-container"}>
			<MobileInputSlider id={`time-format`} label={translate(DICTIONARY.MILITARY_TIME.X)}
				height={"3vh"} width={"8vh"} defaultChecked={false} className={"w3-display-middle"}
				onChange={() => setUse24Hr(!use24Hr)} padding={"0.25vh 0vh 0.25vh 2vh"} form={"form"}/>
		</div>*/}
		{holidays.length === 0 ? <div style={{padding: "3vh", textAlign: "center"}}>
			{translate(DICTIONARY.NO_HOLIDAYS_SPECIFIED.X)}</div> : <></>}
		{holidays.map(holiday => {
			return <div key={`div-holiday-${holiday}`} className={"w3-animate-opacity"} style={{paddingBottom: "1vh"}}>
				<MobileHolidayHoursOfOperation key={`holiday-${holiday}`} id={holiday} use24Hr={use24Hr} defaultOther={false}
					country={getFromStorage("location").country} left={{icon: ICONS.TRASH, onClick: () => {
						const arr = [...holidays]
						const index = arr.findIndex(item => item === holiday)
						arr.splice(index, 1)
						setHolidays([...arr])
				}}}/>
			</div>})}
		<MobileAddNewBanner text={`${translate(DICTIONARY.ADD.X)} ${translate(DICTIONARY.HOLIDAY.X)} `} onClick={() => {
			setHolidays(holidays.length === 0 ? [0] : [...holidays, holidays[holidays.length - 1] + 1])
		}}/>
	</>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginHolidays