import React from 'react'
import {store} from "../../../index"
import {PAGE_STATES, PAGES} from "../../../globals/constants/client/constants"
import MobileLoginStart from "./MobileLoginStart"
import MobileLoginEntry from "../current/MobileLoginEntry"
import MobileNewUser from "../MobileNewUser"

/**
 * Moves by default to Login Start, where user selects option for logging in or creating an account.
 * If of one these has been selected, it will move based on which is selected.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} [props]
 * @param {Object} [props.origin] - last page user visited
 * @return {JSX.Element}
 * @constructor
 */
const MobileLogin = props  => {
	let window = store.getState().window.current
	if (!window.levelOne) {window = props.origin}
	let nextContainer
	switch (window.levelThree) {
		case PAGES.COMMON.LOGIN.EXISTING_USER.X:
			nextContainer = <MobileLoginEntry origin={PAGE_STATES.MOBILE.LOGIN.X}/>
			break
		case PAGES.COMMON.LOGIN.NEW_USER.X:
			nextContainer = <MobileNewUser origin={PAGE_STATES.MOBILE.LOGIN.X}/>
			break
		default:
			nextContainer = <MobileLoginStart origin={PAGE_STATES.MOBILE.LOGIN.X}/>
	}
	return nextContainer
}

export default MobileLogin