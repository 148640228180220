import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import {getServices} from "../../../globals/functions/client/serverFunctions"
import {removeDuplicateObjectsFromArray, removeDuplicateObjectsFromArrayByIndexing} from "../../../globals/functions/shared/local"
import MobileOrderCard from "./MobileOrderCard"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import MobileGenericDropdown from "../../shared/input/dropdown/MobileGenericDropdown"
import Modal from "../../../globals/components/Modal"
import IndexedArray from "../../../globals/classes/shared/IndexedArray"
/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MobileOrders = props => {
	/* TODO: include "having problems with your order?" with onClick which leads to Modal with options */
	/* TODO: only placed orders are being shown now, make it so all orders are shown and then filtered */
	/* TODO: include vessel/provider/marina profiles for Modal on order card */
	const [orders, setOrders] = useState({orders: {all: {placed: [], assigned: [], received: [], marina: []},
		flattened: [], filtered: []}, sort: {order: "DESC", key: "updated"},
		filter: {provider: -1, marina: -1, vessel: -1, category: -1, state: -1, transit: -1}})
	const [sort, setSort] = useState(false)
	const [filter, setFilter] = useState(false)
	const [services, setServices] = useState(new IndexedArray())
	useEffect(() => {
		getServices().then(response => {
			// console.log("response", response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setServices(new IndexedArray({obj: response.payload.asIndexedArray.obj}))
			}})},[])
	const ORDER_SORT_OPTIONS = [
		{
			label: translate(DICTIONARY.LAST_UPDATE.X),
			value: "updated"
		},
		{
			label: translate(DICTIONARY.ORDER_DATE.X),
			value: "opened"
		},
		{
			label: translate(DICTIONARY.ORDER_NUMBER.X),
			value: "id"
		},
		{
			label: translate(DICTIONARY.DUE_DATE.X),
			value: "dueDate"
		}
	]
	const compare = (a, b, key) => {
		let valA, valB
		switch (key) {
			case "id":
				valA = Number(a.id)
				valB = Number(b.id)
				break
			case "dueDate":
				valA = new Date(a.id).getTime()
				valB = new Date(b.id).getTime()
				break
			case "opened":
				valA = new Date(a.id).getTime()
				valB = new Date(b.id).getTime()
				break
			case "updated":
				valA = new Date(a.id).getTime()
				valB = new Date(b.id).getTime()
				break
			case "vessel":
				valA = a.vessel.name.display
				valB = b.vessel.name.display
				break
			case "marina":
				valA = a.marina.name.display
				valB = b.marina.name.display
				break
			case "category":
				valA = a.category.code
				valB = b.category.code
				break
			case "state":
				valA = a.state === "RESPONDED" ? a.response === "ACCEPT" ? "CONFIRMED" : "DENIED" : a.state
				valB = b.state === "RESPONDED" ? b.response === "ACCEPT" ? "CONFIRMED" : "DENIED" : b.state
				break
			default:
				valA = a[orders.sort.key]
				valB = b[orders.sort.key]
		}
		return (valA > valB) ? 1 : -1
	}
	const getTransitList = () => {
		try {
			const transitKeys = Object.keys(orders.orders.all)
			// console.log(transitKeys, "transitKeys")
			const transitKeysFiltered = transitKeys.filter(key => orders.orders.all[key].length > 0)
			// console.log(transitKeysFiltered, "transitKeysFiltered")
			return [{id: -1, label: translate(DICTIONARY.ALL.X), value: "all"},
				...transitKeysFiltered.map((key, index) => {return {id: index + 1, label: translate(key.toUpperCase()), value: key}})]
		} catch (e) {
			console.log(e)
			return []
		}
	}
	const flattenAndFilterOrders = _orders_ => {
		try {
			const orders_ = _orders_.orders
			// console.log("orders_", orders_)
			const filter = _orders_.filter
			const transits = getTransitList()
			// console.log("transits", transits, "filter", filter, "transits[filter.transit]", transits[filter.transit])
			const flattened = filter.transit === -1 ? removeDuplicateObjectsFromArrayByIndexing([...orders_.all.placed,
				...orders_.all.received, ...orders_.all.assigned, ...orders_.all.marina]) :
				orders_.all[transits[filter.transit].value]
			// console.log("flattened", flattened, [...orders_.all.placed,	...orders_.all.received, ...orders_.all.assigned, ...orders_.all.marina])
			const states_ = Array.from(new Set((flattened.map(order => order.state === "RESPONDED" ?
				order.response === "ACCEPT" ? "CONFIRMED" : "DENIED" : order.state))))
			return {
				flattened: removeDuplicateObjectsFromArrayByIndexing([...orders_.all.placed,
					...orders_.all.received, ...orders_.all.assigned, ...orders_.all.marina]),
				filtered: flattened.filter(order => {
					const orderProviderId = `${order.provider.id}`
					const filterProviderId = `${filter.provider}`
					const provider = filterProviderId === `${-1}` ? true : orderProviderId === filterProviderId
					// console.log("provider", orderProviderId, filterProviderId, provider)
					const orderMarinaId = `${order.marina.id}`
					const filterMarinaId = `${filter.marina}`
					const marina = filterMarinaId === `${-1}` ? true : orderMarinaId === filterMarinaId
					// console.log("marina", orderMarinaId, filterMarinaId, marina)
					const orderVesselId = `${order.vessel.id}`
					const filterVesselId = `${filter.vessel}`
					const vessel = filterVesselId === `${-1}` ? true : orderVesselId === filterVesselId
					// console.log("vessel", orderVesselId, filterVesselId, vessel)
					const orderCategoryId = `${order.category.id}`
					const filterCategoryId = `${filter.category}`
					const category = filterCategoryId === `${-1}` ? true : orderCategoryId === filterCategoryId
					// console.log("category", orderCategoryId, filterCategoryId, category)
					const orderState = order.state === "RESPONDED" ? order.response === "ACCEPT" ?
						"CONFIRMED" : "DENIED" : order.state
					const orderStateId = `${states_.findIndex(state => orderState === state)}`
					const filterStateId = `${filter.state}`
					const state = filterStateId === `${-1}` ? true : orderStateId === filterStateId
					// console.log("filter",filter, "state", order.state, orderState, orderStateId, filterStateId, state, "states_", states_)
					return provider && marina && vessel && category && state
				})
			}
		} catch (e) {
			console.log(e)
			return {flattened: [], filtered: []}
		}
	}
	useEffect(() => {
		props.refreshOrders()
	}, [])
	useEffect(() => {
		// console.log("useEffect 2 called")
		// console.log("props.orders", props.orders)
		const ordersCopy = JSON.parse(JSON.stringify(orders))
		ordersCopy.orders.all.placed = props.orders.placed || []
		ordersCopy.orders.all.received = props.orders.received || []
		ordersCopy.orders.all.assigned = props.orders.assigned || []
		ordersCopy.orders.all.marina = props.orders.marina || []
		const ff = flattenAndFilterOrders(ordersCopy)
		ordersCopy.orders.flattened = ff.flattened
		const sorted = ff.filtered.sort((a, b) => compare(a, b, orders.sort.key))
		ordersCopy.orders.filtered = ordersCopy.sort.order === "ASC" ? sorted : sorted.reverse()
		// console.log("flattened", ff.flattened, "filtered", ff.filtered)
		setOrders(ordersCopy)
	}, [props.orders])
	// console.log("orders", orders)
	const vessels = removeDuplicateObjectsFromArray(orders.orders.flattened.map(order =>
	{return {id: order.vessel.id, label: order.vessel.name.display}}))
	const marinas = removeDuplicateObjectsFromArray(orders.orders.flattened.map(order =>
	{return {id: order.marina.id, label: order.marina.name.display}}))
	const providers = removeDuplicateObjectsFromArray(orders.orders.flattened.map(order =>
	{return {id: order.provider.id, label: order.provider.name.display, stripeId: order.provider.stripeId}}))
	const categories = removeDuplicateObjectsFromArray(orders.orders.flattened.map(order =>
	{return {id: order.category.id, label: window.textToIcon(order.category.name.replace(/_/g, " ")).text}}))
	// console.log(categories, orders.orders.flattened)
	const states_ = Array.from(new Set((orders.orders.flattened.map(order => order.state === "RESPONDED" ?
		order.response === "ACCEPT" ? "CONFIRMED" : "DENIED" : order.state))))
	const states = states_.map((state, i) => {return {id: i, label: window.textToIcon(state || "").text}})
	/* TODO: let order card know if order is placed, assigned, received, marinas */
	const cards = orders.orders.filtered.length > 0 ?
		orders.orders.filtered.map(order => <MobileOrderCard key={order.id} order={order} dataFilterArray={"0,0,0,0"}
			refreshOrders={props.refreshOrders} organization={props.organization} services={services} account={props.account}
			refreshAccount={props.refreshAccount}/>) :
		<div style={{paddingTop: "10vh"}}>
			<div>
				<MobileFontAwesome icon={ICONS.DOLLY_FLATBED_EMPTY} style={{fontSize: "20vh"}} label={translate(DICTIONARY.NO_ORDERS.X)}
					onClick={() => fadingMessage("no-orders", translate(DICTIONARY.NO_ORDERS.X), "", "w3-pale-blue",
						ICONS.DOLLY_FLATBED_EMPTY)}/>
			</div>
			<div style={{fontSize: "3vh"}}>
				{translate(DICTIONARY.NO_ORDERS.X)}
			</div>
		</div>
/*	const updateOrders = message => {
		// console.log("updateOrders called")
		const orderList = JSON.parse(message.data)
		// console.log(orderList)
		setOrders(orderList)
	}*/

	/*
	useEffect(() => {
		let i = 0
		if (!source) {
			// console.log("useEffect called on Mobile Orders")
			const eventSource = new EventSource("http://paralian.app/api/restricted/stream/orders")
			setSource(eventSource)
			eventSource.addEventListener("ping", () => {
				console.log("ping")
			})
			eventSource.onmessage = updateOrders
			eventSource.onerror = (e) => {
				console.log(e)
				eventSource.close()
				if (i < 50) {
					i++
					setTimeout(() => {
						console.log("Orders SSE connection reset with useEffect")
						setSource(null)
					}, 1000*5)// 1000*60*5)
				}
			}
			eventSource.onopen = (e) => console.log(e)
			// console.log("Orders SSE connection opened with useEffect")
		} else {
			return () => {
				source.close()
				// console.log("Orders SSE connection closed with useEffect else")
			}
		}
	},[source])
	*/
	const clearFilter = () => {
		setOrders({
			orders: {all: orders.orders.all, flattened: orders.orders.flattened, filtered: orders.orders.flattened},
			sort: {order: orders.sort.order, key: orders.sort.key},
			filter: {provider: -1, marina: -1, vessel: -1, category: -1, state: -1, transit: -1}
		})
	}
	const transitList = getTransitList()
	const providersList = [{id: -1, label: translate(DICTIONARY.ALL.X)}, ...providers]
	const marinasList = [{id: -1, label: translate(DICTIONARY.ALL.X)}, ...marinas]
	const vesselsList = [{id: -1, label: translate(DICTIONARY.ALL.X)}, ...vessels]
	const categoriesList = [{id: -1, label: translate(DICTIONARY.ALL.X)},	...categories]
	const statesList = [{id: -1, label: translate(DICTIONARY.ALL.X)},	...states]
	// console.log("statesList", statesList)
	const applyFilter = (key, id) => {
		const ordersCopy = JSON.parse(JSON.stringify(orders))
		ordersCopy.filter[key] = id
		const ff = flattenAndFilterOrders(ordersCopy)
		ordersCopy.orders.flattened = ff.flattened
		ordersCopy.orders.filtered = ff.filtered
		setOrders(ordersCopy)
	}
	const filter_ = filter ? <Modal id={"filter-modal"} icon={ICONS.FILTER} title={translate(DICTIONARY.FILTER.X)}
		body={<>
			<div style={{textAlign: "left", fontSize: "2.5vh", opacity: 0.5, margin: "0vh 2vh"}}>
				<span style={{cursor: "pointer"}} className={"w3-ripple"} onClick={clearFilter}>
					<MobileFontAwesome icon={ICONS.UNDO} label={translate(DICTIONARY.RESET.X)} style={{paddingRight: "1.5vh"}}/>
					<i>{translate(DICTIONARY.RESET.X)}</i>
				</span>
			</div>
			<div style={{padding: "2vh"}}>
				<MobileGenericDropdown id={"transit"} options={transitList} className={"paralian-theme-level-2"}
					left={{icon: ICONS.PEOPLE_ARROWS, onClick: () => {}}} label={translate(DICTIONARY.TRANSIT.X)}
					onChange={id => applyFilter("transit", id)} defaultValue={transitList.findIndex(v => v.id === orders.filter.transit)}/>
				<MobileGenericDropdown id={"providers"} options={providersList}	left={{icon: ICONS.USER_HARD_HAT}}
					className={"paralian-theme-level-2"} defaultValue={providersList.findIndex(v => v.id === orders.filter.provider)}
				label={translate(DICTIONARY.PROVIDER.X)} onChange={id => applyFilter("provider", id)}/>
				<MobileGenericDropdown id={"marinas"} options={marinasList}	left={{icon: ICONS.ANCHOR}}
					className={"paralian-theme-level-2"} defaultValue={marinasList.findIndex(v => v.id === orders.filter.marina)}
				label={translate(DICTIONARY.MARINA.X)} onChange={id => applyFilter("marina", id)}/>
				<MobileGenericDropdown id={"vessels"} options={vesselsList}	left={{icon: ICONS.SHIP}}
					className={"paralian-theme-level-2"} defaultValue={vesselsList.findIndex(v => v.id === orders.filter.vessel)}
				label={translate(DICTIONARY.VESSEL.X)} onChange={id => applyFilter("vessel", id)}/>
				<MobileGenericDropdown id={"categories"} options={categoriesList} left={{icon: ICONS.TRUCK}}
					className={"paralian-theme-level-2"} defaultValue={categoriesList.findIndex(v => v.id === orders.filter.category)}
				label={translate(DICTIONARY.CATEGORY.X)} onChange={id => applyFilter("category", id)}/>
				<MobileGenericDropdown id={"state"} options={statesList} left={{icon: ICONS.CHECK_CIRCLE}}
					className={"paralian-theme-level-2"} defaultValue={statesList.findIndex(v => v.id === orders.filter.state)}
				label={translate(DICTIONARY.STATUS.X)} onChange={id => applyFilter("state", id)}/>
		</div></>}
		onClick={() => setFilter(false)} padding={"0vh"}/> : <></>
	const flipSort = () => {
		const sorted = [...orders.orders.filtered].reverse()
		setOrders({orders: {all: orders.orders.all, flattened: orders.orders.flattened, filtered: sorted},
			sort: {order: orders.sort.order === "ASC" ? "DESC" : "ASC", key: orders.sort.key},
			filter: {provider: orders.filter.provider, marina: orders.filter.marina, vessel: orders.filter.vessel,
				category: orders.filter.category, state: orders.filter.state, transit: orders.filter.transit}})}
	const sort_ = sort ? <Modal id={"sort-modal"} icon={ICONS.SORT_DOWN} title={translate(DICTIONARY.SORT.X)}
		body={<div style={{padding: "2vh"}}>
			<MobileInputGeneric type={"dropdown"} id={"sort"} className={"paralian-theme-level-2"} style={{fontSize: "2.5vh"}}
				label={translate(DICTIONARY.SORT.X)} multiple={false} name={"sort"} required={false} size={"1"}
				left={{icon: orders.sort.order === "ASC" ? ICONS.SORT_UP : ICONS.SORT_DOWN, onClick: flipSort}}
				defaultValue={orders.sort.key} showLabel={false} list={ORDER_SORT_OPTIONS}
				onChange={() => {
					const key = document.getElementById("sort-input").value
					const sorted = [...orders.orders.filtered].sort((a, b) => compare(a, b, key))
					setOrders({orders: {all: orders.orders.all, flattened: orders.orders.flattened, filtered: sorted},
						sort: {order: orders.sort.order, key: key},
						filter: {provider: orders.filter.provider, marina: orders.filter.marina, vessel: orders.filter.vessel,
							category: orders.filter.category, state: orders.filter.state, transit: orders.filter.transit}})
				}}/>
		</div>} onClick={() => setSort(false)} padding={"0vh"}/> : <></>
	const numFilters = Object.values(orders.filter).reduce((sum, item) => {
		return sum + (item !== -1 ? 1 : 0)
	}, 0)
	return <div id={"scrollBody"} className={"w3-display-middle paralian-scroll paralian-hide-scroll-ms " +
		"paralian-hide-scroll-chrome w3-center w3-animate-opacity"}
		style={{width: "100%", height: "83vh", padding: "0vh 2vh"}}>
		<div className={"sticky paralian-theme-level-1"} style={{zIndex: "3", display: "flex",
			alignContent: "center", alignItems: "center", height: "6vh", fontSize: "2.5vh"}}>
			{/*<div className="w3-display-container" style={{display: "inline-flex", margin: "1vh"}}>
				<MobileInputSlider id={`orders-activate-SSE`} form={null} className={""}
					label={<b>{translate(DICTIONARY.AUTO_UPDATE.X)}</b>}
					defaultChecked={true} height={"3vh"} width={"8vh"} padding={"0vh 2vw"} onChange={element => {
					if (element.checked) {
						// console.log("Slider set ON")
						if (source) {
							source.close()
						}
						setSource(null)
						// console.log("Orders SSE connection opened with slider")
					} else {
						// console.log("Slider set OFF")
						if (source) {
							source.close()
						}
						// console.log("Orders SSE connection closed with slider")
					}}}/>
			</div>*/}
			<div style={{margin: "1vh 0vh 1vh auto", cursor: "pointer"}} className={"w3-ripple"}
				onClick={() => props.refreshOrders()}>
				<MobileFontAwesome label={translate(DICTIONARY.REFRESH.X)} icon={ICONS.SYNC} style={{padding: "0vh 2vh"}}/>
				<span style={{fontSize: "2vh"}}>{translate(DICTIONARY.REFRESH.X)}</span>
			</div>
			<div style={{margin: "1vh"}}>
				<MobileFontAwesome icon={orders.sort.order === "ASC" ? ICONS.SORT_UP : ICONS.SORT_DOWN}
					label={translate(DICTIONARY.SORT.X)} style={{padding: "0vh 2vh"}} onClick={flipSort}/>
				<span style={{fontSize: "2vh", cursor: "pointer"}} className={"w3-ripple"} onClick={() => {
					setSort(!sort)
				}}>{translate(DICTIONARY.SORT.X)}</span>
			</div>
			<div style={{margin: "1vh auto 1vh 0vh", cursor: "pointer"}} className={"w3-ripple"} onClick={() => {
				setFilter(!filter)
			}}>
				<MobileFontAwesome icon={ICONS.FILTER} label={translate(DICTIONARY.FILTER.X)} style={{padding: "0vh 2vh"}}/>
				<span style={{fontSize: "2vh"}}>{translate(DICTIONARY.FILTER.X)}</span>
				{numFilters !== 0 ?
					<span style={{fontSize: "2vh"}}>{` (${numFilters})`}</span> : <></>
				}
			</div>
		</div>
		{sort_}
		{filter_}
		{cards}
	</div>
}

export default MobileOrders