import React, {useEffect} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {getFromStorage, replaceInStorage, translate} from "../../../../globals/functions/client/localFunctions"
import ExteriorCleaningType from "./ExteriorCleaningType"
import InteriorCleaningType from "./InteriorCleaningType"

const CleaningType = props => {
	useEffect(() => {
		const session_ = getFromStorage("order", "session")
		if (!session_.servicesExterior) {
			replaceInStorage("order", "servicesExterior", [], "session")
		}
		if (!session_.servicesInterior) {
			replaceInStorage("order", "servicesInterior", [], "session")
		}
		if (props.area === "exterior") {
			const servicesExterior_ = session_.servicesExterior || [{id: 1005, quantity: 1}]
			// console.log("servicesExterior_", session_.servicesExterior, servicesExterior_)
			replaceInStorage("order", "servicesExterior", servicesExterior_, "session")
		} else if (props.area === "interior") {
			const servicesInterior_ = session_.servicesInterior || [{id: 1004, quantity: 1}]
			replaceInStorage("order", "servicesInterior", servicesInterior_, "session")
		}
	}, [props.area])
	return <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.SERVICE_TYPE.X)}</legend>
		{
			props.area === "exterior" ?	<>
				<div style={{borderBottom: "solid", padding: "1vh", marginBottom: "2vh", fontSize: "2.5vh"}}>
					{translate(DICTIONARY.EXTERIOR.X)}
				</div>
				<ExteriorCleaningType isOrganization={props.isOrganization} onClick={v => {
					if (v === "wash") {
						replaceInStorage("order", "servicesExterior", [{id: 1005, quantity: 1}], "session")
					} else if (v === "detailed-wash") {
						replaceInStorage("order", "servicesExterior", [{id: 1105, quantity: 1}], "session")
					} else if (v === "bottomScrub") {
						replaceInStorage("order", "servicesExterior", [{id: 1015, quantity: 1}], "session")
					} else if (v === "detail") {
						replaceInStorage("order", "servicesExterior", [{id: 999999, quantity: 1}], "session")
					} else if (v === "exterior-none") {
						replaceInStorage("order", "servicesExterior", [{id: -1, quantity: 0}], "session")
					}
				}}/>
			</> : <></>
		}
		{
			props.area === "interior" ?	<>
				<div style={{borderBottom: "solid", padding: "1vh", marginBottom: "2vh", fontSize: "2.5vh"}}>
					{translate(DICTIONARY.INTERIOR.X)}
				</div>
				<InteriorCleaningType onClick={v => {
					if (v === "clean") {
						replaceInStorage("order", "servicesInterior", [{id: 1004, quantity: 1}], "session")
					} else if (v === "housekeeping") {
						replaceInStorage("order", "servicesInterior",
							[{id: 1011, quantity: 1}], "session")
					} else if (v === "interior-none") {
						replaceInStorage("order", "servicesInterior", [{id: -1, quantity: 0}], "session")
					}
				}}/>
			</> : <></>
		}
	</fieldset>
}

export default CleaningType