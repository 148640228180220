import { isObject, typeOrNull } from '../../functions/shared/local';

class Icon {
	/**
	 *
	 * @param {Icon} [icon = null]
	 * @param {string[]} [icon.icon] - 2-element fontAwesome descriptor. Example: ["fad", "ladder"]
	 * @param {string} [icon.label]
	 * @param {string} [icon.className]
	 * @param {Object} [icon.style]
	 * @param {function} [icon.onClick]
	 */
	constructor(icon) {
		if (isObject(icon)) {
			this.icon = typeOrNull(icon.icon, "array")
			this.onClick = typeOrNull(icon.onClick, "function")
			this.label = typeOrNull(icon.label, "string")
			this.className = typeOrNull(icon.className, "string")
			this.style = typeOrNull(icon.style, "object")
		} else {
			this.icon = null
			this.onClick = () => {}
			this.label = null
			this.className = null
			this.style = null
		}
	}
}

export default Icon;