import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputContactInfo from "./MobileInputContactInfo"
import MobileContactInfoFields from "./MobileContactInfoFields"
import MobileInfoCardHeader from "../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {ContactInfo} props.contactInfo
 * @param {Websites} props.websites
 * @param {string} props.type - account type
 * @param {boolean} props.canEdit
 * @param {boolean} props.isOrganization
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileContactInfo = props => {
	const [edit, setEdit] = useState(false)
	const [contactInfo, setContactInfo] = useState(props.contactInfo)
	const [websites, setWebsites] = useState(props.websites)
	useEffect(() => {setContactInfo(props.contactInfo)}, [props.contactInfo])
	useEffect(() => {setWebsites(props.websites)}, [props.websites])
	const content =  edit ? <MobileInputContactInfo contactInfo={contactInfo} websites={websites} refresh={props.refresh}
		setContactInfo={setContactInfo} setWebsites={setWebsites} type={props.type} isOrganization={props.isOrganization}/> :
		<MobileContactInfoFields contactInfo={contactInfo} websites={websites} isOrganization={props.isOrganization}/>
	return <div className={"paralian-mobile-info-card w3-animate-opacity"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.CONTACT_INFO.X)]}/>
		{content}
	</div>
}

export default MobileContactInfo