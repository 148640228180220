import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {ENGINE_CLASS} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {boolean} props.autoFocus
 * @param {boolean} props.required
 * @param {boolean} props.showLabel
 * @returns {JSX.Element}
 * @constructor
 */
const MobileEngineClassDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const defaultValue = props.defaultValue || ENGINE_CLASS.OUTBOARD
	const id = `${props.id}-engine-class`
	const list = Object.values(ENGINE_CLASS).map(value => {return {value: value, label: translate(value)}})
	const onChange = props.setValue ? () => {
		try {
			const element = document.getElementById(`${id}-input`)
			props.setValue(element.value)
		} catch (e) {
			console.log(e)
		}
	} : undefined
	return <MobileInputGeneric type={"dropdown"} id={id} name={id} form={props.form} autoFocus={props.autoFocus}
		required={props.required} defaultValue={defaultValue} multiple={false} className={className} size={"1"}
	 	showLabel={props.showLabel}	list={list} label={translate(DICTIONARY.CLASS.X)} style={props.style}
		onChange={onChange}/>
}

export default MobileEngineClassDropdown