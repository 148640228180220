import {typeOrNull} from '../../../functions/shared/local'
import Accesses from '../Accesses'

class VesselAccess {
	/**
	 *
	 * @param {VesselAccess} [access = null]
	 * @param {Accesses} [access.account]
	 * @param {Accesses[]} [access.organizations]
	 * @param {Accesses[]} [access.individuals]
	 */
	constructor (access = null) {
		try {
			this.account = new Accesses(access.account)
			this.organizations = typeOrNull(access.organizations, "array")
			this.individuals = typeOrNull(access.individuals, "array")
		} catch (e) {
			this.account = new Accesses()
			this.organizations = null
			this.individuals = null
		}
	}
}

export default VesselAccess