import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {getDefaultPricingStructures, getOrgOfferedServices,	getServices, updateOrgServicesPrices} from "../../../../globals/functions/client/serverFunctions"
import getServicePricingInput from "../../../main/pricing/getServicePricingInput"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputPricing from "../../../main/pricing/MobileInputPricing"
import Servicio from "../../../../globals/classes/shared/Servicio"
import Currency from "../../../../globals/classes/shared/Currency"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"

const MobileLoginOrgPricing = props => {
	/* TODO: allow organizations to select from a range of default pricing structures */
	const [services, setServices] = useState({services: [], currency: new Currency()})
	const [defaultPricingStructures, setDefaultPricingStructures] = useState(new IndexedArray())
	useEffect(() => {
		getDefaultPricingStructures().then(response => {
			// console.log("getDefaultPricingStructures response", response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setDefaultPricingStructures(new IndexedArray({obj: response.payload}))
			}
		})
	}, [])
	useEffect(() => {
		// console.log("MobileLoginOrgPricing useEffect 2")
		/* TODO: use an IndexedArray to speed things up */
		if (props.message) {
			newMessage("pricing-message", translate(DICTIONARY.PRICING.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}
		getServices().then(all => {
			// console.log("getServices response", all)
			if (all.status === SERVER_RESPONSE_STATE.SUCCESS) {
				getOrgOfferedServices().then(offered => {
					// console.log("getOrgOfferedServices response", offered)
					if (offered.status === SERVER_RESPONSE_STATE.SUCCESS) {
						const indexedAll = new IndexedArray({obj: all.payload.asIndexedArray.obj})
						// console.log("all_", all_)
						// const offered_ = all_.filter(service => offered.payload.ids.includes(service.id))
						const offered_ = offered.payload.ids.map(id => new Servicio(indexedAll.byIndex(id)))
						// console.log("offered_", offered_)
						setServices({services: offered_, currency: new Currency(offered.payload.currency)})
					}
				})
			}}
		)
	}, [props.message])
	const onSubmit = async event => {
		event.preventDefault()
		// console.log("services", services, "defaultPricingStructures", defaultPricingStructures)
		try {
			const pricingSpecs = []
			for (let i = 0; i < services.services.length; i++) {
				pricingSpecs.push(getServicePricingInput(services.services[i], services.currency, defaultPricingStructures))
			}
			// console.log("pricingSpecs", pricingSpecs)
			const response = await updateOrgServicesPrices(pricingSpecs)
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = services.services.map(service => {
		const service_ = new Servicio(service)
		return <MobileInputPricing key={`service-${service_.id}`} id={`service-${service_.id}`} currency={services.currency}
			service={service_} className={"paralian-theme-level-2"} showLabel={true} exampleFlipColor={true}
			defaultPricingStructures={defaultPricingStructures}/>
	})
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginOrgPricing