import {typeOrNull} from '../../functions/shared/local'
import {translate} from "../../functions/client/localFunctions"
import Pricing from './Pricing'
import Services from './Services'
import Constraints from "./Constraints"

class Servicio {
	/**
	 *
	 * @param {Servicio} [servicio = null]
	 * @param {number} [servicio.level]
	 * @param {number} [servicio.id]
	 * @param {boolean} [servicio.isActive]
	 * @param {string} [servicio.label]
	 * @param {string[]} [servicio.labels]
	 * @param {string} [servicio.name]
	 * @param {number} [servicio.parent] - parent id
	 * @param {Pricing} [servicio.pricing]
	 * @param {boolean} [servicio.hasChildren]
	 * @param {Services} [servicio.children]
	 * @param {Constraints} [servicio.constraints]
	 * @param {number} [servicio.defaultPricingStructure]
	 * @param {number} [servicio.displayOrder]
	 */
	constructor(servicio = null) {
		try {
			this.level_ = typeOrNull(servicio.level || servicio.level_, "number")
			this.id = typeOrNull(servicio.id, "number")
			this.labels = typeOrNull(servicio.labels, "array")
			this.isActive = typeOrNull(servicio.isActive, "boolean")
			this.label = typeOrNull(servicio.label, "string")
			this.name = typeOrNull(servicio.name, "string")
			this.parent = typeOrNull(servicio.parent, "number")
			this.pricing_ = new Pricing(servicio.pricing || servicio.pricing_)
			this.hasChildren = typeOrNull(servicio.hasChildren, "boolean")
			this.children = new Services(servicio.children)
			this.category = typeOrNull(servicio.category, "number")
			this.constraints = new Constraints(servicio.constraints)
			this.defaultPricingStructure = typeOrNull(servicio.defaultPricingStructure, "number")
			this.displayOrder = typeOrNull(servicio.displayOrder, "number")
		} catch (e) {
			this.level_ = null
			this.id = null
			this.labels = []
			this.isActive = null
			this.label = null
			this.name = null
			this.parent = null
			this.pricing_ = new Pricing()
			this.hasChildren = false
			this.children = new Services()
			this.category = null
			this.constraints = new Constraints()
			this.defaultPricingStructure = null
			this.displayOrder = null
		}
	}

	get level() {
		return this.level_
	}

	set level(level) {
		this.level_ = typeOrNull(level, "number")
	}

	get pricing() {
		return this.pricing_
	}

	set pricing(pricing) {
		this.pricing_ = new Pricing(pricing)
	}

	/**
	 *
	 * @returns {string|null}
	 */
	get labelsAsString() {
		try {
			if (this.labels.length === 1 && this.label !== this.labels[0]) {
				const translated = [translate(this.labels[0]), translate(this.label)]
				return translated.join(" > ")
			}
			const translated = this.labels.length > 0 ?
				this.labels.map(label => translate(label)) : null
			return translated ? translated.join(" > ") : null
		} catch (e) {
			console.log(e)
			return null
		}
	}

	get lastTwoLabelsAsString() {
		try {
			const translated = this.labels.length > 0 ?
				this.labels.map(label => translate(label)) : null
			return translated ? [translated[translated.length - 2], translated[translated.length - 1]].join(" > ") : null
		} catch (e) {
			console.log(e)
			return null
		}
	}

	/*
	 * Turns servicio object into array of itself and its children
	 * @returns {servicio[]}
	 */
	get asArray() {
		const getChildren = (_parent, _level = 1) => {
			let children = []
			const _children = [..._parent.children.parents, ..._parent.children.singles]
			for (let i = 0; i < _children.length; i++) {
				_children[i].level_ = _level
				children.push(_children[i])
				if (_children[i].hasChildren) {children = [...children, ...getChildren(_children[i], _level + 1)]}
			}
			return children
		}
		return [this,	...getChildren(this)]
	}
}

export default Servicio