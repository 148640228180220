import {typeOrNull} from '../../functions/shared/local'
class VHF {
	/**
	 *
	 * @param {VHF} [vhf = null]
	 * @param {number} [vhf.primary]
	 * @param {number} [vhf.secondary]
	 */
	constructor(vhf = null) {
		try {
			this.primary = typeOrNull(vhf.primary, "number")
			this.secondary = typeOrNull(vhf.secondary, "number")
		} catch (e) {
			this.primary = null
			this.secondary = null
		}
	}
}

export default VHF