import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {adjustOrderDueDate} from "../../../../../globals/functions/client/serverFunctions"
import {
	dateToYYYYMMDD,
	fadingMessage,
	getTimeFormat,
	translate
} from "../../../../../globals/functions/client/localFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileInputDate from "../../../../shared/input/text/MobileInputDate"
import MobileTimeField from "../../../calendar/MobileTimeField"
import MobileInputText from "../../../../shared/input/text/MobileInputText"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {string} props.order.dueDate
 * @param {{date: string, time: string}} props.dueDate
 * @param {Account} props.account
 * @param {boolean} props.showProposeAdjustDueDateModal
 * @param {function} props.setShowProposeAdjustDueDateModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const ProposeAdjustOrderDueDateModal = props => {
	const order = props.order
	return props.showProposeAdjustDueDateModal ? <Modal id={`order-${order.id}-adjust-modal`} padding={"0vh"}
		title={translate(DICTIONARY.PROPOSE_DUE_DATE_CHANGE.X)}	icon={ICONS.CALENDAR_ALT}
		onClick={() => props.setShowProposeAdjustDueDateModal(false)}	body={<div style={{padding: "2vh"}}>
		<div className={"paralian-theme-level-2"} style={{padding: "1vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
			<div style={{padding: "2vh 0vh"}}>
				<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>{translate(DICTIONARY.CURRENT_DUE_DATE.X)}</div>
				<div style={{fontSize: "4vh", paddingTop: "1vh"}}>{props.dueDate.date}</div>
				<div style={{fontSize: "2vh", paddingBottom: "1vh"}}>{props.dueDate.time}</div>
			</div>
			<div style={{textAlign: "left", paddingLeft: "0.5vh"}}>{translate(DICTIONARY.PROPOSED_DUE_DATE.X)}</div>
			<div style={{padding: "1vh"}}>
				<MobileInputDate id={`order-${order.id}-adjust-date`} name={"date"} label={translate(DICTIONARY.DATE.X)}
					showLabel={false} className={"paralian-theme-level-1"} style={{margin: "1vh 0vw"}} borderRadius={"1vh"}
					bottomRadius={"1vh"} topRadius={"1vh"} autoComplete={"off"} required={true} defaultValue={order.dueDate}
					min={dateToYYYYMMDD(new Date())} max={null} autoFocus={false} left={{}} right={{}} form={"form"}/>
			</div>
			<div style={{padding: "1vh"}}>
				<MobileTimeField id={`order-${order.id}-adjust-time`} form={"form"} time={{hour: 8, minute: 0, xm: null}}
					className={"paralian-theme-level-1"} style={{fontSize: "3vh", height: "12vh"}}
					use24Hr={getTimeFormat(props.account) === "24Hr"}/>
			</div>
			<div style={{margin: "1vh"}}>
				<MobileInputText id={`order-${order.id}-adjust-reason`} label={translate(DICTIONARY.MESSAGE_TO_CUSTOMER.X)}
					required={true} pattern={"([a-zA-ZÀ-ž0-9-_\. ]){2,128}"} showLabel={true}
					className={"paralian-theme-level-1"}
					placeholder={translate(DICTIONARY.WHY.X)}/>
			</div>
			<div style={{padding: "1vh"}}>
				<MobileButtonGeneric id={"submit-propose-adjust"} type={"button"} className={"paralian-theme-level-1"}
					padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}}
					text={{content: translate(DICTIONARY.SUBMIT.X)}}
					onClick={async () => {
						try {
						 const dueDate = {
							 date: document.getElementById(`order-${order.id}-adjust-date-input`).value,
							 hour: document.getElementById(`order-${order.id}-adjust-time-hour-input`).value,
							 minute: document.getElementById(`order-${order.id}-adjust-time-minute-input`).value,
							 xm: getTimeFormat(props.account) === "24Hr" ? null :
								 document.getElementById(`order-${order.id}-adjust-time-format-input`).value
						 }
						 const message = document.getElementById(`order-${order.id}-adjust-reason-input`).value
						 const response = await adjustOrderDueDate(order.id, dueDate, message)
						 // console.log(response)
						 if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							 props.refreshOrders()
							 props.setShowProposeAdjustDueDateModal(false)
							 fadingMessage("propose-adjust-date-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
						 }
						} catch (e) {
						 console.log(e)
						}
					}
					}/>
			</div>

		</div>
</div>}/> : <></>
}

export default ProposeAdjustOrderDueDateModal