import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getFacilities} from "../../../../globals/functions/client/serverFunctions"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselPhysicalFacilitiesAndAmenitiesEdit from "./MobilePhysicalFacilitiesAndAmenitiesEdit"
import MobileIconExpandToText from "../MobileIconExpandToText"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"

const MobileVesselPhysicalFacilitiesAndAmenities = props => {
	const [edit, setEdit] = useState(false)
	const [text, setText] = useState("")
	const [facilities, setFacilities] = useState(new IndexedArray())
	useEffect(() => {
		try {
			getFacilities().then(response => {
				// console.log("response", response)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					setFacilities(new IndexedArray({obj: response.payload.obj}))
				}
			})
		} catch (e) {
			console.log(e)
		}
	}, [])
	const content = edit ?
		<MobileVesselPhysicalFacilitiesAndAmenitiesEdit selected={props.physicalFacilities} all={facilities}
			onSuccess={() => {
				props.refresh().then()
				setEdit(false)
			}}/> :
		<div>
			<div className={"paralian-mobile-info-card-header"} style={{marginBottom: "1vh"}}>
				{text}
			</div>
			{props.physicalFacilities.map(facility => {
				const facility_ = facilities.byIndex(facility)
				const icon = facility_ && ICONS[facility_.icon] ? [facility_.iconType, ICONS[facility_.icon][1]] :
					ICONS.CHECK_CIRCLE
				return <div key={`facility-icon-${facility}`} style={{display: "inline-block"}}>
					<MobileIconExpandToText label={translate(facility)} setText={setText}	icon={icon}/>
				</div>
			})
			}
		</div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.FACILITIES_AND_AMENITIES.X)]}/>
		{content}
	</div>
}

export default MobileVesselPhysicalFacilitiesAndAmenities