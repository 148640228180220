import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileAddressFields from "./MobileAddressFields"

/**
 *
 * @param {Object} props
 * @param {Address} props.address
 * @return {JSX.Element}
 * @constructor
 */
const MobileAddress = props => {
	const billing = props.address.isBilling ?
		<MobileFontAwesome id={"billing-address"} icon={ICONS.WALLET} label={translate(DICTIONARY.BILLING_ADDRESS.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("billingMessage", translate(DICTIONARY.BILLING_ADDRESS.X), translate(DICTIONARY.THIS_IS_BILLING_ADDRESS.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	const primary = props.address.isPrimary ?
		<MobileFontAwesome id={"primary-address"} icon={ICONS.STAR} label={translate(DICTIONARY.PRIMARY_ADDRESS.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("primaryMessage", translate(DICTIONARY.PRIMARY_ADDRESS.X), translate(DICTIONARY.THIS_IS_PRIMARY_ADDRESS.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	const mailing = props.address.isMailing ?
		<MobileFontAwesome id={"mailing-address"} icon={ICONS.MAILBOX} label={translate(DICTIONARY.MAILING_ADDRESS.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("mailingMessage", translate(DICTIONARY.MAILING_ADDRESS.X), translate(DICTIONARY.THIS_IS_SUPPORT_ADDRESS.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	const support = props.address.isSupport ?
		<MobileFontAwesome id={"support-address"} icon={ICONS.USER_HEADSET} label={translate(DICTIONARY.SUPPORT_ADDRESS.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("supportMessage", translate(DICTIONARY.SUPPORT_ADDRESS.X), translate(DICTIONARY.THIS_IS_SUPPORT_ADDRESS.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	const physical = props.address.isPhysical ?
		<MobileFontAwesome id={"physical-address"} icon={ICONS.MAP_MARKER} label={translate(DICTIONARY.PHYSICAL_ADDRESS.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("physicalMessage", translate(DICTIONARY.PHYSICAL_ADDRESS.X), translate(DICTIONARY.THIS_IS_PHYSICAL_ADDRESS.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	const verified = props.address.isVerified ?
		<MobileFontAwesome id={"verified-address"} icon={ICONS.CHECK} label={translate(DICTIONARY.VERIFIED.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("verifiedMessage", translate(DICTIONARY.VERIFIED.X), translate(DICTIONARY.THIS_ADDRESS_VERIFIED.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	const show = props.address.display ?
		<MobileFontAwesome id={"show-address"} icon={ICONS.EYE} label={translate(DICTIONARY.VERIFIED.X)}
			style={{margin: "0vh 0.5vh", fontSize: "3vh"}} onClick={() => {
			newMessage("showMessage", translate(DICTIONARY.SHARE_WITH_CUSTOMERS.X), translate(DICTIONARY.THIS_ADDRESS_SHARED.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}/> : <></>
	return <div className={"w3-animate-opacity"}>
		<div className={"paralian-mobile-info-card-header"}>
			<div style={{padding: "1vh 0vw"}}>
				{mailing}{physical}{primary}{verified}{billing}{support}{show}
			</div>
		</div>
		<MobileAddressFields address={props.address}/>
	</div>
}

export default MobileAddress