import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {updateOrgServicesPrices} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import getServicePricingInput from "./getServicePricingInput"

const submitOrgServicePricesUpdate = async (event, service, defaultPricingStructures, currency = null, onSubmit = null) => {
	// console.log("submitted")
	event.preventDefault()
	try {
		// console.log("pricingSpecs", pricingSpecs)
		const id = service.id
		// console.log("service", service)
		const currency_ = service.pricing.currency.code ? service.pricing.currency : currency
		// console.log("currency_", currency_)
		const spi = getServicePricingInput(service, currency_, defaultPricingStructures)
		// services.services[i]), services.currency, defaultPricingStructures
		let isActive = false
		try {
			isActive = document.getElementById(`${id}-isActive`).getAttribute("data-is-checked") === "true"
		} catch (e) {
			console.log(e)
		}
		if (spi.type === "COMPOSITE") {
			const confirmed = window.confirm(translate(DICTIONARY.COMPOSITE_PRICING_QUERY.X))
			if (confirmed) {
				// console.log("spi", spi)
				const response = await updateOrgServicesPrices([spi], false)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					if (onSubmit) {
						onSubmit(spi, false)
					}
				}
			}
		} else {
			// console.log("spi", spi)
			const response = await updateOrgServicesPrices([spi], isActive)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				if (onSubmit) {
					onSubmit(spi, isActive)
				}
			}
		}
	} catch (e) {
		console.log(e)
	}
}

export default submitOrgServicePricesUpdate