import Coordinates from './Coordinates'
import Country from './Country'
import State from './State'
import {typeOrNull} from '../../functions/shared/local'

class Area {
	/**
	 *
	 * @param {Area} [area = null]
	 * @param {Coordinates} [area.origin]
	 * @param {number} [area.distance] - in kilometers
	 * @param {boolean} [area.operatesBeyondContinent]
	 * @param {boolean} [area.operatesBeyondCountry]
	 * @param {boolean} [area.operatesBeyondState]
	 * @param {boolean} [area.operatesBeyondCity]
	 * @param {boolean} [area.welcomesVisitors]
	 * @param {Country} [area.country]
	 * @param {State} [area.state]
	 * @param {string} [area.city]
	 */
	constructor (area = null) {
		try {
			this.origin = new Coordinates(area.origin)
			this.radius = typeOrNull(area.radius, "number")
			this.operatesBeyondContinent = typeOrNull(area.operatesBeyondContinent, "boolean")
			this.operatesBeyondCountry = typeOrNull(area.operatesBeyondCountry, "boolean")
			this.operatesBeyondState = typeOrNull(area.operatesBeyondState, "boolean")
			this.operatesBeyondCity = typeOrNull(area.operatesBeyondCity, "boolean")
			this.welcomesVisitors = typeOrNull(area.welcomesVisitors, "boolean")
			this.country = new Country(area.country)
			this.state = new State(area.state)
			this.city = typeOrNull(area.city, "string")
		} catch (e) {
			this.origin = new Coordinates()
			this.radius = null
			this.operatesBeyondContinent = null
			this.operatesBeyondCountry = null
			this.operatesBeyondState = null
			this.operatesBeyondCity = null
			this.welcomesVisitors = null
			this.country = new Country()
			this.state = new State()
			this.city = null
		}
	}

	/**
	 * 
	 * @returns {number|null}
	 */
	get distanceInMiles() {
		return this.radius !== null ? 0.62137119223 * this.radius : null
	}

	/**
	 *
	 * @returns {string}
	 */
	get distanceKmAsText() {
		return this.radius !== null ? this.radius.toFixed().toLocaleString() : ""
	}

	/**
	 *
	 * @returns {string}
	 */
	get distanceMiAsText() {
		return this.radius !== null ? this.distanceInMiles.toFixed().toLocaleString() : ""
	}
}

export default Area