import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {ACCOUNT_TYPE, SERVER_RESPONSE_STATE, TERMS_OF_SERVICE} from "../../../../globals/constants/shared/enumerators"
import {getTermsAndConditions, sendTermsConditionsPoliciesAcceptance} from "../../../../globals/functions/client/serverFunctions"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import setLoggedIn from "../../../../globals/functions/client/mode/setLoggedIn"
import setAccountType from "../../../../globals/functions/client/mode/setAccountType"
import submitToServer from "../../../../globals/functions/client/submitToServer"
import MobileFontAwesome from "../../../main/MobileFontAwesome"

/**
 * @param {Object} props
 * @param {Object} props.next
 * @param {string} props.accountType
 * @param {boolean} props.login
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginNewTandC = props => {
	/* TODO: make it so that if a user either hasn't accepted their TOS or is out of date, tos will show upon login */
	const [source, setSource] = useState(0)
	const [disabled, setDisabled] = useState([true, true])
	const [sources, setSources] = useState([])
	const [showRefresh, setShowRefresh] = useState(false)
	const refresh = () => {
		getTermsAndConditions().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				// console.log(response.payload)
				const sources_ = response.payload.tos
				const termsOfUse = sources_.find(item => item.type === TERMS_OF_SERVICE.TERMS_OF_USE)
				const privacyPolicy = sources_.find(item => item.type === TERMS_OF_SERVICE.PRIVACY_POLICY)
				setSources([termsOfUse, privacyPolicy])
			} else {
				setShowRefresh(true)
			}
		})
	}
	useEffect(refresh, [])
	const onSuccess = () => {
		try {
			if (props.login) {
				setLoggedIn(true)
			} else {
				store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
			}
			switch (props.accountType) {
				case ACCOUNT_TYPE.ORGANIZATION:
					setAccountType(ACCOUNT_TYPE.ORGANIZATION)
					break
				case ACCOUNT_TYPE.PERSONAL:
					setAccountType(ACCOUNT_TYPE.PERSONAL)
					break
				case ACCOUNT_TYPE.EMPLOYEE:
					setAccountType(ACCOUNT_TYPE.EMPLOYEE)
					break
				default:
					break
			}
		} catch (e) {
			console.log(e)
		}
	}
	const onSubmit = async event => {
		try {
			if (document.getElementById("terms-check-box").checked) {
				await submitToServer(event, {tos: [1, 2], response: "ACCEPT"},
					sendTermsConditionsPoliciesAcceptance, onSuccess)
			} else {
				newMessage("terms", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.TERMS_AND_CONDITIONS_UNCHECKED.X),
					"w3-pale-yellow", ICONS.EXCLAMATION)
			}
		} catch (e) {
			console.log(e)
		}
	}

	const content = showRefresh ? <MobileFontAwesome id={"refresh"} icon={ICONS.SYNC} onClick={refresh}
		label={translate(DICTIONARY.REFRESH.X)} style={{fontSize: "10vh"}}/> :
		<iframe title={translate(DICTIONARY.TERMS_AND_CONDITIONS.X)}
			src={sources[source] ? sources[source].path : "about:blank"}
			style={{background: "white", minHeight: "50vh", border: "unset", borderRadius: "1vh", width: "100%"}}
			onError={() => {
				const disabled_ = [...disabled]
				disabled_[source] = true
				setDisabled(disabled_)
			}}
			onLoad={() => {
				const disabled_ = [...disabled]
				disabled_[source] = false
				setDisabled(disabled_)
			}}/>
	/* TODO ...including the cookie use statement and disclaimers */
	/* I agree with Paralian using my information, including the information I enter into the app, in the ways
			described in the Privacy Policy and summarized above. I understand that I can later withdraw this consent
			using my account settings or other tools for deleting my data or my account.
	 */
	const aboveSubmit = <div style={{display: "flex", padding: "1.5vh 4vh 0vh 4vh"}}>
		<input id={"terms-check-box"} style={{height: "8vh", width: "10vh", margin: "1vh"}} type={"checkbox"}
			name={"policies"} data-a={"false"} onChange={() => {
				const currentState = document.getElementById("terms-check-box").getAttribute("data-a")
				const newState = currentState === "false" ? "true" : "false"
				document.getElementById("terms-check-box").setAttribute("data-a", newState)
				document.getElementById("submit").disabled = newState === "false"
				setDisabled([newState === "false", newState === "false"])
		}}/>
		<label htmlFor={"terms-check-box"} style={{display: "none"}}>
			{translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_1.X) +
			translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_2.X) +
			translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_3.X) +
			translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_4.X)}
		</label>
		<span style={{textAlign: "left", margin: "auto"}}>
			{translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_1.X)}
			<b onClick={() => setSource(0)}>
				{translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_2.X)}
			</b>
			<b onClick={() => setSource(1)}>
				{translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_3.X)}
			</b>
			{translate(DICTIONARY.TERMS_AND_CONDITIONS_CHECK_BOX_4.X)}
		</span>
	</div>

	return <MobileLoginFormScaffolding header={null} content={content} disabled={disabled.some(item => item)}
		onSubmit={onSubmit} aboveSubmit={aboveSubmit}/>
}

export default MobileLoginNewTandC