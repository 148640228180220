import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileVesselEngineSpecsEdit from "./MobileVesselEngineSpecsEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileVesselEngineSpecs = props => {
	const [edit, setEdit] = useState(false)
	const [specs, setSpecs] = useState({power: props.engine.power, weight: props.engine.weight,
		rotation: props.engine.isCounterRotating, fuel: props.engine.fuel, classification: props.engine.classification})
	useEffect(() => setSpecs({power: props.engine.power, weight: props.engine.weight,
		rotation: props.engine.isCounterRotating, fuel: props.engine.fuel, classification: props.engine.classification}),
		[props.engine.power, props.engine.weight, props.engine.isCounterRotating, props.engine.fuel,
			props.engine.classification])
	const content = edit ?
		<MobileVesselEngineSpecsEdit id={props.id} engine={props.engine} units={props.units} onSuccess={specs => {
			props.refresh().then()
			setSpecs(specs)
			setEdit(false)
		}}/> : <>
			<MobileSubtitleAndValue subtitle={translate(DICTIONARY.CLASS.X)} value={{text: translate(specs.classification)}}
				units={props.units}/>
			<MobileSubtitleAndValue subtitle={translate(DICTIONARY.FUEL.X)} value={{text: translate(specs.fuel)}}
				units={props.units}/>
			<MobileSubtitleAndValue subtitle={translate(DICTIONARY.ROTATION.X)} units={props.units}
				value={{text: specs.rotation ? translate(DICTIONARY.COUNTER_ROTATING.X) : translate(DICTIONARY.STANDARD.X)}}/>
			<MobileSubtitleAndValue subtitle={translate(DICTIONARY.POWER.X)} value={{number: specs.power,
				units: "kW"}} units={props.units}/>
			<MobileSubtitleAndValue subtitle={translate(DICTIONARY.WEIGHT.X)} value={{number: specs.weight,
				units: "kg"}} units={props.units}/>
		</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.ENGINE_SPECS.X)]}/>
		{content}
	</div>
}

export default MobileVesselEngineSpecs