import React from "react"
import {DOCUMENT_CONTAINER, DOCUMENT_TYPE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.container - DOCUMENT_CONTAINER
 * @return {JSX.Element}
 * @constructor
 */
const MobileDocumentTypeDropdown = props => {
	const list = [
		{label: translate(DICTIONARY.CERTIFICATES.X), value: DOCUMENT_TYPE.CERTIFICATES},
	]
	if (props.container === DOCUMENT_CONTAINER.VESSELS) {
		list.push({label: translate(DICTIONARY.DOCUMENTATION.X), value: DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS},
			{label: translate(DICTIONARY.TITLE.X), value: DOCUMENT_TYPE.VESSEL_TITLES},
			{label: translate(DICTIONARY.REGISTRATION.X), value: DOCUMENT_TYPE.VESSEL_REGISTRATIONS})
	}
	if (props.container === DOCUMENT_CONTAINER.ORGANIZATIONS) {
		list.push({label: translate(DICTIONARY.BONDS.X), value: DOCUMENT_TYPE.BONDS},
			{label: translate(DICTIONARY.REGISTRATION.X), value: DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS})
	}
	if (props.container !== DOCUMENT_CONTAINER.ACCOUNTS) {
		list.push({label: translate(DICTIONARY.INSURANCE.X), value: DOCUMENT_TYPE.INSURANCE})
	}
	const label = props.label || translate(DICTIONARY.DOCUMENT_TYPE.X)
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-type`} className={className} size={"1"} left={left}
		label={label} multiple={false} topRadius={props.topRadius} showLabel={props.showLabel} right={props.right}
		style={style} list={list} placeholder={label}	name={"category"} form={props.form} autoFocus={false} required={true}
		bottomRadius={props.bottomRadius} dataId={list[0].value} onChange={() => {
		try {
		const element = document.getElementById(`${props.id}-type-input`)
		element.setAttribute("data-id", element.value)
		} catch (e) {
		console.log(e)
		}
		onChange()
		}}/>
}

export default MobileDocumentTypeDropdown