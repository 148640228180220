import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import {getFacilities} from "../../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MooringIcon from "../icons/MooringIcon"
import SlipIcon from "../icons/SlipIcon"
import TrailerIcon from "../icons/TrailerIcon"
import BoatyardIcon from "../icons/BoatyardIcon"
import MobileVesselStorageFacilitiesEdit from "./MobileVesselStorageFacilitiesEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import VesselStorageFacility from "../../../../globals/classes/shared/marinas/VesselStorageFacility"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"

const MobileVesselStorageFacilities = props => {
	const [edit, setEdit] = useState(false)
	const [info, setInfo] = useState(new VesselStorageFacility())
	const [facilities, setFacilities] = useState(new IndexedArray())
	useEffect(() => {
		try {
			getFacilities().then(response => {
				// console.log("response", response)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					const all = new IndexedArray({obj: response.payload.obj})
					setFacilities(new IndexedArray({array: all.values.filter(v => v.display === "VESSEL_STORAGE"),
						key: "type"}))
				}
			})
		} catch (e) {
			console.log(e)
		}
	}, [])
	let qt = info.quantity
	if (info.quantity > 0 && info.name === translate(DICTIONARY.BOAT_YARD.X) && props.units !== "SI") {
		qt = info.quantity/10.763910417
	}
	const content = edit ?
		<MobileVesselStorageFacilitiesEdit units={props.units} vesselStorageFacilities={props.vesselStorageFacilities}
			facilities={facilities} onSuccess={() => {
				props.refresh().then()
				setEdit(false)
		}}/> :
		<div>
			<div className={"paralian-mobile-info-card-header w3-animate-opacity"} style={{marginBottom: "1vh"}}>
				{`${info.name || ""}${qt > 0 ? ": " + qt : ""}`}
			</div>
			{info.capacity.hasMaxInfo ? <div style={{display: "inline-flex", maxWidth: "40vh"}}
				className={"w3-animate-opacity"}>
				<div style={{textAlign: "left", padding: "2vh 0vh"}}>
					<div style={{marginBottom: "1vh", textAlign: "center"}}><i>{translate(DICTIONARY.UPPER_LIMITS.X)}</i></div>
					{info.capacity.weight.max > 0 ? <div style={{display: "inline-flex", width: "100%"}}>
						<div style={{minWidth: "45%"}}>{translate(DICTIONARY.WEIGHT.X) + ": "}</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{convertToPreferredUnits(info.capacity.weight.max, "kg", props.units, 0)}
						</div>
					</div> : <></>}
					{info.capacity.length.max > 0 ? <div style={{display: "inline-flex", width: "100%"}}>
						<div style={{minWidth: "45%"}}>{translate(DICTIONARY.LENGTH.X) + ": "}</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{convertToPreferredUnits(info.capacity.length.max, "m", props.units, 1)}
						</div>
					</div>: <></>}
					{info.capacity.width.max > 0 ? <div style={{display: "inline-flex", width: "100%"}}>
						<div style={{minWidth: "45%"}}>{translate(DICTIONARY.WIDTH.X) + ": "}</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{convertToPreferredUnits(info.capacity.width.max, "m", props.units, 1)}
						</div>
					</div>: <></>}
					{info.capacity.depth.max > 0 ? <div style={{display: "inline-flex", width: "100%"}}>
						<div style={{minWidth: "45%"}}>{translate(DICTIONARY.DRAUGHT.X) + ": "}</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{convertToPreferredUnits(info.capacity.depth.max, "m", props.units, 1)}
						</div>
					</div>: <></>}
					{info.capacity.height.max > 0 ? <div style={{display: "inline-flex", width: "100%"}}>
						<div style={{minWidth: "45%"}}>{translate(DICTIONARY.BRIDGE_CLEARANCE.X) + ": "}</div>
						<div style={{textAlign: "right", width: "100%"}}>
							{convertToPreferredUnits(info.capacity.height.max, "m", props.units, 1)}
						</div>
					</div>: <></>}
				</div>
			</div> : <></>}
			<div style={{fontSize: "6vh"}}>
				{props.vesselStorageFacilities.MOORINGS.quantity > 0 ? <MooringIcon onClick={() => {
					const info = {...props.vesselStorageFacilities.MOORINGS, name: translate(DICTIONARY.MOORINGS.X)}
					setInfo(info)
				}}/> : <></>}
				{props.vesselStorageFacilities.SLIPS.quantity > 0 ? <SlipIcon onClick={() => {
					const info = {...props.vesselStorageFacilities.SLIPS, name: translate(DICTIONARY.SLIPS.X)}
					setInfo(info)
				}}/> : <></>}
				{props.vesselStorageFacilities.TRAILERS.quantity > 0 ? <TrailerIcon onClick={() => {
					const info = {...props.vesselStorageFacilities.TRAILERS, name: translate(DICTIONARY.TRAILERS.X)}
					setInfo(info)
				}}/> : <></>}
				{props.vesselStorageFacilities.RACKS.quantity > 0 ? <MobileFontAwesome icon={ICONS.FORKLIFT}
					style={{margin: "0vh 1vh"}} onClick={() => {
					const info = {...props.vesselStorageFacilities.RACKS, name: translate(DICTIONARY.RACKS.X)}
					setInfo(info)
				}}/> : <></>}
				{props.vesselStorageFacilities.CRADLES.quantity > 0 ? <BoatyardIcon onClick={() => {
					const info = {...props.vesselStorageFacilities.CRADLES, name: translate(DICTIONARY.CRADLES.X)}
					setInfo(info)
				}}/> : <></>}
				{props.vesselStorageFacilities.BOAT_YARD.quantity > 0 ? <MobileFontAwesome icon={ICONS.TOOLS}
					style={{margin: "0vh 1vh"}} onClick={() => {
					const info = {...props.vesselStorageFacilities.BOAT_YARD, name: translate(DICTIONARY.BOAT_YARD.X)}
					setInfo(info)
				}}/> : <></>}
			</div>
		</div>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.VESSEL_STORAGE.X)]}/>
		{content}
	</div>
}

export default MobileVesselStorageFacilities