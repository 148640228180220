import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {ICONS, DICTIONARY, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {LOGIN_TYPE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newAccountContactInfo} from "../../../../globals/functions/client/serverFunctions"
import {getLanguage, newMessage, setToStorage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputEmail from "../../../shared/input/text/MobileInputEmail"

/**
 *
 * @param {Object} props
 * @param {Object} props.next - page object
 * @param {Object} props.back - page object
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginNewEmail = props => {
	/* TODO: update onSubmit to generalized form */
	// console.log("MobileLoginNewEmail Rendered")
	useEffect(() => {
		// console.log("MobileLoginNewEmail useEffect")
		if (props.message) {
			newMessage("login-email-message", translate(DICTIONARY.LOGIN_EMAIL.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}}, [props.message])
	const onSubmit = async event => {
		// console.log("mobile login new email submit")
		event.preventDefault()
		const email = document.getElementById("login-email-input").value
		// console.log("mobile login new email submit email", email)
		setToStorage("email", email, "session")
		const response = await newAccountContactInfo(LOGIN_TYPE.LOGIN_EMAIL, email, null, getLanguage())
		// console.log(response)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			// window.sessionStorage.removeItem("invited")
			// window.sessionStorage.removeItem("type")
			store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
		} else if (response.status === SERVER_RESPONSE_STATE.FAIL) {
			try {
				newMessage("login-email-error-message", response.payload.title, response.payload.message,
					"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
			} catch (e) {
				console.log(e)
			}
		}
	}
	const label = translate(DICTIONARY.LOGIN_EMAIL.X)
	const content = <MobileInputEmail id={"login"} form={"form"} autoComplete={"email"} autoFocus={true}
		required={true} placeholder={label} name={"loginEmail"} label={label} showLabel={false} className={"paralian-theme-level-1-flipped"}
		left={{icon: ICONS.EMAIL, onClick: undefined}}
		right={{icon: ICONS.INFO_CIRCLE, onClick: () => newMessage("login", translate(TEXT_FIELD_PROPERTIES.eMail.hoverTitle),
			translate(TEXT_FIELD_PROPERTIES.eMail.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}}/>
	return <MobileLoginFormScaffolding header={null} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginNewEmail