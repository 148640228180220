import React, {useEffect} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {dateToYYYYMMDD,	getFromStorage,	newMessage, replaceInStorage, stringDateAndTimeToDate, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import DateAndTime from "./DateAndTime"
import Time from "../../../../globals/classes/shared/Time"

const StartDate = props => {
	const current = new Date()
	const session = getFromStorage("order", "session")
	const maxDate = session.dueDate
	const defaultTime = session.startTime ? new Time(session.startTime) :
		{hour: current.getHours(), minute: current.getMinutes(), xm: null}
	const defaultDate = session.startDate || dateToYYYYMMDD(new Date())
	useEffect(() => {
		if (!session.startDate) {replaceInStorage("order", "startDate", defaultDate, "session")}
		if (!session.startTime) {replaceInStorage("order", "startTime", defaultTime, "session")}
	}, [])
	return <DateAndTime id={"start"} defaultDate={defaultDate} defaultTime={defaultTime} use24Hr={props.use24Hr}
		minDate={dateToYYYYMMDD(new Date())} maxDate={maxDate} legend={<>
			{translate(DICTIONARY.START_DATE.X)}
			<MobileFontAwesome id={"start-date"} icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.START_DATE.X)}
				style={{paddingLeft: "1vh"}} onClick={() => newMessage("startDate", translate(DICTIONARY.START_DATE.X),
					translate(DICTIONARY.START_DATE_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}/></>}
		setTime={v => {
			// console.log(v)
			const session = getFromStorage("order", "session")
			const dueTime = new Time(session.dueTime)
			const due = stringDateAndTimeToDate(session.dueDate + "-" + dueTime.as24hrString)
			const startTime = new Time(v)
			const start = stringDateAndTimeToDate(session.startDate + "-" + startTime.as24hrString)
			if (due && start && due.getTime() > start.getTime()) {
				replaceInStorage("order", "startTime", v, "session")
				props.setDisabled(false)
			} else if (due && start && due.getTime() <= start.getTime()) {
				newMessage("start-time-error", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.START_DATE_ERROR.X),
					"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
				props.setDisabled(true)
			}
		}}
		setDate={v => {
			const session = getFromStorage("order", "session")
			const dueTime = new Time(session.dueTime)
			const due = stringDateAndTimeToDate(session.dueDate + "-" + dueTime.as24hrString)
			const startTime = new Time(session.startTime)
			const start = stringDateAndTimeToDate(v + "-" + startTime.as24hrString)
			if (due && start && due.getTime() > start.getTime()) {
				replaceInStorage("order", "startDate", v, "session")
				props.setDisabled(false)
			} else if (due && start && due.getTime() <= start.getTime()) {
				newMessage("start-time-error", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.START_DATE_ERROR.X),
					"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
				props.setDisabled(true)
			}
		}}/>
}

export default StartDate