const googleMapsCleanup = mapScripts => {
	return () => {
		if (mapScripts.scriptBody) {
			try {
				document.head.removeChild(mapScripts.scriptBody)
			} catch (e) {}
		}
		if (mapScripts.scriptImport) {
			try {
				document.head.removeChild(mapScripts.scriptImport)
			} catch (e) {}
		}
	}
}

export default googleMapsCleanup