import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {getMarinaIndex, getVesselIndex, getVesselOwnership, setToStorage, translate} from "../../../globals/functions/client/localFunctions"
import MobileMyFleet from "./fleet/MobileMyFleet"
import MobileVessel from "./MobileVessel"
import Vessel from "../../../globals/classes/shared/Vessel"
/**
 *
 * @param {Object} props
 * @param {Account} props.account
 * @param {Organization} props.organization
 * @param {{owned: Vessel[], customer: Vessel[]}} props.vessels
 * @param {Organization[]} props.marinas
 * @param {function} props.refreshAccount
 * @param {function} props.refreshVessels
 * @return {JSX.Element}
 * @constructor
 */
const MobileVessels = props => {
	const top = "4.4vh"
	const stateVesselIndex = getVesselIndex()
	const state = {vessel: {index: stateVesselIndex}, marina: {index: getMarinaIndex()}}
	const [ownership, setOwnership] = useState(getVesselOwnership())
	const [index, setIndex] = useState(stateVesselIndex < props.vessels.owned.length && stateVesselIndex > -1 ?
		stateVesselIndex : 0)
	const vessel = new Vessel(ownership === "customer" ? props.vessels.customer[index] : props.vessels.owned[index])
	const isAdmin = props.account.isAdmin
	const isEmployeeOrAdmin = props.account.isAdminOrEmployee
	const isPersonal = props.account.isPersonal
	const refreshVessels = props.refreshVessels
	const refreshAccount = props.refreshAccount
/*	if ([...props.vessels.owned, ...props.vessels.customer].length === 1 && vessel.id === null) {
		return <div className={"w3-display-middle w3-animate-opacity"}>
			<MobileFontAwesome icon={ICONS.SYNC} style={{fontSize: "15vh"}} onClick={refreshVessels}/>
		</div>
	}*/
	const fleet = <MobileMyFleet primary={props.account.preferences.defaults.vessel} vessels={props.vessels.owned} zIndex={13}
		account={props.account} refreshAccount={refreshAccount} organization={props.organization} marinas={props.marinas}
		top={props.vessels.owned.length > 0 ? top : "0vh"} label={isEmployeeOrAdmin ? translate(DICTIONARY.OUR_FLEET.X) : null}
		refresh={refreshVessels} canEdit={isAdmin || isPersonal} setIndex={id => {
				try {
					const index_ = props.vessels.owned.findIndex(vessel => vessel.id === id)
					state.vessel.id = id
					state.vessel.index = index_ !== -1 ? index_ : index
					state.vessel.ownership = "owned"
					setToStorage("state", state)
					setOwnership("owned")
					setIndex(index_ !== -1 ? index_ : index)
				} catch (e) {
					console.log(e)
				}
		}} isOrganization={isEmployeeOrAdmin} areCustomers={false}/>
	const customerVessels = isEmployeeOrAdmin ? <MobileMyFleet vessels={props.vessels.customer} zIndex={14} areCustomers={true}
		top={props.vessels.customer.length > 0 ? top : "0vh"} label={translate(DICTIONARY.CUSTOMER_VESSELS.X)} canInvite={true}
		refresh={refreshVessels} refreshAccount={refreshAccount} canEdit={false} setIndex={id => {
			try {
				const index_ = props.vessels.customer.findIndex(vessel => vessel.id === id)
				state.vessel.id = id
				state.vessel.index = index_ !== -1 ? index_ : index
				state.vessel.ownership = "customer"
				setToStorage("state", state)
				setOwnership("customer")
				setIndex(index_ !== -1 ? index_ : index)
			} catch (e) {
				console.log(e)
			}
		}}/> : <></>
	const profile = vessel.id ? <MobileVessel vessel={vessel} marinas={props.marinas} account={props.account}
		organization={props.organization} refreshAccount={props.refreshAccount} refreshVessels={props.refreshVessels}/> : <></>
	return <>
		<div id={"vessels-container"} className={"w3-display-middle w3-animate-opacity paralian-mobile-content " +
			"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}>
			{profile}
			{fleet}
			{customerVessels}
		</div>
	</>
}

export default MobileVessels