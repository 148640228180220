import { isObject, typeOrNull } from '../../functions/shared/local';

class Acceptance {
	/**
	 *
	 * @param {Acceptance} [acceptance = null]
	 * @param {string|number|Date} [acceptance.date]
	 * @param {string} [acceptance.ip]
	 */
	constructor(acceptance = null) {
		if (isObject(acceptance)){
			this.date = typeOrNull(acceptance.date, "date")
			this.ip = typeOrNull(acceptance.ip, "string")
		} else {
			this.date = null
			this.ip = null
		}
	}
}

export default Acceptance;