import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {ACCOUNT_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileEmployeeServiceAccessFields from "./MobileEmployeeServiceAccessFields"

const MobileEmployeeFields = props => {
	const name = props.employee.contact.name.given
	const username = props.employee.status === ACCOUNT_STATE.NEW ||
		props.employee.status === ACCOUNT_STATE.INCOMPLETE ? "" : props.employee.username
	const title = props.employee.title
	const accessFields = props.employee.services.map(service =>
		<MobileEmployeeServiceAccessFields key={`${props.employee.id}-${service.service}`} id={props.employee.id}
			service={service} services={props.services}/>)
	return <>
		<div style={{fontSize: "3vh"}}>{name}</div>
		<div style={{fontSize: "2.5vh"}}><i>{title}</i></div>
		<div style={{fontSize: "1.5vh"}}>{username}</div>
		<div style={{padding: "1vh", textAlign: "center", fontSize: "3vh"}}	className={`w3-animate-opacity`}>
		<span>
			<MobileFontAwesome icon={props.employee.status === ACCOUNT_STATE.ACTIVE ? ICONS.CHECK :
				[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(props.employee.status) ?
					ICONS.BAN :	ICONS.EXCLAMATION_TRIANGLE} style={{paddingRight: "1.5vh"}}/>
			{props.employee.status === ACCOUNT_STATE.ACTIVE ? translate(DICTIONARY.ACTIVE.X) :
				[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(props.employee.status) ?
					translate(DICTIONARY.DEACTIVATED.X) : translate(DICTIONARY.PENDING.X)}
		</span>
		</div>
		{[ACCOUNT_STATE.CLOSED, ACCOUNT_STATE.DISABLED, ACCOUNT_STATE.LOCKED].includes(props.employee.status) ? <></> :
			<div>{accessFields}</div>}
	</>
}

export default MobileEmployeeFields