import React, {useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import {addEngine} from "../../../../globals/functions/client/serverFunctions"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import MobileInputEngineBrandDatalist from "./MobileInputEngineBrandDatalist"
import MobileInputEngineModelDatalist from "./MobileInputEngineModelDatalist"
import MobileInputYear from "../../../shared/input/text/MobileInputYear"
import MobileInputPower from "../MobileInputPower"
import MobileInputWeight from "../MobileInputWeight"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileEngineClassDropdown from "../../../shared/input/dropdown/MobileEngineClassDropdown"
import MobileFuelDropdown from "../../../shared/input/dropdown/MobileFuelDropdown"
import MobileEngineRotationDropdown from "../../../shared/input/dropdown/MobileEngineRotationDropdown"
import MobileInputGeneric from "../../../shared/input/MobileInputGeneric"
import Engine from "../../../../globals/classes/shared/vessels/Engine"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel Id
 * @param {function} props.onUndo
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselEngineAdd = props => {
	const [year, setYear] = useState(null)
	const [brand, setBrand] = useState(null)
	const [classification, setClassification] = useState(null)
	const [fuel, setFuel] = useState(null)
	const [rotation, setRotation] = useState(null)
	const onSubmit = async event => {
		try {
			event.preventDefault()
			const label = document.getElementById(`${props.id}-engine-label-input`).value
			const brand = document.getElementById(`${props.id}-engine-brand-input`).value
			const model = document.getElementById(`${props.id}-engine-model-input`).value
			const year = document.getElementById(`${props.id}-engine-year-input`).value
			const hours_ = document.getElementById(`${props.id}-engine-hrs-input`).value
			const hours = Number(hours_.replace(/[a-zA-Z,. ]/g,''))
			const serialNumber = document.getElementById(`${props.id}-engine-sn-input`).value
			const modelNumber = document.getElementById(`${props.id}-engine-mn-input`).value
			const power_ = Number(document.getElementById(`${props.id}-engine-power-whole-input`).value.replace(/[a-zA-Z,. ]/g,''))
			const power = Number(convertToPreferredUnits(power_,
				props.units === "SI" ? "kW" : "hp", "SI", 4, false, false))
			const classification = document.getElementById(`${props.id}-engine-class-input`).value
			const fuel = document.getElementById(`${props.id}-engine-fuel-input`).value
			const rotation = document.getElementById(`${props.id}-engine-rotation-input`).value === "COUNTER_ROTATING"
			const weight_ = Number(document.getElementById(`${props.id}-engine-weight-whole-input`).value.replace(/[a-zA-Z,. ]/g,''))
			const weight = Number(convertToPreferredUnits(weight_,
				props.units === "SI" ? "kg" : "lb", "SI", 4, false, false))
			const response = await addEngine(props.id, label, brand, model, year, serialNumber, modelNumber, classification,
				fuel, rotation, power, weight, hours)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess(new Engine({id: response.payload, label: label, brand: brand, model: model, year: year,
					serialNumber: serialNumber, modelNumber: modelNumber, classification: classification, fuel: fuel,
					rotation: rotation, power: power, weight: weight, hours: hours}))
			}
		} catch (e) {
			console.log(e)
		}
	}
	const form = `${props.id}-engine-add-form`
	const className = "paralian-theme-level-1"
	const style = {margin: "1vh 0vh"}
	const content =  <>
		<MobileInputText id={`${props.id}-engine-label`} name={`${props.id}-engine-label`}
			showLabel={true} maxLength={"24"} required={true} placeholder={translate(DICTIONARY.LABEL.X)} autoFocus={false}
			label={translate(DICTIONARY.LABEL.X)} className={className} style={style}
			form={form}	autoComplete={"off"}/>
		<MobileInputEngineBrandDatalist required={true} label={translate(DICTIONARY.BRAND.X)} year={year}
			placeholder={translate(DICTIONARY.BRAND.X)} name={"brand"} className={className} defaultValue={brand}
			id={`${props.id}-engine-brand`} showLabel={true} autoFocus={false} setBrand={setBrand}
			form={form} autoComplete={"off"} style={style}/>
		<MobileInputEngineModelDatalist required={true} label={translate(DICTIONARY.MODEL.X)} year={year} brand={brand}
			placeholder={translate(DICTIONARY.MODEL.X)} name={"model"} className={className}
			id={`${props.id}-engine-model`} showLabel={true} autoFocus={false}
			form={form} autoComplete={"off"} style={style}/>
		<MobileInputYear id={`${props.id}-engine-year`} name={"year"} showLabel={true} style={style}
			form={form} autoComplete={"off"} required={false}
			placeholder={translate(DICTIONARY.MODEL_YEAR.X)} autoFocus={false} label={translate(DICTIONARY.MODEL_YEAR.X)}
			className={className} onChange={year => {if (year.length === 4) {setYear(year)}}}/>
		<div style={{marginBottom : "1vh"}}>
			<MobileInputGeneric type={"long"} id={`${props.id}-engine-hrs`} name={`${props.id}-engine-hrs`}
				form={`${props.id}-engine-hrs-form`} autoFocus={true} maxLength={5} autoComplete={"off"} required={false}
				placeholder={translate(DICTIONARY.HOURS.X)} min={"0"} max={"99999"} step={1}	style={{textAlign: "center"}}
				label={translate(DICTIONARY.HOURS.X)} showLabel={true} className={"paralian-theme-level-1"}/>
		</div>
		<MobileInputText id={`${props.id}-engine-sn`} name={`${props.id}-engine-sn`} autoFocus={false}
			showLabel={true} maxLength={"24"} required={true} placeholder={translate(DICTIONARY.SERIAL_NUMBER.X)}
			label={translate(DICTIONARY.SERIAL_NUMBER.X)} className={className} style={style}
			form={form}	autoComplete={"off"}/>
		<MobileInputText id={`${props.id}-engine-mn`} name={`${props.id}-engine-mn`} autoFocus={false}
			showLabel={true} maxLength={"24"} required={true} placeholder={translate(DICTIONARY.MODEL_NUMBER.X)}
			label={translate(DICTIONARY.MODEL_NUMBER.X)} className={className} style={style}
			form={form}	autoComplete={"off"}/>
		<MobileEngineClassDropdown id={props.id} form={form} autoFocus={false} required={true} className={className}
			showLabel={true} style={style} defaultValue={classification} setValue={setClassification}/>
		<MobileFuelDropdown id={props.id} className={className} form={form}	autoFocus={false} required={true}
			showLabel={true} style={style} defaultValue={fuel} setValue={setFuel}/>
		<MobileEngineRotationDropdown id={props.id} form={form} autoFocus={false} required={true} className={className}
			showLabel={true} style={style} defaultValue={rotation} setValue={setRotation}/>
		<div style={{marginBottom: "1vh"}}>
			<div style={{textAlign: "left", padding: "0.5vh"}}>{translate(DICTIONARY.POWER.X)}</div>
			<div style={style} className={`paralian-theme-level-1 w3-animate-opacity`}>
				<MobileInputPower id={`${props.id}-engine-power`} label={translate(DICTIONARY.POWER.X)}
					form={form} units={props.units} className={className}/>
			</div>
		</div>
		<div style={{marginBottom: "1vh"}}>
			<div style={{textAlign: "left", padding: "0.5vh"}}>{translate(DICTIONARY.WEIGHT.X)}</div>
			<div style={style} className={`paralian-theme-level-1 w3-animate-opacity`}>
				<MobileInputWeight id={`${props.id}-engine-weight`} units={props.units} showLabel={true}
					form={form} label={translate(DICTIONARY.WEIGHT.X)} className={className}/>
			</div>
		</div>

	</>
	return <MobileInputForm id={`${props.id}-engine-add`} content={content} onSubmit={onSubmit} style={{zIndex: props.zIndex}}/>
}

export default MobileVesselEngineAdd