import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class PhoneNumber extends TextInputFilter {
	minLength = 8
	maxLength = 22
	regexp = /[^0-9+(). -]/gi

	get numbersOnly() {
		const cleaned = this.input_ ? `${this.input_}`.replace(/[^0-9]/gi, "") : null
		return this.checkInput(cleaned) ? cleaned : null
	}
}

export default PhoneNumber;