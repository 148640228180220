import React from "react"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {Object} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {boolean} props.showLabel
 * @param {boolean} [props.autoComplete = "off"]
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputCity = props => {
	const style = props.style || {margin: "0.5vh 0vh"}
	const autoComplete = props.autoComplete === true ? "address-city" : "off"
	const label = translate(DICTIONARY.CITY.X)
	return <MobileInputText id={`${props.id}-city`} form={props.form} name={"city"} placeholder={label} style={style}
		label={label} showLabel={props.showLabel} autoComplete={autoComplete} autoFocus={false}	required={false}
		pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX}
		title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE} defaultValue={props.defaultValue} className={props.className}/>
}

export default MobileInputCity