import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {deleteMessage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputSlider from "../../../shared/input/switch/MobileInputSlider"
import MobileInputName from "../../../shared/input/text/MobileInputName"
import MobileGenericDropdown from "../../../shared/input/dropdown/MobileGenericDropdown"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"

/**
 *
 * @param {Object} props
 * @param {Object} props.owner
 * @param {function} props.setOwner
 * @param {function} props.setPage
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselInviteOwnerOrgQuestion = props => {
	const [orgOwned, setOrgOwned] = useState(false)
	const [action, setAction] = useState(1)
	useEffect(() => {
		newMessage("invite-additional-info-message", translate(DICTIONARY.ADDITIONAL_INFO.X),
			translate(DICTIONARY.INVITE_CUSTOMER_ADDITONAL_INFO_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
		return () => {
			try {
				deleteMessage("invite-additional-info-message", false)
			} catch (e) {
				console.log(e)
			}
		}
	}, [])
	const onSubmit = async event => {
		event.preventDefault()
		const name = document.getElementById("owner-name-input").value
		const owner = {...props.owner}
		owner.name = name
		owner.isOrganization = orgOwned
		owner.action = action
		props.setOwner(owner)
		props.setPage("BMY")
	}
	const actionsList = [
		{id: 1, label: translate(DICTIONARY.ADD_AS_PERMANENT_CUSTOMER.X)},
		{id: 2, label: translate(DICTIONARY.ADD_AS_TEMPORARY_CUSTOMER.X)},
		{id: 3, label: translate(DICTIONARY.SEND_INVITE_ONLY.X)}
	]
	const content = <div style={{padding: "2vh"}}>
		<div style={{height: "5vh"}} className={"w3-display-container"}>
			<MobileInputSlider id={"org-owned"} label={translate(DICTIONARY.THIS_VESSEL_OWNED_BY_ORGANIZATION.X)} height={"3vh"}
				form={"add-info-form"} className={"w3-display-middle"} labelStyle={{paddingLeft: "4vh", textAlign: "left"}}
				defaultChecked={false} width={"8vh"} onChange={() => setOrgOwned(!orgOwned)} padding={"0.25vh 0vh 0.25vh 2vh"}/>
		</div>
		<div style={{margin: "2vh 0vh"}}>
			<MobileInputName id={"owner"} form={"add-info-form"} required={true} className={"paralian-theme-level-2"}
				label={translate(orgOwned ? DICTIONARY.ORGANIZATION_NAME.X : DICTIONARY.OWNERS_NAME.X)}
				autoComplete={"off"} showLabel={true}/>
		</div>
		<MobileGenericDropdown id={"providers"} options={actionsList} className={"paralian-theme-level-2"} label={"Action"}
			form={"add-info-form"} defaultValue={action - 1} showLabel={true} onChange={id => setAction(id)}/>
	</div>
	return <MobileInputForm id={"add-info"} content={content} className={" "} onSubmit={onSubmit} style={{paddingBottom: "2vh"}}/>
}

export default MobileVesselInviteOwnerOrgQuestion