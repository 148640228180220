import React, {useEffect, useState} from "react"
import {ACCOUNT_STATE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import {deleteOrgAdmin, editOrgEmployee} from "../../../globals/functions/client/serverFunctions"
import MobileEmployeeEdit from "./MobileEmployeeEdit"
import MobileEmployeeFields from "./MobileEmployeeFields"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import getEmployeeInput from "./getEmployeeInput"
import submitToServer from "../../../globals/functions/client/submitToServer"
/* TODO: create Employee class */
/**
 *
 * @param {Object} props
 * @param {Account} props.employee
 * @param {Servicio[]} props.services
 * @param {function} props.onEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileEmployee = props => {
	const [edit, setEdit] = useState(false)
	const [employee, setEmployee] = useState(props.employee)
	useEffect(() => {setEmployee(props.employee)}, [props.employee])
	const id = props.employee.id
	const login = props.employee.contact.contactInfo.emails.primary.address
	const name = props.employee.contact.name.given
	const username = props.employee.status === ACCOUNT_STATE.NEW ||
	props.employee.status === ACCOUNT_STATE.INCOMPLETE ? "" : props.employee.username
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const input = getEmployeeInput({id: id, services: props.employee.services.map(v => {
					v.id = v.itemCategory
					return v
				})})
			// console.log("input", input)
			await submitToServer(event, input, editOrgEmployee, () => {
				props.refresh().then()
				setEdit(false)
			})
		} catch (e) {
			console.log(e)
		}
	}
	const onDelete = async () => {
		const confirmed = window.confirm(`${translate(DICTIONARY.DELETE_ACCOUNT_FOR_QUERY.X)}${username || name}?`)
		if (confirmed) {
			const response = await deleteOrgAdmin(props.employee.id)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.refresh().then()
				setEdit(false)
			}
		}
	}
	const content = edit && props.canEdit ? <MobileEmployeeEdit employee={employee} services={props.services}
		onUndo={() => setEdit(false)} onSubmit={onSubmit} onEdit={() => {
			props.refresh().then()
			setEdit(false)
		}}/> :
		<MobileEmployeeFields employee={employee} services={props.services} setEdit={() => setEdit(true)}/>
	/* TODO: ...and all children version of service access where for example an employee has the same access to all
		vessel maintenance services provided by the company */
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} onDelete={onDelete} labels={[login]}/>
		{content}
	</div>
}

export default MobileEmployee