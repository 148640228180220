import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getCurrencies} from "../../../globals/functions/client/serverFunctions"
import {getLanguage, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import Currency from "../../../globals/classes/shared/Currency"
import Country from "../../../globals/classes/shared/Country"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {Currency} props.currency
 * @param {Country} props.country
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {boolean} props.appendToLabel
 * @param {boolean} props.showSymbol
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCurrencyDropdown = props => {
	const [currencies, setCurrencies] = useState({index: 0, list: [{label: "US Dollar", value: 0, dataId: "USD", symbol: 36}]})
	const currency_ = new Currency(props.currency)
	const country_ = new Country(props.country)
	const defaultValue = currency_.code || country_.currency.code || "USD"
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	const label = props.showLabel || !props.appendToLabel ? "" : `${translate(DICTIONARY.CURRENCY.X)}: `
	useEffect(() => {
		getCurrencies(country_.code || "USA").then(response => {
			try {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					// console.log(response)
					const translator = new Intl.DisplayNames([getLanguage()], {type: "currency"})
					const list = []
					for (let i = 0; i < response.payload.length; i++) {
						const symbol = response.payload[i].symbol ?
							Number(response.payload[i].symbol) : 36
						list.push({label: `${label}${translator.of(response.payload[i].code) || response.payload[i].name}`,
							value: i, symbol: symbol,	dataId: response.payload[i].code})
					}
					// console.log(list)
					const index = list.findIndex(item => item.dataId === defaultValue)
					setCurrencies({index: index === -1 ? 0 : index, list: [...list]})
				}
			} catch (e) {
				console.log(e)
			}
		})
	}, [country_.code, defaultValue, label])
	return <MobileInputGeneric type={"dropdown"} id={props.id} className={className} size={"1"}
		style={{fontWeight: props.showSymbol ? "bold" : "normal", ...props.style}} dataB={currencies.list[currencies.index].label}
		label={translate(DICTIONARY.CURRENCY.X)} multiple={false} dataId={currencies.list[currencies.index].dataId}
		form={props.form} autoFocus={false} required={true} list={currencies.list} showLabel={props.showLabel}
		defaultValue={currencies.index} bottomRadius={props.bottomRadius} dataIndex={currencies.index} left={props.left}
		dataA={currencies.list[currencies.index].symbol} topRadius={props.topRadius} name={"currency"} onChange={() => {
			try {
				const element = document.getElementById(`${props.id}-input`)
				const code = currencies.list[element.value].dataId
				const name = currencies.list[element.value].label
				const symbol = currencies.list[element.value].symbol
				element.setAttribute("data-id", code)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", symbol)
				element.setAttribute("data-b", name)
				setCurrencies({list: [...currencies.list], index: element.value})
				onChange(new Currency({code: code, name: name, symbol: symbol}))
			} catch (e) {
				console.log(e)
			}
		}}/>

}

export default MobileInputCurrencyDropdown