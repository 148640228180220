import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileServiceToggle from "./MobileServiceToggle"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Servicio from "../../../globals/classes/shared/Servicio"
/**
 *
 * @param {Object} props
 * @param {Servicio} props.service
 * @param {React.HTMLAttributes.className} props.className
 * @param {Currency} props.currency
 * @param {IndexedArray} props.defaultPricingStructures
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileService = props => {
	const [edit, setEdit] = useState(false)
	const [service, setService] = useState(new Servicio(props.service))
	useEffect(() => {setService(new Servicio(props.service))}, [props.service])
	const state = !edit ?
		<div style={{margin: "1vh", textAlign: "center", fontSize: "3vh", color: !service.isActive ? "black" : null,
			backgroundColor: !service.isActive ? "orange" : null,
			padding: !service.isActive ? "1vh 0vh" : null}} className={"w3-animate-opacity"}>
			<MobileFontAwesome icon={service.isActive ? ICONS.CHECK : ICONS.BAN} style={{paddingRight: "1.5vh"}}/>
			{service.isActive ? translate(DICTIONARY.ACTIVE.X) : translate(DICTIONARY.DEACTIVATED.X)}
		</div> : <></>
	// console.log(props.service, service, service.label, service.labels, service.labelsAsString)
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit} subtitle={service.labelsAsString}
			labels={[<b style={{opacity: 1}}>{translate(service.label)}</b>]}/>
		<MobileServiceToggle service={service} edit={edit} setService={setService} currency={props.currency}
			defaultPricingStructures={props.defaultPricingStructures}
			onUndo={() => setEdit(!edit)}
			onSubmit={(servicePricingInput, isActive) => {
				if (props.refresh) {props.refresh().then()}
				const service_ = new Servicio(service)
				service_.isActive = isActive
				service_.pricing = servicePricingInput.pricing
				service_.currency = service.currency
				setService(service_)
				setEdit(false)
		}}/>
		{state}
	</div>
}

export default MobileService