import {LOGIN_TYPE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {PAGE_STATES} from "../../../globals/constants/client/constants"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {checkUsername} from "../../../globals/functions/client/serverFunctions"
import {getFromStorage, setToStorage} from "../../../globals/functions/client/localFunctions"
import respondToUsernameCheck from "../shared/respondToUsernameCheck"

/**
 * Used during initial login
 * Verifies login username/phone/email against data in database and proceeds accordingly
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @constructor
 */
const confirmLoginUsername = async event => {
	event.preventDefault()
	try {
		const levelOne = store.getState().window.current.levelOne
		const element = document.getElementById("username-input")
		const value = element.value
		const attribute = element.getAttribute("type")
		let field
		switch (attribute) {
			case "email":
				field = LOGIN_TYPE.LOGIN_EMAIL
				break
			case "tel":
				field = LOGIN_TYPE.LOGIN_PHONE
				break
			default:
				field = LOGIN_TYPE.USERNAME
		}
		// userName field may contain username, primary email, or primary phone
		const response = await checkUsername(value, field)
		// console.log(response)
		if (response.status !== SERVER_RESPONSE_STATE.BAD) {
			const form = getFromStorage("form", "session") || {loginAttempt: 0}
			form.loginAttempt = form.loginAttempt ? form.loginAttempt + 1 : 1
			respondToUsernameCheck(response.payload.status, true, () => {
				setToStorage("form", {...response.payload, loginAttempt: form.loginAttempt,
					userIdProvided: true, userIdFound: response.status === SERVER_RESPONSE_STATE.SUCCESS}, "session")
				store.dispatch({type: actions.SET_CURRENT_WINDOW,
				values: {window: PAGE_STATES[levelOne].LOGIN.EXISTING_USER.PASSWORD.X}})
			}, () => {
				setToStorage("form", form, "session")
			})
		}
	} catch (e) {
		console.log(e)
	}
}

export default confirmLoginUsername