import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../globals/functions/client/localFunctions"
import {getServices} from "../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "./MobileFontAwesome"
import MobileInputGeneric from "../shared/input/MobileInputGeneric"
import MobileGenericDropdown from "../shared/input/dropdown/MobileGenericDropdown"
import MobileVerificationStatusCard from "./MobileVerificationStatusCard"
import Modal from "../../globals/components/Modal"
import IndexedArray from "../../globals/classes/shared/IndexedArray"
import Servicio from "../../globals/classes/shared/Servicio"
/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MobileVerifications = props => {
	// console.log("organization", props.organization)
	const [filteredSorted, setFilteredSorted] = useState({filtered: [],	sort: {order: "DESC", key: "status"},
		filter: {organization: -1, category: -1, status: -1}})
	const [services, setServices] = useState(new IndexedArray())
	const [sort, setSort] = useState(false)
	const [filter, setFilter] = useState(false)
	const [selected, setSelected] = useState(null)
	// console.log("filteredSorted", filteredSorted)
	const flattenRequests = r => {
		try {
			const r_ = []
			for (let i = 0; i < r.length; i++) {
				const item_ = r[i]
				const organization_ = item_.organization
				const categories = item_.categories.map(v => v.id)
				const states = Array.from(new Set(item_.categories.map(v => v.status)))
				const resolved = states.every(v => v ==="APPROVED" || v === "DENIED")
				r_.push({organization: organization_, states: states, categories: categories, resolved: resolved})
			}
			return r_
		} catch (e) {
			console.log(e)
		}
	}
	useEffect(() => {
		getServices().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setServices(new IndexedArray({obj: response.payload.asIndexedArray.obj}))
			}})},[])
	useEffect(() => {
		const updated = {...filteredSorted}
		updated.filtered = flattenRequests(props.organization.serviceApprovals.received)
		setFilteredSorted(updated)
	}, [props.organization])
	const organizationIndexedRequests = new IndexedArray({array: props.organization.serviceApprovals.received, key: "organization"})
	// console.log("organizationIndexedRequests", organizationIndexedRequests)
	const all = flattenRequests(props.organization.serviceApprovals.received)
	// console.log("all", all)
	const ORDER_SORT_OPTIONS = [
		{
			label: translate(DICTIONARY.CATEGORY.X),
			value: "category"
		},
		{
			label: translate(DICTIONARY.STATUS.X),
			value: "status"
		},
		{
			label: translate(DICTIONARY.ORGANIZATION.X),
			value: "organization"
		}
	]
	const compare = (a, b, key) => {
		let valA, valB
		switch (key) {
			case "organization":
				valA = a.organization
				valB = b.organization
				break
			case "category":
				valA = a.category
				valB = b.category
				break
			case "status":
				valA = a.status
				valB = b.status
				break
			default:
				valA = a[key]
				valB = b[key]
		}
		return (valA > valB) ? 1 : -1
	}
	const _filter_ = filteredSortedCopy => {
		return all.filter(v => {
			let org = true, stat = true, cat = true
			if (filteredSortedCopy.filter.organization !== -1) {
				org = v.organization === filteredSortedCopy.filter.organization
			}
			if (filteredSortedCopy.filter.status !== -1) {
				stat = v.states.includes(filteredSortedCopy.filter.status)
			}
			if (filteredSortedCopy.filter.category !== -1) {
				cat = v.categories.includes(filteredSortedCopy.filter.category)
			}
			return org && stat && cat
		})
	}
	const filter_ = (key, value) => {
		try {
			const updated = JSON.parse(JSON.stringify(filteredSorted))
			updated.filter[key] = value
			updated.filtered = _filter_(updated)
			setFilteredSorted(updated)
		} catch (e) {
			console.log(e)
		}
	}
	const providers = organizationIndexedRequests.keysAsNumber.map(key => {
		return {
			id: key,
			label: organizationIndexedRequests.byIndex(key).profile.name.display
		}
	})
	const categories = Array.from(new Set(all.map(v => v.categories).flat())).map(v => {
		const service = new Servicio(services.byIndex(v))
		return {
			id: v,
			label: service.labelsAsString
		}
	})
	const states = Array.from(new Set(all.map(v => v.states).flat())).map(v => {
		return {
			id: v,
			label: translate(v)
		}
	})
	// console.log("providers", providers, "categories", categories, "states", states)
	const modal = selected ? <Modal id={"verification-card-modal"} padding={"0vh"} icon={ICONS.SHIELD_CHECK}
		title={organizationIndexedRequests.byIndex(selected.organization).profile.name.display}
		onClick={() => setSelected(null)} body={<MobileVerificationStatusCard setServiceApprovals={() => {}}
			request={organizationIndexedRequests.byIndex(selected.organization)} services={services} onDelete={() => {}}
			documentRequirements={props.organization.documentRequirements} refresh={props.refreshOrganization} canEdit={props.canEdit}
			resolved={selected.resolved} isRequester={false}/>}/> : <></>
	const cards = filteredSorted.filtered.length > 0 ?
		filteredSorted.filtered.map(v => {
			const req = organizationIndexedRequests.byIndex(v.organization)
			return <MobileVerificationStatusCard simple={true} setServiceApprovals={() => {}}
				key={`${v.organization}-verification-card`}	request={req} services={services}
				onDelete={() => props.refreshOrganization()} documentRequirements={props.organization.documentRequirements}
				refresh={props.refreshOrganization}	canEdit={props.canEdit} resolved={v.resolved}
				isRequester={false} onClick={() => setSelected(v)}/>}) :
		<div style={{paddingTop: "10vh"}}>
			<div>
				<MobileFontAwesome icon={ICONS.FOLDER_OPEN} style={{fontSize: "20vh"}} label={translate(DICTIONARY.NO_REQUESTS.X)}
					onClick={() => fadingMessage("no-orders", translate(DICTIONARY.NO_REQUESTS.X), "", "w3-pale-blue",
						ICONS.DOLLY_FLATBED_EMPTY)}/>
			</div>
			<div style={{fontSize: "3vh"}}>
				{translate(DICTIONARY.NO_REQUESTS.X)}
			</div>
		</div>
	// console.log("filteredSorted.filtered", filteredSorted.filtered)
	const providersList = [{id: -1, label: translate(DICTIONARY.ALL.X)}, ...providers]
	const categoriesList = [{id: -1, label: translate(DICTIONARY.ALL.X)},	...categories]
	const statesList = [{id: -1, label: translate(DICTIONARY.ALL.X)}, ...states]
	const onReset = () => {
		try {
			const updated = JSON.parse(JSON.stringify(filteredSorted))
			updated.filter.organization = -1
			updated.filter.status = -1
			updated.filter.category = -1
			updated.filtered = _filter_(updated)
			setFilteredSorted(updated)
		} catch (e) {
			console.log(e)
		}
	}
	const filter__ = filter ? <Modal id={"filter-modal"} icon={ICONS.FILTER} title={translate(DICTIONARY.FILTER.X)}
		onClick={() => setFilter(false)} padding={"0vh"} body={<>
			<div style={{textAlign: "left", fontSize: "2.5vh", opacity: 0.5, margin: "0vh 2vh"}}>
				<span style={{cursor: "pointer"}} className={"w3-ripple"} onClick={onReset}>
					<MobileFontAwesome icon={ICONS.UNDO} label={translate(DICTIONARY.RESET.X)} style={{paddingRight: "1.5vh"}}/>
					<i>{translate(DICTIONARY.RESET.X)}</i>
				</span>
			</div>
			<div style={{padding: "2vh"}}>
				<MobileGenericDropdown id={"providers"} options={providersList} className={"paralian-theme-level-2"}
					left={{icon: ICONS.USER_HARD_HAT, onClick: () => {}}}
					defaultValue={providersList.findIndex(v => v.id === filteredSorted.filter.organization)}
					label={translate(DICTIONARY.PROVIDER.X)} onChange={id => filter_("organization", id)}/>
				<MobileGenericDropdown id={"categories"} options={categoriesList} left={{icon: ICONS.TRUCK, onClick: () => {}}}
					defaultValue={categoriesList.findIndex(v => v.id === filteredSorted.filter.category)}
					label={translate(DICTIONARY.CATEGORY.X)} className={"paralian-theme-level-2"}
					onChange={id => filter_("category", id)}/>
				<MobileGenericDropdown id={"state"} options={statesList} left={{icon: ICONS.CHECK_CIRCLE, onClick: () => {}}}
					defaultValue={statesList.findIndex(v => v.id === filteredSorted.filter.status)}
					label={translate(DICTIONARY.STATUS.X)} className={"paralian-theme-level-2"}
					onChange={id => filter_("status", id)}/>
			</div></>}/> : <></>
	const flipSort = () => {
		const updated = {...filteredSorted}
		updated.filtered = [...filteredSorted.filtered].reverse()
		updated.sort.order = filteredSorted.sort.order === "ASC" ? "DESC" : "ASC"
		setFilteredSorted(updated)
	}
	const sort_ = sort ? <Modal id={"sort-modal"} icon={ICONS.SORT_DOWN} title={translate(DICTIONARY.SORT.X)}
		onClick={() => setSort(false)} padding={"0vh"}
		body={<div style={{padding: "2vh"}}>
		<MobileInputGeneric type={"dropdown"} id={"sort"} className={"paralian-theme-level-2"} style={{fontSize: "2.5vh"}}
			label={translate(DICTIONARY.SORT.X)} multiple={false} name={"sort"} required={false} size={"1"}
			defaultValue={null} showLabel={false}	list={ORDER_SORT_OPTIONS}
			left={{icon: filteredSorted.sort.order === "ASC" ? ICONS.SORT_UP : ICONS.SORT_DOWN, 
				onClick: flipSort}}
			onChange={() => {
				const updated = {...filteredSorted}
				const key = document.getElementById("sort-input").value
				updated.filtered = [...filteredSorted.filtered].sort((a, b) => compare(a, b, key))
				updated.sort.key = key
				setFilteredSorted(updated)
			}}/>
	</div>}/> : <></>
	const numFilters = Object.values(filteredSorted.filter).reduce((sum, item) => {
		return sum + (item !== -1 ? 1 : 0)
	}, 0)
	return <div id={"scrollBody"} className={"w3-display-middle paralian-scroll paralian-hide-scroll-ms " +
		"paralian-hide-scroll-chrome w3-center w3-animate-opacity"}
		style={{width: "100%", height: "72vh", marginTop: "1vh", padding: "0vh 2vh"}}>
		{modal}
		<div className={"sticky paralian-theme-level-1"} style={{zIndex: "3", padding: "2vh 0vh", display: "flex",
			alignContent: "center", alignItems: "center", height: "8vh", fontSize: "2.5vh"}}>
			<div style={{margin: "1vh 0vh 1vh auto", cursor: "pointer"}} className={"w3-ripple"}
				onClick={() => props.refreshOrganization()}>
				<MobileFontAwesome label={translate(DICTIONARY.REFRESH.X)} icon={ICONS.SYNC} style={{padding: "0vh 2vh"}}/>
				<span style={{fontSize: "2vh"}}>{translate(DICTIONARY.REFRESH.X)}</span>
			</div>
			<div style={{margin: "1vh"}}>
				<MobileFontAwesome icon={filteredSorted.sort.order === "ASC" ? ICONS.SORT_UP : ICONS.SORT_DOWN}
					label={translate(DICTIONARY.SORT.X)} style={{padding: "0vh 2vh"}} onClick={flipSort}/>
				<span style={{fontSize: "2vh", cursor: "pointer"}} className={"w3-ripple"} onClick={() => {
					setSort(!sort)
				}}>
					{translate(DICTIONARY.SORT.X)}
				</span>
			</div>
			<div style={{margin: "1vh auto 1vh 0vh", cursor: "pointer"}} className={"w3-ripple"} onClick={() => {
				setFilter(!filter)
			}}>
				<MobileFontAwesome icon={ICONS.FILTER} label={translate(DICTIONARY.FILTER.X)} style={{padding: "0vh 2vh"}}/>
				<span style={{fontSize: "2vh"}}>{translate(DICTIONARY.FILTER.X)}</span>
				{numFilters !== 0 ?
					<span style={{fontSize: "2vh"}}>{` (${numFilters})`}</span> : <></>
				}
			</div>
		</div>
		{sort_}
		{filter__}
		{cards}
	</div>
}

export default MobileVerifications