import { typeOrNull } from '../../../functions/shared/local'

class HullDimensions {
	/**
	*
	* @param {HullDimensions} [dimensions = null]
	* @param {number} [dimensions.length]
	* @param {number} [dimensions.beam]
	* @param {number} [dimensions.draught]
	* @param {number} [dimensions.height]
	* @param {number} [dimensions.clearance]
	*/
	constructor (dimensions = null) {
		try {
			this.length = typeOrNull(dimensions.length, "number")
			this.beam = typeOrNull(dimensions.beam, "number")
			this.draught = typeOrNull(dimensions.draught, "number")
			this.height = typeOrNull(dimensions.height, "number")
			this.clearance = typeOrNull(dimensions.clearance, "number")
		} catch (e) {
			this.length = null
			this.beam = null
			this.draught = null
			this.height = null
			this.height = null
		}
	}
}

export default HullDimensions