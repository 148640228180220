import React from "react"
import {DICTIONARY} from "../constants/client/constants"
import {manageCrash, translate} from "../functions/client/localFunctions"
/**
 * Displays bottom quote
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const BottomQuote = () => {
  try {
    return <div className="w3-display-bottommiddle" style={{height: "10vh", width: "100%"}}>
      <div className={"w3-center paralian-font"} style={{fontSize: "2.5vh", paddingTop: "2.5vh"}}>
        <i>{translate(DICTIONARY.PARALIAN.X)}</i>
      </div>
    </div>
  } catch (e) {
    manageCrash(e, "BottomQuote", "warning")
    return <></>
  }
}

export default BottomQuote