import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import {createStripeConnectPaymentIntent} from "../../../globals/functions/client/serverFunctions"
import submitToServer from "../../../globals/functions/client/submitToServer"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"

const MobileNewOrderSubmit = props => {
	{
		const [disabled, setDisabled] = useState(true)
		const onSubmit = async event => {
			// console.log("handle submit")
			await submitToServer(event, props.order, createStripeConnectPaymentIntent, props.onSuccess)
			setDisabled(false)
		}
		const content_ = <div>
			{props.showTermsOfSale ? props.termsOfSale : <></>}
			<div className={"w3-center paralian-mobile-info-card"}>
				<div className={"paralian-mobile-info-card-header"} style={{padding: "2vh"}}>
					{"Order Summary"}
				</div>
				{props.summary}
				<div style={{display: "flex", padding: "1.5vh 4vh 0vh 4vh"}}>
					<input id={"terms-of-sale-checkbox"} style={{height: "6vh", width: "6vh", margin: "1vh"}} type={"checkbox"}
						name={"terms-of-sale"} value={"accepted"} onChange={() => setDisabled(!disabled)}/>
					<label htmlFor={"terms-of-sale-checkbox"} style={{display: "none"}}>
						{translate(DICTIONARY.READ_AND_AGREE_TERMS_OF_SALE.X)}
					</label>
					<span style={{textAlign: "left", margin: "auto"}}>
					{translate(DICTIONARY.READ_AND_AGREE_TO.X)}
						<b onClick={() => props.setShowTermsOfSale(!props.showTermsOfSale)}>
						{translate(DICTIONARY.TERMS_OF_SALE.X)}
						</b>
					</span>
				</div>
				<div style={{padding: "2vh 0vh"}}>
					<MobileButtonGeneric id={"submit"} type={"submit"} form={"new-order-submit"}  padding={"1vh"}
						text={{content: translate(DICTIONARY.SUBMIT.X)}} style={{width: "100%"}} disabled={disabled}
						className={"paralian-theme-level-1-flipped"}/>
				</div>
			</div>
		</div>

		return <MobileInputForm id={"new-order-submit"} content={content_} className={" "} hideButton={true}
			disabled={disabled} onSubmit={onSubmit}/>
	}
}

export default MobileNewOrderSubmit