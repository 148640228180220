import React from "react"
import {convertToPreferredUnits} from "../../../globals/functions/client/localFunctions"
import MobileInputDecimal from "../../shared/input/text/MobileInputDecimal"

/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {string} props.form
 * @param {string} props.units - SI or US
 * @param {number} props.value
 * @param {string} props.label
 * @param {React.HTMLProps.className} props.className
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputPower = props => {
	const units = props.units === "SI" ? "kW" : "hp"
	const power = Number(convertToPreferredUnits(props.value, "kW", props.units, 0,
		false, true).replace(/[a-zA-Z,. ]/g,''))
	const power_ = power > 0 ? power.toLocaleString() : null
	return <MobileInputDecimal className={props.className} label={props.label}
		defaultValue={null} required={false} units={units} form={props.form} topRadius={"0vh"}
		name={props.id} id={props.id} digits={18} decimals={0} autoFocus={false} bottomRadius={"0vh"}
		defaultLong={power_}/>
}

export default MobileInputPower