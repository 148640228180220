import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {addressDelete} from "../../../globals/functions/client/serverFunctions"
import MobileInputAddressEdit from "./MobileInputAddressEdit"
import MobileAddress from "./MobileAddress"
import Modal from "../../../globals/components/Modal"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Addresses from "../../../globals/classes/shared/Addresses"

/**
 *
 * @param {Object} props
 * @param {string} props.form
 * @param {boolean} props.showLabel
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {Address} props.address - default address
 * @param {boolean} props.isOrganization
 * @param {boolean} props.allowDelete
 * @param {function} props.setAddresses
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileAddressToggle = props => {
	const [edit, setEdit] = useState(false)
	const content =  edit ? <Modal id={`address-${props.address.id}-edit`} icon={ICONS.PENCIL}
		title={translate(DICTIONARY.EDIT_ADDRESS.X)} onClick={() => setEdit(false)} padding={"0vh"} body={
		<div style={{margin: "2vh"}}><MobileInputAddressEdit form={props.form} showLabel={props.showLabel}
			refresh={props.refresh} address={props.address} style={props.style} allowDelete={true}
			className={props.className} isOrganization={props.isOrganization} onSubmit={addresses => {
				props.refresh().then()
				props.setAddresses(addresses)
				setEdit(false)
		}} onDelete={async () => {
			const confirmed = window.confirm(translate(DICTIONARY.DELETE_ADDRESS_PROMPT.X))
			if (confirmed) {
				const response = await addressDelete(props.address.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.refresh().then()
					props.setAddresses(props.isOrganization ? new Addresses(response.payload) : response.payload)
				}}}}/></div>
		}/> :	<MobileAddress address={props.address}/>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}	labels={[props.address.label]}/>
		{content}
	</div>
}

export default MobileAddressToggle