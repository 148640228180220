import React, {useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MarinaSelect from "../cleaning/MarinaSelect"
import ProvisionsForm from "./ProvisionsForm"
import Mailto from "reactv16-mailto"
const ProvisionsHome = props => {
	/* TODO: translate */
	const currentState = store.getState()
	const levelOne = currentState.window.current.levelOne
	const parentPage = PAGE_STATES[levelOne].MAIN.REQUESTS
	const isOrganization = props.account.isAdminOrEmployee
	const [disabled, setDisabled] = useState(false)
	const [marinaEmail, setMarinaEmail] = useState("hello@paralian.io")
	let content, back, next, onSubmit, onSubmitPromise
	let onSubmit_ = event => {
		try {
			event.preventDefault()
			if (event.target.id === "form") {
				if (onSubmitPromise) {onSubmitPromise().then(() =>
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})).catch(e => console.log(e))
				} else {
					if (onSubmit) {onSubmit()}
					store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})
				}
			}
		} catch (e) {
			console.log(e)
		}
	}
	const email = <div id={"vessel-provisioning-send-email-parent"} style={{margin: "2vh", display: "none"}}>
		<Mailto id={"vessel-provisioning-send-email"} email={marinaEmail} obfuscate={false} headers={{
			subject: translate(DICTIONARY.VESSEL_PROVISIONING_SEND_MAIL_SUBJECT.X),
			body: translate(DICTIONARY.VESSEL_PROVISIONING_SEND_MAIL_MESSAGE.X) + props.account.contact.name.given
		}}>
			{translate(DICTIONARY.SEND_EMAIL.X)}
		</Mailto>
	</div>
	switch (currentState.window.current.levelFive) {
		default:
			content = <><MarinaSelect account={props.account} marinas={props.marinas} refresh={props.refreshMarinas}
					setDisabled={setDisabled} services={[999999]} ifNotCustomer={email => {
				setMarinaEmail(email)
				newMessage("vessel-provisioning-send-email-message",
					translate(DICTIONARY.UNAVAILABLE.X), translate(DICTIONARY.VESSEL_PROVISIONING_SEND_MAIL_PROMPT.X), "w3-pale-blue",
					ICONS.INFO_CIRCLE, [{
						id: "concierge-crew", icon: null, text: translate(DICTIONARY.EMAIL_MARINA.X),
						onClick: () => {
							document.getElementById("vessel-provisioning-send-email").click()
						}
					}])
			}}/>{email}</>
			back = PAGE_STATES[levelOne].MAIN.REQUESTS.X
			next = parentPage.CONCIERGE.VESSEL.X
	}
	//onClick: () => store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: parentPage.CONCIERGE.X}})}])}/>
	return <ProvisionsForm content={content} back={back} isOrganization={isOrganization} onSubmit={onSubmit_}
		disabled={disabled}/>
}

export default ProvisionsHome