import React, {useEffect, useState} from "react"
import ICONS from "../../../globals/constants/client/icons"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {useElements, useStripe} from "@stripe/react-stripe-js"
import {fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {purchaseParalianProduct} from "../../../globals/functions/client/serverFunctions"
import submitNewPaymentMethod from "../payment/submitNewPaymentMethod"
import MobileInputCreditCard from "../payment/MobileInputCreditCard"
import Modal from "../../../globals/components/Modal"
import MobilePaymentMethodsDropdown from "../../shared/input/dropdown/MobilePaymentMethodsDropdown"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Subscription from "../../../globals/classes/shared/payments/Subscription"
/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {number} props.amount
 * @param {string} props.currency - ISO-3 Code
 * @param {function} props.getSecret
 * @param {function} props.setShow
 * @param {function} props.refresh
 * @param {boolean} props.isOrganization
 * @returns {JSX.Element}
 * @constructor
 */
const MobileParalianSubscriptionSignup = props => {
	const stripe = useStripe()
	const elements = useElements()
	const [subscription, setSubscription] = useState(new Subscription(props.subscription))
	const [showTermsOfSale, setShowTermsOfSale] = useState(false)
	const [tosAccepted, setTosAccepted] = useState(false)
	const [error, setError] = useState(null)
	const [cardComplete, setCardComplete] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [newPaymentMethod, setNewPaymentMethod] = useState(false)
	const [billingDetails, setBillingDetails] = useState({name: props.parentObject ? props.isOrganization ?
			props.parentObject.name.display :	props.parentObject.contact.name.given : null})
	const [billingAddress, setBillingAddress] = useState(props.parentObject ?
		props.parentObject.addresses.asArray.length > 0 ?	props.parentObject.addresses.asArray[0] : -1 : -1)
	const [showModal, setShowModal] = useState(false)
	const [modalBody, setModalBody] = useState(<></>)
	// console.log("processing", processing, "tosAccepted", tosAccepted)
	useEffect(() => {
		try {
			setSubscription(new Subscription(props.subscription))
		} catch (e) {
			setSubscription(new Subscription())
		}
	}, [props.subscription])
	useEffect(() => {
		try {
			const billingAddresses = props.parentObject.addresses.asArray.filter(address => address.isBilling)
			setBillingAddress(billingAddresses[0])
			// console.log("billing address", billingAddresses[0])
		} catch (e) {
			setBillingAddress(-1)
		}
	}, [props.parentObject])
	try {
		const onSubmit = async event => {
			// console.log("handle submit")
			try {
				event.preventDefault()
				let paymentMethod = document.getElementById("payment-method-input").getAttribute("data-id")
				if (paymentMethod === "-1" && !error && cardComplete) {
					paymentMethod = await submitNewPaymentMethod(stripe, elements, setProcessing, billingAddress,	billingDetails,
						() => {}, props.isOrganization)
				}
				if (!paymentMethod || paymentMethod === "-1") {return}
				const response = await purchaseParalianProduct(paymentMethod, subscription.name, props.isOrganization)
				// console.log("MobileParalianSubscriptionSignup response", response)
				setProcessing(false)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					fadingMessage("paymentSuccess", translate(DICTIONARY.SUCCESS_EXCLAMATION.X),
						translate(DICTIONARY.PAYMENT_PROCESSED.X), "w3-pale-blue", ICONS.CREDIT_CARD)
					props.refresh().then()
					props.setShow(false)
				} else if (response.status === SERVER_RESPONSE_STATE.FAIL) {
					if (response.payload.error === "payment incomplete") {
						setModalBody(<iframe title={translate(DICTIONARY.PAYMENT_METHOD.X)}
							src={response.payload.iframe.stripe_js || response.payload.invoice} style={{minHeight: "64vh"}}/>)
						setShowModal(true)
					} else {
						newMessage("paymentError", translate(DICTIONARY.ERROR_PROCESSING_ORDER.X),
							translate(DICTIONARY.PAYMENT_DECLINED_MESSAGE.X), "w3-pale-yellow", ICONS.CREDIT_CARD)
					}
				}
			} catch (e) {
				console.log(e)
			}
		}
		const modal = showModal ? <Modal icon={ICONS.INFO_CIRCLE} title={"3D Secure"} id={"3dsecure"} body={modalBody}
			onClick={() => {
				props.refresh().then()
				props.setShow(false)
				setShowModal(false)
			}}/> : <></>
		const content = newPaymentMethod ?
			<MobileInputCreditCard setCardComplete={setCardComplete} setError={setError} setBillingDetails={setBillingDetails}
				setBillingAddress={setBillingAddress} parentObject={props.parentObject}
				className={"paralian-theme-level-2"} isOrganization={props.isOrganization}/> : <></>
		const termsOfSale = showTermsOfSale ? <Modal title={translate(DICTIONARY.TERMS_OF_SALE.X)} icon={ICONS.HANDSHAKE}
			onClick={() => setShowTermsOfSale(false)} id={"terms-of-sale"}
			body={<div style={{textAlign: "justify", width: "100%"}}>
				<p>{translate(DICTIONARY.TERMS_OF_SALE_MESSAGE_1.X)}</p>
				<p>{translate(DICTIONARY.TERMS_OF_SALE_MESSAGE_2.X)}</p>
				</div>}/> : <></>
		const content_ =  <div>
			{modal}
			<div style={{padding: "2vh"}}>
				<MobilePaymentMethodsDropdown id={"payment-method"}	setNewPaymentMethod={setNewPaymentMethod}
					form={"direct-cc-payment-form"} showLabel={true} parentObject={props.parentObject}/>
			</div>
			{termsOfSale}
			{content}
			<div className={"w3-center"} style={{width: "100%"}}>
				<div style={{display: "flex", padding: "1.5vh 4vh 0vh 4vh"}}>
					<input id={"terms-of-sale-checkbox"} style={{height: "6vh", width: "6vh", margin: "1vh"}} type={"checkbox"}
						name={"terms-of-sale"} value={"accepted"} onChange={() => {
						// document.getElementById("submit").disabled = !document.getElementById("submit").disabled
						setTosAccepted(!tosAccepted)
					}}/>
					<label htmlFor={"terms-of-sale-checkbox"} style={{display: "none"}}>
						{translate(DICTIONARY.READ_AND_AGREE_TERMS_OF_SALE.X)}
					</label>
					<span style={{textAlign: "left", margin: "auto"}}>
						{translate(DICTIONARY.READ_AND_AGREE_TO.X)}
						<b onClick={() => setShowTermsOfSale(!showTermsOfSale)}>
							{translate(DICTIONARY.TERMS_OF_SALE.X)}
						</b>
					</span>
				</div>
				<div style={{padding: "2vh"}}>
					<MobileButtonGeneric id={"submit"} type={"submit"} left={{icon: ICONS.CROWN}} form={"direct-cc-payment-form"}
						text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.SUBSCRIBE.X)}}
					 	className={"paralian-theme-level-2"} padding={"1vh"} style={{width: "100%"}} disabled={processing || !tosAccepted}/>
				</div>
			</div>
		</div>
		return <MobileInputForm id={"direct-cc-payment"} content={content_} className={"w3-animate-top"} hideButton={true}
			disabled={processing || !tosAccepted} onSubmit={onSubmit}/>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileParalianSubscriptionSignup