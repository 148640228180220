import React from "react"
import {IMAGES} from "../constants/client/constants"
import {manageCrash} from "../functions/client/localFunctions"
/**
 * Displays splash screen with Paralian logo and a loader
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const SplashScreen = () => {
	try {
		return <div className={"w3-display-container paralian-theme-level-1"} style={{height: "100vh", width: "100vw"}}>
			<picture className={"w3-display-middle"} style={{width: "80vw", maxWidth: "90vh"}}>
				<img src={IMAGES.LOGO_PARALIAN} alt={"PARALIAN"} style={{width: "100%"}}/>
			</picture>
			<picture className={"w3-display-bottommiddle"} style={{width: "15vw", maxWidth: "15vh", marginBottom: "2vh"}}>
				<img src={IMAGES.LOADER} alt={"loader"} style={{width: "100%"}}/>
			</picture>
		</div>
	} catch (e) {
		manageCrash(e, "SplashScreen", "warning")
		return <div className={"w3-display-container paralian-theme-level-1"} style={{height: "100vh", width: "100vw"}}/>
	}
}

export default SplashScreen