import React from "react"
import {DICTIONARY, ICONS} from "../constants/client/constants"
import {fadingMessage, manageCrash, translate} from "../functions/client/localFunctions"
import MobileFontAwesome from "../../mobile/main/MobileFontAwesome"
/**
 * Displays star rating given a value between 0 and 5
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} props
 * @param {number} props.rating
 * @param {Object} props.style
 * @return {JSX.Element}
 * @constructor
 */
const StarRating = props => {
	try {
		const rating = props.rating
		const style = props.style || {padding: "1.0vh"}
		if (!Number.isFinite(rating) || rating > 5 || rating < 0) {
			return <>
				<MobileFontAwesome icon={ICONS.STAR_EXCLAMATION} style={style} label={translate(DICTIONARY.NO_RATING.X)}
					onClick={() => fadingMessage("no-rating", translate(DICTIONARY.NO_RATING.X), "", "w3-pale-blue",
						ICONS.STAR_EXCLAMATION)}/>
				<MobileFontAwesome icon={ICONS.STAR_EXCLAMATION} style={style} label={translate(DICTIONARY.NO_RATING.X)}
					onClick={() => fadingMessage("no-rating", translate(DICTIONARY.NO_RATING.X), "", "w3-pale-blue",
						ICONS.STAR_EXCLAMATION)}/>
				<MobileFontAwesome icon={ICONS.STAR_EXCLAMATION} style={style} label={translate(DICTIONARY.NO_RATING.X)}
					onClick={() => fadingMessage("no-rating", translate(DICTIONARY.NO_RATING.X), "", "w3-pale-blue",
						ICONS.STAR_EXCLAMATION)}/>
				<MobileFontAwesome icon={ICONS.STAR_EXCLAMATION} style={style} label={translate(DICTIONARY.NO_RATING.X)}
					onClick={() => fadingMessage("no-rating", translate(DICTIONARY.NO_RATING.X), "", "w3-pale-blue",
						ICONS.STAR_EXCLAMATION)}/>
				<MobileFontAwesome icon={ICONS.STAR_EXCLAMATION} style={style} label={translate(DICTIONARY.NO_RATING.X)}
					onClick={() => fadingMessage("no-rating", translate(DICTIONARY.NO_RATING.X), "", "w3-pale-blue",
						ICONS.STAR_EXCLAMATION)}/>
			</>
		}
		let stars = [["fas", ICONS.STAR[1]], ["fas", ICONS.STAR[1]], ["fas", ICONS.STAR[1]], ["fas", ICONS.STAR[1]], ["fas", ICONS.STAR[1]]]
		if (rating > 4.75) {
			// 5 star image
			// console.log("5-star");
		} else if (rating > 4.25) {
			// 4.5 star image
			stars[4] = ["fas", ICONS.STAR_HALF_ALT[1]]
		} else if (rating > 3.75) {
			// 4 star image
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 3.25) {
			// 3.5 star image
			stars[3] = ["fas", ICONS.STAR_HALF_ALT[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 2.75) {
			// 3 star image
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 2.25) {
			// 2.5 star image
			stars[2] = ["fas", ICONS.STAR_HALF_ALT[1]]
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 1.75) {
			// 2 star image
			stars[2] = ["far", ICONS.STAR[1]]
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 1.25) {
			// 1.5 star image
			stars[1] = ["fas", ICONS.STAR_HALF_ALT[1]]
			stars[2] = ["far", ICONS.STAR[1]]
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 0.75) {
			// 1 star image
			stars[1] = ["far", ICONS.STAR[1]]
			stars[2] = ["far", ICONS.STAR[1]]
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else if (rating > 0.25) {
			// 0.5 star image
			stars[0] = ["fas", ICONS.STAR_HALF_ALT[1]]
			stars[1] = ["far", ICONS.STAR[1]]
			stars[2] = ["far", ICONS.STAR[1]]
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		} else {
			// 0 star image
			stars[0] = ["far", ICONS.STAR[1]]
			stars[1] = ["far", ICONS.STAR[1]]
			stars[2] = ["far", ICONS.STAR[1]]
			stars[3] = ["far", ICONS.STAR[1]]
			stars[4] = ["far", ICONS.STAR[1]]
		}
		return <>
			<MobileFontAwesome icon={stars[0]} style={style}/>
			<MobileFontAwesome icon={stars[1]} style={style}/>
			<MobileFontAwesome icon={stars[2]} style={style}/>
			<MobileFontAwesome icon={stars[3]} style={style}/>
			<MobileFontAwesome icon={stars[4]} style={style}/>
		</>
	} catch (e) {
		manageCrash(e, "StarRating", "warning")
		return <i>{!Number.isFinite(props.rating) || props.rating > 5 || props.rating < 0 ? "N/A" : props.rating}</i>
	}
}

export default StarRating