import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {dateToYYYYMMDD, getDateDelta, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {setNewContactBirthdate} from "../../../../globals/functions/client/serverFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputDate from "../../../shared/input/text/MobileInputDate"

const MobileLoginDateOfBirth = props => {
  useEffect(() => {
    newMessage("psp-message-auto", translate(DICTIONARY.DATE_OF_BIRTH.X),
      translate(DICTIONARY.DATE_OF_BIRTH_INPUT_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
  }, [])
  const onSubmit = async event => {
    event.preventDefault()
    try {
      const dob = document.getElementById("dateOfBirth-input").value
      const response = await setNewContactBirthdate(dob)
      if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
        store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
      }
    } catch (e) {
      console.log(e)
    }
  }
  const maxDate = dateToYYYYMMDD(getDateDelta(-12, 1, 0))
  const content =	<MobileInputDate id={"dateOfBirth"} name={"dateOfBirth"} showLabel={true}
    label={translate(DICTIONARY.DATE_OF_BIRTH.X)} className={"paralian-theme-level-1-flipped"} style={{margin: "1vh 0vw"}}
    bottomRadius={"1vh"} topRadius={"1vh"} autoComplete={"bday"} required={false} defaultValue={null}
    min={"1900-01-01"} max={maxDate} autoFocus={true} left={{}} right={{}} form={"form"}/>
  return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}/>
}

export default MobileLoginDateOfBirth