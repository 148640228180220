import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {getServices} from "../../../globals/functions/client/serverFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileAdmin from "./MobileAdmin"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileAdminSave from "./MobileAdminSave"
import Modal from "../../../globals/components/Modal"
import Employee from "../../../globals/classes/shared/Employee"
import IndexedArray from "../../../globals/classes/shared/IndexedArray"
import Servicio from "../../../globals/classes/shared/Servicio"
/**
 *
 * @param {Object} props
 * @param {Account[]} props.admins
 * @param {string} props.top - top margin for sticky property of banner
 * @param {string|number} props.zIndex
 * @param {function} props.onAdd
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdmins = props => {
	const [add, setAdd] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [admins, setAdmins] = useState(props.admins)
	const [categories, setCategories] = useState([])
	useEffect(() => {
		getServices().then(response => {
			// console.log("response", response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const services = new IndexedArray({obj: response.payload.asIndexedArray.obj})
				const categories = Array.from(new Set(props.services.map(service => service.category)))
				setCategories(categories.map(id => new Servicio(services.byIndex(id))))
			}})},[props.services])
	const onDelete = id => {
		props.refresh().then()
		setAdmins(admins.filter(admin => admin.id !== id))
	}
	useEffect(() => {setAdmins(props.admins)}, [props.admins])
	const content = admins.map(admin =>
		<MobileAdmin key={admin.id} admin={new Employee(admin)} onDelete={onDelete} onEdit={() => {}}
			canEdit={props.canEdit && props.account.id !== admin.id}/>)
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} id={"admins"} zIndex={props.zIndex} top={props.top}
			text={translate(DICTIONARY.ADMINS.X)} onClick={() => setExpanded(!expanded)}/>
		{expanded ? <div id={"admins"} className={"w3-animate-opacity w3-animate-top"}>
			{content}
			{add && props.canEdit ?
				<Modal id={"admin-add-modal"} padding={"0vh"} icon={ICONS.USER_SHIELD} title={translate(DICTIONARY.ADD_ADMIN.X)}
				 	onClick={() => setAdd(false)} body={
					<MobileAdminSave id={"admin-save"} onUndo={() => setAdd(!add)} style={null}
						className={"paralian-theme-level-1"} services={categories} onSubmit={admin => {
						if (props.refresh) {props.refresh().then()}
						setAdmins([admin, ...admins])
						setAdd(false)
					}}/>
				}/> : <></>}
			{props.canEdit ?
				<MobileAddNewBanner text={translate(DICTIONARY.ADD.X)} onClick={() => setAdd(!add)}/> : <></>}
		</div> : <></>}
	</>
}

export default MobileAdmins