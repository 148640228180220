import {typeOrNull} from '../../functions/shared/local'
import Time from './Time'

class Day {
	/**
	 *
	 * @param {Day} [day = null]
	 * @param {boolean} [day.isOpen]
	 * @param {Time} [day.open]
	 * @param {Time} [day.close]
	 */
	constructor (day = null) {
		try {
			this.isOpen_ = day.isOpen === true || day.isOpen_ === true
			this.open_ = new Time(day.open || day.open_)
			this.close_ = new Time(day.close || day.close_)
		} catch (e) {
			this.isOpen_ = null
			this.open_ = new Time()
			this.close_ = new Time()
		}
	}

	get isOpen(){
		return this.isOpen_
	}

	set isOpen(isOpen) {
		this.isOpen_ = typeOrNull(isOpen, "boolean")
	}

	get open(){
		return this.open_
	}

	set open(open) {
		this.open_ = new Time(open)
	}

	get close(){
		return this.close_
	}

	set close(close) {
		this.close_ = new Time(close)
	}

	get isOpen24Hrs() {
		return this.open.hourAs24hr === 0 && this.close.hourAs24hr === 23 && this.close.minute === 59
	}
}

export default Day