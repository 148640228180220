import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class Title extends TextInputFilter {
	minLength = 2
	maxLength = 45
	regexp = /[^0-9A-Z]/gi
}

export default Title;