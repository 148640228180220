import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class PositiveNumber extends TextInputFilter {
	regexp = /[^0-9.]/gi
	minVal = 0
	maxVal = 99999999999
}

export default PositiveNumber;