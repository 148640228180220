import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../globals/constants/client/constants"
import {VERIFICATION_STATE} from "../../globals/constants/shared/enumerators"
import {translate} from "../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "./MobileStickyBannerExpandDown"
import MobileIdentityVerificationInstructions from "./verification/MobileIdentityVerificationInstructions"
import MobileIdentityVerificationCard from "./verification/MobileIdentityVerificationCard"
import Verification from "../../globals/classes/shared/Verification"

/* TODO: divide into individual info cards & add trash can
*  TODO: show only "add new" when verifications array is empty
* */
/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style.zIndex} props.zIndex
 * @param {React.HTMLAttributes.style.top} props.top
 * @param {Verification[]} props.verifications
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileIdentityVerification = props => {
	const [expanded, setExpanded] = useState(false)
	const [verifications, setVerifications] = useState(props.verifications || [])
	useEffect(() => setVerifications(props.verifications), [props.verifications])
	// const [add, setAdd] = useState(false)
	const showNextSteps = verifications.filter(verification => verification.status_ === VERIFICATION_STATE.PENDING).length > 0
	const removeVerification = id => {setVerifications(verifications.filter(verification => verification.id !== id))}
	const idVerificationContent = verifications.map(verification => <MobileIdentityVerificationCard id={verification.id}
		key={`verifier-${verification.id}`} verification={new Verification(verification)}
		canDelete={verification.status !== VERIFICATION_STATE.VERIFIED} onDelete={id => {
			props.refresh().then()
			removeVerification(id)
		}}/>)
	/*
	const insert = add ? <MobileIdentityVerificationAdd state={null} country={null}
		onUndo={() => setAdd(false)} onSuccess={verifications_ => {
			props.refresh().then()
			setVerifications(verifications_)
			setAdd(false)
	}}/> : <></>
	const content = !expanded ? <></> : <div className="w3-animate-top" style={{fontSize: "2vh"}}>
		{idVerificationContent}{verifications.length > 0 ? <MobileIdentityVerificationInstructions/> : <></>}{insert}
		<MobileAddNewBanner text={translate(DICTIONARY.GENERATE_MEMBERSHIP_VERIFICATION_REQUEST.X)}	onClick={() => setAdd(!add)}/>
	</div>
	*/
	const content = !expanded ? <></> : <div className={"w3-animate-top"} style={{fontSize: "2vh"}}>
		{idVerificationContent}{showNextSteps ? <MobileIdentityVerificationInstructions/> :
		<div id={"paralian-products"} className={"w3-animate-opacity paralian-mobile-info-card"}
			style={{textAlign: "center", padding: "2vh"}}>
			{translate(DICTIONARY.NO_VERIFICATION_REQUESTS_MESSAGE.X)}
		</div>}</div>
	return <div>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top}
			text={translate(DICTIONARY.IDENTITY_VERIFICATION.X)} onClick={() => setExpanded(!expanded)}/>
		{content}
	</div>
}

export default MobileIdentityVerification