import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {VERIFICATION_TYPE} from "../../../../globals/constants/shared/enumerators"
import {getFromStorage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputCode from "../../../shared/input/text/MobileInputCode"

/**
 *
 * @param {Object} props
 * @param {Object} props.next - page object
 * @param {Object} props.back - page object
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginNewEmailVerification = props => {
	const label = translate(DICTIONARY.EMAIL.X)
	const email = getFromStorage("email", "session")
	useEffect(() => {
		newMessage("login-email-code-message", translate(DICTIONARY.VERIFICATION_CODE.X),
		translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + email +
		translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
	}, [email])
	const content = <MobileInputCode id={"login-email-code"} className={"paralian-theme-level-1-flipped"}
		name={"login-email-code"}	label={label} right={{}} left={{icon: ICONS.SHIELD, onClick: () => {
			newMessage(`login-email-info-message`, translate(DICTIONARY.VERIFICATION_CODE.X),
				translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + email +
				translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO)}}}
		 verificationType={VERIFICATION_TYPE.LOGIN_EMAIL} autoFocus={true}
		 showLabel={false} placeholder={translate(DICTIONARY.CODE.X)}
		 onFailure={()=>{}}
		 onSuccess={() => {
			 window.sessionStorage.removeItem("email")
			 store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}})
		 }}/>

	return <MobileLoginFormScaffolding header={null} content={content} back={props.back}/>
}

export default MobileLoginNewEmailVerification