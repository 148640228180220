import React from "react"
import {ID_TYPE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {vesselAccessEdit, vesselAccessRescind} from "../../../../globals/functions/client/serverFunctions"
import MobileVesselAccessInputFields from "./MobileVesselAccessInputFields"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import Accesses from "../../../../globals/classes/shared/Accesses"
import DataAccess from "../../../../globals/classes/shared/DataAccess"
import OwnershipAccess from "../../../../globals/classes/shared/OwnershipAccess"

/**
 *
 * @param {Object} props
 * @param {number} props.id - vessel id
 * @param {VesselAccess} props.accesses
 * @param {function} props.onEdit
 * @constructor
 */
const MobileVesselAccessCardEdit = props => {
	const accesses = new Accesses(props.accesses)
	const account = accesses.idType === ID_TYPE.ACCOUNT ? accesses.id : null
	const organization = accesses.idType === ID_TYPE.ORGANIZATION ? accesses.id : null
	const onSubmit = async event => {
		event.preventDefault()
		try {
			const owner = document.getElementById(`${props.id}-owner`).getAttribute("data-a") === "true"
			let ok = true, submitted = false, response
			if (owner === accesses.ownership.isEmpty) {
				accesses.ownership.isEmpty ? accesses.ownership.id = 1 : accesses.ownership = new OwnershipAccess()
				response = owner ? await vesselAccessEdit(props.id, "ownership", owner, accesses.username, organization) :
					await vesselAccessRescind(props.id, "ownership", account, organization)
				submitted = true
				if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
			}
			if (owner) {
				if (accesses.orders.access.length > 0) {
					response = await vesselAccessRescind(props.id, "orders", account, organization, accesses.orders.provider)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (accesses.data.limitedProfile.length + accesses.data.fullProfile.length > 0) {
					response = await vesselAccessRescind(props.id, "data", account, organization)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (accesses.physical.access.length > 0) {
					response = await vesselAccessRescind(props.id, "physical", account, organization, accesses.physical.marina)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
			} else {
				const daViewDocuments = document.getElementById(`${props.id}-da-viewDocuments`).getAttribute("data-a")
				const daViewProfile = document.getElementById(`${props.id}-da-viewProfile`).getAttribute("data-a")
				const paMoveWithinPremises = document.getElementById(`${props.id}-pa-moveWithinPremises`).getAttribute("data-a")
				const paLeavePremises = document.getElementById(`${props.id}-pa-leavePremises`).getAttribute("data-a")
				const paBoard = document.getElementById(`${props.id}-pa-board`).getAttribute("data-a")
				const paService = document.getElementById(`${props.id}-pa-service`).getAttribute("data-a")
				const orders = document.getElementById(`${props.id}-oa-place`).getAttribute("data-a")
				const physical = []
				const order = orders === "true" ? ["PLACE"] : []
				const data = new DataAccess({
					fullProfile: daViewDocuments === "true" ? ["VIEW"] : [],
					limitedProfile: daViewProfile === "true" ? ["VIEW"] : []
				})
				if (paMoveWithinPremises === "true") {physical.push("MOVE")}
				if (paLeavePremises === "true") {physical.push("LEAVE")}
				if (paBoard === "true") {physical.push("BOARD")}
				if (paService === "true") {physical.push("SERVICE")}
				if ((order.length === 0) === accesses.orders.access.includes("PLACE")) {
					response = order.length === 0 ?
						await vesselAccessRescind(props.id, "orders", account, organization) :
						await vesselAccessEdit(props.id, "orders", order, account, organization)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (((data.fullProfile.length === 0) === accesses.data.fullProfile.includes("VIEW")) ||
					(data.limitedProfile.length === 0) === accesses.data.limitedProfile.includes("VIEW")) {
					response = data.fullProfile.length === 0 && data.limitedProfile.length === 0 ?
						await vesselAccessRescind(props.id, "data", account, organization) :
						await vesselAccessEdit(props.id, "data", data, account, organization)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (physical.sort().join() !== accesses.physical.access.sort().join()) {
					response = physical.length === 0 ?
						await vesselAccessRescind(props.id, "physical", account, organization) :
						await vesselAccessEdit(props.id, "physical", physical, account, organization)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				// users are not allowed to modify or delete access to organizations directly, they must cancel the order
				// this is why the account type is always "individuals"
			}
			if (ok && submitted) {
				props.onEdit(accesses, "individuals")
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <MobileVesselAccessInputFields id={props.id} accesses={props.accesses}/>
	return <MobileInputForm id={`${props.accesses.id}-access-edit`} content={content} disableAnimateOpacity={true}
		className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselAccessCardEdit