import React, {useEffect, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {
	clearCanvas,
	fadingMessage,
	handleFileUpload,
	translate
} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileCanvas from "../../../shared/input/canvas/MobileCanvas"

/**
 *
 * @param {Object} props
 * @param {string} props.category - one of: org-logo, org-icon, org-image
 * @param {JSX.Element} props.header
 * @param {Object} props.back
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginOrgImage = props => {
	const next = () => {store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})}
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		setDisabled(false)
	}, [props.category])
	const onSubmit = async event => {
		event.preventDefault()
		setDisabled(true)
		const response = await handleFileUpload("canvas", "image/png", props.category)
		// console.log(response)
		// console.log(response.status)
		// console.log(response.status === SERVER_RESPONSE_STATE.SUCCESS)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			fadingMessage(`img-${props.category}`, translate(DICTIONARY.SUCCESS_EXCLAMATION.X),
				translate(DICTIONARY.IMAGE_UPLOADED.X))
			setTimeout(() => {
				clearCanvas("canvas")
				store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
			},	3500)
		} else {
			setDisabled(false)
		}
	}
	const content = <>
		<MobileCanvas/>
		<div style={{marginTop: "2vh", textAlign: "center"}} onClick={next}>
			<b><i>{translate(DICTIONARY.SKIP.X)}</i></b>
		</div>
	</>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} disabled={disabled}
			onSubmit={onSubmit} encType={"multipart/form-data"}/>
}

export default MobileLoginOrgImage