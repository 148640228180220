import React, {useEffect, useMemo, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {getHolidays} from "../../../../globals/functions/client/serverFunctions"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"
import Holiday from "../../../../globals/classes/shared/Holiday"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {Holiday} props.holiday
 * @param {string} props.country - 3-letter country code
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {function} props.setOther
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileHolidayDropdown = props => {
	const [holidays, setHolidays] = useState([{label: translate(DICTIONARY.OTHER.X), value: 0, dataId: -1,
		name: null, subtitle: " "}])
	const [sub, setSub] = useState(null)
	const label = useMemo(() => props.showLabel ? "" : `${translate(DICTIONARY.HOLIDAY.X)}: `, [props.showLabel])
	useEffect(() => {
		getHolidays(props.country || "USA").then(response => {
			const list = []
			try {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					for (let i = 0; i < response.payload.length; i++) {
						const holiday_ = new Holiday(response.payload[i])
						list[i] = {label: `${label}${holiday_.name}`, value: i, subtitle: holiday_.asRecurrenceString,
							dataId: holiday_.id, name: holiday_.name, dataA: holiday_.isCustom}
					}
					setHolidays([...list, {label: `${label}${translate(DICTIONARY.OTHER.X)}`, value: response.payload.length,
						dataId: -1, name: null, subtitle: " ", dataA: true}])
					// setSub(list[0].subtitle)
				}
			} catch (e) {
				console.log(e)
			}
		})
	}, [label, props.country])
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-4"
	const defaultValue = props.holiday ? props.holiday.isCustom ? -1 : props.holiday.id !== null ?
		props.holiday.id : holidays[0].dataId : holidays[0].dataId
	// console.log(defaultValue)
	const _index = holidays.findIndex(item => item.dataId === defaultValue)
	const defaultIndex = _index === -1 ? holidays.length - 1 : _index
	const defaultItem = sub ? sub : holidays[defaultIndex]
	// console.log("defaultItem", defaultItem)
	return <>
		<MobileInputGeneric type={"dropdown"} id={props.id} className={className} size={"1"} showLabel={props.showLabel}
			disabled={props.disabled} label={translate(DICTIONARY.HOLIDAY.X)} multiple={false} topRadius={props.topRadius}
			dataId={defaultItem.dataId} name={"holiday"} form={props.form} autoFocus={false} required={true} list={holidays}
			left={props.left} style={props.style} defaultValue={defaultItem.value} bottomRadius={props.bottomRadius}
			dataIndex={defaultItem.value} dataA={defaultItem.dataA}
			onChange={() => {
				const element = document.getElementById(`${props.id}-input`)
				element.setAttribute("data-id", holidays[element.value].dataId)
				element.setAttribute("data-index", element.value)
				element.setAttribute("data-a", holidays[element.value].dataA)
				setSub(holidays[element.value])
				holidays[element.value].dataId === -1 ? props.setOther(true) : props.setOther(false)
				onChange()
				// onChange(marinas[element.value])
		}}/>
		<div className={className} style={{padding: "1vh"}}>{defaultItem.subtitle}</div>
	</>
}

export default MobileHolidayDropdown