import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getOrganizationPublicProfile} from "../../../globals/functions/client/serverFunctions"
import {getFromStorage, setToStorage} from "../../../globals/functions/client/localFunctions"
import MobileImageAndLogo from "./MobileImageAndLogo"
import MobileBusinessRating from "../MobileBusinessRating"
import MobileContactInfo from "../contact/MobileContactInfo"
import MobileAddressList from "../address/MobileAddressList"
import MobileOrgLocation from "../address/MobileOrgLocation"
import MobileServiceArea from "../service/MobileServiceArea"
import MobileCurrencyAndTimezone from "../currency/MobileCurrencyAndTimezone"
import MobileServiceMarinas from "../../login/new/organization/MobileServiceMarinas"
import MobileSchedule from "../calendar/MobileSchedule"
import MobileServices from "../service/MobileServices"
import MobileOrganizationDocuments from "../MobileOrganizationDocuments"
import imagePlaceholder from "../../../images/image-duotone.svg"
import logoPlaceholder from "../../../images/pencil-paintbrush-duotone.svg"
import Organization from "../../../globals/classes/shared/Organization"
const MobileOrganizationPublicProfile = props => {
	const [organization, setOrganization] = useState(new Organization())
	const refresh = () => getOrganizationPublicProfile(false, props.id).then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {setOrganization(new Organization(response.payload))}})
	useEffect(() => {
		// console.log("MobileOrganizationPublicProfile useEffect called")
		getOrganizationPublicProfile(true, props.id).then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const organization_ = new Organization(response.payload)
				setOrganization(organization_)
			}})},[props.id])
	const top = "0vh"
	const addressArray = organization.addresses.asArray
	const showAddressAddBanner = !(
		(organization.addresses.physical.isMailing && organization.addresses.physical.isSupport) ||
		(organization.addresses.mailing.isSupport || organization.addresses.support.isMailing) ||
		(organization.addresses.physical.isPhysical && organization.addresses.mailing.isMailing &&
			organization.addresses.support.isSupport)
	)
	const logo_ = getFromStorage("logo")
	if (!logo_.path && organization.logo.path && organization.isParalianCustomer) {
		setToStorage("logo", organization.logo.path)
	}
	return <div className={"paralian-theme-level-1"} style={{paddingBottom: "2vh"}}>
		{/* Image and Logo */}
		<MobileImageAndLogo image={organization.image.path ? organization.image : null}
			imagePlaceholder={imagePlaceholder} logoPlaceholder={logoPlaceholder}
			logo={organization.logo.path ? organization.logo : null}
			showUpload={false} onClick={() => {}}/>
		{/* Display name */}
		<div style={{fontSize: "4vh", padding: "0vh 1vh 1vh 1vh"}} translate={"no"}>{organization.name.display}</div>
		{/* Rating */}
		<MobileBusinessRating rating={organization.metrics.rating}/>
		{/* Contact Info */}
		<MobileContactInfo contactInfo={organization.contactInfo} websites={organization.websites}
			isOrganization={true} refresh={refresh} canEdit={false}/>
		{/* Address */}
		<MobileAddressList addresses={addressArray} top={top} zIndex={2} allowDelete={false} isOrganization={true}
			showAddBanner={showAddressAddBanner} refresh={refresh} canEdit={false}/>
		{/* Location */}
		<MobileOrgLocation coordinates={organization.coordinates} canVisit={organization.serviceArea.welcomesVisitors}
			top={top} zIndex={3} refresh={refresh} canEdit={false} physicalAddress={organization.addresses.physical}/>
		{/* Service Area */}
		<MobileServiceArea area={organization.serviceArea} top={top} zIndex={4} refresh={refresh} canEdit={false}/>
		{/* Currency & Timezone */}
		<MobileCurrencyAndTimezone currency={organization.currency} timezone={organization.timezone}
			country={organization.addresses.physical.country} top={top} zIndex={5} canEdit={false} refresh={refresh}/>
		{/* Marina Service Access Status */}
		{/* TODO: only show list of approved/provisional status marinas */}
		<MobileServiceMarinas serviceApprovals={organization.serviceApprovals.sent} services={organization.categories}
			top={top}	zIndex={6} canEdit={false} refresh={refresh} documents={organization.documents} currency={organization.currency}
			country={organization.addresses.physical.country} state={organization.addresses.physical.state}/>
		{/* Schedule */}
		<MobileSchedule schedule={organization.schedule} holidays={organization.holidays} format={"amPM"}
			className={"paralian-theme-level-1"} top={top} zIndex={7} canEdit={false} refresh={refresh}/>
		{/* Services & Pricing */}
		{/* TODO: only show active services */}
		<MobileServices services={organization.services} top={top} zIndex={8} canEdit={false}
			country={organization.addresses.physical.country}	currency={organization.currency} refresh={refresh}/>
		{/* Documents */}
		<MobileOrganizationDocuments documents={organization.documents} top={top} zIndex={12} refresh={refresh} canEdit={false}/>
	</div>
}

export default MobileOrganizationPublicProfile