import { isObject, typeOrNull, enumOrNull } from '../../functions/shared/local';
import { VERIFICATION_STATE } from '../../constants/shared/enumerators';
import Image from './Image';

class Verification {
	/**
	 *
	 * @param {Verification} [verification = null]
	 * @param {number} [verification.id]
	 * @param {string} [verification.status]
	 * @param {string} [verification.name]
	 * @param {Image} [verification.image]
	 */
	constructor(verification = null) {
		if (isObject(verification)) {
			this.id = typeOrNull(verification.id, "number")
			this.status_ = enumOrNull(verification.status || verification.status_, VERIFICATION_STATE)
			this.name = typeOrNull(verification.name, "string")
			this.image = new Image(verification.image)
		} else {
			this.id = null
			this.status_ = null
			this.name = null
			this.image = new Image()
		}
	}

	get status(){
		return this.status_
	}

	set status(status) {
		this.status_ = enumOrNull(status, VERIFICATION_STATE)
	}
	
}

export default Verification;