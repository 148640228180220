import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"

const MobileIdentityVerificationInstructions = () => {
	return <div className={"nextStepsList paralian-mobile-info-card"}>
		<div className={"paralian-mobile-info-card-header"}>{translate(DICTIONARY.NEXT_STEPS.X)}</div>
		<ol style={{textAlign: "left"}}>
			<li>{translate(DICTIONARY.IVI_1.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_2.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_3.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_4.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_5.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_6.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_7.X)}</li><br/>
			<li>{translate(DICTIONARY.IVI_8.X)}</li><br/>
		</ol>
	</div>
}

export default MobileIdentityVerificationInstructions