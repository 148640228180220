import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileRigSpinnakerEdit from "./MobileRigSpinnakerEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileRigSpinnaker = props => {
	const [edit, setEdit] = useState(false)
	const [spinnaker, setSpinnaker] = useState({ISP: props.rig.ISP, SPL: props.rig.SPL, STL: props.rig.STL})
	useEffect(() => {
			setSpinnaker({ISP: props.rig.ISP, SPL: props.rig.SPL, STL: props.rig.STL})
		},
		[props.rig.ISP, props.rig.SPL, props.rig.STL])
	const content = edit ?
		<MobileRigSpinnakerEdit id={props.id} rig={props.rig} units={props.units} onSuccess={(isp, spl, stl) => {
			props.refresh().then()
			setSpinnaker({ISP: isp, SPL: spl, STL: stl})
			setEdit(false)}}/> : <>
			<MobileSubtitleAndValue units={props.units}
				subtitle={`ISP (${translate(DICTIONARY.HALYARD.X)} - ${translate(DICTIONARY.ELEVATION.X)})`}
				value={{number: spinnaker.ISP, units: "m", digits: 3}}/>
			<MobileSubtitleAndValue units={props.units}
				subtitle={`SPL (${translate(DICTIONARY.POLE.X)} - ${translate(DICTIONARY.LENGTH.X)})`}
				value={{number: spinnaker.SPL, units: "m", digits: 3}}/>
			<MobileSubtitleAndValue units={props.units}
				subtitle={`STL (${translate(DICTIONARY.TACK.X)} - ${translate(DICTIONARY.LENGTH.X)})`}
				value={{number: spinnaker.STL, units: "m", digits: 3}}/>
		</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.SPINNAKER.X)]}/>
		{content}
	</div>
}

export default MobileRigSpinnaker