import React from "react"
import {editOrgEmployee} from "../../../globals/functions/client/serverFunctions"
import submitToServer from "../../../globals/functions/client/submitToServer"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileAdminInput from "./MobileAdminInput"

/**
 *
 * @param {Object} props
 * @param {Employee} props.admin
 * @param {React.HTMLAttributes.className} props.className
 * @param {React.HTMLAttributes.style} props.style
 * @param {function} props.onSubmit
 * @return {JSX.Element}
 * @constructor
 */
const MobileAdminEdit = props => {
	const onSubmit = async event => {
		const title = document.getElementById(`${props.admin.id}-title-input`).value
		const status = document.getElementById(`active-button-admin-${props.admin.id}`)
			.getAttribute("data-active")
		const input = {id: props.admin.id, title: title, status: status}
		await submitToServer(event, input, editOrgEmployee, props.onSubmit)
	}
	const content = <>
		<div style={{fontSize: "3vh"}}>{props.admin.contact.name.given}</div>
		<div style={{fontSize: "2.5vh"}}>{props.admin.contact.contactInfo.emails.primary.address}</div>
		<MobileAdminInput admin={props.admin} style={props.style}	className={props.className} showName={false}
			showEmail={false} showLabel={true} showActive={true}/>
	</>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={onSubmit}/>

}

export default MobileAdminEdit