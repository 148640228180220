import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileWeekFields from "./MobileWeekFields"
import MobileInputWeekEdit from "./MobileInputWeekEdit"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Modal from "../../../globals/components/Modal"
import Week from "../../../globals/classes/shared/Week"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Week} props.week
 * @param {boolean} props.use24Hr
 * @returns {JSX.Element}
 * @constructor
 */
const  MobileWeek = props => {
	const [edit, setEdit] = useState(false)
	const [week, setWeek] = useState(new Week(props.week))
	useEffect(() => setWeek(props.week), [props.week])
	const onSubmit = week => {
		props.refresh().then()
		setWeek(week)
		setEdit(false)
	}
	return <div id={"service-area"} className={"w3-animate-top w3-animate-opacity paralian-mobile-info-card"}
		style={{textAlign: "center", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.STANDARD_WEEK.X)]}/>
		{edit ? <Modal id={"standard-week-edit-modal"} padding={"0vh"} icon={ICONS.CALENDAR_ALT}
			title={translate(DICTIONARY.STANDARD_WEEK.X)} onClick={() => setEdit(false)} body={
				<div style={{margin: "2vh"}}>
					<MobileInputWeekEdit id={props.id} week={week} use24Hr={props.use24Hr} form={props.form}
						 className={props.className} onSubmit={onSubmit}/>
				</div>
				}/> :	<MobileWeekFields week={props.week} use24Hr={props.use24Hr}/>
		}
	</div>
}

export default MobileWeek