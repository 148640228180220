import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {ICONS} from "../../../../globals/constants/client/constants"
import {setPhysicalFacilitiesAndAmenities} from "../../../../globals/functions/client/serverFunctions"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileIconExpandToText from "../MobileIconExpandToText"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"

const MobileVesselPhysicalFacilitiesAndAmenitiesEdit = props => {
	const [selected, setSelected] = useState(props.selected)
	const [text, setText] = useState("")
	const onSubmit = async event => {
		event.preventDefault()
		const response = await setPhysicalFacilitiesAndAmenities(selected)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess()
		}
	}
	const keys = props.all.values.filter(v => v.display === "PHYSICAL").map(v => v.type)
	const content = <div>
		<div className={"paralian-mobile-info-card-header"} style={{marginBottom: "1vh"}}>
			{text}
		</div>
		{keys.map(key => {
			const isSelected = selected.includes(key)
			const facility_ = props.all.byIndex(key)
			const icon = facility_ && ICONS[facility_.icon] ? [facility_.iconType, ICONS[facility_.icon][1]] :
				ICONS.CHECK_CIRCLE
			return <div key={`facility-icon-${key}`} style={{display: "inline-block",
				color: isSelected ? "inherit" : "lightblue", opacity: isSelected ? 1 : 0.5}} onClick={() => {
				if (isSelected) {
					// console.log("isSelected - deselecting")
					setSelected(selected.filter(v => v !== key))
				} else {
					// console.log("notSelected - selecting")
					setSelected([...selected, key])
				}
			}}>
				<MobileIconExpandToText label={translate(key)} setText={setText} icon={icon}/>
			</div>
		})
		}
	</div>
	return <MobileInputForm id={"physical-facilities-edit"} content={content} className={" "}
		disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileVesselPhysicalFacilitiesAndAmenitiesEdit