import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {setHullCapacity} from "../../../../globals/functions/client/serverFunctions"
import {convertToPreferredUnits, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputVolume from "../MobileInputVolume"
import MobileInputWeight from "../MobileInputWeight"
import MobileInputPower from "../MobileInputPower"
import MobileSubtitleAndContent from "../../MobileSubtitleAndContent"
import MobileInputGeneric from "../../../shared/input/MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {HullCapacity} props.capacity
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullCapacityEdit = props => {
	const onSubmit = async event => {
		try {
			event.preventDefault()
			let fuel = Number(`${document.getElementById("hull-fuel-whole-input").value.replace(/[,.]/g,'')}`)
			let water = Number(`${document.getElementById("hull-water-whole-input").value.replace(/[,.]/g,'')}`)
			let waste = Number(`${document.getElementById("hull-waste-whole-input").value.replace(/[,.]/g,'')}`)
			let livewell = Number(`${document.getElementById("hull-livewell-whole-input").value.replace(/[,.]/g,'')}`)
			let sleeps = Number(`${document.getElementById("hull-sleeping-input").value.replace(/[,.]/g,'')}`)
			let personsQuantity = Number(`${document.getElementById("hull-persons-quantity-input").value.replace(/[,.]/g,'')}`)
			let personsWeight = Number(`${document.getElementById("hull-persons-weight-whole-input").value.replace(/[,.]/g,'')}`)
			let load = Number(`${document.getElementById("hull-load-whole-input").value.replace(/[,.]/g,'')}`)
			let motor = Number(`${document.getElementById("hull-motor-whole-input").value.replace(/[,.]/g,'')}`)
			if (props.units !== "SI") {
				fuel = convertToPreferredUnits(fuel, "gal", "SI", 6, false, false)
				water = convertToPreferredUnits(water, "gal", "SI", 6, false, false)
				waste = convertToPreferredUnits(waste, "gal", "SI", 6, false, false)
				livewell = convertToPreferredUnits(livewell, "gal", "SI", 6, false, false)
				personsWeight = convertToPreferredUnits(personsWeight, "lb", "SI", 6, false, false)
				load = convertToPreferredUnits(load, "lb", "SI", 6, false, false)
				motor = convertToPreferredUnits(motor, "hp", "SI", 6, false, false)
			}
			const response = await setHullCapacity(props.id, fuel, water, waste, personsQuantity, personsWeight, load, motor, sleeps, livewell)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.onSuccess({freshWater: water, fuel: fuel, holdingTank: waste, sleeping: sleeps,
					persons: {quantity: personsQuantity, weight: personsWeight}, load: load, motor: motor, livewell: livewell})
			}
		} catch (e) {
			console.log(e)
		}
	}
	const content = <>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.PERSONS.X)} content={
			<>
				<div style={{display: "inline-flex", width: "100%"}}>
					<MobileInputGeneric type={"number"} id={"hull-persons-quantity"} name={"hull-persons-weight"} form={`hin-form`}
						autoFocus={true} maxLength={4} autoComplete={"off"}	required={false} placeholder={"#".repeat(4)}
						min={"0"} max={"9999"} step={1}	defaultValue={props.capacity.persons.quantity} style={{textAlign: "center"}}
						label={translate(DICTIONARY.QUANTITY.X)} showLabel={false} className={"paralian-theme-level-1"}/>
					<b style={{margin: "auto", paddingRight: "2vh"}}>{translate(DICTIONARY.PERSONS.X)}</b>
				</div>
				<div style={{minHeight: "1vh"}} className={"paralian-theme-level-2"}/>
				<MobileInputWeight id={"hull-persons-weight"} label={translate(DICTIONARY.WEIGHT.X)} units={props.units}
					value={props.capacity.persons.weight} className={"paralian-theme-level-1"}/>
			</>}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.SLEEPING.X)} content={
			<div style={{display: "inline-flex", width: "100%"}}>
				<MobileInputGeneric type={"number"} id={"hull-sleeping"} name={"hull-sleeping"} form={`hin-form`}
					autoFocus={true} maxLength={4} autoComplete={"off"}	required={false} placeholder={"#".repeat(4)}
					min={"0"} max={"9999"} step={1}	defaultValue={props.capacity.sleeping} style={{textAlign: "center"}}
					label={translate(DICTIONARY.QUANTITY.X)} showLabel={false} className={"paralian-theme-level-1"}/>
				<b style={{margin: "auto", paddingRight: "2vh"}}>{translate(DICTIONARY.PERSONS.X)}</b>
			</div>}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.LOAD.X)} content={
			<MobileInputWeight id={"hull-load"} value={props.capacity.load} units={props.units}
				form={`hin-form`} label={translate(DICTIONARY.LOAD.X)} className={"paralian-theme-level-1"}/>}/>
		<MobileSubtitleAndContent className={"paralian-theme-level-1"} subtitle={translate(DICTIONARY.MOTOR.X)} content={
			<MobileInputPower id={"hull-motor"} label={translate(DICTIONARY.MOTOR.X)}
			 form={`hin-form`} units={props.units} value={props.capacity.motor} className={"paralian-theme-level-1"}/>}/>
		<MobileInputVolume id={"hull-fuel"} label={translate(DICTIONARY.FUEL.X)} units={props.units}
			value={props.capacity.fuel} className={"paralian-theme-level-1"}/>
		<MobileInputVolume id={"hull-water"} label={translate(DICTIONARY.FRESH_WATER.X)} units={props.units}
			value={props.capacity.freshWater} className={"paralian-theme-level-1"}/>
		<MobileInputVolume id={"hull-waste"} label={translate(DICTIONARY.HOLDING_TANK.X)} units={props.units}
			value={props.capacity.holdingTank} className={"paralian-theme-level-1"}/>
		<MobileInputVolume id={"hull-livewell"} label={translate(DICTIONARY.LIVEWELL.X)} units={props.units}
			value={props.capacity.livewell} className={"paralian-theme-level-1"}/>
	</>
	return <MobileInputForm id={`hin-form`} content={content} className={" "} disableAnimateOpacity={true} onSubmit={onSubmit}/>
}

export default MobileVesselHullCapacityEdit