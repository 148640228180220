import React from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {toggleOverflow, translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "./MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {JSX.Element|string} [props.left]
 * @param {function} [props.onDelete]
 * @param {string[]} props.labels
 * @param {JSX.Element|string} [props.subtitle]
 * @param {boolean} props.edit
 * @param {boolean} props.canEdit
 * @param {function} props.setEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileInfoCardHeader = props => {
	return <div className={"paralian-mobile-info-card-header"} style={{display: "flex", marginBottom: "1vh"}}>
		<div style={{minWidth: "15%", textAlign: "left"}}>
			{props.left ? props.left : <></>}
			{props.edit && props.onDelete ?
				<MobileFontAwesome icon={ICONS.TRASH} style={{fontSize: "3vh"}}	label={translate(DICTIONARY.DELETE.X)}
					className={"w3-animate-opacity"} onClick={props.onDelete}/> : <></>}
		</div>
		<div id={`display-subtitle-info-card-header-${props.subtitle}`} style={{width: "70%", textAlign: "center"}}
		 	className={"paralian-overflow-nowrap-ellipsis"}
		 	onClick={() => toggleOverflow(`display-subtitle-info-card-header-${props.subtitle}`)}>
			<div><i>{props.edit ? props.labels[props.labels.length > 1 ? 1 : 0] : props.labels[0]}</i></div>
			{props.subtitle ? <div><i>{props.subtitle}</i></div> : <></>}
		</div>
		<div style={{minWidth: "15%", textAlign: "right"}}>
			{props.canEdit ? <MobileFontAwesome icon={props.edit ? ICONS.BACK : ICONS.PENCIL} style={{paddingLeft: "1.5vh"}}
				label={props.edit ? translate(DICTIONARY.BACK.X) : translate(DICTIONARY.EDIT.X)}
				className={"w3-animate-opacity"}
				onClick={() => {
					props.setEdit(!props.edit)
				}}/> : <></>}
		</div>
	</div>
}

export default MobileInfoCardHeader