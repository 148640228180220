import Phone from './Phone';
import { isObject } from '../../functions/shared/local';

class Phones {
	/**
	 *
	 * @param {Phones} [phones = null]
	 * @param {Phone} [phones.primary]
	 * @param {Phone} [phones.secondary]
	 */
	constructor(phones = null) {
		if (isObject(phones)) {
			this.primary = new Phone(phones.primary)
			this.secondary = new Phone(phones.secondary)
		} else {
			this.primary = new Phone()
			this.secondary = new Phone()
		}
	}
}

export default Phones;