import {AUTHENTICATION_STATES, DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"

const respondToPasswordCheck = status => {
	switch (status) {
		case AUTHENTICATION_STATES.NO_LINKS_BETWEEN_PERSONAL_ACCOUNTS:
			newMessage("linkedPersonal", translate(DICTIONARY.FORBIDDEN.X),
				translate(DICTIONARY.CANT_LINK_PERSONAL_ACCOUNTS.X), "w3-pale-yellow", ICONS.BAN)
			break
		case AUTHENTICATION_STATES.INCORRECT_PASSWORD:
			newMessage("incorrectPassword",translate(DICTIONARY.INCORRECT_PASSWORD.X),
				translate(DICTIONARY.INCORRECT_PASSWORD_LOGIN_MESSAGE.X), "w3-pale-red", ICONS.BAN)
			break
		case AUTHENTICATION_STATES.TOO_MANY_LOGIN_ATTEMPTS:
			newMessage("tooManyLogin", translate(DICTIONARY.TOO_MANY_LOGIN_ATTEMPTS.X),
				translate(DICTIONARY.TOO_MANY_LOGIN_ATTEMPTS_MESSAGE.X), "w3-pale-red", ICONS.EXCLAMATION_CIRCLE)
			break
		case AUTHENTICATION_STATES.ERROR_VERIFYING_PASSWORD:
			newMessage("errorVerifying", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.ERROR_406_MESSAGE.X),
				"w3-pale-red", ICONS.EXCLAMATION_CIRCLE)
			break
		default:
			newMessage("noMatch", translate(DICTIONARY.ERROR.X), translate(DICTIONARY.ERROR_406_MESSAGE.X),
				"w3-pale-red", ICONS.EXCLAMATION_CIRCLE)
			break
	}
}

export default respondToPasswordCheck