import React from "react"
import MobileSVGIcon from "./MobileSVGIcon"

const MooringIcon = props => {
	const icon = <g>
		<path stroke={"currentColor"} id={"svg_2"}
			d={"m562.2,307.86c8.1,0.9 13.9,8.3 13.8,16.3l0,31.61c0,9.1 -7.6,16.8 -16.7,16a185,185 0 0 1" +
			" -79.12,-25.77c-55,34.11 -135.52,34.61 -192,0c-55,34.11 -135.52,34.61 -192,0c-23.31,14.5 -50.61,23.4 " +
			"-79.42,25.9c-9.1,0.8 -16.7,-6.9 -16.7,-16l0,-32.24c0,-7.9 5.7,-14.9 13.6,-15.7c21.71,-2.3 42.21,-10.2 " +
			"57.71,-22.41c13.8,-10.9 33.61,-13.2 47.11,-2c38.31,31.71 107.22,31.81 145.23,1.7c13.7,-10.9 33.2,-13 " +
			"46.7,-1.8c38.51,31.91 107.82,31.91 145.73,1.5a38.79,38.79 0 0 1 48.21,0c15.8,12.41 36.4,20.41 " +
			"57.91,22.91l-0.06,0z"}	fill={"currentColor"} className={"fa-secondary"}/>
		<ellipse fillOpacity={"0.4"} ry={"160"} rx={"160"} id={"svg_4"} cy={"256"} cx={"288"} strokeWidth={"0"}
			stroke={"currentColor"} fill={"currentColor"}/>
	</g>
	return <MobileSVGIcon icon={icon} onClick={props.onClick}/>
}

export default MooringIcon