import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {convertToPreferredUnits, fadingMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileVesselHullCapacityEdit from "./MobileVesselHullCapacityEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"
import HullCapacity from "../../../../globals/classes/shared/vessels/HullCapacity"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.units - SI or US
 * @param {HullCapacity} props.capacity
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselHullCapacity = props => {
	const [edit, setEdit] = useState(false)
	const [capacity, setCapacity] = useState(props.capacity)
	const content = edit ? <MobileVesselHullCapacityEdit id={props.id} units={props.units} capacity={capacity}
		onSuccess={capacity => {
			props.refresh().then()
			setCapacity(new HullCapacity(capacity))
			setEdit(false)
	}}/> : <>
		<div style={{margin: "2vh"}}>
			<div style={{textAlign: "left"}}><i>{translate(DICTIONARY.PERSONS.X)}</i></div>
			<div style={{textAlign: "right", fontSize: "3vh"}} className={"w3-animate-opacity"}>
				{
					`${capacity.persons.quantity || "#"} ${translate(DICTIONARY.PERSONS.X)} ${translate(DICTIONARY.OR.X).toUpperCase()} ` +
					`${capacity.persons.weight ? convertToPreferredUnits(capacity.persons.weight, "kg", 
						props.units, 0) : `# ${props.units === "SI" ? "kg" : "lb"}`}`
				}
			</div>
		</div>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.SLEEPING.X)} units={props.units}
			value={{text: capacity.sleeping ? `${capacity.sleeping} ${translate(DICTIONARY.PERSONS.X)}` :
				`# ${translate(DICTIONARY.PERSONS.X)}`}}/>
		<div style={{margin: "2vh"}}>
			<div style={{textAlign: "left"}}>
				<i>{translate(DICTIONARY.LOAD.X)}</i>
				<MobileFontAwesome icon={ICONS.INFO_CIRCLE} label={translate(DICTIONARY.LOAD.X)} style={{paddingLeft: "1vh"}}
					onClick={() => fadingMessage("load-capacity-message", translate(DICTIONARY.LOAD.X),
						translate(DICTIONARY.PERSONS_MOTOR_AND_GEAR.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}/>
			</div>
			<div style={{textAlign: "right", fontSize: "3vh"}} className={"w3-animate-opacity"}>
				{capacity.load ? convertToPreferredUnits(capacity.load, "kg", props.units, 0) :
					props.units === "SI" ? "# kg" : "# lb"}
			</div>
		</div>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.MOTOR.X)} units={props.units}
			value={{number: capacity.motor, units: "kW", text: `# ${props.units === "SI" ? "kW" : "hp"}`}}/>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.FUEL.X)}	units={props.units}
			value={{number: capacity.fuel, units: "l", text: `# ${props.units === "SI" ? "L" : "gal"}`}}/>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.FRESH_WATER.X)} units={props.units}
			value={{number: capacity.freshWater, units: "l", text: `# ${props.units === "SI" ? "L" : "gal"}`}}/>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.HOLDING_TANK.X)} units={props.units}
			value={{number: capacity.holdingTank, units: "l", text: `# ${props.units === "SI" ? "L" : "gal"}`}}/>
		<MobileSubtitleAndValue subtitle={translate(DICTIONARY.LIVEWELL.X)} units={props.units}
			value={{number: capacity.livewell, units: "l", text: `# ${props.units === "SI" ? "L" : "gal"}`}}/>
	</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.CAPACITY.X)]}/>
		{content}
	</div>
}

export default MobileVesselHullCapacity