import React, {useState} from "react"
import {ICONS} from "../../../globals/constants/client/constants"
import doc from "../../../images/file-pdf-duotone.svg"
import MobileFontAwesome from "../MobileFontAwesome"
import Modal from "../../../globals/components/Modal"
/**
 *
 * @param {Object} props
 * @param {Object} props.requirements
 * @param {Object} props.document
 * @return {JSX.Element}
 * @constructor
 */
const MobileRequiredDocumentVerificationCard = props => {
	// console.log("MobileRequiredDocumentVerificationCard props", props)
	const [showDocument, setShowDocument] = useState(false)
	const requirement = props.requirements.byIndex(props.document.requirement)
	const pdf = showDocument ? <Modal icon={ICONS.FILE_PDF} onClick={() => setShowDocument(false)}
		id={`${requirement.id}-modal`} title={requirement.label} padding={"0vh"} body={
		<div className={"w3-display-container"} style={{minHeight: "10vh", padding: "1vh 2vh"}}>
			<picture style={{width: "22.5vh", paddingTop: "1.5vh"}}>
				{props.document.path ? <a href={props.document.path} download={`${props.document.name}.pdf`}
					target={"_blank"} rel={"noreferrer"}>
					<MobileFontAwesome icon={ICONS.DOWNLOAD} className={"w3-display-middle"}
						style={{padding: "1.5vh",	zIndex: "3", fontSize: "10vh"}}/>
				</a> : <></>}
				<img id={`${props.id}-img`} className={"w3-display-container"} alt={props.document.name}
					src={props.document.image || doc} style={{height: "100%", width: "100%"}}
					onError={() => document.getElementById(`${props.id}-img`).src = doc}/>
			</picture>
		</div>
	}/> : <></>

	return <>
		{pdf}
		<div style={{cursor: "pointer", margin: "1vh 0vh", padding: "2vh"}} className={"w3-ripple"}
		 	onClick={() => setShowDocument(true)}>
			{requirement.label}
			<MobileFontAwesome icon={ICONS.FILE_PDF} style={{paddingLeft: "1.5vh"}}/>
		</div>
	</>
}

export default MobileRequiredDocumentVerificationCard