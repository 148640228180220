import {typeOrNull, enumOrNull} from '../../../functions/shared/local'
import {FUEL, ENGINE_CLASS} from '../../../constants/shared/enumerators'

class Engine {
	/**
	 *
	 * @param {Engine} [engine = null]
	 * @param {number} [engine.id]
	 * @param {string} [engine.label]
	 * @param {string} [engine.serialNumber]
	 * @param {string} [engine.modelNumber]
	 * @param {string} [engine.brand]
	 * @param {string} [engine.modelLine]
	 * @param {string} [engine.model]
	 * @param {number} [engine.year]
	 * @param {number} [engine.power]
	 * @param {number} [engine.weight]
	 * @param {boolean} [engine.isCounterRotating]
	 * @param {string} [engine.fuel_]
	 * @param {string} [engine.classification_]
	 * @param {number} [engine.hours]
	 */
	constructor (engine = null) {
		try {
			this.id = typeOrNull(engine.id, "number")
			this.label = typeOrNull(engine.label, "string")
			this.serialNumber = typeOrNull(engine.serialNumber, "string")
			this.modelNumber = typeOrNull(engine.modelNumber, "string")
			this.brand = typeOrNull(engine.brand, "string")
			this.modelLine = typeOrNull(engine.modelLine, "string")
			this.model = typeOrNull(engine.model, "string")
			this.year = typeOrNull(engine.year, "number")
			this.power = typeOrNull(engine.power, "number")
			this.weight = typeOrNull(engine.weight, "number")
			this.isCounterRotating = typeOrNull(engine.isCounterRotating, "boolean")
			this.fuel_ = enumOrNull(engine.fuel || engine.fuel_, FUEL)
			this.classification_ = enumOrNull(engine.classification || engine.classification_, ENGINE_CLASS)
			this.hours = typeOrNull(engine.hours, "number")
		} catch (e) {
			this.id = null
			this.label = null
			this.serialNumber = null
			this.modelNumber = null
			this.brand = null
			this.modelLine = null
			this.model = null
			this.year = null
			this.power = null
			this.weight = null
			this.isCounterRotating = null
			this.fuel_ = null
			this.classification_ = null
			this.hours = null
		}
	}
	
	get fuel(){
		return this.fuel_
	}

	set fuel(fuel) {
		this.fuel_ = enumOrNull(fuel, FUEL)
	}

	get classification(){
		return this.classification_
	}

	set classification(classification) {
		this.classification_ = enumOrNull(classification, ENGINE_CLASS)
	}

}

export default Engine