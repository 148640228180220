import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {ID_TYPE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {shareDocument, unshareDocument} from "../../../globals/functions/client/serverFunctions"
import doc from "../../../images/file-pdf-duotone.svg"
import containerAndMinimalTypeToDocumentType from "./containerAndMinimalTypeToDocumentType"
import submitDocumentUpload from "./submitDocumentUpload"
import documentContainerToSubject from "./documentContainerToSubject"
import appendFileUploadFormData from "../../shared/input/file/appendFileUploadFormData"
import submitToServer from "../../../globals/functions/client/submitToServer"
import MobileFontAwesome from "../MobileFontAwesome"
import Modal from "../../../globals/components/Modal"
import MobileHeaderAndContent from "../MobileHeaderAndContent"
import MobileDocumentSelectOrUpload from "./MobileDocumentSelectOrUpload"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Document from "../../../globals/classes/shared/Document"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.requirement
 * @param {number} props.requirement.id
 * @param {string} props.requirement.container
 * @param {string} props.requirement.type
 * @param {string} props.requirement.specifications
 * @param {string} props.requirement.label
 * @param {number} props.requirement.organization
 * @param {IndexedArray[Document]} props.documents
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileRequiredDocumentDocumentCard = props => {
	const [edit, setEdit] = useState(false)
	const [showSpecifications, setShowSpecifications] = useState(false)
	const [showDocument, setShowDocument] = useState(false)
	const [upload, setUpload] = useState(false)
	const requirementId = props.requirement.id
	const specificationsDocument = new Document({name: props.requirement.label, path: props.requirement.pdf, image: {path: props.requirement.img}})
	const ud = props.requirement.shared.length > 0 ? props.requirement.shared[0] : null
	const uploadedDocument = ud ? new Document({id: ud.id, image: {path: ud.image}, name: ud.name,
		path: ud.path}) : new Document()
	const canShowDocument = showDocument && uploadedDocument.path || showSpecifications && specificationsDocument.path
	const onSuccess = () => {
		props.refresh().then()
		setEdit(false)
		setUpload(false)
		fadingMessage("share-success", translate(DICTIONARY.SUCCESS_EXCLAMATION.X))
	}
	const onUpload = async event => {
		event.preventDefault()
		try {
			const documentId = document.getElementById(`${requirementId}-doc-select-upload-dropdown-input`).value
			if (documentId === "-2" && ud) {
				const params = {idType: ID_TYPE.ORGANIZATION, id: props.requirement.organization, requirement: requirementId, document: ud.id}
				await submitToServer(event, params, unshareDocument, onSuccess)
			} else if (documentId === "-1") {
				const formData = new FormData()
				const params = {requirement: requirementId, idType: ID_TYPE.ORGANIZATION,	id: props.requirement.organization}
				appendFileUploadFormData(`${requirementId}`, formData).then(updatedFormData =>
					submitDocumentUpload(updatedFormData, `new-${requirementId}-upload`, onSuccess, props.requirement.container,
						containerAndMinimalTypeToDocumentType(props.requirement.container, props.requirement.type), -1,
						null, params))
			} else {
				const params = {requirement: requirementId, document: documentId, idType: ID_TYPE.ORGANIZATION,
					id: props.requirement.organization}
				await submitToServer(event, params, shareDocument, onSuccess)
			}
		} catch (e) {
			console.log(e)
		}
	}

	const upload_ = upload ? <MobileDocumentSelectOrUpload title={props.requirement.label} onSubmit={onUpload}
		documents={props.documents} selected={ud ? ud.id : "-1"} currency={props.currency} country={props.country} state={props.state}
		type={containerAndMinimalTypeToDocumentType(props.requirement.container, props.requirement.type)} id={requirementId}
		label={props.requirement.label} onClick={() => setUpload(false)} container={props.requirement.container}
		className={"paralian-theme-level-1"} info={translate(DICTIONARY.PROVIDE_REQUIRED_DOCUMENT_SPEC_DOC.X)}/> : <></>

	const pdf = showDocument || showSpecifications ?
		<Modal id={`${props.id}-modal`} icon={ICONS.FILE_PDF} padding={"0vh"}
			onClick={() => showDocument ? setShowDocument(false) : setShowSpecifications(false)}
			body={
				<div className={"w3-display-container"} style={{minHeight: "10vh", padding: "1vh 2vh"}}>
					<picture style={{width: "22.5vh", paddingTop: "1.5vh"}}>
						{
							canShowDocument ?
								<a href={showDocument ? uploadedDocument.path : specificationsDocument.path}
									 download={`${showDocument ? uploadedDocument.name : specificationsDocument.name}.pdf`}
									 target={"_blank"} rel={"noreferrer"}>
									<MobileFontAwesome icon={ICONS.DOWNLOAD} className={"w3-display-middle"}
										 style={{padding: "1.5vh",	zIndex: "3", fontSize: "10vh"}}/>
								</a> : <></>
						}
						<img id={`${props.id}-img`} className={"w3-display-container"}
						 	alt={showDocument ? uploadedDocument.name : specificationsDocument.name}
							src={showDocument ? uploadedDocument.image.path : specificationsDocument.image.path || doc}
						 	style={{height: "100%", width: "100%"}}
							onError={() => document.getElementById(`${props.id}-img`).src = doc}/>
					</picture>
				</div>
	}/> : <></>
	const onDelete = async () => {
		const params = {idType: ID_TYPE.ORGANIZATION, id: props.requirement.organization,
			requirement: requirementId, document: ud.id}
		const response = await unshareDocument(params)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			onSuccess()
		}
	}
	const content = <>
		{pdf}
		{upload_}
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={ud ? props.canEdit : false} onDelete={ud ? onDelete : null}
			labels={[translate(props.requirement.label)]}/>
		{ ud ? <></> :
			<div style={{margin: "1vh auto", padding: "1vh", color: "black", backgroundColor: "orange", borderRadius: "1vh"}}
					 onClick={() => {newMessage(`required-document-not-provided-message`,
						 translate(DICTIONARY.NONE_PROVIDED.X),	translate(DICTIONARY.REQUESTED_DOCUMENT_NOT_PROVIDED.X),
						 "w3-pale-blue", ICONS.MINUS_CIRCLE)}}>
				{translate(DICTIONARY.NONE_PROVIDED.X)}
				<MobileFontAwesome icon={ICONS.MINUS_CIRCLE} style={{paddingLeft: "1.5vh"}}/>
			</div>
		}
		<div style={{textAlign: "left"}}>
			<MobileHeaderAndContent name={"document"} header={translate(DICTIONARY.DOCUMENT.X)}
				content={
					<div style={{display: "inline-flex"}}>
						<div>
							{ud ? uploadedDocument.name : translate(DICTIONARY.NONE_PROVIDED.X)}
							{ud && !edit ? <MobileFontAwesome icon={ICONS.FILE_PDF} style={{paddingLeft: "1.5vh"}}
								onClick={() => setShowDocument(true)}/> : <></>}
						</div>
						{
							edit || !ud ? <div style={{whiteSpace: "nowrap"}}>
								<i style={{paddingLeft: "1.5vh", opacity: "0.5", fontWeight: "normal", cursor: "pointer"}}
									 className={"w3-ripple"} onClick={() => setUpload(true)}>
									{ud ? translate(DICTIONARY.REPLACE.X) : translate(DICTIONARY.UPLOAD.X)}
									<MobileFontAwesome icon={ud ? ICONS.REPEAT : ICONS.UPLOAD}
										style={{paddingLeft: "1.5vh"}}/>
								</i>
							</div> : <></>}
					</div>
				}/>
			<MobileHeaderAndContent name={"subject"} header={translate(DICTIONARY.SUBJECT.X)}
				content={translate(documentContainerToSubject(props.requirement.container))}/>
			<MobileHeaderAndContent name={"capacity"} header={translate(DICTIONARY.CAPACITY.X)}
				content={props.requirement.customers && props.requirement.providers ?
					translate(DICTIONARY.CUSTOMER.X) + " " + translate(DICTIONARY.OR.X)+ " " + translate(DICTIONARY.PROVIDER.X) :
					props.requirement.customers ? translate(DICTIONARY.CUSTOMER.X) : translate(DICTIONARY.PROVIDER.X)
				}/>
			<MobileHeaderAndContent name={"type"} header={translate(DICTIONARY.DOCUMENT_TYPE.X)}
				content={translate(props.requirement.type)}/>
			<MobileHeaderAndContent name={"requirement"} header={translate(DICTIONARY.REQUIREMENTS.X)}
				content={
					<div style={{display: "inline-flex"}}>
						<div>
							{specificationsDocument.path ? specificationsDocument.name : translate(DICTIONARY.NONE_PROVIDED.X)}
							{specificationsDocument.path ? <MobileFontAwesome icon={ICONS.FILE_PDF} style={{paddingLeft: "1.5vh"}}
								onClick={() => setShowSpecifications(true)}/> : <></>}
						</div>
					</div>
				}/>
		</div>
	</>
	return <div style={{padding: "2vh", margin: "2vh"}}>{content}</div>
}

export default MobileRequiredDocumentDocumentCard