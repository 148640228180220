import {typeOrNull, enumOrNull} from '../../functions/shared/local'
import {VESSEL_TYPE} from '../../constants/shared/enumerators'
import Name from './Name'
import VesselAccess from './vessels/VesselAccess'
import Image from './Image'
import Documents from './Documents'
import AIS from './vessels/AIS'
import Rig from './vessels/Rig'
import Hull from './vessels/Hull'
import Storage from './vessels/Storage'
import IMO from "./textInputFilters/IMO"
import CallSign from "./textInputFilters/CallSign"
const CUSTOMER = Object.freeze({PERMANENT: "PERMANENT", TEMPORARY: "TEMPORARY"})
/* TODO: privatize with properties_ with  when supported */

class Vessel {
	/**
	 *
	 * @param {Vessel} [vessel = null]
	 * @param {number} [vessel.id]
	 * @param {Name} [vessel.name]
	 * @param {string} [vessel.type]
	 * @param {VesselAccess} [vessel.access]
	 * @param {Image} [vessel.image]
	 * @param {Documents} [vessel.documents]
	 * @param {Engine[]} [vessel.engines]
	 * @param {string} [vessel.registration]
	 * @param {number} [vessel.documentation]
	 * @param {AIS} [vessel.ais]
	 * @param {Rig} [vessel.rig]
	 * @param {Tank[]} [vessel.tanks]
	 * @param {Hull} [vessel.hull]
	 * @param {Storage} [vessel.facilities]
	 * @param {VesselHome[]} [vessel.homes]
	 * @param {string} [vessel.imo]
	 * @param {string} [vessel.callSign]
	 * @param {string} [vessel.customer]
	 */
	constructor(vessel = null) {
		try {
			this.id = vessel.id
			this.name = new Name(vessel.name)
			this.type_ = typeOrNull(vessel.type || vessel.type_, "string")
			this.access = new VesselAccess(vessel.access)
			this.image = new Image(vessel.image)
			this.documents = new Documents(vessel.documents)
			this.engines = typeOrNull(vessel.engines, "array")
			this.registration = typeOrNull(vessel.registration, "string")
			this.documentation = typeOrNull(vessel.documentation, "string")
			this.ais = new AIS(vessel.ais)
			this.rig = new Rig(vessel.rig)
			this.tanks = typeOrNull(vessel.tanks, "array")
			this.hull = new Hull(vessel.hull)
			this.storage = new Storage(vessel.storage)
			this.homes = typeOrNull(vessel.homes, "array")
			this.imo = new IMO(vessel.imo).filtered
			this.callSign = new CallSign(vessel.callSign).filtered
			this.customer_ = enumOrNull(vessel.customer || vessel.customer_, CUSTOMER)
		} catch (e) {
			this.id = null
			this.name = new Name()
			this.type_ = null
			this.access = new VesselAccess()
			this.image = new Image()
			this.documents = new Documents()
			this.engines = []
			this.registration = null
			this.documentation = null
			this.ais = new AIS()
			this.rig = new Rig()
			this.tanks = []
			this.hull = new Hull()
			this.storage = new Storage()
			this.homes = []
			this.imo = null
			this.callSign = null
			this.customer_ = null
		}
	}

	get type(){
		return this.type_
	}

	set type(type) {
		this.type_ = enumOrNull(type, VESSEL_TYPE)
	}

	get customer(){
		return this.customer_
	}

	set customer(customer) {
		this.customer_ = enumOrNull(customer, CUSTOMER)
	}

}

export default Vessel