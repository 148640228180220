import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string} props.form
 * @param {string} props.className
 * @param {string} props.defaultValue
 * @param {boolean} props.autoFocus
 * @param {boolean} props.showLabel
 * @constructor
 */
const MobileInputJobTitle = props => {
	return <MobileInputText id={`${props.id}-title`} className={props.className} form={props.form}
		placeholder={translate(DICTIONARY.JOB_TITLE.X)}	label={translate(DICTIONARY.JOB_TITLE.X)} required={props.required}
		name={"title"} pattern={"([a-zA-Z- ]){2,128}"} autoFocus={props.autoFocus} showLabel={props.showLabel}
		defaultValue={props.defaultValue} left={{icon: ICONS.ID_BADGE}} right={{icon: ICONS.INFO_CIRCLE, onClick: () => {
			newMessage("jobTitle-message", translate(DICTIONARY.JOB_TITLE.X),
			translate(DICTIONARY.JOB_TITLE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}/>
}

export default MobileInputJobTitle