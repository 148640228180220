import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileVesselStorageFacilitiesAdd from "./MobileVesselStorageFacilitiesAdd"
import MobileVesselStorageUnitCard from "./MobileVesselStorageUnitCard"
import MobileAddNewBanner from "../../MobileAddNewBanner"
import Modal from "../../../../globals/components/Modal"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {marinas} props.marinas
 * @param {VesselHome[]} props.homes
 * @param {boolean} props.canEdit
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselStorageFacilities = props => {
	const [homes, setHomes] = useState(props.homes)
	const [edit, setEdit] = useState(false)
	const [add, setAdd] = useState(false)
	useEffect(() => setHomes(props.homes), [props.homes])
	const onDelete = index => {
		props.refresh().then()
		const priority = homes[index].priority
		const homes_ = homes.map(home => {
			if (home.priority > priority) {home.priority = home.priority - 1}
			return home
		})
		homes_.splice(index, 1)
		setHomes(homes_)
	}
	const onPrimary = index => {
		props.refresh().then()
		const priority = homes[index].priority
		const homes_ = homes.map(home => {
			if (home.priority < priority) {home.priority = home.priority + 1}
			return home
		})
		homes_[index].priority = 1
		setHomes(homes_)
	}
	const onCurrent = index => {
		props.refresh().then()
		const homes_ = homes.map((home, index_) => {
			index_ === index ? home.isCurrent = true : home.isCurrent = false
			return home
		})
		setHomes(homes_)
	}
	const add_ = props.canEdit && (props.marinas.length > 0) ?
		<MobileAddNewBanner className={`linkedAccounts`} text={translate(DICTIONARY.ADD_VESSEL_STORAGE_UNIT.X)}
			onClick={() => {add ? setAdd(false) : setAdd(true)}}/> : props.canEdit ?
			<div className={"paralian-mobile-info-card"}>
				<div className={"paralian-mobile-info-card-header"} style={{padding: "0.5vh 0vh"}}>
				{translate(DICTIONARY.MARINA_LIST_EMPTY_MESSAGE.X)}
				</div>
			</div> : <></>
	const content = homes.map((home, index) => <MobileVesselStorageUnitCard key={`vessel-homes-${home.priority}`} id={props.id}
		home={home} canEdit={props.canEdit} edit={edit} index={index} onDelete={onDelete} onPrimary={onPrimary} onCurrent={onCurrent}/>)
	return <>
		<div className={"paralian-mobile-info-card"}>
			<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
				labels={[translate(DICTIONARY.VESSEL_STORAGE_UNITS.X)]}/>
		</div>
		{content}
		{add ? <Modal id={"vessel-storage-unit-add-modal"} icon={ICONS.ANCHOR} padding={"0vh"}
			title={translate(DICTIONARY.ADD_VESSEL_STORAGE_UNIT.X)} onClick={() => setAdd(false)} body={
			<MobileVesselStorageFacilitiesAdd id={props.id} marinas={props.marinas} setAdd={setAdd} onSuccess={home => {
				props.refresh().then()
				setHomes([...homes, home])
				setAdd(false)
			}}/>
		}/>  : <></>}
		{add_}
	</>

}

export default MobileVesselStorageFacilities