import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {getUnits, translate} from "../../../globals/functions/client/localFunctions"
import MobileInputCountryDropdown from "../address/MobileInputCountryDropdown"
import MobileInputStateDropdown from "../address/MobileInputStateDropdown"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import State from "../../../globals/classes/shared/State"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {Area} props.area
 * @param {boolean} props.defaultLimits
 * @param {React.HTMLAttributes.className} props.className
 * @param {function} props.onUndo
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputServiceArea = props => {
	// TODO: multiple select of country OR state
	const [restrictCountry, setRestrictCountry] = useState(false)
	const [restrictState, setRestrictState] = useState(false)
	const [dropdownUnits, setDropdownUnits] = useState(getUnits() === "SI" ? "kilometers" : "miles")
	const [country, setCountry] = useState(props.area.country)
	const [state, setState] = useState(props.area.state)
	useEffect(() => {
		try {
			setCountry(props.area.country)
		} catch (e) {}
	}, [props.area])
	useEffect(() => {
		try {
			setState(props.area.state)
		} catch (e) {}
	}, [props.area])
	useEffect(() => {
		try {
			let restrict
			try {restrict = document.getElementById(`${props.id}-restrict`).checked} catch (e) {}
			setRestrictCountry(!!country.code && (props.defaultLimits || restrict))
		} catch (e) {}
	}, [props.area])
	useEffect(() => {
		try {
			let restrict
			try {restrict = document.getElementById(`${props.id}-restrict-state`).checked} catch (e) {}
			setRestrictState(!!state.code && (props.defaultLimits || restrict))
		} catch (e) {}
	}, [props.area])
	// console.log(restrictCountry, restrictState, country, state, props.area)
	const units = getUnits()
	const radius_ = units === "SI" ? props.area.radius : props.area.distanceInMiles
	const style = {margin: "0.5vh 0vw"}
	const countryElement = restrictCountry ? <MobileInputCountryDropdown required={true} className={props.className}
		id={props.id} showLabel={true} autoFocus={false} setCountry={setCountry}
		style={style} form={props.form} defaultValue={country.code || "USA"} autoComplete={"off"} onChange={() => {}}/> : <></>
	const stateElement = (!!country.code && restrictState) ? <MobileInputStateDropdown required={true}
		className={props.className} country={country} id={props.id} showLabel={true} autoFocus={false}
		style={style} form={props.form} defaultValue={state.code} autoComplete={"off"}
		onChange={state => setState(new State({code: state.id}))}/> : <></>
	const stateSlider = restrictCountry ?	<div style={{padding: "1vh 0vh"}}>
		<div style={{textAlign: "right"}} className={"w3-display-container"}>
			<MobileInputSlider id={`${props.id}-restrict-state`} form={props.form} label={translate(DICTIONARY.LIMIT_TO_STATE.X)}
				defaultChecked={!!state.code && props.defaultLimits} height={"3vh"} className={"paralian-mobile-info-card-header"} width={"8vh"}
				padding={"0.25vh 0vh 0.25vh 2vh"}	onChange={() => {setRestrictState(!restrictState)}}/>
		</div></div>  : <></>
	return <div className="w3-center w3-animate-opacity" style={{fontSize: "3vh"}}>
		<div style={{padding: "1vh 0vh"}}>
			<div style={{textAlign: "right"}} className={"w3-display-container"}>
				<MobileInputSlider id={`${props.id}-restrict`} form={props.form} label={translate(DICTIONARY.LIMIT_TO_REGION.X)}
					defaultChecked={!!country.code && props.defaultLimits} height={"3vh"} className={"paralian-mobile-info-card-header"} width={"8vh"}
					padding={"0.25vh 0vh 0.25vh 2vh"}	onChange={() => {
					try {
						if (restrictCountry) {
							const element = document.getElementById(`${props.id}-restrict-state`)
							element.checked = false
							element.setAttribute("data-is-checked", "false")
							setRestrictState(false)
						}
						setRestrictCountry(!restrictCountry)
					} catch (e) {console.log(e)}
				}}/>
			</div>
		</div>
		{stateSlider}
		<div style={{display: "flex", margin: "0.5vh 0vh", width: "100%"}}>
			<MobileInputGeneric type={"long"} id={`${props.id}-distance`} name={"distance"} form={props.form}
				autoFocus={false} maxLength={"4"} autoComplete={"off"} pattern={`([0-9,]){1,5}`} max={"9".repeat(4)}
				required={true} placeholder={"#".repeat(4)} min={"0"} className={props.className} defaultValue={Math.round(radius_)}
				step={"1"} label={translate(DICTIONARY.DISTANCE.X)}	showLabel={false} style={{height: "12vh", padding: "3vh 0vh"}} />
			<div style={{marginLeft: "1vh", width: "100%"}}>
				<MobileInputGeneric type={"dropdown"} id={`${props.id}-units`} size={"1"}
					className={props.className === "paralian-theme-level-1" ? "paralian-theme-level-2-flipped" : "paralian-theme-level-2"}
					label={translate(DICTIONARY.COUNTRY.X)} multiple={false} dataId={"miles"} showLabel={false}
					defaultValue={dropdownUnits} style={{height: "12vh", width: "100%", padding: "3vh 0vh"}}
					name={"units"} form={props.form} autoFocus={false} required={true}
					list={[{value: "miles", label: translate(DICTIONARY.MILES.X)},
						{value: "kilometers", label: translate(DICTIONARY.KILOMETERS.X)}]}
					placeholder={translate(DICTIONARY.COUNTRY.X)} onChange={() => {
						try {
							setDropdownUnits(document.getElementById(`${props.id}-units-input`).value)
						} catch (e) {}
				}}/>
			</div>
		</div>
		{countryElement}
		{stateElement}
	</div>
}

export default MobileInputServiceArea