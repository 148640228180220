import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileCurrencyEdit from "./MobileCurrencyEdit"
import MobileInfoCardHeader from "../MobileInfoCardHeader"

/**
 *
 * @param {Object} props
 * @param {string} [props.country = "USA"] - ISO3166 3-letter code
 * @param {Currency} props.currency
 * @param {boolean} props.appendToLabel
 * @param {function} props.refresh
 * @return {JSX.Element}
 * @constructor
 */
const MobileCurrency = props => {
	const [edit, setEdit] = useState(false)
	const [currency, setCurrency] = useState(props.currency)
	const onSubmit = currency => {
		props.refresh().then()
		setCurrency(currency)
		setEdit(false)
	}
	useEffect(() => {setCurrency(props.currency)}, [props.currency])
	const content = edit ? <MobileCurrencyEdit id={"cr"} country={props.country} currency={currency} onSubmit={onSubmit}/> :
		<div className={"w3-animate-opacity"}>
			<div>{currency.name}</div>
			<div style={{fontSize: "5vh"}}>{`${currency.asString} (${currency.code})`}</div>
		</div>
	return <div className={"paralian-mobile-info-card"} style={{textAlign: "center", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.CURRENCY.X)]}/>
		{content}
	</div>
}

export default MobileCurrency