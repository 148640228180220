import MobileImage from "../account/MobileImage"
import React from "react"

const MobileEmployeeCard = props => {
	return <div id={`employee-${props.employee.id}`} className={props.className || "paralian-theme-level-1"}
		style={{padding: "1vh"}} onClick={props.onClick}>
		<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
			textAlign: "center", marginBottom: "1vh"}}>
			<div style={{width: "100%", padding: "0.5vh"}}>
				<i>{props.employee.contact.contactInfo.emails.primary.address}</i>
			</div>
		</div>
		<div style={{display: "inline-flex", width: "100%", maxWidth: "36vh"}}>
			<MobileImage image={props.employee.image} id={`employee-image-${props.employee.id}`} size={"10vh"} alt={"profile"}
				style={{margin: "auto 1vh"}}/>
			<div style={{width: "100%", textAlign: "right", margin: "auto"}}>
				<div>
					<i>{props.employee.title}</i>
				</div>
				<div style={{fontSize: "3vh"}}>
					{props.employee.contact.name.given}
				</div>
				<div style={{fontSize: "2vh"}}>{props.employee.username}</div>
			</div>
		</div>
	</div>
}

export default MobileEmployeeCard