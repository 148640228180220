import React from "react"
import {ICONS} from "../../../../globals/constants/client/constants"
import MobileInputGeneric from "../MobileInputGeneric"
/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.showLabel {boolean}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.right {Object}
 * @param props.right.icon {string} - FontAwesome icon classname
 * @param props.right.onClick {Object} - function
 * @returns {*}
 * @constructor
 */
const MobileInputUpdateUsername = props => <MobileInputGeneric type={"username"} id={props.id} name={props.name}
	className={props.className} style={props.style} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
	form={props.form} autoComplete={"username"}	required={true} defaultValue={null} showLabel={props.showLabel}
	autoFocus={props.autoFocus} left={{icon: ICONS.USER, onClick: () => {}}} right={props.right}/>

export default MobileInputUpdateUsername