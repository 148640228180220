import React from "react"
import {convertToPreferredUnits, getUnits} from "../../../globals/functions/client/localFunctions"
import MobileImage from "../account/MobileImage"
import Image from "../../../globals/classes/shared/Image"

/**
 *
 * @param {Object} props
 * @param {Object} props.marina
 * @param {number} props.marina.id
 * @param {string} props.marina.name
 * @param {Image} props.marina.logo
 * @param {string} props.marina.city
 * @param {State} props.marina.state
 * @param {Country} props.marina.country
 * @param {number} props.marina.distance
 * @param {number[]} props.selected
 * @param {function} props.setSelected
 * @return {JSX.Element}
 * @constructor
 */
const MobileNearbyMarinaCard = props => {
	const units = getUnits()
	const className = `paralian-mobile-info-card ${props.className}`
	const distance = convertToPreferredUnits(props.marina.distance/1000, "km", units, 1,
		true, true)
	return <div id={`marina-${props.marina.id}`} className={className}
		style={{margin: "2vh 0vh"}} onClick={() => {
		if (props.selected.includes(props.marina.id)) {
			const index = props.selected.findIndex(item => item === props.marina.id)
			const arr = [...props.selected]
			arr.splice(index, 1)
			document.getElementById(`marina-${props.marina.id}`).className =
				`${className} ${props.className || "paralian-theme-level-2"}`
			props.setSelected(arr)
		} else {
			document.getElementById(`marina-${props.marina.id}`).className =
				`${className} paralian-theme-level-4-flipped`
			props.setSelected([...props.selected, props.marina.id])
		}
		// console.log(`clicked: ${props.marina.id}`)
	}}>
		<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
			textAlign: "center", marginBottom: "1vh"}}>
			<div style={{width: "100%", padding: "0.5vh"}}>
				<i>{props.marina.name}</i>
			</div>
		</div>
		<div style={{display: "flex", width: "100%", maxWidth: "40vh", margin: "auto"}}>
			<MobileImage image={new Image(props.marina.logo)} id={`marina-logo-${props.marina.id}`} size={"10vh"} alt={"logo"}
				style={{margin: "auto 1vh"}}/>
			<div style={{width: "100%", textAlign: "right", margin: "auto"}}>
				<div>
					<i>{props.marina.city}</i>
				</div>
				<div style={{fontSize: "3vh"}}>
					{props.marina.state.name || props.marina.country.name}
				</div>
				<div style={{fontSize: "2vh"}}>{distance}</div>
			</div>
		</div>
	</div>
}

export default MobileNearbyMarinaCard