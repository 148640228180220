import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {getEngineBrands} from "../../../../globals/functions/client/serverFunctions"
import MobileInputDatalist from "../../../shared/input/datalist/MobileInputDatalist"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLProps.className} props.className
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {string} props.defaultValue
 * @param {string} props.name
 * @param {string} props.form
 * @param {boolean} props.showLabel
 * @param {boolean} props.required
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputEngineBrandDatalist = props => {
	const onChange = props.onChange ? props.onChange : () => {}
	const [brands, setBrands] = useState([])
	useEffect(() => {
		getEngineBrands().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setBrands(response.payload.map(brand => {return {label: brand.brand, value: brand.brand}}))
			}
		}).catch(reason => console.log(reason))
	},[])
	return <MobileInputDatalist id={props.id} className={props.className} style={props.style} list={brands}
		label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
		left={props.left} right={props.right} frozenValue={null} name={props.name} form={props.form} translate={"no"}
		autoFocus={props.autoFocus} maxLength={"45"} autoComplete={props.autoComplete} pattern={null}
		required={props.required} placeholder={props.placeholder} defaultValue={props.defaultValue}
		onChange={() => {
			const element = document.getElementById(`${props.id}-input`)
			element.setAttribute("data-a", element.value)
			const index = brands.findIndex(brand => brand.value === element.value)
			if (index !== -1) {
				props.setBrand(element.value)
				onChange()
			}}
		}
	/>
}

export default MobileInputEngineBrandDatalist