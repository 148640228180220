import {AUTHENTICATION_STATES, DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"

/**
 *
 * @param {Object} status - status object sent from server
 * @param {boolean} findExisting - true if a positive outcome would be to find the username
 * @param {function} onSuccess - function to execute if positive outcome is achieved
 * @param {function} onFailure - function to execute if negative outcome is achieved
 */
const respondToUsernameCheck = (status, findExisting = true, onSuccess = () => {}, onFailure = () => {}) => {
	/* TODO: translate */
	if (findExisting) {
		switch (status) {
			case AUTHENTICATION_STATES.USER_FOUND_ACTIVE:
				onSuccess()
				return true
			case AUTHENTICATION_STATES.USER_FOUND_NEW:
				/* TODO: something special if the user still needs to input critical data */
				onSuccess()
				return true
			case AUTHENTICATION_STATES.USER_FOUND_CLOSED:
				newMessage("username-check-message", translate(DICTIONARY.ACCOUNT_CLOSED.X),
					translate(DICTIONARY.ACCOUNT_CLOSED_MESSAGE.X))
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_DEACTIVATED:
				newMessage("username-check-message", translate(DICTIONARY.ACCOUNT_DEACTIVATED.X),
					translate(DICTIONARY.ACCOUNT_DEACTIVATED_MESSAGE.X))
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_DISABLED:
				newMessage("username-check-message", translate(DICTIONARY.ACCOUNT_DISABLED.X),
					translate(DICTIONARY.ACCOUNT_DISABLED_MESSAGE.X))
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_LOCKED:
				/* TODO: something special if the user still needs to input critical data */
				newMessage("username-check-message", translate(DICTIONARY.ACCOUNT_LOCKED.X),
					translate(DICTIONARY.ACCOUNT_LOCKED_MESSAGE.X), "w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE)
				onSuccess()
				return true
			case AUTHENTICATION_STATES.USER_NOT_FOUND:
				newMessage("username-check-message", translate(DICTIONARY.NO_MATCH.X),
					translate(DICTIONARY.USER_NOT_FOUND_MESSAGE.X), "w3-pale-red")
				onFailure()
				return false
			case AUTHENTICATION_STATES.TOO_MANY_LOGIN_ATTEMPTS:
				newMessage("username-check-message", translate(DICTIONARY.TOO_MANY_LOGIN_ATTEMPTS.X),
					translate(DICTIONARY.TOO_MANY_LOGIN_ATTEMPTS_MESSAGE.X))
				return false
			default:
				newMessage("username-check-message", translate(DICTIONARY.ERROR.X),
					translate(DICTIONARY.LOGIN_ERROR_MESSAGE.X), "w3-pale-red", ICONS.EXCLAMATION_CIRCLE)
				return false
		}
	} else {
		switch (status) {
			case AUTHENTICATION_STATES.USER_FOUND_ACTIVE:
				newMessage("username-check-message", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "")
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_NEW:
				newMessage("username-check-message", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "")
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_CLOSED:
				newMessage("username-check-message", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "")
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_DEACTIVATED:
				newMessage("username-check-message", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "")
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_DISABLED:
				newMessage("username-check-message", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "")
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_FOUND_LOCKED:
				newMessage("username-check-message", translate(DICTIONARY.USERNAME_UNAVAILABLE.X), "")
				onFailure()
				return false
			case AUTHENTICATION_STATES.USER_NOT_FOUND:
				newMessage("username-check-message", translate(DICTIONARY.NO_MATCH.X),
					translate(DICTIONARY.USER_NOT_FOUND_MESSAGE.X),	"w3-pale-red")
				onSuccess()
				return true
			case AUTHENTICATION_STATES.TOO_MANY_LOGIN_ATTEMPTS:
				newMessage("username-check-message", translate(DICTIONARY.TOO_MANY_LOGIN_ATTEMPTS.X),
					translate(DICTIONARY.TOO_MANY_LOGIN_ATTEMPTS_MESSAGE.X))
				return false
			default:
				newMessage("username-check-message", translate(DICTIONARY.ERROR.X),
					translate(DICTIONARY.LOGIN_ERROR_MESSAGE.X), "w3-pale-red", ICONS.EXCLAMATION_CIRCLE)
				return false
		}
	}
}

export default respondToUsernameCheck