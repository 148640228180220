import TextInputFilter from './TextInputFilter';
/**
 * @extends TextInputFilter
 */
class Hour extends TextInputFilter {
	minLength = 1
	maxLength = 2
	regexp = /[^0-9]/gi
	minVal = 0
	maxVal = 23
}

export default Hour;