import React, {useState} from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {newMessage, togglePasswordVisibility, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.form
 * @param {boolean} props.showLabel
 * @param {string} props.username
 * @param {boolean} props.autoFocus
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.autoComplete - if true, will be set to "current-password". if false, will be set to "new-password"
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputPassword = props => {
	const autoComplete = props.autoComplete ? "current-password" : "new-password"
	const username = props.username ? <input id={"username-input"} defaultValue={props.username} name={"username"}
		type={"text"} autoComplete={"username"} style={{display: "none"}} form={props.form}/> : <></>
	const [icon, setIcon] = useState(ICONS.EYE_SLASH)

	return <>
		{username}
		<MobileInputGeneric type={"password"} id={"password"} name={"password"} label={translate(DICTIONARY.PASSWORD.X)}
			showLabel={props.showLabel}	className={props.className} style={props.style} bottomRadius={props.bottomRadius}
			topRadius={props.topRadius} left={{icon: ICONS.KEY, onClick: () => {newMessage("login",
				translate(TEXT_FIELD_PROPERTIES.password.hoverTitle),
				translate(TEXT_FIELD_PROPERTIES.password.hoverMessage), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}
			right={{icon: icon, onClick: () => {
				togglePasswordVisibility("password-input","password-right", setIcon)
			}}} form={props.form} autoComplete={autoComplete} username={props.username}
			placeholder={translate(DICTIONARY.PASSWORD.X)} autoFocus={props.autoFocus}/>
	</>
}

export default MobileInputPassword