import React, {useRef, useState} from "react"
import {stripePromise} from "../../../index"
import {ICONS} from "../../../globals/constants/client/constants"
import {DOCUMENT_TYPE, DOCUMENT_CONTAINER, ACCOUNT_STATE,	ACCOUNT_TYPE,	IMAGE_CATEGORY, DOCUMENT_TYPE_MINIMAL} from "../../../globals/constants/shared/enumerators"
import {Elements} from "@stripe/react-stripe-js"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileProfileImage from "../MobileProfileImage"
import MobileUsersLinkedAccounts from "./MobileUsersLinkedAccounts"
import ImageUpload from "../../../tools/ImageUpload"
import MobileDocuments from "../documents/MobileDocuments"
import MobileIdentityVerification from "../MobileIdentityVerification"
import MobileAccountControl from "./MobileAccountControl"
import MobileAddressList from "../address/MobileAddressList"
import StarRating from "../../../globals/components/StarRating"
import MobileDateOfBirth from "../contact/MobileDateOfBirth"
import MobilePaymentMethods from "../payment/MobilePaymentMethods"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileContactInfo from "../contact/MobileContactInfo"
import MobileEmployeeServiceAccess from "../employee/MobileEmployeeServiceAccess"
import MobileParalianSubscriptions from "../products/MobileParalianSubscriptions"
import imagePlaceholder from "../../../images/user.svg"

const MobileAccounts = props => {
	/* TODO: profile X% complete */
	/* TODO: flash sections which are incomplete to prompt user to provide the data */
	/* TODO: include title in case of admin or employee */
	/* TODO: include schedule in case of admin or employee */
	/* TODO: include employee since in case of admin or employee */
	/* TODO: remove upload icon from image if there is an image to show */
	/* TODO: show pdf icon image for documents when image can't show (place it in the background) */
	const [name, setName] = useState(null)
	const [username, setUsername] = useState(null)
	const [uploadImage, setUploadImage] = useState(false)
	const certificatesRef = useRef(null)
	const idVerificationRef = useRef(null)
	const top = "7vh"
	const isEmployee = props.account.type === ACCOUNT_TYPE.EMPLOYEE
	const isAdmin = props.account.type === ACCOUNT_TYPE.ORGANIZATION
	const isEmployeeOrAdmin = isEmployee || isAdmin
	const isPersonal = props.account.type === ACCOUNT_TYPE.PERSONAL
	if (props.account.id === null) {
		return <div className={"w3-display-middle w3-animate-opacity"}>
			<MobileFontAwesome icon={ICONS.SYNC} style={{fontSize: "15vh"}} onClick={() => props.refreshAccount()}/>
		</div>
	}
	const upload = uploadImage ? <ImageUpload id={props.account.id} show={setUploadImage} type={IMAGE_CATEGORY.ACCOUNT_PROFILE}
		refresh={props.refreshAccount}/> : <></>
	const title = props.account.title ? <div style={{opacity: "0.5", paddingBottom: "1vh"}}><i>{props.account.title}</i></div> : <></>
	const services = isEmployeeOrAdmin ?
		<MobileEmployeeServiceAccess account={props.account} services={props.organization.services} top={top} zIndex={3}/> : <></>
	const addresses = isPersonal ?
		<MobileAddressList addresses={props.account.addresses.asArray} allowDelete={true} isOrganization={false} showAddBanner={true}
			top={top} zIndex={4} refresh={props.refreshAccount} canEdit={true}/> : <></>
	const contracts = isEmployeeOrAdmin ?
		<MobileDocuments documents={props.account.documents.contracts} container={DOCUMENT_CONTAINER.ACCOUNTS}
			canEdit={isAdmin} docType={DOCUMENT_TYPE_MINIMAL.CONTRACTS} parentId={props.account.id} add={isAdmin} refresh={props.refreshAccount}
			top={top} zIndex={7}/> : <></>
	const paymentMethods = isPersonal ?
		<Elements stripe={stripePromise}>
			<MobilePaymentMethods parentObject={props.account} refresh={props.refreshAccount} top={top} zIndex={8}/>
		</Elements> : <></>
	return <>
		{upload}
		<div id={"user-container"} className={"w3-display-middle w3-animate-opacity paralian-mobile-content " +
			"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}>
			{/* Profile Image */}
			<MobileProfileImage image={props.account.image.path ? props.account.image : null}
				placeholder={imagePlaceholder} upload={{include: true, hide: !!props.account.image.path,
				onClick: () => setUploadImage(!uploadImage)}}/>
			{/* Username and Account Type */}
			<div style={{zIndex: "20", padding: "1vh 0vh", height: "7vh", fontSize: "2vh"}}
			 	className={"sticky paralian-theme-level-1"}>
				<i style={{margin: "1vh 0vh"}} translate={"no"}>{username || props.account.username}</i>
				<div className="accountType" style={{opacity: "0.5", paddingBottom: "1vh"}}>
					<i>{props.account.type ? translate(`${props.account.type}_ACCOUNT`.toUpperCase()) : ""}</i>
				</div>
			</div>
			{/* Name */}
			<div style={{fontSize: "4vh", padding: "0vh 2vh 1vh"}} translate={"no"}>
				{name || props.account.contact.name.given}
			</div>
			{/* Title */}
			{title}
			{/* Rating */}
			<div style={{fontSize: "3vh", marginBottom: "1vh"}}>
				<StarRating rating={props.account.rating.overall} style={{padding: "0vh 1vh"}}/>
			</div>
			{/* Icons */}
			<div style={{fontSize: "3vh", display: "inline-flex"}}>
				<MobileFontAwesome icon={ICONS.FILE_CERTIFICATE} style={{padding: "0vh 1vh"}} onClick={() => {
					document.getElementById("user-container").scrollTo(0, certificatesRef.current.offsetTop)
				}}/>
				<MobileFontAwesome icon={ICONS.ID_CARD}	style={{padding: "0vh 1vh"}} onClick={() => {
					document.getElementById("user-container").scrollTo(0, idVerificationRef.current.offsetTop)
				}}/>
				<MobileFontAwesome icon={["fas", ICONS.SHIELD_CHECK[1]]}	style={{fontSize: "2vh", marginLeft: "-2vh"}}/>
				<MobileFontAwesome icon={ICONS.SYNC}	style={{padding: "0vh 1vh"}}
					onClick={() => props.refreshAccount().then()}/>
			</div>
			{/* Contact Info */}
			<MobileContactInfo contactInfo={props.account.contact.contactInfo} refresh={props.refreshAccount}
				type={props.account.type} canEdit={true}/>
			{/* Date of Birth */}
			{props.account.contact.birthdate.verified ? <></> :	<MobileDateOfBirth birthdate={props.account.contact.birthdate}
				refresh={props.refreshAccount} canEdit={true}/>}
			{/* Schedule */}
			{/*schedule*/}
			{/* Services */}
			{services}
			{/* Addresses */}
			{addresses}
			{/* Linked Accounts */}
			<MobileUsersLinkedAccounts linkedAccounts={props.account.linkedAccounts} setAccount={props.setAccount}
				refresh={props.refreshAccount} top={top} zIndex={5}/>
			{/* Documents */}
			<div ref={certificatesRef}/>
			<MobileDocuments documents={props.account.documents.certificates} container={DOCUMENT_CONTAINER.ACCOUNTS}
				canEdit={true} docType={DOCUMENT_TYPE.CERTIFICATES} parentId={props.account.id} add={true}
				refresh={props.refreshAccount} top={top} zIndex={6}/>
			{contracts}
			{/* Payment Methods */}
			{paymentMethods}
			{/* Subscriptions */}
			{/* TODO: subscriptions */}
			{/* ID Verification */}
			<>
				<div ref={idVerificationRef}/>
				<MobileIdentityVerification verifications={props.account.verifications} refresh={props.refreshAccount}
					top={top} zIndex={9}/>
			</>
			{/* Paralian Products */}
			{isPersonal ? <MobileParalianSubscriptions canEdit={true} isOrganization={false} parentObject={props.account}
				refresh={props.refreshAccount} top={top} zIndex={10} canDelete={true}/> : <></>}
			{/*<MobilePrivacy access={props.account.viewAccess} top={top} zIndex={7}/>*/}
			<MobileAccountControl locked={props.account.status === ACCOUNT_STATE.LOCKED} name={name}
				username={username} setUsername={setUsername} twoFactor={props.account.twoFactor} setName={setName}
					refresh={props.refreshAccount} top={top} zIndex={11} contactInfo={props.account.contact.contactInfo}/>
		</div>
	</>
}

export default MobileAccounts