import React from "react"
import {store} from "../../index"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../globals/constants/client/constants"
import {translate} from "../../globals/functions/client/localFunctions"
import MobileQuestionTwoOptions from "../shared/input/combination/MobileQuestionTwoOptions"

const MobileLoginPhysicalAddressVisitQ = props => {
	const levelOne = store.getState().window.current.levelOne
	return <MobileQuestionTwoOptions icon={ICONS.STORE_ALT} keyname={"canVisit"}
		question={translate(DICTIONARY.PHYSICAL_ADDRESS_VISIT_Q_MESSAGE.X)} back={props.back}
		left={{next: PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICE_AREA.X,
			text: translate(DICTIONARY.NO.X), value: false}}
		right={{next: PAGE_STATES[levelOne].LOGIN.NEW_USER.ORGANIZATION_SERVICE_AREA.X,
			text: translate(DICTIONARY.YES.X), value: true}}/>
}

export default MobileLoginPhysicalAddressVisitQ