import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {DOCUMENT_TYPE, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {getAllCountries} from "../../../globals/functions/client/serverFunctions"
import translateDocumentType from "./translateDocumentType"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileAddNewBanner from "../MobileAddNewBanner"
import MobileDocument from "./MobileDocument"
import MobileDocumentUploadForm from "./MobileDocumentUploadForm"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import MobileParalianSubscriptions from "../products/MobileParalianSubscriptions"
import Modal from "../../../globals/components/Modal"
/* TODO: toggle for show expired */
/* TODO: add isVerified / isParalianVerified */
/* TODO: add filter/sort when more than two documents are available for a given document type */
/**
 *
 * @param {Object} props
 * @param {number} props.zIndex
 * @param {string} props.top
 * @param {string} props.docType
 * @param {string} props.container
 * @param {number} props.vesselId
 * @param {Document[]} props.documents
 * @param {boolean} props.canEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileDocuments = props => {
	const [expanded, setExpanded] = useState(false)
	const [add, setAdd] = useState(false)
	const [documents, setDocuments] = useState(props.documents || [])
	const [countries, setCountries] = useState([])
	const [showPayment, setShowPayment] = useState(false)
	const removeDocument = id => {
		const filtered = documents.filter(document => Number(document.document.id) !== Number(id))
		setDocuments(filtered)
	}
	const appendDocument = document => {
		setDocuments([...documents, document])
	}
	const replaceDocument = (id, document) => {
		const filtered = documents.filter(document => Number(document.document.id) !== Number(id))
		setDocuments([...filtered, document])
	}
	useEffect(() => {setDocuments(props.documents || [])}, [props.documents])
	useEffect(() => {getAllCountries().then(response => {
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			setCountries(response.payload)
		}
	})}, [])
	const bannerText = translateDocumentType(props.docType)
	const content = documents.map(document => {
		return <MobileDocument document={document} docType={props.docType} parentId={props.parentId} countries={countries}
			refresh={props.refresh}	container={props.container} appendDocument={appendDocument} removeDocument={removeDocument}
			replaceDocument={replaceDocument} key={`${props.docType}-${document.document.id}`} canEdit={props.canEdit}/>
	}) || []
	const numDocs = content.length
	const maxReached = [DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS, DOCUMENT_TYPE.VESSEL_REGISTRATIONS,
		DOCUMENT_TYPE.VESSEL_TITLES].includes(props.docType) && numDocs > 0
	const add_ = props.add && !maxReached ? <MobileAddNewBanner className={`add-${props.docType}`}
		text={`${translate(DICTIONARY.ADD.X)} ${bannerText}`}	onClick={() => setAdd(!add)}/> : <></>
	// console.log("MD props.vesselId", props.vesselId)

	const insert = add ? <Modal icon={ICONS.PLUS_CIRCLE} title={`${translate(DICTIONARY.ADD.X)} ${bannerText}`}
		onClick={() => setAdd(false)} id={`upload-${props.docType}-modal`} padding={"0vh"}
		body={<MobileDocumentUploadForm id={`upload-${props.docType}`}
		form={`form-${props.docType}`} docType={props.docType} container={props.container}
		className={"paralian-theme-level-1"} containerId={props.vesselId} onSuccess={payload => {
			props.refresh().then()
			appendDocument(payload)
			setAdd(false)
		}
		}/>}/> : <></>
	const subscribe = props.subscribe ? <MobileButtonGeneric id={"premium-membership-subscribe"} type={"button"}
		padding={"2vh"} style={{margin: "2vh"}} className={"paralian-theme-level-2"}
	 	onClick={() => {
			// console.log(props.account)
			setShowPayment(!showPayment)
		}}
		text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.BECOME_MEMBER_UNLOCK.X)}}/> : <></>
	const pay = showPayment ? <MobileParalianSubscriptions canEdit={true} isOrganization={false} parentObject={props.account}
		refresh={props.refreshAccount} top={"7vh"} hideBanner={true} zIndex={10} canDelete={true}/> : <></>
	const content_ = !expanded ? <></> : <div id={props.docType} className="w3-animate-top" style={{fontSize: "2vh"}}>
		{content}{insert}{subscribe}{pay}{add_}</div>
	return !props.canEdit && documents.length === 0 ? <></> : <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={bannerText} top={props.top} premium={props.premium}
			onClick={() => setExpanded(!expanded)}/>
		{content_}
	</>
}

export default MobileDocuments