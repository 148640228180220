import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {getMarinasWithinRadius} from "../../../globals/functions/client/serverFunctions"
import MobileNearbyMarinaCard from "./MobileNearbyMarinaCard"
/**
 *
 * @param {Object} props
 * @param {string} props.message
 * @param {function} props.setMarinas
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputSelectServiceMarinas = props => {
	const [marinas, setMarinas] = useState([])
	// const [selected, setSelected] = useState([])
	// console.log(props.hidden, props.message, marinas)
	useEffect(() => {
		if (props.message) {
			newMessage("service-marinas-message", translate(DICTIONARY.MARINAS_AND_STORAGE_FACILITIES.X), props.message,
				"w3-pale-blue", ICONS.INFO_CIRCLE)
		}
		getMarinasWithinRadius().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				// console.log(response.payload)
				const filtered = props.hidden ? response.payload.filter(item => !props.hidden.includes(item.id)) : response.payload
				setMarinas(filtered)
			}
		})
	}, [props.message])
	return <>
		{/*<div id={"selected-marinas"} style={{display: "none"}} data-selected=""/>*/}
		{marinas.length > 0 ? marinas.map(marina => <MobileNearbyMarinaCard marina={marina} selected={props.selected}
			setSelected={props.setSelected} className={"paralian-theme-level-1"} key={`marina-card-${marina.id}`}/>) :
			<div style={{textAlign: "center"}}>
				<i>{translate(DICTIONARY.NO_MARINA_WITHIN_SERVICE_AREA.X)}</i>
			</div>}
	</>
}

export default MobileInputSelectServiceMarinas
