import React from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import ICONS from "../../../../globals/constants/client/icons"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {getServices} from "../../../../globals/functions/client/serverFunctions"
import {useEffect, useState} from "react"
import {setToStorage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileServicesTreeSimple from "../../../main/service/simple/MobileServicesTreeSimple"
import IndexedArray from "../../../../globals/classes/shared/IndexedArray"
import Servicio from "../../../../globals/classes/shared/Servicio"
/**
 *
 * @param {Object} props
 * @param {Object} props.back
 * @param {Object} props.next
 * @param {JSX.Element|string} props.header
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginOrgServices = props => {
	const [selected, setSelected] = useState([])
	const [allServices, setAllServices] = useState({asArray: [], asIndexedArray: new IndexedArray(),
		contained: new Servicio(), children: new IndexedArray()})
	const getChildren = parent => {
		let children = []
		const _children = [...parent.children.parents, ...parent.children.singles]
		for (let i = 0; i < _children.length; i++) {
			children.push({id: _children[i].id, isParent: _children[i].hasChildren})
			if (_children[i].hasChildren) {children = [...children, ...getChildren(_children[i])]}
		}
		return children
	}
	const getServices_ = () => {
		/* TODO: automatically adjust based on who is creating account (BROKER/BUSINESS/MARINA) */
		getServices({isBusiness: true, isLive: true}, false).then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const asArray = response.payload.asArray.map(v => new Servicio(v))
				setAllServices({
					asArray: asArray,
					asIndexedArray: new IndexedArray({array: asArray, key: "id"}),
					contained: response.payload.contained.map(v => new Servicio(v)),
					children: new IndexedArray({array: asArray.map(v => {
							return {
								id: v.id,
								children: getChildren(v)
							}
						}), key: "id"})
				})
			}
		})
	}
	useEffect(getServices_, [])
	setToStorage("services", selected, "session")
	const content = allServices.asArray.length > 0 ?
		<MobileServicesTreeSimple allServices={allServices} preSelected={[]} singleSelect={false}
			selected={selected} setSelected={setSelected}/> :
		<MobileFontAwesome id={"refresh"} icon={ICONS.SYNC}	onClick={() => getServices_()}
			label={translate(DICTIONARY.REFRESH.X)}	style={{fontSize: "10vh"}}/>
	const onSubmit = allServices.asArray.length > 0 ?
		() => store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: props.next}}) : null
	return <MobileLoginFormScaffolding onSubmit={onSubmit} disabled={selected.length === 0} header={null}
		content={content} back={props.back}/>
}

export default MobileLoginOrgServices