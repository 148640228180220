import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {VESSEL_SYSTEM} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../../shared/input/MobileInputGeneric"

/**
 *
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.style} [props.style = {margin: "0.5vh 0vh"}]
 * @param {React.HTMLAttributes.className} props.className - className(s) of container's top level element
 * @param {string} [props.label]
 * @param {boolean} props.showLabel
 * @param {string} props.autoComplete
 * @param {string} props.defaultValue
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {function} props.onChange
 * @param {Object} [props.left = {icon: " ", onClick: () => {}}]
 * @param {string[]} props.left.icon - FontAwesome icon classname
 * @param {function} props.left.onClick
 * @param {Object} props.right
 * @param {string[]} props.right.icon - FontAwesome icon classname
 * @param {function} props.right.onClick
 * @param {function} props.setCountry
 * @returns {JSX.Element}
 * @constructor
 */
const MobileVesselSystemDropdown = props => {
	const list = Object.values(VESSEL_SYSTEM).map(item => {return {label: translate(item), value: item, id: item}})
	const label = props.label || translate(DICTIONARY.SYSTEM.X)
	const style = props.style || {margin: "0.5vh 0vh", fontSize: "2.5vh"}
	const left = props.left || {icon: " ", onClick: () => {}}
	const onChange = props.onChange ? props.onChange : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	/* TODO: turn into multiple select */
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}-system`} className={className} size={"1"} left={left}
		label={label} multiple={false} topRadius={props.topRadius} showLabel={props.showLabel} right={props.right}
		style={style} list={list} placeholder={label}	name={"system"} form={props.form} autoFocus={false} required={true}
		bottomRadius={props.bottomRadius} dataId={list[0].id} onChange={() => {
		try {
			const element = document.getElementById(`${props.id}-system-input`)
			element.setAttribute("data-id", element.value)
		} catch (e) {
			console.log(e)
		}
		onChange()
	}}/>
}

export default MobileVesselSystemDropdown