import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileSubtitleAndValue from "../../MobileSubtitleAndValue"
import MobileRigMizzenEdit from "./MobileRigMizzenEdit"
import MobileInfoCardHeader from "../../MobileInfoCardHeader"

const MobileRigMizzen = props => {
	const [edit, setEdit] = useState(false)
	const [mizzen, setMizzen] = useState({Py: props.rig.Py, Ey: props.rig.Ey})
	useEffect(() => {
		setMizzen({Py: props.rig.Py, Ey: props.rig.Ey})
	}, [props.rig.Py, props.rig.Ey])
	const content = edit ?
		<MobileRigMizzenEdit id={props.id} rig={props.rig} units={props.units} onSuccess={(py, ey) => {
			props.refresh().then()
			setMizzen({Py: py, Ey: ey})
			setEdit(false)}}/> : <>
			<MobileSubtitleAndValue units={props.units} subtitle={`Py (${translate(DICTIONARY.HOIST.X)})`}
				value={{number: mizzen.Py, units: "m", digits: 3}}/>
			<MobileSubtitleAndValue units={props.units} subtitle={`Ey (${translate(DICTIONARY.FOOT.X)})`}
				value={{number: mizzen.Ey, units: "m", digits: 3}}/>
		</>
	return <div className={"paralian-mobile-info-card"}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.MIZZEN.X)]}/>
		{content}
	</div>
}

export default MobileRigMizzen