import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import {setEngineHrs} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileInputGeneric from "../../../shared/input/MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {string} props.engine
 * @param {function} props.onSuccess
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselEngineHoursEdit = props => {
	const onSubmit = async event => {
		event.preventDefault()
		const hrs = Number(document.getElementById(`${props.engine.id}-engine-hrs-input`).value.replace(/[a-zA-Z,. ]/g,''))
		const response = await setEngineHrs(props.id, props.engine.id, hrs)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.onSuccess(hrs)
		}
	}
	const defaultValue = props.engine.hours > 0 ? props.engine.hours : null
	const content = <MobileInputGeneric type={"long"} id={`${props.engine.id}-engine-hrs`} name={`${props.engine.id}-engine-hrs`}
		form={`${props.id}-engine-hrs-form`} autoFocus={true} maxLength={5} autoComplete={"off"} required={true}
		placeholder={"####"} min={"0"} max={"99999"} step={1}	defaultValue={defaultValue} style={{textAlign: "center"}}
		label={translate(DICTIONARY.HOURS.X)} showLabel={false} className={"paralian-theme-level-1"}/>
	return <MobileInputForm id={`${props.engine.id}-engine-hrs`} content={content} className={" "} onSubmit={onSubmit}/>
}

export default MobileVesselEngineHoursEdit